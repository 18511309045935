import { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric'
import { AlignGuidelines } from 'fabric-guideline-plugin'
import FontFaceObserver from 'fontfaceobserver'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { CenterAlignBox, CenterAlignStack } from 'components'
import {
  BlueCircleCheckIcon,
  CheckedCircleIcon,
  CheckedCircleIconNotistack,
  CircleCheckIcon,
  CloseCuteIcon,
  DropdownArrowIcon,
  PlusIcon,
  PlusIcon2,
  PlusMobileIcon,
  RegenerateIcon,
} from 'theme/icon'
import { motion, AnimatePresence } from 'framer-motion'
import { fontSize } from './Toolbar'
import { Group, Layer, Stage, Text, Transformer } from 'react-konva'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { Html } from 'react-konva-utils'

const layers = {
  canvasSize: {
    width: 500,
    height: 500,
  },
  whiteRect: {
    x: 150,
    y: 110,
    width: 500,
    height: 500,
    id: 'whiteRect',
  },
  shapes: [
    {
      id: 'a45b89e1-31f2-4475-8d1a-b9911dfd8130',
      x: 0,
      y: 0,
      width: 500,
      height: 500,
      fill: 'rgb(170, 186, 83)',
      text: 'background 1',
      result_s3_url:
        'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
      config: {
        type: 'background',
        background_type: 'inpaint',
        bg_color: '',
        bg_blur_intensity: 0,
        bg_inpaint_image_url:
          'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
        bg_inpaint_prompt: '',
        bg_inpaint_image_bbox: [294, 292, 471, 469],
        bg_inpaint_background_image_url: '',
        bg_inpaint_background_image_bbox: [],
        bg_inpaint_background_mask_bbox: [],
        bg_concept_prompt: '',
        bg_reference_image_url: '',
        bg_reference_image_bbox: [],
        bbox: [0, 0, 500, 500],
      },
    },
    {
      id: '04673144-ae4a-4c5c-8442-5aa3abb51ff6',
      x: 294,
      y: 292,
      width: 177,
      height: 177,
      fill: 'rgba(255, 255, 255, 0.6)',
      text: 'inpaint 1',
      result_s3_url:
        'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
      config: {
        type: 'inpaint',
        bg_inpaint_image_url:
          'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
        imageUrl:
          'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
        bbox: [294, 292, 471, 469],
      },
    },
    {
      id: '9880271c-879b-42f4-8bba-9b203f5070a1',
      x: 29,
      y: 33,
      width: 442,
      height: 100,
      fill: 'rgb(169, 35, 169)',
      text: 'text 1',
      result_s3_url:
        'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
      config: {
        type: 'text',
        text_type: 'copy',
        text_copy_type: 'info',
        text_price_prev_price: '',
        text_price_post_price: '',
        text_custom: '',
        text_role: 'title',
        linebreak: 'false',
        text_font: 'auto',
        text_size: 'auto',
        text_align: 'center',
        text_vertical_align: 'center',
        text_color: [50, 50, 50, 1],
        text_bg_color: [255, 255, 255, 1],
        bbox: [29, 33, 471, 133],
      },
    },
    {
      id: 'edc71199-85d6-4deb-9e51-2d8514fc9ced',
      x: 29,
      y: 145,
      width: 173,
      height: 175,
      fill: 'rgb(187, 30, 177)',
      text: 'image 1',
      result_s3_url:
        'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
      config: {
        type: 'image',
        has_main_image: false,
        image_type: 'paste',
        image_human_prompt: '',
        image_concept_prompt: '',
        image_reference_image: '',
        image_frame_form: 'nukki',
        image_frame_beyond: false,
        image_blur_intensity: 0,
        image_gradation_direction: 'None',
        image_inpaint_img_url: '',
        image_inpaint_bbox: [],
        image_inpaint_prompt: '',
        bbox: [29, 145, 202, 320],
      },
    },
    {
      id: 'f9f97835-bc88-4ca0-927b-74f2893afa36',
      x: 29,
      y: 330,
      width: 139,
      height: 139,
      fill: 'rgb(67, 193, 236)',
      text: 'logo 1',
      result_s3_url:
        'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
      config: {
        type: 'logo',
        logo_image_url: '',
        bbox: [29, 330, 168, 469],
      },
    },
  ],
  defaultConfig: {
    product_name: '',
    product_feature: '',
    brand_name: '',
    brand_feature: '',
    filter_grain_flag: false,
    filter_grain_intensity: 0,
    filter_sharpen_flag: false,
    filter_whitebalance_flag: false,
    filter_whitebalance_temperature: 0,
  },
}
const EDITOR_LISTS = [
  {
    avatar: '/static/images/test_sample/a2.webp',
    title: '기본 설정 레이어',
    subtitle: '상품-스튜디오',
  },
  { avatar: '/static/images/test_sample/a3.webp', title: '메인 배경', subtitle: '상품' },
  { avatar: '/static/images/test_sample/a4.jpeg', title: '텍스트', subtitle: '카피라이트' },
  { avatar: '/static/images/test_sample/a3.webp', title: '로고', subtitle: '로고 이미지' },
  {
    avatar: '/static/images/test_sample/a5.png',
    title: '디자인 요소',
    subtitle: '상품-상품 장식',
  },
  {
    avatar: '/static/images/test_sample/a4.jpeg',
    title: '필터 레이어',
    subtitle: '상품-상품 장식',
  },
]

export default function TestPage18() {
  return (
    <>
      {/* <Test1 /> */}
      <WordArtPresets />
      <Test2 />
      <Test3 />
      {/* <EditableText2 /> */}
      {/* <EditableText3 /> */}
    </>
  )
}

const Test2 = () => {
  const [textProps, setTextProps] = useState({
    // 기본 텍스트 속성
    text: '텍스트를 수정해보세요',
    x: 300,
    y: 100,

    // 크기 및 위치 관련
    width: 'auto',
    height: 'auto',
    fontSize: 32,
    scale: { x: 1, y: 1 },
    offsetX: 0,
    offsetY: 0,

    // 스타일 관련
    fontFamily: 'Arial',
    fontStyle: 'normal', // normal, bold, italic
    fontVariant: 'normal',
    textDecoration: 'none', // '', 'underline', 'line-through'
    fill: '#000000',
    backgroundColor: '#90EE90',
    stroke: '#000000',
    strokeWidth: 0,
    shadowColor: 'black',
    shadowBlur: 0,
    shadowOffset: { x: 0, y: 0 },
    shadowOpacity: 0,

    // 정렬 관련
    align: 'left', // left, center, right
    verticalAlign: 'top', // top, middle, bottom
    padding: 0,
    lineHeight: 1,
    wrap: 'none', // none, word, char
    ellipsis: false,

    // 변환 관련
    rotation: 0,
    scaleX: 1,
    scaleY: 1,
    opacity: 1,

    // 기타 속성
    visible: true,
    listening: true,
    perfectDrawEnabled: true,
    draggable: false,
  })

  const fonts = ['Arial', 'Helvetica', 'Times New Roman', '굴림', '돋움', '바탕']
  const fontStyles = ['normal', 'bold', 'italic', 'bold italic']
  const textDecorations = ['', 'underline', 'line-through']
  const alignOptions = ['left', 'center', 'right']
  const verticalAlignOptions = ['top', 'middle', 'bottom']
  const wrapOptions = ['none', 'word', 'char']

  const updateTextProp = (prop, value) => {
    setTextProps(prev => ({ ...prev, [prop]: value }))
  }

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', p: 3 }}>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Konva Text Properties Demo
          </Typography>

          <Box sx={{ border: '1px solid #ddd', mb: 3, bgcolor: '#f5f5f5' }}>
            <Stage width={600} height={400}>
              <Layer>
                <Text
                  {...textProps}
                  draggable={true}
                  onDragMove={e => {
                    updateTextProp('x', e.target.x())
                    updateTextProp('y', e.target.y())
                  }}
                />
              </Layer>
            </Stage>
          </Box>

          <Stack spacing={2}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                <Typography>기본 텍스트 속성</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <TextField
                    label="텍스트"
                    multiline
                    rows={2}
                    value={textProps.text}
                    onChange={e => updateTextProp('text', e.target.value)}
                    fullWidth
                  />

                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                      label="X 위치"
                      type="number"
                      value={textProps.x}
                      onChange={e => updateTextProp('x', Number(e.target.value))}
                    />
                    <TextField
                      label="Y 위치"
                      type="number"
                      value={textProps.y}
                      onChange={e => updateTextProp('y', Number(e.target.value))}
                    />
                  </Box>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                <Typography>글꼴 및 스타일</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel>글꼴</InputLabel>
                    <Select
                      value={textProps.fontFamily}
                      label="글꼴"
                      onChange={e => updateTextProp('fontFamily', e.target.value)}
                    >
                      {fonts.map(font => (
                        <MenuItem key={font} value={font}>
                          {font}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel>글꼴 스타일</InputLabel>
                    <Select
                      value={textProps.fontStyle}
                      label="글꼴 스타일"
                      onChange={e => updateTextProp('fontStyle', e.target.value)}
                    >
                      {fontStyles.map(style => (
                        <MenuItem key={style} value={style}>
                          {style}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel>텍스트 장식</InputLabel>
                    <Select
                      value={textProps.textDecoration}
                      label="텍스트 장식"
                      onChange={e => updateTextProp('textDecoration', e.target.value)}
                    >
                      {textDecorations.map(decoration => (
                        <MenuItem key={decoration} value={decoration}>
                          {decoration || 'none'}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Typography gutterBottom>글꼴 크기: {textProps.fontSize}px</Typography>
                  <Slider
                    value={textProps.fontSize}
                    onChange={(_, value) => updateTextProp('fontSize', value)}
                    min={8}
                    max={72}
                    step={1}
                    valueLabelDisplay="auto"
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                <Typography>색상 및 효과</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <TextField
                    label="채우기 색상"
                    type="color"
                    value={textProps.fill}
                    onChange={e => updateTextProp('fill', e.target.value)}
                    fullWidth
                    sx={{ '& input': { height: 50 } }}
                  />

                  <TextField
                    label="외곽선 색상"
                    type="color"
                    value={textProps.stroke}
                    onChange={e => updateTextProp('stroke', e.target.value)}
                    fullWidth
                    sx={{ '& input': { height: 50 } }}
                  />

                  <Typography gutterBottom>외곽선 두께: {textProps.strokeWidth}px</Typography>
                  <Slider
                    value={textProps.strokeWidth}
                    onChange={(_, value) => updateTextProp('strokeWidth', value)}
                    min={0}
                    max={20}
                    step={0.1}
                    valueLabelDisplay="auto"
                  />

                  <TextField
                    label="그림자 색상"
                    type="color"
                    value={textProps.shadowColor}
                    onChange={e => updateTextProp('shadowColor', e.target.value)}
                    fullWidth
                    sx={{ '& input': { height: 50 } }}
                  />

                  <Typography gutterBottom>그림자 흐림: {textProps.shadowBlur}px</Typography>
                  <Slider
                    value={textProps.shadowBlur}
                    onChange={(_, value) => updateTextProp('shadowBlur', value)}
                    min={0}
                    max={30}
                    step={1}
                    valueLabelDisplay="auto"
                  />

                  <Typography gutterBottom>그림자 투명도: {textProps.shadowOpacity}</Typography>
                  <Slider
                    value={textProps.shadowOpacity}
                    onChange={(_, value) => updateTextProp('shadowOpacity', value)}
                    min={0}
                    max={1}
                    step={0.1}
                    valueLabelDisplay="auto"
                  />

                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                      label="그림자 X 오프셋"
                      type="number"
                      value={textProps.shadowOffset.x}
                      onChange={e =>
                        updateTextProp('shadowOffset', {
                          ...textProps.shadowOffset,
                          x: Number(e.target.value),
                        })
                      }
                    />
                    <TextField
                      label="그림자 Y 오프셋"
                      type="number"
                      value={textProps.shadowOffset.y}
                      onChange={e =>
                        updateTextProp('shadowOffset', {
                          ...textProps.shadowOffset,
                          y: Number(e.target.value),
                        })
                      }
                    />
                  </Box>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                <Typography>정렬 및 래핑</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel>가로 정렬</InputLabel>
                    <Select
                      value={textProps.align}
                      label="가로 정렬"
                      onChange={e => updateTextProp('align', e.target.value)}
                    >
                      {alignOptions.map(align => (
                        <MenuItem key={align} value={align}>
                          {align}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel>세로 정렬</InputLabel>
                    <Select
                      value={textProps.verticalAlign}
                      label="세로 정렬"
                      onChange={e => updateTextProp('verticalAlign', e.target.value)}
                    >
                      {verticalAlignOptions.map(align => (
                        <MenuItem key={align} value={align}>
                          {align}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel>텍스트 래핑</InputLabel>
                    <Select
                      value={textProps.wrap}
                      label="텍스트 래핑"
                      onChange={e => updateTextProp('wrap', e.target.value)}
                    >
                      {wrapOptions.map(wrap => (
                        <MenuItem key={wrap} value={wrap}>
                          {wrap}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Typography gutterBottom>패딩: {textProps.padding}px</Typography>
                  <Slider
                    value={textProps.padding}
                    onChange={(_, value) => updateTextProp('padding', value)}
                    min={0}
                    max={50}
                    step={1}
                    valueLabelDisplay="auto"
                  />

                  <Typography gutterBottom>줄 높이: {textProps.lineHeight}</Typography>
                  <Slider
                    value={textProps.lineHeight}
                    onChange={(_, value) => updateTextProp('lineHeight', value)}
                    min={0.5}
                    max={3}
                    step={0.1}
                    valueLabelDisplay="auto"
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                <Typography>변환</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <Typography gutterBottom>회전: {textProps.rotation}°</Typography>
                  <Slider
                    value={textProps.rotation}
                    onChange={(_, value) => updateTextProp('rotation', value)}
                    min={0}
                    max={360}
                    step={1}
                    valueLabelDisplay="auto"
                  />

                  <Typography gutterBottom>가로 스케일: {textProps.scaleX}</Typography>
                  <Slider
                    value={textProps.scaleX}
                    onChange={(_, value) => updateTextProp('scaleX', value)}
                    min={0.1}
                    max={3}
                    step={0.1}
                    valueLabelDisplay="auto"
                  />

                  <Typography gutterBottom>세로 스케일: {textProps.scaleY}</Typography>
                  <Slider
                    value={textProps.scaleY}
                    onChange={(_, value) => updateTextProp('scaleY', value)}
                    min={0.1}
                    max={3}
                    step={0.1}
                    valueLabelDisplay="auto"
                  />

                  <Typography gutterBottom>투명도: {textProps.opacity}</Typography>
                  <Slider
                    value={textProps.opacity}
                    onChange={(_, value) => updateTextProp('opacity', value)}
                    min={0}
                    max={1}
                    step={0.01}
                    valueLabelDisplay="auto"
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                <Typography>기타 설정</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={textProps.visible}
                        onChange={e => updateTextProp('visible', e.target.checked)}
                      />
                    }
                    label="보이기"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={textProps.listening}
                        onChange={e => updateTextProp('listening', e.target.checked)}
                      />
                    }
                    label="이벤트 수신"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={textProps.perfectDrawEnabled}
                        onChange={e => updateTextProp('perfectDrawEnabled', e.target.checked)}
                      />
                    }
                    label="퍼펙트 드로우"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={textProps.draggable}
                        onChange={e => updateTextProp('draggable', e.target.checked)}
                      />
                    }
                    label="드래그 가능"
                  />

                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                      label="오프셋 X"
                      type="number"
                      value={textProps.offsetX}
                      onChange={e => updateTextProp('offsetX', Number(e.target.value))}
                    />
                    <TextField
                      label="오프셋 Y"
                      type="number"
                      value={textProps.offsetY}
                      onChange={e => updateTextProp('offsetY', Number(e.target.value))}
                    />
                  </Box>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={textProps.ellipsis}
                        onChange={e => updateTextProp('ellipsis', e.target.checked)}
                      />
                    }
                    label="말줄임표 표시"
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  )
}

const Test1 = () => {
  const [open, setOpen] = useState(true)

  const handleClose = (e, r) => {
    if (r === 'backdropClick') return

    setOpen(false)
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiModal-backdrop': {
          backgroundColor: '#30303099',
          backdropFilter: 'blur(20px)',
        },
        '& .MuiDialog-paper': {
          minWidth: '1280px',
          minHeight: '808px',
          borderRadius: '16px',
        },
      }}
    >
      <CenterAlignStack sx={{ width: '100%' }}>
        <EditorBar handleClose={handleClose} />
        <Box sx={{ display: 'flex', width: '100%' }}>
          <LayerPart />
          <CanvasPart />
        </Box>
      </CenterAlignStack>
    </Dialog>
  )
}

const EditorBar = ({ handleClose, title = '닥터지 바쿠치올 포어젯 세럼' }) => {
  return (
    <CenterAlignBox
      sx={{
        justifyContent: 'space-between',
        height: '6rem',
        alignItems: 'center',
        width: '100%',
        p: '1.8rem 2rem',
        '& img': {
          width: '2.8rem',
        },

        '& .logo': {
          display: 'flex',
          gap: '0.8rem',
        },

        '& .close': {
          display: 'flex',
          width: '15.348rem',
          justifyContent: 'end',
        },

        '& .title': {
          fontSize: '1.8rem',
          fontWeight: 600,
        },
      }}
    >
      <Box className="logo">
        <img src="/static/images/logo/logo_image.png" />
        <Typography sx={{ fontSize: '2rem', fontWeight: 700 }}>Draph Editor</Typography>
      </Box>

      <Typography className="title">{title}</Typography>

      <Box className="close">
        <IconButton sx={{ p: '0.8rem' }}>
          <CloseCuteIcon />
        </IconButton>
      </Box>
    </CenterAlignBox>
  )
}

const LayerPart = () => {
  const theme = useTheme()
  const [selectedLayer, setSelectedLayer] = useState(null)

  return (
    <Stack
      sx={{
        width: '38.4rem',
        position: 'relative',
        overflow: 'hidden',
        // backgroundColor: 'rgba(41,163,27,0.3)',
        '& .MuiList-root': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },

        '& .MuiListItemButton-root.default': {
          height: '6rem',
          p: '1.2rem 2.4rem 1.2rem 2rem',

          '&:hover': {
            backgroundColor: theme.palette.draph.lighterblue, // 호버 시 배경색 변경
          },

          '& .MuiListItemAvatar-root': {
            mr: '1.2rem',

            '& .MuiAvatar-root': {
              width: '3.6rem',
              height: '3.6rem',
            },
          },

          '& .MuiListItemText-primary': {
            fontWeight: 700,
            fontSize: '1.6rem',
            lineHeight: 'normal',
            mb: '0.2rem',
          },

          '& .MuiListItemText-secondary': {
            fontWeight: 400,
            fontSize: '1.2rem',
            lineHeight: 'normal',
          },
        },
      }}
    >
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: selectedLayer ? -384 : 0 }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <List disablePadding>
          {EDITOR_LISTS.map((l, i) => (
            <ListItemButton
              className="default"
              disableGutters
              disableRipple
              key={i}
              onClick={() => setSelectedLayer(l)}
            >
              <ListItemAvatar>
                <Avatar>
                  <img src={l.avatar} alt={l.title} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={l.title} secondary={l.subtitle} />
              <Box sx={{ transform: 'rotate(-90deg)' }}>
                <DropdownArrowIcon size="xs" color={theme.palette.common.black} />
              </Box>
            </ListItemButton>
          ))}
          <ListItemButton className="default">
            <CenterAlignBox
              sx={{
                backgroundColor: '#989898',
                width: '3.6rem',
                height: '3.6rem',
                borderRadius: '50%',
                alignItems: 'center',
                mr: '1.2rem',
              }}
            >
              <PlusMobileIcon style={{ width: '1.8rem', height: '1.8rem' }} color="white" />
            </CenterAlignBox>
            <Typography sx={{ fontWeight: 700, fontSize: '1.6rem', lineHeight: 'normal' }}>
              구성 요소 추가
            </Typography>
          </ListItemButton>
        </List>
      </motion.div>
      <AnimatePresence>
        {selectedLayer && (
          <motion.div
            initial={{ x: 384 }}
            animate={{ x: 0 }}
            exit={{ x: 384 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <LayerSettings layer={selectedLayer} onBack={() => setSelectedLayer(null)} />
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  )
}

const CanvasPart = () => {
  return (
    <CenterAlignStack
      sx={{
        width: '100%',
        flex: 1,
        backgroundColor: 'rgba(76,13,127,0.3)',
        minHeight: '748px',
      }}
    >
      dd
    </CenterAlignStack>
  )
}

const LayerSettings = ({ layer, onBack }) => {
  const theme = useTheme()
  return (
    <Stack
      sx={{
        '& .MuiList-root': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },

        '& .MuiListItemButton-root.default': {
          height: '6rem',
          p: '1.2rem 2.8rem 1.2rem 2.8rem',

          '&:hover': {
            backgroundColor: theme.palette.draph.lighterblue, // 호버 시 배경색 변경
          },

          '& .MuiListItemAvatar-root': {
            mr: '1.2rem',

            '& .MuiAvatar-root': {
              width: '3.6rem',
              height: '3.6rem',
            },
          },

          '& .MuiListItemText-primary': {
            fontWeight: 700,
            fontSize: '1.6rem',
            lineHeight: 'normal',
            mb: '0.2rem',
          },

          '& .MuiListItemText-secondary': {
            fontWeight: 400,
            fontSize: '1.2rem',
            lineHeight: 'normal',
          },
        },
      }}
    >
      <ListItemButton
        className="default"
        disableGutters
        disableRipple
        onClick={onBack}
        selected={true}
      >
        <Box sx={{ transform: 'rotate(90deg)', ml: '1.2rem' }}>
          <DropdownArrowIcon size="xs" color={theme.palette.common.black} />
        </Box>
        <ListItemAvatar sx={{ ml: '1.2rem' }}>
          <Avatar>
            <img src={layer.avatar} alt={layer.title} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={layer.title} secondary={layer.subtitle} />
      </ListItemButton>

      <CenterAlignBox
        sx={{
          my: '2.4rem',
          gap: '0.8rem',
          '& .box': {
            width: '16.8rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 600,
            alignItems: 'center',
            borderRadius: '4px',
            cursor: 'pointer',

            '& .content': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
              transition: 'transform 0.3s ease-in-out', // transition 추가
            },

            '&.regenerate': {
              color: theme.palette.draph.blue,
              backgroundColor: theme.palette.draph.lighterblue,

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },

            '&.accept': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.draph.blue,

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },
          },
        }}
      >
        <CenterAlignBox className="box regenerate">
          <Box className="content">
            <RegenerateIcon color={theme.palette.draph.blue} />
            재생성
          </Box>
        </CenterAlignBox>

        <CenterAlignBox className="box accept">
          <Box className="content">
            <CircleCheckIcon color={theme.palette.common.white} />
            적용
          </Box>
        </CenterAlignBox>
      </CenterAlignBox>

      {layer.title === '기본 설정 레이어' && <CanvasSetting />}
    </Stack>

    //     {layer.title === '메인 배경' && <BackgroundSettings />}
    //     {layer.title === '텍스트' && <TextSettings />}
  )
}

const CanvasSetting = () => {}

function Test3() {
  const handleTextChange = newText => {
    console.log('Text changed to:', newText)
  }
  const str = `더블클\n릭해서\n편집하세요!`

  return (
    <Stage width={500} height={500} style={{ backgroundColor: 'rosybrown' }}>
      <Layer>
        {/* <EditableText
          text={str}
          x={100}
          y={100}
          fontSize={20}
          fontFamily="Arial"
          fill="black"
          align="left"
          initialWidth={200}
          onChange={newText => console.log('변경된 텍스트:', newText)}
        /> */}

        <EditableText2
          text={str}
          x={100}
          y={100}
          fontSize={20}
          fontFamily="Arial"
          fill="black"
          align="left"
          initialWidth={200}
          onChange={newText => console.log('변경된 텍스트:', newText)}
        />
      </Layer>
    </Stage>
  )
}

const EditableText = ({
  text: initialText,
  x,
  y,
  fontSize = 16,
  width: initialWidth = 200,
  height,
  fill = 'black',
  fontFamily = 'Arial',
  onChange,
  align = 'left',
  padding = 0,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [text, setText] = useState(initialText)
  const [width, setWidth] = useState(initialWidth)
  const shapeRef = useRef(null)
  const transformerRef = useRef(null)
  const textareaRef = useRef(null) // input 대신 textarea 사용

  useEffect(() => {
    setText(initialText)
  }, [initialText])

  useEffect(() => {
    if (isSelected && transformerRef.current && shapeRef.current) {
      transformerRef.current.nodes([shapeRef.current])
      transformerRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  const handleClick = () => {
    setIsSelected(true)
  }

  const handleTransform = () => {
    if (shapeRef.current) {
      const node = shapeRef.current
      const scaleX = node.scaleX()
      const newWidth = Math.max(node.width() * scaleX, 20)
      node.scaleX(1)
      setWidth(newWidth)
    }
  }

  const handleDblClick = () => {
    setIsEditing(true)
    // setIsSelected(false)

    const textNode = shapeRef.current
    const stage = textNode.getStage()
    const stageBox = stage.container().getBoundingClientRect()

    console.log(stageBox)

    const transform = textNode.getAbsoluteTransform()
    const apos = transform.point({ x: 0, y: 0 })

    // textarea 생성
    const textarea = document.createElement('textarea')
    document.body.appendChild(textarea)
    textareaRef.current = textarea

    // textarea 스타일 설정
    textarea.value = text
    textarea.style.position = 'absolute'
    textarea.style.top = `${stageBox.top + apos.y}px`
    textarea.style.left = `${stageBox.left + apos.x}px`
    textarea.style.fontSize = `${fontSize}px`
    textarea.style.fontFamily = fontFamily
    textarea.style.color = fill
    textarea.style.border = '1px solid red'
    textarea.style.padding = `${padding}px`
    textarea.style.margin = '0px'
    textarea.style.background = 'none'
    textarea.style.outline = 'none'
    textarea.style.textAlign = align
    textarea.style.width = `${width}px`
    textarea.style.minHeight = `${height || fontSize * 2}px` // 최소 높이 설정
    textarea.style.lineHeight = `1`
    textarea.style.zIndex = '1000'
    textarea.style.resize = 'none' // 리사이즈 비활성화
    textarea.style.overflow = 'hidden' // 스크롤바 숨기기
    textarea.style.whiteSpace = 'pre-wrap' // 줄바꿈 유지
    textarea.style.wordBreak = 'keep-all'
    textarea.style.lineBreak = 'strict'
    console.log(textarea)

    // 텍스트 높이에 따라 자동으로 textarea 높이 조절
    const adjustHeight = () => {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }

    textarea.addEventListener('input', adjustHeight)
    adjustHeight()

    textarea.focus()
    textarea.setSelectionRange(0, textarea.value.length)

    const handleKeyDown = e => {
      if (e.key === 'Enter' && e.shiftKey) {
        // Shift + Enter로 편집 완료
        e.preventDefault()
        textarea.blur()
      }
      // ESC로 편집 취소
      if (e.key === 'Escape') {
        setIsEditing(false)
        if (textarea.parentNode) {
          document.body.removeChild(textarea)
        }
      }
    }

    const handleBlur = e => {
      handleTextComplete(e)
      textarea.removeEventListener('blur', handleBlur)
      textarea.removeEventListener('keydown', handleKeyDown)
      textarea.removeEventListener('input', adjustHeight)
    }

    textarea.addEventListener('blur', handleBlur)
    textarea.addEventListener('keydown', handleKeyDown)
  }

  const handleTextComplete = e => {
    const newText = e.target.value
    setText(newText)
    setIsEditing(false)
    if (onChange) {
      onChange(newText)
    }
    if (e.target.parentNode) {
      document.body.removeChild(e.target)
    }
  }

  useEffect(() => {
    const checkDeselect = e => {
      const clickedOnEmpty = e.target === e.target.getStage()
      if (clickedOnEmpty) {
        setIsSelected(false)
      }
    }

    const stage = shapeRef.current?.getStage()
    if (stage) {
      stage.on('click', checkDeselect)
      return () => stage.off('click', checkDeselect)
    }
  }, [])

  const textProps = {
    ref: shapeRef,
    text: text,
    x,
    y,
    fontSize,
    fontFamily,
    fill: isEditing ? 'transparent' : fill,
    width,
    height,
    align,
    padding,
    lineHeight: 1,
    draggable: true,
    onClick: handleClick,
    onDblClick: handleDblClick,
    onTransform: handleTransform,
    visible: !isEditing,
    wrap: 'word',
  }

  return (
    <>
      <Text {...textProps} />
      {isSelected && !isEditing && (
        <Transformer
          ref={transformerRef}
          boundBoxFunc={(oldBox, newBox) => {
            newBox.width = Math.max(30, newBox.width)
            return newBox
          }}
          enabledAnchors={['middle-left', 'middle-right']}
        />
      )}
    </>
  )
}

const EditableText2 = ({
  text: initialText,
  x,
  y,
  fontSize = 16,
  width: initialWidth = 200,
  height,
  fill = 'black',
  fontFamily = 'Arial',
  onChange,
  align = 'left',
  padding = 0,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [text, setText] = useState(initialText)
  const [width, setWidth] = useState(initialWidth)
  const shapeRef = useRef(null)
  const transformerRef = useRef(null)
  const textareaRef = useRef(null)

  useEffect(() => {
    setText(initialText)
  }, [initialText])

  useEffect(() => {
    if (isSelected && transformerRef.current && shapeRef.current) {
      transformerRef.current.nodes([shapeRef.current])
      transformerRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  const handleClick = () => {
    setIsSelected(true)
  }

  const handleTransform = () => {
    if (shapeRef.current) {
      const node = shapeRef.current
      const scaleX = node.scaleX()
      const newWidth = Math.max(node.width() * scaleX, 20)
      node.scaleX(1)
      setWidth(newWidth)
    }
  }

  const handleDblClick = () => {
    setIsEditing(true)
    if (textareaRef.current) {
      textareaRef.current.focus()
      textareaRef.current.setSelectionRange(0, textareaRef.current.value.length)
    }
  }

  const handleTextComplete = e => {
    const newText = e.target.value
    setText(newText)
    setIsEditing(false)
    if (onChange) {
      onChange(newText)
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault()
      handleTextComplete(e)
    }
    if (e.key === 'Escape') {
      setIsEditing(false)
    }
  }

  useEffect(() => {
    const checkDeselect = e => {
      const clickedOnEmpty = e.target === e.target.getStage()
      if (clickedOnEmpty) {
        setIsSelected(false)
      }
    }

    const stage = shapeRef.current?.getStage()
    if (stage) {
      stage.on('click', checkDeselect)
      return () => stage.off('click', checkDeselect)
    }
  }, [])

  const textProps = {
    ref: shapeRef,
    text: text,
    x,
    y,
    fontSize,
    fontFamily,
    fill: isEditing ? 'transparent' : fill,
    width,
    height,
    align,
    padding,
    lineHeight: 1,
    draggable: true,
    onClick: handleClick,
    onDblClick: handleDblClick,
    onTransform: handleTransform,
    visible: !isEditing,
    wrap: 'word',
  }

  // HTML 내부의 textarea 위치 계산
  const textareaPosition = () => {
    if (!shapeRef.current) return { display: 'none' }

    const textNode = shapeRef.current
    const transform = textNode.getAbsoluteTransform()
    const apos = transform.point({ x: 0, y: 0 })

    return {
      position: 'absolute',
      top: apos.y + 'px',
      left: apos.x + 'px',
      fontSize: fontSize + 'px',
      fontFamily,
      color: fill,
      border: '1px solid red',
      padding: padding + 'px',
      margin: '0px',
      background: 'none',
      outline: 'none',
      textAlign: align,
      width: width + 'px',
      height: 'auto',
      lineHeight: '1',
      zIndex: '1000',
      resize: 'none',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      wordBreak: 'keep-all',
      lineBreak: 'strict',
      display: isEditing ? 'block' : 'none',
    }
  }

  return (
    <>
      <Text {...textProps} />
      {isSelected && !isEditing && (
        <Transformer
          ref={transformerRef}
          boundBoxFunc={(oldBox, newBox) => {
            newBox.width = Math.max(30, newBox.width)
            return newBox
          }}
          enabledAnchors={['middle-left', 'middle-right']}
        />
      )}
      <Html>
        <textarea
          ref={textareaRef}
          style={textareaPosition()}
          value={text}
          onChange={e => setText(e.target.value)}
          onBlur={handleTextComplete}
          onKeyDown={handleKeyDown}
          onInput={e => {
            e.target.style.height = 'auto'
            e.target.style.height = e.target.scrollHeight + 'px'
          }}
        />
      </Html>
    </>
  )
}

// 워드아트 프리셋 정의
const wordArtPresets = {
  shadowStroke: {
    name: 'Shadow Stroke',
    props: {
      fill: '#DBFF00',
      fontSize: 48,
      fontFamily: 'Roboto',
      fontStyle: 'italic bold',
      shadowColor: '#000000',
      // 그림자를 사용하여 외곽선 효과 생성
      shadowBlur: 0,
      shadowOffset: { x: 0, y: 0 },
      shadowOpacity: 1,
      // 여러 개의 그림자를 적용하기 위한 설정
      shadowForStrokeEnabled: true,
      strokeWidth: 3,
    },
  },
  outerStrokeTwoLayer: {
    name: 'Outer Stroke (Two Layer)',
    // 일반 props 대신, 두 겹으로 쓸 수 있도록 구조를 분리
    twoLayerProps: {
      // 뒷단(외곽선) 역할
      background: {
        fill: '#000000', // 바탕색(또는 stroke만 써도 됨)
        stroke: '#000000', // 검정색으로 두껍게
        strokeWidth: 15, // 스트로크 두께
        fontSize: 60,
        fontFamily: 'Roboto',
        fontStyle: 'bold italic',
        lineJoin: 'round', // 모서리 각지지 않게
      },
      // 앞단(실제 텍스트) 역할
      foreground: {
        fill: '#DBFF00', // 앞단 텍스트 색
        fontSize: 60,
        fontFamily: 'Roboto',
        fontStyle: 'bold italic',
      },
    },
  },
  modernGradient: {
    name: 'Modern Gradient',
    props: {
      fillLinearGradientStartPoint: { x: 0, y: 0 },
      fillLinearGradientEndPoint: { x: 100, y: 0 },
      fillLinearGradientColorStops: [
        0,
        '#2C4DFF',
        0.2,
        '#4235FD',
        0.4,
        '#5526FD',
        0.6,
        '#6B1AFC',
        0.8,
        '#7C0DFC',
        1,
        '#8D00FC',
      ],
      // fillPatternImage:
      //   'https://newsteacher.chosun.com/site/data/img_dir/2024/03/20/2024032003988_0.jpg',
      fontSize: 64,
      fontStyle: 'bold italic',
      fontFamily: 'Arial',
      rotation: 0, // 필요한 경우 각도 조정 가능
    },
  },

  outerShadowStroke: {
    name: 'Outer Shadow Stroke',
    props: {
      fill: '#DBFF00',
      // stroke를 fill과 같게 두고, shadow를 검정으로 주어
      // 실제로는 ‘외곽선만’ 검정처럼 보이게 함
      stroke: '#DBFF00',
      strokeWidth: 3,

      fontSize: 60,
      fontFamily: 'Roboto',
      fontStyle: 'bold italic',

      shadowColor: '#000000',
      // 그림자를 두껍게, 혹은 원하는 만큼 조절
      shadowBlur: 5,
      shadowOffset: { x: 0, y: 0 },
      shadowOpacity: 1,

      // Konva 최신 버전에서 shadow를 stroke에만 적용시키는 옵션
      shadowForStrokeEnabled: true,
    },
  },
  neonOutline: {
    name: 'Neon Outline',
    props: {
      fill: '#DBFF00', // 형광색 채우기
      stroke: '#000000', // 검정색 외곽선
      strokeWidth: 3, // 3px 외곽선
      fontSize: 28, // 2.8rem
      fontFamily: 'Roboto',
      fontStyle: 'italic',
      fontWeight: 900,
      lineHeight: 1.14, // 3.2/2.8 = 1.14
    },
  },
  metallic: {
    name: 'Metallic',
    props: {
      fillLinearGradientStartPoint: { x: 0, y: 0 },
      fillLinearGradientEndPoint: { x: 0, y: 50 },
      fillLinearGradientColorStops: [0, '#e0e0e0', 0.5, '#808080', 1, '#c0c0c0'],
      stroke: '#404040',
      strokeWidth: 1,
      shadowColor: 'black',
      shadowBlur: 2,
      shadowOpacity: 0.3,
      fontSize: 48,
      fontFamily: 'Arial',
    },
  },
  rainbow: {
    name: 'Rainbow',
    props: {
      fillLinearGradientStartPoint: { x: 0, y: 0 },
      fillLinearGradientEndPoint: { x: 600, y: 0 },
      fillLinearGradientColorStops: [
        0,
        '#ff0000',
        0.2,
        '#ffa500',
        0.4,
        '#ffff00',
        0.6,
        '#00ff00',
        0.8,
        '#0000ff',
        1,
        '#800080',
      ],
      fontSize: 48,
      fontFamily: 'Arial',
    },
  },
  fire: {
    name: 'Fire',
    props: {
      fillLinearGradientStartPoint: { x: 0, y: 50 },
      fillLinearGradientEndPoint: { x: 0, y: 0 },
      fillLinearGradientColorStops: [0, '#ff4400', 0.5, '#ff7700', 1, '#ffcc00'],
      shadowColor: '#ff0000',
      shadowBlur: 10,
      shadowOpacity: 0.5,
      fontSize: 48,
      fontFamily: 'Arial',
    },
  },
  outline3D: {
    name: '3D Outline',
    props: {
      fill: '#ffffff',
      stroke: '#000000',
      strokeWidth: 2,
      shadowColor: '#000000',
      shadowBlur: 0,
      shadowOffsetX: 5,
      shadowOffsetY: 5,
      shadowOpacity: 0.5,
      fontSize: 48,
      fontFamily: 'Arial',
    },
  },
  cosmic: {
    name: 'Cosmic',
    props: {
      fillLinearGradientStartPoint: { x: 0, y: 0 },
      fillLinearGradientEndPoint: { x: 600, y: 0 },
      fillLinearGradientColorStops: [0, '#7400B8', 0.5, '#5E60CE', 1, '#4EA8DE'],
      shadowColor: '#7400B8',
      shadowBlur: 15,
      shadowOpacity: 0.5,
      fontSize: 48,
      fontFamily: 'Arial',
    },
  },
  retro: {
    name: 'Retro',
    props: {
      fill: '#FFD700',
      stroke: '#FF6B6B',
      strokeWidth: 3,
      shadowColor: '#4A90E2',
      shadowBlur: 5,
      shadowOffsetX: 3,
      shadowOffsetY: 3,
      fontSize: 48,
      fontFamily: 'Arial',
      fontStyle: 'bold',
    },
  },
}

const WordArtPresets = () => {
  const [text, setText] = useState('Word Art')
  const [selectedPreset, setSelectedPreset] = useState('modernGradient')
  const textRef = useRef(null)
  const [textWidth, setTextWidth] = useState(0)

  useEffect(() => {
    if (textRef.current) {
      // 텍스트의 실제 너비를 구합니다
      setTextWidth(textRef.current.getTextWidth())
    }
  }, [text])

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <Card>
        <CardContent className="p-6">
          {/* Text Input and Preset Selection */}
          <div className="space-y-4 mb-6">
            <div>
              <input
                type="text"
                value={text}
                onChange={e => setText(e.target.value)}
                className="w-full p-2 border rounded"
                placeholder="Enter text..."
              />
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
              {Object.entries(wordArtPresets).map(([key, preset]) => (
                <button
                  key={key}
                  onClick={() => setSelectedPreset(key)}
                  className={`p-2 rounded text-sm ${
                    selectedPreset === key
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 hover:bg-gray-200'
                  }`}
                >
                  {preset.name}
                </button>
              ))}
            </div>
          </div>

          {/* Preview Stage */}
          <div className="border rounded bg-gray-50">
            <Stage width={600} height={200}>
              <Layer>
                {wordArtPresets[selectedPreset].twoLayerProps ? (
                  // 2개 Text 렌더링하는 프리셋이면...
                  <Group
                    // Group을 드래그 가능하게
                    draggable
                    // Group의 위치를 정해줌 (중앙 300,100 근처)
                    x={300}
                    y={100}
                    // 텍스트 길이에 맞춰 Group 자체에 offset 설정할 수도 있음
                    offsetX={
                      text.length *
                      (wordArtPresets[selectedPreset].twoLayerProps.background.fontSize / 4)
                    }
                    offsetY={wordArtPresets[selectedPreset].twoLayerProps.background.fontSize / 2}
                  >
                    {/* 바깥 윤곽 (검정색 등) */}
                    <Text
                      text={text}
                      {...wordArtPresets[selectedPreset].twoLayerProps.background}
                      // Group 내부에서는 offset을 0으로 두고, 대신 Group 자체의 offset을 사용 가능
                      offsetX={0}
                      offsetY={0}
                    />
                    {/* 앞단 텍스트 (주된 색) */}
                    <Text
                      text={text}
                      {...wordArtPresets[selectedPreset].twoLayerProps.foreground}
                      offsetX={0}
                      offsetY={0}
                    />
                  </Group>
                ) : (
                  // 기존 단일 Text 렌더링하는 프리셋이면 그대로
                  <Text
                    text={text}
                    x={300}
                    y={100}
                    {...wordArtPresets[selectedPreset].props}
                    offsetX={text.length * (wordArtPresets[selectedPreset].props.fontSize / 4)}
                    offsetY={wordArtPresets[selectedPreset].props.fontSize / 2}
                    align="center"
                    // 단일 Text라면 이쪽에서 바로 드래그 가능
                    draggable
                  />
                )}
              </Layer>
            </Stage>
          </div>

          {/* Current Preset Info */}
          <div className="mt-4 p-3 bg-gray-50 rounded">
            <p className="text-sm text-gray-600">
              Current Preset: {wordArtPresets[selectedPreset].name}
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
