import { Box, Button, Stack, SwipeableDrawer } from '@mui/material'
import { mobileUploadDrawerOpenAtom } from 'atom/mobileUploadAtom'
import { startTransition, useState } from 'react'
import { useRecoilState } from 'recoil'
import { allowedTypes, iOS } from 'utils/common'
import { AddImage } from './AddImage'
import { MyAsset } from './MyAsset'
import { useUploadAsset } from 'hooks/useUploadAsset'
import { uploadFilesAtom } from 'atoms'
import { useRefineUploadFiles } from 'hooks/useRefineUploadFiles'

export const MobileUploadDrawer = () => {
  const [drawerOpen, setDrawerOpen] = useRecoilState(mobileUploadDrawerOpenAtom)
  const [files, setFiles] = useRecoilState(uploadFilesAtom)

  // AddImage || MyAsset
  const [currentView, setCurrentView] = useState('AddImage')

  const { uploadAssets } = useUploadAsset()

  const onMyAssetClick = () => {
    setCurrentView('MyAsset')
  }

  const handleClose = () => {
    setFiles([])
    setCurrentView('AddImage')
    setDrawerOpen(false)
  }

  const handleAddUpload = async () => {
    await uploadAssets(files)
    setFiles([])
    onMyAssetClick()
  }

  const handleMyAssetButton = () => {
    console.log('set add image')
    setCurrentView('AddImage')
  }

  return (
    <SwipeableDrawer
      open={drawerOpen}
      id="image-swiper-dialjog"
      onClose={handleClose}
      anchor="bottom"
      sx={{
        '& .MuiPaper-root': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .MuiDrawer-paperAnchorBottom': {
          borderTopLeftRadius: '25px',
          borderTopRightRadius: '25px',
        },
      }}
      disableDiscovery={iOS}
      disableSwipeToOpen={true}
    >
      <Box
        sx={{
          width: '4rem',
          height: '0.4rem',
          backgroundColor: '#BCBCBC',
          borderRadius: '20px',
          mt: '1.2rem',
          mb: '1.2rem',
        }}
      ></Box>
      {currentView === 'AddImage' ? <AddImage /> : null}
      {currentView === 'MyAsset' ? <MyAsset /> : null}
      <Stack direction={'row'} py={'28px'} justifyContent={'center'}>
        <Button onClick={handleClose}>닫기</Button>
        {currentView === 'AddImage' ? <AddImageButton handleAddUpload={handleAddUpload} /> : null}
        {currentView === 'MyAsset' ? (
          <MyAssetButton handleMyAssetButton={handleMyAssetButton} />
        ) : null}
      </Stack>
    </SwipeableDrawer>
  )
}

const AddImageButton = ({ handleAddUpload }) => {
  return (
    <Button
      variant="contained"
      sx={{ px: '48px', bgcolor: 'draph.blue' }}
      onClick={handleAddUpload}
    >
      등록하기
    </Button>
  )
}

const MyAssetButton = ({ handleMyAssetButton }) => {
  const refineUploadFiles = useRefineUploadFiles(20)

  return (
    <>
      <input
        type="file"
        id="upload-drawer"
        style={{ display: 'none' }}
        accept={allowedTypes.join(',')}
        onChange={async e => {
          e.preventDefault()
          refineUploadFiles(Array.from(e.target.files))
          handleMyAssetButton()
        }}
        multiple
      />
      <label htmlFor="upload-drawer" style={{ display: 'block', cursor: 'pointer' }}>
        <Button
          sx={{
            bgcolor: 'draph.blue',
            px: '48px',
            width: '100%',
            pointerEvents: 'none',
          }}
          variant="contained"
        >
          추가 등록
        </Button>
      </label>
    </>
  )
}
