import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Slider from '@mui/material/Slider'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

import { atom, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  artworkRegenCountAtom,
  blockDragDropAtom,
  canvasBrushCursorStyleAtom,
  clicksAtom,
  defaultPortfolioAtom,
  endpointAtom,
  indexAtom,
  mannequinLoadingAtom,
  maskImageAtom,
  nukkiSAMModeAtom,
  nukkiSAMOriSizeAtom,
  nukkiSAMSizeAtom,
  portfolioConfigAtom,
  portfolioTypeAtom,
  removeMaskImgAtom,
  segmentLoadingAtom,
  segmentStepAtom,
  selectedMaskImgAtom,
  uploadFilesAtom,
  userAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  isSimpleAtom,
  uploadFilesAndUrlAtom,
  uploadImgHeightAtom,
  uploadImgWidthAtom,
  portfolioDetailAtom,
  configDialogOpenAtom,
} from 'atoms'
import {
  CenterAlignBox,
  CenterAlignStack,
  PortfolioConfigTabs,
  CreateButton,
  SetPortfolioCategory,
  SetPortfolioOutput,
} from 'components'
import { cloneElement, useEffect, useRef, useState } from 'react'

import { PORTFOLIO_CATEGORIES, ARTWORK_REGEN_REQ_MAX, ARTWORK_REGEN_AUTO_COMPLETE } from 'config'
import { apis } from 'apis'
import useConfirm from 'hooks/useConfirm'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import {
  CloseIcon,
  ClothesIcon,
  FoodIcon,
  CosmeticsIcon,
  CarIcon,
  ModelIcon,
  ClipBoardIcon,
  DropdownArrowIcon,
  RefreshIcon,
  CloseCuteIcon,
} from 'theme/icon'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import {
  Collapse,
  Divider,
  IconButton,
  OutlinedInput,
  SvgIcon,
  SwipeableDrawer,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ConfigDisplay } from './RegenerateDialogV2'

const AngleSlider = styled(Slider)(({ theme }) => ({
  width: '1rem',
  padding: '0 1.2rem',
  '& .MuiSlider-rail': {
    color: '#D9D9D9',
    opacity: 1,
    height: 'calc(100% + 0.9rem)',
    bottom: 0,
  },
  '& .MuiSlider-track': {
    opacity: 0,
  },

  '& .MuiSlider-mark': {
    width: '0.8rem',
    height: '0.8rem',
    border: '0.5px solid #909090',
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
  },

  '& .MuiSlider-thumb': {
    width: '1.25rem',
    height: '1.25rem',
    outline: '0.1rem solid #4D80FF99',
    outlineOffset: '.3rem',

    '&:hover': {
      boxShadow: 'none',
    },

    '&.Mui-active': {
      // transition: 'outline-offset 0.2s',
      boxShadow: '2px 2px 20px rgba(77, 128, 255, 0.6)',
    },
  },

  [theme.breakpoints.down('lg')]: {
    padding: '0',
    paddingLeft: '2rem',
    //   marginTop: '2rem',
    //   width: '100%',
    //   '& .MuiSlider-rail': {
    //     height: '1rem',
    //   },

    //   '& .MuiSlider-mark:nth-last-of-type(3)': {
    //     left: 'calc(50% - 0.5rem) !important',
    //   },

    //   '& .MuiSlider-mark:nth-last-of-type(2)': {
    //     left: 'calc(100% - 1rem) !important',
    //   },
  },
}))

const angleImages = [
  '/static/images/angle_example/angle_04_perpen.png',
  '/static/images/angle_example/angle_03_45.png',
  '/static/images/angle_example/angle_02_floor.png',
  '/static/images/angle_example/angle_01_front.png',
]

// const angleValues = ['perpen', '45', 'floor', 'front']
const angleValues = ['perpen', 'diag', 'floor'] // 0, 1, 2
const actionHeight = '10rem'

const smallTitleStyle = {
  fontWeight: 700,
  fontSize: { lg: '1.6rem', xs: '1.3rem' },
  lineHeight: 'normal',
}

const commentStyle = {
  fontSize: { lg: '1.2rem', xs: '1.1rem' },
  fontWeight: 400,
  color: '#595959',
}

export default function UploadConfigDialog() {
  const { t, i18n } = useTranslation()

  const [refImage, setRefImage] = useState([])
  const [sodModified, setSodModified] = useState(false)

  const [open, setOpen] = useRecoilState(configDialogOpenAtom)
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [isSimple, setIsSimple] = useRecoilState(isSimpleAtom) // 기본 선택일 경우 옮겨지는 느낌이 생김
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)
  const [regenCount, setRegenCount] = useRecoilState(artworkRegenCountAtom)
  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const user = useRecoilValue(userAtom)

  useEffect(() => {
    return () => {}
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleApply = () => {
    const editedConfigForbody = { ...portfolioConfig }
    let configOutputlist = [...editedConfigForbody.outputSizeList] ?? []

    if (!portfolioConfig.outputWidth || !portfolioConfig.outputHeight) {
      editedConfigForbody.outputHeight = null
      editedConfigForbody.outputWidth = null
    }

    if (configOutputlist.length !== 0) {
      configOutputlist = configOutputlist.filter(o => o.w !== null && o.h !== null)
    }

    if (configOutputlist.length > 1) {
      editedConfigForbody.simpleBgColorList = [[255, 255, 255]]
    } else {
      editedConfigForbody.simpleBgColorList = PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList
    }

    editedConfigForbody.outputSizeList = configOutputlist

    const body = {
      new_name: user.username,
      theme: portfolioConfig.theme,
      config: { ...editedConfigForbody },
    }

    apis.portfolio.updatePortfolio(defaultPortfolio.id, body).then(response => {
      setPortfolioDetail({ ...portfolioDetail, ...response.data })
    })

    handleClose()
  }

  return (
    <>
      {' '}
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          // disableScrollLock={true}
          sx={{
            overflowX: 'hidden',

            '& .MuiDialog-paper': {
              minWidth: '80rem',
              borderRadius: '10px',
              p: '4.4rem 4rem',
            },
          }}
        >
          <IconButton
            disableFocusRipple
            disableTouchRipple
            disableRipple
            sx={{ position: 'absolute', top: '3.4rem', right: '3.4rem' }}
            onClick={handleClose}
          >
            <CloseCuteIcon sx={{ width: '1.2rem', height: '1.2rem' }} />
          </IconButton>
          <CenterAlignStack sx={{ width: '100%', gap: '2.8rem' }}>
            <ConfigDisplay option={portfolioConfig} />
            <CofigPannel />
          </CenterAlignStack>

          <CenterAlignStack>
            <CreateButton onClick={handleApply} disabled={isSimple === 'ref' && !refImage.length}>
              <Box
                className="content"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  transition: 'transform 0.3s ease-in-out', // transition 추가
                }}
              >
                {t('button.confirm')}
              </Box>
            </CreateButton>
          </CenterAlignStack>
        </Dialog>
      </Desktop>
      <Mobile>{/* <MobileSwipeableDrawer></MobileSwipeableDrawer> */}</Mobile>
    </>
  )
}

const CofigPannel = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const [tab, setTab] = useState(0)

  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  const onClickMain = category => event => {
    setPortfolioConfig({
      ...portfolioConfig,
      category: category.id,
      subCategory: 'auto',
    })
  }

  const tabs = [
    {
      default: t('upload_config.product_category'),
      mobile: t('upload_config.product_category_s'),
    },
    {
      default: t('upload_config.advanced_setting'),
      mobile: t('upload_config.advanced_setting'),
    },
  ]

  return (
    <CenterAlignStack sx={{ width: '100%', alignItems: 'flex-start' }}>
      {
        <PortfolioConfigTabs
          tabs={tabs}
          value={tab}
          onChange={handleChange}
          tabSx={{ fontSize: '1.3rem !important', height: '3.2rem !important' }}
        />
      }

      {tab === 0 && (
        <SetPortfolioCategory
          config={portfolioConfig}
          setConfig={setPortfolioConfig}
          onClickMain={onClickMain}
        />
      )}

      {tab === 1 && <SetPortfolioOutput config={portfolioConfig} setConfig={setPortfolioConfig} />}
    </CenterAlignStack>
  )
}
