import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import dayjs from 'dayjs'
import { AssetsBox } from './AssetBox'

export const AssetSelect = ({ myAssets, selectAsset }) => {
  return (
    <Grid
      container
      spacing={1}
      width={'100%'}
      px={'20px'}
      justifyContent={'center'}
      alignItems={'center'}
      maxHeight={'calc(100vh - 200px)'}
      overflow={'hidden'}
      sx={{ overflowY: 'auto' }}
    >
      {myAssets.map(asset => (
        <Grid item xs={6} key={asset.id}>
          <AssetsBox
            size="auto"
            onClick={() => selectAsset(asset.id)}
            selectMode={true}
            selected={asset.selected}
          >
            <img src={asset.file_url} loading="lazy" />
            <Box sx={{ mt: '8px' }}>
              <Typography
                sx={{
                  fontSize: 9,
                  color: 'black',
                  fontWeight: 500,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {asset.name}
              </Typography>
              <Typography sx={{ fontSize: 7, color: 'black', fontWeight: 500 }}>
                {dayjs(asset.created_at).format('YYYY-MM-DD')}등록
              </Typography>
            </Box>
          </AssetsBox>
        </Grid>
      ))}
    </Grid>
  )
}

