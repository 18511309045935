import {
  BrandIcon,
  CoverLayerIcon,
  DesignIcon,
  IllustrationIcon,
  MainBgIcon,
  ProductFilterIcon,
  TextIcon,
} from 'theme/icon'

import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { getS3ImageSrc } from 'utils/common'

export const useAddInfo = () => {
  const SHAPE_LIST_ADDINFO = {
    background: { avatar: <MainBgIcon />, title: '메인 배경' },
    inpaint: { avatar: <ProductFilterIcon />, title: '상품' },
    logo: { avatar: <BrandIcon />, title: '로고' },
    image: {
      avatar: <DesignIcon />,
      title: '디자인 스티커',
      children: {
        paste: {
          avatar: <ProductFilterIcon />,
          title: '상품 필터',
        },
        illustration: {
          avatar: <IllustrationIcon />,
          title: '일러스트',
        },
        concept: {
          avatar: <DesignIcon />,
          title: '컨셉 이미지',
        },
        reference: {
          avatar: <DesignIcon />,
          title: '레퍼런스',
        },
        inpaint: {
          avatar: <CoverLayerIcon />,
          title: '배경 수정',
        },
      },
    },
    text: {
      avatar: <TextIcon />,
      title: '텍스트',
      children: {
        gen_font: {
          avatar: <CoverLayerIcon />,
          title: 'AI 폰트 디자인',
        },
      },
    },
    inpaint_mask: { avatar: <CoverLayerIcon />, title: '커버 레이어' },
  }

  return { SHAPE_LIST_ADDINFO }
}

export const useImageLoader = ({
  shapes,
  setShapes,
  initshapes,
  setInitshapes,
  selectedLayerId,
}) => {
  // 로드된 이미지 객체를 저장하는 상태
  const [loadedImages, setLoadedImages] = useState({})
  // 각 이미지의 로딩 상태를 추적하는 상태 ('loading' | 'loaded' | 'error')
  const [loadingStatus, setLoadingStatus] = useState({})
  // 이전에 로드된 이미지의 URL을 저장하여 중복 로드 방지
  const previousShapesRef = useRef({})

  // 단일 이미지를 로드하는 함수
  const loadSingleImage = useCallback(
    async shape => {
      if (!shape.result_s3_url) return

      // 필터 적용 여부에 따라 사용할 URL 결정
      const checkURLName = shape.filter_flag ? 'filtered_result_s3_url' : 'result_s3_url'
      const currentURL = shape[checkURLName]
      const previousShape = previousShapesRef.current[shape.id]

      // 이미지가 이미 로드되어 있고 URL이 변경되지 않았다면 스킵
      // if (
      //   previousShape &&
      //   previousShape[checkURLName] === currentURL &&
      //   previousShape.width === shape.width &&
      //   previousShape.height === shape.height &&
      //   loadedImages[shape.id]
      // ) {
      //   return
      // }

      // 로딩 시작 상태 설정
      setLoadingStatus(prev => ({
        ...prev,
        [shape.id]: 'loading',
      }))

      try {
        const imageObj = new window.Image()
        // base64나 파이프라인 URL은 그대로 사용, 그 외에는 S3 URL로 변환
        const imageSrc =
          currentURL.includes('data:image/png;base64') ||
          currentURL.includes('https://upload-pipeline-data')
            ? currentURL
            : getS3ImageSrc(currentURL) + `?w=${Date.now().toString()}`

        imageObj.src = imageSrc
        imageObj.crossOrigin = 'Anonymous'

        // 이미지 로드 완료 대기
        await new Promise((resolve, reject) => {
          imageObj.onload = resolve
          imageObj.onerror = reject
        })

        // 로드된 이미지 저장
        setLoadedImages(prev => ({
          ...prev,
          [shape.id]: imageObj,
        }))

        // 로딩 완료 상태 설정
        setLoadingStatus(prev => ({
          ...prev,
          [shape.id]: 'loaded',
        }))

        // 현재 URL 저장하여 다음 비교 시 사용
        previousShapesRef.current[shape.id] = {
          [checkURLName]: currentURL,
          width: shape.width,
          height: shape.height,
        }

        // // 모든 이미지가 로드되었는지 확인
        // const allImagesLoaded = shapes
        //   .filter(s => s.result_s3_url)
        //   .every(s => loadingStatus[s.id] === 'loaded')

        // if (allImagesLoaded) {
        //   // initshapes 업데이트
        //   setInitshapes(prevShapes =>
        //     prevShapes.map(s => (s.id === shape.id ? { ...s, loadComplete: true } : s))
        //   )

        //   // 현재 선택된 레이어인 경우 shapes도 업데이트
        //   if (selectedLayerId === shape.id) {
        //     setShapes(prevShapes =>
        //       prevShapes.map(s => (s.id === selectedLayerId ? { ...s, loadComplete: true } : s))
        //     )
        //   }
        // }
      } catch (error) {
        console.error(`Failed to load image for shape ${shape.id}:`, error)
        setLoadingStatus(prev => ({
          ...prev,
          [shape.id]: 'error',
        }))
      }
    },
    [loadedImages, loadingStatus, shapes, setShapes, setInitshapes, selectedLayerId]
  )

  // const loadImageDeps = useMemo(
  //   () =>
  //     shapes.map(shape => ({
  //       id: shape.id,
  //       result_s3_url: shape.result_s3_url,
  //       filtered_result_s3_url: shape.filtered_result_s3_url,
  //       filter_flag: shape.filter_flag,
  //       width: shape.width, // width 추가
  //       height: shape.height, // height 추가
  //     })),
  //   [shapes]
  // )

  // shapes가 변경될 때마다 필요한 이미지만 로드
  useEffect(() => {
    const imagesToLoad = shapes.filter(shape => shape.result_s3_url || shape.filtered_result_s3_url)
    imagesToLoad.forEach(shape => loadSingleImage(shape))
  }, [shapes])

  return {
    // 이미지 객체 맵
    loadedImages,
    // 각 이미지의 로딩 상태 맵
    loadingStatus,
    // 모든 이미지가 로드되었는지 여부
    isAllLoaded: shapes
      .filter(shape => shape.result_s3_url)
      .every(shape => loadingStatus[shape.id] === 'loaded'),

    setLoadedImages,
  }
}
