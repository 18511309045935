import { Box, Button, IconButton, Typography, useTheme } from '@mui/material'
import { CenterAlignBox } from './CenterAlignContainer'
import { CloseBlackIcon } from 'theme/icon'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'

export const MessageTool = ({ preText = 'Tip', contentText = '예시' }) => {
  const [showMessageTool, setShowMessageTool] = useState(true)

  return (
    <div>
      <AnimatePresence>
        {showMessageTool && (
          <Message
            // 어떤 props가 있든 여기서 전달
            preText={preText}
            contentText={contentText}
            onClose={() => setShowMessageTool(false)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

const Message = ({ preText = 'Tip', contentText = '예시', onClose }) => {
  return (
    <motion.div
      // 초기 위치, 초기 투명도
      initial={{ opacity: 0, y: -20 }}
      // 마운트된 후 보여지는 애니메이션
      animate={{ opacity: 1, y: 0 }}
      // 언마운트될 때 실행할 애니메이션
      exit={{ opacity: 0, y: 20 }}
      // 옵션(transition duration 설정 등)
      transition={{ duration: 0.3 }}
      style={{ width: 'fit-content' }}
    >
      <Box
        sx={{
          height: '3rem',
          position: 'relative',
          display: 'flex',
          p: '10px 36px 10px 12px',
          gap: '7.5px',
          bgcolor: theme => theme.palette.common.white,
          borderRadius: '5px',
          width: 'fit-content',
          alignItems: 'center',
          '& .pre-box': {
            borderRadius: '5px',
            bgcolor: theme => theme.palette.common.black,
            alignItems: 'center',
            color: theme => theme.palette.common.white,
            fontSize: '1.2rem',
            fontWeight: 800,
            p: '2px 5px',
          },
          '& .typo': {
            fontSize: '1rem',
          },
        }}
      >
        <CenterAlignBox className="pre-box">{preText}</CenterAlignBox>
        <Typography className="typo">{contentText} </Typography>

        <IconButton sx={{ p: 0, position: 'absolute', top: 6, right: 4 }} onClick={onClose}>
          <CloseBlackIcon sx={{ width: '12px', height: '12px' }} />
        </IconButton>
      </Box>{' '}
    </motion.div>
  )
}
