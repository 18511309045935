import { CircularProgress, Menu, MenuItem } from '@mui/material'
import { ORDER_LIMIT_SHAPE_TYPES } from 'config'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Stage, Layer, Rect, Image, Transformer, Text } from 'react-konva'
import { getS3ImageSrc, toRGBA } from 'utils/common'
import TextEditor from './TextEditor'
import { Html } from 'react-konva-utils'
import Konva from 'konva'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  bannerEachFilterAtom,
  bannerSelectedLoadingAtom,
  bannerV2WarningDetectAtom,
  bannerV2WarningDialogAtom,
  bannerWholeFilterAtom,
} from 'atoms'
import { PuffLoader } from 'react-spinners'
import LoadingSpinner from './LoadingSpinner'
import { useAddInfo, useImageLoader } from 'hooks/useBannerV2'
import _ from 'lodash'

export const canvasW = 896
export const canvasH = 571

export default function EditorCanvas({
  piece,
  shapes,
  setShapes,
  selectedId,
  setSelectedId,
  selectedLayerId,
  setSelectedLayerId,
  initshapes,
  setInitshapes,
  stageRef,
  scale,
  setScale,
  addShape,
  updatePieceHandler,
}) {
  const { SHAPE_LIST_ADDINFO } = useAddInfo()

  // URL 을 바로 사용하지 못해서 한번 해석 후 저장
  // const [loadedImages, setLoadedImages] = useState({})
  const [canvasSize, setCanvasSize] = useState({})
  const [contextMenu, setContextMenu] = useState(null)

  const eachFilter = useRecoilValue(bannerEachFilterAtom)

  const resetEachFilter = useResetRecoilState(bannerEachFilterAtom)
  const resetWholeFilter = useResetRecoilState(bannerWholeFilterAtom)

  const { loadedImages, setLoadedImages, loadingStatus, isAllLoaded } = useImageLoader({
    shapes,
    setShapes,
    initshapes,
    setInitshapes,
    selectedLayerId,
  })

  // // 만약에 확정이 안된다면 위치 및 모든 것 초기화 ! useEffect 순서가 중요 하단에서는 피스 들어올 때는 첫 shapes 저장이 필요하기에 !
  // useEffect(() => {
  //   // resetEachFilter()
  //   // resetWholeFilter()
  //   // setShapes(initshapes)
  //   const selectedLayer = shapes.find(s => s.id === selectedLayerId)

  //   console.log('----------------')
  //   console.log('shapes', shapes)
  //   console.log('selectedLayer', selectedLayer)
  //   console.log('----------------')
  // }, [selectedLayerId])

  useEffect(() => {
    if (!selectedLayerId) return
    const selectedLayer = shapes.find(s => s.id === selectedLayerId)

    const isText = selectedLayer?.config.type === 'text' && !selectedLayer?.config.text_gen_flag

    // console.log('🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨')
    // console.log(piece.config.filterConfig)
    // console.log(selectedLayer?.filter_config)
    // console.log(_.isEqual(piece.config.filterConfig, selectedLayer?.filter_config))
    // console.log(selectedLayer?.result_s3_url)
    // console.log(isAllLoaded)
    // console.log(loadedImages[selectedLayer?.id]?.complete)
    // console.log('🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨')
    if (
      // Object.keys(selectedLayer?.filter_config || {}).length &&
      !isText &&
      isAllLoaded &&
      !_.isEqual(piece.config.filterConfig, selectedLayer?.filter_config) &&
      selectedLayer?.result_s3_url
    ) {
      const image = loadedImages[selectedLayer?.id]
      if (image?.complete && image.naturalWidth !== 0) {
        // requestAnimationFrame을 사용하여 다음 렌더링 프레임에서 실행
        requestAnimationFrame(() => {
          updatePieceHandler({
            hasChangeFilter: true,
            addShape: { filter_config: piece.config.filterConfig },
          })
        })
      }
    }
  }, [isAllLoaded, shapes])

  useEffect(() => {
    console.log('stageRef', stageRef)
    if (!piece?.config?.canvasSize && canvasSize?.width && canvasSize?.height) return

    // if (stageRef.current) {
    //   const stage = stageRef.current
    //   stage.getLayer()?.clear()
    // }

    // Calculate scale based on the longer side
    const originalW = piece.config.canvasSize.width
    const originalH = piece.config.canvasSize.height
    const scaleW = canvasW / originalW
    const scaleH = canvasH / originalH
    const newScale = Math.min(scaleW, scaleH)

    setScale(newScale)
    setCanvasSize({ width: originalW * newScale, height: originalH * newScale })

    if (piece?.config?.shapes) {
      const updatedShapes = piece.config.shapes.map(shape => {
        // shape의 type이 SHAPE_LIST_ADDINFO의 키와 일치하는 경우
        if (shape.config.type === 'text') {
          // text_gen_flag true일 때는 children의 gen_font 정보에서 필요한 정보만 추출
          if (shape.config.text_gen_flag) {
            const { avatar, title } = SHAPE_LIST_ADDINFO.text.children.gen_font
            return {
              ...shape,
              avatar,
              title,
            }
          }
          // text_gen_flag false일 때는 text의 기본 정보에서 children을 제외하고 추출
          const { avatar, title } = SHAPE_LIST_ADDINFO.text
          return {
            ...shape,
            avatar,
            title,
          }
        }
        if (SHAPE_LIST_ADDINFO[shape.config.type]) {
          // 기존 shape 객체와 추가 정보를 병합

          if (SHAPE_LIST_ADDINFO[shape.config.type].children) {
            return {
              ...shape,
              ...SHAPE_LIST_ADDINFO[shape.config.type].children[
                shape.config[`${shape.config.type}_type`]
              ],
            }
          }
          return {
            ...shape,
            ...SHAPE_LIST_ADDINFO[shape.config.type],
          }
        }
        // 일치하지 않는 경우 원본 shape 반환
        return shape
      })
      setShapes(updatedShapes)
      setInitshapes(updatedShapes)
      setSelectedLayerId(null)
      setLoadedImages({}) // Clear loaded images when piece changes
    }
  }, [piece?.config])

  useEffect(() => {
    console.log('shapes', shapes)
    console.log('initshapes', initshapes)
    console.log('piece', piece)
  }, [shapes, initshapes, piece])

  // useEffect(() => {
  //   console.log('--------드래곤볼 수집---------')
  //   console.log('1성구, isAllLoaded', isAllLoaded)
  //   console.log('2성구, piece', piece)
  //   console.log(
  //     '3성구, 선택 피스 ',
  //     shapes.find(s => s.id === selectedLayerId)
  //   )
  //   console.log('------드래곤볼 수집 완료------')
  // }, [isAllLoaded, piece, shapes, selectedLayerId])

  // const imageLoadDeps = useMemo(
  //   () =>
  //     shapes.map(shape => ({
  //       id: shape.id,
  //       result_s3_url: shape.result_s3_url,
  //       filtered_result_s3_url: shape.filtered_result_s3_url,
  //       filter_flag: shape.filter_flag,
  //     })),
  //   [shapes]
  // )

  // console.log(imageLoadDeps)

  // useEffect(() => {
  //   console.log('??')
  //   const loadImage = async shape => {
  //     if (!shape.result_s3_url) return

  //     const checkURLName = shape.filter_flag ? 'filtered_result_s3_url' : 'result_s3_url'
  //     try {
  //       const imageObj = new window.Image()
  //       const imageSrc =
  //         shape[checkURLName].includes('data:image/png;base64') ||
  //         shape[checkURLName].includes('https://upload-pipeline-data')
  //           ? shape[checkURLName]
  //           : getS3ImageSrc(shape[checkURLName]) + `?w=${Date.now().toString()}`

  //       imageObj.src = imageSrc
  //       imageObj.crossOrigin = 'Anonymous'
  //       await new Promise((resolve, reject) => {
  //         imageObj.onload = resolve
  //         imageObj.onerror = reject
  //       })

  //       setLoadedImages(prev => ({
  //         ...prev,
  //         [shape.id]: imageObj,
  //       }))
  //     } catch (error) {
  //       console.error(`Failed to load image for shape ${shape.id}:`, error)
  //     }
  //   }

  //   // Load all images for shapes
  //   shapes.forEach(shape => {
  //     if (shape.result_s3_url) {
  //       loadImage(shape)
  //     }
  //   })
  // }, [imageLoadDeps])

  const handleShapeChange = (id, newProps) => {
    setShapes(prevShapes =>
      prevShapes.map(shape =>
        shape.id === id
          ? {
              ...shape,
              x: newProps.x / scale,
              y: newProps.y / scale,
              width: newProps.width / scale,
              height: newProps.height / scale,
            }
          : shape
      )
    )
  }

  // menu !
  const handleContextMenu = (e, shapeId) => {
    e.evt.preventDefault()

    // setSelectedId(shapeId)
    setSelectedLayerId(shapeId)
    setContextMenu({
      mouseX: e.evt.clientX,
      mouseY: e.evt.clientY,
      shapeId,
    })
  }

  const handleCloseContextMenu = () => {
    setContextMenu(null)
    // setSelectedId(null)
    // setSelectedLayerId(null)
  }

  const onDelete = id => {
    setShapes(prevShapes => prevShapes.filter(shape => shape.id !== id))
    setInitshapes(prevShapes => prevShapes.filter(shape => shape.id !== id))
    setSelectedId(null)
    setSelectedLayerId(null)
    handleCloseContextMenu()
  }

  const moveShape = direction => {
    if (!contextMenu) return

    setShapes(prevShapes => {
      const shapeIndex = prevShapes.findIndex(shape => shape.id === contextMenu.shapeId)

      if (shapeIndex === -1) return prevShapes

      const shape = prevShapes[shapeIndex] // 현재 shape
      if (ORDER_LIMIT_SHAPE_TYPES.includes(shape.config.type)) return prevShapes // 배경 아이템은 이동하지 않음

      const newShapes = [...prevShapes]
      const [movedShape] = newShapes.splice(shapeIndex, 1)

      const lastImmutableIndex = newShapes.reduce((lastIndex, shape, index) => {
        return ORDER_LIMIT_SHAPE_TYPES.includes(shape.config.type) ? index : lastIndex
      }, -1)

      let newIndex
      if (direction === 'up' && shapeIndex < newShapes.length) {
        newIndex = Math.max(shapeIndex + 1, lastImmutableIndex + 1)
      } else if (direction === 'down' && shapeIndex > lastImmutableIndex + 1) {
        newIndex = shapeIndex - 1
      } else {
        newIndex = shapeIndex // 변경이 없는 경우
      }

      newShapes.splice(newIndex, 0, movedShape)

      return newShapes
    })
    handleCloseContextMenu()
  }

  return (
    <div>
      <Stage
        width={canvasSize.width}
        height={canvasSize.height}
        onContextMenu={e => {
          // 기본 컨텍스트 메뉴를 막습니다

          e.evt.preventDefault()
        }}
        ref={stageRef}
      >
        <Layer>
          {shapes.map(shape => (
            <Shape
              shapes={shapes}
              key={shape.id}
              shape={shape}
              setShapes={setShapes}
              initshapes={initshapes}
              isSelected={shape.id === selectedLayerId}
              scale={scale}
              setSelectedId={setSelectedId}
              onChange={handleShapeChange}
              canvasWidth={canvasSize?.width}
              canvasHeight={canvasSize?.height}
              loadedImages={loadedImages}
              selectedLayerId={selectedLayerId}
              setSelectedLayerId={setSelectedLayerId}
              onContextMenu={e => {
                if (!ORDER_LIMIT_SHAPE_TYPES.includes(shape.config.type)) {
                  setSelectedId(shape.id)
                  setSelectedLayerId(shape.id)
                  handleContextMenu(e, shape.id)
                }
              }}
              updatePieceHandler={updatePieceHandler}
            />
          ))}
        </Layer>
      </Stage>

      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
        }
      >
        <MenuItem onClick={() => moveShape('up')}>위로 올리기</MenuItem>
        <MenuItem onClick={() => moveShape('down')}>아래로 내리기</MenuItem>
        <MenuItem onClick={() => onDelete(selectedId)}>삭제</MenuItem>
      </Menu>
    </div>
  )
}

export function Shape({
  shapes,
  shape,
  isSelected,
  setShapes,
  initshapes,
  scale,
  setSelectedId,
  onChange,
  canvasWidth,
  canvasHeight,
  loadedImages,
  selectedLayerId,
  setSelectedLayerId,
  onContextMenu,
  updatePieceHandler,
}) {
  const shapeRef = useRef()
  const transformerRef = useRef()
  const textareaRef = useRef(null)
  const [isEditing, setIsEditing] = useState(false)
  const [textareaPosition, setTextareaPosition] = useState(null)

  const [lastWholeFilter, setLastWholeFilter] = useState(null)
  const [lastEachFilter, setLastEachFilter] = useState(null)

  const wholeFilter = useRecoilValue(bannerWholeFilterAtom)
  const eachFilter = useRecoilValue(bannerEachFilterAtom)

  const [selectedLoading, setSelectedLoading] = useRecoilState(bannerSelectedLoadingAtom)

  useEffect(() => {
    console.log('shapeRef', shapeRef)
    console.log('textareaRef', textareaRef)
  }, [textareaRef, shapeRef, shape])
  // 선택이 되면
  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([shapeRef.current])
      transformerRef.current.getLayer().batchDraw()
    }
  }, [isSelected, shape])

  const calculateTextHeight = (text, width) => {
    const tempDiv = document.createElement('div')
    document.body.appendChild(tempDiv)

    tempDiv.style.position = 'absolute'
    tempDiv.style.visibility = 'hidden'
    tempDiv.style.width = `${width}px`
    tempDiv.style.fontSize = `${shape.config.text_size * scale}px`
    tempDiv.style.fontFamily = 'Arial'
    tempDiv.style.lineHeight = shape.config.text_line_height * 1.3 || 1.35
    tempDiv.style.whiteSpace = 'pre-wrap'
    tempDiv.style.wordBreak = 'keep-all'
    tempDiv.style.lineBreak = 'strict'

    tempDiv.textContent = text

    const height = Math.max(tempDiv.scrollHeight, shape.config.text_size * scale * 1.3)
    document.body.removeChild(tempDiv)

    return height
  }

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      adjustTextareaSize()
    }
  }, [isEditing, shape.config.text_list])

  const adjustTextareaSize = () => {
    if (!textareaRef.current) return

    const textarea = textareaRef.current

    // Reset height to get the actual content height
    textarea.style.height = 'auto'

    // Get the scrollHeight which represents the content height
    const newHeight = Math.max(textarea.scrollHeight, shape.config.text_size * scale * 1.2)

    // Set new height
    textarea.style.height = `${newHeight}px`

    // Update shape height to match
    if (newHeight !== shape.height * scale) {
      onChange(shape.id, {
        x: shape.x * scale,
        y: shape.y * scale,
        width: shape.width * scale,
        height: newHeight,
      })
    }

    // Update textarea position state
    setTextareaPosition(prev => ({
      ...prev,
      height: newHeight,
    }))
  }

  const constrainPosition = (pos, shapeWidth, shapeHeight) => {
    return {
      x: Math.max(0, Math.min(pos.x, canvasWidth - shapeWidth)),
      y: Math.max(0, Math.min(pos.y, canvasHeight - shapeHeight)),
    }
  }

  const isLimitedShapes = !(
    shape.config?.type !== 'background' &&
    (shape.config?.type !== 'image' || shape.config?.image_type !== 'paste') &&
    (shape.config?.type !== 'image' ||
      shape.config?.image_type !== 'inpaint' ||
      !shape.result_s3_url)
  )

  const isText = shape.config.type === 'text' && !shape.config.text_gen_flag
  const isImagePaste = shape.config.type === 'image' && shape.config.image_type === 'paste'

  const handleTransform = () => {
    if (!shapeRef.current || !isText) return

    const node = shapeRef.current
    const scaleX = node.scaleX()
    const newWidth = Math.max(node.width() * scaleX, 20)

    node.scaleX(1)

    const newHeight = calculateTextHeight(shape.config.text_list.join('\n'), newWidth)

    // const finalPos = constrainPosition({ x: node.x(), y: node.y() }, newWidth, newHeight)

    setShapes(prevShapes =>
      prevShapes.map(s =>
        s.id === selectedLayerId
          ? {
              ...s,
              width: newWidth / scale,
              height: newHeight / scale,
            }
          : s
      )
    )

    setTextareaPosition(prev => ({
      ...prev,
      width: newWidth,
      height: newHeight,
    }))
  }

  const handleTextChange = e => {
    const newText = e.target.value.split('\n')
    const newHeight = calculateTextHeight(e.target.value, shape.width * scale)

    setShapes(prevShapes =>
      prevShapes.map(s =>
        s.id === selectedLayerId
          ? {
              ...s,
              config: {
                ...s.config,
                text_list: newText,
              },
              height: newHeight / scale,
            }
          : s
      )
    )

    setTextareaPosition(prev => ({
      ...prev,
      height: newHeight,
    }))
  }

  const handleTextareaKeyDown = e => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault()
      setIsEditing(false)
    } else if (e.key === 'Escape') {
      e.preventDefault()
      setIsEditing(false)
    }
  }

  const handleDblClick = () => {
    if (!isText) return

    const textNode = shapeRef.current
    const textPosition = textNode.getAbsolutePosition()

    const newHeight = calculateTextHeight(shape.config.text_list.join('\n'), shape.width * scale)

    setTextareaPosition({
      x: textPosition.x,
      y: textPosition.y,
      width: shape.width * scale,
      height: newHeight,
    })

    setIsEditing(true)
  }

  const ColorBalance = useMemo(() => {
    return function (imageData) {
      const data = imageData.data
      const isPasteImage = shape.config.type !== 'text' && shape.id === selectedLayerId
      const redOffset = wholeFilter.red + (isPasteImage ? eachFilter?.red || 0 : 0)
      const greenOffset = wholeFilter.green + (isPasteImage ? eachFilter?.green || 0 : 0)
      const blueOffset = wholeFilter.blue + (isPasteImage ? eachFilter?.blue || 0 : 0)

      for (let i = 0; i < data.length; i += 4) {
        data[i] = Math.max(0, Math.min(255, data[i] + redOffset))
        data[i + 1] = Math.max(0, Math.min(255, data[i + 1] + greenOffset))
        data[i + 2] = Math.max(0, Math.min(255, data[i + 2] + blueOffset))
      }
    }
  }, [wholeFilter, eachFilter, shape.config.type, shape.config.image_type, shape.result_s3_url])

  const getCombinedFilterValues = useCallback(() => {
    // const isPasteImage = shape.config.type === 'image' && shape.config.image_type === 'paste'
    const isPasteImage = shape.config.type !== 'text' && shape.id === selectedLayerId

    if (!isPasteImage) {
      return wholeFilter
    }

    return {
      brightness: wholeFilter.brightness + (eachFilter?.brightness || 0),
      contrast: wholeFilter.contrast + (eachFilter?.contrast || 0),
      saturation: wholeFilter.saturation + (eachFilter?.saturation || 0),
      noise: wholeFilter.noise + (eachFilter?.noise || 0),
      blurRadius: wholeFilter.blurRadius + (eachFilter?.blurRadius || 0),
      enhance: wholeFilter.enhance + (eachFilter?.enhance || 0),
      red: wholeFilter.red + (eachFilter?.red || 0),
      green: wholeFilter.green + (eachFilter?.green || 0),
      blue: wholeFilter.blue + (eachFilter?.blue || 0),
    }
  }, [wholeFilter, eachFilter, shape.config.type, shape.config.image_type])

  const getActiveFilters = useCallback(
    filterValues => {
      return [
        filterValues.brightness !== 0 && Konva.Filters.Brighten,
        filterValues.contrast !== 0 && Konva.Filters.Contrast,
        filterValues.saturation !== 0 && Konva.Filters.HSL,
        filterValues.noise !== 0 && Konva.Filters.Noise,
        filterValues.blurRadius !== 0 && Konva.Filters.Blur,
        filterValues.enhance !== 0 && Konva.Filters.Enhance,
        (filterValues.red !== 0 || filterValues.green !== 0 || filterValues.blue !== 0) &&
          ColorBalance,
      ].filter(Boolean)
    },
    [ColorBalance]
  )

  useEffect(() => {
    shapeRef.current.cache({
      pixelRatio: 2,
    })
    shapeRef.current.getLayer()?.batchDraw()
  }, [wholeFilter, eachFilter, loadedImages, shape.config.text_list])

  const combinedFilters = getCombinedFilterValues()
  const activeFilters = getActiveFilters(combinedFilters)

  const [bannerV2WarningDetect, setBannerV2WarningDetect] =
    useRecoilState(bannerV2WarningDetectAtom)

  const skipPopup = localStorage.getItem('hideBannerWarningPopup')

  // useEffect(() => {
  //   if (!selectedLayerId) return
  //   // resetEachFilter()
  //   // resetWholeFilter()
  //   // setShapes(initshapes)

  //   showConfirm({
  //     content: '레이어가 변경되었습니다. ',
  //     onConfirm: () => {
  //       const selectedLayer = shapes.find(l => l.id === selectedLayerId)
  //       updatePieceHandler({
  //         hasChangeFilter:
  //           selectedLayer.config.type === 'image' && selectedLayer.config.image_type === 'paste',
  //       })
  //     },
  //   })
  // }, [selectedLayerId])

  const shapeProps = {
    id: shape.id,
    ref: shapeRef,
    x: shape.x * scale,
    y: shape.y * scale,
    width: shape.width * scale,
    height: isText ? 'auto' : shape.height * scale,
    draggable: !isLimitedShapes,
    onClick: () => {
      if (!selectedLayerId) {
        setShapes(initshapes)
        setSelectedLayerId(shape.id)
        return
      }

      if (selectedLayerId !== shape.id) {
        if (!skipPopup) {
          setBannerV2WarningDetect({
            detect: true,
            cancelCallback: () => {
              setShapes(initshapes)
              setSelectedLayerId(shape.id)
            },
          })
        } else {
          setShapes(initshapes)
          setSelectedLayerId(shape.id)
        }
      }
    },

    onDragMove: e => {
      e.cancelBubble = true // 이벤트 버블링 방지
      if (!selectedLayerId) {
        setShapes(initshapes)
        setSelectedLayerId(shape.id)
        return
      }

      if (selectedLayerId !== shape.id) {
        if (!skipPopup) {
          // 드래그 멈춤 !
          e.target.position({
            x: shape.x * scale,
            y: shape.y * scale,
          })

          setBannerV2WarningDetect({
            detect: true,
            cancelCallback: () => {
              setShapes(initshapes)
              setSelectedLayerId(shape.id)
            },
          })
          return
        }
      }

      const node = e.target

      const newPos = shape.something
        ? constrainPosition({ x: node.x(), y: node.y() }, shape.width * scale, shape.height * scale)
        : { x: node.x(), y: node.y() }

      node.position(newPos)
    },

    onDragEnd: e => {
      e.cancelBubble = true // 이벤트 버블링 방지
      if (isLimitedShapes) return
      const node = e.target
      const finalPos = constrainPosition(
        { x: node.x(), y: node.y() },
        shape.width * scale,
        shape.height * scale
      )
      onChange(shape.id, {
        x: finalPos.x,
        y: finalPos.y,
        width: shape.width * scale,
        height: shape.height * scale,
      })
    },
    // onDragStart: () => {
    //   if (shape.id !== selectedLayerId) {
    //     setShapes(initshapes)
    //   }
    // },

    onTransformEnd: e => {
      // 여기 확인
      e.cancelBubble = true // 이벤트 버블링 방지
      if (!shapeRef.current) return

      const node = shapeRef.current
      const scaleX = node.scaleX()
      const scaleY = node.scaleY()

      if (!isText) {
        const newWidth = node.width() * scaleX
        const newHeight = node.height() * scaleY
        const finalPos = constrainPosition({ x: node.x(), y: node.y() }, newWidth, newHeight)

        node.scaleX(1)
        node.scaleY(1)

        onChange(shape.id, {
          x: finalPos.x,
          y: finalPos.y,
          width: newWidth,
          height: newHeight,
        })
      } else {
        const newWidth = node.width() * scaleX
        const newHeight = calculateTextHeight(shape.config.text_list.join('\n'), newWidth)

        node.scaleX(1)
        node.scaleY(1)

        const finalPos = shape.something
          ? constrainPosition({ x: node.x(), y: node.y() }, newWidth, newHeight)
          : { x: node.x(), y: node.y() }

        onChange(shape.id, {
          x: finalPos.x,
          y: finalPos.y,
          width: newWidth,
          height: newHeight,
        })

        setTextareaPosition(prev => ({
          ...prev,
          width: newWidth,
          height: newHeight,
        }))
      }
      // 크기 조절 후 경계 확인
    },
    onContextMenu: e => {
      if (!ORDER_LIMIT_SHAPE_TYPES.includes(shape.config.type)) {
        onContextMenu(e)
      }
    },
    onDblClick: handleDblClick,
    visible: !isEditing,
  }

  const freeTransformer =
    ((shape.config?.type === 'image' && shape.config?.image_type === 'inpaint') ||
      (shape.config?.type === 'text' && shape.config?.text_gen_flag === true)) &&
    !shape?.result_s3_url

  const ShapeComponent = isText ? Text : shape.result_s3_url ? Image : Rect
  const additionalProps = isText
    ? {
        text: shape.config.text_list?.join('\n') || '문구를 수정해주세요.',
        width: textareaPosition?.width || shape.width * scale,
        // height: textareaPosition?.height || shape.height * scale,
        fontSize: shape.config.text_size * scale,
        fontFamily: shape.config.text_font,
        align: shape.config.text_horizontal_align || 'left',
        verticalAlign:
          (shape.config.text_vertical_align === 'center'
            ? 'middle'
            : shape.config.text_vertical_align) || 'top',
        fill: toRGBA(shape.config.text_color) || 'black',
        visible: !isEditing,
        onTransform: handleTransform,
        wrap: 'word',
        padding: 0,
        fontStyle: '600',
        lineHeight: shape.config.text_line_height * 1.3 || 1.35,
        perfectDrawEnabled: true, // 텍스트 렌더링 품질 향상
        shadowForStrokeEnabled: true, // 텍스트 외곽선 품질 향상
        hitStrokeWidth: 0, // 텍스트 주변 히트 영역 최소화
      }
    : shape.result_s3_url
    ? {
        image: loadedImages[shape.id],
        filters: activeFilters,
        brightness: combinedFilters.brightness,
        contrast: combinedFilters.contrast,
        saturation: combinedFilters.saturation,
        noise: combinedFilters.noise,
        blurRadius: combinedFilters.blurRadius,
        enhance: combinedFilters.enhance,
      }
    : { fill: shape.fill }

  const aspectRatio = shape.width / shape.height

  const getBoundBoxFunc = () => {
    if (isText) {
      // Text용 boundBox 함수
      return (oldBox, newBox) => {
        // 최소 너비 제한
        newBox.width = Math.max(25, newBox.width)

        // 캔버스 범위 제한
        newBox.width = Math.min(newBox.width, canvasWidth)

        // 높이는 변경하지 않음
        newBox.height = oldBox.height

        // 위치 제한
        newBox.x = Math.max(0, Math.min(newBox.x, canvasWidth - newBox.width))
        newBox.y = Math.max(0, Math.min(newBox.y, canvasHeight - newBox.height))

        return newBox
      }
    } else {
      // 일반 Shape용 boundBox 함수
      return (oldBox, newBox) => {
        const minWidth = 25
        const minHeight = 25 // 최소 높이도 절대값으로 설정

        // inpaint 이미지이고 result_s3_url이 없을 때는 비율 유지를 하지 않음
        if (!freeTransformer) {
          const currentRatio = newBox.width / newBox.height
          if (currentRatio !== aspectRatio) {
            newBox.height = newBox.width / aspectRatio
          }
        }

        // 최소 크기 제한
        newBox.width = Math.max(minWidth, newBox.width)
        newBox.height = Math.max(minHeight, newBox.height)

        if (!shape.something) {
          // 최대 크기 제한
          newBox.width = Math.min(newBox.width, canvasWidth)
          newBox.height = Math.min(newBox.height, canvasHeight)

          // 위치 제한
          newBox.x = Math.max(0, Math.min(newBox.x, canvasWidth - newBox.width))
          newBox.y = Math.max(0, Math.min(newBox.y, canvasHeight - newBox.height))
        }

        return newBox
      }
    }
  }

  return (
    <>
      {isSelected && selectedLoading ? (
        <Text {...shapeProps} {...additionalProps} text="로딩 중.." />
      ) : (
        <>
          <ShapeComponent {...shapeProps} {...additionalProps} />
        </>
      )}

      {isEditing && isText && (
        <Html>
          <div
            style={{
              top: `${textareaPosition.y}px`,
              left: `${textareaPosition.x}px`,
              width: `${textareaPosition.width}px`,
              height: `${textareaPosition.height}px`,
              display: 'flex',
              position: 'absolute',
              flexDirection: 'column',
              justifyContent:
                shape.config.text_vertical_align === 'top'
                  ? 'flex-start'
                  : shape.config.text_vertical_align === 'bottom'
                  ? 'flex-end'
                  : 'center',
              border: '1px solid #0096ff',
              background: 'rgba(255,255,255,0.2)',
              zIndex: 1000,
            }}
          >
            <textarea
              ref={textareaRef}
              style={{
                width: '100%',
                flex: 1,
                fontSize: `${shape.config.text_size * scale}px`,
                fontFamily: shape.config.text_font,
                color: toRGBA(shape.config.text_color),
                border: 'none',
                padding: '0',
                margin: '0',
                background: 'transparent',
                outline: 'none',
                textAlign: shape.config.text_horizontal_align || 'left',
                lineHeight: shape.config.text_line_height * 1.3 || 1.35,
                resize: 'none',
                overflow: 'hidden',
                whiteSpace: 'pre-wrap',
                wordBreak: 'keep-all',
                lineBreak: 'strict',
                fontWeight: 600,
              }}
              value={shape.config.text_list.join('\n')}
              onChange={handleTextChange}
              onKeyDown={handleTextareaKeyDown}
              onBlur={() => setIsEditing(false)}
              autoFocus
            />
          </div>
        </Html>
      )}

      {isSelected && (
        <Transformer
          ref={transformerRef}
          rotateEnabled={false}
          flipEnabled={false}
          enabledAnchors={
            freeTransformer
              ? [
                  'top-left',
                  'top-center',
                  'top-right',
                  'middle-right',
                  'middle-left',
                  'bottom-left',
                  'bottom-center',
                  'bottom-right',
                ]
              : isText
              ? ['middle-left', 'middle-right']
              : isLimitedShapes
              ? []
              : ['top-left', 'top-right', 'bottom-left', 'bottom-right']
          }
          keepRatio={true}
          boundBoxFunc={getBoundBoxFunc()}
        />
      )}
    </>
  )
}
