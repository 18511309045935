import { MHidden } from 'components/@material-extend'
import {
  Box,
  Typography,
  Checkbox,
  Grid,
  Divider,
  IconButton,
  Stack,
  TextField,
  Pagination,
  Button,
  Select,
  MenuItem,
  ToggleButton,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import {
  CenterAlignBox,
  CenterAlignStack,
  TempLayoutDiv,
  FlexBasis,
  ArtworksImageSwiperSlideDialog,
  RegenerateDialog,
  AddPieceDialog,
  SingleImageDialog,
  CustomPagination,
  SearchInputMobile,
  ScrollToTop,
  CustomTooltip,
  SizeControlDialog,
  ControlSizeDialog,
  GIFDialog,
  CreateBgForNukkiDialog,
  TwoImageSlider,
  NaverProductButton,
  ArtowrkDownloadButton,
  RegenerateButton,
  DeleteSelectedButton,
  IntroduceGIF,
  MoveArtworkToPortfolio,
  OptionViewerDialog,
  triggerGA4DownloadEvent,
  getGA4EventNameByPortfolioType,
  CreatePortfolioWithConfigDialog,
  BannerGeneratedImageLayout,
  BannerControlBoxLayout,
  ImageSwiperDialog2,
  GuestArtworkRow,
} from 'components'

import {
  selector,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil'
import {
  artworkPagingSelector,
  creditPolicyDictSelector,
  isPortfolioOwnerSelector,
  portfolioUploadConfigSelector,
  sortedPiecesArtworkListSelector,
} from 'selector'
import {
  portfolioAtom,
  portfolioDetailAtom,
  portfolioArtworkAtom,
  artworkInViewAtom,
  userAtom,
  confirmDialogAtom,
  artworkViewConfigAtom,
  artworkRegenCountAtom,
  portfolioConfigAtom,
  artworkListsIsFoldedAtom,
  ArtworkListModPieceErrorAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  defaultPortfolioAtom,
  portfolioTypeAtom,
  uploadDialogOpenAtom,
  segmentStepAtom,
  regenDialogOpenAtom,
  blockDragDropAtom,
  retryMannequinAtom,
  languageAtom,
  appMenuAtom,
  currentMenuAtom,
  portfolioLastArtwork,
  artworkPageAtom,
  guestUserAtom,
  pleaseLoginDialogAtom,
} from 'atoms'
import { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react'
import { styled, useTheme, alpha } from '@mui/material/styles'
import {
  GIFstandardDate,
  getPieceType,
  getQueryParam,
  getS3ImageSrc,
  imageUrlToFile,
  isKo,
  padZeros,
  setWatermark,
  sortPieces,
  utcToLocal,
} from 'utils/common'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
  motion,
  useAnimation,
  AnimateSharedLayout,
  AnimatePresence,
  useScroll,
  useMotionValueEvent,
} from 'framer-motion'

import { AiFillEdit as EditIcon, AiFillSave as SaveIcon, AiOutlineConsoleSql } from 'react-icons/ai'
import * as config from 'config'

import { DotLoader, PuffLoader, PulseLoader, SyncLoader } from 'react-spinners'

import { useInView } from 'react-intersection-observer'
import { apis } from 'apis'

import JSZip from 'jszip'
import axios from 'axios'
import _, { isMap, result } from 'lodash'
import { saveAs } from 'file-saver'
import useConfirm from 'hooks/useConfirm'
import {
  DotIcon,
  DownloadIcon,
  TrashCanIcon,
  PencilIcon,
  CircleIcon,
  CheckedCircleIcon,
  CirclePlusIcon,
  SelectSideIconThin,
  ErrorTriangleIcon,
  ArtworkWarningIcon,
  CircleLightbulbIcon,
} from 'theme/icon'
import { PAGE_HEADER_HEIGHT, PAGE_HEADER_HEIGHT_MOBILE } from 'pages/PortfolioDetail'

import { useSnackbar } from 'notistack'
import { Desktop, Mobile, useDesktopMediaQuery, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { MOBILE_PORTFOLIO_WIDTH_LIMIT } from 'pages/Portfolio'
import { RIGHT_CLICK_ALLOW_USER } from 'App.js'
import moment from 'moment'
import { getImagePromise } from './UploadImageDialog'
import { useTranslation } from 'react-i18next'
import { useRefineUploadFiles } from 'hooks/useRefineUploadFiles'
import usePleaseLoginDialog from 'hooks/usePleaseLoginDialog'
import { blogPostsAtom, modelBgRegenDialogAtom, modelFaceRegenDialogAtom } from '../../atoms'
import { ModelBgRegenDialog } from '../../pages/ModelBg'
import { NHNProductButton, NHNProductOutlineButton } from 'pages/NHNCommerce'
import { getDownloadPieceFileName } from '../../utils/common'
import { NO_SO_UPLOAD_NAME, PORTFOLIO_CONFIG_DEFAULT_BACK } from 'config'
import { Cafe24ProductButton } from 'pages/Cafe24'
import { Navigation, Autoplay, Swiper, Manipulation } from 'swiper'
import { ArtworkRow, EmptyRow } from './ArtworkRow'
import { English, Japanese, Korean } from 'hooks/useLanguage'
import { sampleArtwork, SampleArtworkRow } from './artwork_progress_sample'

const pollingIds = []

export const ADDPIECE_SELECTOR = 'addpiecetemp'

const MOBILE_MARGIN_X = '2rem'

const StyledArtworkListBox = styled(Box)(({ theme }) => ({
  width: '100%',
  borderTop: '0.2rem solid',
  // flex: 1,
  // [theme.breakpoints.down('lg')]: {},
}))

export const checkedURLforNoCache = path => {
  if (path?.includes(ADDPIECE_SELECTOR)) {
    return `${path}?w=${Date.now().toString()}`
  }

  return path
}

const StyledHeaderBox = styled(Box)(({ theme }) => ({
  borderBottom: '0.1rem solid',

  width: '100%',
  minHeight: '3rem',
  display: 'flex',
  alignItems: 'center',
  height: '7rem',
  backgroundColor: theme.palette.common.white,

  [theme.breakpoints.down('lg')]: {
    height: '5rem',
    position: 'sticky',
    justifyContent: 'center',
    top: 0,
    zIndex: 3,
  },
}))

const StyledGroupHeaderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '5.6rem',
  background: '#F8F8F8',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'sticky',
  borderBottom: '0.05rem solid #909090',

  top: PAGE_HEADER_HEIGHT,
  // top: '22.1rem', // PAGE_HEADER_HEIGHT + StyledHeaderBox의 height - 0.1

  [theme.breakpoints.down('lg')]: {
    position: 'sticky',
    top: '5rem',
    // minHeight: '7.2rem',
    height: 'unset',
    paddingTop: '1.7rem',
    paddingBottom: '1.7rem',
  },
}))

const GroupHeaderText1 = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 500,
  color: '#525252',
  marginRight: '0.3rem',

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    fontWeight: 500,
    // color: theme.palette.common.gray,
  },
}))

const GroupHeaderText2 = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 700,
  color: theme.palette.common.black,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    fontWeight: 700,
    // color: theme.palette.common.gray,
  },
}))

const DESKTOP_FLEX_BASIS = ['10%', '15%', '50%', '25%']
const MOBILE_FLEX_BASIS = DESKTOP_FLEX_BASIS

const getArtworkArray = o => {
  let arr = []
  Object.entries(o).forEach(([key, v]) => {
    arr = arr.concat(v)
  })
  return arr
}

const notify = (message, options) => {
  if ('Notification' in window && Notification.permission === 'granted' && message) {
    return new Notification(message, options)
  }
}

let scrollTargetId = null

export async function processImages(results, dirName, artworkName = false, pieces = null) {
  const saveAsZip = results.length > 10
  const zip = new JSZip()

  for (const item of results) {
    try {
      const config = item.response ? item.response.config : item.config
      let overrideFileName = null

      if (pieces && config?.url) {
        const url = config.url
        const piece = pieces.filter(p => url.includes(p.path))[0]

        if (piece?.result_filename) {
          overrideFileName = piece.result_filename
        }
      }

      const [w, h] = overrideFileName ? ['_', '_'] : await getImagePromise(config.url)
      const ext = _.last(_.first(config.url.split('/').pop().split('?')).split('.'))

      const fileName =
        overrideFileName?.length > 0
          ? overrideFileName
          : getDownloadPieceFileName({
              prefix: artworkName || item.artworkName,
              idx: item.fileIdx || results.indexOf(item) + 1,
              width: w,
              height: h,
              ext,
            })

      if (saveAsZip) {
        zip.folder(dirName).file(fileName, item.response ? item.response.data : item.data)
      } else {
        saveAs(item.response ? item.response.data : item.data, fileName)
      }
    } catch (error) {
      console.error('Error image:', error)
    }
  }

  if (saveAsZip) {
    try {
      const blob = await zip.generateAsync({ type: 'blob' })
      saveAs(blob, `${dirName}.zip`)
    } catch (error) {
      console.error('Error generating zip:', error)
    }
  }
}

const SHOW_AD_COUNT_PER_PAGE = 3
const ARTWORK_PER_PAGE = 10
const IDX_POOL = [...Array(ARTWORK_PER_PAGE).keys()].filter(i => i > 1 && i < 6)
const IDX_POOL_2 = [...Array(ARTWORK_PER_PAGE).keys()].filter(i => i >= 6)

const getRandomIdx = () => {
  // 한페이지의 아트워크 10개 중 맨 위 아트워크는 무조건 보여주기 때문에, 그 다음 1개까지는 제외하고 나머지 중 뽑는다

  const shuffled1 = IDX_POOL.sort(() => 0.5 - Math.random())
  const shuffled2 = IDX_POOL_2.sort(() => 0.5 - Math.random())

  return [0, shuffled1[0], shuffled2[0]]
}

export function GuestArtworkList({ upload }) {
  const { t, i18n } = useTranslation()
  const scrollRef = useRef(null)
  const location = useLocation()

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const artworkPageList = useRecoilValue(artworkPagingSelector) // [페이지&그룹]으로 묶은 아트워크 목록 [{}, {}, {}, ...]

  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom) // 현재 포트폴리오 정보
  const [user, setUser] = useRecoilState(guestUserAtom)
  const [artworkInView, setArtworkInView] = useRecoilState(artworkInViewAtom) // 현재 보고있는 아트워크 (artworkPageList 중 하나의 object)
  const setArtworkList = useSetRecoilState(portfolioArtworkAtom) // 포트폴리오의 전체 아트워크 목록
  const artworkList = useRecoilValue(sortedPiecesArtworkListSelector)

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)

  const [viewConfig, setViewConfig] = useRecoilState(artworkViewConfigAtom)
  const [textFilter, setTextFilter] = useState('') // 모바일에서만 사용
  const [downloadedFilter, setDownloadedFilter] = useState('all') // 모바일에서만 사용

  const [selectedCommonConfig, setSelectedCommonConfig] = useState({})

  const creditPolicy = useRecoilValue(creditPolicyDictSelector)

  const resetArtworkInView = useResetRecoilState(artworkInViewAtom)
  const resetPortfolio = useResetRecoilState(portfolioAtom)
  const resetArtworkList = useResetRecoilState(portfolioArtworkAtom)
  const resetArtworkListModPieceErrorAtom = useResetRecoilState(ArtworkListModPieceErrorAtom)
  const resetArtworkPage = useResetRecoilState(artworkPageAtom)

  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)

  const currentMenu = useRecoilValue(currentMenuAtom)

  const [page, setPage] = useRecoilState(artworkPageAtom)
  // const [checked, setChecked] = useState([false])
  const [checked, setChecked] = useState([false])

  const [currentConfig, setCurrentConfig] = useState({ display: false })
  const [checkedArtworkPiece, setCheckedArtworkPiece] = useState({})
  const [highestCheckboxState, setHighestCheckboxState] = useState(null)

  const [openImageDialog, setOpenImageDialog] = useState(false)
  const [imageDialogArtwork, setImageDialogArtwork] = useState({})
  const [imageDialogPieceIdx, setImageDialogPieceIdx] = useState(0)

  const queryParams = new URLSearchParams(location.search)

  const portfolioId = upload ? defaultPortfolio.id : useParams().portfolioId

  const [createPortfolioDialogOpen, setCreatePortfolioDialogOpen] = useState(false)

  const pages = queryParams.get('pages') || 1
  const navigate = useNavigate()
  const { showConfirm } = useConfirm()

  const [searchParams, setSearchParams] = useSearchParams()
  const theme = useTheme()

  const { enqueueSnackbar } = useSnackbar()

  const isMobile = useMobileMediaQuery()

  const [intervalId, setIntervalId] = useState()

  const [showAdIdx, setShowAdIdx] = useState(getRandomIdx())
  const [blogPosts, setBlogPosts] = useRecoilState(blogPostsAtom)
  const [pleaseLoginDialog, setPleaseLoginDialog] = useRecoilState(pleaseLoginDialogAtom)

  useEffect(() => {
    setShowAdIdx(getRandomIdx())
  }, [page])

  useEffect(() => {
    return () => {
      clearInterval(intervalId)
    }
  }, [intervalId])
  useEffect(() => {
    setChecked([false])
    setCheckedArtworkPiece({})
  }, [artworkList])

  useEffect(() => {
    const portfolioDetailConfig = portfolioDetail.config
    if (
      (portfolioDetailConfig?.zip_file_total === null ||
        portfolioDetailConfig?.zip_file_total - portfolioDetailConfig?.zip_fail_count >
          portfolioDetailConfig?.zip_artwork_count) &&
      !intervalId
    ) {
      subscribeStatus()
    } else if (
      typeof portfolioDetailConfig?.zip_file_total === 'number' &&
      portfolioDetailConfig?.zip_file_total - portfolioDetailConfig?.zip_fail_count <=
        portfolioDetailConfig?.zip_artwork_count &&
      intervalId
    ) {
      unsubscribeStatus()
    }
  }, [portfolioDetail.config])

  const subscribeStatus = () => {
    const x = setInterval(() => {
      if (!portfolioId) return
      apis.portfolio.getPortfolio(portfolioId).then(response => {
        const d = response.data

        setPortfolioDetail({
          id: portfolioId,
          name: d.name,
          theme: d.theme,
          stat: d.stat,
          user_id: d.user_id,
          config: { ...PORTFOLIO_CONFIG_DEFAULT, name: d.name, ...d.config },
          is_default: d.is_default,
          // 가입시 생성되는 최초의 포트폴리오 대응
        })
      })
      refreshArtworks()
    }, 10 * 1000)
    setIntervalId(x)
  }

  const unsubscribeStatus = () => {
    clearInterval(intervalId)
    setIntervalId(null)
  }

  useEffect(() => {
    setPage(Number(pages))
  }, [pages])

  useEffect(() => {
    const lang = i18n.language
    apis.blog.getArticles(lang).then(r => {
      const blogPosts = r.data
      setBlogPosts(blogPosts)
    })

    return () => {}
  }, [])

  const refreshArtworks = () => {
    apis.guest.getGuestArtworkAll(portfolioType).then(response => {
      setArtworkList(response.data)
    })
  }

  useEffect(() => {
    const v = {
      theme: portfolioDetail.config?.theme,
      category: portfolioDetail.config?.category,
      subCategory: portfolioDetail.config?.subCategory,
      genShadow: portfolioDetail.config?.genShadow ? 'ON' : 'OFF',
      genFace: portfolioDetail.config?.genFace ? 'ON' : 'OFF',

      theme_template: portfolioDetail.config?.theme_template,
      theme_custom: portfolioDetail.config?.theme_custom,
    }
    const boundary = config.OBJECT_BOUNDARY.filter(
      x => x.id === portfolioDetail.config?.objectBoundary
    )
    if (boundary.length > 0) {
      v.objectBoundary = boundary[0].name
    }

    const angle = config.OBJECT_ANGLE.filter(x => x.id === portfolioDetail.config?.objectAngle)
    if (angle.length > 0) {
      v.objectAngle = angle[0].name
    }

    let display = false

    if (artworkInView && Object.values(artworkInView)[0]?.length > 0) {
      const lastConfig = Object.values(artworkInView)[0][0].config
        ? JSON.parse(Object.values(artworkInView)[0][0].config)
        : {}
      // display condition
      display =
        page === 1 &&
        portfolioDetail.config?.category !== undefined &&
        portfolioDetail.config?.name !== '' &&
        (lastConfig.gen_shadow !== portfolioDetail.config?.genShadow ||
          lastConfig.gen_face !== portfolioDetail.config?.genFace ||
          lastConfig.object_category !== portfolioDetail.config?.category ||
          lastConfig.object_sub_category !== portfolioDetail.config?.subCategory ||
          lastConfig.theme_template !== portfolioDetail.config?.theme_template)
    }

    setCurrentConfig({
      display,
      ...v,
    })
  }, [portfolioDetail.config, artworkInView])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, name: textFilter },
    })
  }, [textFilter]) // 모바일

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, downloaded: downloadedFilter },
    })
  }, [downloadedFilter]) // 모바일

  useEffect(() => {
    // refreshArtworks()
    // apis.portfolio.getAllportfolio().then(response => {
    //   setPortfolio(response.data)
    // })

    return () => {
      resetArtworkInView()
      resetPortfolio()
      resetArtworkList()
      resetArtworkListModPieceErrorAtom()
      resetArtworkPage()
    }
  }, [])

  useEffect(() => {
    // 아트워크 조회 부분
    refreshArtworks()
    window.scrollTo(0, 0)
  }, [page])

  useEffect(() => {
    if (artworkList?.length > 0) {
      // 페이지로 넘어와서 artworkList 가 한번 셋되면 사라지므로 주의...

      const aId = searchParams.get('artworkId')
      const aIndex = artworkList.findIndex(a => a.id === aId)
      if (aIndex >= 0) {
        const aPage = Math.floor(aIndex / viewConfig.pageSize) + 1

        scrollTargetId = `a_${aId}`
        setPage(aPage)
      }
      if (aId) {
        searchParams.delete('artworkId')
        setSearchParams(searchParams)
      }
    }
  }, [artworkPageList])

  useEffect(() => {
    if (artworkPageList === null) return
    if (artworkPageList.length > 0) {
      const pageData = artworkPageList[page - 1]
      setArtworkInView(pageData)
    } else {
      setArtworkInView([])
    }
  }, [page, artworkPageList])

  useEffect(() => {
    if (artworkInView) {
      // console.log(artworkInView, 1)
      let l = 0
      for (const [key, value] of Object.entries(artworkInView)) {
        l += value.length
      }
      setChecked(Array(l).fill(false))
      setCheckedArtworkPiece({})
    }
  }, [artworkInView, page])

  useEffect(() => {
    if (scrollTargetId) {
      // querySelector 에 불가능한 특수문자 앞에  \를 넣어줘야한다고 함.

      const anchorIdTranslation = scrollTargetId.replace(/[#[\].():'"~,]/g, '\\$&')
      const anchor = document.querySelector(`#${anchorIdTranslation}`)
      if (anchor) {
        anchor.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })

        scrollTargetId = null
      }
    }
  }, [artworkInView])

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    return credit
  }

  const handlePage = (e, p) => {
    const path = window.location.pathname
    setPage(p)
    navigate(`${path}?pages=${p}`)
  }

  const toggleCheckAll = (event, c) => {
    setChecked(Array(checked.length).fill(c))
    setHighestCheckboxState(c)
  }

  const downloadSelected = () => {
    setPleaseLoginDialog({ open: true, type: 'download' })
    // ----- GA4 event -----
    window.gtag('event', 'non_member_removebg_download', {
      method: 'header',
    })
    // ---------------------
  }

  const deleteSelected = () => {
    const artworkArray = getArtworkArray(artworkInView)
    const selectedIDs = []

    checked.forEach((c, i) => {
      if (c) {
        if (
          [config.ARTWORK_DONE_STATUS, ...config.ARTWORK_ERROR_STATUS].includes(
            artworkArray[i].status
          )
        ) {
          selectedIDs.push(artworkArray[i].id)
        }
      }
    })

    const checkedArtworkPieceCopy = { ...checkedArtworkPiece }

    selectedIDs.forEach((id, idx) => {
      delete checkedArtworkPieceCopy[id]
    })

    const checkedArtworkPieceKeys = Object.keys(checkedArtworkPiece)
    const checkedArtworkPieceValues = Object.values(checkedArtworkPiece)

    const checkedArtworkPieceCopyKeys = Object.keys(checkedArtworkPieceCopy)

    showConfirm({
      title: t('artworkList.image_delete'),
      content: (
        <>
          {checkedArtworkPieceValues.flat().length > 0 ? (
            <p>
              {t('artworkList.image_delete_comment_1')} {checkedArtworkPieceValues.flat().length}
              {t('artworkList.image_delete_comment_2')}
            </p>
          ) : (
            <p>
              <Korean>이미지를 삭제하시겠습니까?</Korean>
              <Japanese>画像を削除しますか？</Japanese>
              <English>Are you sure you want to delete images?</English>
            </p>
          )}
        </>
      ),
      onConfirm: () => {
        // checkedArtworkPieceCopyKeys.forEach((aid, idx) => {
        //   if (checkedArtworkPieceCopy[aid].length === 0) return
        //   const path = []

        //   checkedArtworkPiece[aid].forEach(piece => {
        //     path.push(piece.path)
        //   })

        //   apis.portfolio
        //     .deletePiece(portfolioId, aid, {
        //       data: { paths: [...path] },
        //     })
        //     .then(() => {
        //       refreshArtworks()
        //       setChecked(Array(checked.length).fill(false))
        //       setHighestCheckboxState(false)
        //       setCheckedArtworkPiece({})
        //     })
        // })

        apis.guest.deleteArtworks({ artwork_ids: selectedIDs }).then(() => {
          refreshArtworks()
          setChecked(Array(checked.length).fill(false))
          setHighestCheckboxState(false)
          setCheckedArtworkPiece({})
        })

        // ----- GA4 event -----
        // window.gtag('event', 'image_delete', {
        //   menu: currentMenu?.id,
        //   method: 'header',
        //   count: checkedArtworkPieceValues.flat().length,
        // })
        // ---------------------
      },
      onCancel: () => {},
    })
  }

  const createPortfolioWithCommonConfig = () => {
    // commonConfig 그룹화 기준 (selector.js 의 genGroupKey) 이외의 설정은 디폴트를 넣어줌

    const commonConfig = selectedCommonConfig

    apis.portfolio.getAllportfolio().then(res => {
      const userPortfolioNames = res.data.map(p => p.name)
      const newPortfolioName = genPortfolioName(userPortfolioNames)

      const conf = {
        ...PORTFOLIO_CONFIG_DEFAULT,
        name: newPortfolioName,
        category: commonConfig.object_category,
        subCategory: commonConfig.object_sub_category,
        genFace: commonConfig.gen_face,
        genShadow: commonConfig.gen_shadow,
        theme_template: commonConfig.theme_template,
        theme_custom: commonConfig.theme_custom,
      }

      conf.config = { ...conf }
      apis.portfolio
        .createPortfoilo(conf)
        .then(response => {
          // ----- GA4 event -----
          // TODO
          // window.gtag('event', 'portfolio_new_complete', {
          //   config_object_category: conf.category,
          //   config_object_sub_category: conf.subCategory,
          //   output_size_width: conf.outputWidth,
          //   output_size_height: conf.outputHeight,
          //   image_type: bg,
          //   config_gen_shadow: conf.genShadow.toString(),
          //   config_gen_face: conf.genFace.toString(),
          //   config_object_boundary: conf.objectBoundary,
          //   config_object_angle: conf.objectAngle,
          //   theme: conf.theme_template,
          // })
          // ---------------------

          if (response.data.success) {
            const portfolioID = response.data.id
            setCreatePortfolioDialogOpen(false)
            resetArtworkList()

            // window.location.href = `${window.location.origin}/portfolio/${portfolioID}?pages=1`
            navigate(`/portfolio/${portfolioID}?pages=1`, {
              replace: true,
              state: { openCreateSuccessDialog: true },
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    })
  }

  const genPortfolioName = userPortfolioNames => {
    const today = moment(new Date()).format('YYYY.MM.DD')
    const filterArray = userPortfolioNames?.filter(name => name.includes(today))

    const pattern = /\((\d+)\)/
    const numbers = filterArray?.map(item => {
      const match = item.match(pattern)
      return match ? parseInt(match[1]) : 0
    })

    const maxNumber = Math.max(...numbers)

    if (filterArray.length === 0) {
      return today
    } else {
      return today + ` (${maxNumber + 1})`
    }
  }

  const showDialog = (artwork, pIdx, addInfo) => {
    setImageDialogArtwork({ ...artwork, ...addInfo })
    setImageDialogPieceIdx(pIdx)

    setOpenImageDialog(true)
  }

  return (
    <StyledArtworkListBox ref={scrollRef}>
      <Mobile>
        <div id="scroll-anchor"></div>
      </Mobile>
      <StyledHeaderBox
        // id="scroll-anchor"
        id={isMobile ? undefined : 'scroll-anchor'}
        zIndex={3}
        sx={{
          position: isMobile
            ? 'sticky'
            : checked.every(v => !v) &&
              Object.values(checkedArtworkPiece)
                .flat()
                .every(v => !v)
            ? 'initial'
            : 'sticky',
          top: isMobile
            ? 0
            : checked.every(v => !v) &&
              Object.values(checkedArtworkPiece)
                .flat()
                .every(v => !v)
            ? 'initial'
            : '0rem',
        }}
      >
        <HeaderContent
          checkboxProps={{
            checked: artworkList?.length > 0 && checked.every(v => v),
            onChange: toggleCheckAll,
          }}
          paginationAreaProps={{
            sx: {
              ...(!(
                checked.every(v => !v) &&
                Object.values(checkedArtworkPiece)
                  .flat()
                  .every(v => !v)
              ) && { justifyContent: 'flex-end' }),
            },
          }}
          paginationAreaComponent={
            <>
              {checked.every(v => !v) &&
              Object.values(checkedArtworkPiece)
                .flat()
                .every(v => !v) ? (
                <CustomPagination
                  sx={{
                    '& .MuiPaginationItem-root': {
                      fontSize: '1.6rem',
                    },
                  }}
                  size="small"
                  count={artworkPageList?.length}
                  showFirstButton={false}
                  showLastButton={false}
                  boundaryCount={1}
                  siblingCount={1}
                  page={page}
                  onChange={handlePage}
                />
              ) : (
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <Typography sx={{ fontSize: isKo(i18n) ? '1.6rem' : '1.3rem', fontWeight: 700 }}>
                    {t('artworkList.selected_piece_1')}
                    {Object.values(checkedArtworkPiece).flat().length}{' '}
                    {t('artworkList.selected_piece_2')}
                  </Typography>
                  {isKo(i18n) && (
                    <Divider
                      flexItem
                      orientation="vertical"
                      sx={{ m: 'auto 1.4rem', height: '2rem' }}
                    />
                  )}
                  <Button
                    onClick={downloadSelected}
                    startIcon={<DownloadIcon />}
                    sx={{
                      padding: '6px 8px 6px 0px',
                      fontSize: isKo(i18n) ? '1.6rem' : '1.3rem',
                      fontWeight: 400,
                      color: theme.palette.common.black,
                      ' & .MuiButton-startIcon.MuiButton-iconSizeMedium': { ml: 0 },
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: theme.palette.draph.blue,
                        '& svg path': {
                          transition: 'stroke 0.3s',
                          stroke: theme.palette.draph.blue,
                        },
                      },
                      '& svg path': {
                        transition: 'stroke 0.3s',
                      },
                    }}
                  >
                    {t('button.download')}
                  </Button>
                  <Button
                    onClick={deleteSelected}
                    startIcon={<TrashCanIcon color={theme.palette.common.black} />}
                    sx={{
                      fontSize: isKo(i18n) ? '1.6rem' : '1.3rem',
                      fontWeight: 400,
                      color: theme.palette.common.black,
                      ' & .MuiButton-startIcon.MuiButton-iconSizeMedium': { ml: 0 },
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: theme.palette.common.red,
                        '& svg path': {
                          transition: 'stroke 0.3s',
                          stroke: theme.palette.common.red,
                        },
                      },
                      '& svg path': {
                        transition: 'stroke 0.3s',
                      },
                    }}
                  >
                    {t('button.delete')}
                  </Button>
                </Stack>
              )}
            </>
          }
          mobileFilterProps={{
            value: downloadedFilter,
            onChange: e => setDownloadedFilter(e.target.value),
          }}
          mobileTextFilterProps={{
            value: textFilter,
            onChange: e => setTextFilter(e.target.value),
          }}
          mobileOnClickDownload={downloadSelected}
          mobileOnClickDelete={deleteSelected}
        />
      </StyledHeaderBox>

      <ZipUploadLoading />

      {artworkInView &&
        Object.entries(artworkInView).map(([key, artworks], idx, array) => {
          const commonConfig = artworks[0].config ? JSON.parse(artworks[0].config) : {}

          const redoActionArtworksIdx = artworks
            .filter(a => a.download === 0)
            .filter((a, i) => i % 3 === 2)
            .map(a => a.idxInPage)

          const configText = {
            object_boundary: config.OBJECT_BOUNDARY.filter(
              e => e.id === commonConfig?.object_boundary
            )[0],
            object_angle: config.OBJECT_ANGLE.filter(e => e.id === commonConfig?.object_angle)[0],
          }
          const renderContent = (
            <Box key={key}>
              {artworks.map(artwork => {
                return (
                  <GuestArtworkRow
                    key={artwork.id}
                    idx={artwork.idxInPage} // 페이지의 전체 아트워크 중 몇번째인지
                    initArtwork={artwork}
                    isOwner={isOwner}
                    checked={checked}
                    setChecked={setChecked}
                    checkedArtworkPiece={checkedArtworkPiece}
                    setCheckedArtworkPiece={setCheckedArtworkPiece}
                    currentConfig={currentConfig}
                    highestCheckboxState={highestCheckboxState}
                    setHighestCheckboxState={setHighestCheckboxState}
                    refreshArtworks={refreshArtworks}
                    redoAnimation={redoActionArtworksIdx.includes(artwork.idxInPage)}
                    showDialog={showDialog}
                    setSelectedCommonConfig={setSelectedCommonConfig}
                    setCreatePortfolioDialogOpen={setCreatePortfolioDialogOpen}
                    showAdIdx={showAdIdx}
                  />
                )
              })}
            </Box>
          )
          return renderContent
        })}
      {artworkInView?.length < 1 ? (
        <EmptyRow />
      ) : (
        artworkInView === null && (
          <CenterAlignBox sx={{ width: '100%', py: 3 }}>
            <PuffLoader />
          </CenterAlignBox>
        )
      )}
      <CenterAlignStack sx={{ width: '100%', pt: '5rem', pb: '7rem' }}>
        <CustomPagination
          count={artworkPageList?.length}
          showFirstButton
          showLastButton
          page={page}
          onChange={handlePage}
        />
      </CenterAlignStack>
      <Box id="scroll-anchor-bottom"></Box>

      <CreatePortfolioWithConfigDialog
        open={createPortfolioDialogOpen}
        setOpen={setCreatePortfolioDialogOpen}
        commonConfig={selectedCommonConfig}
        setCommonConfig={setSelectedCommonConfig}
        createPortfolio={createPortfolioWithCommonConfig}
      />
      {openImageDialog && (
        <ImageSwiperDialog2
          open={openImageDialog}
          setOpen={setOpenImageDialog}
          pieceIdx={imageDialogPieceIdx}
          setPieceIdx={setImageDialogPieceIdx}
          artwork={imageDialogArtwork}
          setArtwork={setImageDialogArtwork}
        />
      )}
    </StyledArtworkListBox>
  )
}

function CommonConfigComment({ handleClick }) {
  const { t } = useTranslation()

  useEffect(() => {
    // ----- GA4 event -----
    window.gtag('event', 'suggest_portfolio', { menu: 'imp' }) // 포트폴리오 생성 유도 텍스트 노출
    // ---------------------
  }, [])
  return (
    <>
      <CenterAlignBox
        sx={{
          alignItems: 'center',
          height: '4rem',
          px: '1.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={handleClick}
        >
          <CircleLightbulbIcon />
          <Typography
            sx={{
              ml: '0.5rem',
              color: theme => theme.palette.draph.blue,
              fontSize: '1.5rem',
              fontWeight: 500,
            }}
          >
            {t('artworkList.common_setting_comment')}
          </Typography>
        </Box>
      </CenterAlignBox>
    </>
  )
}

// --------------------

export const DESKTOP_CONTENT_WIDTH = '97.7rem'

function HeaderContent({
  checkboxProps,
  paginationAreaProps,
  paginationAreaComponent,
  mobileFilterProps,
  mobileTextFilterProps,
  mobileOnClickDownload,
  mobileOnClickDelete,
}) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <Desktop>
        <CenterAlignBox sx={{ width: '100%' }}>
          <Stack
            direction="row"
            sx={{
              // TODO 정렬 체크

              width: DESKTOP_CONTENT_WIDTH,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <CenterAlignBox>
              <Checkbox {...checkboxProps}></Checkbox>
            </CenterAlignBox>

            <CenterAlignBox>
              <Typography fontSize="1.8rem" fontWeight={600}>
                {t('artwork_list.gen_img')}
              </Typography>
            </CenterAlignBox>

            <CenterAlignBox id="pagination-area" {...paginationAreaProps}>
              {paginationAreaComponent}
            </CenterAlignBox>
          </Stack>
        </CenterAlignBox>
      </Desktop>

      <Mobile>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: MOBILE_PORTFOLIO_WIDTH_LIMIT,
            zIndex: 99,
            pl: MOBILE_MARGIN_X,
          }}
        >
          {/* <Select
            IconComponent={() => <SelectSideIconThin size="small" />}
            sx={{
              width: '7.6rem',
              height: '2.8rem',
              '& .MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
                width: '5rem',
                pl: '1rem',
                pr: 0,
                pt: '0.7rem',
                pb: '0.7rem',
              },

              fontSize: { lg: '1.5rem', xs: '1.2rem' },
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '0.1rem',
                p: 0,
              },
            }}
            {...mobileFilterProps}
          >
            <MenuItem value="all" sx={{ fontSize: { lg: '1.5rem', xs: '1.2rem' } }}>
              {t('artwork_list.show_all')}
            </MenuItem>
            <MenuItem value="downloaded" sx={{ fontSize: { lg: '1.5rem', xs: '1.2rem' } }}>
              {t('artwork_list.download_true')}
            </MenuItem>
            <MenuItem value="notDownloaded" sx={{ fontSize: { lg: '1.5rem', xs: '1.2rem' } }}>
              {t('artwork_list.download_false')}
            </MenuItem>
          </Select> */}
          <Box></Box>

          <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'end', pr: '' }}>
            <Box sx={{ width: '15rem', mb: '0.2rem' }}>
              <SearchInputMobile {...mobileTextFilterProps} />
            </Box>
            <IconButton
              className="mobile-download-icon"
              onClick={mobileOnClickDownload}
              sx={{
                '&:active': {
                  backgroundColor: 'transparent',
                  color: theme => theme.palette.draph.blue,
                  '& svg path': {
                    transition: 'stroke 0.3s',
                    stroke: theme => theme.palette.draph.blue,
                  },
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: theme => theme.palette.draph.blue,
                  '& svg path': {
                    transition: 'stroke 0.1s',
                    stroke: theme => theme.palette.draph.blue,
                  },
                },
                '& svg path': {
                  stroke: theme => theme.palette.common.gray,
                  transition: 'stroke 0.3s',
                },
              }}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              className="mobile-delete-icon"
              onClick={mobileOnClickDelete}
              sx={{
                mr: '0.5rem',
                '&:active': {
                  backgroundColor: 'transparent',
                  color: theme => theme.palette.common.red,
                  '& svg path': {
                    transition: 'stroke 0.3s',
                    stroke: theme => theme.palette.common.red,
                  },
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                  stroke: theme => theme.palette.common.red,
                  '& svg path': {
                    transition: 'stroke 0.1s',
                    stroke: theme => theme.palette.common.red,
                  },
                },
                '& svg path': {
                  stroke: theme => theme.palette.common.gray,
                  transition: 'stroke 0.3s',
                },
              }}
            >
              <TrashCanIcon color={theme.palette.common.black} />
            </IconButton>
          </Stack>
        </Stack>
      </Mobile>
    </>
  )
}

export function EmptyArtworkList({ message = false, sx = {} }) {
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const lang = useRecoilValue(languageAtom)
  const { showPleaseLoginDialog } = usePleaseLoginDialog()

  return (
    <StyledArtworkListBox>
      <StyledHeaderBox>
        <HeaderContent
          checkboxProps={{
            checked: false,
            onChange: () => {
              showPleaseLoginDialog()
            },
          }}
          paginationAreaComponent={
            <CustomPagination
              sx={{
                '& .MuiPaginationItem-root': {
                  fontSize: '1.6rem',
                },
              }}
              size="small"
              count={1}
              showFirstButton={false}
              showLastButton={false}
              boundaryCount={1}
              siblingCount={1}
              page={1}
              onClick={() => {
                showPleaseLoginDialog()
              }}
            />
          }
          mobileFilterProps={{
            value: downloadedFilter,
            onChange: e => {
              showPleaseLoginDialog()
            },
          }}
          mobileTextFilterProps={{
            value: textFilter,
            onChange: e => setTextFilter(e.target.value),
            onClick: e => {
              showPleaseLoginDialog()
            },
          }}
        />
      </StyledHeaderBox>

      <EmptyRow
        sx={sx}
        messageOverride={
          message || (
            <Typography
              sx={{
                fontSize: { lg: '1.7rem', xs: '1.3rem' },
                '& span': {
                  color: theme => theme.palette.draph.blue,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
            >
              {lang === 'ko' ? (
                <>
                  {t('artwork_list.message.login_and_enjoy_a')}
                  <span
                    onClick={() => {
                      navigate('/login')
                    }}
                  >
                    {t('artwork_list.message.login_and_enjoy_login')}
                  </span>
                  {t('artwork_list.message.login_and_enjoy_b')}
                </>
              ) : (
                <>
                  <span
                    onClick={() => {
                      navigate('/login')
                    }}
                  >
                    {t('artwork_list.message.login_and_enjoy_login')}
                  </span>
                  {t('artwork_list.message.login_and_enjoy_a')}
                  {t('artwork_list.message.login_and_enjoy_b')}
                </>
              )}
            </Typography>
          )
        }
      />
    </StyledArtworkListBox>
  )
}

const ZipUploadLoading = () => {
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const portfolioDetailConfig = portfolioDetail.config

  return (
    (portfolioDetailConfig?.zip_file_total === null ||
      (typeof portfolioDetailConfig?.zip_file_total === 'number' &&
        portfolioDetailConfig?.zip_file_total - portfolioDetailConfig?.zip_fail_count >
          portfolioDetailConfig?.zip_artwork_count)) && (
      <CenterAlignStack
        sx={{ mt: '4rem', width: '100%', pb: '3.5rem', borderBottom: '1px solid #606060' }}
      >
        <CenterAlignStack sx={{}}>
          <DotLoader color="#00E8B9" />
          <Typography sx={{ mt: '2rem', textAlign: 'center' }}>대용량 업로드 중입니다.</Typography>
        </CenterAlignStack>
      </CenterAlignStack>
    )
  )
}
