import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { apis } from 'apis'
import { creditPlansAtom, userAtom, userCreditAtom } from 'atoms'
import { usePricing } from 'hooks/usePricing'
import { forwardRef, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { OpacityMotionDiv, SpanDraphBlue } from './CustomStyle'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { useTranslation } from 'react-i18next'
import { DialogButton } from './DialogButtons'
import { isKo, nullZero, numberCommaFormatter, trasnferDate } from 'utils/common'
import { useNavigate } from 'react-router-dom'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import MobileSwipeableDrawer from './MobileSwipeableDrawer'
import { English, Japanese, Korean } from 'hooks/useLanguage'
import { fontSize } from 'pages/test/Toolbar'
import { set } from 'lodash'
import { CashIcon, CloseIcon, HappyIcon, PresentIcon, PricingIcon } from 'theme/icon'
import { motion } from 'framer-motion'
import { removeUserCookie } from 'utils/cookie'

const style = {
  title: {
    fontSize: { lg: '2.4rem', xs: '1.6rem' },
    fontWeight: { lg: 700, xs: 800 },
    lineHeight: 'normal',
  },
  subtitle1: { fontSize: { lg: '1.4rem', xs: '1.2rem' }, fontWeight: 700, lineHeight: 'normal' },
  subtitle2: { fontSize: { lg: '1.4rem', xs: '1.2rem' } },
}

const Transition = forwardRef(function NoTransition(props, ref) {
  return (
    <Slide direction="left" ref={ref} {...props} in={props.in} timeout={{ enter: 200, exit: 0 }} />
  )
})

export function ShowUserState({
  open,
  setOpen,
  openNextDialog,
  setOpenNextDialog,
  openNextDialogforSNS,
  setOpenNextDialogforSNS,
  currentSubscription,
  userCredit,
  type = 'withdrawal',
}) {
  const { t, i18n } = useTranslation()
  const user = useRecoilValue(userAtom)
  const theme = useTheme()
  const [userStat, setUserStat] = useState(null)
  const { getPlanDisplayName } = usePricing()
  const navigate = useNavigate()

  // ------------------------------------
  const planId = currentSubscription?.plan_id
  const currentSubscriptionEndDate = currentSubscription?.end_date
  const currentSubscriptionStartDate = currentSubscription?.start_date
  const isUnsubscriptionType = type === 'unsubscribe'

  const nextSubscription = currentSubscription?.next_subscription ?? {}
  // ------------------------------------

  useEffect(() => {
    if (open) {
      // ----- GA4 event -----
      window.gtag('event', `${type}_process`, { menu: 'user_status' })
      // ---------------------
    }
  }, [open])

  useEffect(() => {
    apis.user.getUserStat().then(r => {
      const stat = r.data

      setUserStat(stat)
    })
  }, [])

  const handleAction = () => {
    if (!user.login_sns) {
      setOpenNextDialog(true)
    } else {
      setOpenNextDialogforSNS(true)
    }

    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const style = {}

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          keepMounted
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              width: { lg: isKo(i18n) ? '38rem' : '47rem', xs: '320px' },
              minWidth: { lg: '38rem', xs: '430px' },
              minHeight: { lg: '27.7rem', xs: '430px' },
              margin: { lg: '20px', xs: 0 },
              borderRadius: '10px',
            },
          }}
        >
          <DialogContent
            sx={{
              overflowY: 'hidden',
              padding: '2.8rem 2.5rem',
            }}
          >
            <OpacityMotionDiv style={style}>
              <ShowuserStateContent
                planId={planId}
                currentSubscriptionEndDate={currentSubscriptionEndDate}
                currentSubscriptionStartDate={currentSubscriptionStartDate}
                isUnsubscriptionType={isUnsubscriptionType}
                nextSubscription={nextSubscription}
                open={open}
                setOpen={setOpen}
                openNextDialog={openNextDialog}
                setOpenNextDialog={setOpenNextDialog}
                setOpenNextDialogforSNS={setOpenNextDialogforSNS}
                userCredit={userCredit}
              />{' '}
            </OpacityMotionDiv>
          </DialogContent>
        </Dialog>
      </Desktop>
      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={handleClose}
        >
          <CenterAlignStack sx={{ mt: '0.8rem', mb: '2.2rem', px: '2rem' }}>
            <ShowuserStateContent
              planId={planId}
              currentSubscriptionEndDate={currentSubscriptionEndDate}
              currentSubscriptionStartDate={currentSubscriptionStartDate}
              isUnsubscriptionType={isUnsubscriptionType}
              nextSubscription={nextSubscription}
              open={open}
              setOpen={setOpen}
              openNextDialog={openNextDialog}
              setOpenNextDialog={setOpenNextDialog}
              setOpenNextDialogforSNS={setOpenNextDialogforSNS}
              userCredit={userCredit}
            />
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

export function LeaveNoticeDialog({
  open,
  setOpen,
  openNextDialog,
  setOpenNextDialog,
  currentSubscription,
  userCredit,
  type = 'withdrawal',
}) {
  const planId = currentSubscription?.plan_id
  const currentSubscriptionEndDate = currentSubscription?.end_date
  const nextSubscription = currentSubscription?.next_subscription ?? {}

  const isSubscription = Boolean(planId && !currentSubscriptionEndDate)
  const hasCredit = Boolean(userCredit > 0 ?? false)

  const isUnsubscriptionType = type === 'unsubscribe'

  const [openSubscriptionWarningDialog, setOpenSubscriptionWarningDialog] = useState(false)

  // useEffect(() => {
  // const isSubscription = Boolean(currentSubscription?.plan_id ?? false)
  // const hasCredit = Boolean(userCredit ?? false)

  //   if (!isSubscription && hasCredit) {
  //     return setPage(1)
  //   } else if (isSubscription && !hasCredit) {
  //     return setPage(2)
  //   } else {
  //     setPage(0)
  //   }
  // }, [currentSubscription, userCredit])
  if (!openSubscriptionWarningDialog) {
    return (
      <BenefitNotice
        isSubscription={isSubscription}
        open={open}
        setOpen={setOpen}
        setOpenNextDialog={setOpenNextDialog}
        userCredit={userCredit}
        planId={planId}
        openSubscriptionWarningDialog={openSubscriptionWarningDialog}
        setOpenSubscriptionWarningDialog={setOpenSubscriptionWarningDialog}
        hasCredit={hasCredit}
        currentSubscription={currentSubscription}
        type={type}
      />
    )
  } else {
    return isUnsubscriptionType ? (
      <UnsubscriptionWarningDialog
        open={openSubscriptionWarningDialog}
        setOpen={setOpenSubscriptionWarningDialog}
        setOpenNextDialog={setOpenNextDialog}
        planId={planId}
        type={type}
      />
    ) : (
      <SubscriptionWarningDialog
        open={openSubscriptionWarningDialog}
        setOpen={setOpenSubscriptionWarningDialog}
        planId={planId}
        type={type}
      />
    )
  }
}

const BenefitNotice = ({
  open,
  setOpen,
  isSubscription,
  setOpenNextDialog,
  openSubscriptionWarningDialog,
  setOpenSubscriptionWarningDialog,
  userCredit,
  currentSubscription,
  planId,
  hasCredit,
  type,
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const isUnsubscriptionType = type === 'unsubscribe'
  const isRefundable = currentSubscription.refundable

  useEffect(() => {
    if (open) {
      // ----- GA4 event -----
      window.gtag('event', `${type}_process`, {
        menu: isUnsubscriptionType ? 'unsubscribe_reservation' : 'draph_delete_id',
      })
      // ---------------------
    }
  }, [open])

  const handleClose = () => {
    setOpen(false)
  }

  const handleAction = () => {
    if (isUnsubscriptionType ? isRefundable : isSubscription) {
      setOpenSubscriptionWarningDialog(true)
    } else {
      setOpenNextDialog(true)
    }
    setOpen(false)
  }
  return (
    <>
      <>
        <Desktop>
          <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            sx={{
              '& .MuiPaper-root.MuiDialog-paper': {
                width: { lg: 'auto', xs: '320px' },
                minWidth: { lg: '40rem', xs: '430px' },
                minHeight: { lg: '27.7rem', xs: '430px' },
                margin: { lg: '20px', xs: 0 },
                borderRadius: '10px',
                maxWidth: '80rem',
              },
            }}
          >
            <DialogContent
              sx={{
                overflowY: 'hidden',
                padding: { lg: '2.8rem 3rem', xs: '33px 0 25px 0' },
              }}
            >
              <OpacityMotionDiv>
                <BenefitContent
                  isSubscription={isSubscription}
                  open={open}
                  setOpen={setOpen}
                  setOpenNextDialog={setOpenNextDialog}
                  userCredit={userCredit}
                  planId={planId}
                  openSubscriptionWarningDialog={openSubscriptionWarningDialog}
                  setOpenSubscriptionWarningDialog={setOpenSubscriptionWarningDialog}
                  hasCredit={hasCredit}
                  currentSubscription={currentSubscription}
                  type={type}
                />
              </OpacityMotionDiv>
            </DialogContent>
          </Dialog>
        </Desktop>

        <Mobile>
          <MobileSwipeableDrawer
            open={open}
            onOpen={() => {
              return open
            }}
            onClose={handleClose}
          >
            <CenterAlignStack sx={{ mt: '0.8rem', mb: '2.2rem', px: '2rem' }}>
              <BenefitContent
                isSubscription={isSubscription}
                open={open}
                setOpen={setOpen}
                setOpenNextDialog={setOpenNextDialog}
                userCredit={userCredit}
                planId={planId}
                openSubscriptionWarningDialog={openSubscriptionWarningDialog}
                setOpenSubscriptionWarningDialog={setOpenSubscriptionWarningDialog}
                hasCredit={hasCredit}
                currentSubscription={currentSubscription}
                type={type}
              />
            </CenterAlignStack>
          </MobileSwipeableDrawer>
        </Mobile>
      </>
    </>
  )
}

// const BenefitNotice = ({
//   open,
//   setOpen,
//   isSubscription,
//   setOpenNextDialog,
//   userCredit,
//   planId,
// }) => {}

const SubscriptionWarningDialog = ({ open, setOpen, planId }) => {
  const theme = useTheme()
  const { getPlanDisplayName, getPlanPrice, getCurrencySymbol } = usePricing()
  const creditPlans = useRecoilValue(creditPlansAtom)
  const navigate = useNavigate()

  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (open) {
      // ----- GA4 event -----
      window.gtag('event', 'withdrawal_process', { menu: 'ing_subscription' })
      // ---------------------
    }
  }, [open])

  const handleClose = () => {
    setOpen(false)
  }

  const handleAction = () => {
    setOpen(false)
    navigate('/user/mypage')
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              width: { lg: 'auto', xs: '320px' },
              minWidth: { lg: '40rem', xs: '430px' },
              minHeight: { lg: '27.7rem', xs: '430px' },
              margin: { lg: '20px', xs: 0 },
              borderRadius: '10px',
              maxWidth: 'none',
            },
          }}
        >
          <DialogContent
            sx={{
              overflowY: 'hidden',
              padding: { lg: '2.8rem 3rem', xs: '33px 0 25px 0' },
            }}
          >
            <OpacityMotionDiv>
              <SubscriptionWarningContent
                planId={planId}
                handleAction={handleAction}
                setOpen={setOpen}
              />
            </OpacityMotionDiv>
          </DialogContent>
        </Dialog>
      </Desktop>

      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={handleClose}
        >
          <CenterAlignStack sx={{ mt: '0.8rem', mb: '2.2rem' }}>
            <SubscriptionWarningContent
              planId={planId}
              handleAction={handleAction}
              setOpen={setOpen}
            />
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

const UnsubscriptionWarningDialog = ({ open, setOpen, planId, setOpenNextDialog }) => {
  const theme = useTheme()
  const { getPlanDisplayName, getPlanPrice, getCurrencySymbol } = usePricing()
  const creditPlans = useRecoilValue(creditPlansAtom)
  const navigate = useNavigate()

  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (open) {
      // ----- GA4 event -----
      window.gtag('event', 'unsubscribe_process', { menu: 'refund_amount' })
      // ---------------------
    }
  }, [open])

  const handleClose = () => {
    setOpen(false)
  }

  const handleAction = () => {
    setOpenNextDialog(true)
    setOpen(false)
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              width: { lg: 'auto', xs: '320px' },
              minWidth: { lg: '40rem', xs: '430px' },
              minHeight: { lg: '27.7rem', xs: '430px' },
              margin: { lg: '20px', xs: 0 },
              borderRadius: '10px',
            },
          }}
        >
          <DialogContent
            sx={{
              overflowY: 'hidden',
              padding: { lg: '2.8rem 3rem', xs: '33px 0 25px 0' },
            }}
          >
            <OpacityMotionDiv>
              <UnsubscriptionWarningContent
                planId={planId}
                handleAction={handleAction}
                setOpen={setOpen}
              />
            </OpacityMotionDiv>
          </DialogContent>
        </Dialog>
      </Desktop>

      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={handleClose}
        >
          <CenterAlignStack sx={{ mt: '0.8rem', mb: '2.2rem' }}>
            <UnsubscriptionWarningContent
              planId={planId}
              handleAction={handleAction}
              setOpen={setOpen}
            />
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

export function SelectReasonDialog({
  options,
  open,
  setOpen,
  selectedOption,
  setSelectedOption,
  openNextDialog,
  setOpenNextDialog,
  etcText,
  setEtcText,
  refreshSubscription = () => {},
  currentSubscription,
  type = 'withdrawal',
}) {
  const resetUser = useResetRecoilState(userAtom)
  const theme = useTheme()
  const isUnsubscriptionType = type === 'unsubscribe'
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (open) {
      // ----- GA4 event -----
      window.gtag('event', `${type}_process`, {
        menu: isUnsubscriptionType ? 'unsubscribe_survey' : 'delete_id_survey',
      })
      // ---------------------
    }
  }, [open])

  // const handleAction = () => {
  //   setOpen(false)
  //   setOpenNextDialog(true)

  //   // ----- GA4 event -----
  //   const txt = options.filter(o => {
  //     return o.id === selectedOption
  //   })[0]?.label
  //   window.gtag('event', 'withdrawal_process', { method: selectedOption === 999 ? etcText : txt })
  //   // ---------------------
  // }

  const handleAction = async () => {
    const reason =
      selectedOption === 999 ? etcText : options.filter(o => o.id === selectedOption)[0].label

    window.gtag('event', `${type}_survey`, { method: reason })

    if (isUnsubscriptionType) {
      await apis.user.sendCancelSubscriptionFeedback({ message: reason })

      await apis.user
        .cancelSubscription({ current_subscription_id: currentSubscription?.id })
        .then(() => {
          refreshSubscription()
          setOpen(false)
          setOpenNextDialog(true)

          // ----- GA4 event -----
          // window.gtag('event', 'unsubscribe', {})
          // ---------------------
        })
    } else {
      await apis.user.leaveDraph().then(response => {
        setOpen(false)
        setOpenNextDialog(true)
      })
    }
  }

  useEffect(() => {
    setSelectedOption('')
    setEtcText('')
  }, [open])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              width: { lg: 'auto', xs: '320px' },
              minWidth: { lg: '40rem', xs: '430px' },
              minHeight: { lg: '27.7rem', xs: '430px' },
              margin: { lg: '20px', xs: 0 },
              borderRadius: '10px',
              maxWidth: '80rem',
            },
          }}
        >
          <DialogContent
            sx={{
              overflowY: 'hidden',
              padding: { lg: '2.8rem 3rem', xs: '33px 0 25px 0' },
            }}
          >
            <OpacityMotionDiv>
              <SelectReasonContent
                options={options}
                isUnsubscriptionType={isUnsubscriptionType}
                handleAction={handleAction}
                handleClose={handleClose}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                etcText={etcText}
                setEtcText={setEtcText}
              />
            </OpacityMotionDiv>
          </DialogContent>
        </Dialog>
      </Desktop>
      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={handleClose}
        >
          <CenterAlignStack sx={{ mt: '0.8rem', mb: '2.2rem' }}>
            <SelectReasonContent
              options={options}
              isUnsubscriptionType={isUnsubscriptionType}
              handleAction={handleAction}
              handleClose={handleClose}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              etcText={etcText}
              setEtcText={setEtcText}
            />
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

export function LeaveDialog({
  options,
  open,
  setOpen,
  selectedOption,
  setSelectedOption,
  etcText,
  setEtcText,

  type = 'withdrawal',
}) {
  const { t, i18n } = useTranslation()
  const user = useRecoilValue(userAtom)
  const [userStat, setUserStat] = useState(null)
  const resetUser = useResetRecoilState(userAtom)
  const theme = useTheme()
  const isUnsubscriptionType = type === 'unsubscribe'

  useEffect(() => {
    if (open) {
      // ----- GA4 event -----
      window.gtag('event', type, {})
      // ---------------------
    }
  }, [open])

  const handleAction = () => {
    if (isUnsubscriptionType) {
      setOpen(false)
    } else {
      removeUserCookie()
      resetUser()
      window.location.reload()
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const isUserIdlong = Boolean(user.username.length > 5)

  useEffect(() => {
    apis.user.getUserStat().then(r => {
      const stat = r.data

      setUserStat(stat)
    })
  }, [])

  // const handleAction = () => {
  //   const reason =
  //     selectedOption === 999 ? etcText : options.filter(o => o.id === selectedOption)[0].label

  //   apis.user.leaveDraph().then(response => {
  //     if (response.data.success) {
  //       alert(t('profile.leave_complete'))

  //       // ----- GA4 event -----
  //       window.gtag('event', 'withdrawal', {})
  //       // ---------------------

  //       removeUserCookie()
  //       resetUser()
  //       window.location.reload()
  //     }
  //   })
  // }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              width: { lg: 'auto', xs: '320px' },
              minWidth: { lg: '44rem', xs: '430px' },
              // minHeight: { lg: '27.7rem', xs: '430px' },
              margin: { lg: '20px', xs: 0 },
              borderRadius: '10px',
              maxWidth: '80rem',
            },
          }}
        >
          <DialogContent
            sx={{
              overflowY: 'hidden',
              padding: { lg: '2.8rem 3rem', xs: '33px 0 25px 0' },
            }}
          >
            <LeaveContent handleAction={handleAction} isUnsubscriptionType={isUnsubscriptionType} />
          </DialogContent>
        </Dialog>
      </Desktop>
      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={handleClose}
        >
          <CenterAlignStack sx={{ mb: '2.2rem' }}>
            <LeaveContent handleAction={handleAction} isUnsubscriptionType={isUnsubscriptionType} />
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

const ShowuserStateContent = ({
  planId,
  currentSubscriptionEndDate,
  currentSubscriptionStartDate,
  isUnsubscriptionType,
  nextSubscription,
  open,
  setOpen,
  openNextDialog,
  setOpenNextDialog,
  setOpenNextDialogforSNS,
  userCredit,
}) => {
  const { t, i18n } = useTranslation()

  const user = useRecoilValue(userAtom)
  const theme = useTheme()
  const [userStat, setUserStat] = useState(null)
  const { getPlanDisplayName } = usePricing()
  const navigate = useNavigate()

  useEffect(() => {
    apis.user.getUserStat().then(r => {
      const stat = r.data

      setUserStat(stat)
    })
  }, [])

  const handleAction = () => {
    if (!user.login_sns) {
      setOpenNextDialog(true)
    } else {
      setOpenNextDialogforSNS(true)
    }

    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const tableContentsRawData = [
    {
      title: t('user_state.table_1'),
      content: getPlanDisplayName(planId) ?? t('user_state.nothing'),
    },
    {
      title: t('user_state.table_2'),
      content: planId
        ? `${trasnferDate(currentSubscriptionStartDate, 'dotM', i18n)} ~ ${
            currentSubscriptionEndDate ? trasnferDate(currentSubscriptionEndDate, 'dotM', i18n) : ''
          }`
        : '-',
    },
    { title: t('user_state.table_3'), content: t('user_state.price_result') },
    { title: t('user_state.table_4'), content: userCredit },
  ]

  return (
    <Stack>
      <Typography className="title" sx={{ ...style.title }}>
        {t('user_state.title')}
      </Typography>

      <Typography
        className="subtile"
        sx={{
          fontSize: { lg: '1.6rem', xs: '1.3rem' },
          fontWeight: { lg: 500, xs: 400 },
          color: { lg: theme.palette.common.black, xs: '#595959' },
        }}
      >
        {t('user_state.subtitle')}
      </Typography>

      <CenterAlignBox
        sx={{
          backgroundColor: '#F8F8F8',
          width: '100%',
          p: '2.1rem',
          borderRadius: '10px',
          mt: { lg: '2rem', xs: '2.4rem' },
        }}
      >
        <Korean>
          <Typography
            sx={{
              fontSize: { lg: '1.6rem', xs: '1.4rem' },
              fontWeight: 600,
              wordBreak: 'keep-all',
              textAlign: { lg: 'left', xs: 'center' },
            }}
          >
            <SpanDraphBlue
              style={{
                maxWidth: '18.5rem',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                display: 'inline-block',
                verticalAlign: 'bottom',
              }}
            >
              {user.username}
            </SpanDraphBlue>
            님은 드랩아트와 함께{' '}
            <SpanDraphBlue>{trasnferDate(user?.created, 'detail', i18n)}</SpanDraphBlue>
            부터 총 <SpanDraphBlue> {nullZero(userStat?.piece[0]?.piece)}장</SpanDraphBlue>을
            생성하여 사진 스튜디오 촬영 대비
            <SpanDraphBlue> 최소 {nullZero(userStat?.piece[0]?.piece) * 6}만원</SpanDraphBlue>의
            비용을 아끼셨어요.
          </Typography>
        </Korean>
        <Japanese>
          <Typography
            sx={{
              fontSize: { lg: '1.6rem', xs: '1.4rem' },
              fontWeight: 600,
              wordBreak: 'keep-all',
              textAlign: { lg: 'left', xs: 'center' },
            }}
          >
            <SpanDraphBlue
              style={{
                maxWidth: '18.5rem',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                display: 'inline-block',
                verticalAlign: 'bottom',
              }}
            >
              {user.username}
            </SpanDraphBlue>
            さんはドラップアートと一緒に{' '}
            <SpanDraphBlue>{trasnferDate(user?.created, 'detail', i18n)}</SpanDraphBlue>
            これまで <SpanDraphBlue> {nullZero(userStat?.piece[0]?.piece)}枚</SpanDraphBlue>
            の画像を生成し、写真スタジオでの撮影と比較して
            <SpanDraphBlue> 最低 {nullZero(userStat?.piece[0]?.piece) * 6}千円</SpanDraphBlue>
            のコストを節約しました。
          </Typography>
        </Japanese>
        <English>
          <Typography
            sx={{
              fontSize: { lg: '1.6rem', xs: '1.4rem' },
              fontWeight: 600,
              wordBreak: 'keep-all',
              textAlign: { lg: 'left', xs: 'center' },
            }}
          >
            <SpanDraphBlue
              style={{
                maxWidth: '18.5rem',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                display: 'inline-block',
                verticalAlign: 'bottom',
              }}
            >
              {user.username},
            </SpanDraphBlue>{' '}
            you have generated a total of{' '}
            <SpanDraphBlue>{nullZero(userStat?.piece[0]?.piece)} images</SpanDraphBlue> with Draph
            Art from <SpanDraphBlue>{trasnferDate(user?.created, 'detail', i18n)}</SpanDraphBlue>,
            saving at least{' '}
            <SpanDraphBlue>${nullZero(userStat?.piece[0]?.piece) * 43}</SpanDraphBlue> compared to
            actual studio photoshooting.
          </Typography>
        </English>
      </CenterAlignBox>

      <Divider sx={{ my: '2.4rem', borderColor: '#D9D9D9' }} flexItem orientation="horizontal" />

      <Stack sx={{ px: { lg: '1.2rem', xs: '0.4rem' }, gap: { lg: '2rem', xs: '1.4rem' } }}>
        {tableContentsRawData.map((c, i) => {
          const isPriceResult = c.title === t('user_state.table_3')

          const priceResultStyle = {
            cursor: 'pointer',
            color: theme.palette.draph.blue,
            textDecorationLine: 'underline',
            textDecorationColor: theme.palette.draph.blue,
          }

          return (
            <Box
              key={i}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                className="tableTitle"
                sx={{
                  lineHeight: 'noraml',
                  fontSize: '1.6rem',
                  color: theme.palette.text.lightgrey,
                }}
              >
                {c.title}
              </Typography>{' '}
              <Typography
                className="content"
                sx={{
                  lineHeight: 'noraml',
                  fontSize: '1.6rem',
                  color: theme.palette.text.lightgrey,
                  ...(isPriceResult && priceResultStyle),
                }}
                onClick={
                  isPriceResult
                    ? () => {
                        navigate('/user/creditInfo')
                        setOpen(false)
                      }
                    : () => {}
                }
              >
                {c.content}
              </Typography>{' '}
            </Box>
          )
        })}
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '2.4rem' }}>
        <Button
          sx={{
            minWidth: '10.8rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 400,
            color: theme.palette.common.black,
            '&:hover': { background: '#ffffff', color: theme.palette.common.red },
          }}
          onClick={handleAction}
        >
          {isUnsubscriptionType ? t('button.cancel_subscription_2') : t('button.withdraw')}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: '9.9rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 400,
          }}
          onClick={handleClose}
        >
          {t('button.close')}
        </Button>
      </Box>
    </Stack>
  )
}

const BenefitContent = ({
  isSubscription,
  open,
  setOpen,
  setOpenNextDialog,
  userCredit,
  planId,
  openSubscriptionWarningDialog,
  setOpenSubscriptionWarningDialog,
  hasCredit,
  currentSubscription,
  type,
}) => {
  const { t, i18n } = useTranslation()

  const handleClose = () => {
    setOpen(false)
  }
  const theme = useTheme()

  const isUnsubscriptionType = type === 'unsubscribe'
  const isRefundable = currentSubscription.refundable

  const handleAction = () => {
    if (isUnsubscriptionType ? isRefundable : isSubscription) {
      setOpenSubscriptionWarningDialog(true)
    } else {
      setOpenNextDialog(true)
    }
    setOpen(false)
  }

  return (
    <CenterAlignStack sx={{ width: { lg: 'auto', xs: '100%' } }}>
      <Typography
        className="title"
        sx={{ fontSize: { lg: '2.4rem', xs: '1.6rem' }, fontWeight: { lg: 700, xs: 800 } }}
      >
        {isUnsubscriptionType ? t('unsubscription_notice.title') : t('leave_notice.title')}
      </Typography>

      <CenterAlignStack sx={{ mt: '1.6rem', mb: '1.2rem' }}>
        <Typography
          className="subtitle"
          sx={{ fontSize: { lg: '1.4rem', xs: '1.2rem' }, fontWeight: 700, lineHeight: 'normal' }}
        >
          {t('leave_notice.subtitle_1')}
        </Typography>
        <Typography className="subtitle" sx={{ fontSize: { lg: '1.4rem', xs: '1.2rem' } }}>
          {isUnsubscriptionType ? t('leave_notice.subtitle_3') : t('leave_notice.subtitle_2')}
        </Typography>
      </CenterAlignStack>

      <CenterAlignStack
        sx={{
          borderRadius: '10px',
          width: '100%',
          py: { lg: '1.6rem', xs: '1.4rem' },
          background: '#F8F8F8',
        }}
      >
        <Korean>
          <Typography
            sx={{
              textAlign: 'center',
              alignSelf: 'center',
              fontSize: { lg: '1.4rem', xs: '1.3rem' },
              fontWeight: 500,
              lineHeight: { lg: '1.6', xs: '1.7' },
              '& .blueBg': {
                fontWeight: 600,
                fontSize: '1.2rem',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.draph.blue,
                padding: '0.2rem 0.6rem',
                borderRadius: '6px',
                display: 'inline-flex',
                alignItems: 'center',
                gap: '0.2rem',
                lineHeight: 'normal',
                '& svg': {},
              },

              '& .blackBg': {
                fontWeight: 600,
                fontSize: '1.2rem',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.common.black,
                padding: '0.2rem 0.6rem',
                borderRadius: '6px',
                display: 'inline-flex',
                alignItems: 'center',
                gap: '0.2rem',
                lineHeight: 'normal',
                '& svg': {},
              },
            }}
          >
            {isUnsubscriptionType
              ? '지금 드랩아트 구독 취소를 하시면,'
              : '지금 드랩아트 회원탈퇴를 하시면,'}
            <br />
            {hasCredit && (
              <>
                보유하고 계신{' '}
                <span className="blueBg">
                  {numberCommaFormatter(currentSubscription.subscription_credit)} Credits
                  {/* {numberCommaFormatter(userCredit)} Credits */}
                  <CashIcon />
                </span>{' '}
                이 모두 소멸되고,
                <br />
              </>
            )}
            앞으로{' '}
            <span className={hasCredit ? 'blackBg' : 'blueBg'}>
              멤버십 혜택
              <PresentIcon />
            </span>{' '}
            {isUnsubscriptionType ? (
              <>
                을 이용할 수 없어요.
                <br /> 취소하시겠습니까?
              </>
            ) : (
              <>
                {' '}
                을 이용하실 수 없으며, <br />
                <span className={hasCredit ? 'blackBg' : 'blueBg'}>
                  해당 아이디
                  <HappyIcon />
                </span>{' '}
                로 무려 90일간 재가입이 불가능합니다. <br />
                탈퇴하시겠습니까?
              </>
            )}
          </Typography>
        </Korean>
        <Japanese>
          <Typography
            sx={{
              textAlign: 'center',
              alignSelf: 'center',
              fontSize: { lg: '1.4rem', xs: '1.3rem' },
              fontWeight: 500,
              lineHeight: { lg: '1.6', xs: '1.7' },
              '& .blueBg': {
                fontWeight: 600,
                fontSize: '1.2rem',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.draph.blue,
                padding: '0.2rem 0.6rem',
                borderRadius: '6px',
                display: 'inline-flex',
                alignItems: 'center',
                gap: '0.2rem',
                lineHeight: 'normal',
                '& svg': {},
              },

              '& .blackBg': {
                fontWeight: 600,
                fontSize: '1.2rem',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.common.black,
                padding: '0.2rem 0.6rem',
                borderRadius: '6px',
                display: 'inline-flex',
                alignItems: 'center',
                gap: '0.2rem',
                lineHeight: 'normal',
                '& svg': {},
              },
            }}
          >
            {isUnsubscriptionType
              ? '今、ドラップアートの定期購読を解約されると、'
              : '今、ドラップアートの会員退会をされると、'}
            <br />
            {hasCredit && (
              <>
                お持ちの{' '}
                <span className="blueBg">
                  {numberCommaFormatter(currentSubscription.subscription_credit)} クレジット
                  {/* {numberCommaFormatter(userCredit)} クレジット */}
                  <CashIcon />
                </span>{' '}
                がすべて消失し、
                <br />
              </>
            )}{' '}
            今後{' '}
            <span className={hasCredit ? 'blackBg' : 'blueBg'}>
              メンバーシップ特典
              <PresentIcon />
            </span>{' '}
            {isUnsubscriptionType ? (
              <>
                をご利用いただけなくなります。
                <br /> 解約しますか？
              </>
            ) : (
              <>
                {' '}
                をご利用いただけず、
                <br />
                <span className={hasCredit ? 'blackBg' : 'blueBg'}>
                  このアカウント
                  <HappyIcon />
                </span>{' '}
                での再登録は90日間できなくなります。
                <br />
                退会しますか？
              </>
            )}
          </Typography>
        </Japanese>
        <English>
          <Typography
            sx={{
              textAlign: 'center',
              alignSelf: 'center',
              fontSize: { lg: '1.4rem', xs: '1.3rem' },
              fontWeight: 500,
              lineHeight: { lg: '1.6', xs: '1.7' },
              '& .blueBg': {
                fontWeight: 600,
                fontSize: '1.2rem',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.draph.blue,
                padding: '0.2rem 0.6rem',
                borderRadius: '6px',
                display: 'inline-flex',
                alignItems: 'center',
                gap: '0.2rem',
                lineHeight: 'normal',
                '& svg': {},
              },

              '& .blackBg': {
                fontWeight: 600,
                fontSize: '1.2rem',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.common.black,
                padding: '0.2rem 0.6rem',
                borderRadius: '6px',
                display: 'inline-flex',
                alignItems: 'center',
                gap: '0.2rem',
                lineHeight: 'normal',
                '& svg': {},
              },
            }}
          >
            {isUnsubscriptionType
              ? 'If you cancel your subscription now,'
              : 'If you delete your Draph Art account now,'}
            <br />
            {hasCredit && (
              <>
                your current{' '}
                <span className="blueBg">
                  {numberCommaFormatter(userCredit)} Credits
                  <CashIcon />
                </span>{' '}
                will be canceled,
                <br />
              </>
            )}
            your{' '}
            <span className={hasCredit ? 'blackBg' : 'blueBg'}>
              Membership benefits <PresentIcon />
            </span>{' '}
            {isUnsubscriptionType ? (
              <>
                will be inactived.
                <br /> Do you still want to Cancel your subscription?
              </>
            ) : (
              <>
                will be inactived, <br />
                and{' '}
                <span className={hasCredit ? 'blackBg' : 'blueBg'}>
                  this ID
                  <HappyIcon />
                </span>{' '}
                will be blocked from rejoining for 90 days. <br />
                Do you still want to delete your account?
              </>
            )}
          </Typography>
        </English>
      </CenterAlignStack>

      <CenterAlignBox sx={{ mt: '2.4rem' }}>
        <Button
          sx={{
            width: '10.8rem',
            height: '4rem',
            fontSize: '1.6rem',
            borderRadius: '4px',
            fontWeight: 400,
            color: theme.palette.common.black,
            '&:hover': { background: '#ffffff', color: theme.palette.common.red },
          }}
          onClick={handleAction}
        >
          {t('button.confirm')}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: '9.9rem',
            height: '4rem',
            borderRadius: '4px',
            fontSize: '1.6rem',
            fontWeight: 400,
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          {t('button.cancel')}
        </Button>
      </CenterAlignBox>
    </CenterAlignStack>
  )
}

const SubscriptionWarningContent = ({ planId, handleAction, setOpen }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const { getPlanDisplayName, getPlanPrice, getCurrencySymbol } = usePricing()
  const creditPlans = useRecoilValue(creditPlansAtom)

  return (
    <CenterAlignStack>
      <Typography className="title" sx={{ ...style.title }}>
        {t('subscription_warning.title')}
      </Typography>

      <CenterAlignStack
        sx={{ mt: { lg: '1.6rem', xs: '1.2rem' }, mb: { lg: '2.8rem', xs: '3.1rem' } }}
      >
        <Korean>
          <Typography sx={{ ...style.subtitle1 }}>
            구독 중인 월정액이 있는 경우,{' '}
            <span style={{ textDecorationLine: 'underline' }}>구독 취소 후 탈퇴 </span> 가능합니다.
          </Typography>
          <Typography sx={{ ...style.subtitle2 }}>
            마이페이지에서 구독 취소 후, 다시 진행해주세요.
          </Typography>
        </Korean>
        <Japanese>
          <Typography sx={{ ...style.subtitle1 }}>
            定期購読中の月額プランがある場合、{' '}
            <span style={{ textDecorationLine: 'underline' }}>購読を解約後、退会</span> できます。{' '}
          </Typography>

          <Typography sx={{ ...style.subtitle2 }}>
            マイページから購読を解約した後、再度お試しください。
          </Typography>
        </Japanese>
        <English>
          <Typography sx={{ fontWeight: 700 }}>
            You have to cancel{' '}
            <span style={{ textDecorationLine: 'underline' }}>
              your monthly subscription first{' '}
            </span>
            in order to delete your account.
          </Typography>
          <Typography>Cancel your subscription on [My Page], then proceed again.</Typography>
        </English>
      </CenterAlignStack>

      <div style={{ position: 'relative' }}>
        <Box
          sx={{
            // width: { lg: '10rem', xs: '5.6rem' },
            height: { lg: '2.4rem', xs: '2.2rem' },
            borderRadius: { lg: '6px', xs: '6px' },
            background: theme => theme.palette.draph.blue,
            color: 'white',
            fontSize: { lg: '1.3rem', xs: '1.2rem' },
            fontWeight: 700,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%)',
            top: { lg: '-1rem', xs: '-1rem' },
            zIndex: 99,
            px: { lg: '1.9rem', xs: '1.9rem' },
            width: 'max-content',
          }}
        >
          {t('subscription_warning.user_subscription')}
        </Box>

        <CenterAlignStack
          sx={{
            borderRadius: '10px',
            width: { lg: '24rem', xs: '19rem' },
            py: '1.8rem',
            border: '2px solid #4D80FF',
          }}
        >
          <Typography
            sx={{
              fontSize: '1.8rem',
              fontWeight: 600,
              color: theme.palette.draph.blue,
            }}
          >
            {getPlanDisplayName(planId)}
          </Typography>
          <Typography sx={{ fontWeight: 600 }}>
            ({getPlanPrice(creditPlans, planId)}
            {t('mypage.monthly_price')})
          </Typography>
        </CenterAlignStack>
      </div>

      <CenterAlignBox sx={{ mt: '2.4rem' }}>
        <Button
          sx={{
            minWidth: '10.8rem',
            height: '4rem',
            fontSize: '1.6rem',
            borderRadius: '4px',
            fontWeight: 400,
            color: theme.palette.common.black,
            '&:hover': { background: '#ffffff', color: theme.palette.common.red },
          }}
          onClick={handleAction}
        >
          {t('button.cancel_subscription_1')}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: '9.9rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 400,
            borderRadius: '4px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          {t('button.cancel')}
        </Button>
      </CenterAlignBox>
    </CenterAlignStack>
  )
}

const UnsubscriptionWarningContent = ({ planId, handleAction, setOpen }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const { getPlanDisplayName, getPlanPrice, getCurrencySymbol } = usePricing()
  const creditPlans = useRecoilValue(creditPlansAtom)

  return (
    <CenterAlignStack>
      <Typography className="title" sx={{ ...style.title }}>
        {t('unsubscription_notice.title_2')}
      </Typography>

      <div style={{ position: 'relative', marginTop: '2.8rem' }}>
        <Box
          sx={{
            // width: { lg: '10rem', xs: '5.6rem' },
            height: { lg: '2.4rem', xs: '2.4rem' },
            borderRadius: { lg: '6px', xs: '0.8rem' },
            background: theme => theme.palette.draph.blue,
            color: 'white',
            fontSize: { lg: '1.3rem', xs: '1.2rem' },
            fontWeight: 700,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%)',
            top: { lg: '-1rem', xs: '-1rem' },
            zIndex: 99,
            px: { lg: '1.9rem', xs: '1.9rem' },
            width: 'max-content',
          }}
        >
          {t('subscription_warning.refundable_user')}
        </Box>

        <CenterAlignStack
          sx={{
            borderRadius: '10px',
            width: { lg: '42rem', xs: '32rem' },
            py: '1.8rem',
            px: '1.8rem',
            border: '2px solid #4D80FF',
          }}
        >
          <Korean>
            <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', lineHeight: 'normal' }}>
              최신 결제일 기준 7일 이내에 신규 충전된 크레딧을 사용하지 않고 구독취소를 신청하시는
              경우에는 결제하신 월정액 구독료 전액을 환불해드립니다. [마이페이지] - [충전내역]에서
              [결제취소] 버튼을 눌러 환불 신청을 할 수 있습니다.
              <br />
              <br />
              <span style={{ color: '#FF2323' }}>
                단, 최신 월정액 구독에 대해 환불을 받는 경우 전월까지만 구독한 것으로 간주되어,
                지금까지 월정액으로 충전됐던 잔여 크레딧은 환불 신청 완료 즉시 소멸됩니다.
              </span>
            </Typography>
          </Korean>
          <Japanese>
            <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', lineHeight: 'normal' }}>
              最新の決済日から7日以内に新たにチャージされたクレジットを使用せず購読解約を申請された場合、
              支払済みのサブスクリプション料金全額を返金いたします。[マイページ] -
              [チャージ履歴]から [決済キャンセル]ボタンを押して返金申請が可能です。
              <br />
              <br />
              <span style={{ color: '#FF2323' }}>
                ただし、最新のサブスクリプションに対する返金を受ける場合、前月分までの購読のみが有効とみなされ、
                これまで月額購読でチャージされた残りのクレジットは返金申請が完了した時点で即時消失します。
              </span>
            </Typography>
          </Japanese>
          <English>
            <Typography sx={{ fontSize: '1.2rem', lineHeight: 'normal' }}>
              If you request cancellation within 7 days of the lastest paymnt without using any
              newly added credits, you can get the entire monthly subscription fee refunded. You can
              get a refund by clicking [My Page] - [Charge History] - [Cancel Payment].
              <br />
              <br />
              <span style={{ color: '#FF2323' }}>
                However, if you receive a refund for the latest monthly subscrption payment, only
                the subscription up to the previous month will be considered, and any remaining
                credits charged monthly up to now will be expired immediately upon the refund
                request.
              </span>
            </Typography>
          </English>
        </CenterAlignStack>
      </div>

      <CenterAlignBox sx={{ mt: '2.4rem' }}>
        <Button
          sx={{
            width: '10.8rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 400,
            borderRadius: '4px',
            color: theme.palette.common.black,
            '&:hover': { background: '#ffffff', color: theme.palette.common.red },
          }}
          onClick={handleAction}
        >
          {t('button.confirm')}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: '9.9rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 400,
            borderRadius: '4px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          {t('button.cancel')}
        </Button>
      </CenterAlignBox>
    </CenterAlignStack>
  )
}

const SelectReasonContent = ({
  options,
  isUnsubscriptionType,
  handleAction,
  handleClose,
  selectedOption,
  setSelectedOption,
  etcText,
  setEtcText,
}) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const inputFontStyle = { fontSize: '1.6rem', fontWeight: 400 }
  const isMobile = useMobileMediaQuery()

  return (
    <CenterAlignStack>
      <Typography className="title" sx={{ ...style.title }}>
        {t('select_reason_dialog.title')}
      </Typography>

      <CenterAlignStack sx={{ mt: '1.6rem' }}>
        <Typography
          className="subtitle"
          sx={{ fontSize: '1.4rem', fontWeight: 700, lineHeight: 'normal' }}
        >
          {t('select_reason_dialog.subtitle_1')}
        </Typography>
        <Typography className="subtitle" sx={{ fontSize: '1.4rem', textAlign: 'center' }}>
          {t('select_reason_dialog.subtitle_2_1')}
          {isMobile && <br />} {t('select_reason_dialog.subtitle_2_2')}
        </Typography>
      </CenterAlignStack>

      <CenterAlignStack
        sx={{
          width: '100%',
          height: {
            lg: selectedOption === 999 ? '14rem' : '8.5rem',
          },
          padding: { lg: '2rem 0 2.4rem 0', xs: '21px 0 26px 0' },
          gap: '1.6rem',
          transition: 'all .3s ease-in-out',
        }}
      >
        <Select
          displayEmpty
          value={selectedOption}
          onChange={e => {
            setSelectedOption(e.target.value)
          }}
          sx={{
            width: { lg: '35rem', xs: '32rem' },
            height: '4rem',
            p: 0,
            background: ' white',
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
              borderColor: '#D9E1EC',
            },
            ...inputFontStyle,
          }}
          renderValue={selected => {
            if (selected.length === 0) {
              return (
                <Typography
                  sx={{
                    ...inputFontStyle,
                    color: '#878787',
                    width: '30.6rem',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {isUnsubscriptionType
                    ? t('select_reason_dialog.select_unscribe')
                    : t('select_reason_dialog.select')}
                </Typography>
              )
            } else {
              return (
                <Typography
                  sx={{
                    ...inputFontStyle,

                    width: '30.6rem',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {
                    options.filter(o => {
                      return o.id === selected
                    })[0]?.label
                  }
                </Typography>
              )
            }
          }}
        >
          {options.map(o => (
            <MenuItem value={o.id} key={o.id} sx={{ px: '1rem', ...inputFontStyle }}>
              {o.label}{' '}
            </MenuItem>
          ))}
        </Select>
        {selectedOption === 999 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <OutlinedInput
              // disabled={}
              placeholder={t('select_reason_dialog.input')}
              autoFocus
              value={etcText}
              onChange={e => {
                setEtcText(e.target.value)
              }}
              sx={{
                width: '35rem',
                height: '4rem',
                background: ' white',

                '& .MuiOutlinedInput-input': { padding: 0 },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderWidth: '1px',
                  borderColor: '#D9E1EC',
                },
                ...inputFontStyle,
              }}
            />
          </motion.div>
        )}
      </CenterAlignStack>

      <CenterAlignBox sx={{}}>
        <Button
          sx={{
            width: '10.8rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 400,
            borderRadius: '4px',
            color: theme.palette.common.black,
            '&.Mui-disabled': {
              color: theme.palette.grey[500],
            },
            '&:hover': { background: '#ffffff', color: theme.palette.common.red },
          }}
          disabled={!selectedOption}
          onClick={handleAction}
        >
          {t('button.confirm')}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: '9.9rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 400,
            borderRadius: '4px',
          }}
          onClick={handleClose}
        >
          {t('button.cancel')}
        </Button>
      </CenterAlignBox>
    </CenterAlignStack>
  )
}

const LeaveContent = ({ isUnsubscriptionType, handleAction }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  return (
    <CenterAlignStack>
      <Typography className="title" sx={{ ...style.title }}>
        {isUnsubscriptionType ? t('leave_dialog.title_unscribe') : t('leave_dialog.title')}
      </Typography>

      <CenterAlignStack sx={{ mt: '1.6rem' }}>
        <Typography className="subtitle" sx={{ ...style.subtitle2 }}>
          {isUnsubscriptionType
            ? t('leave_dialog.subtitle_1_unscribe')
            : t('leave_dialog.subtitle_1')}
        </Typography>
        <Typography className="subtitle" sx={{ ...style.subtitle1 }}>
          {t('leave_dialog.subtitle_2')}
        </Typography>
      </CenterAlignStack>

      <Button
        variant="contained"
        sx={{
          width: '18rem',
          height: '4rem',
          fontSize: '1.6rem',
          fontWeight: 400,
          mt: '2.4rem',
          borderRadius: '4px',
        }}
        onClick={handleAction}
      >
        {t('button.confirm')}
      </Button>
    </CenterAlignStack>
  )
}
