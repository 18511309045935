import { useEffect, useMemo, useRef, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  FormControl,
  GlobalStyles,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Slider,
  Stack,
  ToggleButton,
  Typography,
  useTheme,
} from '@mui/material'
import {
  CenterAlignBox,
  CenterAlignStack,
  CustomOutlinedInput,
  CustonBannerToggleButton,
  EditorCanvas,
  OpacityMotionDiv,
  TextSettings,
} from 'components'
import {
  APIPlusIcon,
  BgFilterIcon,
  BlueCircleCheckIcon,
  BrandIcon,
  CheckedCircleIcon,
  CheckedCircleIconNotistack,
  CircleCheckIcon,
  CloseBlackIcon,
  CloseCuteIcon,
  CoverLayerIcon,
  DesignIcon,
  DropdownArrowIcon,
  PlusIcon,
  PlusIcon2,
  PlusMobileIcon,
  RegenerateIcon,
  TextIcon,
  TrashCanIcon,
  TrashCanIcon2,
} from 'theme/icon'
import { motion, AnimatePresence } from 'framer-motion'
import {
  allowedTypes,
  base64ToBlob,
  getRandomColor,
  getS3ImageSrc,
  imageToFile,
  isDev,
  s3UriToUrl,
  translatePromptKoToEn,
} from 'utils/common'
import {
  bannerEachFilterAtom,
  bannerSelectedFontListsAtom,
  bannerSelectedLoadingAtom,
  bannerTemplateParamsAtom,
  bannerV2WarningDetectAtom,
  bannerV2WarningDialogAtom,
  bannerWholeFilterAtom,
  portfolioDetailAtom,
  userAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { apis } from 'apis'
import { BannerPromptRegenerate } from './BannerPrompt'
import { useQueries } from '@tanstack/react-query'
import {
  useArtworkPolling,
  useArtworkQuery,
  useInfiniteFont,
  useTemplatesPagination,
} from 'hooks/useReactQuery'
import axios from 'axios'
import { PuffLoader } from 'react-spinners'
import { v4 as uuidv4 } from 'uuid'
import {
  BANNERV2_CONFIG_DEFAULT,
  ARTWORK_IN_PROGRESS_STATUS,
  ARTWORK_DONE_STATUS,
  ARTWORK_ERROR_STATUS,
} from 'config'
import { TemplateCard, TemplatePreviewDialog } from './BannerTemplatesList'
import { AnimatedUploadBox } from 'components/InpaintCanvas'
import { fontSize } from 'pages/test/Toolbar'
import { useAddInfo } from 'hooks/useBannerV2'
import { canvasH, canvasW } from './EditorCanvas'
import { COPY_TYPE_OPTIONS, FontSelect } from './bannerV2/TextSettings'
import useConfirm from 'hooks/useConfirm'
import { TrashcanButton } from './ArtworkRowControl'
import { enqueueSnackbar } from 'notistack'
import FontManager from 'components/FontManger'

const EDITOR_LISTS = [
  // {
  //   avatar: '/static/images/test_sample/a2.webp',
  //   title: '기본 설정 레이어',
  //   // subtitle: '상품-스튜디오',
  // },
  { avatar: '/static/images/test_sample/a3.webp', title: '메인 배경' },
  { avatar: '/static/images/test_sample/a4.jpeg', title: '텍스트' },
  { avatar: '/static/images/test_sample/a3.webp', title: '로고' },
  {
    avatar: '/static/images/test_sample/a5.png',
    title: '디자인 요소',
  },
  {
    avatar: '/static/images/test_sample/a4.jpeg',
    title: '필터 레이어',
  },
]

export const adjustShape = shape => {
  const { avatar, ...restShape } = shape

  return {
    ...restShape,
    width: Math.ceil(shape.width),
    height: Math.ceil(shape.height),
    x: Math.ceil(shape.x),
    y: Math.ceil(shape.y),
    config: {
      ...shape.config,
      bbox: [
        Math.ceil(shape.x),
        Math.ceil(shape.y),
        Math.ceil(shape.x + shape.width),
        Math.ceil(shape.y + shape.height),
      ],
    },
  }
}

export default function BannerEditorV2({ open, setOpen, artwork, setArtwork }) {
  const { SHAPE_LIST_ADDINFO } = useAddInfo()

  const artworkConfig = useMemo(() => {
    return JSON.parse(artwork.config)
  }, [artwork])

  const artworkPieces = useMemo(() => {
    return artwork.pieces
  }, [artwork])

  // Editor Canvas의 stage 영역을 DOM
  const stageRef = useRef(null)

  const user = useRecoilValue(userAtom)
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)

  const fixButtonRef = useRef(null)

  const [selectedPieceIdx, setSelectedPieceIdx] = useState(0)
  const [selectedPiece, setSelectedPiece] = useState(artworkPieces[0])
  const [selectedId, setSelectedId] = useState(null)
  const [selectedLayerId, setSelectedLayerId] = useState(null)

  const [initshapes, setInitshapes] = useState([])

  // const [uploading, setUploading] = useState(false)

  const [shapes, setShapes] = useState([])
  const [scale, setScale] = useState(1)

  const wholeFilter = useRecoilValue(bannerWholeFilterAtom)
  const [eachFilter, setEachFilter] = useRecoilState(bannerEachFilterAtom)

  const resetEachFilter = useResetRecoilState(bannerEachFilterAtom)
  const resetWholeFilter = useResetRecoilState(bannerWholeFilterAtom)

  const [selectedFontLists, setSelectedFontLists] = useRecoilState(bannerSelectedFontListsAtom)

  const [isLoading, setIsLoading] = useState(false)

  const { showConfirm } = useConfirm()

  // useEffect(() => {
  //   if (!selectedLayerId) return
  //   // resetEachFilter()
  //   // resetWholeFilter()
  //   // setShapes(initshapes)

  //   showConfirm({
  //     content: '레이어가 변경되었습니다. ',
  //     onConfirm: () => {
  //       const selectedLayer = shapes.find(l => l.id === selectedLayerId)
  //       updatePieceHandler({
  //         hasChangeFilter:
  //           selectedLayer.config.type === 'image' && selectedLayer.config.image_type === 'paste',
  //       })
  //     },
  //   })
  // }, [selectedLayerId])

  const { refetch: allArtworkRefetch } = useArtworkQuery({
    portfolioId: portfolioDetail.id,
  }).getArtworks

  const shouldPoll = useMemo(
    () => ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status),
    [artwork.status]
  )

  const { individualArtwork, error, modPieceError, refetch } = useArtworkPolling(
    portfolioDetail.id,
    artwork.id,
    shouldPoll
  )

  const artworkDone = ARTWORK_DONE_STATUS === artwork.status
  const artworkError = ARTWORK_ERROR_STATUS.includes(artwork.status)
  const artworkLoading =
    (!artworkDone && !artworkError) || ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)

  // 피스 동기화
  useEffect(() => {
    if (individualArtwork) {
      setArtwork(individualArtwork)
      setSelectedPiece(individualArtwork.pieces[selectedPieceIdx])
    }
  }, [individualArtwork, individualArtwork?.status, individualArtwork?.pieces])

  // useEffect(() => {
  //   setSelectedPiece(artworkPieces[selectedPieceIdx])
  // }, [selectedPieceIdx])

  useEffect(() => {
    return () => {
      resetEachFilter()
      resetWholeFilter()
    }
  }, [])

  useEffect(() => {
    if (!artwork) return
    if (!artworkLoading) {
      setIsLoading(false)
      if (selectedPiece.config?.filterConfig && selectedLayerId) {
        setEachFilter(selectedPiece.config?.filterConfig)
        // setTimeout(() => {
        //   updatePieceHandler({ hasChangeFilter: true })
        // }, 3000)
      }

      // console.log(
      //   selectedPiece.config.shapes.find(s => s.id === selectedLayerId)?.config?.regenerate_shape
      // )
      // if (
      //   selectedPiece.config?.filterConfig &&
      //   selectedLayerId &&
      //   !selectedPiece.config.shapes.find(s => s.id === selectedLayerId)?.config?.regenerate_shape
      // ) {
      //   setEachFilter(selectedPiece.config?.filterConfig)
      //   console.log(
      //     'artwork',
      //     artwork.pieces[selectedPieceIdx].config.shapes.find(s => s.id === selectedLayerId)
      //   )
      //   // filterUpload({
      //   //   shapeURL: artwork.pieces[selectedPieceIdx].config.shapes.find(
      //   //     s => s.id === selectedLayerId
      //   //   ).result_s3_url,
      //   // })
      //   updatePieceHandler({ hasChangeFilter: true })
      // }
    }
  }, [artwork.status])

  const directConfigUpdate = (configName, configValue) => {
    setShapes(prev => {
      return prev.map(s =>
        s.id === selectedLayerId ? { ...s, config: { ...s.config, [configName]: configValue } } : s
      )
    })
  }

  // 폰트 넣기
  const getFontFaces = fonts => {
    if (fonts && fonts.map) {
      return fonts
        .map(font => {
          const format = font.cdn_path.split('.').at(-1)

          return `
            @font-face {
              font-family: '${font.id}';
              src: url('${font.cdn_path}') format('${format}');
              font-weight: normal;
              font-style: normal;
            }
          `
        })
        .join('\n')
    }
  }

  useEffect(() => {
    if (shapes.length === 0) return
    setSelectedFontLists(
      shapes
        .map(s => {
          return s.config.text_font
        })
        .filter(Boolean)
    )
  }, [shapes])

  // const fontLists = useMemo(() => {
  //   if (shapes.length === 0) return []
  //   return shapes
  //     .map(s => {
  //       return s.config.text_font
  //     })
  //     .filter(Boolean)
  // }, [shapes])

  // React Query로 폰트 데이터 가져오기
  const fontQueries = useQueries({
    queries: selectedFontLists.map(font => ({
      queryKey: ['font', font],
      queryFn: () => apis.common.getFont(font),
      select: response => response.data,
      enabled: Boolean(font), // font가 있을 때만 쿼리 실행
    })),
  })

  const allFonts = fontQueries
    .map(query => query.data)
    .filter(Boolean)
    .flat()

  const handleClose = (e, r) => {
    if (r === 'backdropClick') return

    resetEachFilter()
    resetWholeFilter()
    setOpen(false)
  }

  // const filterUpload = async ({ hasChangeFilter = false, shapeURL }) => {
  //   if (!shapeURL) return
  //   try {
  //     const dataURL = shapeURL.includes('https://upload-pipeline-data')
  //       ? shapeURL
  //       : getS3ImageSrc(shapeURL)
  //     const response = await fetch(dataURL)

  //     // response를 blob으로 변환
  //     const imageFile = await response.blob()

  //     console.log(response, imageFile)

  //     const presignedData = (await apis.data.presignedURL('png')).data

  //     await axios.put(presignedData.presigned_url, imageFile, {
  //       headers: {
  //         'Content-Type': imageFile.type,
  //       },
  //     })

  //     const s3Url = s3UriToUrl(presignedData.s3_file_path)

  //     console.log(s3Url)
  //   } catch {}
  // }

  const updatePieceHandler = async ({
    hasChangeFilter = false,
    addShapeConfig = {},
    addShape = {},
  }) => {
    setIsLoading(true)
    try {
      const selectedLayerRef = stageRef?.current?.findOne(`#${selectedLayerId}`)
      const dataURL = selectedLayerRef.toDataURL({ pixelRatio: 3 * (1 / scale) })

      const imageFile = base64ToBlob(dataURL, 'image/png')
      const presignedData = (await apis.data.presignedURL('png')).data

      const width = selectedLayerRef.width() / scale
      const height = selectedLayerRef.height() / scale

      await axios.put(presignedData.presigned_url, imageFile, {
        headers: {
          'Content-Type': imageFile.type,
        },
      })

      const s3Url = s3UriToUrl(presignedData.s3_file_path)

      const adjustConfig = {
        ...selectedPiece.config,
        shapes: shapes.map(s => {
          if (s.id === selectedLayerId) {
            const isText = s.config.type === 'text'
            const changedShape = {
              ...s,
              width: isText ? width : s.width,
              height: isText ? height : s.height,
            }

            const adjustedShapes = adjustShape(changedShape)

            return {
              ...adjustedShapes,
              ...addShape,
              ...(!hasChangeFilter && { result_s3_url: s3Url, filter_flag: false }),
              ...(hasChangeFilter && { filtered_result_s3_url: s3Url, filter_flag: true }),
              config: {
                ...adjustedShapes.config,
                ...addShapeConfig,
                regenerate_shape: false,
                ...(addShapeConfig.image_reference_image && { image_reference_image: s3Url }),
                ...(addShapeConfig.logo_image_url && { logo_image_url: s3Url }),
              },
            }
          }
          return adjustShape(s)
        }),
      }

      setInitshapes(adjustConfig.shapes)

      const snapshotParams = {
        user_id: user.id,
        portfolio_id: portfolioDetail.id,
        artwork_id: artwork.id,
        piece_id: selectedPiece.config.piece_id,
        banner_presets: JSON.stringify(adjustConfig),
      }

      const r = await apis.appfront.getSnapshot(snapshotParams)
      await refetch()
    } catch (err) {
      console.error('Upload failed:', error)
      throw error
    } finally {
      setIsLoading(false)
      resetEachFilter()
      resetWholeFilter()
    }
  }

  // useEffect(() => {
  //   console.log('shapes', shapes)
  // }, [shapes])

  const updatePieceCRUD = ({ path, newConfig }) => {
    const body = { path, config: newConfig }
    return apis.portfolio.updatePiece(portfolioDetail.id, artwork.id, body)
  }

  const directPieceUpdate = (addConfigs = {}) => {
    const newShapes = shapes.map(s => {
      if (s.id === selectedLayerId) {
        return {
          ...adjustShape(s),
          ...addConfigs,
        }
      }
      return adjustShape(s)
    })
    setShapes(newShapes)
    // setInitshapes(newShapes)
  }

  const updateAllPieceHandler = async () => {
    setIsLoading(true)
    try {
      // 1. 모든 shape에서 Image type만 필터링
      const imageShapes = shapes.filter(
        shape => shape.config.type !== 'text' // Konva Image 타입만 선택
      )

      // 2. 각 이미지에 대해 병렬로 업로드 처리
      const uploadPromises = imageShapes.map(async shape => {
        const layerRef = stageRef?.current?.findOne(`#${shape.id}`)
        if (!layerRef) return shape // 레이어를 찾지 못한 경우 원본 반환

        const dataURL = layerRef.toDataURL({ pixelRatio: 3 * (1 / scale) })
        const imageFile = base64ToBlob(dataURL, 'image/png')

        // presigned URL 받아오기
        const presignedData = (await apis.data.presignedURL('png')).data

        // S3 업로드
        await axios.put(presignedData.presigned_url, imageFile, {
          headers: {
            'Content-Type': imageFile.type,
          },
        })

        const s3Url = s3UriToUrl(presignedData.s3_file_path)

        // 업데이트된 shape 정보 반환
        return {
          ...adjustShape(shape),
          filtered_result_s3_url: s3Url,
          filter_flag: true,
          filter_config: wholeFilter,
          // ...(!shape.filter_flag && { result_s3_url: s3Url, filter_flag: false }),
          // ...(shape.filter_flag && { filtered_result_s3_url: s3Url, filter_flag: true }),
        }
      })

      // 3. 모든 업로드 완료 대기
      const updatedImageShapes = await Promise.all(uploadPromises)

      // // 4. 전체 shapes 배열 업데이트
      const adjustConfig = {
        ...selectedPiece.config,
        filterConfig: wholeFilter,
        shapes: shapes.map(shape => {
          const updatedImage = updatedImageShapes.find(updated => updated.id === shape.id)
          return updatedImage || adjustShape(shape)
        }),
      }

      await updatePieceCRUD({
        path: selectedPiece.path,
        newConfig: {
          ...selectedPiece.config,
          shapes: adjustConfig.shapes,
        },
      })
      // 5. 상태 업데이트
      setInitshapes(adjustConfig.shapes)

      // 6. 스냅샷 생성
      const snapshotParams = {
        user_id: user.id,
        portfolio_id: portfolioDetail.id,
        artwork_id: artwork.id,
        piece_id: selectedPiece.config.piece_id,
        banner_presets: JSON.stringify(adjustConfig),
      }

      await apis.appfront.getSnapshot(snapshotParams)
      refetch()
    } catch (error) {
      console.error('Multiple images upload failed:', error)
      throw error
    } finally {
      setIsLoading(false)
      resetWholeFilter()
    }
  }

  const retryHandler = async ({ selectedLayerId, newConfig = {} }) => {
    setIsLoading(true)
    try {
      const feedback = 'regenerate_auto'

      const formData = new FormData()

      // Basic form data setup
      const basicFormData = {
        user_id: user.id,
        username: user.username,
        portfolio_id: portfolioDetail.id,
        artwork_id: artwork.id,
        piece_id: selectedPiece.config.piece_id,
      }

      Object.entries(basicFormData).forEach(([key, value]) => {
        formData.append(key, value)
      })

      // 3. Process shapes and update config in single pass
      const processedShapes = shapes.map(shape => {
        const adjustedShape = adjustShape(shape)

        return shape.id === selectedLayerId && Object.keys(newConfig).length > 0
          ? { ...adjustedShape, config: { ...newConfig, regenerate_shape: true } }
          : { ...adjustedShape, config: { ...adjustedShape.config, regenerate_shape: false } }
      })

      formData.append('modified_banner_presets', JSON.stringify(processedShapes))

      await apis.portfolio.updateBannerArtworkFeedback(portfolioDetail.id, artwork.id, { feedback })

      await apis.appfront.retryBannerV2(formData).then(response => {
        return response
      })

      // await allArtworkRefetch()
      await refetch()
    } catch (err) {
      console.log('error :', err)
      setIsLoading(false)
    }
  }

  const addShape = ({ type, childrenType, isRegen, addConfig = {} }) => {
    // Find the highest existing number for this type

    const existingNumbers = shapes
      .filter(shape => {
        return shape.config.type === type
      })
      .map(shape => parseInt(shape.text.split(' ')[1]))

    const maxNumber = Math.max(type === 'background' ? 1 : 0, ...existingNumbers)
    const newNumber = maxNumber + 1

    const newShape = {
      id: uuidv4(),

      x: (selectedPiece?.config.canvasSize.width - (type === 'text' ? 500 : 400)) / 2,
      y: (selectedPiece?.config.canvasSize.height - 400) / 2,
      width: type === 'text' ? 500 : 400,
      height: 400,
      fill: 'rgba(158,158,158,0.3)',
      text: `${type} ${newNumber}`,
      config: {
        ...BANNERV2_CONFIG_DEFAULT[type],
        ...(childrenType && { [`${type}_type`]: childrenType }),
        regenerate_shape: isRegen,
        ...addConfig,
      },
    }

    const updateFunc = () => {
      if (SHAPE_LIST_ADDINFO[newShape.config.type]) {
        // 기존 shape 객체와 추가 정보를 병합

        if (SHAPE_LIST_ADDINFO[newShape.config.type].children && childrenType) {
          return {
            ...newShape,
            ...SHAPE_LIST_ADDINFO[newShape.config.type].children[childrenType],
          }
        }

        const { children, ...restInfo } = SHAPE_LIST_ADDINFO[newShape.config.type]
        return {
          ...newShape,
          ...restInfo,
        }
      }
    }

    const updatedShapes = updateFunc()
    // shape의 type이 SHAPE_LIST_ADDINFO의 키와 일치하는 경우

    // 인페이트 shape 은 무조건 백그라운드 위에
    setShapes(prevShapes => {
      return [...prevShapes, updatedShapes]
    })
    // setInitshapes(prevShapes => {
    //   return [...prevShapes, updatedShapes]
    // })

    setSelectedLayerId(newShape.id)
  }

  const onDelete = id => {
    setIsLoading(true)
    setShapes(prevShapes => prevShapes.filter(shape => shape.id !== id))
    setInitshapes(prevShapes => prevShapes.filter(shape => shape.id !== id))
    setSelectedId(null)
    setSelectedLayerId(null)

    const adjustConfig = {
      ...selectedPiece.config,
      shapes: shapes
        .filter(shape => shape.id !== id)
        .map(s => {
          const { avatar, ...restShape } = s
          return restShape
        }),
    }

    const snapshotParams = {
      user_id: user.id,
      portfolio_id: portfolioDetail.id,
      artwork_id: artwork.id,
      piece_id: selectedPiece.config.piece_id,
      banner_presets: JSON.stringify(adjustConfig),
    }

    apis.appfront
      .getSnapshot(snapshotParams)
      .then(() => {
        refetch()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiModal-backdrop': {
            backgroundColor: '#30303099',
            backdropFilter: 'blur(20px)',
          },
          '& .MuiDialog-paper': {
            minWidth: '1280px',
            minHeight: '808px',
            borderRadius: '16px',
          },
        }}
      >
        <FontManager fonts={allFonts} />
        {isLoading && (
          <Box
            sx={{
              zIndex: 99999,
              display: 'flex',
              backgroundColor: 'white',
              opacity: 0.8,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              position: 'absolute',
            }}
          >
            <PuffLoader />
          </Box>
        )}

        <CenterAlignStack sx={{ width: '100%' }}>
          <EditorBar handleClose={handleClose} title={artwork?.name} />

          <Box sx={{ display: 'flex', width: '100%' }}>
            <LayerPart
              selectedPiece={selectedPiece}
              setSelectedPiece={setSelectedPiece}
              shapes={shapes}
              setShapes={setShapes}
              setSelectedId={setSelectedId}
              retryHandler={retryHandler}
              updatePieceHandler={updatePieceHandler}
              updateAllPieceHandler={updateAllPieceHandler}
              selectedLayerId={selectedLayerId}
              setSelectedLayerId={setSelectedLayerId}
              directConfigUpdate={directConfigUpdate}
              initshapes={initshapes}
              addShape={addShape}
              updatePieceCRUD={updatePieceCRUD}
              directPieceUpdate={directPieceUpdate}
              onDelete={onDelete}
              setIsLoading={setIsLoading}
            />
            <CanvasPart
              artworkPieces={artworkPieces}
              selectedPiece={selectedPiece}
              setSelectedPiece={setSelectedPiece}
              shapes={shapes}
              setShapes={setShapes}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              selectedLayerId={selectedLayerId}
              setSelectedLayerId={setSelectedLayerId}
              initshapes={initshapes}
              setInitshapes={setInitshapes}
              stageRef={stageRef}
              scale={scale}
              setScale={setScale}
              addShape={addShape}
              selectedPieceIdx={selectedPieceIdx}
              setSelectedPieceIdx={setSelectedPieceIdx}
              updatePieceHandler={updatePieceHandler}
            />
          </Box>
        </CenterAlignStack>
      </Dialog>
    </>
  )
}

const EditorBar = ({ handleClose, title = '닥터지 바쿠치올 포어젯 세럼' }) => {
  return (
    <CenterAlignBox
      sx={{
        justifyContent: 'space-between',
        height: '6rem',
        alignItems: 'center',
        width: '100%',
        p: '1.8rem 2rem',
        '& img': {
          width: '2.8rem',
        },

        '& .logo': {
          display: 'flex',
          gap: '0.8rem',
        },

        '& .close': {
          display: 'flex',
          width: '15.348rem',
          justifyContent: 'end',
        },

        '& .title': {
          fontSize: '1.8rem',
          fontWeight: 600,
        },
      }}
    >
      <Box className="logo">
        <img src="/static/images/logo/logo_image.png" />
        <Typography sx={{ fontSize: '2rem', fontWeight: 700 }}>Draph Editor</Typography>
      </Box>

      <Typography className="title">{title}</Typography>

      <Box className="close">
        <IconButton sx={{ p: '0.8rem' }} onClick={handleClose}>
          <CloseCuteIcon />
        </IconButton>
      </Box>
    </CenterAlignBox>
  )
}

const LayerPart = ({
  selectedPiece,
  setSelectedPiece,
  shapes,
  setShapes,
  setSelectedId,
  retryHandler,
  updatePieceHandler,
  updateAllPieceHandler,
  selectedLayerId,
  setSelectedLayerId,
  directConfigUpdate,
  initshapes,
  updatePieceCRUD,
  addShape,
  directPieceUpdate,
  onDelete,
  setIsLoading,
}) => {
  const theme = useTheme()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const containerRef = useRef(null)

  const [selectedLayer, setSelectedLayer] = useState()
  const [selectedList, setSelectedList] = useState(null)

  const [wholeFilter, setWholeFilter] = useRecoilState(bannerWholeFilterAtom)

  const COMMON_LISTS = [
    {
      id: 'backgroundFilter',
      title: '배경 필터',
      avatar: <BgFilterIcon />,
    },
  ]

  useEffect(() => {
    if (containerRef.current && isMenuOpen) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [isMenuOpen])

  // selectedLayer 읽기용 무조건 읽기용으로 사용할 것 layer => shapes 로 변경 흐름은 되지 않음
  useEffect(() => {
    setSelectedLayer(shapes.find(l => l.id === selectedLayerId))
  }, [shapes, selectedLayerId])

  // const selectedLayer = shapes.find(l => l.id === selectedLayerId)

  const menuItems = [
    {
      id: 'image',
      avatar: <DesignIcon />,
      label: '디자인 스티커',
    },
    { id: 'logo', avatar: <BrandIcon />, label: '로고' },
    { id: 'text', avatar: <TextIcon />, label: '텍스트' },
    {
      id: 'image',
      avatar: <CoverLayerIcon />,
      label: '배경 수정',
      childrenType: 'inpaint',
    },
    {
      id: 'text',
      avatar: <TextIcon />,
      label: 'AI 폰트 디자인',
      addConfig: {
        text_gen_flag: true,
        text_list: [],
        text_font: 'YOnepickTTF_2',
        text_type: 'custom',
        text_size: 'auto',
      },
    },
  ]

  return (
    <Stack
      sx={{
        width: '38.4rem',
        position: 'relative',
        overflow: 'hidden',
        // overflowX: 'hidden',
        // overflowY: 'auto',
        // height: '68rem',
        // backgroundColor: 'rgba(41,163,27,0.3)',
        '& .MuiList-root': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },

        '& .MuiListItemButton-root.default': {
          height: '6rem',
          p: '1.2rem 2.4rem 1.2rem 2rem',
          '&:hover': {
            backgroundColor: theme.palette.draph.lighterblue, // 호버 시 배경색 변경

            '& .MuiAvatar-root': {
              backgroundColor: '#DBE6FF !important',
              '& path': {
                stroke: `${theme.palette.draph.blue} !important`, // SVG stroke 색상 변경
              },
            },
          },

          '& .MuiListItemAvatar-root': {
            mr: '1.2rem',

            '& .MuiAvatar-root': {
              width: '3.6rem',
              height: '3.6rem',
              backgroundColor: '#EEEEEE',

              '& path': {
                stroke: '#808080', // 기본 stroke 색상
                transition: 'stroke 0.2s ease', // 부드러운 색상 전환 효과
              },
            },
          },

          '& .MuiListItemText-primary': {
            fontWeight: 600,
            fontSize: '1.6rem',
            lineHeight: 'normal',
          },

          '& .MuiListItemText-secondary': {
            fontWeight: 400,
            fontSize: '1.2rem',
            lineHeight: 'normal',
          },
        },
      }}
    >
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: selectedLayer ? -384 : 0 }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <List disablePadding>
          {shapes.map((l, i) => {
            return (
              <ListItemButton
                className="default"
                disableGutters
                disableRipple
                key={i}
                onClick={() => {
                  setSelectedLayerId(l.id)
                  // if (l.config?.type !== 'background') {
                  //   setSelectedId(l.id)
                  // }
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    {l.avatar}
                    {/* <img src={l.avatar} alt={l.title} /> */}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={l.title} secondary={l.subtitle} />
                <Box sx={{ transform: 'rotate(-90deg)' }}>
                  <DropdownArrowIcon color={theme.palette.common.black} />
                </Box>
              </ListItemButton>
            )
          })}

          {COMMON_LISTS.map(l => {
            return (
              <ListItemButton
                key={l.id}
                className="default"
                onClick={() => {
                  setSelectedList(l.id)
                }}
              >
                <CenterAlignBox
                  sx={{
                    width: '3.6rem',
                    height: '3.6rem',
                    borderRadius: '50%',
                    alignItems: 'center',
                    mr: '1.2rem',
                  }}
                >
                  <Avatar sx={{ backgroundColor: '#EEEEEE' }}>{l.avatar}</Avatar>
                </CenterAlignBox>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                  <Typography sx={{ fontWeight: 600, fontSize: '1.6rem', lineHeight: 'normal' }}>
                    {l.title}
                  </Typography>
                  <Box sx={{ transform: 'rotate(-90deg)' }}>
                    <DropdownArrowIcon color={theme.palette.common.black} />
                  </Box>
                </Box>
              </ListItemButton>
            )
          })}

          {/* <ListItemButton
            className="default"
            onClick={() => addShape({ type: 'image', isRegen: true })}
          >
            <CenterAlignBox
              sx={{
                backgroundColor: '#989898',
                width: '3.6rem',
                height: '3.6rem',
                borderRadius: '50%',
                alignItems: 'center',
                mr: '1.2rem',
              }}
            >
              <PlusMobileIcon style={{ width: '1.8rem', height: '1.8rem' }} color="white" />
            </CenterAlignBox>
            <Typography sx={{ fontWeight: 600, fontSize: '1.6rem', lineHeight: 'normal' }}>
              일러스트 추가
            </Typography>
          </ListItemButton> */}

          <ListItemButton
            className="default"
            onClick={() => {
              setIsMenuOpen(p => !p)
            }}
            disableTouchRipple
            sx={{
              '&:hover': {
                backgroundColor: 'transparent !important', // 호버 시 배경색 변경
              },
            }}
          >
            <CenterAlignBox
              sx={{
                backgroundColor: isMenuOpen ? theme.palette.draph.blue : '#989898',
                width: '3.6rem',
                height: '3.6rem',
                borderRadius: '50%',
                alignItems: 'center',
                mr: '1.2rem',
              }}
            >
              <APIPlusIcon sx={{ width: '1.8rem', height: '1.8rem' }} color="white" />
            </CenterAlignBox>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '1.6rem',
                lineHeight: 'normal',
                color: isMenuOpen ? theme.palette.draph.blue : theme.palette.common.black,
              }}
            >
              구성 요소 추가
            </Typography>
          </ListItemButton>
        </List>
        <Collapse in={isMenuOpen} timeout="auto">
          <Paper elevation={0} ref={containerRef}>
            <Stack sx={{ m: '0 2.8rem 0 4.8rem', gap: '1.2rem' }}>
              {menuItems.map(item => (
                <Box
                  key={item.label}
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    '&:hover': {
                      '& .MuiButton-root': {
                        backgroundColor: theme => theme.palette.draph.blue,
                      },
                      '& .avatar-box': {
                        backgroundColor: '#DBE6FF',
                        '& path': {
                          stroke: '#3A73FF',
                        },
                      },
                    },
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    addShape({
                      type: item.id,
                      childrenType: item.childrenType,
                      isRegen: !(item.id === 'text' || item.id === 'logo'),
                      addConfig: item.addConfig || {},
                    })
                  }}
                >
                  <Box
                    className="avatar-box"
                    sx={{
                      width: '3.6rem',
                      height: '3.6rem',
                      borderRadius: '50%',
                      backgroundColor: '#eee',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mr: '1.2rem',
                      '& path': {
                        stroke: '#808080',
                        transition: 'stroke 0.2s ease', // 부드러운 색상 전환 효과
                      },
                    }}
                  >
                    {item.avatar}
                    {/* <img
                      src={item.avatar}
                      style={{ width: '3.6rem', height: '3.6rem', borderRadius: '50%' }}
                    /> */}
                  </Box>

                  <Typography
                    sx={{
                      minWidth: '15.3rem',
                      fontWeight: 600,
                      fontSize: '1.6rem',
                      lineHeight: 'normal',
                    }}
                  >
                    {item.label}
                  </Typography>

                  <Button
                    variant="contained"
                    sx={{
                      fontSize: '1.4rem',
                      fontWeight: 600,
                      p: '0.3rem 1.2rem',
                      gap: '0.375rem',
                      borderRadius: '6px',
                    }}
                  >
                    <APIPlusIcon sx={{ width: '1.05rem', height: '1.05rem' }} />
                    추가하기
                  </Button>
                </Box>
              ))}
            </Stack>
          </Paper>
        </Collapse>
      </motion.div>
      {/* <AnimatePresence> */}
      {selectedLayer && (
        <motion.div
          key={selectedLayer.id}
          initial={{ x: 384 }}
          animate={{ x: 0 }}
          exit={{ x: 384 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <LayerSettings
            selectedPiece={selectedPiece}
            selectedLayer={selectedLayer}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
            onBack={() => {
              setShapes(initshapes)
              setSelectedLayerId(null)
              setSelectedId(null)
            }}
            directConfigUpdate={directConfigUpdate}
            updatePieceHandler={updatePieceHandler}
            shapes={shapes}
            retryHandler={retryHandler}
            updatePieceCRUD={updatePieceCRUD}
            directPieceUpdate={directPieceUpdate}
            onDelete={onDelete}
            setIsLoading={setIsLoading}
          />
        </motion.div>
      )}

      {selectedList && !selectedLayerId && (
        <motion.div
          initial={{ x: 384 }}
          animate={{ x: 0 }}
          exit={{ x: 384 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Stack
            sx={{
              '& .MuiList-root': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              },

              '& .MuiListItemButton-root.default': {
                height: '6rem',
                p: '1.2rem 2.8rem 1.2rem 2.8rem',

                '&:hover': {
                  backgroundColor: theme.palette.draph.lighterblue, // 호버 시 배경색 변경
                },

                '& .MuiListItemAvatar-root': {
                  mr: '1.2rem',

                  '& .MuiAvatar-root': {
                    width: '3.6rem',
                    height: '3.6rem',
                  },
                },

                '& .MuiListItemText-primary': {
                  fontWeight: 700,
                  fontSize: '1.6rem',
                  lineHeight: 'normal',
                },

                '& .MuiListItemText-secondary': {
                  fontWeight: 400,
                  fontSize: '1.2rem',
                  lineHeight: 'normal',
                },
              },
            }}
          >
            <ListItemButton
              className="default"
              disableGutters
              disableRipple
              onClick={() => {
                setSelectedList(null)
                setSelectedLayerId(null)
              }}
              selected={true}
            >
              <Box sx={{ transform: 'rotate(90deg)', mr: '1.2rem' }}>
                <DropdownArrowIcon color={theme.palette.common.black} />
              </Box>
              <ListItemAvatar
                sx={{
                  '& .MuiAvatar-root': {
                    backgroundColor: '#DBE6FF !important',
                    '& path': {
                      stroke: `${theme.palette.draph.blue} !important`, // SVG stroke 색상 변경
                    },
                  },
                }}
              >
                <Avatar>
                  <BgFilterIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={COMMON_LISTS[0].title} />
            </ListItemButton>

            <CenterAlignBox
              sx={{
                my: '2.4rem',
                gap: '0.8rem',
                '& .box': {
                  width: '34.4rem',
                  height: '4rem',
                  fontSize: '1.6rem',
                  fontWeight: 600,
                  alignItems: 'center',
                  borderRadius: '4px',
                  cursor: 'pointer',

                  '& .content': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem',
                    transition: 'transform 0.3s ease-in-out', // transition 추가
                  },

                  '&.regenerate': {
                    color: theme.palette.draph.blue,
                    backgroundColor: theme.palette.draph.lighterblue,

                    '&:hover .content': {
                      transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
                    },
                  },

                  '&.accept': {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.draph.blue,

                    '&:hover .content': {
                      transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
                    },
                  },
                },
              }}
            >
              <CenterAlignBox className="box accept" onClick={() => updateAllPieceHandler()}>
                <Box className="content">
                  <CircleCheckIcon color={theme.palette.common.white} />
                  적용
                </Box>
              </CenterAlignBox>
            </CenterAlignBox>

            <Stack
              sx={{
                px: '2.5rem',
                overflowY: 'auto',
                height: '59.8rem',
                '& .title': { fontSize: '1.6rem', fontWeight: 600, lineHeight: '20px' },

                '& .box': {
                  // width: '16.8rem',
                  // height: '4rem',
                  // fontSize: '1.6rem',
                  // fontWeight: 600,
                  // alignItems: 'center',
                  // borderRadius: '4px',
                  // cursor: 'pointer',

                  '& .content': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem',
                    transition: 'transform 0.3s ease-in-out', // transition 추가
                  },

                  '&.regenerate': {
                    color: theme.palette.draph.blue,
                    backgroundColor: theme.palette.draph.lighterblue,

                    '&:hover .content': {
                      transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
                    },
                  },

                  '&.accept': {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.draph.blue,

                    '&:hover .content': {
                      transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
                    },
                  },
                },
              }}
            >
              {selectedList === 'backgroundFilter' && (
                <FilterSettings
                  filterValue={wholeFilter}
                  onFilterChange={setWholeFilter}
                  updatePieceHandler={updatePieceHandler}
                />
              )}
            </Stack>

            {/* <LayerSettings
              selectedPiece={selectedPiece}
              selectedLayer={selectedLayer}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              onBack={() => {
                setShapes(initshapes)
                setSelectedLayerId(null)
                setSelectedId(null)
              }}
              directConfigUpdate={directConfigUpdate}
              updatePieceHandler={updatePieceHandler}
              shapes={shapes}
              retryHandler={() => retryHandler(selectedLayer)}
            /> */}
          </Stack>
        </motion.div>
      )}
      {/* </AnimatePresence> */}
    </Stack>
  )
}

const CanvasPart = ({
  artworkPieces,
  selectedPiece,
  setSelectedPiece,
  shapes,
  setShapes,
  selectedId,
  setSelectedId,
  selectedLayerId,
  setSelectedLayerId,
  initshapes,
  setInitshapes,
  stageRef,
  scale,
  setScale,
  addShape,
  selectedPieceIdx,
  setSelectedPieceIdx,
  updatePieceHandler,
}) => {
  const resetEachFilter = useResetRecoilState(bannerEachFilterAtom)
  const resetWholeFilter = useResetRecoilState(bannerWholeFilterAtom)

  return (
    <CenterAlignStack
      sx={{
        width: '100%',
        flex: 1,
        background: '#EEEEEE',
        minHeight: '748px',
        p: '23px 0 21px 0',
      }}
    >
      <Stack sx={{ minHeight: '52px', width: '100%' }}></Stack>
      <CenterAlignStack sx={{ minHeight: '571px', width: '100%' }}>
        <EditorCanvas
          piece={selectedPiece}
          shapes={shapes}
          setShapes={setShapes}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          selectedLayerId={selectedLayerId}
          setSelectedLayerId={setSelectedLayerId}
          initshapes={initshapes}
          setInitshapes={setInitshapes}
          stageRef={stageRef}
          scale={scale}
          setScale={setScale}
          addShape={addShape}
          updatePieceHandler={updatePieceHandler}
        />
      </CenterAlignStack>
      <Stack sx={{ minHeight: '77px', width: '100%', pt: '21px' }}>
        <CenterAlignBox sx={{ gap: '0.8rem' }}>
          {artworkPieces.map((p, i) => {
            const src = getS3ImageSrc(p.path)
            const isSelected = p.id === selectedPiece.id
            return (
              <Box sx={{ position: 'relative' }} key={p.id}>
                <img
                  src={src}
                  style={{
                    width: '5.6rem',
                    height: '5.6rem',
                    objectFit: 'contain',
                    cursor: 'pointer',
                    borderRadius: '4px',
                  }}
                  onClick={() => {
                    setSelectedPieceIdx(i)
                    setSelectedPiece(artworkPieces[i])
                    setSelectedLayerId(null)
                    // setSelectedPiece(p)
                    resetEachFilter()
                    resetWholeFilter()
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: -3,
                    left: -3,
                    right: -3,
                    bottom: -3,
                    border: '3px solid #2C4DFF',
                    borderRadius: '7px',
                    pointerEvents: 'none',
                    opacity: isSelected ? 1 : 0,
                    transition: 'opacity 0.2s ease-in',
                  }}
                />
              </Box>
            )
          })}
        </CenterAlignBox>
      </Stack>
    </CenterAlignStack>
  )
}

const LayerSettings = ({
  selectedPiece,
  selectedLayer,
  selectedList,
  setSelectedList,
  onBack,
  retryHandler,
  updatePieceHandler,
  directConfigUpdate,
  updatePieceCRUD,
  shapes,
  directPieceUpdate,
  onDelete,
  setIsLoading,
}) => {
  const theme = useTheme()
  const [eachFilter, setEachFilter] = useRecoilState(bannerEachFilterAtom)

  const [activeLayer, setActiveLayer] = useState(selectedLayer)

  const configUpdate = (configName, configValue) => {
    setActiveLayer(prev => {
      return { ...prev, config: { ...prev.config, [configName]: configValue } }
    })
  }

  // useEffect(() => {
  //   console.log('selectedLayer', selectedLayer)
  //   setActiveLayer(selectedLayer)
  // }, [selectedLayer])

  // 하위 컴포넌트에서 상태 업데이트를 위한 함수

  const handleApply = () => {
    const hasChangeFilter =
      activeLayer.config.type === 'image' && activeLayer.config.image_type === 'paste'

    // 각 타입별로 필요한 설정을 추가
    let addShapeConfig = {}
    switch (activeLayer.config.type) {
      case 'image':
        addShapeConfig = {
          image_type: activeLayer.config.image_type,
          image_reference_image: activeLayer.config.image_reference_image,
        }
        break

      case 'logo':
        addShapeConfig = {
          logo_image_url: activeLayer.config.logo_image_url,
        }
        break

      // 다른 타입들에 대한 처리 추가
    }

    updatePieceHandler({
      hasChangeFilter,
      addShapeConfig,
    })
  }

  const pieceConfig = useMemo(() => {
    return selectedPiece.config
  }, [selectedPiece])

  return (
    <Stack
      sx={{
        '& .MuiList-root': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },

        '& .MuiListItemButton-root.default': {
          height: '6rem',
          p: '1.2rem 2.8rem 1.2rem 2.8rem',

          '&:hover': {
            backgroundColor: theme.palette.draph.lighterblue, // 호버 시 배경색 변경
          },

          '& .MuiListItemAvatar-root': {
            mr: '1.2rem',

            '& .MuiAvatar-root': {
              width: '3.6rem',
              height: '3.6rem',
            },
          },

          '& .MuiListItemText-primary': {
            fontWeight: 700,
            fontSize: '1.6rem',
            lineHeight: 'normal',
          },

          '& .MuiListItemText-secondary': {
            fontWeight: 400,
            fontSize: '1.2rem',
            lineHeight: 'normal',
          },
        },
      }}
    >
      <ListItemButton
        className="default"
        disableGutters
        disableRipple
        onClick={onBack}
        selected={true}
      >
        <Box sx={{ transform: 'rotate(90deg)', mr: '1.2rem' }}>
          <DropdownArrowIcon color={theme.palette.common.black} />
        </Box>
        <ListItemAvatar
          sx={{
            '& .MuiAvatar-root': {
              backgroundColor: '#DBE6FF !important',
              '& path': {
                stroke: `${theme.palette.draph.blue} !important`, // SVG stroke 색상 변경
              },
            },
          }}
        >
          <Avatar sx={{ bgcolor: 'transparent' }}>{selectedLayer.avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={selectedLayer.title} secondary={selectedLayer.subtitle} />

        <Box
          sx={{
            display: 'flex',
            gap: '0.8rem',
            '& .accept': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '8.8rem',
              height: '3.6rem',
              backgroundColor: theme.palette.draph.blue,
              borderRadius: '4px',
              '& .content': {
                color: theme.palette.common.white,
                fontSize: '1.6rem',
                fontWeight: 600,

                transition: 'transform 0.3s ease-in-out', // transition 추가
              },

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },
          }}
        >
          {' '}
          <Box
            className="accept"
            onClick={e => {
              e.stopPropagation()
              handleApply()
            }}
          >
            <Typography className="content">적용</Typography>
          </Box>
          <TrashcanButton
            handleClick={e => {
              // e.stopPropagation()
              onDelete(selectedLayer.id)
            }}
            sx={{
              backgroundColor: 'transparent',
              borderColor: theme => theme.palette.draph.blue,
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: theme => theme.palette.common.red,
              },
              '& svg': {
                stroke: theme => theme.palette.draph.blue,
              },
              '&:hover svg': {
                stroke: theme => theme.palette.common.red,
              },
              height: '3.6rem !important',
              width: '3.6rem !important',
            }}
          />
        </Box>
      </ListItemButton>

      {/* <CenterAlignBox
        sx={{
          my: '2.4rem',
          px: '2rem',
          gap: '0.8rem',
          '& .box': {
            flex: 1,
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 600,
            alignItems: 'center',
            borderRadius: '4px',
            cursor: 'pointer',

            '& .content': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
              transition: 'transform 0.3s ease-in-out', // transition 추가
            },

            '&.regenerate': {
              color: theme.palette.draph.blue,
              backgroundColor: theme.palette.draph.lighterblue,

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },

            '&.accept': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.draph.blue,

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },
          },
        }}
      >
        {regenShapes && (
          <CenterAlignBox className="box regenerate" onClick={retryHandler}>
            <Box className="content">
              <RegenerateIcon color={theme.palette.draph.blue} />
              재생성
            </Box>
          </CenterAlignBox>
        )}

        {fixShapes && (
          <CenterAlignBox
            className="box accept"
            onClick={() =>
              updatePieceHandler({
                hasChangeFilter:
                  selectedLayer.config.type === 'image' ||
                  selectedLayer.config.image_type === 'paste',
              })
            }
          >
            <Box className="content">
              <CircleCheckIcon color={theme.palette.common.white} />
              적용
            </Box>
          </CenterAlignBox>
        )}
      </CenterAlignBox> */}

      <Stack
        sx={{
          px:
            selectedLayer.config.type === 'image' || selectedLayer.config.image_type === 'paste'
              ? '3rem'
              : '2rem',
          overflowY: 'auto',
          height: '68.7rem',
          '& .title': { fontSize: '1.6rem', fontWeight: 600, lineHeight: '20px' },

          '& .box': {
            // width: '16.8rem',
            // height: '4rem',
            // fontSize: '1.6rem',
            // fontWeight: 600,
            // alignItems: 'center',
            // borderRadius: '4px',
            // cursor: 'pointer',

            '& .content': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
              transition: 'transform 0.3s ease-in-out', // transition 추가
            },

            '&.regenerate': {
              color: theme.palette.draph.blue,
              backgroundColor: theme.palette.draph.lighterblue,

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },

            '&.accept': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.draph.blue,

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },
          },
        }}
      >
        {selectedLayer.config.type === 'background' && (
          <BackgroundSetting
            pieceConfig={pieceConfig}
            selectedPiece={selectedPiece}
            selectedLayer={selectedLayer}
            directConfigUpdate={directConfigUpdate}
            retryHandler={retryHandler}
            updatePieceHandler={updatePieceHandler}
            updatePieceCRUD={updatePieceCRUD}
            layer={activeLayer}
            configUpdate={configUpdate}
          />
        )}

        {selectedLayer.config.type === 'text' && !selectedLayer.config.text_gen_flag && (
          <TextSettings
            pieceConfig={pieceConfig}
            selectedLayer={selectedLayer}
            directConfigUpdate={directConfigUpdate}
            retryHandler={retryHandler}
            updatePieceHandler={updatePieceHandler}
          />
        )}

        {selectedLayer.config.type === 'image' && selectedLayer.config.image_type === 'paste' && (
          <FilterSettings
            filterValue={eachFilter}
            onFilterChange={setEachFilter}
            pieceConfig={pieceConfig}
            updatePieceHandler={updatePieceHandler}
            selectedLayer={selectedLayer}
            directConfigUpdate={directConfigUpdate}
            retryHandler={retryHandler}
          />
        )}

        {selectedLayer.config.type === 'image' && selectedLayer.config.image_type === 'inpaint' && (
          <CoverSettings
            pieceConfig={pieceConfig}
            updatePieceHandler={updatePieceHandler}
            selectedLayer={selectedLayer}
            directConfigUpdate={directConfigUpdate}
            retryHandler={retryHandler}
            layer={activeLayer}
            configUpdate={configUpdate}
          />
        )}

        {selectedLayer.config.type === 'image' &&
          selectedLayer.config.image_type !== 'paste' &&
          selectedLayer.config.image_type !== 'inpaint' && (
            <ImageSettings
              pieceConfig={pieceConfig}
              selectedLayer={selectedLayer}
              directConfigUpdate={directConfigUpdate}
              updatePieceHandler={updatePieceHandler}
              retryHandler={retryHandler}
              directPieceUpdate={directPieceUpdate}
              layer={activeLayer}
              configUpdate={configUpdate}
            />
          )}

        {selectedLayer.config.type === 'inpaint_mask' && (
          <InpaintMaskSettings
            pieceConfig={pieceConfig}
            selectedLayer={selectedLayer}
            retryHandler={retryHandler}
          />
        )}

        {selectedLayer.config.type === 'logo' && (
          <LogoSettings
            pieceConfig={pieceConfig}
            selectedLayer={selectedLayer}
            retryHandler={retryHandler}
            updatePieceHandler={updatePieceHandler}
            directConfigUpdate={directConfigUpdate}
            directPieceUpdate={directPieceUpdate}
            layer={activeLayer}
            configUpdate={configUpdate}
          />
        )}

        {selectedLayer.config.type === 'text' && selectedLayer.config.text_gen_flag && (
          <GenFontSettings
            pieceConfig={pieceConfig}
            selectedLayer={selectedLayer}
            directConfigUpdate={directConfigUpdate}
            retryHandler={retryHandler}
            updatePieceHandler={updatePieceHandler}
            setIsLoading={setIsLoading}
            layer={activeLayer}
            setLayer={setActiveLayer}
            configUpdate={configUpdate}
          />
        )}
      </Stack>
    </Stack>

    //     {layer.title === '메인 배경' && <BackgroundSettings />}
    //     {layer.title === '텍스트' && <TextSettings />}
  )
}

const BackgroundSetting = ({
  pieceConfig,
  selectedPiece,
  selectedLayer,
  updatePieceHandler,
  retryHandler,
  updatePieceCRUD,
  layer,
  configUpdate,
}) => {
  const theme = useTheme()

  // const [subcategory, setSubcategory] = useState(pieceConfig.templateInfo.subcategory)
  const [selectedBannerIds, setSelectedBannerIds] = useState([])
  const [localPrompt, setLocalPrompt] = useState(pieceConfig.promptObj)

  const [bannerTemplateConfig, setBannerTemplateConfig] = useState({
    category: pieceConfig?.templateInfo?.category || 'auto',
    subcategory: pieceConfig?.templateInfo?.subcategory || 'studio',
    initialPageSize: 5,
    subsequentPageSize: 8,
  })

  const bannerSubCategoryLists = [
    { value: 'studio', label: '상품 컨셉', src: '/static/images/bannerV2/category/studio.png' },
    { value: 'model', label: '단순 모델', src: '/static/images/bannerV2/category/model.png' },
    {
      value: 'interaction',
      label: '그랩 모델',
      src: '/static/images/bannerV2/category/interaction.png',
    },
  ]

  const MAX_SELECTIONS = 1
  const isMaxSelected = selectedBannerIds?.length >= MAX_SELECTIONS

  return (
    <Stack
      sx={{
        '& .type': {
          display: 'flex',
          gap: '0.8rem',
          mt: '1.6rem',
        },
        '& .bg-type': {
          width: '8rem',
          height: '12rem',
          backgroundColor: '#eeeeee',
          borderRadius: '4px',
          position: 'relative',
        },

        '& .bg-border': {
          width: '8rem',
          height: '12rem',

          borderRadius: '4px',
          position: 'absolute',
          border: `2px solid ${theme.palette.draph.blue}`,
          top: 0,
        },
        '& .bg-title': {
          bottom: '1rem',
          fontSize: '1.2rem',
          fontWeight: 700,
          position: 'absolute',
          width: '100%',
          textAlign: 'center',
          color: theme.palette.common.white,
        },

        '& .prompt': {
          // mt: '2.8rem',
        },
      }}
    >
      {' '}
      <CommonButtonArea
        retryHandler={async () => {
          const newConfig = {
            ...layer.config,
            bg_inpaint_prompt: translatePromptKoToEn(localPrompt),
          }

          const newShapes = pieceConfig.shapes.map(s =>
            s.id === layer.id ? { ...s, config: { ...newConfig } } : s
          )

          await retryHandler({ selectedLayerId: layer.id, newConfig })
          await updatePieceCRUD({
            path: selectedPiece.path,
            newConfig: { ...pieceConfig, shapes: newShapes, promptObj: localPrompt },
          })
        }}
        updatePieceHandler={updatePieceHandler}
        selectedLayer={selectedLayer}
      />
      {/* <Typography className="title">배경 타입</Typography>
      <Box className="type">
        {bannerSubCategoryLists.map(l => {
          return (
            <Box className="bg-type" key={l.value} onClick={subcategoryHandler(l.value)}>
              <img className="bg-type" src={l.src} style={{ objectFit: 'cover' }} />
              <Typography className="bg-title">{l.label}</Typography>
              {bannerTemplateConfig.subcategory === l.value && <Box className="bg-border"></Box>}
            </Box>
          )
        })}
      </Box>
      <Typography className="title" sx={{ m: '2.4rem 0 1.6rem 0' }}>
        레이아웃 선택
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <IconButton
          onClick={() => {
            goToPreviousPage()
          }}
          disabled={!hasPreviousPage}
          size="large"
          sx={{ opacity: hasPreviousPage ? 1 : 0.5, p: 0, borderRadius: 0 }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.2558 5.24408C11.9554 4.94367 11.4827 4.92057 11.1558 5.17475L11.0772 5.24408L6.91058 9.41074C6.61018 9.71115 6.58707 10.1838 6.84126 10.5107L6.91058 10.5893L11.0772 14.7559C11.4027 15.0814 11.9303 15.0814 12.2558 14.7559C12.5562 14.4555 12.5793 13.9828 12.3251 13.6559L12.2558 13.5774L8.679 10L12.2558 6.42259C12.5562 6.12219 12.5793 5.64949 12.3251 5.32258L12.2558 5.24408Z"
              fill="#808080"
            />
          </svg>
        </IconButton>
        <Box
          sx={{
            width: isLoading ? '100%' : 'inherit',
            minHeight: '18rem',
            minWidth: '29.6rem',
            // backgroundColor: 'rgba(14,54,123,0.5)',
            display: 'flex',
            flexFlow: 'wrap',
            gap: '0.8rem',
          }}
        >
          {isLoading ? (
            <CenterAlignBox sx={{ width: '100%', alignItems: 'center' }}>
              <CircularProgress color="inherit" size={80} thickness={2} />
            </CenterAlignBox>
          ) : (
            <>
              {templates.map((t, idx) => (
                <Box
                  key={idx}
                  sx={{
                    position: 'relative',
                    background: '#D9D9D9',
                    borderRadius: '4px',
                    width: 'calc((100% - 2.4rem ) / 4)',
                    minWidth: 'calc((100% - 2.4rem ) / 4)',
                  }}
                >
                  <TemplateCard
                    template={t}
                    // selectedIds={selectedIds}
                    isSelected={selectedBannerIds.includes(t.banner_id)}
                    onSelect={() => handleSelect(t.banner_id)}
                    isMaxSelected={isMaxSelected}
                    sx={{ width: '100%', height: '9.6rem', borderRadius: '4px' }}
                  />
                  {isDev && (
                    <Box sx={{ position: 'absolute', top: '0.5rem' }}>
                      <TemplatePreviewDialog data={JSON.parse(t.template.data)} />
                    </Box>
                  )}
                </Box>
              ))}
            </>
          )}
        </Box>
        <IconButton
          onClick={() => {
            // loadMore()
            goToNextPage()
          }}
          variant="outlined"
          disabled={!hasNextPage}
          size="large"
          sx={{ opacity: hasNextPage ? 1 : 0.5, p: 0, borderRadius: 0 }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.74408 5.24408C8.04448 4.94367 8.51717 4.92057 8.84408 5.17475L8.92259 5.24408L13.0893 9.41074C13.3897 9.71115 13.4128 10.1838 13.1586 10.5107L13.0893 10.5893L8.92259 14.7559C8.59715 15.0814 8.06951 15.0814 7.74408 14.7559C7.44367 14.4555 7.42057 13.9828 7.67475 13.6559L7.74408 13.5774L11.3208 10L7.74408 6.42259C7.44367 6.12219 7.42057 5.64949 7.67475 5.32258L7.74408 5.24408Z"
              fill="#808080"
            />
          </svg>
        </IconButton>
      </Box> */}
      <OpacityMotionDiv>
        <Stack className="prompt">
          <Typography className="title">프롬프트</Typography>

          <Box
            sx={{
              width: '100%',
              p: '0.8rem',
              backgroundColor: '#f8f8f8',
              borderRadius: '6px',
              mt: '1.6rem',
            }}
          >
            <Box>
              <BannerPromptRegenerate
                templateType={bannerTemplateConfig.subcategory}
                promptValues={localPrompt}
                setPromptValues={setLocalPrompt}
                directConfigUpdate={configUpdate}
                category={bannerTemplateConfig.category}
              />
            </Box>
          </Box>
        </Stack>
      </OpacityMotionDiv>
    </Stack>
  )
}

// const TextSettings = ({
//   pieceConfig,
//   selectedLayer,

//   retryHandler,
//   directConfigUpdate,
// }) => {
//   const copyTypeLists = [
//     { value: 'info', label: '정보전달' },
//     { value: 'question', label: '궁금증유발' },
//     { value: 'review', label: '인기강조' },
//     { value: 'price', label: '가격강조' },
//     // { value: 'brand', label: '브랜드명' },
//     // { value: 'product', label: '상품명' },
//   ]

//   const theme = useTheme()
//   const [selectedLoading, setSelectedLoading] = useRecoilState(bannerSelectedLoadingAtom)
//   const [selectedFontLists, setSelectedFontLists] = useRecoilState(bannerSelectedFontListsAtom)

//   const [selectedFontId, setSelectedFontId] = useState(selectedLayer.config.text_font)

//   const fontQueries = useQueries({
//     queries: selectedFontLists.map(font => ({
//       queryKey: ['font', font],
//       queryFn: () => apis.common.getFont(font),
//       select: response => response.data,
//       enabled: Boolean(font), // font가 있을 때만 쿼리 실행
//     })),
//   })

//   const selectedFontListsData = fontQueries
//     .map(query => query.data)
//     .filter(Boolean)
//     .flat()

//   const { data, error, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } =
//     useInfiniteFont()

//   const fontLists = data ? [...selectedFontListsData, ...data] : []

//   const getFontFaces = fonts => {
//     if (fonts && fonts.map) {
//       return fonts
//         .map(font => {
//           const format = font.cdn_path.split('.').at(-1)

//           return `
//             @font-face {
//               font-family: '${font.id}';
//               src: url('${font.cdn_path}') format('${format}');
//               font-weight: normal;
//               font-style: normal;
//             }
//           `
//         })
//         .join('\n')
//     }
//   }

//   const handleChange = event => {
//     directConfigUpdate('text_font', event.target.value)
//   }

//   const changeCopyHandler = b => async () => {
//     setSelectedLoading(true)
//     directConfigUpdate('text_type', b.value)

//     const defaultPresets = JSON.stringify(pieceConfig.defaultConfig)
//     const layerInfo = JSON.stringify(selectedLayer)

//     const body = {
//       layer_info: layerInfo,
//       default_presets: defaultPresets,
//       copy_style: b.value,
//       lang: 'ko',
//     }

//     const copy = (
//       await apis.appfront.copyRetry(body).finally(() => {
//         setSelectedLoading(false)
//       })
//     ).data

//     const newCopy = copy.recommended_copy
//     directConfigUpdate('text_list', newCopy)
//     setSelectedLoading(false)
//   }

//   return (
//     <Stack
//       sx={{
//         '& .button-box': {
//           display: 'flex',
//           width: '100%',
//           gap: '1rem',
//           flexFlow: 'wrap',
//           mt: '1.7rem',
//         },
//         '& .copy-button': {
//           width: 'calc((100% - 2rem) / 3)',
//           // height: '3.2rem',
//           // border: 'none',
//           // p: 0,
//           // backgroundColor: '#eeeeee',
//           // fontSize: '1.2rem',
//           // fontWeight: 700,
//           // color: theme.palette.common.black,

//           // '&:hover': {
//           //   backgroundColor: '#eeeeee',
//           //   color: theme.palette.common.black,
//           // },

//           // '&.Mui-selected': {
//           //   backgroundColor: theme.palette.draph.blue,
//           //   color: theme.palette.common.white,
//           // },
//         },

//         '& .text-stack': {
//           mt: '2.4rem',
//         },

//         '& .text-border': {
//           width: '8rem',
//           height: '12rem',

//           borderRadius: '4px',
//           position: 'absolute',
//           border: `2px solid ${theme.palette.draph.blue}`,
//         },
//       }}
//     >
//       {' '}
//       <GlobalStyles styles={getFontFaces(data)} />
//       <Stack className="copy-stack">
//         <Typography className="title">카피라이트 타입</Typography>

//         <Box className="button-box">
//           {copyTypeLists.map(b => {
//             return (
//               <CustonBannerToggleButton
//                 key={b.value}
//                 className="copy-button"
//                 variant="cantained"
//                 selected={selectedLayer.config.text_type === b.value}
//                 onClick={changeCopyHandler(b)}
//                 disabled={selectedLoading}
//               >
//                 {b.label}
//               </CustonBannerToggleButton>
//             )
//           })}
//         </Box>
//       </Stack>
//       <Stack className="text-stack">
//         <Typography className="title">텍스트 디자인</Typography>

//         <FormControl variant="outlined">
//           <Select
//             value={selectedLayer.config.text_font}
//             onChange={handleChange}
//             sx={{ width: '30rem' }}
//             renderValue={selected => {
//               const selectedFont = fontLists.find(font => font.id === selected)
//               return (
//                 <span
//                   style={{
//                     fontFamily: `'${selectedFont?.id}'`,
//                     fontSize: '1.4rem',
//                   }}
//                 >
//                   {selectedFont?.name}
//                 </span>
//               )
//             }}
//             // 메뉴가 열릴 때 스크롤 이벤트를 처리하여 무한 스크롤을 구현할 수도 있습니다.
//             // 여기서는 단순히 "더 불러오기" 버튼을 사용합니다.
//             MenuProps={{
//               // 드롭다운 메뉴의 최대 높이를 설정하여 스크롤이 가능하도록 함
//               PaperProps: {
//                 style: {
//                   maxHeight: 300,
//                 },
//               },
//             }}
//           >
//             {fontLists.map(font => (
//               <MenuItem
//                 key={font.id}
//                 value={font.id}
//                 sx={{
//                   fontFamily: `'${font?.id}'`,
//                   fontSize: '1.4rem',
//                 }}
//               >
//                 {font.name}
//               </MenuItem>
//             ))}

//             {/* "더 불러오기" 버튼 */}
//             {hasNextPage && (
//               <MenuItem
//                 disabled={isFetchingNextPage}
//                 onClick={() => fetchNextPage()}
//                 sx={{
//                   justifyContent: 'center',
//                   padding: '1rem',
//                   display: 'flex',
//                   alignItems: 'center',
//                   flexDirection: 'column',
//                 }}
//               >
//                 {isFetchingNextPage ? (
//                   <Box display="flex" alignItems="center">
//                     <CircularProgress size={20} />
//                     <span style={{ marginLeft: '0.5rem' }}>불러오는 중...</span>
//                   </Box>
//                 ) : (
//                   '더 불러오기'
//                 )}
//               </MenuItem>
//             )}

//             {/* 로딩 중일 때 표시 */}
//             {isLoading && (
//               <MenuItem disabled>
//                 <CircularProgress size={20} />
//               </MenuItem>
//             )}
//           </Select>
//         </FormControl>
//       </Stack>
//     </Stack>
//   )
// }

const FilterSettings = ({
  filterValue,
  onFilterChange,
  pieceConfig,
  selectedLayer,
  setSelectedLayer,
  updatePieceHandler,
  retryHandler,
  directConfigUpdate,
}) => {
  const theme = useTheme()
  const [filterType, setFilterType] = useState('original')

  const onChangeHandler = e => {
    const value = e.target.value
    const name = e.target.name
    setFilterType('custom')
    onFilterChange(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const filterHandler = (type, value) => () => {
    setFilterType(type)
    onFilterChange(value)
  }

  const photoFilters = [
    {
      value: {
        brightness: 0,
        contrast: 0,
        saturation: 0,
        noise: 0,
        blurRadius: 0,
        enhance: 0,
        red: 0,
        green: 0,
        blue: 0,
      },
      label: '원본',
      type: 'original',
      image: '/static/images/theme_template/kitchen/default.png',
    },
    {
      value: {
        brightness: 0,
        contrast: 0,
        saturation: 0,
        noise: 0.09,
        blurRadius: 0,
        enhance: 0,
        red: 0,
        green: 0,
        blue: 0,
      },
      label: '스튜디오\n그레인',
      type: 'grain',
      image: '/static/images/bannerV2/editor/grain.png',
    },
    {
      value: {
        brightness: 0,
        contrast: -3,
        saturation: -0.3,
        noise: 0,
        blurRadius: 0,
        enhance: 0.2,
        red: 3,
        green: 0,
        blue: -9,
      },
      label: '자연 채광',
      type: 'nature',
      image: '/static/images/bannerV2/editor/nature.png',
    },
    {
      value: {
        brightness: 0.04,
        contrast: -3,
        saturation: 0,
        noise: 0.04,
        blurRadius: 0,
        enhance: 0.2,
        red: -7,
        green: 0,
        blue: 4,
      },
      label: '스튜디오\n라이팅',
      type: 'lighting',
      image: '/static/images/bannerV2/editor/lightning.png',
    },
    {
      value: {
        brightness: 0.01,
        contrast: 5,
        saturation: -2,
        noise: 0,
        blurRadius: 0,
        enhance: 0.4,
        red: 0,
        green: 0,
        blue: 0,
      },
      label: '흑백',
      type: 'blackAndWhite',
      image: '/static/images/bannerV2/editor/black.png',
    },
  ]

  const sliderConfigs = [
    { name: 'brightness', label: '밝기', min: -0.5, max: 0.5, step: 0.01 },
    { name: 'contrast', label: '대비', min: -100, max: 100, step: 1 },
    { name: 'saturation', label: '채도', min: -2, max: 2, step: 0.1 },
    { name: 'noise', label: '노이즈', min: 0, max: 1, step: 0.01 },
    { name: 'blurRadius', label: '블러', min: 0, max: 40, step: 1 },
    { name: 'enhance', label: '선명도 향상', min: 0, max: 1, step: 0.1 },
    {
      name: 'red',
      label: '빨강',
      min: -50,
      max: 50,
      step: 1,
      color: 'linear-gradient(90deg, #00FFFF 0%, #FF0000 100%)',
      thumbColor: '#FF0000',
    },
    {
      name: 'green',
      label: '초록',
      min: -50,
      max: 50,
      step: 1,
      color: 'linear-gradient(90deg, #FF00FF 0%, #00FF00 100%)',
      thumbColor: '#00FF00',
    },
    {
      name: 'blue',
      label: '파랑',
      min: -50,
      max: 50,
      step: 1,
      color: 'linear-gradient(90deg, #FFFF00 0%, #0000FF 100%)',
      thumbColor: '#0000FF',
    },
  ]

  return (
    <Stack
      sx={{
        gap: '2rem',
        mb: '2rem',
        '& .photo-filters': { display: 'flex', flexFlow: 'wrap', gap: '0.8rem', mt: '1.6rem' },
        '& .photo-filter': {
          width: '7.1rem',
          height: '12rem',
          borderRadius: '4px',
          cursor: 'pointer',
          '&.box': {
            backgroundColor: '#eee',
            position: 'relative',
          },
          '&.image': {
            objectFit: 'cover ',
          },
          '&.selected-box': {
            position: 'absolute',
            border: `2px solid ${theme.palette.draph.blue}`,
            top: 0,
          },
          '& .photo-filter-name': {
            fontWeight: 700,
            fontSize: '1.2rem',
            position: 'absolute',
            transform: 'translate(-50%, 0%)',
            left: '50%',
            bottom: '1rem',
            wordBreak: 'keep-all',
            textAlign: 'center',
            color: '#fff',
          },
        },
        '& .default-slider': {
          '& .MuiSlider-rail': { backgroundColor: '#D9E1EC', borderColor: '#D9E1EC' },
          '& .MuiSlider-track': { backgroundColor: 'blue', borderColor: 'blue' },
          '& .MuiSlider-thumb': { backgroundColor: 'blue' },
        },
      }}
    >
      {' '}
      <Stack>
        {selectedLayer && (
          <CommonButtonArea
            retryHandler={retryHandler}
            updatePieceHandler={updatePieceHandler}
            selectedLayer={selectedLayer}
          />
        )}

        <Typography className="title">포토 필터</Typography>
        <Box className="photo-filters">
          {photoFilters.map(p => {
            return (
              <Box
                className="photo-filter box"
                key={p.label}
                onClick={filterHandler(p.type, p.value)}
              >
                <img className="photo-filter image" src={p.image} />
                <Box className="photo-filter-name">{p.label}</Box>
                {p.type === filterType && <Box className="photo-filter selected-box"></Box>}
              </Box>
            )
          })}
        </Box>
      </Stack>
      {sliderConfigs.map(config => (
        <Stack key={config.name}>
          <Typography className="title">{config.label}</Typography>
          <Slider
            className={config.color ? undefined : 'default-slider'}
            value={filterValue[config.name]}
            name={config.name}
            onChange={onChangeHandler}
            min={config.min}
            max={config.max}
            step={config.step}
            sx={
              config.color
                ? {
                    '& .MuiSlider-track': {
                      background: 'transparent',
                      border: 'none',
                    },

                    '& .MuiSlider-rail': {
                      background: config.color,
                      border: 'none',
                      opacity: 1,
                    },
                    '& .MuiSlider-thumb': { background: config.thumbColor },
                  }
                : undefined
            }
          />
        </Stack>
      ))}
    </Stack>
  )
}

// const ImagePasteSettings = ({
//   pieceConfig,
//   selectedLayer,
//   setSelectedLayer,
//   retryHandler,
//   directConfigUpdate,
// }) => {
//   const theme = useTheme()
//   const [eachFilter, setEachFilter] = useRecoilState(bannerEachFilterAtom)
//   const [filterType, setFilterType] = useState('original') // original, grain, nature, lightning, blackAndWhite, custom

//   const onChangeHandler = e => {
//     const value = e.target.value
//     const name = e.target.name
//     setFilterType('custom')
//     setEachFilter(p => {
//       return { ...p, [name]: value }
//     })
//   }

//   const filterHandler = (type, value) => () => {
//     setFilterType(type)
//     setEachFilter(value)
//   }

//   const photoFilters = [
//     {
//       value: {
//         brightness: 0,
//         contrast: 0,
//         saturation: 0,
//         noise: 0,
//         blurRadius: 0,
//         enhance: 0,
//         red: 0,
//         green: 0,
//         blue: 0,
//       },
//       label: '원본',
//       type: 'original',
//       image: '/static/images/theme_template/kitchen/default.png',
//     },
//     {
//       value: {
//         brightness: 0.4,
//         contrast: 0,
//         saturation: 0,
//         noise: 0,
//         blurRadius: 0,
//         enhance: 0,
//         red: 10,
//         green: 0,
//         blue: 0,
//       },
//       label: '스튜디오\n그레인',
//       type: 'grain',
//       image: '/static/images/theme_template/bubble/default.png',
//     },
//     {
//       value: {
//         brightness: 0,
//         contrast: 0,
//         saturation: 0,
//         noise: 0,
//         blurRadius: 0,
//         enhance: 0,
//         red: 0,
//         green: 0,
//         blue: 0,
//       },
//       label: '자연 채광',
//       type: 'nature',
//       image: '/static/images/theme_template/half/default.png',
//     },

//     {
//       value: {
//         brightness: 0,
//         contrast: 0,
//         saturation: 0,
//         noise: 0,
//         blurRadius: 0,
//         enhance: 0,
//         red: 0,
//         green: 0,
//         blue: 0,
//       },
//       label: '스튜디오\n라이팅',
//       type: 'lightning',
//       image: '/static/images/theme_template/office/default.png',
//     },

//     {
//       value: {
//         brightness: 0,
//         contrast: 0,
//         saturation: 0,
//         noise: 0,
//         blurRadius: 0,
//         enhance: 0,
//         red: 0,
//         green: 0,
//         blue: 0,
//       },
//       label: '흑백',
//       type: 'blackAndWhite',
//       image: '/static/images/theme_template/office/default.png',
//     },
//   ]

//   return (
//     <Stack
//       sx={{
//         gap: '2rem',
//         '& .photo-filters': { display: 'flex', flexFlow: 'wrap', gap: '0.8rem', mt: '1.6rem' },
//         '& .photo-filter': {
//           width: '7.6rem',
//           height: '12rem',
//           borderRadius: '4px',
//           cursor: 'pointer',
//           '&.box': {
//             backgroundColor: '#eee',
//             position: 'relative',
//           },
//           '&.image': {
//             objectFit: 'contain',
//           },
//           '&.selected-box': {
//             position: 'absolute',
//             border: `1px solid ${theme.palette.draph.blue}`,
//           },
//           '& .photo-filter-name': {
//             fontWeight: 700,
//             fontSize: '1.2rem',
//             position: 'absolute',
//             transform: 'translate(-50%, 0%)',
//             left: '50%',
//             bottom: '1rem',
//             wordBreak: 'keep-all',
//             textAlign: 'center',
//           },
//         },
//         '& .default-slider': {
//           '& .MuiSlider-track': { backgroundColor: 'blue', borderColor: 'blue' },
//           '& .MuiSlider-thumb': { backgroundColor: 'blue' },
//         },
//       }}
//     >
//       <Stack>
//         <Typography className="title">카피라이트 타입</Typography>
//         <Box className="photo-filters">
//           {photoFilters.map(p => {
//             return (
//               <Box
//                 className="photo-filter box"
//                 key={p.label}
//                 onClick={filterHandler(p.type, p.value)}
//               >
//                 {/* <img className="photo-filter image" src={p.image} /> */}
//                 <Box className="photo-filter-name">{p.label}</Box>
//                 {p.type === filterType && <Box className="photo-filter selected-box"></Box>}
//               </Box>
//             )
//           })}
//         </Box>
//         <Typography className="title">밝기</Typography>
//         <Slider
//           className="default-slider"
//           value={eachFilter.brightness}
//           name={'brightness'}
//           onChange={onChangeHandler}
//           min={-0.5}
//           max={0.5}
//           step={0.01}
//         />
//       </Stack>

//       <Stack>
//         <Typography className="title">대비</Typography>
//         <Slider
//           className="default-slider"
//           value={eachFilter.contrast}
//           name={'contrast'}
//           onChange={onChangeHandler}
//           min={-100}
//           max={100}
//           step={1}
//         />
//       </Stack>

//       <Stack>
//         <Typography className="title">채도</Typography>
//         <Slider
//           className="default-slider"
//           value={eachFilter.saturation}
//           name={'saturation'}
//           onChange={onChangeHandler}
//           min={-2}
//           max={2}
//           step={0.1}
//         />
//       </Stack>

//       <Stack>
//         <Typography className="title">노이즈</Typography>
//         <Slider
//           className="default-slider"
//           value={eachFilter.noise}
//           name={'noise'}
//           onChange={onChangeHandler}
//           min={0}
//           max={1}
//           step={0.01}
//         />
//       </Stack>
//       <Stack>
//         <Typography className="title">블러</Typography>
//         <Slider
//           className="default-slider"
//           value={eachFilter.blurRadius}
//           name={'blurRadius'}
//           onChange={onChangeHandler}
//           min={0}
//           max={40}
//           step={1}
//         />
//       </Stack>
//       <Stack>
//         <Typography className="title">선명도 향상</Typography>
//         <Slider
//           className="default-slider"
//           value={eachFilter.enhance}
//           name={'enhance'}
//           onChange={onChangeHandler}
//           min={0}
//           max={1}
//           step={0.1}
//         />
//       </Stack>

//       <Stack>
//         <Typography className="title">빨강</Typography>
//         <Slider
//           value={eachFilter.red}
//           name={'red'}
//           onChange={onChangeHandler}
//           min={-50}
//           max={50}
//           step={1}
//           sx={{
//             '& .MuiSlider-track': { backgroundColor: 'red', borderColor: 'red' },
//             '& .MuiSlider-thumb': { backgroundColor: 'red' },
//           }}
//         />
//       </Stack>

//       <Stack>
//         <Typography className="title">초록</Typography>
//         <Slider
//           value={eachFilter.green}
//           name={'green'}
//           onChange={onChangeHandler}
//           min={-50}
//           max={50}
//           step={1}
//           sx={{
//             '& .MuiSlider-track': { backgroundColor: 'green', borderColor: 'green' },
//             '& .MuiSlider-thumb': { backgroundColor: 'green' },
//           }}
//         />
//       </Stack>

//       <Stack>
//         <Typography className="title">파랑</Typography>
//         <Slider
//           value={eachFilter.blue}
//           name={'blue'}
//           onChange={onChangeHandler}
//           min={-50}
//           max={50}
//           step={1}
//           sx={{
//             '& .MuiSlider-track': { backgroundColor: 'blue', borderColor: 'blue' },
//             '& .MuiSlider-thumb': { backgroundColor: 'blue' },
//           }}
//         />
//       </Stack>
//     </Stack>
//   )
// }

// const WholeFilterSettings = ({
//   pieceConfig,
//   selectedLayer,
//   setSelectedLayer,
//   retryHandler,
//   directConfigUpdate,
// }) => {
//   const theme = useTheme()
//   const [wholeFilter, setWholeFilter] = useRecoilState(bannerWholeFilterAtom)
//   const [filterType, setFilterType] = useState('original') // original, grain, nature, lightning, blackAndWhite, custom

//   const onChangeHandler = e => {
//     const value = e.target.value
//     const name = e.target.name
//     setFilterType('custom')
//     setWholeFilter(p => {
//       return { ...p, [name]: value }
//     })
//   }

//   const filterHandler = (type, value) => () => {
//     setFilterType(type)
//     setWholeFilter(value)
//   }

//   const photoFilters = [
//     {
//       value: {
//         brightness: 0,
//         contrast: 0,
//         saturation: 0,
//         noise: 0,
//         blurRadius: 0,
//         enhance: 0,
//         red: 0,
//         green: 0,
//         blue: 0,
//       },
//       label: '원본',
//       type: 'original',
//       image: '/static/images/theme_template/kitchen/default.png',
//     },
//     {
//       value: {
//         brightness: 0.4,
//         contrast: 0,
//         saturation: 0,
//         noise: 0,
//         blurRadius: 0,
//         enhance: 0,
//         red: 10,
//         green: 0,
//         blue: 0,
//       },
//       label: '스튜디오\n그레인',
//       type: 'grain',
//       image: '/static/images/theme_template/bubble/default.png',
//     },
//     {
//       value: {
//         brightness: 0,
//         contrast: 0,
//         saturation: 0,
//         noise: 0,
//         blurRadius: 0,
//         enhance: 0,
//         red: 0,
//         green: 0,
//         blue: 0,
//       },
//       label: '자연 채광',
//       type: 'nature',
//       image: '/static/images/theme_template/half/default.png',
//     },

//     {
//       value: {
//         brightness: 0,
//         contrast: 0,
//         saturation: 0,
//         noise: 0,
//         blurRadius: 0,
//         enhance: 0,
//         red: 0,
//         green: 0,
//         blue: 0,
//       },
//       label: '스튜디오\n라이팅',
//       type: 'lightning',
//       image: '/static/images/theme_template/office/default.png',
//     },

//     {
//       value: {
//         brightness: 0,
//         contrast: 0,
//         saturation: 0,
//         noise: 0,
//         blurRadius: 0,
//         enhance: 0,
//         red: 0,
//         green: 0,
//         blue: 0,
//       },
//       label: '흑백',
//       type: 'blackAndWhite',
//       image: '/static/images/theme_template/office/default.png',
//     },
//   ]

//   return (
//     <Stack
//       sx={{
//         gap: '2rem',
//         '& .photo-filters': { display: 'flex', flexFlow: 'wrap', gap: '0.8rem', mt: '1.6rem' },
//         '& .photo-filter': {
//           width: '7.6rem',
//           height: '12rem',
//           borderRadius: '4px',
//           cursor: 'pointer',
//           '&.box': {
//             backgroundColor: '#eee',
//             position: 'relative',
//           },
//           '&.image': {
//             objectFit: 'contain',
//           },
//           '&.selected-box': {
//             position: 'absolute',
//             border: `1px solid ${theme.palette.draph.blue}`,
//           },
//           '& .photo-filter-name': {
//             fontWeight: 700,
//             fontSize: '1.2rem',
//             position: 'absolute',
//             transform: 'translate(-50%, 0%)',
//             left: '50%',
//             bottom: '1rem',
//             wordBreak: 'keep-all',
//             textAlign: 'center',
//           },
//         },

//         '& .default-slider': {
//           '& .MuiSlider-track': { backgroundColor: 'blue', borderColor: 'blue' },
//           '& .MuiSlider-thumb': { backgroundColor: 'blue' },
//         },
//       }}
//     >
//       <Stack>
//         <Typography className="title">밝기</Typography>
//         <Slider
//           className="default-slider"
//           value={wholeFilter.brightness}
//           name={'brightness'}
//           onChange={onChangeHandler}
//           min={-0.5}
//           max={0.5}
//           step={0.01}
//         />
//       </Stack>

//       <Stack>
//         <Typography className="title">대비</Typography>
//         <Slider
//           className="default-slider"
//           value={wholeFilter.contrast}
//           name={'contrast'}
//           onChange={onChangeHandler}
//           min={-100}
//           max={100}
//           step={1}
//         />
//       </Stack>

//       <Stack>
//         <Typography className="title">채도</Typography>
//         <Slider
//           className="default-slider"
//           value={wholeFilter.saturation}
//           name={'saturation'}
//           onChange={onChangeHandler}
//           min={-2}
//           max={2}
//           step={0.1}
//         />
//       </Stack>

//       <Stack>
//         <Typography className="title">노이즈</Typography>
//         <Slider
//           className="default-slider"
//           value={wholeFilter.noise}
//           name={'noise'}
//           onChange={onChangeHandler}
//           min={0}
//           max={1}
//           step={0.01}
//         />
//       </Stack>
//       <Stack>
//         <Typography className="title">블러</Typography>
//         <Slider
//           className="default-slider"
//           value={wholeFilter.blurRadius}
//           name={'blurRadius'}
//           onChange={onChangeHandler}
//           min={0}
//           max={40}
//           step={1}
//         />
//       </Stack>
//       <Stack>
//         <Typography className="title">선명도 향상</Typography>
//         <Slider
//           className="default-slider"
//           value={wholeFilter.enhance}
//           name={'enhance'}
//           onChange={onChangeHandler}
//           min={0}
//           max={1}
//           step={0.1}
//         />
//       </Stack>

//       <Stack>
//         <Typography className="title">빨강</Typography>
//         <Slider
//           value={wholeFilter.red}
//           name={'red'}
//           onChange={onChangeHandler}
//           min={-50}
//           max={50}
//           step={1}
//           sx={{
//             '& .MuiSlider-track': { backgroundColor: 'red', borderColor: 'red' },
//             '& .MuiSlider-thumb': { backgroundColor: 'red' },
//           }}
//         />
//       </Stack>

//       <Stack>
//         <Typography className="title">초록</Typography>
//         <Slider
//           value={wholeFilter.green}
//           name={'green'}
//           onChange={onChangeHandler}
//           min={-50}
//           max={50}
//           step={1}
//           sx={{
//             '& .MuiSlider-track': { backgroundColor: 'green', borderColor: 'green' },
//             '& .MuiSlider-thumb': { backgroundColor: 'green' },
//           }}
//         />
//       </Stack>

//       <Stack>
//         <Typography className="title">파랑</Typography>
//         <Slider
//           value={wholeFilter.blue}
//           name={'blue'}
//           onChange={onChangeHandler}
//           min={-50}
//           max={50}
//           step={1}
//           sx={{
//             '& .MuiSlider-track': { backgroundColor: 'blue', borderColor: 'blue' },
//             '& .MuiSlider-thumb': { backgroundColor: 'blue' },
//           }}
//         />
//       </Stack>
//     </Stack>
//   )
// }

const ImageSettings = ({
  prompt,
  setPrompt,
  directConfigUpdate,
  selectedLayer,
  retryHandler,
  updatePieceHandler,
  directPieceUpdate,
  layer,
  configUpdate,
}) => {
  const theme = useTheme()

  const [isFocused, setIsFocused] = useState(false)

  const referenceInputRef = useRef()

  const [loadings, setLoadings] = useState({
    reference: false,
  })
  const [images, setImages] = useState([])

  const referenceUploadHandler = async e => {
    const name = e.target.name
    try {
      setLoadings(p => ({ ...p, [name]: true }))
      const formData = new FormData()
      formData.append('image', e.target.files[0])
      const sodImage = await apis.appfront.getSod(formData)
      const blobImage = base64ToBlob(JSON.parse(sodImage.data.sod_res.body), 'image/png')

      handleImageUpload(blobImage, name)
    } catch {
      setLoadings(p => ({ ...p, [name]: false }))
    }
  }

  const dragHandler = async (file, type) => {
    try {
      setLoadings(p => ({ ...p, [type]: true }))
      const formData = new FormData()

      formData.append('image', file)
      const sodImage = await apis.appfront.getSod(formData)
      const blobImage = base64ToBlob(JSON.parse(sodImage.data.sod_res.body), 'image/png')

      handleImageUpload(blobImage, type)
      // setLoadings(p => ({ ...p, [type]: false }))
    } catch (err) {
      setLoadings(p => ({ ...p, [type]: false }))
    }
  }

  const handleImageUpload = async (file, type) => {
    if (!file) return

    let newWidth = 400
    let newHeight = 400

    try {
      // 1. 먼저 파일을 로컬에서 읽기
      const localImageUrl = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const img = new window.Image()
          img.src = e.target.result
          img.onload = () => {
            const scale = Math.min(400 / img.width, 400 / img.height)

            newWidth = img.width * scale
            newHeight = img.height * scale
          }
          return resolve(e.target.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })

      // 2. 이미지 로드 확인
      await new Promise((resolve, reject) => {
        const img = new window.Image()
        img.onload = resolve
        img.onerror = reject
        img.src = localImageUrl
      })
      setImages([{ src: localImageUrl, type, file }])
      configUpdate('image_reference_image', localImageUrl)
      directPieceUpdate({ result_s3_url: localImageUrl, width: newWidth, height: newHeight })
    } catch (error) {
      console.error('Error uploading file:', error)
    } finally {
      setLoadings(p => ({ ...p, [type]: false }))
    }
  }

  const imageTypes = [
    {
      value: 'illustration',
      label: '일러스트',
      image: '/static/images/bannerV2/editor/illustration.png',
    },
    {
      value: 'concept',
      label: '실사 이미지',
      image: '/static/images/bannerV2/editor/concept.png',
    },
    {
      value: 'reference',
      label: '내 이미지',
      image: '/static/images/bannerV2/editor/reference.png',
    },
  ]

  return (
    <Stack
      sx={{
        '& .image-types-box': { display: 'flex', flexFlow: 'wrap', gap: '0.8rem', mt: '1.6rem' },
        '& .image-type': {
          width: '7.1rem',
          height: '12rem',
          borderRadius: '4px',
          cursor: 'pointer',
          '&.box': {
            backgroundColor: '#eee',
            position: 'relative',
          },
          '&.image': {
            objectFit: 'cover ',
          },
          '&.selected-box': {
            position: 'absolute',
            border: `2px solid ${theme.palette.draph.blue}`,
            top: 0,
          },
          '& .image-type-name': {
            fontWeight: 700,
            fontSize: '1.1rem',
            position: 'absolute',
            transform: 'translate(-50%, 0%)',
            left: '50%',
            bottom: '1rem',
            wordBreak: 'keep-all',
            textAlign: 'center',
            color: theme.palette.common.black,
          },
        },

        '& .upload-box': {
          width: '100%',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1.2rem',
          backgroundColor: '#eeeeee',
          borderRadius: '15px',
          mt: '1.6rem',
          '& svg': {
            transition: 'all 0.3s ease',
          },

          ' & .text': {
            fontSize: '1.6rem',
            fontWeight: 600,
            color: '#808080',
          },
          ' &.reference': {
            py: '3.6rem ',
            minHeight: '12.8rem',
            height: '12.8rem',
          },
        },
      }}
    >
      {' '}
      <CommonButtonArea
        retryHandler={() => {
          retryHandler({ selectedLayerId: layer.id, newConfig: layer.config })
        }}
        updatePieceHandler={updatePieceHandler}
        selectedLayer={selectedLayer}
        addShapeConfig={{
          image_type: layer.config.image_type,
          image_reference_image: layer.config.image_reference_image,
        }}
        regenText={'생성'}
        fixText={'위치/사이즈 적용'}
      />
      <Typography className="title">디자인 요소 유형</Typography>
      <Box className="image-types-box">
        {imageTypes.map(i => {
          return (
            <Box
              className="image-type box"
              key={i.label}
              onClick={() => {
                configUpdate('image_type', i.value)
              }}
            >
              <img className="image-type image" src={i.image} />
              <Box className="image-type-name">{i.label}</Box>
              {i.value === layer.config.image_type && (
                <Box className="image-type selected-box"></Box>
              )}
            </Box>
          )
        })}
      </Box>
      <Collapse
        in={layer.config.image_type === 'illustration' || layer.config.image_type === 'concept'}
      >
        <Typography className="title" sx={{ mt: '2.4rem' }}>
          프롬프트
        </Typography>
        <CenterAlignBox
          sx={{
            mt: '1.6rem',
            alignItems: 'center',
            width: '100%',
            borderRadius: '10px 10px 0 0',
            backgroundColor: isFocused ? theme.palette.draph.blue : theme.palette.common.black,
            color: theme.palette.common.white,
            fontSize: '1.2rem',
            fontWeight: 700,
            height: '2.8rem',
          }}
        >
          프롬프트 입력
        </CenterAlignBox>
        <CustomOutlinedInput
          value={layer.config[`image_${layer.config.image_type}_prompt`]}
          onChange={e => {
            configUpdate(`image_${layer.config.image_type}_prompt`, e.target.value)
          }}
          // value={customTemplate}
          // onChange={e => {
          //   setCustomTemplate(e.target.value)
          // }}

          type="text"
          inputProps={{ maxLength: 1000 }}
          multiline
          minRows={10}
          maxRows={10}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          sx={{
            height: 'auto',
            width: '100%',
            p: '1.2rem',

            borderRadius: '0 0 10px 10px',
            fontSize: '1.2rem',
            fontWeight: 700,

            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                borderWidth: '0.1rem',
                borderColor: layer.config[[`image_${layer.config.image_type}_prompt`]]
                  ? '#000000'
                  : '#D9D9D9',
                // borderRadius: '10px',
              },
            },
          }}
        />
      </Collapse>
      <Collapse in={layer.config.image_type === 'reference'}>
        <Typography className="title" sx={{ mt: '2.4rem' }}>
          이미지 추가
        </Typography>
        {layer.config.image_type === 'reference' &&
        (layer.config.image_reference_image || images.length) ? (
          <Stack
            className={`upload-box logo`}
            sx={{ p: '1.6rem !important', position: 'relative' }}
          >
            <img
              style={{
                width: '100%', // 변경: maxWidth에서 width로
                height: '100%', // 변경: maxHeight에서 height로
                objectFit: 'contain',
                zIndex: 1,
                cursor: 'grab',
              }}
              src={layer.config.image_reference_image ?? images[0].src}
            />
            <IconButton
              sx={{ position: 'absolute', top: '-1.3rem', right: '-1.3rem' }}
              onClick={() => {
                setImages([])
                directPieceUpdate({ result_s3_url: '' })
                configUpdate('image_reference_image', '')
              }}
            >
              <CloseBlackIcon />
            </IconButton>
          </Stack>
        ) : (
          <AnimatedUploadBox
            type={'reference'}
            onDrop={dragHandler}
            onClick={() => {
              referenceInputRef.current.click()
            }}
            loadings={loadings}
          />
        )}

        <input
          type="file"
          ref={referenceInputRef}
          style={{ display: 'none' }}
          accept={allowedTypes.join(', ')}
          name="reference"
          onChange={referenceUploadHandler}
        />
      </Collapse>
    </Stack>
  )
}

export const CommonButtonArea = ({
  retryHandler,
  updatePieceHandler,
  selectedLayer,
  newConfig,
  addShapeConfig = {},
  regenText,
  fixText,
}) => {
  const theme = useTheme()

  const shapeType = selectedLayer.config.type

  const regenShapes =
    shapeType === 'background' ||
    (shapeType === 'image' && selectedLayer.config.image_type !== 'paste') ||
    shapeType === 'inpaint_mask' ||
    (shapeType === 'text' && selectedLayer.config.text_gen_flag)
  const fixShapes =
    shapeType === 'text' ||
    (shapeType === 'image' && selectedLayer.config.image_type !== 'inpaint') ||
    shapeType === 'logo'

  const [bannerV2WarningDetect, setBannerV2WarningDetect] =
    useRecoilState(bannerV2WarningDetectAtom)

  const [bannerV2WarningDialog, setBannerV2WarningDialog] =
    useRecoilState(bannerV2WarningDialogAtom)

  useEffect(() => {
    if (bannerV2WarningDetect.detect) {
      setBannerV2WarningDialog({
        open: true,
        confirmCallback: () =>
          updatePieceHandler({
            hasChangeFilter:
              selectedLayer.config.type === 'image' && selectedLayer.config.image_type === 'paste',
            addShapeConfig,
          }),
        cancelCallback: bannerV2WarningDetect.cancelCallback,
      })
    }
  }, [bannerV2WarningDetect.detect])

  return (
    <CenterAlignBox
      sx={{
        my: '2.4rem',
        // px: '2rem',
        gap: '0.8rem',
        '& .box': {
          flex: 1,
          height: '4rem',
          fontSize: '1.6rem',
          fontWeight: 600,
          alignItems: 'center',
          borderRadius: '4px',
          cursor: 'pointer',

          '& .content': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            transition: 'transform 0.3s ease-in-out', // transition 추가
          },

          '&.regenerate': {
            color: theme.palette.draph.blue,
            backgroundColor: '#E3ECFF',

            '&:hover .content': {
              transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
            },
          },

          '&.accept': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.draph.blue,

            '&:hover .content': {
              transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
            },
          },
        },
      }}
    >
      {regenShapes && (
        <CenterAlignBox className="box regenerate" onClick={retryHandler}>
          <Box className="content">
            <RegenerateIcon color={theme.palette.draph.blue} />
            {regenText || '생성'}
          </Box>
        </CenterAlignBox>
      )}

      {/* {fixShapes && (
        <CenterAlignBox
          className="box accept"
          onClick={() =>
            updatePieceHandler({
              hasChangeFilter:
                selectedLayer.config.type === 'image' &&
                selectedLayer.config.image_type === 'paste',
              addShapeConfig,
            })
          }
        >
          <Box className="content">
            <CircleCheckIcon color={theme.palette.common.white} />
            {fixText || '적용'}
          </Box>
        </CenterAlignBox>
      )} */}
    </CenterAlignBox>
  )
}

export const InpaintMaskSettings = ({ pieceConfig, selectedLayer, retryHandler }) => {
  const theme = useTheme()

  // 위치는 shapes 에 가져와서 사용해야 여기 layer 는 Config 를 변경하는용도

  return (
    <>
      <CommonButtonArea
        retryHandler={() => {
          // 지금 들어온 상태의 bg의 Config
          const shape = pieceConfig.shapes.find(s => s.config.type === 'background')
          // 이건 실시간으로 변경된 shapes의 x,y,width,height 을 사용
          const bbox = [
            Math.round(selectedLayer.x),
            Math.round(selectedLayer.y),
            Math.round(selectedLayer.x + selectedLayer.width),
            Math.round(selectedLayer.y + selectedLayer.height),
          ]
          // 이건 실시간으로 변경된 shapes의 x,y,width,height 을 사용
          const newConfig = { ...shape.config, bg_inpaint_background_mask_bbox: bbox }

          // background 의 id 와 재새성할 config 주입
          retryHandler({ selectedLayerId: shape.id, newConfig })
        }}
        // updatePieceHandler={updatePieceHandler}
        selectedLayer={selectedLayer}
      />
    </>
  )
}

export const LogoSettings = ({
  pieceConfig,
  selectedLayer,
  retryHandler,
  updatePieceHandler,
  directConfigUpdate,
  directPieceUpdate,
  layer,
  configUpdate,
}) => {
  const theme = useTheme()

  const logoInputRef = useRef()

  const [loadings, setLoadings] = useState({
    logo: false,
  })
  const [images, setImages] = useState([])

  useEffect(() => {})

  const logoUploadHandler = async e => {
    const name = e.target.name
    try {
      setLoadings(p => ({ ...p, [name]: true }))
      const formData = new FormData()
      formData.append('logo_image', e.target.files[0])
      const sodImage = await apis.appfront.getCroppedLogoSod(formData)
      const blobImage = base64ToBlob(sodImage.data.sod_result, 'image/png')

      handleImageUpload(blobImage, name)
    } catch {
      setLoadings(p => ({ ...p, [name]: false }))
    }
  }

  const dragHandler = async (file, type) => {
    try {
      setLoadings(p => ({ ...p, [type]: true }))
      const formData = new FormData()

      formData.append('image', file)
      const sodImage = await apis.appfront.getCroppedLogoSod(formData)
      const blobImage = base64ToBlob(JSON.parse(sodImage.data.sod_res.body), 'image/png')

      handleImageUpload(blobImage, type)
    } catch {
      setLoadings(p => ({ ...p, [type]: false }))
    }
  }

  const handleImageUpload = async (file, type) => {
    if (!file) return

    let newWidth = 400
    let newHeight = 400

    try {
      // 1. 먼저 파일을 로컬에서 읽기
      const localImageUrl = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const img = new window.Image()
          img.src = e.target.result
          img.onload = () => {
            const scale = Math.min(400 / img.width, 400 / img.height)

            newWidth = img.width * scale
            newHeight = img.height * scale
          }
          return resolve(e.target.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })

      // 2. 이미지 로드 확인
      await new Promise((resolve, reject) => {
        const img = new window.Image()
        img.onload = resolve
        img.onerror = reject
        img.src = localImageUrl
      })

      // 6. 모든 상태 업데이트를 한번에 수행
      setImages([{ src: localImageUrl, type, file }])
      configUpdate('logo_image_url', localImageUrl)
      directPieceUpdate({ result_s3_url: localImageUrl, width: newWidth, height: newHeight })
    } catch (error) {
      console.error('Error uploading file:', error)
    } finally {
      setLoadings(p => ({ ...p, [type]: false }))
    }
  }

  return (
    <Stack
      sx={{
        '& .upload-box': {
          width: '100%',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1.2rem',
          backgroundColor: '#eeeeee',
          borderRadius: '15px',
          mt: '1.6rem',
          '& svg': {
            transition: 'all 0.3s ease',
          },

          ' & .text': {
            fontSize: '1.6rem',
            fontWeight: 600,
            color: '#808080',
          },
          ' &.logo': {
            py: '3.6rem ',
            minHeight: '12.8rem',
            height: '12.8rem',
          },
        },
      }}
    >
      <CommonButtonArea
        retryHandler={() => {}}
        updatePieceHandler={updatePieceHandler}
        selectedLayer={selectedLayer}
        addShapeConfig={{
          logo_image_url: layer.config.logo_image_url,
        }}
      />
      <Typography className="title">이미지 추가</Typography>
      {layer.config.logo_image_url || images.length ? (
        <>
          <Stack
            className={`upload-box logo`}
            sx={{ p: '1.6rem !important', position: 'relative' }}
          >
            <img
              style={{
                width: '100%', // 변경: maxWidth에서 width로
                height: '100%', // 변경: maxHeight에서 height로
                objectFit: 'contain',
                zIndex: 1,
                cursor: 'grab',
              }}
              src={layer.config.logo_image_url ?? images[0].src}
            />
            <IconButton
              sx={{ position: 'absolute', top: '-1.3rem', right: '-1.3rem' }}
              onClick={() => {
                setImages([])
                directPieceUpdate({ result_s3_url: '' })
              }}
            >
              <CloseBlackIcon />
            </IconButton>
          </Stack>
        </>
      ) : (
        <AnimatedUploadBox
          type={'logo'}
          onDrop={dragHandler}
          onClick={() => {
            logoInputRef.current.click()
          }}
          loadings={loadings}
        />
      )}

      <input
        type="file"
        ref={logoInputRef}
        style={{ display: 'none' }}
        accept={allowedTypes.join(', ')}
        name="logo"
        onChange={logoUploadHandler}
      />
    </Stack>
  )
}

const CoverSettings = ({
  pieceConfig,
  updatePieceHandler,
  selectedLayer,
  directConfigUpdate,
  retryHandler,
  layer,
  configUpdate,
}) => {
  const theme = useTheme()

  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    const shape = pieceConfig.shapes.find(s => s.config.type === 'background')
    configUpdate(
      'image_inpaint_img_url',
      shape.result_s3_url.includes('https://upload-pipeline-data')
        ? shape.result_s3_url
        : getS3ImageSrc(shape.result_s3_url)
    )
  }, [])

  return (
    <Stack>
      {' '}
      {selectedLayer && (
        <CommonButtonArea
          retryHandler={() => {
            retryHandler({
              selectedLayerId: layer.id,
              newConfig: {
                ...layer.config,
                image_inpaint_bbox: [
                  Math.ceil(selectedLayer.x),
                  Math.ceil(selectedLayer.y),
                  Math.ceil(selectedLayer.x + selectedLayer.width),
                  Math.ceil(selectedLayer.y + selectedLayer.height),
                ],
              },
            })
          }}
          updatePieceHandler={updatePieceHandler}
          selectedLayer={selectedLayer}
        />
      )}
      <Typography className="title">프롬프트 입력</Typography>
      <CenterAlignBox
        sx={{
          mt: '1.6rem',
          alignItems: 'center',
          width: '100%',
          borderRadius: '10px 10px 0 0',
          backgroundColor: isFocused ? theme.palette.draph.blue : theme.palette.common.black,
          color: theme.palette.common.white,
          fontSize: '1.2rem',
          fontWeight: 700,
          height: '2.8rem',
        }}
      >
        프롬프트
      </CenterAlignBox>
      <CustomOutlinedInput
        value={layer.config.image_inpaint_prompt}
        onChange={e => {
          configUpdate('image_inpaint_prompt', e.target.value)
        }}
        // value={customTemplate}
        // onChange={e => {
        //   setCustomTemplate(e.target.value)
        // }}

        type="text"
        inputProps={{ maxLength: 1000 }}
        multiline
        minRows={10}
        maxRows={10}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        sx={{
          height: 'auto',
          width: '100%',
          p: '1.2rem',

          borderRadius: '0 0 10px 10px',
          fontSize: '1.2rem',
          fontWeight: 700,

          '&.MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: '0.1rem',
              borderColor: layer.config.image_inpaint_prompt ? '#000000' : '#D9D9D9',
              // borderRadius: '10px',
            },
          },
        }}
      />
    </Stack>
  )
}

const GenFontSettings = ({
  pieceConfig,
  updatePieceHandler,
  selectedLayer,
  directConfigUpdate,
  retryHandler,
  setIsLoading,
  layer,
  setLayer,
  configUpdate,
}) => {
  const theme = useTheme()

  const [isFocused, setIsFocused] = useState(false)

  const [selectedLoading, setSelectedLoading] = useRecoilState(bannerSelectedLoadingAtom)
  const [selectedFontLists, setSelectedFontLists] = useRecoilState(bannerSelectedFontListsAtom)

  const fontQueries = useQueries({
    queries: selectedFontLists.map(font => ({
      queryKey: ['font', font],
      queryFn: () => apis.common.getFont(font),
      select: response => response.data,
      enabled: Boolean(font), // font가 있을 때만 쿼리 실행
    })),
  })

  const selectedFontListsData = fontQueries
    .map(query => query.data)
    .filter(Boolean)
    .flat()

  const { data, error, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteFont()

  const fontLists = data ?? []

  const getFontFaces = fonts => {
    if (fonts && fonts.map) {
      return fonts
        .map(font => {
          const format = font.cdn_path.split('.').at(-1)

          return `
            @font-face {
              font-family: '${font.id}';
              src: url('${font.cdn_path}') format('${format}');
              font-weight: normal;
              font-style: normal;
            }
          `
        })
        .join('\n')
    }
  }

  const handleCopyTypeChange = async copyType => {
    setIsLoading(true)
    configUpdate('text_type', copyType.value)

    const { avatar, ...rest } = layer
    const newConfig = {
      ...rest,
      config: {
        ...rest.config,
        text_type: copyType.value,
      },
    }

    try {
      const body = {
        layer_info: JSON.stringify(newConfig),
        default_presets: JSON.stringify(pieceConfig.defaultConfig),
        copy_style: copyType.value,
        lang: 'ko',
      }

      const { data: copyData } = await apis.appfront.copyRetry(body)

      if (copyData.code === 500) {
        return enqueueSnackbar('에러가 발생했습니다.', { variant: 'error' })
      }

      configUpdate('text_custom', copyData.recommended_copy)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    configUpdate(
      'text_custom',
      layer?.config?.text_custom.length ? layer?.config?.text_custom : ['문구를 입력해주세요.']
    )
  }, [])

  return (
    <Stack
      sx={{
        '& .button-box': {
          display: 'flex',
          width: '100%',
          gap: '1rem',
          flexFlow: 'wrap',
          my: '1.7rem',
        },
        '& .copy-button': {
          width: 'calc((100% - 2rem) / 3)',
        },

        '& .text-stack': {
          mt: '2.4rem',
        },

        '& .text-style-box': {
          display: 'flex',
          gap: '0.8rem',

          '& .MuiFormControl-root': {
            width: '100%',
          },
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            width: '100%',
          },

          '& .select-box-title': {
            position: 'absolute',
            fontSize: '1.2rem',
            color: '#808080',
            px: '0.4rem',
            backgroundColor: '#fff',
            top: 0,

            transform: 'translate(0, -50%)',
          },
        },
      }}
    >
      <FontManager fonts={data} />
      {selectedLayer && (
        <CommonButtonArea
          retryHandler={() => {
            retryHandler({
              selectedLayerId: layer.id,
              newConfig: {
                ...layer.config,
                text_type: 'custom',
                text_size: 'auto',
                text_list: selectedLayer.config.text_list,
                bbox: [
                  Math.ceil(selectedLayer.x),
                  Math.ceil(selectedLayer.y),
                  Math.ceil(selectedLayer.x + selectedLayer.width),
                  Math.ceil(selectedLayer.y + selectedLayer.height),
                ],
              },
            })
          }}
          updatePieceHandler={updatePieceHandler}
          selectedLayer={selectedLayer}
        />
      )}
      <Stack className="copy-stack">
        <Typography className="title">카피라이트 타입</Typography>

        <Box className="button-box">
          {COPY_TYPE_OPTIONS.map(option => {
            return (
              <CustonBannerToggleButton
                key={option.value}
                className="copy-button"
                variant="cantained"
                selected={layer.config.text_type === option.value}
                value={option.value}
                onClick={() => handleCopyTypeChange(option)}
                disabled={selectedLoading}
              >
                {option.label}
              </CustonBannerToggleButton>
            )
          })}
        </Box>
      </Stack>
      <Typography className="title" sx={{ m: '1.6rem 0 2rem 0' }}>
        텍스트 디자인
      </Typography>
      <Box className="text-style-box">
        <FontSelect selectedLayer={layer} directConfigUpdate={configUpdate} />
      </Box>

      <Typography className="title" sx={{ m: '1.6rem 0 1rem 0' }}>
        미리보기
      </Typography>
      <Box
        sx={{
          p: '0.5rem 0.8rem',
          background: '#dedede',
          minHeight: '3rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px 4px 0 0',
        }}
      >
        <Typography
          sx={{
            fontFamily: layer.config.text_font,
            textAlign: layer.config.text_horizontal_align,
            whiteSpace: 'pre-wrap', // 이 부분을 추가
          }}
        >
          {layer.config?.text_custom.length
            ? layer.config?.text_custom.join('\n')
            : '미리보기입니다.'}
        </Typography>
      </Box>
      <CustomOutlinedInput
        value={
          typeof layer.config.text_custom === 'string'
            ? layer.config.text_custom
            : layer.config.text_custom.join('\n')
        }
        onChange={e => {
          const newText = e.target.value.split('\n')
          const processedText = newText.length === 1 && newText[0] === '' ? [] : newText
          configUpdate('text_custom', processedText)
        }}
        // value={customTemplate}
        // onChange={e => {
        //   setCustomTemplate(e.target.value)
        // }}

        type="text"
        inputProps={{ maxLength: 1000 }}
        multiline
        minRows={3}
        maxRows={3}
        // onFocus={() => setIsFocused(true)}
        // onBlur={() => setIsFocused(false)}
        sx={{
          height: 'auto',
          width: '100%',
          p: '1.2rem',
          top: '-0.1rem',

          borderRadius: '0 0 10px 10px',
          fontSize: '1.2rem',
          fontWeight: 700,

          '&.MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: '0.1rem',
              borderColor: layer.config.text_custom ? '#000000' : '#D9D9D9',
              // borderRadius: '10px',
            },
          },
        }}
      />
      <CenterAlignBox
        sx={{
          mt: '1.6rem',
          alignItems: 'center',
          width: '100%',
          borderRadius: '10px 10px 0 0',
          backgroundColor: isFocused ? theme.palette.draph.blue : theme.palette.common.black,
          color: theme.palette.common.white,
          fontSize: '1.2rem',
          fontWeight: 700,
          height: '2.8rem',
        }}
      >
        프롬프트
      </CenterAlignBox>
      <CustomOutlinedInput
        value={layer.config.text_gen_prompt}
        onChange={e => {
          configUpdate('text_gen_prompt', e.target.value)
        }}
        // value={customTemplate}
        // onChange={e => {
        //   setCustomTemplate(e.target.value)
        // }}

        type="text"
        inputProps={{ maxLength: 1000 }}
        multiline
        minRows={3}
        maxRows={3}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        sx={{
          height: 'auto',
          width: '100%',
          p: '1.2rem',

          borderRadius: '0 0 10px 10px',
          fontSize: '1.2rem',
          fontWeight: 700,

          '&.MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: '0.1rem',
              borderColor: layer.config.text_gen_prompt ? '#000000' : '#D9D9D9',
              // borderRadius: '10px',
            },
          },
        }}
      />
    </Stack>
  )
}
