import { Stack, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { AssetImage } from './AssetImage'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export const AssetGridItem = ({ onClick, asset, height = 220 }) => {
  const { t } = useTranslation()

  return (
    <Grid2 item key={asset.id} sx={{ cursor: 'pointer' }} xs={1} onClick={onClick}>
      <Stack
        selected={asset.selected}
        selectMode={true}
        sx={{
          gap: '8px',
        }}
      >
        <AssetImage height={height} url={asset.file_url} />
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack gap={'2px'} sx={{ width: 'calc(100% - 30px)' }}>
            <Typography
              sx={{
                fontSize: '1.4rem',
                color: asset.selected ? 'draph.blue' : 'black',
                fontWeight: 600,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {asset.name}
            </Typography>
            <Typography
              sx={{
                fontSize: '1rem',
                color: asset.selected ? 'draph.blue' : 'black',
                fontWeight: 400,
              }}
            >
              {dayjs(asset.created_at).format('YYYY-MM-DD')}
              {t('asset.my_asset_dialog.submit')}
            </Typography>
          </Stack>
          {asset.selected ? <SelectedIcon /> : <UnselectedIcon />}
        </Stack>
      </Stack>
    </Grid2>
  )
}

const UnselectedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    style={{ marginRight: '4px' }}
  >
    <path
      d="M23 12C23 18.0753 18.0753 23 12 23C5.92469 23 1 18.0753 1 12C1 5.92469 5.92469 1 12 1C18.0753 1 23 5.92469 23 12Z"
      fill="white"
      fillOpacity="0.6"
      stroke="#BBBBBB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.90234 12.0956L10.3583 15.5516L17.2703 8.63965"
      stroke="#BBBBBB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const SelectedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: '4px' }}
  >
    <g id="Group 6271">
      <path
        id="Vector"
        d="M12 24C18.6276 24 24 18.6276 24 12C24 5.3724 18.6276 0 12 0C5.3724 0 0 5.3724 0 12C0 18.6276 5.3724 24 12 24Z"
        fill="#2C4DFF"
      />
      <path
        id="Vector_2"
        d="M6.90234 12.0956L10.3583 15.5516L17.2703 8.63965"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
