import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  SwipeableDrawer,
  useTheme,
  Typography,
} from '@mui/material'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { HomePopupCheckBox, HomePopupFilledCircle } from 'theme/icon'

import { useRecoilState, useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { iOS } from 'utils/common'
import { useTranslation } from 'react-i18next'
import { English, getStaticResourceLanguage, Japanese, Korean } from 'hooks/useLanguage'
import { userAtom } from '../atoms'

export function PromotionPopup({ contents, days = 1 }) {
  const [showPopup, setShowPopup] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [popupCheck, setPopupCheck] = useState(false)

  const user = useRecoilValue(userAtom)

  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const theme = useTheme()

  const millsecondTransfer = days => {
    return days * 24 * 60 * 60 * 1000
  }

  const handleClosePopup = () => {
    if (popupCheck) {
      localStorage.setItem('popupClosedTime', Date.now())
    }
    setShowPopup(false)
  }

  const onLoad = () => {
    setImageLoaded(true)
  }

  const popCheckHandle = e => {
    setPopupCheck(e.target.checked)
  }

  useEffect(() => {
    const lastClosedTime = localStorage.getItem('popupClosedTime')
    const threeDaysInMilliseconds = millsecondTransfer(days)

    if (!lastClosedTime || Date.now() - lastClosedTime > threeDaysInMilliseconds) {
      setShowPopup(true)
    }
  }, [])

  useEffect(() => {
    if (showPopup) {
      // ----- GA4 event -----
      const v = user?.id ? 'login_banner_launch' : 'logout_banner_launch'
      window.gtag('event', 'popup_banner_imp', { creative_name: v })
      // ---------------------
    }
  }, [showPopup])

  const handleClick = () => {
    // ----- GA4 event -----
    const v = user?.id ? 'login_banner_backgrounds_quality' : 'logout_banner_backgrounds_quality'
    window.gtag('event', 'popup_banner_click', { creative_name: v })
    // ---------------------

    navigate('/event/ko/normal/kakaotalk_openchat_open ')
  }

  return (
    <>
      <Desktop>
        <Dialog
          onClose={handleClosePopup}
          open={showPopup}
          sx={{
            '& .MuiBackdrop-root': {
              background: 'rgba(0, 0, 0, 0.5) ',
            },
            '& .MuiDialog-paper': {
              borderRadius: '20px',
              boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <DialogContent
            sx={{ position: 'relative', padding: 0, overflow: 'hidden', minHeight: '55rem' }}
          >
            <img
              loading="lazy"
              onLoad={onLoad}
              onClick={handleClick}
              style={{
                width: '50rem',
                height: 'auto',
                opacity: imageLoaded ? 1 : 0, // Show the image when loaded
                transition: 'opacity 0.5s ease-in',
                cursor: 'pointer',
              }}
              src={`/static/images/popup_${getStaticResourceLanguage(i18n.language)}.png`}
            />
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '6.1rem',
              paddingLeft: '2.4rem',
              paddingRight: '2rem',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox icon={<HomePopupCheckBox />} checkedIcon={<HomePopupFilledCircle />} />
              }
              checked={popupCheck}
              onChange={popCheckHandle}
              label={
                <Typography
                  sx={{
                    fontSize: '2.2rem',
                    fontWeight: 500,
                    mr: '2rem',
                    color: popupCheck ? theme.palette.draph.blue : 'unset',
                  }}
                >
                  <Korean>{days + '일 동안 보지 않기'}</Korean>
                  <Japanese>{days + '日間非表示にする'}</Japanese>
                  <English>{`Don't show this for ${days} days`}</English>
                </Typography>
              }
            />

            <Button
              variant="text"
              onClick={handleClosePopup}
              sx={{
                fontSize: '2.2rem',
                fontWeight: 500,
                color: theme.palette.common.black,
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              {t('button.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Desktop>

      <Mobile>
        <SwipeableDrawer
          open={showPopup}
          onOpen={() => {
            return showPopup
          }}
          onClose={handleClosePopup}
          anchor="bottom"
          sx={{
            '& .MuiBackdrop-root': {
              background: 'rgba(0, 0, 0, 0.5) ',
            },
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
              boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
            },
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <CenterAlignStack sx={{ padding: 0, overflow: 'hidden', backgroundColor: '#FFE403' }}>
            <img
              loading="lazy"
              onLoad={onLoad}
              onClick={handleClick}
              style={{
                objectFit: 'cover',
                // 양옆이 단색 백경으로 어려운 경우 너비를 지정하지 않음
                width: '36rem',
                height: 'auto',
                opacity: imageLoaded ? 1 : 0, // Show the image when loaded
                transition: 'opacity 0.5s ease-in',
                cursor: 'pointer',
                minHeight: '39.6rem',
              }}
              src={`/static/images/popup_${getStaticResourceLanguage(i18n.language)}.png`}
            />
            <CenterAlignBox
              sx={{
                width: '100%',
                height: '5.2rem',
                backgroundColor: '#FFF',
                display: 'flex',
                justifyContent: 'space-between',
                px: '1.5rem',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ padding: '0.5rem 0.5rem 0.5rem 0.8rem' }}
                    icon={<HomePopupCheckBox style={{ width: '2rem', height: '2rem' }} />}
                    checkedIcon={
                      <HomePopupFilledCircle style={{ width: '2rem', height: '2rem' }} />
                    }
                  />
                }
                checked={popupCheck}
                onChange={popCheckHandle}
                label={
                  <Typography sx={{ fontSize: '1.6rem', fontWeight: 600, mr: '2rem' }}>
                    <Japanese>{days + '日間非表示にする'}</Japanese>
                    <Korean>{days + '일 동안 보지 않기'}</Korean>
                    <English>{`Don't show this for ${days} days`}</English>
                  </Typography>
                }
              />
              <Button
                variant="text"
                onClick={handleClosePopup}
                sx={{
                  fontSize: '1.6rem',
                  fontWeight: 500,
                  color: theme.palette.common.black,
                  minWidth: 'auto',
                }}
              >
                {t('button.close')}
              </Button>
            </CenterAlignBox>
          </CenterAlignStack>
        </SwipeableDrawer>
      </Mobile>
    </>
  )
}
