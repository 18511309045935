import crudAxios from './configs/crud'
// const user = useRecoilValue(userAtom)

export const assetsAPI = {
  getAsset: id => {
    return crudAxios.get(`/asset/assets/${id}`)
  },
  getAssets: (id, page) => {
    return crudAxios.get(`/asset/user/${id}/assets?page=${page}`)
  },
  postPresigned: (id, body) => {
    return crudAxios.post(`/asset/user/${id}/assets/presigned?num_assets=1`, body)
  },
  postActivate: assetId => {
    return crudAxios.post(`/asset/assets/${assetId}/activate`, {})
  },
  deleteAsset: id => {
    return crudAxios.delete(`/asset/assets/${id}`)
  },
  searchAsset: (userId, name, page) => {
    return crudAxios.get(
      `/user/${userId}/assets/search?name=${name}&is_active=true&page=${page}&page_size=10`
    )
  },
}
