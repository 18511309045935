import { configData } from 'config'
import { Box, Typography, Button, Stack, Select, MenuItem, Card } from '@mui/material'
import {
  ScrollToTop,
  ArtworkList,
  DevEndpointDialog,
  SearchInput,
  ArtworkFilters,
  UploadWrapper,
  UploadMannequinDialog,
  CenterAlignStack,
  CenterAlignBox,
  DragDropFullSize,
  EmptyArtworkList,
  PleaseLoginDialog,
  UploadHeader,
  MannequinConfigComponent,
  GuideComponent,
  ModelBgConfigComponent,
  ModelFaceConfigComponent,
  ModelRenerateDialog,
  MobileModelUploadOld,
  triggerGA4UploadEventManualConfig,
} from 'components'
import { BeforeAfterArrow, BulbIcon, SettingIcon } from 'theme/icon'
import { styled, useTheme } from '@mui/material/styles'

import { useEffect, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useResetRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  portfolioDetailAtom,
  portfolioArtworkAtom,
  userAtom,
  portfolioConfigAtom,
  artworkViewConfigAtom,
  userLikedBgAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  tutorialAtom,
  endpointAtom,
  artworkListsIsFoldedAtom,
  defaultPortfolioAtom,
  portfolioAtom,
  autoUploadAtom,
  dragDropOpenAtom,
  uploadDialogOpenAtom,
  portfolioTypeAtom,
  uploadFilesAtom,
  segmentLoadingAtom,
  segmentStepAtom,
  mannequinLoadingAtom,
  selectedMaskImgAtom,
  retryMannequinAtom,
  uploadFilesAndUrlAtom,
  maskImgArrayAtom,
} from 'atoms'
import { apis } from 'apis'
import { BarLoader as Loader, PuffLoader } from 'react-spinners'
import {
  creditPolicyDictSelector,
  isPortfolioOwnerSelector,
  portfolioUploadConfigSelector,
} from 'selector'
import useConfirm from 'hooks/useConfirm'
import _, { debounce } from 'lodash'
import axios from 'axios'
import { getAccessToken } from 'utils/cookie'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Tutorial } from 'theme/Tutorial'
import { APPBAR_DESKTOP, APPBAR_MOBILE } from 'layouts/main/MainAppBar'
import MainFooter from 'layouts/main/MainFooter'
import { endpointCookieName } from 'components/portfolio/DevEndpointDialog'
import * as config from 'config'
import { useMotionValueEvent, useScroll, useTransform } from 'framer-motion'

import { PAGE_HEADER_HEIGHT, ScrollHandler } from './PortfolioDetail'
import { useTranslation } from 'react-i18next'
import { EmptyPageHeader, EmptyUploadHeader, PageHeaderLayout } from './PortfolioUpload'
import usePleaseLoginDialog from 'hooks/usePleaseLoginDialog'
import { usePortfolio } from 'hooks/usePortfolio'
import { ModelFaceRegenDialog } from './ModelFace'
import { English, Japanese, Korean } from 'hooks/useLanguage'
import { imgMergeAndGrayScale } from 'components/portfolio/UploadMannequinDialog'
import { getUserType } from 'utils/user'
import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import { blobToRLE } from 'components/fragment/fragmentHelpers'

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    // alignItems: 'center',
  },
}))

const PageHeaderStyle = styled(Box)(({ theme }) => ({
  top: 0,
  minHeight: 'auto',
  justifyContent: 'center',
  alignItems: 'end',

  bordercolor: theme.palette.common.black,
  background: theme.palette.common.white,
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingTop: '5.4rem',
    minHeight: PAGE_HEADER_HEIGHT,

    paddingBottom: '3.8rem',
    display: 'flex',
    flexDirection: 'row',
  },
}))

const uploadImglimit = 20

export default function Mannequin() {
  const tutorial = useRecoilValue(tutorialAtom)

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [uploadFiles, setUploadFiles] = useRecoilState(uploadFilesAtom)
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const isMobile = useMobileMediaQuery()

  useEffect(() => {
    // ----- GA4 event -----
    window.gtag('event', 'mannequin', {})
    // ---------------------
    return () => {}
  }, [])

  const token = getAccessToken()
  if (!token)
    return (
      <RootStyle>
        <Mobile>
          <EmptyPageHeader />
        </Mobile>

        <Desktop>
          <EmptyUploadHeader videoSrc={config.GUIDE_VIDEOS.mannequin} isModel={true} />
        </Desktop>
        <EmptyArtworkList />
      </RootStyle>
    )

  return (
    <>
      <RootStyle
        style={{
          // overflow: tutorial.step === 15 ? 'hidden' : 'scroll',
          zIndex: tutorial.step === 17 ? '-1' : null,
        }}
        // onDragEnter={e => {
        //   e.preventDefault()
        //   e.stopPropagation()

        //   setDragDropOpen(true)
        // }}
      >
        <>
          <UploadWrapper portfolioId={defaultPortfolio.id}>
            {isMobile ? (
              <PageHeader />
            ) : (
              <>
                <CenterAlignStack
                  sx={{
                    width: '100%',
                    position: 'relative',
                    background: theme => theme.palette.common.lightgray,
                  }}
                >
                  <UploadHeader
                    /* Mannequin 용 컴포넌트  */
                    configComponent={<MannequinConfigComponent />}
                    uploadButtonComponent={<MannequinConfigComponent uploadButtonOnly={true} />}
                    /* ModelFace 용 컴포넌트  */
                    configComponentSecond={
                      <ModelFaceConfigComponent conceptOff={true} ageOff={true} />
                    }
                    uploadButtonComponentSecond={
                      <ModelFaceConfigComponent uploadButtonOnly={true} />
                    }
                    gudieComponent={<GuideComponent />}
                    configDelay={0.5}
                    imageDelay={0.3}
                    /* 하단 2개가 들어가면 1개 모드로 변경  */
                    multiple={false}
                    uploadImglimit={1}
                    configWidth="86.8rem"
                    /* Model 용 업로드 분기처리  */
                    isModel={true}
                  />
                  {/* {uploadOpen && <UploadMannequinDialog />} */}

                  <CenterAlignBox
                    sx={{
                      p: '1.2rem 1.6rem',
                      background: '#EEEEEE',
                      borderRadius: '3rem',
                      py: '0.95rem',
                      position: 'relative',
                      top: '-4rem',
                      mb: '2.8rem',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.4rem',
                        fontWeight: 600,
                        gap: '0.8rem',
                      }}
                    >
                      <BulbIcon />

                      <Korean>
                        등록할 이미지의 종류를 선택해 주세요. 해당되는 종류가 없는 경우 [일반
                        이미지]를 선택해 주세요.
                      </Korean>
                      <Japanese>
                        登録する画像の種類を選択してください。該当する種類がない場合は、[一般画像]を選択してください。
                      </Japanese>
                      <English>
                        Select the type of image you want to upload. If none of the categories
                        apply, select [Normal].
                      </English>
                    </Box>
                  </CenterAlignBox>
                </CenterAlignStack>
              </>
            )}
          </UploadWrapper>
          <Mobile>
            <Guide />
          </Mobile>

          <ArtworkList upload={true} hideGroupHeader={true} />
        </>
      </RootStyle>
      <ScrollToTop />
      <ScrollHandler />
      {/* {dragDropOpen && uploadFiles.length === 0 && <DragDropFullSize />} */}
      <ModelRenerateDialog />
    </>
  )
}

function PageHeader() {
  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const [user, setUser] = useRecoilState(userAtom)
  const [viewConfig, setViewConfig] = useRecoilState(artworkViewConfigAtom)
  const { t } = useTranslation()
  const theme = useTheme()

  const { prepareUpload, makeUploadFormData, checkUserCredit, refreshArtworks } = usePortfolio()

  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)

  const [userLikedBg, setUserLikedBg] = useRecoilState(userLikedBgAtom)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const resetPortfolioDetail = useResetRecoilState(portfolioDetailAtom)
  const resetViewConfig = useResetRecoilState(artworkViewConfigAtom)
  const resetPortfolioConfig = useResetRecoilState(portfolioConfigAtom)

  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [uploading, setUploading] = useState(false)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const [openEndpointDialog, setOpenEndpointDialog] = useState(false)
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')

  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)

  const isMobile = useMobileMediaQuery()
  const { showConfirm } = useConfirm()

  const [prompt, setPrompt] = useState('')
  const [gender, setGender] = useState('female')
  const [age, setAge] = useState('adult')
  const [model, setModel] = useState('auto')

  const queryParams = new URLSearchParams(location.search)

  const maskImgArray = useRecoilValue(maskImgArrayAtom)

  const [loading, setLoading] = useRecoilState(mannequinLoadingAtom)
  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const retryMannequin = useRecoilValue(retryMannequinAtom)

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  const handleClose = () => {
    setUploadOpen(false)
  }

  const handleUpload = async () => {
    setLoading(true)
    // 버튼 로딩 표시
    setUploading(true)
    const feedback = 'regenerate_auto'

    const mask = await imgMergeAndGrayScale(selectedMaskImg)
    const rleData = await blobToRLE(mask)

    const formData = new FormData()

    if (!retryMannequin.isRetry) {
      const ready = await prepareUpload([{}], null, null, null, true)
      if (!ready.success) return
    }
    const config = retryMannequin.config

    formData.append('image', files[0].file)
    formData.append('binary_mask', mask)
    formData.append('user_id', user.id)
    formData.append('username', user.username)
    formData.append('user_type', getUserType(user))
    formData.append('gen_type', retryMannequin.isRetry ? 'retry' : 'initial')
    formData.append('is_male', gender === 'male')
    formData.append('mann2man_type', config?.mann2man_type ?? 'mann')

    formData.append('user_presets', JSON.stringify([rleData]))
    formData.append('user_binmaps', JSON.stringify(maskImgArray))

    formData.append('portfolio_id', portfolioDetail.id)
    formData.append('artwork_id', retryMannequin.isRetry ? retryMannequin.artworkId : '')
    formData.append('prompt', prompt)
    formData.append('age', age)

    // ----- GA4 event -----
    triggerGA4UploadEventManualConfig(
      {
        image_type: config?.mann2man_type === 'human' ? '일반 이미지' : '마네킹',
        menu: gender === 'male' ? '남' : '여', // 성별
        method: age === 'baby' ? '아기' : age === 'child' ? '어린이' : '성인', // 연령대
        theme: prompt.length > 0, // 배경 테마가 공란인지(false) 입력값있는지(true)
      },
      retryMannequin.isRetry ? 'mannequin_regenerate' : 'mannequin_complete'
    )
    // ---------------------

    try {
      if (retryMannequin.isRetry) {
        // Update artwork feedback

        await apis.portfolio.updateArtworkFeedback(portfolioDetail.id, retryMannequin.artworkId, {
          feedback,
        })

        // Get man-to-man data
        await apis.appfront.getMann2man(formData)

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }

        // Create a new config object
        const beforeConfig = retryMannequin.config
        const afterConfig = JSON.stringify({
          ...beforeConfig,
          age,
          is_male: gender === 'male',
          prompt,
        })

        // Update artwork with new config
        const response = await apis.portfolio.updateArtwork(
          portfolioDetail.id,
          retryMannequin.artworkId,
          {
            config: afterConfig,
          }
        )

        // Refresh artworks and close the modal
        refreshArtworks()
        handleClose()
      } else {
        await apis.appfront.getMann2man(formData)

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }

        handleClose()
        refreshArtworks()
      }
    } catch (error) {
      showConfirm({
        content: t('upload_dialog.warning'),
        alertOnly: true,
      })
    } finally {
      setLoading(false)
      // 버튼 로딩 표시
      setUploading(false)
    }
  }

  useEffect(() => {
    if (uploadOpen) {
      // ----- GA4 event -----
      window.gtag('event', 'mannequin_begin', {})
      // ---------------------
    }
  }, [uploadOpen])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, downloaded: downloadedFilter },
    })
  }, [downloadedFilter])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, name: textFilter },
    })
  }, [textFilter])

  return (
    <>
      <PageHeaderLayout
        headerProps={{
          sx: {
            opacity: isFolded.value ? 0 : 1,
            transition: 'all 0.2s ease',
          },
          zIndex: isFolded.value
            ? -1
            : tutorial.step === 14 || tutorial.step === '14_4' || tutorial.step === 0
            ? 'auto'
            : null,
        }}
        uploadButtonProps={{
          disabled: uploading || !isOwner || uploadButtonDisabled,
          onClick: () => {
            tutorial.mode && setTutorial(prev => ({ ...prev, step: '14_1' }))
            setUploadOpen(true)
          },
        }}
        uploading={uploading}
        uploadDialog={
          uploadOpen && (
            <MobileModelUploadOld
              title={
                <Typography sx={{ fontWeight: 800, lineHeight: 'normal', mt: '1.2rem' }}>
                  {t('mannequin.step_1_title')}
                  <sup
                    style={{
                      color: theme.palette.error.main,
                      fontWeight: 500,
                      fontSize: '1.5rem',
                      lineHeight: '1.5rem',
                      verticalAlign: 'text-top',
                    }}
                  >
                    &#42;
                  </sup>
                </Typography>
              }
              notice={
                <CenterAlignStack
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: { lg: '1.4rem', xs: '1.2rem' },
                      fontWeight: 400,
                      color: '#595959',
                      lineHeight: 'normal',
                    },
                  }}
                >
                  <Mobile>
                    <Typography fontSize="1.4rem" fontWeight={400}>
                      {/* {t('mannequin.step_1_comment_a')}
                    <span style={{ fontWeight: 400 }}> {t('mannequin.step_1_comment_b')}</span> */}
                      {t('mannequin.step_1_comment')}
                    </Typography>
                  </Mobile>
                </CenterAlignStack>
              }
              mannquinUpload={true}
              segmentService="mannequin"
              gender={gender}
              setGender={setGender}
              model={model}
              setModel={setModel}
              age={age}
              setAge={setAge}
              prompt={prompt}
              setPrompt={setPrompt}
              handleUpload={handleUpload}
              uploadButtonTitle={t('button.upload')}
            />
          )
        }
        artworkFilter={
          <ArtworkFilters
            selectValue={downloadedFilter}
            setSelectValue={setDownloadedFilter}
            textValue={textFilter}
            setTextValue={setTextFilter}
          />
        }
      />
    </>
  )
}

const ExampleImage = () => (
  <>
    <Desktop>
      <Stack direction="row" sx={{ alignItems: 'center' }} spacing="1.5rem">
        <img src="/static/images/mannequin_before.png" style={{ width: '12.8rem' }} />
        <BeforeAfterArrow />
        <img src="/static/images/mannequin_after.png" style={{ width: '12.8rem' }} />
      </Stack>
    </Desktop>

    <Mobile>
      <img src="/static/images/mannequin_before.png" style={{ width: '6.8rem' }} />
      <img src="/static/images/mannequin_after.png" style={{ width: '6.8rem' }} />
    </Mobile>
  </>
)

const ExampleText = ({ sx }) => {
  const { t } = useTranslation()
  return (
    <Card
      sx={{
        ml: { lg: '4rem !important', xs: '0.7rem' },
        px: { lg: '2.5rem', xs: '1.5rem' },
        '& .MuiTypography-root': {
          fontSize: { lg: '2rem', xs: '1rem' },
          fontWeight: 500,
          textAlign: { xs: 'center' },
        },
        borderRadius: '1rem',
        boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.2)',
        height: { lg: '10rem', xs: '4.4rem' },
        justifyContent: 'center',
        ...sx,
      }}
    >
      <Stack sx={{ height: '100%', justifyContent: 'center' }}>
        <Typography>{t('mannequin.example_text_1')}</Typography>
        <Typography sx={{ '& span': { fontWeight: 700 } }}>
          {t('mannequin.example_text_2_a')} <span> {t('mannequin.example_text_2_b')}</span>{' '}
          {t('mannequin.example_text_2_c')}
        </Typography>
      </Stack>
    </Card>
  )
}

const Guide = () => (
  <Stack
    direction="row"
    sx={{ alignItems: 'center', justifyContent: 'center', mt: '1rem', mb: '4rem' }}
  >
    <ExampleImage />
    <ExampleText />
  </Stack>
)
