import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { userAtom, languageAtom } from 'atoms'
import { styled, useTheme } from '@mui/material/styles'
import { Select, MenuItem, Stack, Box, Divider, Radio } from '@mui/material'
import { DropdownArrowIcon } from 'theme/icon'
import { Desktop, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { CenterAlignStack } from './CenterAlignContainer'
import { setCookie } from '../utils/cookie'

export default function LanguageSelect(props) {
  const { i18n } = useTranslation()
  const [y, setY] = useState(0)
  const [lang, setLang] = useRecoilState(languageAtom)
  const [open, setOpen] = useState(false)
  const isMobile = useMobileMediaQuery()

  const { pathname } = useLocation()
  const isBlog = pathname.includes('blog')
  const navigate = useNavigate()

  // const moveY = isMobile ? -5 : 0
  const moveY = 0

  const theme = useTheme()

  useEffect(() => {
    const lang = window.localStorage.getItem('i18nextLng') || 'ko'
    setCookie('language', lang)
    setLang(lang)
  }, [])

  const handleChange = event => {
    const newLang = event.target.value

    // ----- GA4 event -----
    window.gtag('event', 'footer_click', { menu: `language_${lang}_${newLang}` })
    // ---------------------

    setLang(newLang)
    i18n.changeLanguage(newLang)
    window.localStorage.setItem('i18nextLng', newLang)
    setCookie('language', newLang)

    if (isBlog) {
      const updatedUrl = pathname.replace(/\/blog\/[a-z]{2}/, `/blog/${newLang}`)
      navigate(`${updatedUrl}`)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setY(0)
  }

  const handleOpen = () => {
    setY(moveY)
    setOpen(true)
  }

  const motionProps = {
    animate: { y },
    transition: { duration: 0.4, stiffness: 100 },
  }

  const dark = {
    selectedBgColor: '#606060',
    hoverBgColor: '#6D6D6D',

    menuBgColor: '#606060',
    menuColor: theme.palette.common.white,

    borderBottomColor: theme.palette.draph.green,
  }

  const light = {
    selectedBgColor: theme.palette.common.white,
    hoverBgColor: theme.palette.draph.blue,
    hoverColor: theme.palette.common.white,

    menuBgColor: theme.palette.common.white,
    menuColor: theme.palette.common.black,

    borderBottomColor: theme.palette.draph.blue,
  }

  const colorTheme = props.theme === 'light' ? light : dark

  // TODO? : styled 를 컴포넌트 안에 이렇게 써도 되는건지 잘 모르겠음 ..

  const StyledLanguageItem = styled(MenuItem)(({ theme }) => ({
    width: '8.4rem',
    height: '3.6rem',
    // padding: '0.9rem 0.7rem',
    paddingLeft: '0.7rem',
    fontSize: '1.5rem',
    fontWeight: 500,

    '&.MuiMenuItem-root.Mui-selected': {
      backgroundColor: colorTheme.selectedBgColor,
    },
    '&.MuiMenuItem-root:hover': {
      backgroundColor: colorTheme.hoverBgColor,
    },

    '&.selected': {
      fontWeight: 700,
      color: theme.palette.draph.green,
    },

    [theme.breakpoints.down('lg')]: {
      width: '5.6rem',
      height: '2.1rem',

      // padding: '0.3rem 0.3rem 0.6rem 0.5rem',
      fontSize: '1.2rem',
      paddingLeft: '0.5rem',
    },
  }))

  const StyledLanguageSelect = styled(Select)(({ theme }) => ({
    width: '8.4rem',
    height: '3.6rem',
    padding: '0.9rem 0.7rem',
    backgroundColor: 'transparent',

    color: colorTheme.menuColor,
    fontSize: '1.5rem',
    fontWeight: 600,

    '&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
      {
        border: 0,
        // borderBottom: open ? '2px solid' : '',
        // borderColor: colorTheme.borderBottomColor,
      },

    '& .MuiSelect-select.MuiInputBase-input.MuiSelect-outlined': {
      padding: '0 0',
    },

    [theme.breakpoints.down('lg')]: {
      width: '5.6rem',
      height: '2.3rem',
      padding: '0.3rem',
      paddingBottom: '0.4rem',
      fontSize: '1.2rem',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
        {
          // borderBottom: '0.1rem solid',
          // borderColor: colorTheme.borderBottomColor,
        },
    },
  }))

  return (
    <motion.div {...motionProps} {...props}>
      <StyledLanguageSelect
        id="language-select"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={lang}
        IconComponent={() => (
          <DropdownArrowIcon
            color={props.theme === 'light' ? 'common.black' : 'common.white'}
            size={isMobile ? 'xs' : null}
          />
        )}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            sx: {
              '&.MuiMenu-paper ': {
                // TODO 자연스럽게 따라올라가면 좋을듯..
                // transform: `translateY(5px) !important`,
                transform: isMobile ? `translateY(3px) !important` : `translateY(5px) !important`,
                borderRadius: '8px',
                boxShadow: '2px 2px 10px 0px #00000026',
              },
              '& .MuiList-root': {
                padding: 0,
              },
              borderRadius: 0,

              backgroundColor: colorTheme.menuBgColor,
              color: colorTheme.menuColor,
            },
          },
        }}
      >
        <StyledLanguageItem value="ko" className={`${lang === 'ko' && 'selected'}`}>
          한국어
        </StyledLanguageItem>
        <StyledLanguageItem value="en" className={`${lang === 'en' && 'selected'}`}>
          English
        </StyledLanguageItem>

        <StyledLanguageItem value="ja" className={`${lang === 'ja' && 'selected'}`}>
          日本語
        </StyledLanguageItem>
      </StyledLanguageSelect>
    </motion.div>
  )
}

export function LanguageSelectV2(props) {
  const { i18n } = useTranslation()
  const [y, setY] = useState(0)
  const [lang, setLang] = useRecoilState(languageAtom)
  const [open, setOpen] = useState(false)
  const isMobile = useMobileMediaQuery()

  const { pathname } = useLocation()
  const isBlog = pathname.includes('blog')
  const navigate = useNavigate()

  // const moveY = isMobile ? -5 : 0
  const moveY = 0

  const theme = useTheme()

  useEffect(() => {
    const lang = window.localStorage.getItem('i18nextLng') || 'ko'
    setCookie('language', lang)
    setLang(lang)
  }, [])

  const handleChange = event => {
    const newLang = event.target.value

    setLang(newLang)
    i18n.changeLanguage(newLang)
    window.localStorage.setItem('i18nextLng', newLang)
    setCookie('language', 'en')

    if (isBlog) {
      const updatedUrl = pathname.replace(/\/blog\/[a-z]{2}/, `/blog/${newLang}`)
      navigate(`${updatedUrl}`)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setY(0)
  }

  const handleOpen = () => {
    setY(moveY)
    setOpen(true)
  }

  const motionProps = {
    animate: { y },
    transition: { duration: 0.4, stiffness: 100 },
  }

  const dark = {
    selectedBgColor: theme.palette.common.black,
    hoverBgColor: theme.palette.draph.green,
    hoverColor: theme.palette.common.black,

    menuBgColor: theme.palette.common.black,
    menuColor: theme.palette.common.white,
  }

  const light = {
    selectedBgColor: theme.palette.common.white,
    hoverBgColor: 'white',

    menuBgColor: theme.palette.common.white,
    menuColor: theme.palette.common.black,
  }

  const colorTheme = props.theme === 'light' ? light : dark

  const StyledLanguageItem = styled(MenuItem)(({ theme }) => ({
    width: '100%',
    padding: '1.2rem 1rem',
    fontSize: '1.4rem',
    fontWeight: 600,

    '&.MuiMenuItem-root.Mui-selected': {
      backgroundColor: colorTheme.selectedBgColor,
    },
    '&.MuiMenuItem-root:hover': {
      backgroundColor: colorTheme.hoverBgColor,
    },

    '& .radio-box': {
      display: 'flex',
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translate(0, -50%)',
    },

    '& .flag-icon': {
      width: '36px',
      marginRight: '10px',
    },

    '&.selected': {
      color: theme.palette.draph.newblue,
    },

    [theme.breakpoints.down('lg')]: {
      width: '11.2rem',
      height: '4rem',

      padding: '1.2rem',
    },
  }))

  const StyledLanguageSelect = styled(Select)(({ theme }) => ({
    width: '14.4rem',
    height: '2.4rem',
    padding: 0,
    backgroundColor: 'transparent',

    color: colorTheme.menuColor,
    fontSize: '1.5rem',
    fontWeight: 600,

    '& .radio-box': { display: 'none' },

    '& .flag-icon': {
      width: '36px',
      marginRight: '10px',
    },

    '& #arrow-icon': {
      transition: 'transform 0.4s ease',
      transform: open ? 'rotate(180deg)' : 'rotate(0)',
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
      {
        border: 0,
      },

    '& .MuiSelect-select.MuiInputBase-input.MuiSelect-outlined': {
      padding: '0 0',
      borderRadius: 0,
    },

    [theme.breakpoints.down('lg')]: {
      width: '8.1rem',
      height: '1.8rem',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
        {
          // borderBottom: '0.1rem solid',
          borderColor: colorTheme.borderBottomColor,
        },
    },
  }))

  return (
    <motion.div {...motionProps} {...props}>
      <StyledLanguageSelect
        id="language-select"
        className={`${open && 'open'}`}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={lang}
        IconComponent={() => (
          <Box id="arrow-icon">
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.292893 0.792893C-0.0675907 1.15338 -0.0953203 1.72061 0.209705 2.1129L0.292893 2.20711L5.29289 7.20711C5.65338 7.56759 6.22061 7.59532 6.6129 7.2903L6.70711 7.20711L11.7071 2.20711C12.0976 1.81658 12.0976 1.18342 11.7071 0.792893C11.3466 0.432409 10.7794 0.40468 10.3871 0.709705L10.2929 0.792893L6 5.085L1.70711 0.792893C1.34662 0.432409 0.779392 0.40468 0.387101 0.709705L0.292893 0.792893Z"
                fill="#303030"
              />
            </svg>
          </Box>
        )}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .divider': { padding: '0 1rem', mt: '0 !important', mb: '0 !important' },
              '&.MuiMenu-paper ': {
                transform: isMobile ? `translateY(12px) !important` : `translateY(12px) !important`,
                boxShadow: '2px 2px 10px 0px #00000026',
                borderRadius: '8px',
              },
              '& .MuiList-root': {
                padding: 0,
              },
              borderRadius: 0,

              backgroundColor: colorTheme.menuBgColor,
              color: colorTheme.menuColor,
            },
          },
        }}
      >
        <StyledLanguageItem value="ko" className={`${lang === 'ko' && 'selected'}`}>
          <CenterAlignStack direction="row">
            <Desktop>
              <img className="flag-icon" src="/static/images/etc/flag_ko.png" />
            </Desktop>
            한국어
            <Box className="radio-box">{lang === 'ko' ? <RadioSelected /> : <RadioCircle />}</Box>
          </CenterAlignStack>
        </StyledLanguageItem>
        <Box className="divider" sx={{ width: '100%' }}>
          <Divider />
        </Box>
        <StyledLanguageItem value="en" className={`${lang === 'en' && 'selected'}`}>
          <CenterAlignStack direction="row">
            <Desktop>
              <img className="flag-icon" src="/static/images/etc/flag_us.png" />
            </Desktop>
            English
            <Box className="radio-box">{lang === 'en' ? <RadioSelected /> : <RadioCircle />}</Box>
          </CenterAlignStack>
        </StyledLanguageItem>
        <Box className="divider" sx={{ width: '100%' }}>
          <Divider />
        </Box>
        <StyledLanguageItem value="ja" className={`${lang === 'ja' && 'selected'}`}>
          <CenterAlignStack direction="row">
            <Desktop>
              <img className="flag-icon" src="/static/images/etc/flag_jp.png" />
            </Desktop>
            日本語
            <Box className="radio-box">{lang === 'ja' ? <RadioSelected /> : <RadioCircle />}</Box>
          </CenterAlignStack>
        </StyledLanguageItem>
      </StyledLanguageSelect>
    </motion.div>
  )
}

const RadioCircle = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white" stroke="#ABABAB" />
  </svg>
)

const RadioSelected = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="14" height="14" rx="7" fill="white" stroke="#2C4DFF" strokeWidth="6" />
  </svg>
)
