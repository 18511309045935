import { useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

export const MobileUploadLayout = () => {
  const location = useLocation()
  const excludedPaths = ['/generate/upload', '/generaete/removebg', '/home', '/mobile'] // 리다이렉트를 제외할 경로들
  const isMobile = useMobileMediaQuery()

  const isExcluded = excludedPaths.some(path => location.pathname === path)

  if (!isExcluded && isMobile) {
    return <Navigate to="/mobile" replace />
  }

  return <Outlet />
}
