import { Box, Button, Container, IconButton, Link, Paper, Typography } from '@mui/material'
import { CenterAlignStack, CreateButton } from 'components'
import { useNavigate } from 'react-router-dom'
import { ErrorTriangleIcon, ErrorTriangleIconNotistack } from 'theme/icon'

export const ErrorBoundaryPage = ({ resetErrorBoundary }) => {
  const navigate = useNavigate()
  const handleRefresh = () => {
    resetErrorBoundary && resetErrorBoundary()
  }

  const handleInquiry = () => {
    resetErrorBoundary && resetErrorBoundary()
    navigate('/inquiry')
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'grey.50',
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ margin: 'auto' }}>
            <ErrorTriangleIconNotistack sx={{ width: 60, height: 60, fill: 'transparent' }} />
          </Box>

          <Box>
            <Typography color="error" gutterBottom sx={{ fontSize: '3rem' }}>
              죄송합니다
            </Typography>
            <Typography color="text.primary" gutterBottom sx={{ fontSize: '2rem' }}>
              예상치 못한 오류가 발생했습니다
            </Typography>
            <Typography color="text.secondary" sx={{ mt: 1, fontSize: '2rem' }}>
              불편을 끼쳐 대단히 죄송합니다. <br />
              잠시 후 다시 시도해 주시기 바랍니다.
            </Typography>
          </Box>

          <CreateButton onClick={handleRefresh} sx={{ borderRadius: 0, my: '2rem' }}>
            다시 시도하기
          </CreateButton>

          <Typography sx={{ fontSize: '1.5rem' }}>
            문제가 지속되면{' '}
            <Link
              component="button"
              onClick={handleInquiry}
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              고객센터
            </Link>
            로 문의해 주세요
          </Typography>
        </Paper>
      </Container>
    </Box>
  )
}
