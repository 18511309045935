import { apis } from 'apis'
import { userAtom } from 'atoms'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

// mode: 'scroll' || 'page'
export const useAssets = multiple => {
  const user = useRecoilValue(userAtom)
  const [page, setPage] = useState(1)
  const [myAssets, setMyAssets] = useState({})
  const [totalPages, setTotalPages] = useState(0)
  const [currentPageAsset, setCurrentPageAsset] = useState([])

  const fetchPage = async currentPage => {
    if (myAssets[currentPage]) {
      setCurrentPageAsset(myAssets[currentPage])

      // 이미 로드된 페이지인 경우 건너뜁니다.
      return
    }

    try {
      const res = await apis.assets.getAssets(user.id, currentPage)
      const newMyAsset = {
        ...myAssets,
        [currentPage]: res.data.assets.map(i => ({ ...i, selected: false })),
      }
      setMyAssets(newMyAsset)
      setCurrentPageAsset(newMyAsset[currentPage])
      setTotalPages(Math.ceil(res.data.total_count / 10))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchPage(page)
  }, [page])

  const changePage = async newPage => {
    if (newPage > totalPages) return
    setPage(newPage)
  }

  const selectAsset = (id, _page) => {
    const selectPage = _page ?? page
    console.log(selectPage)

    const isSelected = myAssets[selectPage].some(asset => asset.id === id && asset.selected)

    // multiple이 true이고 이미 선택된 자산이 있는 경우 새로운 자산을 선택하지 않음
    if (multiple && selectedAssets.length > 0 && !isSelected) {
      return
    }

    const newMyAsset = {
      ...myAssets,
      [selectPage]: myAssets[selectPage].map(i => {
        if (i.id === id) {
          return { ...i, selected: !i.selected }
        }
        return i
      }),
    }
    setMyAssets(newMyAsset)
    setCurrentPageAsset(newMyAsset[selectPage])
  }

  const findAssetPage = id => {
    for (const [page, assets] of Object.entries(myAssets)) {
      if (assets.some(asset => asset.id === id)) {
        return parseInt(page, 10)
      }
    }
    return -1
  }

  const selectAssetInScroll = id => {
    const _page = findAssetPage(id)
    selectAsset(id, _page)
  }

  const resetAllSelect = () => {
    setMyAssets(prev => {
      const newAssets = {}
      for (const [key, assets] of Object.entries(prev)) {
        newAssets[key] = assets.map(i => ({ ...i, selected: false }))
      }
      return newAssets
    })
  }

  const addAsset = assets => {
    setMyAssets(prev => {
      const newAssets = { ...prev }
      const pageOne = newAssets[1]
      const newPageOne = [...assets.map(o => ({ ...o, selected: false })), ...pageOne]
      newAssets[1] = newPageOne
      return newAssets
    })
  }

  const selectedAssets = Object.values(myAssets)
    .flat()
    .filter(asset => asset.selected)

  return {
    page,
    setPage,
    myAssets,
    setMyAssets,
    totalPages,
    setTotalPages,
    fetchPage,
    changePage,
    selectAsset,
    resetAllSelect,
    currentPageAsset,
    addAsset,
    selectAssetInScroll,
    selectedAssets,
  }
}
