import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { apis } from 'apis'

export const getUserType = user => {
  // 백엔드에 업로드 api 호출시 실어보내는 유저 정보 (BE에서 우선순위 큐 배정에 사용됨)
  // user 구독자 여부 확인
  // 추후 다른 타입 추가 (엔터프라이즈 등)
  if (user.subscription_plan?.includes('w100maker')) {
    return 'w100maker'
  } else if (user.subscription_plan?.includes('priority_basic')) {
    return 'priority_basic'
  } else if (user.subscription_plan || user.grade === 'admin') {
    // 최초 생성 10분 지연 시 크레딧 환불처리를 해주는 유효 구독자 (FE에서는 크레딧 환불 문구 표시와 연관됨)
    return 'subscriber'
  }

  return null
}

// naverCoomerceV2에서 유저의 로그인등을 체크하는 함수

export const checkUser = async navigate => {
  const url = new URL(window.location.href)
  const token = url.searchParams.get('token')
  if (token) {
    try {
      const res = await apis.naverCommerceV2.login({ jwe: token })

      if (res.data.success) {
        if (res.data.registered) {
          const d = res.data
          setAccessToken(d.access_token)
          setRefreshToken(d.refresh_token)

          window.gtag('event', 'login', {
            method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
          })

          navigate('/home', { replace: true })
          return { success: true }
        } else {
          // User not registered
          return { success: false, token }
        }
      }
    } catch (e) {
      if (e.response && e.response.status === 401) {
        const detail = e.response.data.detail
        console.error('Unexpected 401 error:', detail)
        if (detail === 'Expired token') {
          alert('유효기간이(60초) 지나 에러페이지로 이동합니다.')
          window.location.href = 'https://solution.smartstore.naver.com/ko/error'
        } else if (detail === 'Invalid token') {
          alert('유효하지 않은 토큰입니다. 다시 시도해주세요.')
          window.location.href = 'https://solution.smartstore.naver.com/ko/error'
        } else if (detail === 'Invalid solution ID') {
          alert('유효하지 않은 솔루션 ID입니다. 다시 시도해주세요.')
          window.location.href = 'https://solution.smartstore.naver.com/ko/error'
        } else {
          alert('유효하지 않은 토큰입니다. 다시 시도해주세요.')
          window.location.href = 'https://solution.smartstore.naver.com/ko/error'
        }
      } else {
        console.error('An unexpected error occurred:', e)
      }
    }
  }

  return { success: false }
}
