import { useTranslation } from 'react-i18next'
import { getRandomElement } from 'utils/common'
import { getAccessToken } from 'utils/cookie'

export function useMenu() {
  const tkn = getAccessToken()
  const { i18n } = useTranslation()

  const menu = [
    {
      id: 'adsV2',
      title: '이미지 생성',
      path: tkn ? '/adsV2/banner?pages=1' : '/overview/banner',
      constantPath: '/adsV2/banner?pages=1',
    },
    {
      id: 'generate',
      title: '상품',
      path: tkn ? '/generate/upload?pages=1' : '/overview/bg_generation',
      constantPath: '/generate/upload?pages=1',
      children: [
        {
          id: 'upload',
          pathName: 'upload',
          title: '배경 생성',
          path: tkn ? '/generate/upload?pages=1' : '/overview/bg_generation',
          constantPath: '/generate/upload?pages=1',
          children: [],
        },
        {
          id: 'removebg',
          pathName: 'removebg',
          title: '배경 제거',
          path: '/generate/removebg?pages=1',
          constantPath: '/generate/removebg?pages=1',
          children: [],
        },
        {
          id: 'bundle',
          pathName: 'bundle',
          title: '묶음 상품',
          path: tkn ? '/generate/bundle?pages=1' : '/overview/bundle',
          constantPath: '/generate/bundle?pages=1',
          children: [],
        },
        {
          id: 'bg_expansion',
          pathName: 'canvas',
          title: '배경 확장',
          path: tkn ? '/generate/canvas?pages=1' : '/overview/bg_expansion',
          constantPath: '/generate/canvas?pages=1',
          children: [],
        },
      ],
    },

    {
      id: 'model',
      title: '모델',
      path: tkn ? '/model/mannequin?pages=1' : '/overview/mannequin_to_model',
      constantPath: '/model/mannequin?pages=1',

      children: [
        {
          id: 'mannequin',
          adminOnly: false,
          pathName: 'mannequin',
          title: '영역 지정 생성',
          path: tkn ? '/model/mannequin?pages=1' : '/overview/mannequin_to_model',
          constantPath: '/model/mannequin?pages=1',
        },
        {
          id: 'modelbg',
          adminOnly: false,
          pathName: 'modelbg',
          title: '모델 생성',
          path: tkn ? '/model/modelbg?pages=1' : '/overview/model_generation',
          constantPath: '/model/modelbg?pages=1',
        },
        {
          id: 'face',
          adminOnly: false,
          pathName: 'face',
          title: '모델얼굴생성',
          path: tkn
            ? '/model/face?pages=1'
            : getRandomElement(['/overview/face_change', '/overview/face_generation']),
          constantPath: '/model/face?pages=1',
        },
      ],
    },
    // {
    //   id: 'background',
    //   adminOnly: false,
    //   title: '배경 관리',
    //   path: '/background',
    // },
    // {
    //   id: 'fun',
    //   title: 'FUN&AI',
    //   path: '/fun/fame?pages=1',
    // },

    {
      id: tkn ? 'portfolio' : 'blog',
      title: tkn ? '포트폴리오' : '블로그',
      path: tkn ? '/portfolio?pages=1' : `/blog`,
      constantPath: tkn ? '/portfolio?pages=1' : `/blog`,
    },
    // {
    //   id: 'blog',
    //   title: '블로그',
    //   path: '/blog',
    // },
  ]

  const GENERATE_SUB = menu.filter(x => x.id === 'generate')[0].children.map(sub => sub.pathName)
  // export const MODEL_SUB = []
  const MODEL_SUB = menu.filter(x => x.id === 'model')[0].children.map(sub => sub.pathName)

  const MAINTAIN_MENU = [
    ...GENERATE_SUB.map(p => `/generate/${p}`),
    ...MODEL_SUB.map(p => `/model/${p}`),
  ]

  const getCurrentMenu = () => {
    const path = window.location.pathname
    let m = null
    menu.forEach(x => {
      if (x.children) {
        x.children.forEach(sub => {
          if (sub?.constantPath?.includes(path)) {
            m = sub
          }
        })
      } else {
        if (path.includes('/portfolio/')) {
          m = menu.filter(x => x.id === 'portfolio')[0]
        }
        if (x?.constantPath?.includes(path)) m = x
      }
    })

    return m
  }

  return { menu, getCurrentMenu, MAINTAIN_MENU, GENERATE_SUB, MODEL_SUB }
}
