import { Suspense, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import MainAppBar, { APPBAR_DESKTOP, APPBAR_MOBILE } from './main/MainAppBar'
import InfoSideBar from './InfoSideBar'

import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { Box, Card, Stack, Typography } from '@mui/material'
import MypagesSideBar from './MypagesSideBar'
import MainFooter, { FOOTER_DESKTOP, FOOTER_MOBILE } from './main/MainFooter'
import MainDownBar from './main/MainDownBar'
import MobileMypageAppbar from './MobileMypageAppbar'
import { SnackbarProvider } from 'notistack'
import { StyledMaterialDesignContent } from 'components/CustomStyle'
import { CheckedCircleIconNotistack, ErrorTriangleIconNotistack } from 'theme/icon'
import { CenterAlignBox } from 'components'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { userCreditInfoAtom } from 'atoms'

export const MYPAGE_SIDE_MARGIN = '0rem'
export const MYPAGE_APPBAR_MOBILE = '10.1rem'
export const MYPAGE_OUTLET_TOPMARGIN_DESKTOP = '8.5rem'
export const MYPAGE_PERCENTAGE_WIDHT = '91%'

const snackbarProps = {
  maxSnack: 5,
  autoHideDuration: 1000,
  // style: {
  //   fontSize: '1.2rem',
  // },
}

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('lg')]: {
    paddingTop: MYPAGE_APPBAR_MOBILE,
  },
}))

const MainStyle = styled('div')(({ theme }) => ({
  display: 'flex',

  alignItems: 'center',
  flexDirection: 'column',

  [theme.breakpoints.down('lg')]: {
    height: '100%',
  },

  [theme.breakpoints.up('lg')]: {
    paddingTop: APPBAR_DESKTOP,
  },
}))

const OutletStyle = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'auto',
  position: 'relative',

  // 하단 푸터, 상단 앱바 두개, 그리고 하단 앱바 하나
  minHeight: `calc(100vh - ${FOOTER_MOBILE} - ${MYPAGE_APPBAR_MOBILE} - ${APPBAR_MOBILE})`,
  paddingTop: '10rem',
  // border: '1px dashed pink',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    height: 'auto',
    paddingTop: '8rem',
  },

  [theme.breakpoints.up('lg')]: {
    minHeight: `calc(100vh - ${FOOTER_DESKTOP} - ${APPBAR_DESKTOP})`,
  },
}))

export const pageTitleStyle = {
  position: 'absolute',
  left: '50%',

  top: { lg: '3.6rem', xs: '12.5rem' },
  // top: { lg: `calc(${APPBAR_DESKTOP} + 3.6rem)`, xs: `calc(${MYPAGE_APPBAR_MOBILE} + 2rem) ` },

  transform: 'translateX(-50%)',

  fontSize: { lg: '2.8rem', xs: '2.4rem' },
  fontWeight: 700,
}

export default function MypageLayout() {
  const [open, setOpen] = useState(false)
  const userCreditInfo = useRecoilValue(userCreditInfoAtom)
  const { t } = useTranslation()

  const titleDict = {
    '/user/subscriptions': t('mypage_menu.credit_and_subscriptions'),
    '/user/creditInfo':
      userCreditInfo === 0 ? t('mypage_menu.charged_credit') : t('mypage_menu.credit_history'),

    '/user/bespoke_history': t('mypage_menu.bespoke_history'),
    '/user/coupon': t('mypage_menu.coupon'),
    '/user/profile': t('profile.title'),
    '/user/assets': t('asset.title'),
  }

  const { pathname } = useLocation()

  return (
    <RootStyle>
      <SnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        iconVariant={{
          success: <CheckedCircleIconNotistack />,
          error: <ErrorTriangleIconNotistack />,
        }}
        {...snackbarProps}
      >
        <MainAppBar />

        <MainStyle>
          <Desktop>
            <Stack
              direction="row"
              sx={{
                width: '128rem',
                pb: '7rem',
              }}
            >
              <Box
                sx={{
                  // background: 'pink',
                  // -----
                  width: '23.6rem',
                  mr: '3.2rem',
                  pt: '3.6rem',
                }}
              >
                <MypagesSideBar />
              </Box>

              <Box
                sx={{
                  flex: 1,
                }}
              >
                {pathname.includes('assets') ? (
                  <Outlet />
                ) : (
                  <OutletStyle sx={{}}>
                    <Typography sx={pageTitleStyle}>{titleDict[pathname]}</Typography>
                    <Outlet />
                  </OutletStyle>
                )}
              </Box>
            </Stack>
            <MainFooter />
          </Desktop>

          <Mobile>
            <MobileMypageAppbar />

            <Box sx={{}}>
              <Typography className="mo-title" sx={pageTitleStyle}>
                {titleDict[pathname]}
              </Typography>
              {pathname.includes('assets') ? (
                <Outlet />
              ) : (
                <OutletStyle>
                  <Outlet />
                </OutletStyle>
              )}
            </Box>
            <MainFooter />
          </Mobile>
        </MainStyle>

        <Mobile>
          <MainDownBar />
        </Mobile>
      </SnackbarProvider>
    </RootStyle>
  )
}
