import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import { AssetUploadButton } from './assets/AssetUploadButton'
import { useTranslation } from 'react-i18next'

export const UploadFileDialog = ({ open, onClose, onFileChange, onAssetOpen, multiple }) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center', position: 'relative', mt: '10px' }}>
        <Typography sx={{ fontSize: '2rem', fontWeight: 700, color: 'draph.blue' }}>
          {t('asset.image_dialog')}
        </Typography>
        <Box
          component={'button'}
          sx={{
            position: 'absolute',
            right: '24px',
            top: '5px',
            cursor: 'pointer',
            outline: 'none',
            border: 'none',
            bgcolor: 'unset',
            height: 24,
          }}
          onClick={onClose}
        >
          <XIcon />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px', pb: '30px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '16px',
          }}
        >
          <input
            multiple={multiple}
            type="file"
            onChange={onFileChange}
            style={{ display: 'none' }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <AssetUploadButton variant={'upload'} />
          </label>
          <AssetUploadButton
            variant={'asset'}
            onClick={() => {
              onClose()
              onAssetOpen()
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const XIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="00 General / 01 Icons / 01 Actions / 08 Close">
      <path
        id="color"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.694 6.30602C18.102 6.71405 18.102 7.37559 17.694 7.78361L13.4783 11.9993L17.694 16.2164C18.102 16.6244 18.102 17.286 17.694 17.694C17.286 18.102 16.6244 18.102 16.2164 17.694L12.0007 13.4769L7.78361 17.694C7.37559 18.102 6.71405 18.102 6.30602 17.694C5.89799 17.286 5.89799 16.6244 6.30602 16.2164L10.5231 11.9993L6.30602 7.78361C5.89799 7.37559 5.89799 6.71405 6.30602 6.30602C6.71405 5.89799 7.37559 5.89799 7.78361 6.30602L12.0007 10.5217L16.2164 6.30602C16.6244 5.89799 17.286 5.89799 17.694 6.30602Z"
        fill="#808080"
      />
    </g>
  </svg>
)
