import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Slider from '@mui/material/Slider'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  artworkRegenCountAtom,
  blockDragDropAtom,
  canvasBrushCursorStyleAtom,
  clicksAtom,
  defaultPortfolioAtom,
  endpointAtom,
  indexAtom,
  mannequinLoadingAtom,
  maskImageAtom,
  nukkiSAMModeAtom,
  nukkiSAMOriSizeAtom,
  nukkiSAMSizeAtom,
  portfolioTypeAtom,
  removeMaskImgAtom,
  segmentLoadingAtom,
  segmentStepAtom,
  selectedMaskImgAtom,
  uploadFilesAtom,
  userAtom,
} from 'atoms'
import {
  CenterAlignBox,
  CenterAlignStack,
  FormTextField,
  ShadowButton,
  ThemeTemplateThumb,
  CustomOutlinedInput,
  SpanDraphBlue,
  GoodBadExample,
  SegmentArea,
  StyledToggleButtonGroup,
  StyledToggleButton,
  ResetDialog,
  themeGridStyle,
  scrollbarStyle,
} from 'components'
import { cloneElement, useEffect, useRef, useState } from 'react'

import { PORTFOLIO_CATEGORIES, ARTWORK_REGEN_REQ_MAX, ARTWORK_REGEN_AUTO_COMPLETE } from 'config'
import { apis } from 'apis'
import useConfirm from 'hooks/useConfirm'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  CloseIcon,
  ClothesIcon,
  FoodIcon,
  CosmeticsIcon,
  CarIcon,
  ModelIcon,
  ClipBoardIcon,
  DropdownArrowIcon,
} from 'theme/icon'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import {
  Collapse,
  Divider,
  OutlinedInput,
  SvgIcon,
  SwipeableDrawer,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material'
import { Stage, Layer, Line, Rect, Group } from 'react-konva'
import Konva from 'konva'
import { FadeLoader, PuffLoader } from 'react-spinners'
import { AiOutlineConsoleSql } from 'react-icons/ai'
import * as config from 'config'
import { StyledInput } from 'components/SearchInput'
import { usePortfolioId } from 'hooks/usePortfoliId'
import {
  AIIconMini,
  getS3ImageSrc,
  iOS,
  imageTransparent,
  imageUrlToFile,
  isKo,
} from 'utils/common'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { useRefineUploadFiles } from 'hooks/useRefineUploadFiles'
import { currentMenuAtom } from '../../atoms'
import { English, Japanese, Korean } from 'hooks/useLanguage'

const AngleSlider = styled(Slider)(({ theme }) => ({
  width: '1rem',
  padding: '0 1.2rem',
  '& .MuiSlider-rail': {
    color: '#D9D9D9',
    opacity: 1,
    height: 'calc(100% + 0.9rem)',
    bottom: 0,
  },
  '& .MuiSlider-track': {
    opacity: 0,
  },

  '& .MuiSlider-mark': {
    width: '0.8rem',
    height: '0.8rem',
    border: '0.5px solid #909090',
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
  },

  '& .MuiSlider-thumb': {
    width: '1.25rem',
    height: '1.25rem',
    outline: '0.1rem solid #4D80FF99',
    outlineOffset: '.3rem',

    '&:hover': {
      boxShadow: 'none',
    },

    '&.Mui-active': {
      // transition: 'outline-offset 0.2s',
      boxShadow: '2px 2px 20px rgba(77, 128, 255, 0.6)',
    },
  },

  [theme.breakpoints.down('lg')]: {
    padding: '0',
    paddingLeft: '2rem',
    //   marginTop: '2rem',
    //   width: '100%',
    //   '& .MuiSlider-rail': {
    //     height: '1rem',
    //   },

    //   '& .MuiSlider-mark:nth-last-of-type(3)': {
    //     left: 'calc(50% - 0.5rem) !important',
    //   },

    //   '& .MuiSlider-mark:nth-last-of-type(2)': {
    //     left: 'calc(100% - 1rem) !important',
    //   },
  },
}))

const angleImages = [
  '/static/images/angle_example/angle_04_perpen.png',
  '/static/images/angle_example/angle_03_45.png',
  '/static/images/angle_example/angle_02_floor.png',
  '/static/images/angle_example/angle_01_front.png',
]

// const angleValues = ['perpen', '45', 'floor', 'front']
const angleValues = ['perpen', 'diag', 'floor'] // 0, 1, 2
const actionHeight = '10rem'

const smallTitleStyle = {
  fontWeight: 700,
  fontSize: { lg: '1.6rem', xs: '1.3rem' },
  lineHeight: 'normal',
}

const commentStyle = {
  fontSize: { lg: '1.2rem', xs: '1.1rem' },
  fontWeight: 400,
  color: '#595959',
}

export default function RegenerateDialog({
  open,
  setOpen,
  artwork,
  regenerateArtwork,
  showWarning,
}) {
  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isBgExpansionPage = portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION

  const [sodModified, setSodModified] = useState(false)
  const [tab, setTab] = useState(1)
  const [subTab, setSubTab] = useState(0)
  const [angle, setAngle] = useState(2)
  const [category, setCategory] = useState('auto')
  const [themeAir, setThemeAir] = useState('')
  const [bgExpansion, setBgExpansion] = useState(false)
  const [themeBackground, setThemeBackground] = useState('')
  const [feedbackTextDialogOpen, setFeedbackTextDialogOpen] = useState(false)

  const [themeTemplate, setThemeTemplate] = useState('')
  const [themeCustom, setThemeCustom] = useState('')

  const [originalMaskImage, setOriginalMaskImage] = useRecoilState(maskImageAtom)
  const [nukkiSAMOriSize, setNukkiSAMOriSize] = useRecoilState(nukkiSAMOriSizeAtom)
  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
  const endpoint = useRecoilValue(endpointAtom)

  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)
  const [regenCount, setRegenCount] = useRecoilState(artworkRegenCountAtom)
  const [images, setImages] = useRecoilState(uploadFilesAtom)

  const { showConfirm } = useConfirm()

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId
  const user = useRecoilValue(userAtom)

  const isMobile = useMobileMediaQuery()
  const { t, i18n } = useTranslation()
  const refineUploadFiles = useRefineUploadFiles()

  const refreshRegenCount = () => {
    apis.portfolio.getArtworkRegenCount().then(response => {
      if (response.data) {
        setRegenCount(response.data)
      }
    })
  }

  const noSetting = isRemoveBgPage
  const noEditArea = isBgExpansionPage || bgExpansion

  const artworkConfig = JSON.parse(artwork.config)

  useEffect(() => {
    if (isRemoveBgPage) {
      setTab(2)
      !images.size && editUploadFile()
    }

    return () => {
      setBlockDragDrop(false)
    }
  }, [])

  useEffect(() => {
    if (artwork.config) {
      const artworkConfig = JSON.parse(artwork.config)
      setCategory(artworkConfig?.object_category)
      setBgExpansion(artworkConfig?.flag_bg_expansion)

      const t = config.BG_THEME_TEMPLATES.filter(t => t.id === artworkConfig?.theme_template)
      if (t.length > 0) {
        setThemeTemplate(t[0])
        if (t[0].id === 'custom') {
          setThemeCustom(artworkConfig?.theme_custom)
        }
      } else {
        const t = config.BG_THEME_TEMPLATES.filter(t => t.id === 'auto')
        setThemeTemplate(t[0])
      }
    }
  }, [artwork])

  useEffect(() => {
    if (open) {
      refreshRegenCount()

      if (artwork.config) {
        const config = JSON.parse(artwork.config)
        const angleIndex = angleValues.findIndex(a => a === config.angle)
        if (angleIndex >= 0) {
          setAngle(angleIndex)
        }
      }

      if (portfolioType === config.PORTFOLIO_TYPE_REMOVEBG) {
        // ----- GA4 event -----
        window.gtag('event', 'removebg_edit_begin', {})
        // ---------------------
      }
    }
  }, [open])

  const loadImage = src => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = src
      img.onload = () => resolve(img)
      img.onerror = err => reject(err)
    })
  }

  const ImgMergeAndEdit = async (imgArray, mode) => {
    const { Image } = require('image-js')
    // 모든 이미지 로딩을 기다리기 위한 Promise 배열

    const imagePromises = imgArray.map(loadImage)
    try {
      // 이미지 배열을 비동기로 로딩
      const images = await Promise.all(imagePromises)
      // 이미지의 너비와 높이를 가져옴
      const width = nukkiSAMOriSize.w
      const height = nukkiSAMOriSize.h

      // Canvas 엘리먼트 생성
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height

      // Canvas 2D 컨텍스트를 얻음
      const ctx = canvas.getContext('2d')

      // 이미지를 Canvas에 그림
      images.forEach(image => {
        ctx.drawImage(image, 0, 0, width, height)
      })

      const selectData = ctx.getImageData(0, 0, width, height)
      const originalImg = new Image(width, height, selectData.data)

      const greyedImg = originalImg.grey()
      greyedImg.data = greyedImg.data.map(x => (x > 0 ? (mode === 'add' ? 1 : 2) : 0))

      // const maskToBlob = await mask.toBlob(blob => {
      //   const file = new File([blob], `crop.jpg`, { type: 'image/png' })
      //   return file
      // }, 'image/png')

      // const result = mask.toDataURL('image/png')

      // combinedImageSrc를 반환
      return greyedImg
    } catch (error) {
      console.error('이미지 로딩 중 오류 발생:', error)
      throw error // 오류를 다시 던지거나 처리할 수 있습니다.
    }
  }

  const edit = async () => {
    setSegmentLoading(true)
    // const ready = await prepareUpload([{}], config.PORTFOLIO_TYPE_MANNEQUIN)
    // if (!ready.success) return

    const addMaskArrImg = selectedMaskImg
      .filter(i => i.mode === 'add')
      .map(i => {
        return i.image
      })

    const removeMaskArrImg = selectedMaskImg
      .filter(i => i.mode === 'remove')
      .map(i => {
        return i.image
      })

    const addedImgData = await ImgMergeAndEdit(addMaskArrImg, 'add')
    const removedImgData = await ImgMergeAndEdit(removeMaskArrImg, 'remove')

    addedImgData.data = addedImgData.data.map((x, i) => {
      if (removedImgData.data[i] > 0) return removedImgData.data[i]
      return x
    })

    const url = addedImgData.toDataURL()
    // console.log([...new Set(penImgGrey.data.filter(v => v > 0))])
    // console.log(url)

    const additionalMask = url.replace(IMG_BASE64, '')

    return apis.appfront
      .editMask({
        type: 'ai',
        artworkId: artwork.id,
        additionalMask,
        sodUrl: endpoint.sod_url,
        refine: 'refine',
      })
      .then(async response => {
        const maskB = response.data.mask.body

        const postProcessingImage = await imageTransparent(
          `${IMG_BASE64}${maskB.replaceAll('"', '')}`
        )
        setOriginalMaskImage(postProcessingImage)
        resetSelectedMaskImg()
        setSodModified(true)
        // setOriginalMaskImage(`${IMG_BASE64}${maskB.replaceAll('"', '')}`)
      })
      .finally(() => {
        setSegmentLoading(false)
      })
  }

  // 자동 재생성 요청
  const handleRequest =
    (auto, editAuto = false) =>
    e => {
      if (portfolioType === config.PORTFOLIO_TYPE_REMOVEBG) {
        // ----- GA4 event -----
        window.gtag('event', 'removebg_edit_complete', {})
        // ---------------------
      }
      const regenerateOptions = {
        auto: true,
        category,
        angle: angleValues[angle],
        retryType: sodModified ? 'sod_mod' : 'regenerate',
        themeAir,
        themeTemplate: themeTemplate.id,
        themeCustom,
      }

      if (auto) {
        if (regenCount.regenerate_auto >= ARTWORK_REGEN_REQ_MAX) {
          showConfirm({
            alertOnly: true,
            content: <Typography>{t('regenerate_dialog.regenerate_warning_1')}</Typography>,
          })
        } else {
          if (originalMaskImage) {
            edit().then(() => {
              setOpen(false)
              regenerateArtwork(regenerateOptions)
            })
          } else {
            setOpen(false)
            regenerateArtwork(regenerateOptions)
          }
        }
      } else {
        if (regenCount.regenerate < ARTWORK_REGEN_REQ_MAX) {
          setFeedbackTextDialogOpen(true)
          // 수동 재생성 요청

          // ----- GA4 event -----
          window.gtag('event', 'regenerate', {
            event_category: config.GA4_EVENT_CATEGORY.portfolio,
            user_id: user.id,
            artwork_id: artwork.id,
          })
          // ---------------------
        } else {
          showConfirm({
            alertOnly: true,
            content: <Typography>{t('regenerate_dialog.regenerate_warning_2')}</Typography>,
          })
        }
      }
    }

  const resetSelectedMaskImg = useResetRecoilState(selectedMaskImgAtom)
  const resetMannequinLoading = useResetRecoilState(mannequinLoadingAtom)
  const resetSegmentLoading = useResetRecoilState(segmentLoadingAtom)
  const resetSegmentStep = useResetRecoilState(segmentStepAtom)
  const resetUploadFiles = useResetRecoilState(uploadFilesAtom)
  const resetNukkiSAMSize = useResetRecoilState(nukkiSAMSizeAtom)
  const resetNukkiSAMOriSize = useResetRecoilState(nukkiSAMOriSizeAtom)
  const resetNukkiSAMMode = useResetRecoilState(nukkiSAMModeAtom)
  const resetMaskImage = useResetRecoilState(maskImageAtom)
  const resetRemoveMaskImg = useResetRecoilState(removeMaskImgAtom)
  const resetIndex = useResetRecoilState(indexAtom)
  const resetClick = useResetRecoilState(clicksAtom)

  const reset = () => {
    resetUploadFiles()
    resetSelectedMaskImg()
    resetMannequinLoading()
    resetSegmentStep()
    resetSegmentLoading()
    resetNukkiSAMSize()
    resetNukkiSAMMode()
    resetMaskImage()
    resetRemoveMaskImg()
    resetNukkiSAMOriSize()
    resetIndex()
    resetClick()
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [])

  const handleClose = (e, reason) => {
    if (reason) return
    setOpen(false)
    setBlockDragDrop(false)
    isRemoveBgPage ? setTab(2) : setTab(1)
    setSodModified(false)
    reset()
    // setCategory('')
    // setAngle(2)
  }

  const updateArtworkFeedback = (feedback, content) => {
    apis.portfolio
      .updateArtworkFeedback(portfolioId, artwork.id, { feedback, content })
      .then(response => {
        // console.log(response.data)
        // setRow(initArtwork(response.data));
        // row.feedback_status = 'regenerate'
        // setRow({ ...row })
        refreshRegenCount()
      })
  }

  const resetMask = () => {
    apis.appfront.restoreMask({ artworkId: artwork.id }).then(response => {})
  }

  const editUploadFile = async () => {
    // console.log(artwork.uploaded)
    const uploadedImgURL = getS3ImageSrc(artwork.uploaded)

    const Imagefile = await imageUrlToFile(uploadedImgURL)
    // console.log(Imagefile)

    // console.log(URL.createObjectURL(Imagefile))
    refineUploadFiles([Imagefile], true)
  }

  const dialogContentSize = tab => {
    switch (true) {
      case tab === 1:
        return { w: '76rem', h: '46rem' }
      case tab === 2:
        return { w: '99rem', h: '55.5rem' }
      case tab === 3 && subTab === 0:
        return { w: '68.8rem', h: '26.4rem' }
      case tab === 3 && subTab === 1:
        return { w: isKo(i18n) ? '76rem' : '81rem', h: '51.9rem' }
      case tab === 3 && subTab === 2:
        return { w: isKo(i18n) ? '76rem' : '81rem', h: '38.3rem' }
      case tab === 3 && subTab === 3:
        return { w: isKo(i18n) ? '76rem' : '81rem', h: '55.5em' }
      case tab === 3 && subTab === 4:
        return { w: isKo(i18n) ? '76rem' : '81rem', h: '38.3rem' }
      case tab === 3 && subTab === 5:
        return { w: isKo(i18n) ? '76rem' : '81rem', h: '32.9rem' }

      default:
        return { w: isKo(i18n) ? '76rem' : '81rem', h: '46rem' }
    }
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          fullScreen={isMobile}
          sx={{
            mt: {
              lg: 0,
              xs: 'calc(100vh - 70rem)',
            },
            '& .MuiPaper-root.MuiDialog-paper': {
              borderRadius: { lg: '2rem', xs: '3rem 3rem 0 0' },
              minHeight: { lg: 'unset', xs: '70rem' },
              transition: 'open 0.5s ease-in-out',
              overflowY: 'hidden',
            },
          }}
          maxWidth="xl"
        >
          <DialogTitle
            sx={{
              display: 'flex',
              pr: { lg: '3.6rem', xs: '3rem' },
              height: { lg: '8.6rem' },
              borderBottom: { lg: '1px solid #AFAFAF' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {!noSetting && (
                  <>
                    <Typography
                      onClick={() => {
                        setTab(1)
                      }}
                      color={theme => ({
                        lg: tab === 1 ? 'primary.main' : theme.palette.common.black,
                        xs: theme.palette.common.black,
                      })}
                      fontSize={{ lg: '2.4rem', xs: '2rem', cursor: 'pointer' }}
                      fontWeight={800}
                    >
                      {t('regenerate_dialog.regenerate')}
                    </Typography>
                    {!noEditArea && (
                      <Divider
                        orientation="vertical"
                        sx={{ mx: '2.8rem', height: '2.2rem', borderColor: '#AFAFAF' }}
                      />
                    )}
                  </>
                )}

                {!noEditArea && (
                  <>
                    <Typography
                      onClick={() => {
                        setTab(2)

                        !images.size && editUploadFile()
                      }}
                      color={theme => (tab === 2 ? 'primary.main' : theme.palette.common.black)}
                      fontSize={{ lg: '2.4rem', xs: '2rem', cursor: 'pointer' }}
                      fontWeight={800}
                    >
                      {t('regenerate_dialog.removebg_edit')}
                    </Typography>
                  </>
                )}

                {!noSetting && (
                  <>
                    <Divider
                      orientation="vertical"
                      sx={{ mx: '2.8rem', height: '2.2rem', borderColor: '#AFAFAF' }}
                    />
                    <Typography
                      onClick={() => {
                        setTab(3)
                      }}
                      color={theme => ({
                        lg: tab === 3 ? 'primary.main' : theme.palette.common.black,
                        xs: theme.palette.common.black,
                      })}
                      fontSize={{ lg: '2.4rem', xs: '2rem', cursor: 'pointer' }}
                      fontWeight={800}
                    >
                      {t('regenerate_dialog.guide')}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              width: {
                lg: dialogContentSize(tab).w,
                // lg: '58rem',
                xs: 'unset',
              },
              height: { lg: dialogContentSize(tab).h, xs: 'unset' },
              // height: { lg: tab === 2 ? '68.5rem' : '37.1rem', xs: 'unset' },
              // transition: 'width .5s ease-in-out, height .5s ease-in-out',
              transition: 'all .5s ease-in-out',
              p: 0,
            }}
          >
            <RegenerateContent
              tab={tab}
              setTab={setTab}
              subTab={subTab}
              setSubTab={setSubTab}
              artwork={artwork}
              artworkConfig={artworkConfig}
              category={category}
              setCategory={setCategory}
              angle={angle}
              setAngle={setAngle}
              themeTemplate={themeTemplate}
              setThemeTemplate={setThemeTemplate}
              themeCustom={themeCustom}
              setThemeCustom={setThemeCustom}
              setSodModified={setSodModified}
              noSetting={noSetting}
              noEditArea={noEditArea}
            />
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              py: '2.8rem',
              height: 'fit-content',
              opacity: 1,
              visibility: 'visible',
              // height: actionHeight,
              // height: isRemoveBgPage? 'fit-content' : (tab === 2 && !sodModified) ? 0 : 'fit-content',
              // // height: tab === 2 && !sodModified ? 0 : actionHeight,
              // visibility: isRemoveBgPage? 'visible' :  (tab === 2 && !sodModified) ? 'hidden' :'visible'  ,
              // opacity:isRemoveBgPage? 1 :  (tab === 2 && !sodModified) ? 0 : 1,
              transition: 'all 0.5s ease-in-out',
              // transition: 'visibility .5s ease-in-out, height .5s ease-in-out',
              borderTop: { lg: '1px solid #AFAFAF', xs: 'none' },
            }}
          >
            <ShadowButton
              sx={{
                width: isKo(i18n) ? '7.5rem' : '9.5rem',
                height: '5rem',
                fontSize: '2rem',
                fontWeight: 800,
                mr: '1.7rem',
                borderRadius: '1rem',
              }}
              disabled={segmentLoading}
              onClick={() => {
                resetMask()
                handleClose()
              }}
            >
              {t('button.cancel')}
            </ShadowButton>
            <CenterAlignStack sx={{ position: 'relative' }}>
              {showWarning && (
                <Typography
                  sx={{
                    color: theme => theme.palette.common.black,
                    fontSize: isKo(i18n) ? '1.3rem' : '1rem',
                    mb: '1.2rem',
                    textAlign: 'center',
                    lineHeight: '1.8rem',
                    position: 'absolute',
                    top: '-2.1rem',
                    zIndex: 3,
                  }}
                >
                  {t('regenerate_dialog.regenerate_warning_3_a')}{' '}
                  <span style={{ fontWeight: 600 }}>
                    {' '}
                    {t('regenerate_dialog.regenerate_warning_3_b')}
                  </span>
                  {t('regenerate_dialog.regenerate_warning_3_c')}
                </Typography>
              )}
              <Button
                variant="contained"
                sx={{
                  width: '20rem',
                  height: '5rem',
                  fontSize: '2rem',
                  fontWeight: 800,
                  borderRadius: '1rem',
                }}
                onClick={handleRequest(true)}
                disabled={category?.length < 1 || segmentLoading}
              >
                {t('regenerate_dialog.button_regenerate')}
              </Button>
            </CenterAlignStack>
            {/* {artwork.feedback_status === ARTWORK_REGEN_AUTO_COMPLETE && (
          <Button
            variant="outlined"
            sx={{
              width: '18.4rem',
              height: '5rem',
              px: 0,
            }}
            onClick={handleRequest(false)}
          >
            수동 재생성 요청 (
            {ARTWORK_REGEN_REQ_MAX - regenCount.regenerate > 0
              ? ARTWORK_REGEN_REQ_MAX - regenCount.regenerate
              : 0}
            회 가능)
          </Button>
        )} */}
            {/* 수동 재생성 요청 가림 23.06.22 */}
          </DialogActions>

          <FeedbackTextDialog
            open={feedbackTextDialogOpen}
            setOpen={setFeedbackTextDialogOpen}
            updateArtworkFeedback={updateArtworkFeedback}
          />
        </Dialog>
      </Desktop>

      <Mobile>
        <SwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          id="image-swiper-dialog"
          onClose={handleClose}
          anchor="bottom"
          sx={{
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
              minHeight: '75.2rem',
            },
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <CenterAlignBox
            sx={{
              mt: '1.2rem',
              mb: '2rem',
            }}
          >
            <Box
              sx={{
                width: '4rem',
                height: '0.4rem',
                backgroundColor: '#BCBCBC',
                borderRadius: '20px',
              }}
            ></Box>
          </CenterAlignBox>
          <CenterAlignStack sx={{}}>
            <CenterAlignBox
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '2rem' }}
            >
              <Typography
                onClick={() => {
                  setTab(1)
                }}
                color={theme => ({
                  lg: tab === 1 ? 'primary.main' : theme.palette.common.black,
                  xs: tab === 1 ? 'primary.main' : theme.palette.common.black,
                })}
                fontSize={{ lg: '2.4rem', xs: '1.6rem', cursor: 'pointer' }}
                fontWeight={800}
              >
                {t('regenerate_dialog.regenerate')}
              </Typography>
              <Divider
                orientation="vertical"
                sx={{ mx: '1.6rem', height: '1.6rem', borderColor: '#AFAFAF' }}
              />

              <Typography
                onClick={() => {
                  setTab(2)
                  !images.size && editUploadFile()
                }}
                color={theme => ({
                  lg: tab === 2 ? 'primary.main' : theme.palette.common.black,
                  xs: tab === 2 ? 'primary.main' : theme.palette.common.black,
                })}
                fontSize={{ lg: '2.4rem', xs: '1.6rem', cursor: 'pointer' }}
                fontWeight={800}
              >
                {t('regenerate_dialog.removebg_edit')}
              </Typography>

              <Divider
                orientation="vertical"
                sx={{ mx: '1.6rem', height: '1.6rem', borderColor: '#AFAFAF' }}
              />

              <Typography
                onClick={() => {
                  setTab(3)
                }}
                color={theme => ({
                  lg: tab === 3 ? 'primary.main' : theme.palette.common.black,
                  xs: tab === 3 ? 'primary.main' : theme.palette.common.black,
                })}
                fontSize={{ lg: '2.4rem', xs: '1.6rem', cursor: 'pointer' }}
                fontWeight={800}
              >
                {t('regenerate_dialog.guide')}
              </Typography>
            </CenterAlignBox>

            <RegenerateContent
              tab={tab}
              setTab={setTab}
              subTab={subTab}
              setSubTab={setSubTab}
              artwork={artwork}
              artworkConfig={artworkConfig}
              category={category}
              setCategory={setCategory}
              angle={angle}
              setAngle={setAngle}
              themeTemplate={themeTemplate}
              setThemeTemplate={setThemeTemplate}
              themeCustom={themeCustom}
              setThemeCustom={setThemeCustom}
              setSodModified={setSodModified}
              noSetting={noSetting}
              noEditArea={noEditArea}
            />

            <CenterAlignBox sx={{ width: '100%', pb: '2.2rem', pt: '1.8rem' }}>
              <Button
                variant="contained"
                sx={{
                  width: '16rem',
                  height: '4.8rem',
                  fontSize: '1.8rem',
                  fontWeight: 800,
                  borderRadius: '1rem',
                }}
                onClick={handleRequest(true)}
                disabled={category?.length < 1}
              >
                {t('button.regenerate')}
              </Button>
            </CenterAlignBox>
          </CenterAlignStack>
        </SwipeableDrawer>
      </Mobile>
    </>
  )
}

function SelectCategory({ artworkConfig, category, setCategory }) {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const disabled = artworkConfig.object_category !== 'auto'

  const list = [
    {
      id: 'auto',
      name: t('artwork_category.auto'),
      icon: <AIIconMini />,
    },
    {
      id: 'person',
      name: t('artwork_category.model'),
      icon: <ModelIcon style={{ width: '1.8rem', height: '2.2rem' }} />,
    },
    {
      id: 'clothes',
      name: t('artwork_category.clothes'),
      icon: <ClothesIcon style={{ width: '2.2rem', height: '2.3rem' }} />,
    },
    {
      id: 'food',
      name: t('artwork_category.food'),
      icon: <FoodIcon style={{ width: '2.2rem', height: '2.2rem' }} />,
    },
    {
      id: 'cosmetics',
      name: t('artwork_category.cosmetics'),
      icon: <CosmeticsIcon style={{ width: '2.7rem', height: '2.7rem' }} />,
    },
    {
      id: 'car',
      name: t('artwork_category.car'),
      icon: <CarIcon style={{ width: '2.4rem', height: '2.3rem' }} />,
    },
  ]

  return (
    <>
      <CenterAlignStack>
        <Typography sx={smallTitleStyle}>{t('upload_config.product_category')}</Typography>

        {disabled ? (
          <Typography sx={{ ...commentStyle, '& span': { fontWeight: 700 } }}>
            {t('regenerate_dialog.regenerate_comment_1_a')}
            <span> {t('regenerate_dialog.regenerate_comment_1_b')}</span>{' '}
            {t('regenerate_dialog.regenerate_comment_1_c')}
          </Typography>
        ) : (
          <Typography sx={commentStyle}>{t('regenerate_dialog.regenerate_comment_2')}</Typography>
        )}

        <Stack direction="row" sx={{ my: '1.3rem' }}>
          {list.map(c => (
            <CenterAlignStack
              key={c.id}
              sx={{ mx: '0.45rem', cursor: disabled ? '' : 'pointer' }}
              onClick={
                disabled
                  ? null
                  : e => {
                      setCategory(c.id)
                    }
              }
            >
              <CenterAlignBox
                sx={{
                  width: '4.4rem',
                  height: '4.4rem',
                  alignItems: 'center',
                  boxShadow: '1px 1px 6px 0px rgba(0, 0, 0, 0.2)',
                  borderRadius: '0.6rem',

                  background: !disabled || category === c.id ? 'white' : '#F8F8F8',

                  border: theme =>
                    disabled && category === c.id
                      ? `2px solid ${theme.palette.common.black}`
                      : category === c.id
                      ? `2px solid ${theme.palette.draph.blue}`
                      : 'none',
                }}
              >
                {cloneElement(c.icon, {
                  color: disabled
                    ? category === c.id
                      ? theme.palette.common.black
                      : '#ababab'
                    : category === c.id
                    ? theme.palette.draph.blue
                    : theme.palette.common.black,
                })}
              </CenterAlignBox>
              <Typography
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: category === c.id ? 700 : 500,
                  mt: '0.3rem',
                  color:
                    category === c.id && !disabled
                      ? theme.palette.draph.blue
                      : disabled
                      ? '#ababab'
                      : theme.palette.common.black,
                }}
              >
                {c.name}
              </Typography>
            </CenterAlignStack>
          ))}
        </Stack>
      </CenterAlignStack>
    </>
  )
}

function SelectAngle({ angle, setAngle, sx }) {
  const { t, i18n } = useTranslation()
  return (
    <>
      <CenterAlignStack sx={{ mt: '1.5rem', ...sx }}>
        <Typography sx={{ ...smallTitleStyle }}> {t('regenerate_dialog.angle')}</Typography>

        <Typography sx={commentStyle}>{t('regenerate_dialog.angle_comment')}</Typography>

        <Stack direction="row" sx={{ mt: { lg: '1.8rem', xs: '1.3rem' } }}>
          <Box
            // width={{ lg: '19rem', xs: '20rem' }}
            // height={{ lg: '13.5rem', xs: '14.2rem' }}
            width={{ lg: '25rem', xs: '20rem' }}
            height={{ lg: '20rem', xs: '14rem' }}
            sx={{
              borderRadius: '1rem',
              boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.05)',
            }}
          >
            <img src={angleImages[angle]} />
          </Box>

          <Stack sx={{ height: { lg: '20rem', xs: '14rem' } }}>
            <AngleSlider
              orientation="vertical"
              value={angle}
              onChange={e => setAngle(e.target.value)}
              step={1}
              min={0}
              max={2}
              marks
            />
          </Stack>
        </Stack>
      </CenterAlignStack>
    </>
  )
}
function SelectTheme({ themeTemplate, setThemeTemplate, themeCustom, setThemeCustom, category }) {
  const THEME_ITEM_PER_ROW_MOBILE = 6 // 모바일
  const THEME_ITEM_MAX_ROW_MOBILE = 2 // 모바일
  const { t, i18n } = useTranslation()
  const scrollRef = useRef(null)

  const [itemPerPage, setItemPerPage] = useState(9)

  const [showCustomTheme, setShowCustomTheme] = useState(false)
  const [selected, setSelected] = useState(null)
  const [themeList, setThemeList] = useState([]) // 고정아이템을 제외한 테마들만
  const [themeListWithFix, setThemeListWithFix] = useState([]) // 고정아이템 포함
  const [themePageItem, setThemePageItem] = useState([])
  const [themePageCount, setThemePageCount] = useState(1)
  const [themePage, setThemePage] = useState(1)
  const [showLeftShadow, setShowLeftShadow] = useState(false)
  const [showRightShadow, setShowRightShadow] = useState(true)

  const ex0 = t('upload_config.bg_theme_ex_1')
  const [placeholder, setPlaceholder] = useState(ex0)

  const onFocus = idx => () => {
    if (idx === 0) setPlaceholder('')
  }
  const onBlur = idx => () => {
    if (idx === 0) setPlaceholder(ex0)
  }

  const handleScroll = () => {
    const l = scrollRef.current.scrollLeft
    const sw = scrollRef.current.scrollWidth
    const cw = scrollRef.current.clientWidth

    if (l > 10) {
      setShowLeftShadow(true)
    } else {
      setShowLeftShadow(false)
    }

    if (cw + l < sw - 10) {
      setShowRightShadow(true)
    } else {
      setShowRightShadow(false)
    }
  }
  useEffect(() => {
    const div = scrollRef.current
    if (div) {
      div.addEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    if (selected?.id === 'custom') {
      setShowCustomTheme(true)
      // setItemPerPage(6)
    } else {
      setShowCustomTheme(false)
      // setThemeCustom('')

      // const pSize = 9
      // setItemPerPage(pSize)
      // const fixItems = config.BG_THEME_TEMPLATES.filter(x => x.fixInPage)
      // const itemInPage = Math.floor(
      //   themeListWithFix.findIndex(x => x.id === selected.id) / (pSize - fixItems.length)
      // )
      // setThemePage(itemInPage + 1)
    }

    if (selected?.id) {
      setThemeTemplate(selected)
    }
  }, [selected])

  useEffect(() => {
    if (themeTemplate?.id && !selected?.id) {
      const t = config.BG_THEME_TEMPLATES.filter(t => t.id === themeTemplate.id)

      if (t.length > 0) {
        setSelected(t[0])
      } else {
        setSelected({ id: 'auto' })
      }
    }
  }, [themeTemplate, selected])

  useEffect(() => {
    let arr = [...config.BG_THEME_TEMPLATES]
    arr = arr.filter(x => !x.fixInPage)

    let order = config.BG_THEME_ORDER[category]
    if (!order) {
      order = config.BG_THEME_ORDER.default
    }

    arr.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))

    setThemeList(arr)
  }, [category])

  useEffect(() => {
    const fixItems = config.BG_THEME_TEMPLATES.filter(x => x.fixInPage)

    setThemeListWithFix([
      ...fixItems.filter(x => x.fixInPage === 'first'),
      ...themeList,
      ...fixItems.filter(x => x.fixInPage === 'last'),
    ])
  }, [themeList])

  useEffect(() => {
    const fixItems = config.BG_THEME_TEMPLATES.filter(x => x.fixInPage)

    const c = itemPerPage - fixItems.length

    setThemePageCount(Math.ceil(themeList.length / c))
    const a = (themePage - 1) * c
    const b = themePage * c

    setThemePageItem([
      ...fixItems.filter(x => x.fixInPage === 'first'),
      ...themeList.slice(a, b),
      ...fixItems.filter(x => x.fixInPage === 'last'),
    ])
  }, [themeList, themePage, itemPerPage])

  const thumbStyle = {
    borderRadius: '1rem',
    borderWidth: '2px',
    width: { lg: '8rem', xs: '8rem' },
    // height: { lg: '8rem', xs: '8rem' },
    '& img': {
      height: { lg: '12rem', xs: '11rem' },
    },
    '& #theme-auto': {
      '& #image-box': {
        width: '3.2rem',
      },
      '& #title-box': {
        mt: '0.7rem',
        width: '4.8rem',
        height: '1.8rem',
        '& #title': {
          fontSize: '1.2rem',
        },
      },
    },
    '& #theme-custom': {
      '& #icon': {
        width: '32px',
        height: '32px',
      },
      '& #title-box': {
        width: '6rem',
        height: '1.8rem',
        '& #title': {
          fontSize: '1.1rem',
        },
      },
    },

    '& #selected-border-box': {
      borderRadius: '1rem',
      borderWidth: '2px',
    },
    '& #selected-name-text': {
      fontSize: '1.2rem',
    },
    '& #selected-name-box': {
      height: '2.3rem',
    },
  }

  return (
    <>
      <CenterAlignStack sx={{ width: '100%' }}>
        <Typography sx={{ ...smallTitleStyle }}>{t('upload_config.bg_theme')}</Typography>

        <Typography
          sx={commentStyle}
          onClick={() => {
            setItemPerPage(6)
          }}
        >
          {t('regenerate_dialog.theme_comment')}
        </Typography>

        <Desktop>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              '& #thumb-list': { mx: '2rem' },
              '& svg': { cursor: 'pointer' },
              '& #prev-icon': { '& path': { stroke: themePage <= 1 ? '#BBBBBB' : '#303030' } },
              '& #next-icon': {
                '& path': { stroke: themePage >= themePageCount ? '#BBBBBB' : '#303030' },
              },
            }}
          >
            <SvgIcon
              id="prev-icon"
              sx={{ width: '1.5rem', height: '2.8rem', fill: 'none' }}
              viewBox="0 0 15 28"
              onClick={() => {
                if (themePage > 1) {
                  setThemePage(p => p - 1)
                }
              }}
            >
              <path
                d="M13 2L1 14L13 26"
                stroke="#303030"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </SvgIcon>

            <Grid
              id="thumb-list"
              container
              columns={3}
              gap={0.6}
              sx={{
                width: '26rem',
                justifyContent: 'center',
                py: showCustomTheme ? '2rem' : '6rem',
                transition: 'all 0.2s ease',
              }}
            >
              {themePageItem.map(t => (
                <Grid
                  item
                  key={`${t.id}_${t.id}`}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelected(t)
                  }}
                >
                  <ThemeTemplateThumb
                    theme={t}
                    category={category}
                    selected={selected?.id === t.id}
                    sx={thumbStyle}
                  />
                </Grid>
              ))}

              {/* dummy */}
              {itemPerPage - themePageItem.length > 0 &&
                Array(itemPerPage - themePageItem.length)
                  .fill(0)
                  .map((a, i) => (
                    <Grid item key={i}>
                      <ThemeTemplateThumb
                        theme={{ id: null }}
                        sx={{
                          borderRadius: '1rem',
                          width: { lg: '8rem' },
                          height: { lg: '8rem' },
                          background: '#eeeeee',
                        }}
                      />
                    </Grid>
                  ))}
            </Grid>

            <SvgIcon
              id="next-icon"
              sx={{ width: '1.5rem', height: '2.8rem', fill: 'none' }}
              viewBox="0 0 15 28"
              onClick={() => {
                if (themePage < themePageCount) {
                  setThemePage(p => p + 1)
                }
              }}
            >
              <path
                d="M2 26L14 14L2 2"
                stroke="#303030"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </SvgIcon>
          </Stack>
        </Desktop>

        <Mobile>
          <Box
            sx={{
              width: '100%',
              maxWidth: '100%',
              position: 'relative',
              mb: '1rem',
              paddingLeft: '3rem',
              paddingRight: '3rem',
            }}
          >
            <div
              style={{
                width: '100%',
                position: 'relative',
                // display: 'flex',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: 0,
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    top: 0,
                    position: 'absolute',
                    height: '100%',
                    zIndex: 3,
                    transform: 'rotate(180deg)',
                    background:
                      'linear-gradient(270deg, rgba(0, 0, 0, 0.15) 0%, rgba(255, 255, 255, 0) 100%)',
                    opacity: showLeftShadow ? 1 : 0,
                    width: showLeftShadow ? '5.6rem' : '0rem',
                    transition: 'all 0.1s',
                  }}
                />

                <div
                  style={{
                    top: 0,
                    right: 0,
                    position: 'absolute',
                    height: '100%',
                    zIndex: 3,
                    background:
                      'linear-gradient(270deg, rgba(0, 0, 0, 0.15) 0%, rgba(255, 255, 255, 0) 100%)',
                    opacity: showRightShadow ? 1 : 0,
                    width: showRightShadow ? '5.6rem' : '0rem',
                    transition: 'all 0.1s',
                  }}
                />
              </Box>
              <Box
                component={'div'}
                ref={scrollRef}
                sx={{
                  ...themeGridStyle,
                  ...scrollbarStyle,
                }}
              >
                {themeListWithFix.map((item, idx) => (
                  <Box
                    key={`${item.id}_${idx}`}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelected(item)
                    }}
                  >
                    <ThemeTemplateThumb
                      theme={item}
                      selected={selected?.id === item.id}
                      category={category}
                      sx={thumbStyle}
                    />
                  </Box>
                ))}
              </Box>
            </div>
          </Box>
        </Mobile>

        <motion.div
          animate={showCustomTheme ? 'show' : 'hide'}
          variants={{
            show: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.3,
              },
            },
            hide: {
              y: -5,
              opacity: 0,
            },
          }}
        >
          <Card
            sx={{
              pt: '1rem',
              pb: '2rem',
              px: { lg: '2rem', xs: '2rem' },
              display: showCustomTheme ? '' : 'none',
              boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.2)',
              top: { lg: '-1rem', xs: 0 },
            }}
          >
            <CenterAlignStack>
              <Typography
                sx={{
                  fontSize: { lg: '1.2rem' },
                  fontWeight: 600,
                  mb: { lg: '1rem', xs: '0.6rem' },
                }}
              >
                {t('upload_config.bg_theme_comment_2_a')}
                <SpanDraphBlue>{t('upload_config.bg_theme_comment_2_b')}</SpanDraphBlue>
                {t('upload_config.bg_theme_comment_2_c')}
              </Typography>

              <CustomOutlinedInput
                value={themeCustom}
                placeholder={placeholder}
                onChange={e => {
                  setThemeCustom(e.target.value)
                }}
                onFocus={onFocus(0)}
                onBlur={onBlur(0)}
                type="text"
                inputProps={{ maxLength: 1000 }}
                sx={{
                  height: '2.8rem',
                  width: '22.4rem',
                  '& input': { fontSize: '1.2rem' },
                }}
              />
            </CenterAlignStack>
          </Card>
        </motion.div>
      </CenterAlignStack>
    </>
  )
}

function FeedbackTextDialog({ open, setOpen, updateArtworkFeedback }) {
  const feedback = 'regenerate' // 수동 재생성
  const [content, setContent] = useState('')
  const { showConfirm } = useConfirm()

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setContent('')
  }, [open])

  const save = () => {
    if (content.length < 1) {
      showConfirm({
        alertOnly: true,
        content: <Typography>개선 요청사항을 입력해주세요.</Typography>,
      })
      return
    }

    updateArtworkFeedback(feedback, content)

    showConfirm({
      alertOnly: true,
      content: <Typography>요청이 접수되었습니다.</Typography>,
      onConfirm: () => {
        setOpen(false)
      },
    })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>수동 재생성 요청사항</DialogTitle>
      <DialogContent>
        <Box width={'34rem'}>
          <TextField
            sx={{ width: '100%' }}
            multiline
            rows={5}
            inputProps={{ maxLength: 200 }}
            label=""
            value={content}
            onChange={e => {
              setContent(e.target.value)
            }}
            placeholder="개선되었으면 하는 점을 구체적으로 적어주세요!"
          />
        </Box>
      </DialogContent>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" sx={{ width: 200 }} onClick={save}>
            제출
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export const IMG_BASE64 = 'data:image/png;base64,'
const LINE_HISTORY_LIMIT = 100

let lastTool = null // 지우개모드 취소했을때 마지막 사용하던 브러쉬 모드(제거/추가) 로 돌아가게 해주기 위해 ..
const roundButtonStyle = {
  height: '3.6rem',
  width: '10.8rem',
  borderRadius: '10px',
  backgroundColor: theme => theme.palette.common.white,
  fontSize: '1.4rem',
  border: '1px solid #D9E1EC',
  // boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
  fontWeight: 500,
  '&.Mui-disabled': {
    '& svg path': {
      stroke: '#919EAB',
    },
  },
  '&:hover': {
    // boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
    background: 'unset',
    color: theme => theme.palette.draph.blue,
    '& svg path': {
      stroke: theme => theme.palette.draph.blue,
      transition: 'stroke 0.3s',
    },
  },
  '& svg path': {
    transition: 'stroke 0.3s',
  },
  '& svg': {
    mr: '0.4rem',
  },
}

const tooltipStyle = {
  width: '25rem',
  height: '16rem',
  background: theme => theme.palette.common.white,
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
  color: 'black',
  '& .MuiTooltip-arrow': {
    color: theme => theme.palette.common.white,
  },
}
const squareButtonStyle = { p: 0, height: '3.6rem', fontSize: '1.4rem', fontWeight: 500 }

function SodCanvas({
  artwork,
  setSodModified,
  subTab,
  setSubTab,
  resetDialogOpen,
  setResetDialogOpen,
}) {
  const [init, setInit] = useState(false)
  const { t, i18n } = useTranslation()
  const zoomAreaHeight = 58
  const MAX_W = 440
  const MAX_H = 520
  const [scale, setScale] = useState(1)
  const [zoom, setZoom] = useState(1)
  const [showZoom, setShowZoom] = useState(true)
  const [showEraserButton, setShowEraserButton] = useState(true)
  const [width, setWidth] = useState(MAX_W)
  const [height, setHeight] = useState(MAX_H)

  const [orgW, setOrgW] = useState(MAX_W) //  원본 사이즈
  const [orgH, setOrgH] = useState(MAX_H) // 원본 사이즈

  const [lineHistory, setLineHistory] = useState([]) // 실행취소시 push, 다시실행시 pop, 라인 리셋시 같이 리셋

  const [brushSize, setBrushSize] = useState(10)
  const [tool, setTool] = useState('pen')
  const [lines, setLines] = useState([])
  const [scaledLines, setScaledLines] = useState([])
  const [image, setImage] = useState('')
  const [maskImage, setMaskImage] = useState('')
  const { showConfirm } = useConfirm()

  const [src, setSrc] = useState('')
  const [maskSrc, setMaskSrc] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const [showBrushPreview, setShowBrushPreview] = useState(false)

  const [brushCursorStyle, setBrushCursorStyle] = useRecoilState(canvasBrushCursorStyleAtom)
  const resetBrushCursorStyle = useResetRecoilState(canvasBrushCursorStyleAtom)

  const isDrawing = useRef(false)
  const drawLayerRef = useRef(null)
  const stageRef = useRef(null)
  const exportLayerRef = useRef(null)
  const exportPenLayerRef = useRef(null)
  const exportRemoveLayerRef = useRef(null)

  const endpoint = useRecoilValue(endpointAtom)

  async function setBg(src, maskSrc) {
    setSrc(src)
    setMaskSrc(maskSrc)

    const img = await getImageMeta(src)
    const maskImage = await getImageMeta(maskSrc)

    setImage(img)
    setMaskImage(maskImage)

    setOrgW(img.width)
    setOrgH(img.height)

    let w = img.width
    let h = img.height
    let s = 1

    if (w >= h) {
      s = MAX_W / w
      w = MAX_W
      h = h * s
    } else {
      s = MAX_H / h
      h = MAX_H
      w = w * s
    }

    setScale(s)
    setWidth(w)
    setHeight(h)
  }

  const loadImage = () => {
    setIsLoading(true)
    apis.appfront
      .editMask({ artworkId: artwork.id, sodUrl: endpoint.sod_url })
      .then(async response => {
        const imgB = response.data.img.body
        const maskB = response.data.mask.body

        await setBg(
          `${IMG_BASE64}${imgB.replaceAll('"', '')}`,
          `${IMG_BASE64}${maskB.replaceAll('"', '')}`
        )
        setIsLoading(false)
        if (!init) setInit(true)
      })
      .catch(() => {
        alert(t('common.error'))
      })
  }

  useEffect(() => {
    loadImage()
    return () => {
      resetBrushCursorStyle()
    }
  }, [])

  useEffect(() => {
    if (src) {
      setBg(src, maskSrc)
    }
  }, [src, maskSrc])

  useEffect(() => {
    const newLines = []
    lines.forEach(l => {
      const oldPoints = l.points
      const newPoints = []

      for (let i = 0; i < oldPoints.length / 2; i++) {
        const point = {
          x: oldPoints[i * 2] / scale,
          y: oldPoints[i * 2 + 1] / scale,
        }
        newPoints.push(point.x, point.y)
      }

      const newLine = {
        tool: l.tool,
        points: newPoints,
        brushSize: l.brushSize,
      }
      newLines.push(newLine)
    })
    setScaledLines(newLines)
  }, [lines])

  useEffect(() => {
    setWidth(orgW * scale)
    setHeight(orgH * scale)
  }, [scale])

  useEffect(() => {
    if (tool !== 'eraser') lastTool = tool
  }, [tool])

  useEffect(() => {
    if (init && !showBrushPreview) {
      setShowBrushPreview(true)

      setTimeout(() => {
        setShowBrushPreview(false)
      }, 1000)
    }
  }, [brushSize])

  const handleMouseDown = e => {
    isDrawing.current = true
    const b = brushSize
    const pos = e.target.getStage().getPointerPosition()
    setLines([...lines, { tool, points: [pos.x, pos.y], brushSize: b }])
    setShowZoom(false)
    setShowEraserButton(false)
  }

  const handleMouseMove = e => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()
    const lastLine = lines[lines.length - 1]
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y])

    // replace last
    lines.splice(lines.length - 1, 1, lastLine)
    setLines(lines.concat())
  }

  const handleMouseUp = () => {
    isDrawing.current = false
    setShowZoom(true)
    setShowEraserButton(true)
  }

  const handleMask = async type => {
    setIsLoading(true)
    const { Image } = require('image-js')
    const w = orgW
    const h = orgH

    // const canvas = exportLayerRef.current.toCanvas({ x: 0, y: 0, w, h })
    // const imageData = canvas.getContext('2d').getImageData(0, 0, w, h)

    // const originalImg = new Image(w, h, imageData.data)
    // const greyedImg = originalImg.grey()
    // greyedImg.data = greyedImg.data.map(x => (x > 254 ? 1 : x > 0 ? 2 : 0))

    // const url = greyedImg.toDataURL()

    const canvasPen = exportPenLayerRef.current.toCanvas({ x: 0, y: 0, w, h })
    const canvasRemove = exportRemoveLayerRef.current.toCanvas({ x: 0, y: 0, w, h })
    const penData = canvasPen.getContext('2d').getImageData(0, 0, w, h)
    const removeData = canvasRemove.getContext('2d').getImageData(0, 0, w, h)

    const penImgGrey = new Image(w, h, penData.data).grey()
    const removeImgGrey = new Image(w, h, removeData.data).grey()

    penImgGrey.data = penImgGrey.data.map(x => (x > 0 ? 1 : 0))
    removeImgGrey.data = removeImgGrey.data.map(x => (x > 0 ? 2 : 0))

    penImgGrey.data = penImgGrey.data.map((x, i) => {
      if (removeImgGrey.data[i] > 0) return removeImgGrey.data[i]
      return x
    })

    const url = penImgGrey.toDataURL()
    // console.log([...new Set(penImgGrey.data.filter(v => v > 0))])
    // console.log(url)

    const additionalMask = url.replace(IMG_BASE64, '')

    apis.appfront
      .editMask({ type, artworkId: artwork.id, additionalMask, sodUrl: endpoint.sod_url })
      .then(response => {
        const imgB = response.data.img.body
        const maskB = response.data.mask.body

        if (response.data.code === 201) {
          showConfirm({
            content: (
              <>
                <Korean>상품영역을 모두 제거할 수 없습니다.</Korean>
                <Japanese>商品エリアをすべて削除することはできません。</Japanese>
                <English>You can&apos;t delete all of your product photos.</English>
              </>
            ),
            alertOnly: true,
          })
        }

        setSrc(`${IMG_BASE64}${imgB.replaceAll('"', '')}`)
        setMaskSrc(`${IMG_BASE64}${maskB.replaceAll('"', '')}`)
        setSodModified(true)
      })
      .finally(() => {
        setIsLoading(false)
      })

    setLines([])
  }

  const resetMask = () => {
    setIsLoading(true)
    setLines([])
    setSodModified(false)
    setLineHistory([])

    apis.appfront
      .restoreMask({ artworkId: artwork.id })
      .then(response => {
        const maskB = response.data.mask.body
        setMaskSrc(`${IMG_BASE64}${maskB.replaceAll('"', '')}`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const undo = () => {
    if (lines.length > 0) {
      const last = lines[lines.length - 1]

      if (lineHistory.length >= LINE_HISTORY_LIMIT) {
        return
      } else {
        setLineHistory(prev => [...prev, last])
      }

      setLines(lines.slice(0, -1))
    }
  }
  const redo = () => {
    if (lineHistory.length > 0) {
      const pop = lineHistory[lineHistory.length - 1]
      setLineHistory(prev => prev.slice(0, -1))
      setLines(lines.concat(pop))
    }
  }

  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        height: MAX_H,

        position: 'relative',
      }}
    >
      {' '}
      {resetDialogOpen && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            background: 'rgb(0,0,0,0.5)',
            position: 'absolute',
            zIndex: 1000,
          }}
        ></Box>
      )}
      <Stack
        sx={{
          position: 'relative',
        }}
      >
        <Stack
          direction="row"
          sx={{
            height: zoomAreaHeight,
            width: '38rem',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'white',
            borderRadius: '3rem',
            position: 'absolute',
            top: showZoom ? '1.4rem' : '-10rem',
            left: '50%',
            transform: 'translate(-50%)',
            zIndex: 903,
            boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
            opacity: showZoom ? 1 : 0,
            transition: 'opacity 0.5s, top 0.9s',
          }}
          spacing="2rem"
        >
          <Box
            sx={{
              height: '2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              setZoom(z => (z - 0.1 >= 1 ? z - 0.1 : 1))
            }}
          >
            <svg
              width="16"
              height="4"
              viewBox="0 0 16 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2H14"
                stroke="#303030"
                strokeWidth="3"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </svg>
          </Box>

          <Slider
            aria-label="zoom"
            value={zoom}
            onChange={(e, v) => setZoom(v)}
            min={1}
            max={4}
            step={0.1}
            sx={{
              width: '24rem',
              '& .MuiSlider-rail': {
                height: '0.8rem',
                opacity: 1,
                background: '#d9d9d9',
              },

              '& .MuiSlider-track': {
                height: '0.8rem',
                border: 0,
              },

              '& .MuiSlider-thumb': {
                width: '2rem',
                height: '2rem',
                outline: '0.1rem solid #4D80FF',
                outlineOffset: '.6rem',

                '&:hover': {
                  boxShadow: 'none',
                },

                '&.Mui-active': {
                  boxShadow: '2px 2px 20px rgba(77, 128, 255, 0.6)',
                },
              },
            }}
          />
          <Box
            sx={{
              height: '2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              setZoom(z => (z + 0.1 <= 4 ? z + 0.1 : 4))
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 2V14"
                stroke="#303030"
                strokeWidth="3"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
              <path
                d="M2 8H14"
                stroke="#303030"
                strokeWidth="3"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
        </Stack>
        <Box
          sx={{
            width: MAX_W,
            height: MAX_H,
            display: 'flex',
            position: 'relative',
          }}
        >
          {resetDialogOpen && (
            <ResetDialog
              open={resetDialogOpen}
              setOpen={setResetDialogOpen}
              subTab={subTab}
              setSubTab={setSubTab}
            />
          )}
          <div
            id="brush-cursor-preview"
            style={{
              ...brushCursorStyle,
              // display: showBrushPreview ? 'block' : 'none',
              display: 'block',
              visibility: showBrushPreview ? 'visible' : 'hidden',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: tool === 'remove-pen' ? 'red' : tool === 'pen' ? 'lime' : '#4d80ff',
              border: '1px solid gray',
              width: brushSize * zoom,
              height: brushSize * zoom,
              opacity: showBrushPreview ? 0.6 : 0,
              transition: 'all .3s ease-out',
            }}
          />
          {isLoading && (
            <CenterAlignBox
              sx={{
                position: 'absolute',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.50)',
                width: '100%',
                height: '100%',
                zIndex: 1000,
              }}
            >
              <FadeLoader color="#FFF" height={25} margin={16} radius={9} width={6} />
            </CenterAlignBox>
          )}

          <Box
            sx={{
              width: MAX_W,
              height: MAX_H,
              maxWidth: MAX_W,
              maxHeight: MAX_H,
              overflow: 'scroll',
              background: '#6D6D6D',
              minHeight: MAX_H,

              pt: height * zoom < MAX_H ? `${(MAX_H - height * zoom) / 2}px` : 0,
              pl: width * zoom < MAX_W ? `${(MAX_W - width * zoom) / 2}px` : 0,
            }}
          >
            <Box
              sx={{
                transform: `scale(${zoom})`,
                transformOrigin: 'top left',
                width,
              }}
            >
              {/* 왜인지 이 값이 없으면 줌값 바꼈을 때 제때 리렌더링이 안됨.. */}
              <span
                style={{
                  position: 'fixed',
                  color: 'transparent',
                }}
              >
                {zoom}
              </span>
              <Box
                sx={{
                  position: 'absolute',
                  cursor: 'none',
                  zIndex: 902,
                  opacity: 0.6,
                }}
                id="stage902"
                onMouseEnter={() => {
                  setBrushCursorStyle({
                    ...brushCursorStyle,
                    position: 'fixed',
                    display: 'block',
                    width: brushSize * zoom,
                    height: brushSize * zoom,
                    backgroundColor:
                      tool === 'remove-pen' ? 'red' : tool === 'pen' ? 'lime' : '#4d80ff',
                    opacity: 0.6,
                  })
                }}
                onMouseMove={e => {
                  const circle = document.getElementById('brush-cursor')
                  if (brushCursorStyle.display === 'none') {
                    setBrushCursorStyle({
                      ...brushCursorStyle,
                      display: 'block',
                      width: brushSize * zoom,
                      height: brushSize * zoom,
                      backgroundColor: tool === 'remove-pen' ? 'red' : 'lime',
                      opacity: 0.6,
                    })
                  }

                  window.requestAnimationFrame(() => {
                    circle.style.top = `${e.clientY - circle.offsetHeight / 2}px`
                    circle.style.left = `${e.clientX - circle.offsetWidth / 2}px`
                  })
                }}
                onMouseOut={() => {
                  isDrawing.current = false
                  setShowZoom(true)
                  setShowEraserButton(true)
                  resetBrushCursorStyle()
                }}
              >
                <Stage
                  ref={stageRef}
                  width={width}
                  height={height}
                  onMouseDown={handleMouseDown}
                  onMousemove={handleMouseMove}
                  onMouseup={handleMouseUp}
                >
                  <Layer ref={drawLayerRef}>
                    {lines.map((line, i) => (
                      <Line
                        key={i}
                        points={line.points}
                        stroke={line.tool === 'remove-pen' ? 'red' : 'lime'}
                        // strokeWidth={brushSize}
                        strokeWidth={line.brushSize ?? brushSize}
                        lineCap="round"
                        lineJoin="round"
                        globalCompositeOperation={
                          // 'source-over'
                          line.tool === 'eraser' ? 'destination-out' : 'source-over'
                        }
                      />
                    ))}
                  </Layer>
                </Stage>
              </Box>
              <Box
                sx={{
                  width,
                  height,
                  position: 'absolute',
                  opacity: 1,
                  backgroundImage: `url(${src})`,
                  backgroundSize: 'cover',
                  zIndex: 900,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              ></Box>
              <Box
                sx={{
                  width,
                  height,
                  position: 'absolute',
                  opacity: 0.7,
                  backgroundImage: `url(${maskSrc})`,
                  backgroundSize: 'cover',
                  zIndex: 901,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              ></Box>
            </Box>
          </Box>
        </Box>
      </Stack>
      <CenterAlignStack
        sx={{
          flex: 1,
          p: { lg: '3.1rem 2.8rem 2.4rem 2.8rem', xs: '0rem 0 0.6rem 0' },
          gap: { lg: 0, xs: '2rem' },
          backgroundColor: '#F8F8F8',
        }}
      >
        <StyledToggleButtonGroup
          value={subTab}
          exclusive
          onChange={e => {
            setSubTab(Number(e.target.value))
          }}
          // onClick={() => {
          //   setResetDialogOpen(true)
          // }}
          sx={{ gap: '0 !important' }}
        >
          <StyledToggleButton
            sx={{
              boxShadow: 'none',
              width: { lg: isKo(i18n) ? '11.2rem' : '11.2rem', xs: '5rem ' },
              height: { lg: '4rem', xs: '4.4rem ' },
              fontSize: { lg: '1.6rem', xs: '1.6rem ' },
              fontWeight: 700,
              p: 0,
              border: '1px solid #D9E1EC',

              '&.Mui-selected': {
                boxShadow: 'none',
                border: 'none',
              },
            }}
            value={1}
          >
            {t('regenerate_dialog.segment')}
          </StyledToggleButton>
          <StyledToggleButton
            sx={{
              boxShadow: 'none',
              width: { lg: isKo(i18n) ? '11.2rem' : '11.2rem', xs: '5rem ' },
              height: { lg: '4rem', xs: '4.4rem ' },
              fontSize: { lg: '1.6rem', xs: '1.6rem ' },
              fontWeight: 700,
              p: 0,
              border: '1px solid #D9E1EC',

              '&.Mui-selected': {
                boxShadow: 'none',
                border: 'none',
              },
            }}
            value={2}
          >
            {t('regenerate_dialog.brush')}
          </StyledToggleButton>
        </StyledToggleButtonGroup>

        <CenterAlignStack sx={{ m: '1.2rem 0 2.8rem' }}>
          <Korean>
            <Typography sx={{ fontSize: '1.2rem', lineHeight: 'normal' }}>
              <span style={{ fontWeight: 600 }}>빨간색 영역</span>은 제거,{' '}
              <span style={{ fontWeight: 600 }}>녹색 영역</span>은 추가입니다.
            </Typography>
          </Korean>
          <Japanese>
            <Typography sx={{ fontSize: '1.2rem', lineHeight: 'normal' }}>
              <span style={{ fontWeight: 600 }}>赤い領域</span>は削除、{' '}
              <span style={{ fontWeight: 600 }}>緑の領域</span>は追加です。
            </Typography>
          </Japanese>
        </CenterAlignStack>

        <Box sx={{ height: { lg: '9rem', xs: '9.5rem' }, position: 'relative' }}>
          <Divider
            orientation="vertical"
            id="dididi"
            sx={{
              height: { lg: '6rem', xs: '4.8rem' },
              borderColor: '#AFAFAF',
              mt: { lg: 0, xs: '0.7rem' },
              position: 'absolute',
              left: { lg: '8rem', xs: '8.5rem' },
            }}
            flexItem
          />
          <ToggleButtonGroup
            value={tool}
            exclusive
            onChange={(e, v) => {
              if (tool === 'eraser' && v === null) {
                if (lastTool) {
                  setTool(lastTool)
                } else setTool('pen')
              }
              if (v !== null) setTool(v)
            }}
          >
            <ToggleButton
              value="remove-pen"
              sx={{
                width: { lg: '6rem', xs: '6rem' },
                height: { lg: '6rem', xs: '6rem' },
                border: 0,
                borderRadius: '1rem !important',
                backgroundColor: '#C5C5C5',

                '&.MuiToggleButton-root.Mui-selected': {
                  backgroundColor: '#F12B5B',
                },
                mr: { lg: '4rem', xs: '5.6rem' },
                transition: 'all 0.1s linear',
              }}
            >
              <svg
                width="30"
                height="4"
                viewBox="0 0 30 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M27.8571 0C29.0406 0 30 0.89543 30 2C30 3.10457 29.0406 4 27.8571 4H2.14286C0.95939 4 0 3.10457 0 2C0 0.89543 0.95939 0 2.14286 0L27.8571 0Z"
                  fill="white"
                />
              </svg>
              <Typography
                sx={{ position: 'absolute', bottom: '-3rem' }}
                fontSize="1.6rem"
                fontWeight={700}
              >
                {t('regenerate_dialog.bg_edit_removed')}
              </Typography>
            </ToggleButton>

            <ToggleButton
              value="pen"
              sx={{
                width: { lg: '6rem', xs: '6rem' },
                height: { lg: '6rem', xs: '6rem' },
                border: 0,
                borderRadius: '1rem !important',
                backgroundColor: '#C5C5C5',

                '&.MuiToggleButton-root.Mui-selected': {
                  backgroundColor: '#06A561',
                },
                transition: 'all 0.1s linear',
              }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 0C16.1835 0 17.1429 0.95939 17.1429 2.14286V12.855L27.8571 12.8571C29.0406 12.8571 30 13.8165 30 15C30 16.1835 29.0406 17.1429 27.8571 17.1429L17.1429 17.1407V27.8571C17.1429 29.0406 16.1835 30 15 30C13.8165 30 12.8571 29.0406 12.8571 27.8571V17.1407L2.14286 17.1429C0.95939 17.1429 0 16.1835 0 15C0 13.8165 0.95939 12.8571 2.14286 12.8571L12.8571 12.855V2.14286C12.8571 0.95939 13.8165 0 15 0Z"
                  fill="white"
                />
              </svg>
              <Typography
                sx={{ position: 'absolute', bottom: '-3rem' }}
                fontSize="1.6rem"
                fontWeight={700}
              >
                {t('regenerate_dialog.bg_edit_added')}
              </Typography>
            </ToggleButton>

            <ToggleButton
              value="eraser"
              sx={{
                width: '4.8rem',
                height: '4.8rem',
                border: 0,
                borderRadius: '1rem !important',
                backgroundColor: theme => theme.palette.common.white,
                '& path': {
                  stroke: theme => theme.palette.common.black,
                },

                '&:hover': {
                  backgroundColor: theme => theme.palette.common.white,
                  '& path': {
                    stroke: theme => theme.palette.draph.blue,
                  },
                },

                '&.MuiToggleButton-root.Mui-selected': {
                  backgroundColor: theme => theme.palette.common.white,
                  '& path': {
                    stroke: theme => theme.palette.draph.blue,
                  },
                },
                position: 'absolute',
                left: showEraserButton ? '-12rem' : '-8rem',
                opacity: showEraserButton ? 1 : 0,
                bottom: '-27rem',
                boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
                transition: 'opacity 0.5s, left 0.5s',
              }}
            >
              <svg
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.02054 19.9447L2.74563 15.6698C1.75146 14.6756 1.75146 13.1844 2.74563 12.2896L12.2896 2.74563C13.2838 1.75146 14.775 1.75146 15.6698 2.74563L21.2371 8.31296C22.2313 9.30713 22.2313 10.7984 21.2371 11.6931L12.9855 19.9447"
                  stroke="#303030"
                  strokeWidth="2.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.933 19.9453H7.02051"
                  stroke="#303030"
                  strokeWidth="2.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.03223 10L13.9797 18.9475"
                  stroke="#303030"
                  strokeWidth="2.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Stack sx={{ mt: '4.6rem', width: '22.4rem', alignItems: 'center' }}>
          <Typography fontSize="1.6rem" fontWeight={700} sx={{ mb: '0.4rem' }}>
            {/* {tool === 'eraser' ? '지우개' : '브러쉬'} */}
            {t('regenerate_dialog.bg_edit_brush')}
          </Typography>
          <Slider
            aria-label="Brush size"
            value={brushSize}
            onChange={(e, v) => setBrushSize(v)}
            min={10}
            max={100}
            sx={{
              width: '22.4rem',
              '& .MuiSlider-rail': {
                height: '0.4rem',
                opacity: 1,
                background: '#d9d9d9',
              },

              '& .MuiSlider-track': {
                height: '0.4rem',
                border: 0,
              },

              '& .MuiSlider-thumb': {
                width: '2rem',
                height: '2rem',

                '&:hover': {
                  boxShadow: 'none',
                },

                '&.Mui-active': {
                  boxShadow: '2px 2px 20px rgba(77, 128, 255, 0.6)',
                },
              },
            }}
          />
        </Stack>

        <Stack
          direction="row"
          sx={{ mt: '2.4rem', alignItems: 'center', justifyContent: 'center' }}
          gap={{ lg: '0.8rem', xs: '1.4rem' }}
        >
          <Button
            variant="outlined"
            sx={roundButtonStyle}
            onClick={undo}
            disabled={lines.length <= 0}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 11L1 6L6 1"
                stroke="#303030"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 6H11.5C12.2223 6 12.9375 6.14226 13.6048 6.41866C14.272 6.69506 14.8784 7.10019 15.3891 7.61091C15.8998 8.12163 16.3049 8.72795 16.5813 9.39524C16.8577 10.0625 17 10.7777 17 11.5C17 12.2223 16.8577 12.9375 16.5813 13.6048C16.3049 14.272 15.8998 14.8784 15.3891 15.3891C14.8784 15.8998 14.272 16.3049 13.6048 16.5813C12.9375 16.8577 12.2223 17 11.5 17H8"
                stroke="#303030"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t('regenerate_dialog.bg_edit_undo')}
          </Button>
          <Button
            variant="outlined"
            sx={roundButtonStyle}
            onClick={redo}
            disabled={lineHistory.length <= 0}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 11L17 6L12 1"
                stroke="#303030"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17 6H6.5C5.04131 6 3.64236 6.57946 2.61091 7.61091C1.57946 8.64236 1 10.0413 1 11.5C1 12.2223 1.14226 12.9375 1.41866 13.6048C1.69506 14.272 2.10019 14.8784 2.61091 15.3891C3.64236 16.4205 5.04131 17 6.5 17H10"
                stroke="#303030"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t('regenerate_dialog.bg_edit_redo')}
          </Button>
        </Stack>

        <Stack direction="row" gap="0.6rem" sx={{ flex: 1, alignItems: 'flex-end' }}>
          <Button
            variant="outlined"
            onClick={resetMask}
            disabled={isLoading}
            sx={{
              width: '6.4rem',
              background: theme => theme.palette.common.white,
              ...squareButtonStyle,
            }}
          >
            {t('regenerate_dialog.bg_edit_reset')}
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              handleMask()
            }}
            disabled={isLoading}
            sx={{ width: '6.4rem', ...squareButtonStyle }}
          >
            {t('regenerate_dialog.bg_edit_edit')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleMask('ai')
            }}
            disabled={isLoading}
            sx={{ width: '8.4rem', ...squareButtonStyle }}
          >
            {t('regenerate_dialog.bg_edit_onetouch')}
          </Button>
        </Stack>

        <Box sx={{ display: 'none' }}>
          <Stack direction="row">
            <img src={src} style={{ width: '50rem' }} />
            <img src={maskSrc} style={{ width: '50rem' }} />
          </Stack>
        </Box>

        <Box sx={{ display: 'none' }}>
          <Stage width={width / scale} height={height / scale}>
            <Layer>
              <Rect width={width / scale} height={height / scale} fillPatternImage={image}></Rect>
            </Layer>
            {/* <Layer ref={exportLayerRef}>
              {scaledLines.map((line, i) => (
                <Line
                  key={i}
                  points={line.points}
                  stroke={line.tool === 'remove-pen' ? 'red' : '#FFFFFF'}
                  opacity={1}
                  strokeWidth={line.brushSize ? line.brushSize / scale : brushSize / scale}
                  lineCap="round"
                  lineJoin="round"
                  globalCompositeOperation={
                    // 'source-over'
                    line.tool === 'eraser' ? 'destination-out' : 'source-over'
                  }
                />
              ))}
            </Layer> */}
            <Layer ref={exportPenLayerRef}>
              {scaledLines
                .filter(l => l.tool === 'pen')
                .map((line, i) => (
                  <Line
                    key={i}
                    points={line.points}
                    stroke={line.tool === 'remove-pen' ? 'red' : 'lime'}
                    opacity={1}
                    strokeWidth={line.brushSize ? line.brushSize / scale : brushSize / scale}
                    lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation={
                      // 'source-over'
                      line.tool === 'eraser' ? 'destination-out' : 'source-over'
                    }
                  />
                ))}
            </Layer>
            <Layer ref={exportRemoveLayerRef}>
              {scaledLines
                .filter(l => l.tool === 'remove-pen')
                .map((line, i) => (
                  <Line
                    key={i}
                    points={line.points}
                    stroke={line.tool === 'remove-pen' ? 'red' : 'lime'}
                    opacity={1}
                    strokeWidth={line.brushSize ? line.brushSize / scale : brushSize / scale}
                    lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation={
                      // 'source-over'
                      line.tool === 'eraser' ? 'destination-out' : 'source-over'
                    }
                  />
                ))}
            </Layer>
          </Stage>
        </Box>
        <div id="brush-cursor" style={brushCursorStyle} />
      </CenterAlignStack>
    </Stack>
  )
}

function getImageMeta(url) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = () => reject(new Error('something bad happened'))
    img.src = url
  })
}

function RegenerateContent({
  tab,
  setTab,
  subTab,
  setSubTab,
  artwork,
  artworkConfig,
  category,
  setCategory,
  angle,
  setAngle,
  themeTemplate,
  setThemeTemplate,
  themeCustom,
  setThemeCustom,
  setSodModified,
  noSetting,
  noEditArea,
}) {
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const isSimpleConfig = !artworkConfig.flag_complex_cmp && !artworkConfig.flag_generate

  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const subTabStyle = {
    fontSize: { lg: '1.4rem', xs: '1.2rem' },
    fontWeight: 500,
    lineHeight: 'normal',
    cursor: 'pointer',
  }

  const selectedSubTabStyle = {
    fontWeight: 700,
    color: theme.palette.draph.blue,
  }

  const guideMenu = [
    {
      id: 'bg_removal',
      component: (
        <>
          {t('regenerate_dialog.guide_comment_1_a')}
          <br />
          {t('regenerate_dialog.guide_comment_1_b')}
        </>
      ),
    },
    { id: 'tilted', component: t('regenerate_dialog.guide_comment_2') },
    { id: 'not_attractive', component: t('regenerate_dialog.guide_comment_3') },
    { id: 'not_clear', component: t('regenerate_dialog.guide_comment_4') },
    { id: 'other', component: t('regenerate_dialog.guide_comment_5') },
  ]

  switch (true) {
    case tab === 1:
      return (
        <CenterAlignStack
          direction={isMobile ? 'column' : 'row'}
          sx={{ background: theme.palette.common.white, height: { lg: '46rem' }, width: '100%' }}
        >
          <CenterAlignStack
            sx={{
              width: { lg: '50%', xs: '100%' },
              height: { lg: '100%', xs: 'auto' },
              pt: { lg: '2rem', xs: '0' },
              pb: { lg: '0rem', xs: '0.3rem' },
              px: { lg: '2rem', xs: 0 },
              background: 'white',
            }}
          >
            <SelectCategory
              artworkConfig={artworkConfig}
              category={category}
              setCategory={setCategory}
            />

            <CenterAlignBox
              sx={{
                background: { lg: 'inherit', xs: '#F8F8F8' },
                width: { lg: 'inherit', xs: '100%' },
                pb: { lg: 0, xs: '1.5rem' },
              }}
            >
              <SelectAngle angle={angle} setAngle={setAngle} />
            </CenterAlignBox>
          </CenterAlignStack>

          <CenterAlignStack
            sx={{
              width: { lg: '50%', xs: '100%' },
              height: { lg: '100%' },
              pt: { lg: '2rem', xs: '1.62rem' },
              px: { lg: '2rem' },
              background: { lg: '#F8F8F8', xs: 'inherit' },
            }}
          >
            {isSimpleConfig ? (
              <SelectSimple />
            ) : (
              <SelectTheme
                themeTemplate={themeTemplate}
                setThemeTemplate={setThemeTemplate}
                themeCustom={themeCustom}
                setThemeCustom={setThemeCustom}
                category={category}
              />
            )}
          </CenterAlignStack>
        </CenterAlignStack>
      )

    case tab === 2:
      return (
        <>
          <NukkiEditTab artwork={artwork} setSodModified={setSodModified} />
          {/* <SodCanvas artwork={artwork} setSodModified={setSodModified} /> */}
        </>
      )

    case tab === 3:
      return (
        <CenterAlignStack
          direction={isMobile ? 'column' : 'row'}
          sx={{ background: theme.palette.common.white, height: { lg: '100%', xs: '58.645rem' } }}
        >
          <CenterAlignStack
            sx={{
              minWidth: { lg: isKo(i18n) ? '35.9rem' : '40.9rem', xs: 'auto' },
              width: { lg: '35.9rem', xs: '100%' },
              height: { lg: '100%' },
              borderRight: { lg: '1px solid #AFAFAF', xs: '0' },
            }}
          >
            <CenterAlignBox
              sx={{
                width: '100%',
                p: { lg: '1.8rem 1.8rem', xs: '0 0 1.6rem 0' },
                background: theme.palette.common.white,
              }}
            >
              <Typography
                sx={{
                  fontSize: isKo(i18n) ? '1.6rem' : '1.3rem',
                  fontWeight: 700,
                  lineHeight: 'normal',
                }}
              >
                {t('regenerate_dialog.guide_title')}
              </Typography>
            </CenterAlignBox>
            <Stack
              component="ol"
              sx={{
                width: '100%',
                listStyleType: 'disc',
                gap: '1.6rem',
                p: { lg: '2.1rem 3.2rem 2.1rem 4.9rem', xs: 0 },
                background: { lg: '#f8f8f8', xs: 'inherit' },
              }}
            >
              {guideMenu.map(({ id, component }, i) => {
                return (
                  <Stack key={i + 1}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        background: '#f8f8f8',
                        padding: { lg: '0', xs: '0.6rem 2rem' },
                        width: { lg: 'auto', xs: '32rem' },
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        // ----- GA4 event -----
                        window.gtag(
                          'event',
                          portfolioType ? 'regenerate_guide' : 'portfolio_regenerate_guide',
                          { menu: id }
                        )
                        // ---------------------

                        setSubTab(i + 1)
                      }}
                    >
                      <Typography
                        component="li"
                        sx={{ ...subTabStyle, ...(subTab === i + 1 && selectedSubTabStyle) }}
                      >
                        {component}
                      </Typography>
                      {isMobile && <DropdownArrowIcon color="#808080" size="xs" />}
                    </Box>
                    {isMobile && (
                      <Collapse in={tab === 3 && subTab === i + 1}>
                        <GuideContent
                          guideMenu={guideMenu}
                          noEditArea={noEditArea}
                          subTab={subTab}
                          setTab={setTab}
                          noSetting={noSetting}
                        />
                      </Collapse>
                    )}
                  </Stack>
                )
              })}
            </Stack>
          </CenterAlignStack>

          <CenterAlignStack
            sx={{
              width: { lg: 'auto', xs: '100%' },
              height: { lg: '100%' },
              pt: '2rem',
              px: { lg: '2rem' },
              background: theme.palette.common.white,
              flex: 1,
            }}
          >
            <Desktop>
              <GuideContent
                guideMenu={guideMenu}
                noEditArea={noEditArea}
                subTab={subTab}
                setTab={setTab}
                noSetting={noSetting}
              />
            </Desktop>
            {subTab !== 0 && <></>}
          </CenterAlignStack>
        </CenterAlignStack>
      )
  }
}

function GuideContent({ guideMenu, subTab, setTab, noSetting, noEditArea }) {
  const { t, i18n } = useTranslation()
  const [text, setText] = useState('')
  const theme = useTheme()
  const isMobile = useMobileMediaQuery()

  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const commentStyle = {
    fontSize: subTab === 3 && !isKo(i18n) ? '1.1rem' : '1.2rem',
    fontWeight: 400,
    lineHeight: 'normal',
  }
  const commentWrapperStyle = {
    gap: '1.6rem',
    minHeight: { lg: 'none', xs: '26.6rem' },
    maxHeight: { lg: 'none', xs: '26.6rem' },
    overflow: { lg: 'inherit', xs: 'auto' },
    mt: { lg: 0, xs: '1.6rem' },
  }

  const EditNukkiButton = () => (
    <Desktop>
      <Button
        variant="outlined"
        sx={{
          p: '0 1.35rem',
          height: '2.4rem',
          borderRadius: '2rem',
          border: `1px solid #303030`,
          fontSize: '1.1rem',
          fontWeight: 500,
          '&:hover': {
            border: `1px solid ${theme.palette.draph.blue}`,
          },
        }}
        onClick={() => {
          // ----- GA4 event -----
          window.gtag(
            'event',
            portfolioType ? 'regenerate_guide_button' : 'portfolio_regenerate_guide_button',
            {
              menu: guideMenu[subTab - 1].id,
              method: 'edit_area',
            }
          )
          // ---------------------
          setTab(2)
        }}
      >
        {t('regenerate_dialog.guide_sub_button_1')}
      </Button>
    </Desktop>
  )

  const SetConfigButton = () => (
    <Button
      variant="outlined"
      sx={{
        p: '0 1.35rem',
        height: '2.4rem',
        borderRadius: '2rem',
        border: `1px solid #303030`,
        fontSize: '1.1rem',
        fontWeight: 500,
        '&:hover': {
          border: `1px solid ${theme.palette.draph.blue}`,
        },
      }}
      onClick={() => {
        // ----- GA4 event -----
        window.gtag(
          'event',
          portfolioType ? 'regenerate_guide_button' : 'portfolio_regenerate_guide_button',
          {
            menu: guideMenu[subTab - 1].id,
            method: 'settings',
          }
        )
        // ---------------------
        setTab(1)
      }}
    >
      {t('regenerate_dialog.guide_sub_button_2')}
    </Button>
  )

  switch (true) {
    case subTab === 0:
      return (
        <CenterAlignBox
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: 'inherit',
            height: 'inherit',
          }}
        >
          <img src="/static/images/logo/logo_h_black.png" style={{ width: '16.2rem' }} />
        </CenterAlignBox>
      )

    case subTab === 1:
      return (
        <CenterAlignStack sx={commentWrapperStyle}>
          <Typography
            sx={{ fontSize: { lg: '1.8rem', xs: '1.4rem' }, fontWeight: { lg: 800, xs: 700 } }}
          >
            {t('regenerate_dialog.sub_1_title')}
          </Typography>
          <CenterAlignStack sx={{ gap: '1.4rem' }}>
            <CenterAlignStack sx={{ gap: '0.8rem' }}>
              <GoodBadExample
                good="/static/images/regen_dialog/sub_1-1.jpeg"
                bad="/static/images/regen_dialog/sub_1-2.jpeg"
                width={{ lg: '10rem', xs: '10rem' }}
                iconSize="1rem"
                sx={{ gap: '0.8rem' }}
                rectsx={{ height: '2rem' }}
              />

              <Typography sx={commentStyle}>
                {t('regenerate_dialog.sub_1_comment_1_a')}
                <SpanDraphBlue style={{ fontWeight: 700 }}>
                  {t('regenerate_dialog.sub_1_comment_1_b')}
                </SpanDraphBlue>
                {t('regenerate_dialog.sub_1_comment_1_c')}
                <SpanDraphBlue style={{ fontWeight: 700 }}>
                  {t('regenerate_dialog.sub_1_comment_1_d')}
                </SpanDraphBlue>
              </Typography>
            </CenterAlignStack>

            <CenterAlignStack sx={{ gap: '0.8rem' }}>
              <GoodBadExample
                good="/static/images/regen_dialog/sub_1-3.jpeg"
                bad="/static/images/regen_dialog/sub_1-4.jpeg"
                width={{ lg: '10rem', xs: '10rem' }}
                iconSize="1rem"
                sx={{ gap: '0.8rem' }}
                rectsx={{ height: '2rem' }}
              />

              <Typography sx={commentStyle}>
                {t('regenerate_dialog.sub_1_comment_2_a')}
                <SpanDraphBlue style={{ fontWeight: 700 }}>
                  {t('regenerate_dialog.sub_1_comment_2_b')}
                </SpanDraphBlue>
                {t('regenerate_dialog.sub_1_comment_2_c')}
                <SpanDraphBlue style={{ fontWeight: 700 }}>
                  {t('regenerate_dialog.sub_1_comment_2_d')}
                </SpanDraphBlue>
              </Typography>
            </CenterAlignStack>

            <CenterAlignStack sx={{ gap: '0.8rem' }}>
              <GoodBadExample
                good="/static/images/regen_dialog/sub_1-5.jpeg"
                bad="/static/images/regen_dialog/sub_1-6.jpeg"
                width={{ lg: '10rem', xs: '10rem' }}
                iconSize="1rem"
                sx={{ gap: '0.8rem' }}
                rectsx={{ height: '2rem' }}
              />

              <Typography sx={commentStyle}>
                {t('regenerate_dialog.sub_1_comment_3_a')}
                <SpanDraphBlue style={{ fontWeight: 700 }}>
                  {t('regenerate_dialog.sub_1_comment_3_b')}
                </SpanDraphBlue>
                {t('regenerate_dialog.sub_1_comment_3_c')}
              </Typography>
            </CenterAlignStack>
          </CenterAlignStack>
          <CenterAlignBox sx={{ gap: '0.8rem', animation: 'fadeIn 1s ' }}>
            {!noEditArea && <EditNukkiButton />}
            {!noSetting && <SetConfigButton />}
          </CenterAlignBox>
        </CenterAlignStack>
      )

    case subTab === 2:
      return (
        <CenterAlignStack sx={commentWrapperStyle}>
          <Typography
            sx={{ fontSize: { lg: '1.8rem', xs: '1.4rem' }, fontWeight: { lg: 800, xs: 700 } }}
          >
            {t('regenerate_dialog.sub_1_title')}
          </Typography>
          <CenterAlignStack sx={{ gap: '1.4rem' }}>
            <CenterAlignStack sx={{ gap: '0.8rem' }}>
              <GoodBadExample
                good="/static/images/regen_dialog/sub_2-1.jpeg"
                bad="/static/images/regen_dialog/sub_2-2.jpeg"
                width={{ lg: '10rem', xs: '10rem' }}
                iconSize="1rem"
                sx={{ gap: '0.8rem' }}
                rectsx={{ height: '2rem' }}
              />

              <Typography sx={commentStyle}>
                {t('regenerate_dialog.sub_2_comment_1_a')}
                <SpanDraphBlue style={{ fontWeight: 700 }}>
                  {t('regenerate_dialog.sub_2_comment_1_b')}
                </SpanDraphBlue>
                {t('regenerate_dialog.sub_2_comment_1_c')}
              </Typography>
            </CenterAlignStack>

            <CenterAlignStack sx={{ gap: '0.8rem' }}>
              <GoodBadExample
                good="/static/images/regen_dialog/sub_2-3.jpeg"
                bad="/static/images/regen_dialog/sub_2-4.jpeg"
                width={{ lg: '10rem', xs: '10rem' }}
                iconSize="1rem"
                sx={{ gap: '0.8rem' }}
                rectsx={{ height: '2rem' }}
              />

              <Typography sx={commentStyle}>
                {t('regenerate_dialog.sub_2_comment_2_a')}
                <SpanDraphBlue style={{ fontWeight: 700 }}>
                  {t('regenerate_dialog.sub_2_comment_2_b')}
                </SpanDraphBlue>
                {t('regenerate_dialog.sub_2_comment_2_c')}
              </Typography>
            </CenterAlignStack>
          </CenterAlignStack>
          <CenterAlignBox sx={{ gap: '0.8rem', animation: 'fadeIn 1s ' }}>
            {!noEditArea && <EditNukkiButton />}
            {!noSetting && <SetConfigButton />}
          </CenterAlignBox>
        </CenterAlignStack>
      )

    case subTab === 3:
      return (
        <CenterAlignStack sx={commentWrapperStyle}>
          <Typography
            sx={{ fontSize: { lg: '1.8rem', xs: '1.4rem' }, fontWeight: { lg: 800, xs: 700 } }}
          >
            {t('regenerate_dialog.sub_1_title')}
          </Typography>
          <CenterAlignStack sx={{ gap: '1.6rem' }}>
            <CenterAlignStack>
              {isMobile ? (
                isKo(i18n) ? (
                  <img
                    style={{ width: '24rem' }}
                    src="/static/images/regen_dialog/sub_m_3-1.jpeg"
                  />
                ) : (
                  <img
                    style={{ width: '24rem' }}
                    src="/static/images/regen_dialog/sub_m_3-1_en.jpeg"
                  />
                )
              ) : isKo(i18n) ? (
                <img style={{ width: '33.2rem' }} src="/static/images/regen_dialog/sub_3-1.jpeg" />
              ) : (
                <img
                  style={{ width: '33.2rem' }}
                  src="/static/images/regen_dialog/sub_3-1_en.jpeg"
                />
              )}

              <Typography sx={{ textAlign: 'center', ...commentStyle }}>
                {t('regenerate_dialog.sub_3_comment_1_a')}
                <SpanDraphBlue style={{ fontWeight: 700 }}>
                  {t('regenerate_dialog.sub_3_comment_1_b')}
                </SpanDraphBlue>
                {t('regenerate_dialog.sub_3_comment_1_c')} {isMobile && <br />}
                {t('regenerate_dialog.sub_3_comment_1_d')}
              </Typography>
            </CenterAlignStack>

            <CenterAlignStack>
              {isMobile ? (
                isKo(i18n) ? (
                  <img
                    style={{ width: '24rem' }}
                    src="/static/images/regen_dialog/sub_m_3-2.jpeg"
                  />
                ) : (
                  <img
                    style={{ width: '24rem' }}
                    src="/static/images/regen_dialog/sub_m_3-2_en.jpeg"
                  />
                )
              ) : isKo(i18n) ? (
                <img style={{ width: '33.2rem' }} src="/static/images/regen_dialog/sub_3-2.jpeg" />
              ) : (
                <img
                  style={{ width: '33.2rem' }}
                  src="/static/images/regen_dialog/sub_3-2_en.jpeg"
                />
              )}

              <Typography sx={{ ...(isMobile && { mt: '0.4rem' }), ...commentStyle }}>
                {t('regenerate_dialog.sub_3_comment_2_a')}
                <SpanDraphBlue style={{ fontWeight: 700 }}>
                  {t('regenerate_dialog.sub_3_comment_2_b')}
                </SpanDraphBlue>
                {t('regenerate_dialog.sub_3_comment_2_c')}
              </Typography>
            </CenterAlignStack>
          </CenterAlignStack>
          <CenterAlignBox sx={{ gap: '0.8rem', animation: 'fadeIn 1s ' }}>
            {!noEditArea && <EditNukkiButton />}
            {!noSetting && <SetConfigButton />}
          </CenterAlignBox>
        </CenterAlignStack>
      )

    case subTab === 4:
      return (
        <CenterAlignStack sx={commentWrapperStyle}>
          <Typography
            sx={{ fontSize: { lg: '1.8rem', xs: '1.4rem' }, fontWeight: { lg: 800, xs: 700 } }}
          >
            {t('regenerate_dialog.sub_1_title')}
          </Typography>
          <CenterAlignStack sx={{ gap: '1.4rem' }}>
            <CenterAlignStack sx={{ gap: '0.8rem' }}>
              <GoodBadExample
                good="/static/images/regen_dialog/sub_4-1.jpeg"
                bad="/static/images/regen_dialog/sub_4-2.jpeg"
                width={{ lg: '10rem', xs: '10rem' }}
                iconSize="1rem"
                sx={{ gap: '0.8rem' }}
                rectsx={{ height: '2rem' }}
              />

              <Typography sx={commentStyle}>
                {t('regenerate_dialog.sub_4_comment_1_a')}

                <SpanDraphBlue style={{ fontWeight: 700 }}>
                  {t('regenerate_dialog.sub_4_comment_1_b')}
                </SpanDraphBlue>
                {t('regenerate_dialog.sub_4_comment_1_c')}
                <SpanDraphBlue style={{ fontWeight: 700 }}>
                  {t('regenerate_dialog.sub_4_comment_1_d')}
                </SpanDraphBlue>
              </Typography>
            </CenterAlignStack>

            <CenterAlignStack sx={{ gap: '0.8rem' }}>
              <GoodBadExample
                good="/static/images/regen_dialog/sub_4-3.jpeg"
                bad="/static/images/regen_dialog/sub_4-4.jpeg"
                width={{ lg: '10rem', xs: '10rem' }}
                iconSize="1rem"
                sx={{ gap: '0.8rem' }}
                rectsx={{ height: '2rem' }}
              />

              <Typography sx={commentStyle}>
                {t('regenerate_dialog.sub_4_comment_2_a')}

                <SpanDraphBlue style={{ fontWeight: 700 }}>
                  {t('regenerate_dialog.sub_4_comment_2_b')}
                </SpanDraphBlue>
                {t('regenerate_dialog.sub_4_comment_2_c')}
              </Typography>
            </CenterAlignStack>
          </CenterAlignStack>
          <CenterAlignBox sx={{ gap: '0.8rem', animation: 'fadeIn 1s ' }}>
            {!noEditArea && <EditNukkiButton />}
            {!noSetting && <SetConfigButton />}
          </CenterAlignBox>
        </CenterAlignStack>
      )

    case subTab === 5:
      return (
        <CenterAlignStack sx={commentWrapperStyle}>
          <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', fontWeight: 600 }}>
            {t('regenerate_dialog.sub_5_title_a')}
            <br />
            {t('regenerate_dialog.sub_5_title_b')}
          </Typography>

          <OutlinedInput
            autoFocus
            multiline
            rows={6}
            value={text}
            onChange={e => setText(e.target.value)}
            sx={{
              width: '27.7rem',
              minHeight: '16rem',
              maxHeight: '29rem',
              overflow: 'auto',
              background: ' white',
              alignItems: 'flex-start',
              '& .MuiOutlinedInput-input': { padding: 0 },
            }}
          />

          <Button
            variant="contained"
            sx={{ width: '13.2rem', height: '2.8rem', fontSize: '1.3rem', fontWeight: 700 }}
            onClick={() => {
              // ----- GA4 event -----
              window.gtag('event', portfolioType ? 'survey' : 'portfolio_survey', {
                menu: text,
              })
              // ---------------------
              alert(t('submit_message'))
              setText('')
            }}
          >
            {t('button.submit')}
          </Button>

          <CenterAlignBox sx={{ gap: '0.8rem', animation: 'fadeIn 1s ' }}>
            {!noEditArea && <EditNukkiButton />}
            {!noSetting && <SetConfigButton />}
          </CenterAlignBox>
        </CenterAlignStack>
      )

    default:
      return (
        <CenterAlignBox
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: 'inherit',
            height: 'inherit',
          }}
        >
          <img src="/static/images/logo/logo_h_black.png" style={{ width: '16.2rem' }} />
        </CenterAlignBox>
      )
  }
}

export const NukkiEditTab = ({ artwork, setSodModified }) => {
  const [subTab, setSubTab] = useState(1)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const currentMenu = useRecoilValue(currentMenuAtom)

  useEffect(() => {
    // ----- GA4 event -----
    window.gtag('event', 'regenerate_bg_removal', {
      menu: currentMenu?.id,
      method: subTab === 1 ? 'piece' : 'brush',
    })
    // ---------------------
  }, [subTab])

  switch (true) {
    case subTab === 1:
      return (
        <SegmentEdit
          subTab={subTab}
          setSubTab={setSubTab}
          artwork={artwork}
          setSodModified={setSodModified}
          resetDialogOpen={resetDialogOpen}
          setResetDialogOpen={setResetDialogOpen}
        />
      )
    case subTab === 2:
      return (
        <Desktop>
          <SodCanvas
            artwork={artwork}
            setSodModified={setSodModified}
            subTab={subTab}
            setSubTab={setSubTab}
            resetDialogOpen={resetDialogOpen}
            setResetDialogOpen={setResetDialogOpen}
          />
        </Desktop>
      )

    default:
      break
  }
}

const SegmentEdit = ({ artwork, subTab, setSubTab, setSodModified }) => {
  const { t, i18n } = useTranslation()
  const [mode, setMode] = useRecoilState(nukkiSAMModeAtom)
  const isMobile = useMobileMediaQuery()
  const endpoint = useRecoilValue(endpointAtom)
  const theme = useTheme()
  const [originalMaskImage, setOriginalMaskImage] = useRecoilState(maskImageAtom)
  const [nukkiSAMOriSize, setNukkiSAMOriSize] = useRecoilState(nukkiSAMOriSizeAtom)
  const [resetDialogOpen, setResetDialogOpen] = useState(true)

  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)

  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)

  const [nukkiSAMSize, setnukkiSAMSize] = useRecoilState(nukkiSAMSizeAtom)
  const [removeMaskImg, SetRemoveMaskImg] = useRecoilState(removeMaskImgAtom)
  const [index, SetIndex] = useRecoilState(indexAtom)
  const resetSelectedMaskImg = useResetRecoilState(selectedMaskImgAtom)

  const mobileRoundStyle = {
    width: '10rem',
    height: '3.2rem',
    fontSize: '1.4rem',
    '& svg': {
      mr: '0.6rem',
    },
  }

  const handleReset = () => {
    setSodModified(false)
    setSegmentLoading(true)
    resetSelectedMaskImg()
    apis.appfront
      .restoreMask({ artworkId: artwork.id })
      .then(async response => {
        const maskB = response.data.mask.body
        const postProcessingImage = await imageTransparent(
          `${IMG_BASE64}${maskB.replaceAll('"', '')}`
        )
        setOriginalMaskImage(postProcessingImage)
      })
      .finally(() => {
        setSegmentLoading(false)
      })
  }
  const handleUndo = () => {
    if (index > -1) {
      const removedNumber = selectedMaskImg[index]
      SetRemoveMaskImg([removedNumber, ...removeMaskImg]) // 빠진 숫자 저장
      setSelectedMaskImg(selectedMaskImg.slice(0, index))
    }
  }

  const handleRedo = () => {
    if (removeMaskImg.length > 0) {
      const newNumbers = [...selectedMaskImg, removeMaskImg[0]]
      setSelectedMaskImg(newNumbers)
      SetRemoveMaskImg(removeMaskImg.slice(1)) // 빠진 숫자에서 가져온 것 제거
      SetIndex(index + 1)
    }
  }

  const loadImage = src => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = src
      img.onload = () => resolve(img)
      img.onerror = err => reject(err)
    })
  }

  const ImgMergeAndEdit = async (imgArray, mode) => {
    const { Image } = require('image-js')
    // 모든 이미지 로딩을 기다리기 위한 Promise 배열

    const imagePromises = imgArray.map(loadImage)
    try {
      // 이미지 배열을 비동기로 로딩
      const images = await Promise.all(imagePromises)
      // 이미지의 너비와 높이를 가져옴
      const width = nukkiSAMOriSize.w
      const height = nukkiSAMOriSize.h

      // Canvas 엘리먼트 생성
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height

      // Canvas 2D 컨텍스트를 얻음
      const ctx = canvas.getContext('2d')

      // 이미지를 Canvas에 그림
      images.forEach(image => {
        ctx.drawImage(image, 0, 0, width, height)
      })

      const selectData = ctx.getImageData(0, 0, width, height)
      const originalImg = new Image(width, height, selectData.data)

      const greyedImg = originalImg.grey()
      greyedImg.data = greyedImg.data.map(x => (x > 0 ? (mode === 'add' ? 1 : 2) : 0))

      // const maskToBlob = await mask.toBlob(blob => {
      //   const file = new File([blob], `crop.jpg`, { type: 'image/png' })
      //   return file
      // }, 'image/png')

      // const result = mask.toDataURL('image/png')

      // combinedImageSrc를 반환
      return greyedImg
    } catch (error) {
      console.error('이미지 로딩 중 오류 발생:', error)
      throw error // 오류를 다시 던지거나 처리할 수 있습니다.
    }
  }

  const edit = async () => {
    setSegmentLoading(true)
    // const ready = await prepareUpload([{}], config.PORTFOLIO_TYPE_MANNEQUIN)
    // if (!ready.success) return

    const addMaskArrImg = selectedMaskImg
      .filter(i => i.mode === 'add')
      .map(i => {
        return i.image
      })

    const removeMaskArrImg = selectedMaskImg
      .filter(i => i.mode === 'remove')
      .map(i => {
        return i.image
      })

    const addedImgData = await ImgMergeAndEdit(addMaskArrImg, 'add')
    const removedImgData = await ImgMergeAndEdit(removeMaskArrImg, 'remove')

    addedImgData.data = addedImgData.data.map((x, i) => {
      if (removedImgData.data[i] > 0) return removedImgData.data[i]
      return x
    })

    const url = addedImgData.toDataURL()
    // console.log([...new Set(penImgGrey.data.filter(v => v > 0))])
    // console.log(url)

    const additionalMask = url.replace(IMG_BASE64, '')

    apis.appfront
      .editMask({
        type: 'ai',
        artworkId: artwork.id,
        additionalMask,
        sodUrl: endpoint.sod_url,
        refine: 'refine',
      })
      .then(async response => {
        const imgB = response.data.img.body
        const maskB = response.data.mask.body
        const postProcessingImage = await imageTransparent(
          `${IMG_BASE64}${maskB.replaceAll('"', '')}`
        )
        setOriginalMaskImage(postProcessingImage)
        resetSelectedMaskImg()
        setSodModified(true)
        // setOriginalMaskImage(`${IMG_BASE64}${maskB.replaceAll('"', '')}`)
      })
      .finally(() => {
        setSegmentLoading(false)
      })
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: { lg: 'row', xs: 'column' },
        minHeight: '52rem',
      }}
    >
      <Desktop>
        <CenterAlignBox sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
          <SegmentArea artwork={artwork} />
        </CenterAlignBox>
      </Desktop>

      <CenterAlignStack
        sx={{
          flex: 1,
          p: { lg: '3.1rem 2.8rem 2.4rem 2.8rem', xs: '0rem 0 0.6rem 0' },
          gap: { lg: 0, xs: '2rem' },
          backgroundColor: '#F8F8F8',
        }}
      >
        <Desktop>
          <StyledToggleButtonGroup
            value={subTab}
            exclusive
            onChange={e => {
              setSubTab(Number(e.target.value))
            }}
            // onClick={() => {
            //   setResetDialogOpen(true)
            // }}
            sx={{ gap: '0 !important' }}
          >
            <StyledToggleButton
              sx={{
                boxShadow: 'none',
                width: { lg: isKo(i18n) ? '11.2rem' : '11.2rem', xs: '5rem ' },
                height: { lg: '4rem', xs: '4.4rem ' },
                fontSize: { lg: '1.6rem', xs: '1.6rem ' },
                fontWeight: 700,
                p: 0,
                border: '1px solid #D9E1EC',

                '&.Mui-selected': {
                  boxShadow: 'none',
                  border: 'none',
                },
              }}
              value={1}
            >
              {t('regenerate_dialog.segment')}
            </StyledToggleButton>
            <StyledToggleButton
              sx={{
                boxShadow: 'none',
                width: { lg: isKo(i18n) ? '11.2rem' : '11.2rem', xs: '5rem ' },
                height: { lg: '4rem', xs: '4.4rem ' },
                fontSize: { lg: '1.6rem', xs: '1.6rem ' },
                fontWeight: 700,
                p: 0,
                border: '1px solid #D9E1EC',

                '&.Mui-selected': {
                  boxShadow: 'none',
                  border: 'none',
                },
              }}
              value={2}
            >
              {t('regenerate_dialog.brush')}
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Desktop>

        <CenterAlignStack sx={{ m: '1.2rem 0 2.8rem' }}>
          <Korean>
            <Typography sx={{ fontSize: '1.2rem', lineHeight: 'normal' }}>
              <span style={{ fontWeight: 600 }}>빨간색 영역</span>은 제거,{' '}
              <span style={{ fontWeight: 600 }}>녹색 영역</span>은 추가입니다.
            </Typography>
          </Korean>
          <Japanese>
            <Typography sx={{ fontSize: '1.2rem', lineHeight: 'normal' }}>
              <span style={{ fontWeight: 600 }}>赤い領域</span>は削除、{' '}
              <span style={{ fontWeight: 600 }}>緑の領域</span>は追加です。
            </Typography>
          </Japanese>
        </CenterAlignStack>

        <Mobile>
          <CenterAlignBox
            sx={{ width: '36rem', overflow: 'auto', position: 'relative', minHeight: '36.9rem' }}
          >
            <SegmentArea artwork={artwork} />
          </CenterAlignBox>
        </Mobile>
        <Box sx={{ height: { lg: '9rem', xs: '9.5rem' }, position: 'relative' }}>
          <Divider
            orientation="vertical"
            id="dididi"
            sx={{
              height: { lg: '6rem', xs: '4.8rem' },
              borderColor: '#AFAFAF',
              mt: { lg: 0, xs: '0.7rem' },
              position: 'absolute',
              left: { lg: '8rem', xs: '8.5rem' },
            }}
            flexItem
          />
          <ToggleButtonGroup
            value={mode}
            exclusive
            onChange={(e, v) => {
              if (v !== null) setMode(v)
            }}
          >
            <ToggleButton
              value="remove"
              sx={{
                width: { lg: '6rem', xs: '6rem' },
                height: { lg: '6rem', xs: '6rem' },
                border: 0,
                borderRadius: '1rem !important',
                backgroundColor: '#C5C5C5',

                '&.MuiToggleButton-root.Mui-selected': {
                  backgroundColor: '#F12B5B',
                },
                mr: { lg: '4rem', xs: '5.6rem' },
                transition: 'all 0.1s linear',
              }}
            >
              <svg
                width="30"
                height="4"
                viewBox="0 0 30 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M27.8571 0C29.0406 0 30 0.89543 30 2C30 3.10457 29.0406 4 27.8571 4H2.14286C0.95939 4 0 3.10457 0 2C0 0.89543 0.95939 0 2.14286 0L27.8571 0Z"
                  fill="white"
                />
              </svg>

              <Typography
                sx={{ position: 'absolute', bottom: '-3rem' }}
                fontSize="1.6rem"
                fontWeight={700}
              >
                {t('regenerate_dialog.bg_edit_removed')}
              </Typography>
            </ToggleButton>

            <ToggleButton
              value="add"
              sx={{
                width: { lg: '6rem', xs: '6rem' },
                height: { lg: '6rem', xs: '6rem' },
                border: 0,
                borderRadius: '1rem !important',
                backgroundColor: '#C5C5C5',

                '&.MuiToggleButton-root.Mui-selected': {
                  backgroundColor: '#06A561',
                },
                transition: 'all 0.1s linear',
              }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 0C16.1835 0 17.1429 0.95939 17.1429 2.14286V12.855L27.8571 12.8571C29.0406 12.8571 30 13.8165 30 15C30 16.1835 29.0406 17.1429 27.8571 17.1429L17.1429 17.1407V27.8571C17.1429 29.0406 16.1835 30 15 30C13.8165 30 12.8571 29.0406 12.8571 27.8571V17.1407L2.14286 17.1429C0.95939 17.1429 0 16.1835 0 15C0 13.8165 0.95939 12.8571 2.14286 12.8571L12.8571 12.855V2.14286C12.8571 0.95939 13.8165 0 15 0Z"
                  fill="white"
                />
              </svg>

              <Typography
                sx={{ position: 'absolute', bottom: '-3rem' }}
                fontSize="1.6rem"
                fontWeight={700}
              >
                {t('regenerate_dialog.bg_edit_added')}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Stack
          direction="row"
          sx={{
            width: { lg: 'auto', xs: '30rem' },
            alignItems: 'center',
            justifyContent: 'center',
            mt: { lg: '2.4rem', xs: 0 },
          }}
          gap={{ lg: '0.8rem', xs: '1.4rem' }}
        >
          <Mobile>
            <Button
              variant="outlined"
              onClick={handleReset}
              sx={{
                ...squareButtonStyle,
                fontSize: '1.4rem',
                fontWeight: 700,
                width: '6.8rem',
                height: '3.2rem',
                p: 0,
                boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.10)',
              }}
              disabled={segmentLoading}
            >
              {t('regenerate_dialog.bg_edit_reset')}
            </Button>
          </Mobile>
          <Button
            variant="outlined"
            sx={{ ...roundButtonStyle, ...(isMobile && mobileRoundStyle) }}
            onClick={handleUndo}
            disabled={index === -1 || segmentLoading}
          >
            <svg
              width={isMobile ? '1.2rem' : '1.4rem'}
              height={isMobile ? '1.2rem' : '1.4rem'}
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 11L1 6L6 1"
                stroke="#303030"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 6H11.5C12.2223 6 12.9375 6.14226 13.6048 6.41866C14.272 6.69506 14.8784 7.10019 15.3891 7.61091C15.8998 8.12163 16.3049 8.72795 16.5813 9.39524C16.8577 10.0625 17 10.7777 17 11.5C17 12.2223 16.8577 12.9375 16.5813 13.6048C16.3049 14.272 15.8998 14.8784 15.3891 15.3891C14.8784 15.8998 14.272 16.3049 13.6048 16.5813C12.9375 16.8577 12.2223 17 11.5 17H8"
                stroke="#303030"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t('regenerate_dialog.bg_edit_undo')}
          </Button>
          <Button
            variant="outlined"
            sx={{ ...roundButtonStyle, ...(isMobile && mobileRoundStyle) }}
            onClick={handleRedo}
            disabled={removeMaskImg.length === 0 || segmentLoading}
          >
            <svg
              width={isMobile ? '1.2rem' : '1.4rem'}
              height={isMobile ? '1.2rem' : '1.4rem'}
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 11L17 6L12 1"
                stroke="#303030"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17 6H6.5C5.04131 6 3.64236 6.57946 2.61091 7.61091C1.57946 8.64236 1 10.0413 1 11.5C1 12.2223 1.14226 12.9375 1.41866 13.6048C1.69506 14.272 2.10019 14.8784 2.61091 15.3891C3.64236 16.4205 5.04131 17 6.5 17H10"
                stroke="#303030"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t('regenerate_dialog.bg_edit_redo')}
          </Button>
        </Stack>
        <Desktop>
          <Stack direction="row" gap="0.8rem" sx={{ flex: 1, alignItems: 'flex-end' }}>
            <Button
              variant="outlined"
              onClick={handleReset}
              sx={{
                width: '10.8rem',
                background: theme => theme.palette.common.white,
                ...squareButtonStyle,
              }}
              disabled={segmentLoading}
            >
              {t('regenerate_dialog.bg_edit_reset')}
            </Button>

            <Button
              variant="contained"
              onClick={edit}
              sx={{ width: '10.8rem', ...squareButtonStyle }}
              disabled={segmentLoading}
            >
              {t('regenerate_dialog.apply')}
            </Button>
          </Stack>
        </Desktop>
      </CenterAlignStack>
    </Box>
  )
}

const SelectSimple = () => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()
  return (
    <CenterAlignStack>
      <Typography sx={{ fontSize: { lg: '1.6rem', xs: '1.3rem' }, fontWeight: 700 }}>
        {t('upload_config.bg_theme')}
      </Typography>

      <Typography
        sx={{
          fontSize: { lg: '1.4rem', xs: '1.1rem' },
          lineHeight: 'normal',
          textAlign: 'center',
          mt: '0.4rem',
          mb: { lg: '1.6rem', xs: '1.1rem' },
        }}
      >
        현재 선택된 테마는 &apos;심플&apos;입니다.
        <br />
        원하시는 콘셉트가 있다면 새로 생성해주세요.
      </Typography>

      <Stack>
        <img
          style={{
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
            width: isMobile ? '14.4rem' : '26rem',
            height: isMobile ? '14.4rem' : '26rem',
          }}
          src="/static/images/api_info/feature_1_before.png"
        />
        <Box
          sx={{
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px',
            width: { lg: '26rem', xs: '14.4rem' },
            height: { lg: '3.6rem', xs: '2.8rem' },
            backgroundColor: theme.palette.draph.blue,
            color: theme.palette.common.white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 700,
            fontSize: { lg: '1.6rem', xs: '1.4rem' },
          }}
        >
          {t('upload_config.bg_simple')}
        </Box>
      </Stack>
    </CenterAlignStack>
  )
}
