import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Stack,
  styled,
} from '@mui/material'

import { QueryClient, useQuery } from '@tanstack/react-query'
import { apis } from 'apis'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { useTemplates, useTemplatesPagination } from 'hooks/useReactQuery'
import { useEffect, useRef, useState } from 'react'

import { Group, Layer, Rect, Stage, Text } from 'react-konva'
import { AlignBoxLeftIcon, OpenedEyeIcon, TextIcon } from 'theme/icon'
import { isDev } from 'utils/common'
import t2iTemplate from 'apis/data/t2iTemplate.json'
import { Masonry, TabContext, TabPanel } from '@mui/lab'
import { English, Japanese, Korean } from 'hooks/useLanguage'
import { useRecoilState, useRecoilValue } from 'recoil'
import { bannerTemplateParamsAtom } from 'atoms'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, EffectCoverflow } from 'swiper'

import { GradationButton } from 'components/CustomButtons'
import { motion, AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { fontSize } from 'pages/test/Toolbar'

const DEFAULT_IMAGE_URL = 'https://i.pinimg.com/564x/e2/55/4d/e2554dea5499f88c242178ce7ed568d9.jpg'
const MAX_SELECTIONS = 1

const SUBCATEGORIES = [
  {
    id: 'studio',
    label: (
      <>
        <Korean>상품 배경</Korean>
        <Japanese>商品背景</Japanese>
        <English>Studio</English>
      </>
    ),
    label2: (
      <>
        <Korean>상품 컨셉 스튜디오</Korean>
        <Japanese>商品コンセプトスタジオ</Japanese>
        <English>Studio</English>
      </>
    ),
    img: '/static/images/bannerV2/category/studio.png',
  },
  {
    id: 'model',
    label: (
      <>
        <Korean>단순 모델</Korean>
        <Japanese>単純モデル</Japanese>
        <English>Model</English>
      </>
    ),
    label2: (
      <>
        <Korean>단순 모델</Korean>
        <Japanese>単純モデル</Japanese>
        <English>Model</English>
      </>
    ),
    img: '/static/images/bannerV2/category/model.png',
  },
  {
    id: 'interaction',
    label: (
      <>
        <Korean>상호작용</Korean>
        <Japanese>インタラクション</Japanese>
        <English>Interaction</English>
      </>
    ),
    label2: (
      <>
        <Korean>상호작용 모델</Korean>
        <Japanese>インタラクションモデル</Japanese>
        <English>Interaction</English>
      </>
    ),
    img: '/static/images/bannerV2/category/interaction.png',
  },
]

const TAB_LISTS = [
  {
    id: 'studio',
    label: (
      <>
        <Korean>상품 배경</Korean>
        <Japanese>商品背景</Japanese>
        <English>Studio</English>
      </>
    ),
  },
  {
    id: 'model',
    label: (
      <>
        <Korean>단순 모델</Korean>
        <Japanese>単純モデル</Japanese>
        <English>Model</English>
      </>
    ),
  },
  {
    id: 'dev',
    label: (
      <>
        <Korean>Dev</Korean>
        <Japanese>Dev</Japanese>
        <English>Dev</English>
      </>
    ),
    devOnly: true,
  },
]

export default function BannerTemplatesList({ selectedIds, setSelectedIds }) {
  const [tab, setTab] = useState('0')
  const [indicatorStyle, setIndicatorStyle] = useState({ left: 0, width: 0 })
  const tabRefs = Array.from({ length: SUBCATEGORIES.length }, () => useRef())
  const [templateSearchParams, setTemplateSearchParams] = useRecoilState(bannerTemplateParamsAtom)

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  useEffect(() => {
    const currentTab = tabRefs[tab]?.current
    if (currentTab) {
      setIndicatorStyle({
        left: currentTab.offsetLeft,
        width: currentTab.offsetWidth,
      })
    }

    setSelectedIds([])

    setTemplateSearchParams(p => ({ ...p, subcategory: TAB_LISTS[tab].id }))
  }, [tab])

  return (
    <CenterAlignStack sx={{ width: '100%', p: { lg: '8.4rem 0 12rem 0' } }}>
      <Box
        sx={{
          width: '100%',

          color: 'white',
          '& .tab-panel': {
            p: 0,
          },
        }}
      >
        <TabContext value={tab}>
          <Box
            sx={{
              position: 'relative',

              borderBottom: 2,
              borderColor: '#808080',
              '& .tabs': {
                '& .tab': {
                  px: '3.5rem',
                  py: '2.4rem',
                  fontSize: { lg: '2.4rem' },
                  color: '#808080',
                  fontWeight: 500,
                  '&.Mui-selected': {
                    color: 'white',
                    fontWeight: 600,
                  },
                },
              },
            }}
          >
            <Tabs
              className="tabs"
              value={tab}
              onChange={handleChange}
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              {TAB_LISTS.filter(category => !category.devOnly || (category.devOnly && isDev)).map(
                (c, i) => (
                  <Tab
                    key={c.id}
                    className="tab"
                    label={c.label}
                    value={`${i}`}
                    ref={tabRefs[i]}
                    disableRipple
                  />
                )
              )}
            </Tabs>

            {/* 선택된 탭 하단 슬라이딩 인디케이터 */}
            <Box
              sx={{
                position: 'absolute',
                bottom: '-2px',
                height: '2px',
                backgroundColor: 'white',
                transition: 'left 0.3s, width 0.3s',
                left: `${indicatorStyle.left}px`,
                width: `${indicatorStyle.width}px`,
              }}
            />
          </Box>
        </TabContext>

        <Box sx={{ width: '100%', minHeight: { lg: '30rem' } }}>
          <TemplateGrid tab={tab} selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
        </Box>
      </Box>
    </CenterAlignStack>
  )
}

function TemplateGrid({ tab, selectedIds, setSelectedIds }) {
  const {
    templates,
    isLoading,
    isError,
    loadMore,

    // goToNextPage,
    // goToPreviousPage,
    // hasPreviousPage,
    hasNextPage,
    // isFirstPage,
  } = useTemplates()

  const handleSelect = id => {
    setSelectedIds(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(selectedId => selectedId !== id)
      } else {
        return [id]
      }
    })
  }

  const isMaxSelected = selectedIds?.length >= MAX_SELECTIONS

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <CircularProgress color="inherit" />
      </Box>
    )
  }

  if (isError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Typography color="error">
          데이터를 불러오는 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.
        </Typography>
      </Box>
    )
  }

  return (
    <CenterAlignStack sx={{}}>
      <Box
        sx={{
          width: '100%',
          '& .tab-panel': {
            p: 0,
          },
        }}
      >
        <TabContext value={tab}>
          {/* Tab Panel - 각 탭에 대한 내용 */}
          {SUBCATEGORIES.map((c, i) => (
            <TabPanel key={c.id} className="tab-panel" value={`${i}`}>
              <CenterAlignStack>
                <Box
                  sx={{
                    width: '100%',

                    // display: 'inline-grid', // 부모 박스의 너비를 자식에 맞춤
                    // gridTemplateColumns: 'repeat(5, 24rem)', // 3열 설정
                    // gap: '2rem', // 간격 설정
                    mt: '5.2rem',
                    '& .box': {
                      bgcolor: 'transparent',
                      borderRadius: '12px',
                      overflow: 'hidden',
                    },
                  }}
                >
                  {' '}
                  <Masonry
                    columns={5}
                    spacing={2}
                    sx={{
                      width: 'calc(120rem + 8rem)', // 5 columns * 24rem + gaps
                      margin: '0 auto',
                    }}
                  >
                    {templates.map((t, idx) => (
                      <Box key={idx} className="box" sx={{ position: 'relative', width: '24rem' }}>
                        <TemplateCard
                          template={t}
                          selectedIds={selectedIds}
                          isSelected={selectedIds?.includes(t.banner_id)}
                          onSelect={() => handleSelect(t.banner_id)}
                          isMaxSelected={isMaxSelected}
                          sx={{ width: '24rem', height: 'auto', borderRadius: '12px' }}
                        />
                        {isDev && (
                          <Box sx={{ position: 'absolute', top: '0.5rem' }}>
                            <TemplatePreviewDialog data={JSON.parse(t.template.data)} />
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Masonry>
                </Box>
                {hasNextPage && (
                  <Button
                    variant="outlined"
                    sx={{
                      mt: { lg: '5.2rem' },
                      width: { lg: '37.3rem' },
                      height: { lg: '6rem' },
                      fontSize: { lg: '2.4rem' },
                      fontWeight: 500,
                      color: 'white',
                      background: '#161616',
                      borderColor: 'white',
                      borderRadius: '3rem',
                      borderWidth: '2px',
                      '&:hover': {
                        borderWidth: '2px',
                        background: '#161616',
                        color: 'white',
                        borderColor: 'white',
                      },
                    }}
                    onClick={loadMore}
                    disabled={!hasNextPage}
                  >
                    <Korean>더보기</Korean>
                    <Japanese>さらに見る</Japanese>
                    <English>Load More</English>
                  </Button>
                )}
              </CenterAlignStack>
            </TabPanel>
          ))}
        </TabContext>
      </Box>

      {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button
          onClick={goToPreviousPage}
          variant="outlined"
          disabled={!hasPreviousPage}
          sx={{ mr: 1 }}
        >
          <BsChevronLeft className="mr-2 h-4 w-4" /> Previous
        </Button>
      </Box>
      <CenterAlignBox sx={{ p: 2, width: '78rem' }}>
        <Grid container spacing={2}>
          {isFirstPage && category === 'manual' && (
            <>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Box sx={{ backgroundColor: 'yellow' }}>ref 자리</Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <TemplateCard
                  template={t2iTemplate}
                  isSelected={selectedIds?.includes(t2iTemplate.banner_id)}
                  onSelect={() => handleSelect(t2iTemplate.banner_id)}
                  isMaxSelected={isMaxSelected}
                  size={size}
                />
              </Grid>
            </>
          )}
          {templates.map((t, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
              <Box sx={{ position: 'relative' }}>
                <TemplateCard
                  template={t}
                  isSelected={selectedIds?.includes(t.banner_id)}
                  onSelect={() => handleSelect(t.banner_id)}
                  isMaxSelected={isMaxSelected}
                  size={size}
                />
                {isDev && (
                  <Box sx={{ position: 'absolute', top: '0.5rem' }}>
                    <TemplatePreviewDialog data={JSON.parse(t.template.data)} />
                  </Box>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </CenterAlignBox>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button onClick={goToNextPage} variant="outlined" disabled={!hasNextPage} sx={{ ml: 1 }}>
          Next <BsChevronRight className="ml-2 h-4 w-4" />
        </Button>
      </Box> */}
    </CenterAlignStack>
  )
}

export function TemplateCard({ template, isSelected, onSelect, isMaxSelected, sx = {} }) {
  const templateData = JSON.parse(template.template.data)
  const templateId = template.banner_id
  const imageUrl = templateData.templateImage || DEFAULT_IMAGE_URL

  return (
    <Box
      sx={{
        width: sx.width,
        height: sx.height,
        position: 'relative',
        cursor: 'pointer',
        borderRadius: sx.borderRadius ?? '16px',
      }}
      onClick={() => {
        onSelect()
      }}
    >
      <Card
        sx={{
          width: '100%',
          height: '100%',
          // position: 'absolute',
          top: 0,
          left: 0,
          opacity: isMaxSelected && !isSelected ? 0.5 : 1,
          borderRadius: sx.borderRadius ?? '16px',
          bgcolor: 'transparent',
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            filter: isMaxSelected && !isSelected ? 'grayscale(100%)' : 'none',
            transition: 'filter 0.3s ease-in',
            borderRadius: sx.borderRadius ?? '16px',
          }}
          image={imageUrl}
          alt={templateData.name || 'Template image'}
        />
      </Card>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: 'inherit',
          height: 'inherit',
          border: `3px solid #1976d2`,
          borderRadius: sx.borderRadius ?? '16px',
          boxShadow: '0 0 10px rgba(25, 118, 210, 0.5)',
          pointerEvents: 'none',
          opacity: isSelected ? 1 : 0,
          transition: 'opacity 0.2s ease-in',
        }}
      />
    </Box>
  )
}

export const TemplatePreviewDialog = ({ data }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton
        onClick={() => {
          setOpen(true)
        }}
      >
        <OpenedEyeIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        sx={{
          overflow: 'auto',
          '& .MuiDialog-paper': {
            maxHeight: 'none',
            maxWidth: 'none',
          },
        }}
      >
        <TemplatePiece data={data} />
      </Dialog>
    </>
  )
}

function TemplatePiece({ data }) {
  const [shapes, setShapes] = useState([])
  const [whiteRect, setWhiteRect] = useState(null)

  const [adjustedSize, setAdjustedSize] = useState({ width: 0, height: 0 })
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 })

  const [scale, setScale] = useState(1)

  const stageRef = useRef(null)
  const shapeRef = useRef(null)
  const theme = useTheme()

  // useEffect(() => {
  //   console.log(JSON.parse(templateInfo.template.data), 'templateInfo')
  //   console.log(selectedTemplate?.banner_id)
  //   console.log(templateInfo?.banner_id)
  // }, [selectedTemplate])

  useEffect(() => {
    const { whiteRect: whiteRectData, shapes: shapesData, canvasSize: canvasSizeData } = data

    setWhiteRect(whiteRectData)
    setCanvasSize(canvasSizeData)

    const { width, height, scale } = calculateAdjustedSize(canvasSizeData)
    setAdjustedSize({ width, height })
    setScale(scale)

    const adjustedShapes = shapesData.map(shape => ({
      ...shape,
      x: shape.x * scale,
      y: shape.y * scale,
      width: shape.width * scale,
      height: shape.height * scale,
    }))
    setShapes(adjustedShapes)
  }, [data])

  // useEffect(() => {
  //   const stage = stageRef.current
  //   stage.scale({ x: 0.1, y: 0.1 })
  // }, [])

  const calculateAdjustedSize = size => {
    const maxSize = 500
    const aspectRatio = size.width / size.height
    let newWidth, newHeight, scale

    if (size.width > size.height) {
      newWidth = Math.min(size.width, maxSize)
      newHeight = newWidth / aspectRatio
      scale = newWidth / size.width
    } else {
      newHeight = Math.min(size.height, maxSize)
      newWidth = newHeight * aspectRatio
      scale = newHeight / size.height
    }

    return { width: Math.round(newWidth), height: Math.round(newHeight), scale }
  }

  const handleWheel = e => {
    if (e.evt.ctrlKey) {
      e.evt.preventDefault()
      const scaleBy = 1.1
      const stage = stageRef.current
      const oldScale = stage.scaleX()

      let newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy
      newScale = Math.max(0.1, Math.min(newScale, 1))

      stage.scale({ x: newScale, y: newScale })

      stage.position({
        x: (150 - newScale * 150) / 2,
        y: (150 - newScale * 150) / 2,
      })

      stage.batchDraw()
    }
  }

  return (
    <Stage
      width={adjustedSize.width}
      height={adjustedSize.height}
      ref={stageRef}
      // onWheel={handleWheel}
      style={{
        backgroundColor: 'rgba(128, 128, 128, 0.3)',
      }}
    >
      <Layer>
        {whiteRect && (
          <Rect
            x={whiteRect.x * scale}
            y={whiteRect.y * scale}
            width={whiteRect.width * scale}
            height={whiteRect.height * scale}
            fill="white"
            stroke="gray"
          />
        )}
        {shapes.map((shape, i) => (
          <Group ref={shapeRef} {...shape} key={i}>
            <Rect width={shape.width} height={shape.height} fill={shape.fill} />
            <Text
              text={shape.text}
              width={shape.width}
              height={shape.height}
              align="center"
              verticalAlign="middle"
              fontSize={shape.width * 0.16}
            />
          </Group>
        ))}
      </Layer>
    </Stage>
  )
}

// 스타일링을 위한 Styled Components
const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 10,
  backgroundColor: 'rgba(255,255,255,0.7)',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,1)',
  },
}))

const carouselStyle = {
  '& .card': {
    p: '3.4rem',
    transition: 'transform 0.3s, box-shadow 0.3s, background 0.3s',
    transform: 'scale(0.85)',
    borderRadius: '3rem',
    cursor: 'pointer',

    background: '#505050',
    '& .label': {
      color: 'white',
      fontSize: '2.8rem',
      fontWeight: '800',
    },
    display: 'block',
  },

  zIndex: '1 !important',

  '& .swiper-wrapper': {},

  '& .swiper-slide': {
    // border: '1px dashed pink', // 영역 확인용
    visibility: 'hidden',
  },
  '& .swiper-slide-prev, .swiper-slide-active, .swiper-slide-next': { visibility: 'visible' },
  '& .swiper-slide-prev,  .swiper-slide-next': {
    '& .select-button': {
      background: '#808080',
      pointerEvents: 'none',
      '&:hover': {
        filter: 'none',
      },
    },
  },

  '& .swiper-slide-active': {
    // width: 'fit-content !important',
    '& .card': {
      background: 'white',
      transform: 'scale(1)',
      opacity: 1,
      boxShadow: theme => theme.shadows[6],
    },
  },

  // ---

  '& .nav': {
    p: 0,
    background: 'transparent',
    '&.prev': { left: '-10rem' },
    '&.next': { right: '-10rem' },
  },
}

export function SelectMainCategory({ handleSelectCategory }) {
  const repeat = 3
  const swiperRef = useRef(null)
  const [templateSearchParams, setTemplateSearchParams] = useRecoilState(bannerTemplateParamsAtom)
  const initSelected = SUBCATEGORIES.findIndex(c => c.id === templateSearchParams?.subcategory)
  const [selectedItem, setSelectedItem] = useState(SUBCATEGORIES[initSelected])

  useEffect(() => {
    if (swiperRef.current) {
      const realIndex = swiperRef.current.realIndex
      setSelectedItem(SUBCATEGORIES[realIndex % SUBCATEGORIES.length])
    }
  }, [])

  useEffect(() => {
    setTemplateSearchParams(p => ({
      ...p,
      subcategory: selectedItem.id,
    }))
  }, [selectedItem])

  const handleSlideChange = () => {
    if (swiperRef.current) {
      const realIndex = swiperRef.current.realIndex
      setSelectedItem(SUBCATEGORIES[realIndex % SUBCATEGORIES.length])
    }
  }

  const handleSlideClick = index => {
    if (swiperRef.current) {
      if (index === 0 && swiperRef.current?.realIndex === SUBCATEGORIES.length * repeat - 1) {
        swiperRef.current?.slideNext()
      } else if (
        index === SUBCATEGORIES.length * repeat - 1 &&
        swiperRef.current?.realIndex === 0
      ) {
        swiperRef.current?.slidePrev()
      } else if (index > swiperRef.current?.realIndex) {
        swiperRef.current?.slideNext()
      } else if (index < swiperRef.current?.realIndex) {
        swiperRef.current?.slidePrev()
      }
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '95rem',

        mt: '8.8rem',
        mb: '15.6rem',

        ...carouselStyle,
      }}
    >
      <Swiper
        initialSlide={initSelected}
        onSwiper={swiper => {
          swiperRef.current = swiper

          setSelectedItem(SUBCATEGORIES[swiper.realIndex % SUBCATEGORIES.length])
        }}
        onSlideChange={handleSlideChange}
        modules={[Navigation, EffectCoverflow]}
        effect="coverflow"
        // spaceBetween={-200} // 슬라이드 겹치기
        slidesPerView={SUBCATEGORIES.length}
        loop={true}
        centeredSlides={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          // modifier: 2,
          slideShadows: false,
        }}
        speed={600}
      >
        {Array(repeat)
          .fill(SUBCATEGORIES)
          .flat()
          .map((c, i) => {
            const isInteraction = c.id === 'interaction'
            return (
              <SwiperSlide key={i} onClick={() => handleSlideClick(i)}>
                <Card className={`card`}>
                  <CenterAlignStack>
                    <Typography
                      variant="h5"
                      component="div"
                      className={`label ${selectedItem?.id === c.id ? 'gradient-text' : ''}`}
                      sx={{
                        ...(isInteraction && {
                          color: '#ababab !important',
                          textFillColor: 'unset !important',
                        }),
                      }}
                    >
                      {c.label2}
                    </Typography>

                    <Box
                      sx={{
                        mt: '2.4rem',
                        width: '33.2rem',
                        height: '33.2rem',
                        position: 'relative',
                        '& img': {
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          ...(isInteraction && { filter: 'brightness(0.6)' }),
                        },
                      }}
                    >
                      <img src={c.img} alt={c.label2} style={{}} />
                      {isInteraction && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '2.4rem',
                            fontWeight: 600,
                            color: '#fff',
                            width: 'max-content',
                          }}
                        >
                          <Korean>업데이트 예정입니다.</Korean>
                          <Japanese>アップデート予定です。</Japanese>
                          <English>Coming soon.</English>
                        </Box>
                      )}
                    </Box>

                    <GradationButton
                      className="select-button"
                      sx={{
                        width: '33.2rem',
                        fontSize: '2.4rem',
                        fontWeight: 700,
                        mt: '2.8rem',
                        ...(isInteraction && {
                          background: '#808080',
                          pointerEvents: 'none',
                          '&:hover': {
                            filter: 'none',
                          },
                        }),
                      }}
                      disabled={isInteraction}
                      onClick={e => {
                        handleSelectCategory(c)
                      }}
                    >
                      <Korean>선택</Korean>
                      <Japanese>選択</Japanese>
                      <English>Select</English>
                    </GradationButton>
                  </CenterAlignStack>
                </Card>
              </SwiperSlide>
            )
          })}
      </Swiper>

      {/* 이전 버튼 */}
      <NavigationButton
        className="nav prev"
        onClick={() => swiperRef.current?.slidePrev()}
        aria-label="이전 슬라이드"
      >
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="42" height="42" rx="21" stroke="white" strokeWidth="2" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5161 14.2796L18.3904 14.3905L11.7238 21.0572C11.2431 21.5378 11.2062 22.2941 11.6129 22.8172L11.7238 22.9428L18.3904 29.6095C18.9111 30.1302 19.7554 30.1302 20.2761 29.6095C20.7567 29.1288 20.7937 28.3725 20.387 27.8495L20.2761 27.7239L15.8853 23.332L29.9999 23.3333C30.7363 23.3333 31.3333 22.7364 31.3333 22C31.3333 21.2636 30.7363 20.6667 29.9999 20.6667L15.8879 20.6653L20.2761 16.2761C20.7567 15.7955 20.7937 15.0392 20.387 14.5161L20.2761 14.3905C19.7954 13.9099 19.0391 13.8729 18.5161 14.2796Z"
            fill="white"
          />
        </svg>
      </NavigationButton>

      {/* 다음 버튼 */}
      <NavigationButton
        className="nav next"
        onClick={() => swiperRef.current?.slideNext()}
        aria-label="다음 슬라이드"
      >
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="42" height="42" rx="21" stroke="white" strokeWidth="2" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.1505 14.2796C23.6274 13.8729 22.8711 13.9099 22.3904 14.3905L22.2795 14.5161C21.8728 15.0392 21.9098 15.7955 22.3904 16.2761L26.7802 20.6667H12.6666C11.9302 20.6667 11.3333 21.2636 11.3333 22C11.3333 22.7364 11.9302 23.3333 12.6666 23.3333H26.7802L22.3904 27.7239L22.2795 27.8495C21.8728 28.3725 21.9098 29.1288 22.3904 29.6095C22.9111 30.1302 23.7554 30.1302 24.2761 29.6095L30.9427 22.9428L31.0536 22.8172C31.4603 22.2941 31.4234 21.5378 30.9427 21.0572L24.2761 14.3905L24.1505 14.2796Z"
            fill="white"
          />
        </svg>
      </NavigationButton>
    </Box>
  )
}

const CarouselContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '115.2rem',
  minWidth: '115.2rem',
  height: '55.2rem',
  marginTop: '14rem',
}))

const StyledCard = styled(Card)(({ transform, opacity }) => ({
  position: 'absolute',
  height: '55.2rem',
  minWidth: '115.2rem',
  borderRadius: '30px 30px 0 0',
  transition: 'all 500ms ease-in-out',
  transform,
  opacity,
}))

const NavigationButton1 = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}))
// 직접제작 - 구도선택
export function SelectTemplate({ selectedIds, setSelectedIds }) {
  const [templateSearchParams, setTemplateSearchParams] = useRecoilState(bannerTemplateParamsAtom)

  useEffect(() => {
    setTemplateSearchParams(p => ({ ...p, initialPageSize: 12, subsequentPageSize: 12 }))
  }, [])

  const {
    templates,
    isLoading,
    isError,
    // loadMore,

    goToNextPage,
    goToPreviousPage,
    hasPreviousPage,
    hasNextPage,
    isFirstPage,
  } = useTemplatesPagination({ initialPageSize: 11, subsequentPageSize: 12 })

  const [activeIndex, setActiveIndex] = useState(0)

  const cards = [
    {
      component: TemplateGridManual,
      props: { selectedIds, setSelectedIds, templates, isFirstPage },
    },
    {
      component: TemplateGridManual,
      props: { selectedIds, setSelectedIds, templates, isFirstPage },
    },
    {
      component: TemplateGridManual,
      props: { selectedIds, setSelectedIds, templates, isFirstPage },
    },
  ]

  const handleNext = () => {}

  const handlePrev = () => {}

  const getCardStyle = index => {
    // 무한 루프를 위한 상대적 위치 계산
    const getRelativeIndex = idx => {
      const diff = idx - activeIndex
      if (diff < -1) return diff + cards.length
      if (diff > 1) return diff - cards.length
      return diff
    }

    const relativeDiff = getRelativeIndex(index)

    if (relativeDiff === -1) {
      // 이전 카드 (왼쪽에 살짝 보임)
      return {
        transform: 'translateX(0.3%) translateY(-14%) scale(0.90)',
        background: '#808080',
        zIndex: 1,
      }
    } else if (relativeDiff === 0) {
      // 현재 카드
      return {
        transform: 'translateX(0) scale(1)',
        opacity: 1,
        zIndex: 3,
      }
    } else if (relativeDiff === 1) {
      // 다음 카드 (오른쪽에 살짝 보임)
      return {
        transform: 'translateX(0.1%) translateY(-8%) scale(0.95)',
        background: '#BBBBBB',

        zIndex: 2,
      }
    } else {
      // 그 외 카드들
      return {
        transform: `translateX(${relativeDiff > 0 ? '10%' : '-10%'}) translateY(-16%) scale(0.9)`,
        opacity: 0.3,
        zIndex: 1,
      }
    }
  }

  return (
    <CarouselContainer sx={{}}>
      {cards.map((card, index) => (
        <StyledCard elevation={3} style={getCardStyle(index)} key={index}>
          <CardContent
            sx={{
              padding: '4rem 3rem',
              height: '100%',
            }}
          >
            {isLoading ? (
              <CenterAlignBox sx={{ width: '100%', height: '100%', alignItems: 'center' }}>
                <CircularProgress color="inherit" size={80} thickness={2} />
              </CenterAlignBox>
            ) : (
              <card.component {...card.props} />
            )}
          </CardContent>
        </StyledCard>
      ))}

      <Stack
        sx={{
          width: 'fit-content',
          position: 'absolute',
          right: '-7rem',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1000,
        }}
      >
        <IconButton
          onClick={() => {
            goToPreviousPage()
            setActiveIndex(prev => (prev - 1 + cards.length) % cards.length)
          }}
          disabled={!hasPreviousPage}
          size="large"
          sx={{ opacity: hasPreviousPage ? 1 : 0.5 }}
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="1" y="1" width="42" height="42" rx="21" stroke="white" strokeWidth="2" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.7204 19.8493L29.6095 19.7237L22.9428 13.057C22.4622 12.5764 21.7059 12.5394 21.1828 12.9461L21.0572 13.057L14.3905 19.7237C13.8698 20.2444 13.8698 21.0886 14.3905 21.6093C14.8712 22.09 15.6275 22.1269 16.1505 21.7202L16.2761 21.6093L20.668 17.2185L20.6667 31.3332C20.6667 32.0696 21.2636 32.6665 22 32.6665C22.7364 32.6665 23.3333 32.0696 23.3333 31.3332L23.3347 17.2212L27.7239 21.6093C28.2045 22.09 28.9608 22.1269 29.4839 21.7202L29.6095 21.6093C30.0901 21.1287 30.1271 20.3724 29.7204 19.8493Z"
              fill={'white'}
            />
          </svg>
        </IconButton>

        <IconButton
          onClick={() => {
            // loadMore()
            goToNextPage()
            setActiveIndex(prev => (prev + 1) % cards.length)
          }}
          variant="outlined"
          disabled={!hasNextPage}
          size="large"
          sx={{ opacity: hasNextPage ? 1 : 0.5 }}
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="1" y="1" width="42" height="42" rx="21" stroke="white" strokeWidth="2" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.7204 25.4842L29.6095 25.6098L22.9428 32.2765C22.4622 32.7571 21.7059 32.7941 21.1828 32.3874L21.0572 32.2765L14.3905 25.6098C13.8698 25.0891 13.8698 24.2449 14.3905 23.7242C14.8712 23.2435 15.6275 23.2066 16.1505 23.6133L16.2761 23.7242L20.668 28.115L20.6667 14.0003C20.6667 13.2639 21.2636 12.667 22 12.667C22.7364 12.667 23.3333 13.2639 23.3333 14.0003L23.3347 28.1123L27.7239 23.7242C28.2045 23.2435 28.9608 23.2066 29.4839 23.6133L29.6095 23.7242C30.0901 24.2048 30.1271 24.9611 29.7204 25.4842Z"
              fill={'white'}
            />
          </svg>
        </IconButton>
      </Stack>
    </CarouselContainer>
  )
}

export function DisplayOnlyCards() {
  // 모바일용 (피씨버전 이용 유도 안내)

  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % SUBCATEGORIES.length)
    }, 2500) //  카드 전환

    return () => clearInterval(interval)
  }, [])
  return (
    <>
      <Box sx={{ display: 'flex', position: 'relative', width: '100%', height: '100%' }}>
        {SUBCATEGORIES.map((c, i) => {
          const isInteraction = c.id === 'interaction'
          return (
            <CenterAlignStack
              key={i}
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                opacity: currentIndex === i ? 1 : 0,
                transition: 'opacity 1s ease-in-out',
              }}
            >
              <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
                <Stack
                  sx={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    pt: '4.8rem',
                    pb: '3.2rem',
                    pl: '2rem',
                    zIndex: 2,
                    display: 'inline-block',
                    background:
                      'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '2rem',
                      lineHeight: '2.4rem',
                      fontWeight: 600,
                      background: 'white',
                      color: theme => theme.palette.common.black,
                      borderRadius: '5px',

                      px: '1rem',
                      py: '0.8rem',
                      width: 'fit-content', // 추가
                    }}
                  >
                    {c.label2}
                  </Typography>
                  <Typography
                    sx={{
                      mt: '1.6rem',
                      fontSize: '2.4rem',
                      lineHeight: '3.4rem',
                      fontWeight: 600,
                      width: '32rem',

                      color: 'white',
                    }}
                  >
                    <Korean>PC 버전에서 새로워진 광고 소재 제작 기능을 체험해보세요.</Korean>
                    <Japanese>
                      PCバージョンで新しくなった広告素材制作機能を体験してみて下さい。
                    </Japanese>
                    <English>Experience new ads feature on the PC version.</English>
                  </Typography>
                </Stack>

                <Box
                  sx={{
                    zIndex: 1,
                    width: '100%',
                    height: '100%',

                    position: 'absolute',

                    '& img': {
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    },
                  }}
                >
                  {isInteraction && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: '#0000004D',
                        backdropFilter: 'blur(6px)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ color: 'white', fontSize: '3.2rem', fontWeight: 700 }}>
                        <Korean>업데이트 예정</Korean>
                        <Japanese>アップデート予定です。</Japanese>
                        <English>Coming soon</English>
                      </Typography>
                    </Box>
                  )}
                  <img
                    src={c.img.replace(/([^/]+)(\.[^/.]+)$/, '$1_mobile$2')}
                    alt={c.label2}
                    style={{}}
                  />
                </Box>
              </Box>
            </CenterAlignStack>
          )
        })}
      </Box>
    </>
  )
}

// // 직접제작 - 구도선택
// export function SelectTemplate1({ selectedIds, setSelectedIds }) {
//   const TOTAL_CARDS = 10
//   const cardData = Array.from({ length: TOTAL_CARDS }, (_, index) => ({
//     id: index + 1,
//     content: `카드 내용 ${index + 1}`,
//   }))

//   const [templateSearchParams, setTemplateSearchParams] = useRecoilState(bannerTemplateParamsAtom)
//   const [visibleCount, setVisibleCount] = useState(3)
//   const [direction, setDirection] = useState(null)
//   const [isAnimating, setIsAnimating] = useState(false)

//   useEffect(() => {
//     setTemplateSearchParams(p => ({ ...p, initialPageSize: 12, subsequentPageSize: 12 }))
//   }, [])

//   const { templates, isLoading, isError, loadMore, hasNextPage } = useTemplates()

//   const handleSelect = id => {
//     setSelectedIds(prevSelected => {
//       if (prevSelected.includes(id)) {
//         return prevSelected.filter(selectedId => selectedId !== id)
//       } else {
//         return [id]
//       }
//     })
//   }

//   const isMaxSelected = selectedIds?.length >= MAX_SELECTIONS

//   const handleNext = async () => {
//     if (isAnimating) return
//     setIsAnimating(true)
//     setDirection('next')
//     await loadMore()
//     setVisibleCount(prev => (prev < TOTAL_CARDS ? prev + 1 : prev))
//     setTimeout(() => {
//       setDirection(null)
//       setIsAnimating(false)
//     }, 500)
//   }

//   const handlePrev = () => {
//     if (isAnimating || visibleCount <= 1) return
//     setIsAnimating(true)
//     setDirection('prev')
//     setVisibleCount(prev => prev - 1)
//     setTimeout(() => {
//       setDirection(null)
//       setIsAnimating(false)
//     }, 500)
//   }

//   const getAnimationVariants = index => {
//     const baseVariants = {
//       initial: {
//         y: direction === 'next' ? 300 : -300,
//         opacity: 0,
//       },
//       animate: {
//         y: 0,
//         opacity: 1,
//         transition: {
//           type: 'spring',
//           stiffness: 70,
//           damping: 20,
//         },
//       },
//       exit: {
//         y: direction === 'next' ? -300 : 300,
//         opacity: 0,
//         transition: {
//           duration: 0.3,
//         },
//       },
//     }

//     return baseVariants
//   }

//   return (
//     <Box
//       sx={{
//         position: 'relative',
//         width: '100%',
//         display: 'flex',
//         justifyContent: 'center',
//         border: '2px solid gray',
//       }}
//     >
//       <Box
//         sx={{
//           width: '112rem',
//           height: '70rem',
//           position: 'relative',
//         }}
//       >
//         <Stack sx={{ width: 'fit-content', position: 'absolute', right: '-7rem', zIndex: 1000 }}>
//           <IconButton onClick={handleNext} disabled={isAnimating}>
//             <svg
//               width="44"
//               height="44"
//               viewBox="0 0 44 44"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <rect x="1" y="1" width="42" height="42" rx="21" stroke="white" strokeWidth="2" />
//               <path
//                 fillRule="evenodd"
//                 clipRule="evenodd"
//                 d="M29.7204 19.8493L29.6095 19.7237L22.9428 13.057C22.4622 12.5764 21.7059 12.5394 21.1828 12.9461L21.0572 13.057L14.3905 19.7237C13.8698 20.2444 13.8698 21.0886 14.3905 21.6093C14.8712 22.09 15.6275 22.1269 16.1505 21.7202L16.2761 21.6093L20.668 17.2185L20.6667 31.3332C20.6667 32.0696 21.2636 32.6665 22 32.6665C22.7364 32.6665 23.3333 32.0696 23.3333 31.3332L23.3347 17.2212L27.7239 21.6093C28.2045 22.09 28.9608 22.1269 29.4839 21.7202L29.6095 21.6093C30.0901 21.1287 30.1271 20.3724 29.7204 19.8493Z"
//                 fill={isAnimating ? 'gray' : 'white'}
//               />
//             </svg>
//           </IconButton>
//           <IconButton onClick={handlePrev} disabled={isAnimating || visibleCount <= 1}>
//             <svg
//               width="44"
//               height="44"
//               viewBox="0 0 44 44"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <rect x="1" y="1" width="42" height="42" rx="21" stroke="white" strokeWidth="2" />
//               <path
//                 fillRule="evenodd"
//                 clipRule="evenodd"
//                 d="M29.7204 25.4842L29.6095 25.6098L22.9428 32.2765C22.4622 32.7571 21.7059 32.7941 21.1828 32.3874L21.0572 32.2765L14.3905 25.6098C13.8698 25.0891 13.8698 24.2449 14.3905 23.7242C14.8712 23.2435 15.6275 23.2066 16.1505 23.6133L16.2761 23.7242L20.668 28.115L20.6667 14.0003C20.6667 13.2639 21.2636 12.667 22 12.667C22.7364 12.667 23.3333 13.2639 23.3333 14.0003L23.3347 28.1123L27.7239 23.7242C28.2045 23.2435 28.9608 23.2066 29.4839 23.6133L29.6095 23.7242C30.0901 24.2048 30.1271 24.9611 29.7204 25.4842Z"
//                 fill={isAnimating || visibleCount <= 1 ? 'gray' : 'white'}
//               />
//             </svg>
//           </IconButton>
//         </Stack>
//         <AnimatePresence mode="wait">
//           {cardData.slice(0, visibleCount).map((card, index) => (
//             <motion.div
//               key={`${card.id}-${index}`}
//               variants={getAnimationVariants(index)}
//               initial="initial"
//               animate="animate"
//               exit="exit"
//               style={{
//                 position: 'absolute',
//                 bottom: `${(visibleCount - index - 1) * 20}px`,
//                 scale: index < visibleCount ? `${Math.pow(0.985, visibleCount - index - 1)}` : 1,
//                 width: '100%',
//                 zIndex: visibleCount - index,
//               }}
//             >
//               <Card
//                 elevation={4}
//                 sx={{
//                   pt: '4rem',
//                   pb: '4.4rem',
//                   px: '3rem',
//                   minHeight: '45rem',
//                   width: '100%',
//                   borderRadius: '3rem',
//                   borderBottomLeftRadius: 0,
//                   borderBottomRightRadius: 0,
//                 }}
//               >
//                 <Box
//                   sx={{
//                     width: { lg: '100%' },
//                     display: 'inline-grid',
//                     gridTemplateColumns: { lg: 'repeat(6, 16rem)' },
//                     gap: { lg: '2rem' },
//                     '& .box': {
//                       position: 'relative',
//                       width: { lg: '16rem' },
//                       height: { lg: '22.4rem' },
//                       background: '#D9D9D9',
//                       borderRadius: { lg: '1.3rem' },
//                       overflow: 'hidden',
//                       '& img': {
//                         width: '100%',
//                         height: '100%',
//                         objectFit: 'cover',
//                       },
//                     },
//                   }}
//                 >
//                   {templates.map((t, idx) => (
//                     <Box className="box" key={idx}>
//                       <TemplateCard
//                         template={t}
//                         selectedIds={selectedIds}
//                         isSelected={selectedIds?.includes(t.banner_id)}
//                         onSelect={() => handleSelect(t.banner_id)}
//                         isMaxSelected={isMaxSelected}
//                         size={{ width: '16rem', height: '22.4rem' }}
//                       />
//                       {isDev && (
//                         <Box sx={{ position: 'absolute', top: '0rem' }}>
//                           <TemplatePreviewDialog data={JSON.parse(t.template.data)} />
//                         </Box>
//                       )}
//                     </Box>
//                   ))}
//                 </Box>

//                 <Box
//                   className="dim-overlay"
//                   sx={{
//                     width: '100%',
//                     height: '100%',
//                     position: 'absolute',
//                     top: 0,
//                     left: 0,
//                     background: `rgba(0, 0, 0, 0.7)`,
//                     transition: 'opacity 0.3s ease-in-out',
//                     opacity: 0.1 * (visibleCount - index - 1),
//                   }}
//                 ></Box>
//               </Card>
//             </motion.div>
//           ))}
//         </AnimatePresence>
//       </Box>
//     </Box>
//   )
// }

const TemplateGridManual = ({ selectedIds, setSelectedIds, templates, isFirstPage }) => {
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)
  // const {
  //   templates,
  //   isLoading,
  //   isError,
  //   goToNextPage,
  //   goToPreviousPage,
  //   hasPreviousPage,
  //   hasNextPage,
  //   isFirstPage,
  // } = useTemplatesPagination()

  const handleSelect = id => {
    setSelectedIds(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(selectedId => selectedId !== id)
      } else {
        return [id]
      }
    })
  }

  const isMaxSelected = selectedIds.length >= MAX_SELECTIONS
  return (
    <>
      <CenterAlignBox
        sx={{
          gap: '2rem',
          flexFlow: 'wrap',
          justifyContent: 'start',

          // (A) 전체 t2i 박스 스타일
          '& .t2i': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '16rem',
            height: '22.4rem',
            position: 'relative',
            cursor: 'pointer',
            borderRadius: '16px',
            backgroundColor: '#EEEEEE',
            overflow: 'hidden', // 자식(= before)이 넘칠 경우를 대비해서
            transition: 'background-color 0.3s ease', // 혹시 단색도 살짝 애니메이션 하고 싶다면
            // (1) ::before - 그라디언트 레이어
            '&::before': {
              content: '""',
              position: 'absolute',
              zIndex: 0,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              // 원하는 그라디언트
              background: 'linear-gradient(90deg, #2c4dff 0%, #8d00fc 100%)',
              opacity: 0,
              transition: 'opacity 0.3s ease', // ★ 여기서 부드러운 전환
            },

            // (2) 실제 컨텐츠가 그라디언트 레이어 위로 오도록 z-index 조정
            '& > *': {
              position: 'relative',
              zIndex: 1,
            },

            // (3) hover 시 before의 투명도를 높임 = 그라디언트가 서서히 나타남
            '&:hover::before': {
              opacity: 1,
            },
          },

          // (B) 아이콘 박스
          '& .icon-box': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            backgroundColor: '#161616',
            width: '4.4rem',
            height: '4.4rem',
            transition: 'background-color 0.3s ease',
          },
          '& .t2i:hover .icon-box': {
            backgroundColor: '#FFFFFF',
          },

          // (C) 아이콘(= svg)과 path의 컬러 제어
          '& .icon-box svg': {
            // 아이콘 전체에 걸리는 color
            // => path에 stroke="currentColor"로 연결됨
            color: 'white',
            transition: 'color 0.3s ease',
          },
          '& .t2i:hover .icon-box svg': {
            // 호버 시 아이콘 “color”를 우선 투명 처리 (stroke를 url(#...)로 바꿀 것이므로)
            color: 'transparent',
          },
          // (C-1) path를 직접 잡아서 stroke를 그라디언트로 바꿈
          '& .t2i:hover .icon-box svg path': {
            stroke: 'url(#textIconGradient)', // (중요) SvgIcon 안의 <defs>에서 정의한 gradient id
          },

          // (D) 타이포
          '& .typo-t2i': {
            fontSize: '1.6rem',
            fontWeight: 600,
            whiteSpace: 'pre-line',
            textAlign: 'center',
            marginTop: '1.6rem',
            transition: 'color 0.3s ease',
          },
          '& .t2i:hover .typo-t2i': {
            color: '#FFFFFF',
          },
        }}
      >
        {isFirstPage && (
          <Stack
            className={`t2i`}
            onClick={() => {
              handleSelect('t2i_template')
            }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <CenterAlignBox className="icon-box">
              <TextIcon strokeWidth={1.8} color="white" />
            </CenterAlignBox>

            <Typography className="typo-t2i">
              {' '}
              {isHover ? t('banner_v2.t2i_2') : t('banner_v2.t2i_1')}{' '}
            </Typography>
          </Stack>
        )}
        {templates.map((t, index) => (
          <Box sx={{ position: 'relative' }} key={index}>
            <TemplateCard
              template={t}
              sx={{ width: '16rem', height: '22.4rem' }}
              isSelected={selectedIds.includes(t.banner_id)}
              onSelect={() => handleSelect(t.banner_id)}
              isMaxSelected={isMaxSelected}
            />
            {isDev && (
              <Box sx={{ position: 'absolute', top: '0.5rem' }}>
                <TemplatePreviewDialog data={JSON.parse(t.template.data)} />
              </Box>
            )}
          </Box>
        ))}
      </CenterAlignBox>
    </>
  )
}
