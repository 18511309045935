import { Box } from '@mui/material'

const WIDTH = {
  small: 156,
  large: 205,
  auto: 'auto',
}

const HEIGHT = {
  small: 182,
  large: 238,
  auto: 'auto',
}

export const AssetsBox = ({ children, size, variant, selectMode, selected, ...props }) => {
  return (
    <Box
      sx={{
        width: WIDTH[size],
        height: HEIGHT[size],
        bgcolor: '#efefef',
        borderRadius: 1.25,
        p: '12px',
        position: 'relative',
      }}
      {...props}
    >
      {selectMode && (
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          {selected ? <SelectedIcon /> : <UnSelectedIcon />}
        </Box>
      )}
      {children}
    </Box>
  )
}



const UnSelectedIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 6233">
      <path
        id="Vector"
        d="M9 17C13.4184 17 17 13.4184 17 9C17 4.5816 13.4184 1 9 1C4.5816 1 1 4.5816 1 9C1 13.4184 4.5816 17 9 17Z"
        stroke="#808080"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M5.60742 9.06377L7.91142 11.3678L12.5194 6.75977"
        stroke="#808080"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

const SelectedIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 6230">
      <path
        id="Vector"
        d="M9 18C13.9707 18 18 13.9707 18 9C18 4.0293 13.9707 0 9 0C4.0293 0 0 4.0293 0 9C0 13.9707 4.0293 18 9 18Z"
        fill="#2C4DFF"
      />
      <path
        id="Vector_2"
        d="M5.18359 9.07174L7.77559 11.6637L12.9596 6.47974"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)  