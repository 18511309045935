import { useEffect, useState } from 'react'
import {
  ActionButton,
  Arrow,
  ButtonTabContent,
  ButtonTabLayout,
  ButtonTabs,
  CenterAlignStack,
  ConfirmDialog,
  ImageCarouselSwiper,
  ImageTextCard,
} from 'components'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { English, Japanese, Korean } from 'hooks/useLanguage'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import { apis } from 'apis'
import { shuffle } from 'utils/common'

export default function LandingCommonLayout({ children }) {
  const navigate = useNavigate()

  return (
    <CenterAlignStack sx={{ width: '100%' }}>
      <CenterAlignStack
        sx={{
          // border: '1px dashed red',
          pt: { lg: '5.6rem', xs: '4rem' },
        }}
      >
        {children}
      </CenterAlignStack>

      <CenterAlignStack
        sx={{ width: '100%', pt: { lg: '26rem', xs: '16rem' }, pb: { lg: '20rem', xs: '14rem' } }}
      >
        <Section1 />

        <CenterAlignStack sx={{ pt: { lg: '26rem', xs: '5rem' } }}></CenterAlignStack>

        <Section2 />

        <ActionButton
          sx={{
            mt: { lg: '10rem', xs: '4.8rem' },
            fontSize: { lg: '2.4rem', xs: '1.4rem' },
            fontWeight: 800,
            lineHeight: 1.4,
            width: { lg: '72rem', xs: '32rem' },
            py: { lg: '1.2rem', xs: '1rem' },

            '&:hover': {
              '& path': {
                fill: 'url(#paint0_linear_11435_2771)',
              },
            },
          }}
          onClick={() => {
            navigate('/join')
          }}
        >
          <Korean>첫 달 무료 혜택받고, 모든 기능을 사용해 보세요! </Korean>
          <Japanese>初月無料特典を受けて、すべての機能をお試しください！</Japanese>
          <English>Try all the features right now!</English>
          <Desktop>
            <Arrow className="arrow-icon" />
          </Desktop>

          <Mobile>
            <Box sx={{ ml: '0.6rem' }}>
              <svg
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.69029 0.174754C7.37645 -0.0794336 6.92266 -0.0563256 6.63427 0.244078L6.56772 0.322584C6.3237 0.649493 6.34589 1.12219 6.63427 1.42259L9.26809 4.16663H0.800007C0.358175 4.16663 0 4.53972 0 4.99996C0 5.4602 0.358175 5.83329 0.800007 5.83329H9.26816L6.63427 8.57741L6.56772 8.65592C6.3237 8.98283 6.34589 9.45552 6.63427 9.75592C6.9467 10.0814 7.45323 10.0814 7.76565 9.75592L11.7657 5.58926L11.8322 5.51075C12.0763 5.18384 12.0541 4.71115 11.7657 4.41074L7.76565 0.244078L7.69029 0.174754Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_11435_2771"
                    x1="0.25"
                    y1="7"
                    x2="17.75"
                    y2="7"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2C4DFF" />
                    <stop offset="1" stopColor="#8D00FC" />
                  </linearGradient>
                </defs>
              </svg>
            </Box>
          </Mobile>
        </ActionButton>
      </CenterAlignStack>
    </CenterAlignStack>
  )
}

function Section1() {
  const tabs = [
    {
      id: 'trendy',
      text: (
        <>
          <Korean>TRENDY</Korean>
          <Japanese>TRENDY</Japanese>
          <English>TRENDY</English>
        </>
      ),
      content: <ToolTrendy />,
    },
    // {
    //   id: 'ai_model',
    //   text: (
    //     <>
    //       <Korean>AI 모델</Korean>
    //       <English>AI Model</English>
    //     </>
    //   ),
    //   content: <ToolAIModel />,
    // },
    // {
    //   id: 'ai_ads',
    //   text: (
    //     <>
    //       <Korean>AI 광고</Korean>
    //       <English>AI Ads</English>
    //     </>
    //   ),
    //   content: <ToolAIAds />,
    // },
    // {
    //   id: 'ai_image_tools',
    //   text: (
    //     <>
    //       <Korean>AI 이미지 툴</Korean>
    //       <English>AI Image Tools</English>
    //     </>
    //   ),
    //   content: <ToolAIImageTools />,
    // },
  ]

  const [selectedTab, setSelectedTab] = useState(tabs[0])

  return (
    <>
      <CenterAlignStack sx={{}}>
        <Typography
          sx={{
            background: 'white',
            color: '#181818',
            lineHeight: 1.15,
            fontSize: { lg: '2.4rem', xs: '1.6rem' },
            fontWeight: 600,
            py: { lg: '1.2rem', xs: '0.8rem' },
            px: { lg: '2.7rem', xs: '1.3rem' },
          }}
        >
          <Korean>요즘 커머스 AI가 제공하는 수준? </Korean>
          <Japanese>最近のコマースAIが提供するレベルは? </Japanese>
          <English>The level of commerce AI these days?</English>
        </Typography>

        <Typography
          variant="h2"
          sx={{
            textAlign: 'center',
            color: 'white',
            fontSize: { lg: '3.6rem', xs: '2rem' },
            fontWeight: 600,
            lineHeight: 1.3,
            mt: { lg: '3.2rem', xs: '2rem' },
          }}
        >
          <Korean>
            브랜딩, 쇼핑몰, 이커머스에{' '}
            <Mobile>
              <br />
            </Mobile>
            바로 사용 가능한 <br />
            드랩의 AI 커머스 툴을 확인해 보세요.
          </Korean>
          <Japanese>
            ブランディング、ショッピングモール、Eコマースに{' '}
            <Mobile>
              <br />
            </Mobile>
            すぐに使用できる <br />
            ドラップのAIコマースツールをご確認ください。
          </Japanese>
          <English>
            Check out commerce tools{' '}
            <Mobile>
              <br />
            </Mobile>
            that are ready to use for <br />
            branding, online stores, and e-commerce
          </English>
        </Typography>

        <CenterAlignStack sx={{ pt: { lg: '6rem', xs: '3rem' } }}>
          <ButtonTabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </CenterAlignStack>
      </CenterAlignStack>
    </>
  )
}

function ToolTrendy() {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: { lg: '104rem', xs: '32rem' },
          '& .card': {
            mr: { lg: '4rem', xs: 0 },
            mb: { lg: '4rem', xs: '2.4rem' },
            '&:nth-of-type(3n)': {
              mr: 0,
            },
          },
        }}
      >
        <ImageTextCard
          img="/static/video/overview/square/removebg_ko.png"
          video="/static/video/overview/square/removebg_en.mp4"
          videoThumbTime={1.13}
          to="/overview/bg_removal"
          title={
            <>
              <Korean>AI 배경 제거</Korean>
              <Japanese>AI背景除去</Japanese>
              <English>AI Background Removal</English>
            </>
          }
          content={
            <>
              <Korean>
                수백장의 사진을 업로드하면 <br />
                일괄적으로 배경 제거를 실행합니다.
              </Korean>
              <Japanese>
                数百枚の写真をアップロードすれば、
                <br />
                一括して背景を除去します。
              </Japanese>
              <English>
                Upload hundreds of photos, <br />
                and the background will be removed in bulk.
              </English>
            </>
          }
        />
        <ImageTextCard
          img="/static/video/overview/square/bg_generation_ko.png"
          video="/static/video/overview/square/bg_generation_en.mp4"
          videoThumbTime={2.2}
          to="/overview/bg_generation"
          title={
            <>
              <Korean>AI 상품 배경 생성</Korean>
              <Japanese>AI商品背景生成</Japanese>
              <English>AI Background Creation</English>
            </>
          }
          content={
            <>
              <Korean>
                기본 상품이미지를 등록하고 원하는 컨셉을 <br />
                입력하기만 하면, <br />
                고품질의 상품 컨셉 썸네일이 바로 생성!
              </Korean>
              <Japanese>
                基本の商品画像を登録し、希望のコンセプトを
                <br />
                入力するだけで、
                <br />
                高画質の商品のコンセプトサムネイルが即座に生成されます！
              </Japanese>
              <English>
                Enter the background you want.
                <br />
                You can create the perfect thumbnail <br />
                just the way you want it.
              </English>
            </>
          }
        />
        <ImageTextCard
          img="/static/video/overview/square/banner_ko.png"
          video="/static/video/overview/square/banner_en.mp4"
          videoThumbTime={7}
          to="/overview/banner"
          title={
            <>
              <Korean>AI 광고 제작</Korean>
              <Japanese>AI広告作成</Japanese>
              <English>AI Ads Creation</English>
            </>
          }
          content={
            <>
              <Korean>
                브랜드와 상품을 입력하면, 카피라이트와 <br />
                이미지 광고 소재를 AI가 생성해줍니다.
              </Korean>
              <Japanese>
                ブランドと商品を入力すると、キャッチコピーと
                <br />
                画像広告素材をAIが生成します。
              </Japanese>
              <English>
                Enter your brand and product. <br />
                AI will generate copywriting and
                <br /> image ads for you.
              </English>
            </>
          }
        />

        <ImageTextCard
          img="/static/video/overview/square/modelbg_ko.png"
          video="/static/video/overview/square/modelbg_en.mp4"
          videoThumbTime={8}
          to="/overview/model_generation"
          title={
            <>
              <Korean>AI 모델 생성</Korean>
              <Japanese>AIモデル生成</Japanese>
              <English>AI Model Creation</English>
            </>
          }
          content={
            <>
              <Korean>
                상품과 가장 잘 어울리는 모델을 찾아, <br />
                브랜딩 및 커머스에 활용해 보세요.
              </Korean>
              <Japanese>
                商品に最適なモデルを見つけて、
                <br />
                ブランディングやコマースで活用してみてください。
              </Japanese>
              <English>
                Find the model that best fits your product, <br />
                and use them for branding and commerce.
              </English>
            </>
          }
        />
        <ImageTextCard
          img="/static/video/overview/square/mannequin_ko.png"
          video="/static/video/overview/square/mannequin_en.mp4"
          to="/overview/mannequin_to_model"
          title={
            <>
              <Korean>AI 마네킹 변경</Korean>
              <Japanese>AIマネキン変更</Japanese>
              <English>Mannequin to AI model </English>
            </>
          }
          content={
            <>
              <Korean>
                마네킹이 착장한 의상은 그대로 두고, <br />
                마네킹을 AI 모델로 변경합니다.
              </Korean>
              <Japanese>
                マネキンが着用した衣装はそのままに、
                <br />
                マネキンをAIモデルに変更します。
              </Japanese>
              <English>
                Keep the outfit on the mannequin, <br />
                but swap the mannequin with an AI model.
              </English>
            </>
          }
        />
        <ImageTextCard
          img="/static/video/overview/square/bg_expansion_ko.png"
          video="/static/video/overview/square/bg_expansion_en.mp4"
          videoThumbTime={1.5}
          to="/overview/bg_expansion"
          title={
            <>
              <Korean>AI 배경 확장</Korean>
              <Japanese>AI背景拡張</Japanese>
              <English>AI Background Extension</English>
            </>
          }
          content={
            <>
              <Korean>
                보이지 않는 부분을 AI가 자연스럽게 생성하여,
                <br />
                제품의 배경을 효과적으로 극대화합니다.
              </Korean>
              <Japanese>
                見えない部分をAIが自然に生成し、
                <br />
                商品の背景を効果的に拡張します。
              </Japanese>
              <English>
                AI will naturally generate any hidden parts, <br />
                effectively enhancing the product&apos;s background.
              </English>
            </>
          }
        />
        <ImageTextCard
          img="/static/video/overview/square/face_blur_ko.png"
          video="/static/video/overview/square/face_blur_en.mp4"
          to="/overview/face_generation"
          title={
            <>
              <Korean>AI 모자이크 변경</Korean>
              <Japanese>AIモザイク変更</Japanese>
              <English>Blurred out Face Recovery</English>
            </>
          }
          content={
            <>
              <Korean>
                모자이크 된 사진을 복원하여, <br />
                AI가 모델의 얼굴로 변경합니다.
              </Korean>
              <Japanese>
                モザイクされた写真を復元し、
                <br />
                AIがモデルの顔に変更します。
              </Japanese>
              <English>
                Restore pixelated photos and <br />
                let AI replace them with the model&apos;s face.
              </English>
            </>
          }
        />
        <ImageTextCard
          img="/static/video/overview/square/face_ko.png"
          video="/static/video/overview/square/face_en.mp4"
          to="/overview/face_change"
          title={
            <>
              <Korean>AI 얼굴 변경</Korean>
              <Japanese>AI顔変更</Japanese>
              <English>AI Face Creation </English>
            </>
          }
          content={
            <>
              <Korean>
                일반인의 얼굴을 자연스럽게 AI 모델로 변경하여, <br />그 누구든 모델로 데뷔할 수
                있습니다.
              </Korean>
              <Japanese>
                一般人の顔を自然にAIモデルに変更して、
                <br /> 誰でもモデルデビューが可能です。
              </Japanese>
              <English>
                Turn any face into an AI model naturally, <br />
                allowing anyone to debut as a model.
              </English>
            </>
          }
        />
        <ImageTextCard
          img="/static/video/overview/square/bundle_ko.png"
          video="/static/video/overview/square/bundle_en.mp4"
          videoThumbTime={3}
          to="/overview/bundle"
          title={
            <>
              <Korean>AI 묶음 상품</Korean>
              <Japanese>AIセット商品</Japanese>
              <English>AI Multipack Product Image</English>
            </>
          }
          content={
            <>
              <Korean>
                묶음으로 상품 판매할 때, 상품 이미지를 입력하면 <br />
                10개든 100개든 묶음 상품으로 만들어 드립니다.
              </Korean>
              <Japanese>
                セットで商品を販売する際、商品画像を入力すれば、
                <br />
                10個でも100個でもセット商品を作成します。
              </Japanese>
              <English>
                AI will create bundled images, <br /> whether it&apos;s 10 or 100 items.
              </English>
            </>
          }
        />
      </Box>
    </>
  )
}
function ToolAIModel() {
  return (
    <>
      <h1>AI MODEL</h1>
    </>
  )
}
function ToolAIAds() {
  return (
    <>
      <h1>AI ADS</h1>
    </>
  )
}
function ToolAIImageTools() {
  return (
    <>
      <h1>AI ImageTools</h1>
    </>
  )
}

function Section2() {
  const DEFAULT_IMAGES = [
    '/static/images/best_practice/1_after.webp',
    '/static/images/best_practice/2_after.webp',
    '/static/images/best_practice/3_after.webp',
    '/static/images/best_practice/4_after.webp',
    '/static/images/best_practice/5_after.webp',
    '/static/images/best_practice/6_after.webp',
    '/static/images/best_practice/8_after.webp',
    '/static/images/best_practice/9_after.webp',
    '/static/images/best_practice/10_after.webp',
    '/static/images/best_practice/11_after.webp',
  ]
  const shuffled = DEFAULT_IMAGES.sort(() => 0.5 - Math.random())
  const [images, setImages] = useState(shuffled)

  const tabs = [
    {
      id: 'new',
      text: (
        <>
          <Korean>NEW</Korean>
          <Japanese>NEW</Japanese>
          <English>NEW</English>
        </>
      ),
      content: <ImageCarouselSwiper images={images} />,
    },
  ]
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const MIN_IMAGE_COUNT = 10

  useEffect(() => {
    apis.common.getBestPracticeImages().then(response => {
      if (response.data) {
        let imgs = shuffle(response.data).map(item => item.path)

        if (imgs < MIN_IMAGE_COUNT) {
          imgs = [...imgs, shuffle(DEFAULT_IMAGES).slice(imgs.length - MIN_IMAGE_COUNT)]
        }
        setImages(imgs)
      }
    })
  }, [])

  return (
    <>
      <CenterAlignStack sx={{ width: '100%' }}>
        <Typography
          sx={{
            background: theme => theme.palette.draph.newblue,
            color: 'white',
            lineHeight: 1.15,
            fontSize: { lg: '2.4rem', xs: '1.6rem' },
            fontWeight: 600,
            py: { lg: '1.2rem', xs: '0.8rem' },
            px: { lg: '3.1rem', xs: '1.5rem' },
          }}
        >
          <Korean>그래서 다들 이거 쓰는 중!</Korean>
          <Japanese>だからみんなこれを使っている！</Japanese>
          <English>That&apos;s why everyone&apos;s using this!</English>
        </Typography>

        <Typography
          sx={{
            textAlign: 'center',
            lineHeight: 1.3,
            color: 'white',
            fontSize: { lg: '3.6rem', xs: '2rem' },
            fontWeight: 600,
            mt: { lg: '3.2rem', xs: '2rem' },
          }}
        >
          <Korean>
            요즘 잘 나가는 쇼핑몰들이
            <br />왜 드랩을 사용하는지 확인해 보세요.
          </Korean>
          <Japanese>
            最近流行のショッピングモールが
            <br /> なぜドラップを利用しているのかを確認してください。
          </Japanese>
          <English>
            Find out why the hottest online stores
            <Mobile>
              <br />
            </Mobile>
            are using Draph.
          </English>
        </Typography>

        <CenterAlignStack sx={{ width: '100%', pt: { lg: '6rem', xs: '3rem' } }}>
          <ButtonTabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            sx={{ width: '100%', '& .content': { width: '100%' } }}
          />
        </CenterAlignStack>
      </CenterAlignStack>
    </>
  )
}

const SLIDE_SAMPLE_IMAGES = {
  w_longer: [
    '/static/images/sample_thumbs/w/1.jpg',
    '/static/images/sample_thumbs/w/2.jpg',
    '/static/images/sample_thumbs/w/3.jpg',
    '/static/images/sample_thumbs/w/4.jpg',
    '/static/images/sample_thumbs/w/5.jpg',
    '/static/images/sample_thumbs/w/6.jpg',
    '/static/images/sample_thumbs/w/7.jpg',
    '/static/images/sample_thumbs/w/8.jpg',
    '/static/images/sample_thumbs/w/9.jpg',
    '/static/images/sample_thumbs/w/10.jpg',
  ],
  h_longer: [
    '/static/images/sample_thumbs/h/1.jpg',
    '/static/images/sample_thumbs/h/2.jpg',
    '/static/images/sample_thumbs/h/3.jpg',
    '/static/images/sample_thumbs/h/4.jpg',
    '/static/images/sample_thumbs/h/5.jpg',
    '/static/images/sample_thumbs/h/6.jpg',
    '/static/images/sample_thumbs/h/7.jpg',
    '/static/images/sample_thumbs/h/8.jpg',
    '/static/images/sample_thumbs/h/9.jpg',
    '/static/images/sample_thumbs/h/10.jpg',
    '/static/images/sample_thumbs/h/11.jpg',
  ],
  new: [
    '/static/images/sample_thumbs/1.png',
    '/static/images/sample_thumbs/2.png',
    '/static/images/sample_thumbs/3.jpg',
    '/static/images/sample_thumbs/4.jpg',
    '/static/images/sample_thumbs/5.jpg',
    '/static/images/sample_thumbs/6.jpg',
    '/static/images/sample_thumbs/7.jpg',
    '/static/images/sample_thumbs/8.jpg',
    '/static/images/sample_thumbs/9.jpg',
    '/static/images/sample_thumbs/10.jpg',
  ],
  cosmetics: [
    '/static/images/sample_thumbs/11.jpg',
    '/static/images/sample_thumbs/12.jpg',
    '/static/images/sample_thumbs/13.jpg',
    '/static/images/sample_thumbs/14.jpg',
    '/static/images/sample_thumbs/15.jpg',
    '/static/images/sample_thumbs/16.jpg',
    '/static/images/sample_thumbs/17.jpg',
    '/static/images/sample_thumbs/18.jpg',
    '/static/images/sample_thumbs/19.jpg',
    '/static/images/sample_thumbs/20.jpg',
  ],
  electronics: [
    '/static/images/sample_thumbs/21.jpg',
    '/static/images/sample_thumbs/22.jpg',
    '/static/images/sample_thumbs/23.jpg',
    '/static/images/sample_thumbs/24.jpg',
    '/static/images/sample_thumbs/25.jpg',
    '/static/images/sample_thumbs/26.jpg',
    '/static/images/sample_thumbs/27.jpg',
    '/static/images/sample_thumbs/28.jpg',
    '/static/images/sample_thumbs/29.jpg',
    '/static/images/sample_thumbs/30.jpg',
  ],
  car: [
    '/static/images/sample_thumbs/31.jpg',
    '/static/images/sample_thumbs/32.jpg',
    '/static/images/sample_thumbs/33.jpg',
    '/static/images/sample_thumbs/34.jpg',
    '/static/images/sample_thumbs/35.jpg',
    '/static/images/sample_thumbs/36.jpg',
    '/static/images/sample_thumbs/37.jpg',
    '/static/images/sample_thumbs/38.jpg',
    '/static/images/sample_thumbs/39.jpg',
    '/static/images/sample_thumbs/40.jpg',
  ],
}
