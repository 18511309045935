import { atom } from 'recoil'
import { appMenuDefault, appSubMenuDefault } from 'layouts/main/MainConfig'

import { PORTFOLIO_CONFIG_DEFAULT_BACK, PIECE_CONFIG_DEFAULT } from 'config'
export const languageAtom = atom({
  key: 'lang',
  default: 'ko',
})

export const confirmDialogAtom = atom({
  key: 'confirmDialogState',
  default: {
    open: false,
    alertOnly: false,
    isHide: false,
    title: '',
    content: '',
    onCofirmTitle: '',
    sx: {},
    onConfirm: () => {},
    onCancel: () => {},
  },
})

export const pleaseLoginDialogAtom = atom({
  key: 'pleaseLoginDialog',
  default: {
    open: false,
    type: null,
  },
})
export const welcomeDialogAtom = atom({
  key: 'welcomeDialog',
  default: {
    open: false,
  },
})
export const allowBrowserNotiDialogAtom = atom({
  key: 'allowBrowserNotiDialog',
  default: {
    open: false,
  },
})

export const ZeroCreditWarningDialogAtom = atom({
  key: 'ZeroCreditWarningDialog',
  default: {
    open: false,
  },
})

export const downloadWarningDialogAtom = atom({
  key: 'downloadWarningDialog',
  default: {
    open: false,
    download: () => {},
  },
})

export const bannerV2WarningDetectAtom = atom({
  key: 'bannerV2WarningDetect',
  default: {
    detect: false,
    cancelCallback: () => {},
  },
})

export const bannerV2WarningDialogAtom = atom({
  key: 'bannerV2WarningDialog',
  default: {
    open: false,
    confirmCallback: () => {},
    cancelCallback: () => {},
  },
})

export const userAtom = atom({
  key: 'user',
  default: {
    id: null,
    username: null,
    email: null,
    is_admin: false,
    grade: null,
    use_credit_on: null,
    category: null,
    website: null,
    company: null,
    phone: null,
    master_user_id: null,
  },
})

export const guestUserAtom = atom({
  key: 'guestUser',
  default: {
    id: null,
    username: null,
    use_credit_on: null,
  },
})

export const userCreditAtom = atom({
  key: 'userCredit',
  default: 0,
})
export const userSubscriptionIdAtom = atom({
  key: 'userSubscriptionId',
  default: null,
})

export const currentMenuAtom = atom({
  key: 'currentMenu',
  default: null,
})

export const appMenuAtom = atom({
  key: 'appMenu',
  default: appMenuDefault,
})

export const subMenuAtom = atom({
  key: 'subMenu',
  default: { open: false, main: '' },
})

export const portfolioAtom = atom({
  key: 'portfolio',
  default: null,
})

export const backgroundAtom = atom({
  key: 'background',
  default: null,
})
// 삭제? [refresh 구현 실패 시]
export const backgroundFetchInfoAtom = atom({
  key: 'backgroundFetchInfoAtom',
  default: {
    page: 1,
    hasNextPage: true,
    isLoading: false,
  },
})

export const portfolioDeleteModeAtom = atom({
  key: 'portfolioDeleteMode',
  default: {
    on: false,
    ids: [],
  },
})

export const viewModeAtom = atom({
  key: 'viewMode',
  default: { type: 'thumbnail', page: 1 },
})

export const dateFilterAtom = atom({
  key: 'dateFileter',

  default: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ],
})

export const dateFilterOnAtom = atom({
  key: 'dateFilterOn',
  default: false,
})

export const searchPortfolioAtom = atom({
  key: 'searchPortfolio',
  default: '',
})

export const newPortfolioAtom = atom({
  // 새 포트폴리오 생성시 step
  key: 'newPortfolio',
  default: 0,
})

export const PORTFOLIO_CONFIG_DEFAULT = {
  name: '',
  theme: '',
  theme_background: '',
  category: 'auto',
  subCategory: 'auto',
  outputWidth: null,
  outputHeight: null,
  bgWhite: true,
  bgSimple: false,
  bgComplex: true,
  bgGenerate: true,
  genShadow: true,
  genFace: false,
  objectBoundary: 'none',
  objectAngle: 'auto',
  modelGender: 'auto',
  flagGenCompo: false,
  flagBgExpansion: false,
  flagMultiblobSod: false,

  flagFacemorphing: false,
  facemorphingRace: 'asian',
  facemorphingGender: 'none',

  flagHumanBackground: false,
  SOLengthScale: 'auto',
  SO_size: 'auto',

  // [{outputWidth: xx, outputHeight: xx}]
  outputSizeList: [],

  simpleBgColorList: [
    [255, 255, 255],
    [242, 242, 244],
  ],

  saveOutputConfig: false,

  theme_template: 'auto',
  theme_custom: '',
  prompt_object: {},
  bg_expansion_bbox: [],
}

export const portfolioConfigAtom = atom({
  key: 'portfolioConfig',
  default: PORTFOLIO_CONFIG_DEFAULT,
})

export const portfolioDetailAtom = atom({
  key: 'portfolioDetail',
  default: {
    id: '',
    name: '',
    theme: '',
    theme_background: '',
    stat: {},
    user_id: '',
    config: null,
    is_default: false,
  },
})

export const portfolioArtworkAtom = atom({
  key: 'portfolioArtwork',
  default: null,
})

export const PROMPT_DEFAULT = {
  product: 'cosmetic',
  around: 'around_value_1',
  place: 'place_value_1',
  tone: 'tone_value_1',
}
export const portfolioCustomPromptAtom = atom({
  key: 'portfolioCustomPrompt',
  default: PROMPT_DEFAULT,
})

export const regenCustomPromptAtom = atom({
  key: 'regenCustomPrompt',
  default: PROMPT_DEFAULT,
})

export const artworkInViewAtom = atom({
  key: 'artworkInView',
  default: null,
})

export const artworkViewConfigAtom = atom({
  key: 'artworkViewConfig',
  default: {
    pageSize: 10,
    filters: { downloaded: 'all', name: null },
  },
})

export const artworkPageAtom = atom({
  key: 'artworkPage',
  default: 1,
})

export const bgConfigStepAtom = atom({
  key: 'bgConfigStep',
  default: 0,
})

export const bgConfigAtom = atom({
  key: 'bgConfig',
  default: {
    name: '',
    tag: '',
    category: '',
    sub_category: '',
    reflect: 0,
    cast_shadow: true,
    angle: '',
    light_src: '',

    pos_w: null,
    pos_h: null,
    h_standard: -1,
    portion_ratio: null,

    so_size: '',

    left_top_w: -1,
    left_top_h: -1,
    right_bottom_w: -1,
    right_bottom_h: -1,

    pos_left_w: null,
    pos_right_w: null,

    actual_len_v: '',
    actual_len_h: '',

    blur: [0],
  },
})

export const lightDirectionAtom = atom({
  key: 'lightDirection',
  default: 'horizontal',
})

export const artworkRegenCountAtom = atom({
  key: 'artworkRegenCount',
  default: { regenerate: 0, regenerate_auto: 0 },
})

export const selectedSampleAtom = atom({
  key: 'selectedSample',
  default: { path: '' },
})

export const loadingAtom = atom({
  key: 'loading',
  default: false,
})

export const loadingSubAtom = atom({
  key: 'loadingSub',
  default: false,
})

export const backgroundDialogAtom = atom({
  key: 'backgroundDialog',
  default: { open: false, idx: 0 },
})

// 서브카테고리 기본값 ? 다시 여쭤보기.
export const autoBgInfoAtom = atom({
  key: 'autoBgInfo',
  default: { name: '', tag: '', category: '', sub_category: 'auto' },
})

export const backgroundNameFilterAtom = atom({
  key: 'backgroundNameFilter',
  default: '',
})

export const backgroundFilterAtom = atom({
  key: 'backgroundFilter',
  default: {
    user: false,
    pinned: false,
  },
})

export const artworkDataAtom = atom({
  key: 'artworkData',
  default: null,
})

export const userLikedBgAtom = atom({
  key: 'userLikedBg',
  default: {
    liked: [],
    pinned: [],
  },
})

export const creditPolicyAtom = atom({
  key: 'creditPolicy',
  default: [
    { id: 1, type: 'artwork_create_simple_only', value: 0.5 },
    { id: 2, type: 'artwork_create', value: 1 },
    { id: 3, type: 'artwork_download', value: 1 },
    { id: 4, type: 'bg_generate', value: 1 },
    { id: 5, type: 'artwork_create_gen_compo', value: 1 },
    { id: 6, type: 'artwork_download_gen_compo', value: 1 },
    { id: 7, type: 'artwork_download_simple_only', value: 0.5 },
    { id: 8, type: 'artwork_create_remove_bg_only', value: 0 },
    { id: 9, type: 'artwork_create_multi_size', value: 1 },
    { id: 10, type: 'artwork_create_mannequin_model', value: 1 },
    { id: 11, type: 'artwork_download_mannequin_model', value: 1 },
  ],
})

export const subEventValidAtom = atom({ key: 'subEventValid', default: false })

export const tutorialAtom = atom({
  key: 'tutorial',
  default: {
    mode: false,
    step: 0,
    artworkId: null, // 튜토리얼을 통해 만든 아트워크 아이디
  },
})

export const canvasBrushCursorStyleAtom = atom({
  key: 'canvasBrushCursorStyle',
  default: {
    display: 'none',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    backgroundColor: 'skyblue',
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 9999,
  },
})

export const fakeArtworksListAtom = atom({
  key: 'fakeArtworksList',
  default: [
    {
      id: 'Input.jpg',
      name: '예시 이미지입니다.',
      uploaded: '/static/images/fakeArtworks/Input.jpg',
      status: 'done',
      pieces: [
        { path: '/static/images/fakeArtworks/output_1.png' },
        { path: '/static/images/fakeArtworks/output_2.jpg' },
        { path: '/static/images/fakeArtworks/output_3.jpg' },
        { path: '/static/images/fakeArtworks/output_4.jpg' },
      ],
    },
  ],
})

export const endpointAtom = atom({
  key: 'endpoint',
  default: {},
})

export const userCreditInfoAtom = atom({
  key: 'creditInfo',
  default: 0,
})

// 전체공개용 모든 플랜
export const creditPlansAtom = atom({
  key: 'plans',
  default: {
    subscription: [
      { id: 'monthly15', cycle: 'monthly', creditAmount: 15, price: { krw: 4900, usd: 4.99 } },
      { id: 'monthly35', cycle: 'monthly', creditAmount: 35, price: { krw: 9900, usd: 9.99 } },
      {
        id: 'monthly75',
        cycle: 'monthly',
        creditAmount: 75,
        price: { krw: 19900, usd: 19.99 },
        default: true,
      },
      { id: 'monthly200', cycle: 'monthly', creditAmount: 200, price: { krw: 49900, usd: 49.99 } },
      { id: 'monthly365', cycle: 'monthly', creditAmount: 365, price: { krw: 89900, usd: 89.99 } },
    ],
    payGo: [
      { id: 'paygo1', creditAmount: 1, price: { krw: 990, usd: 0.99 } },
      { id: 'paygo15', creditAmount: 15, price: { krw: 9900, usd: 9.99 } },
      { id: 'paygo35', creditAmount: 35, price: { krw: 19900, usd: 19.99 } },
      { id: 'paygo75', creditAmount: 75, price: { krw: 39900, usd: 39.99 }, default: true },
      { id: 'paygo200', creditAmount: 200, price: { krw: 99000, usd: 99.99 } },

      // { id: 'paygo500', creditAmount: 500, price: { krw: 358000 } },
      // { id: 'paygo1200', creditAmount: 1200, price: { krw: 798000 } },
      // { id: 'paygo4000', creditAmount: 4000, price: { krw: 2380000 } },
      // { id: 'paygo8000', creditAmount: 8000, price: { krw: 3980000 } },
    ],
  },
})

export const partnerPlansAtom = atom({
  key: 'parnerPlans',
  default: {},
})

export const touchHandleAtom = atom({
  key: 'touchHandle',
  default: false,
})

export const artworkListsIsFoldedAtom = atom({
  key: 'ArtworkListsIsFolded',
  default: { value: false, scrollY: 0 },
})

export const promotionAnchorAtom = atom({
  key: 'promotionAnchorAtom',
  default: null,
})

export const ArtworkListModPieceErrorAtom = atom({
  key: 'artworkListModPieceError',
  default: false,
})

export const searchUserPortfolioAtom = atom({
  key: 'searchUserPortfolio',
  default: '',
})

export const defaultPortfolioAtom = atom({
  key: 'userDefaultPortfolio',
  default: {},
})

export const backgroundLoadingAtom = atom({
  key: 'backgroundLoading',
  default: false,
})

// 누끼에서 특정 아트워크를 이미지 생성으로 옮겨서 아트워크 생성
export const autoUploadAtom = atom({
  key: 'autoUpload',
  default: { auto: false, file: null, theme_template: 'auto' },
})

// 드래그 앤 드롭으로 업로드 창으로 연결
export const dragAutoUploadAtom = atom({
  key: 'dragAutoUpload',
  default: { auto: false, file: null },
})

export const portfolioTypeAtom = atom({
  key: 'portfolioType',
  default: null,
  // config.js  PORTFOLIO_TYPE_XXXXX 중 하나
  // null 이면 일반 유저 포트폴리오, 값이 있으면 누끼용, 미분류용 포폴
})

export const toggleToStgAtom = atom({
  key: 'stg',
  default: localStorage.getItem('draph-stg') === 'true',
})

export const toggleToPrevAtom = atom({
  key: 'prev',
  default: localStorage.getItem('draph-prev') === 'true',
})

// Fragments

export const clicksAtom = atom({
  key: 'clicks',
  default: null,
})

export const imageAtom = atom({
  key: 'image',
  default: null,
})

export const maskImgAtom = atom({
  key: 'maskImg',
  default: null,
})

export const selectedMaskImgAtom = atom({
  key: 'selectedMaskImg',
  default: [],
})

export const maskImgArrayAtom = atom({
  key: 'maskImgArray',
  default: null,
})

export const segmentLoadingAtom = atom({
  key: 'segmentLoading',
  default: false,
})

export const segmentStepAtom = atom({
  key: 'segmentStep',
  default: 0,
})

export const mannequinLoadingAtom = atom({
  key: 'mannequinLoading',
  default: false,
})

export const retryMannequinAtom = atom({
  key: 'retryMannequin',
  default: { isRetry: false, artworkId: null, config: null },
})

// 재생성 쪽에서 탭 변화에 따른 상태 유지를 위해 atom 으로 만듦
export const uploadImgHeightAtom = atom({
  key: 'uploadImgHeight',
  default: 0,
})

export const uploadImgWidthAtom = atom({
  key: 'uploadImgWidth',
  default: 0,
})

//

export const serverStatusAtom = atom({
  key: 'serverStatus',
  default: {
    on: true,
    notice: '',
  },
})

export const appStatusAtom = atom({
  key: 'appStatus',
  default: [],
})

export const noticeBannersAtom = atom({
  key: 'noticeBanner',
  default: [
    // {
    //   id: 1,
    //   content: {ko: '점검 공지', en: '..'},
    //   bgColor: '#FFE6A7',
    //   textColor: '#303030',
    // },
  ],
})

// ------------ 업로드 다이얼로그 ------------

export const dragDropOpenAtom = atom({
  key: 'dragDropOpen',
  default: false,
})

export const uploadDialogOpenAtom = atom({
  key: 'uploadDialogOpen',
  default: false,
})

export const configDialogOpenAtom = atom({
  key: 'configDialogOpen',
  default: false,
})

export const uploadFilesAtom = atom({
  key: 'uploadFiles',
  default: [],
})

export const uploadFilesAndUrlAtom = atom({
  key: 'uploadFilesAndUrl',
  default: [],
})

export const regenerateUploadFilesAndUrlAtom = atom({
  key: 'regenerateUploadFilesAndUrl',
  default: [],
})

export const isAnchorAtom = atom({
  key: 'hasAnchor',
  default: false,
})

export const blockDragDropAtom = atom({
  key: 'blockDragDrop',
  default: false,
})

export const uploadDialogOpen2Atom = atom({
  key: 'uploadDialogOpen2',
  default: false,
})

// ------------ 업로드 다이얼로그 ------------

export const modelBgRegenDialogAtom = atom({
  key: 'modelBgRegenDialogOpen',
  default: {
    open: false,
    artwork: null,
    setArtwork: () => {},
    refreshArtwork: () => {},
    refreshRegenCount: () => {},
  },
})

export const modelFaceRegenDialogAtom = atom({
  key: 'modelFaceRegenDialogOpen',
  default: {
    open: false,
    artwork: null,
    setArtwork: () => {},
    refreshArtwork: () => {},
    refreshRegenCount: () => {},
  },
})

export const modelRenerateDialogAtom = atom({
  key: 'modelRenerateDialog',
  default: {
    open: false,
    artwork: null,
    setArtwork: () => {},
    refreshArtwork: () => {},
    refreshRegenCount: () => {},
    pieceImageSize: {},
  },
})

export const blogPostsAtom = atom({
  key: 'blogPosts',
  default: [],
})

export const blogTabAtom = atom({
  key: 'blogTab',
  default: 'total',
})

// 조각 누끼 수정용 Atoms

export const nukkiSAMOriSizeAtom = atom({
  key: 'nukkiSAMOriSize',
  default: { w: 0, h: 0 },
})

export const nukkiSAMSizeAtom = atom({
  key: 'NukkiSAMSize',
  default: { w: 0, h: 0 },
})

export const nukkiSAMModeAtom = atom({
  key: 'nukkiSAMMode',
  default: 'add',
})

export const maskImageAtom = atom({
  key: 'maskImage',
  default: '',
})

export const removeMaskImgAtom = atom({
  key: 'removeMaskImg',
  default: [],
})

export const indexAtom = atom({
  key: 'index',
  default: -1,
})

// Zip DragAndDrop

export const isBulkUploadAtom = atom({
  key: 'isBulkUpload',
  default: false,
})

// Banner atom
export const unusualPortfolioConfigUpdateAtom = atom({
  key: 'unusualPortfolioConfigUpdate',
  default: {},
})

export const brandReadOnlyConfigAtom = atom({
  key: 'brandReadOnlyConfig',
  default: {},
})

export const brandConfigUpdateAtom = atom({
  key: 'brandConfigUpdate',
  default: {},
})

export const bannerTextFontAtom = atom({
  key: 'bannerTextFont',
  default: { font: 'Pretendard Regular', fontColor: '#FFFFFF', fontSize: 40, scaleX: 1 },
})

export const bannerSizeRatioAtom = atom({
  key: 'bannerSizeRatio',
  default: 1,
})

export const hasProductAtom = atom({
  key: 'hasProduct',
  default: true,
})

export const isnoSOHumanDepictAtom = atom({
  key: 'isnoSOHumanDepict',
  default: false,
})

export const pieceConfigAtom = atom({
  key: 'pieceConfig',
  default: null,
})

// -------------------------------

export const userSubInfoAtom = atom({
  key: 'userSubInfo',
  default: null,
})

export const linkSnsAccountDialogAtom = atom({
  key: 'linkSnsAccountDialog',
  default: { open: false },
})

export const portfolioLastArtwork = atom({
  key: 'portfolioLastArtwork',
  default: null,
})

export const isErrorMessageAtom = atom({
  key: 'isErrorMessage',
  default: '',
})

export const customTemplateAtom = atom({
  key: 'customTemplate',
  default: '',
})

export const keepShowingConfigAtom = atom({
  key: 'keepShowingConfig',
  default: false,
})

export const bannerMannInfoAtom = atom({
  key: 'bannerMannInfo',
  default: {
    mann2man_image: '',
    binary_mask: '',
    mann2man_is_male: '',
    mann2man_age: '',
    human_depict: '',
    gen_type: 'mann2man',
    bg_Depict: '',
  },
})

export const bannerMajorPageAtom = atom({
  key: 'bannerMajorPage',
  default: 0,
})

export const portfolioRegenOptionAtom = atom({
  key: 'portfolioRegenOption',
  default: PORTFOLIO_CONFIG_DEFAULT,
})

export const isSimpleAtom = atom({
  key: 'isSimple',
  default: 'nothing',
})

export const bulkUploadFileAtom = atom({
  key: 'bulkUploadFile',
  default: [],
})

export const f1Atom = atom({
  key: 'f1',
  default: [],
})

export const f2Atom = atom({
  key: 'f2',
  default: [],
})

export const f3Atom = atom({
  key: 'f3',
  default: [],
})

export const imageDialogDiaplayModeAtom = atom({
  key: 'imageDialogDiaplayMode',
  default: 'compare',
})

export const uploadLoadingAtom = atom({
  key: 'uploadLoading',
  default: false,
})

export const historyAtom = atom({
  key: 'history',
  default: {
    past: [],
    future: [],
  },
})
export const guestPortfolioAtom = atom({
  key: 'guestPortfolio',
  default: {},
})
export const apiRequestsState = atom({
  key: 'apiRequestsState',
  default: {},
})

export const refImageAtom = atom({
  key: 'refImage',
  default: [],
})

export const defaultConfigAtom = atom({
  key: 'defaultConfig',
  default: {
    product_name: '',
    product_feature: '',
    brand_name: '',
    brand_feature: '',
    // filter_grain_flag: false,
    // filter_grain_intensity: 0,
    // filter_sharpen_flag: false,
    // filter_whitebalance_flag: false,
    // filter_whitebalance_temperature: 0,
  },
})

// 선택된 객체를 화살표를 움직일 때 오류가 발생하여 인풋에 들어올 때는 그 기작을 못하게 하기 위한
export const blockArrowKeydownAtom = atom({
  key: 'blockArrowKeydown',
  default: false,
})

export const autoScalingModeAtom = atom({
  key: 'autoScalingMode',
  default: true,
})

export const bannerPagesAtom = atom({
  key: 'bannerPages',
  default: 0,
})

export const chipsAtom = atom({
  key: 'chipsState',
  default: [],
})

export const bannerTemplateParamsAtom = atom({
  key: 'bannerTemplateParams',
  default: {
    category: 'auto',
    subcategory: 'studio',
    initialPageSize: 10,
    subsequentPageSize: 10,
    enabled: false,
  },
})

// BannerV2 total filter
export const bannerWholeFilterAtom = atom({
  key: 'bannerWholeFilter',
  default: {
    brightness: 0,
    contrast: 0,
    saturation: 0,
    noise: 0,
    blurRadius: 0,
    enhance: 0,
    red: 0,
    green: 0,
    blue: 0,
  },
})

export const bannerEachFilterAtom = atom({
  key: 'bannerEachFilter',
  default: {
    brightness: 0,
    contrast: 0,
    saturation: 0,
    noise: 0,
    blurRadius: 0,
    enhance: 0,
    red: 0,
    green: 0,
    blue: 0,
  },
})

export const bannerSelectedLoadingAtom = atom({
  key: 'bannerSelectedLoading',
  default: false,
})

export const bannerSelectedFontListsAtom = atom({
  key: 'bannerSelectedFont',
  default: [],
})

export const updateFontListsAtom = atom({
  key: 'updateFontLists',
  default: [],
})
