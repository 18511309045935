import { configData } from 'config'
import {
  Box,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  SwipeableDrawer,
  Collapse,
} from '@mui/material'
import {
  ScrollToTop,
  ArtworkList,
  PortfolioConfigDialog,
  DevEndpointDialog,
  SearchInput,
  UploadImageDialog,
  CustomTooltip,
  NaverProductButton,
  NaverProductButtonV2,
  NaverProductListDialog,
  NaverProductListDialogV2,
  DevOptionDialog,
  ArtworkFilters,
  UploadWrapper,
  triggerGA4UploadEvent,
  StyledToggleButtonGroup,
  StyledToggleButton,
  BlueSwitch,
  DragDropFullSize,
  CenterAlignStack,
  DialogButton,
  CenterAlignBox,
  PortfolioConfigTabs,
  ArtworkListTemp,
} from 'components'
import { BackButton, DropdownArrowIcon, SelectSideIconThin, SettingIcon } from 'theme/icon'
import { styled, useTheme } from '@mui/material/styles'

import { useEffect, useState, useRef, useMemo, Fragment, useLayoutEffect } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useResetRecoilState, useRecoilValue } from 'recoil'
import {
  portfolioDetailAtom,
  portfolioArtworkAtom,
  userAtom,
  portfolioConfigAtom,
  artworkViewConfigAtom,
  userLikedBgAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  tutorialAtom,
  endpointAtom,
  artworkListsIsFoldedAtom,
  toggleToStgAtom,
  uploadDialogOpenAtom,
  dragDropOpenAtom,
  blockDragDropAtom,
  defaultPortfolioAtom,
  isErrorMessageAtom,
} from 'atoms'
import { apis } from 'apis'
import { BarLoader as Loader } from 'react-spinners'
import {
  creditPolicyDictSelector,
  devStgSelector,
  isPortfolioOwnerSelector,
  portfolioUploadConfigSelector,
  userPortfolioNamesSelector,
} from 'selector'
import useConfirm from 'hooks/useConfirm'
import _, { debounce } from 'lodash'
import axios from 'axios'
import { getAccessToken } from 'utils/cookie'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Tutorial } from 'theme/Tutorial'
import { APPBAR_DESKTOP, APPBAR_MOBILE } from 'layouts/main/MainAppBar'
import { endpointCookieName } from 'components/portfolio/DevEndpointDialog'
import * as config from 'config'
import { AnimatePresence, useScroll, motion } from 'framer-motion'

import { RIGHT_CLICK_ALLOW_USER } from 'App'
import { iOS, isDev } from 'utils/common'
import { useTranslation } from 'react-i18next'
import { NHNProductContainedButton, NHNProductListDialog } from './NHNCommerce'
import { ConfigAccordionGroup } from './PortfolioUpload'
import { TabContent } from 'components/portfolio/PortfolioConfigDialog'
import moment from 'moment'
import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import { Cafe24ProductButton, Cafe24ProductListDialog } from './Cafe24'
import { usePortfolio } from 'hooks/usePortfolio'

export const PAGE_HEADER_HEIGHT = '8rem'
export const PAGE_HEADER_HEIGHT_MOBILE = '16.3rem'

const RootStyle = styled(Box)(({ theme }) => ({
  // flexGrow: 1,

  // display: 'flex',
  // flexFlow: 'column',

  // 전체에서 scroll 잡혀서 이를 삭제함
  // overflow: 'auto',

  [theme.breakpoints.down('lg')]: {
    // alignItems: 'center',
  },
}))

const PageHeaderStyle = styled(Box)(({ theme }) => ({
  top: 0,
  minHeight: PAGE_HEADER_HEIGHT_MOBILE,

  paddingTop: '3.5rem',
  justifyContent: 'center',
  alignItems: 'end',

  // borderBottom: '0.2rem solid',
  bordercolor: theme.palette.common.black,
  background: theme.palette.common.white,
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingTop: '5.4rem',
    minHeight: PAGE_HEADER_HEIGHT,

    paddingBottom: '3.8rem',
    display: 'flex',
    flexDirection: 'row',
  },
}))

const uploadImglimit = 20

export default function PortfolioDetail() {
  const scrollRef = useRef(null)
  const tutorial = useRecoilValue(tutorialAtom)
  const theme = useTheme()
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)

  const [isExpanded, setIsExpanded] = useState(true)
  const [openConfig, setOpenConfig] = useState(false)
  const isMobile = useMobileMediaQuery()

  const [value, setValue] = useState(0)
  const [error, setError] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)

    // TODO 가운데로 보내고싶은데 죽어도 안됨
    const scrollParent = document.querySelector(`#mobile-tabs .MuiTabs-scroller`)
    const target = document.querySelector(`#mobile-tab-${newValue}`)

    if (scrollParent) {
      let to = 0
      to = 100

      //   setTimeout(() => {
      //     scrollParent.scrollTo({
      //       left: to,
      //       behavior: 'smooth',
      //     })
      //   }, 2000)
    }
  }

  useEffect(() => {
    return () => {
      setBlockDragDrop(false)
    }
  }, [])

  const navigate = useNavigate()
  const { portfolioId } = useParams()

  return (
    <>
      {/* <Wrapper> */}
      <RootStyle
        style={{
          // overflow: tutorial.step === 15 ? 'hidden' : 'scroll',
          zIndex: tutorial.step === 17 ? '-1' : null,
        }}
        onDragEnter={e => {
          e.preventDefault()
          e.stopPropagation()

          if (!blockDragDrop) {
            setDragDropOpen(true)
          }
        }}
      >
        <CenterAlignStack>
          <UploadWrapper portfolioId={portfolioId}>
            <PageHeader openConfig={openConfig} setOpenConfig={setOpenConfig} />
          </UploadWrapper>

          {openConfig && (
            <CenterAlignStack sx={{ width: { lg: '118.4rem', xs: '32rem' } }}>
              <PortfolioConfigTabs value={value} onChange={handleChange} />
              <Collapse
                in={isExpanded}
                sx={{
                  width: '100%',
                  '& .MuiCollapse-wrapperInner': { display: 'flex', justifyContent: 'center' },
                }}
              >
                <TabContent value={value} setError={setError} />
              </Collapse>
              <Button
                sx={{
                  width: { lg: '118.4rem', xs: '32rem' },
                  height: { lg: '5.2rem', xs: '3.2rem' },
                  backgroundColor: '#eeeeee',
                  borderTopLeftRadius: '0rem',
                  borderTopRightRadius: '0rem',
                  borderBottomLeftRadius: '1rem',
                  borderBottomRightRadius: '1rem',
                  mb: { lg: '4rem', xs: '3.2rem' },
                  transition: 'all 1s',
                  '& svg': {
                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(360deg)',
                    transition: 'all 0.4s',
                  },
                }}
                onClick={() => {
                  setIsExpanded(i => !i)
                }}
              >
                <DropdownArrowIcon
                  color={theme.palette.common.black}
                  style={isMobile ? {} : { width: '3.2rem', height: '1.6rem' }}
                />
              </Button>
            </CenterAlignStack>
          )}

          <ArtworkList />
        </CenterAlignStack>
      </RootStyle>
      <ScrollToTop />
      <ScrollHandler />
      {dragDropOpen && !blockDragDrop && <DragDropFullSize />}

      {/* </Wrapper> */}
    </>
  )
}

export function ScrollHandler({ targetRef }) {
  const { scrollY } = useScroll({ container: targetRef, layoutEffect: false })
  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)
  const tutorial = useRecoilValue(tutorialAtom)
  const resetIsFolded = useResetRecoilState(artworkListsIsFoldedAtom)

  const handleScroll = _.debounce(() => {
    const currentScrollY = scrollY.get()
    const threshold = 140

    if (tutorial.mode) return

    if (!isFolded.value) {
      setIsFolded(v => ({ ...v, scrollY: currentScrollY }))
    }

    if (currentScrollY > threshold && !isFolded.value) {
      setIsFolded(v => ({ ...v, value: true }))
    }

    if (currentScrollY < threshold - 20) {
      setIsFolded(v => ({ ...v, value: false }))
    }
  }, 100) // 200ms 지연 시간 설정

  useEffect(() => {
    // 스크롤 이벤트 리스너 등록
    const unsubscribe = scrollY.onChange(() => {
      handleScroll()
    })

    // 컴포넌트 언마운트 시 이벤트 리스너 해제 및 debounce 초기화
    return () => {
      unsubscribe()
      handleScroll.cancel() // debounce 취소 함수 호출
      resetIsFolded()
    }
  }, [])

  return <></>
}

export function PageHeader({ openConfig, setOpenConfig }) {
  const { portfolioId } = useParams()
  const { t, i18n } = useTranslation()
  const { prepareUpload, makeUploadFormData, checkUserCredit, refreshArtworks } = usePortfolio()
  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)
  const [user, setUser] = useRecoilState(userAtom)
  const [viewConfig, setViewConfig] = useRecoilState(artworkViewConfigAtom)

  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)

  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const [uploading, setUploading] = useState(false)

  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  // const [openConfig, setOpenConfig] = useState(false)
  const [openEndpointDialog, setOpenEndpointDialog] = useState(false)
  const [openOptionDialog, setOpenOptionDialog] = useState(false)
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')

  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)
  const [userLikedBg, setUserLikedBg] = useRecoilState(userLikedBgAtom)

  const [errorMessage, setErrorMessage] = useRecoilState(isErrorMessageAtom)
  const isSimpleConfig =
    !portfolioConfig.bgComplex && !portfolioConfig.bgGenerate ? 'simple' : 'concept'

  const resetPortfolioDetail = useResetRecoilState(portfolioDetailAtom)
  const resetViewConfig = useResetRecoilState(artworkViewConfigAtom)
  const resetPortfolioConfig = useResetRecoilState(portfolioConfigAtom)
  const resetUploadDialogOpen = useResetRecoilState(uploadDialogOpenAtom)

  const [openCreateSuccessDialog, setOpenCreateSuccessDialog] = useState(false)

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  const { showConfirm } = useConfirm()
  const isMobile = useMobileMediaQuery()
  const navigate = useNavigate()

  const { state } = useLocation()

  useEffect(() => {
    if (state) {
      const { openCreateSuccessDialog } = state
      if (openCreateSuccessDialog) {
        setOpenCreateSuccessDialog(true)
      } else {
        setOpenCreateSuccessDialog(false)
      }
    } else {
      setOpenCreateSuccessDialog(false)
    }

    return () => {}
  }, [])

  const isNCommerceUser = user.login_sns === 'naver_commerce'
  const isNCommerceV2User = user.login_sns === 'naver_commerce_v2'
  const isNHNCommerceUser = user.login_sns === 'nhn_commerce'
  const isCafe24CommerceUser = user.login_sns === 'cafe24_commerce'
  const isCommerceUser =
    isNCommerceUser || isNCommerceV2User || isNHNCommerceUser || isCafe24CommerceUser
  const [nProductDialogOpen, setNProductDialogOpen] = useState(false)
  const [nProductDialogV2Open, setNProductDialogV2Open] = useState(false)
  const [stg, setStg] = useRecoilState(toggleToStgAtom)
  const [nhnProductDialogOpen, setNhnProductDialogOpen] = useState(false)
  const [cafe24ProductDialogOpen, setCafe24ProductDialogOpen] = useState(false)

  // const [isSimple, setIsSimple] = useState('nothing') // 기본 선택일 경우 옮겨지는 느낌이 생김

  // useEffect(() => {
  //   if (isSimple === 'nothing') return

  //   if (isSimple) {
  //     // setShowCustomTheme(false)
  //     setPortfolioConfig({
  //       ...portfolioConfig,
  //       bgSimple: true,
  //       bgComplex: false,
  //       bgGenerate: false,
  //       theme_template: 'auto',
  //     })
  //   } else {
  //     const updatedConfig = {
  //       ...portfolioConfig,
  //       bgSimple: false,
  //       bgComplex: !portfolioConfig.flagMultiblobSod,
  //       bgGenerate: true,
  //     }

  //     if (portfolioConfig.outputSizeList.length > 1) {
  //       let configOutputlist = [...portfolioConfig.outputSizeList]

  //       configOutputlist = configOutputlist.filter(o => o.w !== null && o.h !== null).slice(0, 1)

  //       setPortfolioConfig({
  //         ...updatedConfig,
  //         outputSizeList: configOutputlist,
  //       })
  //     } else {
  //       setPortfolioConfig(updatedConfig)
  //     }
  //   }
  // }, [isSimple])

  // useEffect(() => {
  //   const isSimpleConfig = !portfolioConfig.bgComplex && !portfolioConfig.bgGenerate

  //   setIsSimple(isSimpleConfig)
  // }, [portfolioConfig.bgComplex, portfolioConfig.bgGenerate])

  useEffect(() => {
    // 포트폴리오 페이지 진입시 로컬스토리지에서 엔드포인트 정보 가져와 atom에 세팅

    const s = localStorage.getItem('draph-stg')
    setStg(s === 'true')

    // ## router 의 <SetPortfolio> 에서 호출하도록 수정함
    // apis.portfolio
    //   .getPortfolio(portfolioId)
    //   .then(response => {
    //     const d = response.data

    //     setPortfolioDetail({
    //       id: portfolioId,
    //       name: d.name,
    //       theme: d.theme,
    //       stat: d.stat,
    //       user_id: d.user_id,
    //       config: { ...PORTFOLIO_CONFIG_DEFAULT, name: d.name, ...d.config },
    //       is_default: d.is_default,
    //       // 가입시 생성되는 최초의 포트폴리오 대응
    //     })
    //   })
    //   .catch(error => {
    //     console.log(error.response.status)
    //   })

    apis.background.getLikedBackgrounds().then(response => {
      setUserLikedBg(response.data)
    })

    return () => {
      resetPortfolioDetail()
      resetViewConfig()
      resetPortfolioConfig()
      resetUploadDialogOpen()
    }
  }, [])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, downloaded: downloadedFilter },
    })
  }, [downloadedFilter])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, name: textFilter },
    })
  }, [textFilter])

  // useEffect(() => {
  //   console.log(uploadConfig)
  //   console.log(portfolioDetail)
  // }, [uploadConfig])

  const handleUpload = async (files, eventName = 'portfolio_upload') => {
    const uploadFiles = await prepareUpload(files)

    if (!uploadFiles) {
      setUploading(false)
      return
    }
    setUploadButtonDiasbled(true)

    // ----- GA4 event -----
    triggerGA4UploadEvent(uploadConfig, user, uploadFiles.length, eventName)
    // ---------------------

    const formData = await makeUploadFormData(uploadFiles)
    setUploadOpen(false)

    console.log('--------- start uploading')
    setUploading(true)

    apis.appfront
      .upload(formData)
      .then(() => {
        checkUserCredit()
        if (portfolioId !== window.location.pathname.split('/')[2]) return
        setUploading(false)
        setUploadButtonDiasbled(false)
        refreshArtworks()
        tutorial.mode && setTutorial(prev => ({ ...prev, step: '14_5' }))

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }
      })
      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  const userPortfolioNames = useRecoilValue(userPortfolioNamesSelector)

  const checkedDuplicatedName = () => {
    if (
      !portfolioConfig.name ||
      (portfolioConfig.name !== portfolioDetail.name &&
        userPortfolioNames?.includes(portfolioConfig.name))
    ) {
      return portfolioDetail.name
    }
    return portfolioConfig.name
  }

  const updateAndUploadDialogOpen = () => {
    setUploadOpen(true)
    const newName = checkedDuplicatedName()

    setErrorMessage('')

    const editedConfigForbody = { ...portfolioConfig }
    let configOutputlist = [...editedConfigForbody.outputSizeList] ?? []

    if (!portfolioConfig.outputWidth || !portfolioConfig.outputHeight) {
      editedConfigForbody.outputHeight = null
      editedConfigForbody.outputWidth = null
    }

    if (configOutputlist.length !== 0) {
      configOutputlist = configOutputlist.filter(o => o.w !== null && o.h !== null)
    }

    if (configOutputlist.length > 1) {
      editedConfigForbody.simpleBgColorList = [[255, 255, 255]]
    } else {
      editedConfigForbody.simpleBgColorList = PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList
    }

    editedConfigForbody.outputSizeList = configOutputlist
    editedConfigForbody.name = newName

    const body = {
      new_name: newName,
      theme: portfolioConfig.theme,
      config: { ...editedConfigForbody },
    }

    // const is = areObjectsEqual(editedConfigForbody, portfolioDetail.config)

    apis.portfolio.updatePortfolio(portfolioDetail.id, body).then(response => {
      setPortfolioDetail({ ...portfolioDetail, ...response.data })
    })
    // console.log(editedConfigForbody, 'editedConfigForbody')
    // console.log(portfolioDetail.config, 'portfolioDetail', is)
    // ----- GA4 event -----
    // window.gtag('event', 'portfolio_setting_modify', {
    //   config_object_category: portfolioConfig.category,
    //   config_object_sub_category: portfolioConfig.subCategory,
    //   output_size_width: portfolioConfig.outputWidth,
    //   output_size_height: portfolioConfig.outputHeight,
    //   image_type: isSimpleConfig,
    //   config_gen_shadow: portfolioConfig.genShadow.toString(),
    //   config_gen_face: portfolioConfig.genFace.toString(),

    //   theme:
    //     isSimpleConfig === 'simple'
    //       ? null
    //       : portfolioConfig.theme_custom || portfolioConfig.theme_template,
    //   removebg: portfolioConfig.bgWhite,
    //   multi_blob: portfolioConfig.flagMultiblobSod,
    // })
    // ---------------------
  }

  return (
    <PageHeaderStyle
      sx={{
        // padding: isFolded ? '0 !important' : '5.4rem 0 3.8rem 0 ',
        // minHeight: isFolded ? 'auto !important' : '15.1rem',
        // height: isFolded ? '0rem !important' : 'auto',

        opacity: isFolded.value ? 0 : 1,
        transition: 'all 0.2s ease',
      }}
      zIndex={
        isFolded.value
          ? -1
          : tutorial.step === 14 || tutorial.step === '14_4' || tutorial.step === 0
          ? 'auto'
          : null
      }
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: { lg: 'flex-start', md: 'center', sm: 'center', xs: 'center' },
          alignItems: 'center',
          width: { lg: '35rem', xs: '100%' },
          px: 1,
        }}
      >
        <>
          <Box
            sx={{
              display: 'flex',
              width: { lg: '28rem', xs: '25.5rem' },
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              alignItems: 'center',
              minHeight: '3.8rem',
            }}
          >
            <IconButton
              onClick={() => {
                navigate('/portfolio')
              }}
              sx={{
                mr: { lg: '3rem', xs: '2.2rem' },
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              <Desktop>
                <BackButton />
              </Desktop>
              <Mobile>
                <BackButton style={{ width: '1.5rem', height: '2.5rem' }} />
              </Mobile>
            </IconButton>

            <CustomTooltip title={portfolioDetail.name} placement="top">
              <Typography
                fontSize={{ lg: '2.5rem', xs: '2rem' }}
                fontWeight={700}
                noWrap
                sx={{
                  width: { lg: 'min-content', xs: '13rem' },
                  color: portfolioDetail.is_default ? 'gray' : '',
                }}
              >
                {portfolioDetail.is_default
                  ? t(`configOrDict.DEFAULT_PORTFOLIO_NAME_DICT.upload`)
                  : portfolioDetail.name}
              </Typography>
            </CustomTooltip>

            {portfolioDetail.id && !portfolioDetail.is_default && (
              <IconButton
                onClick={() => {
                  setOpenConfig(true)
                  // ----- GA4 event -----
                  window.gtag('event', 'portfolio_setting', {})
                  // ---------------------
                }}
                disabled={!isOwner}
                sx={{ ml: '0.2rem' }}
              >
                <Desktop>
                  <SettingIcon size="medium" />
                </Desktop>
                <Mobile>
                  <SettingIcon size="small" />
                </Mobile>
              </IconButton>
            )}
          </Box>
        </>
      </Box>
      <Box
        sx={{
          width: { lg: '50rem', xs: '100%' },
          mt: { lg: 0, xs: '1.5rem' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <>
          <Tutorial step="14_4">
            <Tutorial step={14}>
              <Button
                variant="contained"
                component="span"
                sx={{
                  width: isCommerceUser
                    ? { lg: '22rem', xs: '14rem' }
                    : { lg: '26rem', xs: '18rem' },
                  height: { lg: '6rem', xs: '4.2rem' },
                  fontSize: { lg: '2rem', xs: '1.5rem' },
                  fontWeight: 800,
                }}
                disabled={uploading || !isOwner || uploadButtonDisabled}
                onClick={updateAndUploadDialogOpen}
              >
                {!uploading && t('button.generate_new_image')}
                {uploading && <Loader />}
              </Button>
            </Tutorial>
          </Tutorial>

          {isNCommerceUser ? (
            <>
              <NaverProductButton
                sx={{ ml: { lg: '2rem', xs: '0.8rem' } }}
                onClick={() => {
                  setNProductDialogOpen(true)
                }}
                text="상품 불러오기"
              />
              <NaverProductListDialog
                open={nProductDialogOpen}
                setOpen={setNProductDialogOpen}
                uploadImage={handleUpload}
                uploading={uploading}
                setUploading={setUploading}
              />
            </>
          ) : isNCommerceV2User ? (
            <>
              <NaverProductButtonV2
                sx={{ ml: { lg: '2rem', xs: '0.8rem' } }}
                onClick={() => {
                  setNProductDialogV2Open(true)
                }}
                text="상품 불러오기"
              />
              <NaverProductListDialogV2
                open={nProductDialogV2Open}
                setOpen={setNProductDialogV2Open}
                uploadImage={handleUpload}
                uploading={uploading}
                setUploading={setUploading}
              />
            </>
          ) : isNHNCommerceUser ? (
            <>
              <NHNProductContainedButton
                sx={{ ml: { lg: '2rem', xs: '0.8rem' } }}
                onClick={() => {
                  setNhnProductDialogOpen(true)
                }}
                text="상품 불러오기"
              />
              <NHNProductListDialog
                open={nhnProductDialogOpen}
                setOpen={setNhnProductDialogOpen}
                uploadImage={handleUpload}
                uploading={uploading}
                setUploading={setUploading}
              />
            </>
          ) : isCafe24CommerceUser ? (
            <>
              <Cafe24ProductButton
                sx={{ ml: { lg: '2rem', xs: '0.8rem' } }}
                text={isMobile ? '상품' : '상품 연동'}
                onClick={() => {
                  setCafe24ProductDialogOpen(true)
                }}
              />
              <Cafe24ProductListDialog
                open={cafe24ProductDialogOpen}
                setOpen={setCafe24ProductDialogOpen}
                uploadImage={handleUpload}
                uploading={uploading}
                setUploading={setUploading}
              />
            </>
          ) : (
            <></>
          )}
          {uploadOpen && (
            <UploadImageDialog
              uploadImage={handleUpload}
              uploadButtonTitle={t('button.original_upload')}
            />
          )}
        </>

        <Desktop>
          <Box sx={{ position: 'absolute', bottom: '-3rem' }}>
            {isDev && (
              <CustomTooltip>
                <IconButton
                  onClick={() => {
                    setOpenEndpointDialog(true)
                  }}
                >
                  <SettingIcon color={Object.keys(endpoint).length > 0 ? 'red' : null} />
                </IconButton>
              </CustomTooltip>
            )}
            {(isDev || RIGHT_CLICK_ALLOW_USER.includes(user.id)) && (
              // 드랩사람만 볼수있어야해요 !!!다른사람한테도 열려면 분리 필요함!!!
              <IconButton
                onClick={() => {
                  setOpenOptionDialog(true)
                }}
              >
                <SettingIcon />
              </IconButton>
            )}
            {isDev && (
              <>
                <BlueSwitch
                  sx={{
                    ml: '30px',
                    mr: '2px',
                    width: '4rem',
                    height: '2rem',
                    '& .MuiSwitch-switchBase': {
                      '&.Mui-checked': {
                        transform: 'translateX(2.1rem)',
                      },
                    },
                    '& .MuiSwitch-thumb': {
                      width: '2rem',
                      height: '2rem',
                    },
                  }}
                  checked={stg}
                  onChange={(e, c) => {
                    setStg(c)
                    localStorage.setItem('draph-stg', c)
                  }}
                />
                <span>stg</span>
              </>
            )}
          </Box>
        </Desktop>
      </Box>

      <Desktop>
        <ArtworkFilters
          selectValue={downloadedFilter}
          setSelectValue={setDownloadedFilter}
          textValue={textFilter}
          setTextValue={setTextFilter}
        />
      </Desktop>

      {/* <PortfolioConfigDialog open={openConfig} setOpen={setOpenConfig} /> */}
      <DevEndpointDialog open={openEndpointDialog} setOpen={setOpenEndpointDialog} />
      <DevOptionDialog open={openOptionDialog} setOpen={setOpenOptionDialog} />

      <CreateSuccessDialog open={openCreateSuccessDialog} setOpen={setOpenCreateSuccessDialog} />
    </PageHeaderStyle>
  )
}

function CreateSuccessDialog({ open, setOpen }) {
  const { t } = useTranslation()

  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const handleClose = (e, reason) => {
    // ----- GA4 event -----
    if (reason && reason === 'backdropClick') {
      window.gtag('event', 'suggest_portfolio_complete', { method: 'dimmed' })
    } else {
      window.gtag('event', 'suggest_portfolio_complete', { method: 'cancel' })
    }
    // ---------------------

    setOpen(false)
  }
  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiDialog-paper': {
              py: '2rem',
              px: 0,
              borderRadius: '2rem',
              width: '42rem',
            },
          }}
        >
          <DialogContent>
            <CenterAlignStack>
              <Typography
                sx={{ fontSize: '2rem', fontWeight: 700, color: theme => theme.palette.draph.blue }}
              >
                {t('create_portfolio_with_setting_dialog.create_success_title')}
              </Typography>
              <Typography sx={{ fontSize: '1.6rem', fontWeight: 400 }}>
                {t('create_portfolio_with_setting_dialog.create_success_comment')}
              </Typography>
            </CenterAlignStack>
          </DialogContent>
          <DialogActions>
            <CenterAlignBox sx={{ width: '100%' }}>
              <DialogButton
                actionText={t('create_portfolio_with_setting_dialog.upload_image')}
                handleClose={handleClose}
                handleAction={() => {
                  handleClose()
                  // ----- GA4 event -----
                  window.gtag('event', 'suggest_portfolio_complete', { method: 'upload' })
                  // ---------------------
                  setUploadOpen(true)
                }}
              />
            </CenterAlignBox>
          </DialogActions>
        </Dialog>
      </Desktop>

      <Mobile>
        <SwipeableDrawer
          open={open}
          onClose={handleClose}
          onOpen={() => {
            return open
          }}
          anchor="bottom"
          sx={{
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
            },
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <CenterAlignStack sx={{ pt: '1rem' }}>
            <CenterAlignBox
              sx={{
                width: '4rem',
                height: '0.4rem',
                backgroundColor: '#BCBCBC',
                borderRadius: '20px',
                mt: '1.2rem',
                mb: '1.2rem',
              }}
            ></CenterAlignBox>

            <CenterAlignStack sx={{ py: '2rem' }}>
              <Typography
                sx={{
                  fontSize: '1.8rem',
                  fontWeight: 700,
                  color: theme => theme.palette.draph.blue,
                }}
              >
                {t('create_portfolio_with_setting_dialog.create_success_title')}
              </Typography>
              <Typography sx={{ fontSize: '1.4rem', fontWeight: 400 }}>
                {t('create_portfolio_with_setting_dialog.create_success_comment')}
              </Typography>
            </CenterAlignStack>

            <CenterAlignBox sx={{ width: '100%', py: '2rem' }}>
              <DialogButton
                actionText={t('create_portfolio_with_setting_dialog.upload_image')}
                handleClose={handleClose}
                handleAction={() => {
                  handleClose()
                  setUploadOpen(true)
                }}
              />
            </CenterAlignBox>
          </CenterAlignStack>
        </SwipeableDrawer>
      </Mobile>
    </>
  )
}
