import { Box } from '@mui/material'
import { useState } from 'react'

export const AssetImage = ({ url, height }) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden', borderRadius: '10px', height }}>
      {!loaded && (
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            bgcolor: 'common.lightgray',
            animation: ' pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;',
            height,
          }}
        ></Box>
      )}
      {/* <Box
        sx={{
          position: 'absolute',
          filter: 'blur(30px)',
          transform: 'scale(1.25)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: '0.5',
          zIndex: 0,
          inset: 0,
          backgroundImage: `url(${url});`,
        }}
      /> */}
      <img
        src={url}
        loading="lazy"
        style={{
          aspectRatio: '0.72',
          objectFit: 'cover',
          position: 'relative',
        }}
        onLoad={() => setLoaded(true)}
      />
    </Box>
  )
}
