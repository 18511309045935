import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { apis } from 'apis'
import { CenterAlignBox, CenterAlignStack, ScrollToTop } from 'components'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import { getS3ImageSrc, removeTags } from 'utils/common'
import { BlogThumbnail } from './Blog'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, XIcon } from 'react-share'
import { ClipBoardIcon } from 'theme/icon'
import { useSnackbar } from 'notistack'
import { useScript } from 'hooks/useScript'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Helmet } from 'react-helmet-async'
import { useRecoilState, useRecoilValue } from 'recoil'
import { blogTabAtom, noticeBannersAtom } from 'atoms'
import { getAccessToken } from 'utils/cookie'
import { English, Japanese, Korean } from 'hooks/useLanguage'
import { EventThumbnail } from './Event'

export default function EventPost() {
  const { category } = useParams()
  const { eventId } = useParams()
  const { t, i18n } = useTranslation()
  const [event, setEvent] = useState(null)

  useEffect(() => {
    apis.blog
      .getEvent(eventId)
      .then(r => {
        const eventDetail = r.data
        setEvent(eventDetail)
      })
      .catch(() => {
        navigate(`/event/${i18n.language}`)
      })
  }, [eventId])

  const navigate = useNavigate()

  return (
    <CenterAlignBox sx={{ width: '100%', position: 'relative' }}>
      <Helmet>
        <meta property="og:title" content={event?.title} />
        <title>{`${event?.title} | Draph Art`}</title>

        {event?.meta?.description && <meta name="description" content={event.meta.description} />}
        {event?.meta?.description && (
          <meta property="og:description" content={event.meta.description} />
        )}
        {event?.cover_image_url && (
          <meta property="og:image" content={getS3ImageSrc(event.cover_image_url)} />
        )}
        <meta property="og:url" content={location.href} />
      </Helmet>
      <Desktop>
        <EventShareBox event={event} />
      </Desktop>
      <CenterAlignStack
        sx={{
          width: { lg: '74rem', xs: '32rem' },
          m: { lg: '5.6rem 0 9.6rem 0', xs: '2.4rem 0 7.8rem 0' },
        }}
      >
        {event ? (
          <>
            <Typography
              sx={{
                fontSize: { lg: '3rem', xs: '1.8rem' },
                fontWeight: 800,
                lineHeight: 'normal',
                m: { lg: '0 0 2.6rem 0', xs: '0rem 0 1.2rem 0' },
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(`/event/${i18n.language}`)
              }}
            >
              {t('event.main_title')}
            </Typography>
            <EventDetailTitle event={event} />
            <EventDetailContent event={event} />
            <Mobile>
              <EventShareBox event={event} />
            </Mobile>
            <EventDetailFooter event={event} />
          </>
        ) : (
          <PuffLoader />
        )}
      </CenterAlignStack>
      <Desktop>
        <Box sx={{ width: '10.1rem' }} />
      </Desktop>
      <ScrollToTop upside={true} />
    </CenterAlignBox>
  )
}

const EventDetailTitle = ({ event }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMobile = useMobileMediaQuery()
  const navigate = useNavigate()

  const [tabValue, setTabValue] = useRecoilState(blogTabAtom)

  const clickHandler = () => {}

  return (
    <CenterAlignStack id="blogTitle" sx={{ mb: { lg: '2rem', xs: '2rem' } }}>
      <Typography
        sx={{
          fontSize: { lg: '2rem', xs: '1.2rem' },
          fontWeight: 400,
          lineHeight: 'normal',
          cursor: 'pointer',
          color: theme.palette.draph.blue,
        }}
        onClick={() => {
          setTabValue(event.category)
          navigate(`/event/${i18n.language}`)
        }}
      >
        {t(`event.${event.category}`)}
      </Typography>
      <Typography
        sx={{
          fontSize: { lg: '4rem', xs: '2.4rem' },
          fontWeight: 800,
          lineHeight: 'normal',
          m: { lg: '1.4rem 0 1.8rem 0', xs: '0.8rem 0 1rem 0' },
        }}
      >
        {event.title}
      </Typography>
      <Typography
        sx={{ fontSize: { lg: '2rem', xs: '1.2rem' }, fontWeight: 500, lineHeight: 'normal' }}
      >
        <Korean>by 드랩아트</Korean>
        <Japanese>by ドラップアート</Japanese>
        <English>by Draph Art</English>
      </Typography>

      <img
        src={getS3ImageSrc(event.cover_image_url)}
        style={{
          width: isMobile ? '32rem' : '74rem',
          height: isMobile ? '16rem' : '37rem',
          objectFit: 'cover',
          marginTop: isMobile ? '2rem' : '2.4rem',
        }}
      />
    </CenterAlignStack>
  )
}

const EventDetailContent = ({ event }) => {
  const contentWrapperRef = useRef(null)

  useEffect(() => {
    const content = document.querySelector('article#content-display')
    if (content) content.remove()

    if (event && event.content_html) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(event.content_html, 'text/html')
      const content = doc.querySelector('article#content-display')

      if (contentWrapperRef.current && content) {
        contentWrapperRef.current.appendChild(content)
      }
    }
  }, [event])

  return <div id="content-wrapper" ref={contentWrapperRef} />
}

const EventShareBox = ({ event }) => {
  const baseURL = window.location.href

  const shareURL = {
    facebook: `${baseURL}?utm_source=draphblog_share&utm_medium=draphblog_share&utm_campaign=facebook`,
    twitter: `${baseURL}?utm_source=draphblog_share&utm_medium=draphblog_share&utm_campaign=x`,
    kakao: `${baseURL}?utm_source=draphblog_share&utm_medium=draphblog_share&utm_campaign=kakaotalk`,
    link: `${baseURL}?utm_source=draphblog_share&utm_medium=draphblog_share&utm_campaign=copiedlink`,
  }

  const title = 'Draph Art'

  const isMobile = useMobileMediaQuery()

  const iconSize = isMobile ? '3.2rem' : '2.4rem'
  const { enqueueSnackbar } = useSnackbar()

  const handleCopyClipBoard = async url => {
    // ----- GA4 event -----
    window.gtag('event', 'blog_sns', { method: 'clipboard' })
    // ---------------------

    try {
      await navigator.clipboard.writeText(url)
      enqueueSnackbar('클립보드에 링크가 복사되었어요.', { variant: 'success' })
    } catch (err) {
      console.log(err)
    }
  }
  const token = getAccessToken()
  const noticeBanner = useRecoilValue(noticeBannersAtom)

  const sideMenuStyle = {
    width: '6rem',
    height: '17.6rem',
    boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: '1rem',
    position: 'fixed',
    transform: 'translate(0, 14.9rem)',
    left: 'calc(50% - 47rem)',
    py: '1.6rem',
    gap: '1.6rem',
  }
  const sideMenuStyle1 = {
    width: '6rem',
    height: '17.6rem',
    boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: '1rem',
    position: 'sticky',
    bottom: !token || !noticeBanner ? '50rem' : '54rem',
    py: '1.6rem',
    gap: '1.6rem',
  }

  const rowMenuStyle = { flexDirection: 'row', gap: '1.6rem', mt: '2.4rem' }

  return (
    // <CenterAlignStack sx={isMobile ? rowMenuStyle : sideMenuStyle}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: { lg: '26.4rem', xs: '0' },
        marginRight: { lg: '4.1rem', xs: '0' },
      }}
    >
      <CenterAlignStack sx={isMobile ? rowMenuStyle : sideMenuStyle1}>
        <FacebookShareButton
          url={shareURL.facebook}
          className="Demo__some-network__share-button"
          style={{ display: 'flex' }}
          onClick={() => {
            // ----- GA4 event -----
            window.gtag('event', 'blog_sns', { method: 'facebook' })
            // ---------------------
          }}
        >
          <img src="/static/images/logo/outside/facebook_logo.png" style={{ width: iconSize }} />
          {/* <FacebookIcon width={iconSize} height={iconSize} round /> */}
        </FacebookShareButton>

        <TwitterShareButton
          url={shareURL.twitter}
          title={title}
          style={{ display: 'flex' }}
          className="Demo__some-network__share-button"
          onClick={() => {
            // ----- GA4 event -----
            window.gtag('event', 'blog_sns', { method: 'twitter' })
            // ---------------------
          }}
        >
          <XIcon width={iconSize} height={iconSize} round />
        </TwitterShareButton>

        <KakaoShare shareURL={shareURL.kakao} event={event} iconSize={iconSize} />

        <IconButton
          sx={{ p: 0, width: 'fit-content' }}
          onClick={() => handleCopyClipBoard(shareURL.link)}
        >
          <ClipBoardIcon style={{ width: iconSize, height: iconSize }} />
        </IconButton>
      </CenterAlignStack>
    </Box>
  )
}

const EventDetailFooter = ({ event }) => {
  const navigate = useNavigate()
  const isMobile = useMobileMediaQuery()
  const { t, i18n } = useTranslation()

  return (
    <CenterAlignStack sx={{ width: '100%', mt: { lg: '4rem', xs: '2.4rem' } }}>
      <Button
        variant="contained"
        sx={{
          minWidth: { lg: '47.2rem', xs: '32rem' },
          // width: { lg: '47.2rem', xs: '32rem' },
          height: { lg: '7.2rem', xs: '4.8rem' },
          fontSize: { lg: '2.8rem', xs: '1.8rem' },
          fontWeight: 800,
          borderRadius: '1rem',
        }}
        onClick={() => navigate(`/generate/upload`)}
      >
        {t('event.draph_start')}
      </Button>

      <Divider
        flexItem
        sx={{ m: { lg: '6.4rem 0 3.6rem 0', xs: '3.6rem 0 2.8rem 0' }, borderColor: '#808080' }}
      />

      <CenterAlignStack>
        <Typography
          sx={{ fontSize: { lg: '2.8rem', xs: '1.8rem' }, fontWeight: 700, lineHeight: 'normal' }}
        >
          {t('event.read_more')}
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: { lg: '2rem', xs: '1.6rem' },
            justifyContent: 'center',
            mt: { lg: '3.2rem', xs: '1.6rem' },
          }}
        >
          {event.next && <EventThumbnail event={event.next} width={isMobile ? '15.2' : '36'} />}
          {event.prev && <EventThumbnail event={event.prev} width={isMobile ? '15.2' : '36'} />}
        </Box>
      </CenterAlignStack>
    </CenterAlignStack>
  )
}

export const KakaoShare = ({ shareURL, event, iconSize }) => {
  const status = useScript('https://t1.kakaocdn.net/kakao_js_sdk/2.6.0/kakao.min.js')

  // kakao sdk 초기화하기
  // status가 변경될 때마다 실행되며, status가 ready일 때 초기화를 시도합니다.
  useEffect(() => {
    if (status === 'ready' && window.Kakao) {
      // 중복 initialization 방지
      if (!window.Kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT)

        // console.log(window.Kakao)
      }
    }
  }, [status])

  const handleKakaoButton = () => {
    // ----- GA4 event -----
    window.gtag('event', 'blog_sns', { method: 'kakao' })
    // ---------------------
    window.Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: event.title,
        description: removeTags(event.content_html).slice(4, 90),
        imageUrl: getS3ImageSrc(encodeURIComponent(event.cover_image_url)), // 이미지 한글 포함 시.. 에러발생
        imageWidth: 1000,
        imageHeight: 500,

        link: {
          mobileWebUrl: shareURL,
          webUrl: shareURL,
        },
      },
      buttons: [
        {
          title: '자세히 보기',
          link: {
            mobileWebUrl: shareURL,
            webUrl: shareURL,
          },
        },
      ],
    })
  }

  return (
    <IconButton sx={{ p: 0, width: 'fit-content' }} onClick={handleKakaoButton}>
      <img
        src="/static/images/logo/outside/kakao_logo.png"
        style={{ width: iconSize, height: iconSize }}
      />{' '}
    </IconButton>
  )
}
