import guestAxios from './configs/guest'
import * as cookie from 'utils/cookie'

const path = '/g'

const getGuestSessionInfo = () => {
  const d = {}

  const guestAccessToken = cookie.getGuestAccess()

  d[cookie.guestAccessToken] = guestAccessToken
  return d
}

export const guestAPI = {
  getGuestSession: () => {
    return guestAxios.get(`${path}`)
  },
  getGuestUser: () => {
    const guestAccessToken = cookie.getGuestAccess()
    return guestAxios.post(`${path}/u`, getGuestSessionInfo())
  },
  getGuestAccess: () => {
    return guestAxios.get(`${path}/x`)
  },
  getGuestPortfolio: portfolioType => {
    return guestAxios.get(`${path}/p?type=${portfolioType}`)
  },
  getGuestArtworkAll: portfolioType => {
    return guestAxios.get(`${path}/a?type=${portfolioType}`)
  },
  getGuestArtwork: (artworkId, portfolioType) => {
    return guestAxios.get(`${path}/a/${artworkId}?type=${portfolioType}`)
  },
  refreshGuestSession: () => {
    return guestAxios.get(`${path}/rs`)
  },
  checkGuestAccessToken: () => {
    return guestAxios.post(`${path}/ra`, getGuestSessionInfo())
  },
  deleteArtworks: body => {
    return guestAxios.post(`${path}/a/d`, body)
  },
}
