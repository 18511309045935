import { Box, Divider } from '@mui/material'
import { AssetUploadButton } from './AssetUploadButton'

export const AssetSelectButtons = ({ onFileChange, onClickAsset, divider = false }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '16px',
        alignItems: 'center',
        height: '100%',
        py: '16px',
        px: '12px',
      }}
    >
      <input type="file" onChange={onFileChange} style={{ display: 'none' }} id="file-upload" />
      <label style={{ height: '100%', flex: 1 }} htmlFor="file-upload">
        <AssetUploadButton variant={'upload'} size="auto" />
      </label>
      {divider && <Divider orientation="vertical" />}
      <AssetUploadButton variant={'asset'} sx={{ flex: 1 }} onClick={onClickAsset} size="auto" />
    </Box>
  )
}
