import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { apis } from 'apis'
import { setAccessToken, setRefreshToken } from 'utils/cookie'

import { useTheme } from '@mui/material/styles'
import Register from 'pages/Register'
import Login from 'pages/Login'

export const buttonMobileStyle = {
  width: '320px',
  height: '48px',
  mt: '23px',
  fontSize: '18px',
  fontWeight: '800',
}
export function NaverCommerceRegisterV2() {
  const navigate = useNavigate()
  const [showRegisterForm, setShowRegisterForm] = useState(true)
  // const [showRegisterForm, setShowRegisterForm] = useState(false)
  useEffect(() => {
    checkUser()

    // ----- GA4 event -----
    window.gtag('event', 'sign_up_begin', {})
    // ---------------------
  }, [])

  const checkUser = async () => {
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')
    if (token) {
      try {
        const res = await apis.naverCommerceV2.login({ jwe: token })
        console.log(token)
        console.log(res.data)

        if (res.data.success) {
          if (res.data.registered) {
            // 로그인 처리
            const d = res.data

            const accessToken = d.access_token
            const refreshToken = d.refresh_token
            setAccessToken(accessToken)
            setRefreshToken(refreshToken)

            window.gtag('event', 'login', {
              method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
            })

            navigate('/home', { replace: true })
          } else {
            // 회원가입 입력창 띄우기 (페이지 또는 팝업)
            setShowRegisterForm(true)
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          console.error('401 Unauthorized: Redirecting to error page')
          // window.location.href = 'https://solution.smartstore.naver.com/ko/error'
        } else {
          console.error('An unexpected error occurred:', e)
        }
      }
    }
  }
  const theme = useTheme()

  const showLogin = () => {
    setShowRegisterForm(false)
  }
  const showRegister = () => {
    setShowRegisterForm(true)
  }

  return (
    <>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.common.black,
          height: '8rem',
        }}
      >
        <img src="/static/images/logo/logo_h_white.png" style={{ height: '4.9rem' }} />
      </Stack>

      {showRegisterForm ? (
        <Register naverCommerceV2={true} naverCommerceV2HandleLogin={showLogin} />
      ) : (
        <Login naverCommerceV2={true} naverCommerceV2HandleRegister={showRegister} />
      )}
    </>
  )
}
