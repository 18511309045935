import { Box, SwipeableDrawer, Typography } from '@mui/material'
import { tutorialAtom } from 'atoms'
import { AssetSelect } from 'components/assets/AssetSelect'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { Tutorial } from 'theme/Tutorial'
import { iOS } from 'utils/common'
import { DragDropFile } from './UploadImageDialog'
import { useMobileMediaQuery } from 'hooks/useMediaQuery'
import { startTransition, useState } from 'react'
import { NukkiUploadComment } from './NukkiUploadComment'
import { DialogButton } from 'components/DialogButtons'
import { PuffLoader } from 'react-spinners'
import { useLocation } from 'react-router-dom'
import { useAssets } from 'hooks/useAssets'

export const UploadSwipeableDrawer = ({
  uploadOpen,
  handleClose,
  swipeableDrawerSx,
  title,
  notice,
  images,
  setImages,
  portfolioArtworklimit,
  multiple,
  uploadImglimit,
  example,
  isRemoveBgPage,
  bulkLoading,
  uploadButtonTitle,
  actionDisabledCondition,
  handleUpload,
}) => {
  const { t } = useTranslation()
  const [showMyAsset, setShowMyAsset] = useState(false)

  const isMobile = useMobileMediaQuery()

  const [tutorial] = useRecoilState(tutorialAtom)
  const location = useLocation()

  const { myAssets, selectAsset, resetAllSelect, selectedAssets } = useAssets('page')
  const [showUpload, setShowUpload] = useState(true)

  return (
    <SwipeableDrawer
      open={uploadOpen}
      onOpen={() => {
        return uploadOpen
      }}
      id="image-swiper-dialog"
      onClose={handleClose}
      anchor="bottom"
      sx={{
        // backgroundColor: 'white',
        '& .MuiDrawer-paper': {
          // top: isRemoveBgPage ? 'auto' : '20px',
        },
        '& .MuiDrawer-paperAnchorBottom': {
          borderTopLeftRadius: '25px',
          borderTopRightRadius: '25px',
        },
        ...swipeableDrawerSx,
      }}
      disableDiscovery={iOS}
      disableSwipeToOpen={true}
    >
      <CenterAlignStack>
        <CenterAlignBox
          sx={{
            width: '4rem',
            height: '0.4rem',
            backgroundColor: '#BCBCBC',
            borderRadius: '20px',
            mt: '1.2rem',
            mb: tutorial.mode && tutorial.step === '14_2' ? '3.2rem' : '1.2rem',
          }}
        ></CenterAlignBox>
        <Tutorial step="14_2">
          <CenterAlignStack
            sx={{
              width: '100vw',
            }}
          >
            {title ?? (
              <Typography fontSize="1.6rem" fontWeight={800} sx={{ mt: '0.8rem' }}>
                {t('generate_new_image')}
              </Typography>
            )}
            {notice ?? (
              <Typography fontSize="1.2rem" fontWeight={400} sx={{ px: '2rem' }}>
                {t('upload_dialog.notice_1')}
              </Typography>
            )}
            <CenterAlignStack sx={{ width: '100%', mt: '1.6rem' }}>
              {showMyAsset ? (
                <AssetSelect myAssets={Object.values(myAssets).flat()} selectAsset={selectAsset} />
              ) : (
                <DragDropFile
                  files={images}
                  setFiles={setImages}
                  width="89%"
                  height={isMobile ? '20rem' : '8rem'}
                  portfolioArtworklimit={portfolioArtworklimit}
                  multiple={multiple}
                  uploadImglimit={uploadImglimit}
                  onShowMyAsset={
                    location.pathname.includes('generate') ? () => setShowMyAsset(true) : () => {}
                  }
                  showUpload={showUpload}
                  setShowUpload={setShowUpload}
                />
              )}
            </CenterAlignStack>
          </CenterAlignStack>
        </Tutorial>
        <>{example}</>
        {isRemoveBgPage && images.length > 1 && <NukkiUploadComment />}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '10.4rem',
          }}
        >
          <Tutorial step="14_3">
            {isRemoveBgPage && images.length > 1 && <></>}
            <DialogButton
              handleClose={handleClose}
              actionText={
                bulkLoading ? (
                  <PuffLoader />
                ) : (
                  <>
                    {showMyAsset ? '선택' : uploadButtonTitle ?? t('upload')}
                    {isRemoveBgPage && images.length <= 1 && (
                      <Box
                        sx={{
                          ml: '0.7rem',
                          transition: 'all 0.3s',
                          width: '4rem',
                          height: '1.8rem',
                          background: 'white',
                          borderRadius: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          id="free-text"
                          sx={{
                            fontSize: { lg: '1.1rem', xs: '1rem' },
                            fontWeight: 600,
                            color: 'black',
                          }}
                        >
                          FREE
                        </Typography>
                      </Box>
                    )}
                  </>
                )
              }
              actionprops={{
                disabled:
                  location.pathname.includes('generate') && selectedAssets.length !== 0
                    ? false
                    : actionDisabledCondition
                    ? true
                    : images.length < 1 || bulkLoading,
              }}
              actionSx={{
                '&:hover #free-text': {
                  transition: 'all 0.3s',
                  color: theme => theme.palette.draph.blue,
                },
              }}
              handleAction={
                showMyAsset
                  ? () => {
                      startTransition(async () => {
                        const files = await Promise.all(
                          selectedAssets.map(async asset => {
                            const response = await fetch(`${asset.file_url}?w=${Date.now()}`)
                            const blob = await response.blob()

                            const file = new File([blob], asset.name, {
                              type: blob.type,
                            })

                            return file
                          })
                        )

                        await new Promise(resolve => {
                          setImages(prev => prev.concat(files))
                          resolve()
                        })
                      })

                      resetAllSelect()
                      setShowUpload(false)
                      setShowMyAsset(false)
                    }
                  : handleUpload
              }
            />
          </Tutorial>
        </Box>
      </CenterAlignStack>
    </SwipeableDrawer>
  )
}

// {/* {showGenCompo && (
//       <CenterAlignStack sx={{ pb: '2rem', width: '32rem' }}>
//         <FormControlLabel
//           control={
//             <Checkbox
//               checked={flagGenCompo}
//               onChange={e => {
//                 setFlagBgExpansion(false)
//                 setFlagGenCompo(e.target.checked)
//               }}
//               sx={{ py: '0px' }}
//             />
//           }
//           sx={{ '& .MuiTypography-root': { lineHeight: '1.8rem' } }}
//           label={
//             <span style={{ fontSize: '1.4rem' }}>
//               접시의 잘린 부분을 ai로 제작하여 <br /> 온전한 형태로 이미지를 생성합니다.
//             </span>
//           }
//         />
//         <Typography
//           sx={{
//             color: theme => theme.palette.common.red,
//             fontSize: '1.2rem',
//             lineHeight: '1.4rem',
//             mt: '0.3rem',
//             ml: '1rem',
//           }}
//         >
//           * 선택 시, 업로드 이미지당 1크레딧{' '}
//           <span style={{ fontWeight: 700 }}>추가 차감</span>
//         </Typography>
//       </CenterAlignStack>
//     )}

//     {showGenCompo1 && (
//       <CenterAlignStack sx={{ pb: '2rem', width: '32rem' }}>
//         <FormControlLabel
//           control={
//             <Checkbox
//               checked={flagBgExpansion}
//               onChange={e => {
//                 setFlagGenCompo(false)
//                 setFlagBgExpansion(e.target.checked)
//               }}
//               sx={{ py: '0px' }}
//             />
//           }
//           sx={{ '& .MuiTypography-root': { lineHeight: '1.8rem' } }}
//           label={
//             <span style={{ fontSize: '1.4rem' }}>
//               접시의 잘린 부분을 ai로 제작하여 <br /> 온전한 형태로 이미지를 생성합니다.
//             </span>
//           }
//         />
//         <Typography
//           sx={{
//             color: theme => theme.palette.common.red,
//             fontSize: '1.2rem',
//             lineHeight: '1.4rem',
//             mt: '0.3rem',
//             ml: '1rem',
//           }}
//         >
//           * 선택 시, 업로드 이미지당 1크레딧{' '}
//           <span style={{ fontWeight: 700 }}>추가 차감</span>
//         </Typography>
//       </CenterAlignStack>
//     )} */}
