import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UploadAssetIcon } from './UploadAssetIcon'

// 잘못 만듬. 기본 외엔 props로 받아어야헀는데
export const AssetUploadButton = ({ variant, size = 'large', ...props }) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 1.25,
        gap: '12px',
        border: 'none',
        cursor: 'pointer',

        ...ASSET[variant][size].sx,
        ...props.sx,
      }}
      {...props}
    >
      <Stack alignItems={'center'}>
        {ASSET[variant][size].icon}
        <DialogText
          sx={{
            // fontSize: size === 'small' ? '6px' : '1.4rem',
            color: variant === 'upload' ? '#161616' : 'draph.blue',
          }}
        >
          {t(ASSET[variant][size].text)}
        </DialogText>
      </Stack>
      {ASSET[variant][size].subText ? (
        <Typography
          sx={{
            fontSize: '1.2rem',
            fontWeight: 400,
            color: '#161616',
          }}
        >
          {ASSET[variant][size].subText}
        </Typography>
      ) : null}
    </Box>
  )
}

const DialogText = ({ children, sx = {} }) => (
  <Typography
    sx={{
      fontSize: '1.4rem',
      fontWeight: 600,
      wordBreak: 'keep-all',
      textAlign: 'center',
      ...sx,
    }}
  >
    {children}
  </Typography>
)

const AssetIcon = ({ width = 18, height = 18 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginBottom: '4px' }}
  >
    <path
      id="Vector"
      d="M1 8.11111V2.77778C1 2.30628 1.1873 1.8541 1.5207 1.5207C1.8541 1.1873 2.30628 1 2.77778 1H15.2222C15.6937 1 16.1459 1.1873 16.4793 1.5207C16.8127 1.8541 17 2.30628 17 2.77778V15.2222C17 15.6937 16.8127 16.1459 16.4793 16.4793C16.1459 16.8127 15.6937 17 15.2222 17H9.88889M1 17L9 9M1 17H6.33333M1 17V11.6667"
      stroke="#2C4DFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const ASSET = {
  upload: {
    large: {
      sx: {
        // width: 200,
        height: 126,
        py: '32px',
        px: '20px',
        border: '1px dashed',
        borderColor: 'asset.borderGray',
        bgcolor: 'common.lightgray',

        gap: '2px',
      },
      icon: <UploadAssetIcon />,

      text: 'asset.asset_upload',
      subText: '파일을 드롭하거나 클릭하세요.',
    },
    small: {
      sx: {
        width: 66,
        height: 98,
        border: '1px dashed',
        borderColor: 'asset.borderGray',
        bgcolor: 'common.lightgray',
      },
      icon: <UploadAssetIcon />,

      text: 'asset.asset_upload_small',
    },
    auto: {
      sx: {
        bgcolor: '#EEEEEE',
        height: '100%',
        px: '16px',
      },
      icon: <UploadAssetIcon />,
    },
  },
  asset: {
    large: {
      sx: {
        // width: 200,
        height: 126,

        py: '32px',
        px: '20px',

        bgcolor: 'draph.lighterblue',
        borderRadius: '10px',
        color: 'draph.blue',
        gap: '2px',
      },
      icon: <AssetIcon />,
      subText: '저장한 이미지를 불러오세요.',
      text: 'asset.asset_load',
    },
    small: {
      sx: {
        width: 66,
        height: 98,
        bgcolor: 'draph.lighterblue',
        borderRadius: '10px',
        color: 'draph.blue',
      },
      icon: <AssetIcon />,
      text: 'asset.asset_load_small',
    },
    auto: {
      sx: {
        bgcolor: '#D9F8E7',
        height: '100%',
        px: '16px',
      },
      icon: <AssetIcon />,
    },
  },
}
