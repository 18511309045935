import {
  Container,
  Grid,
  Pagination,
  Typography,
  Paper,
  Box,
  Divider,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  styled,
} from '@mui/material'
import { useTheme } from '@mui/system'
import { userAtom } from 'atoms'
import {
  CenterAlignBox,
  FlexBasis,
  CenterAlignStack,
  CustomPagination,
  CustomAccordionSummary,
  CustomAccordion,
  CustomAccordionDetails,
} from 'components'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { INFO_PERCENTAGE_WIDHT } from 'layouts/InfoLayout'
import { DRAWER_WIDTH_PERCENTAGE } from 'layouts/InfoSideBar'
import { MYPAGE_APPBAR_MOBILE, MYPAGE_SIDE_MARGIN } from 'layouts/MypageLayout'
import { APPBAR_MOBILE } from 'layouts/main/MainAppBar'
import { FOOTER_MOBILE } from 'layouts/main/MainFooter'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { DropdownArrowIcon } from 'theme/icon'
import { fontSize } from './test/Toolbar'
import { English, getStaticResourceLanguage, Korean } from 'hooks/useLanguage'
import { isKo } from 'utils/common'

const DetaislTypo = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    fontWeight: 400,
    fontSize: '1.3rem',
    wordBreak: 'keep-all',
  },
}))

export default function Board() {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const { id } = useRecoilValue(userAtom)

  const headerStyle = { fontSize: '1.7rem', fontWeight: 700 }
  const postStyle = { fontSize: '1.5rem', fontWeight: 400 }
  const isMobile = useMobileMediaQuery()
  const titlePrefix = isMobile ? '\u{1F389}' : `[${t('common.event')}]`
  const allPosts = [
    {
      id: 23,
      title: t('board.board_23_title'),
      date: '24.12.24',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <Korean>
            <DetaislTypo>
              안녕하세요, 고객님!
              <br />
              따뜻한 연말, 드랩아트가 더욱 강력해진 AI 기능으로 찾아왔어요! 🎄 이번 업데이트에서는
              광고 기능이 한층 더 똑똑하고 편리해졌답니다. 12월의 특별한 업데이트 소식을
              전해드릴게요!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>🎉 광고 v2가 더욱 강력해졌어요!</span> <br />
              이제는 템플릿으로 클릭 한 번에 광고를 뚝딱 만들고, 배경도 다시 만들 수 있어요! 로고
              이미지와 다양한 디자인 요소도 추가하는 등 더 섬세한 편집도 가능해졌답니다. 전문
              디자이너의 손길을 더한 것처럼, 매력적인 광고를 더 쉽고 빠르게 만들어보세요!
              <br /> <br />
              <span style={{ fontWeight: 600 }}>
                1) 광고 제작을 더 쉽게! &apos;AI 템플릿으로 제작&apos; 옵션 추가{' '}
              </span>{' '}
              <br />
              SNS 광고, 포스터, 상세페이지, 온라인 배너, 인스타그램 광고까지 다양한 템플릿을
              준비했어요. 마음에 드는 템플릿을 고르기만 하면 상품 이미지, 광고 문구, 배경 디자인까지
              모든 요소들이 조화롭게 채워진 광고를 바로 만들어져요. 광고 디자인, 이제 더 이상
              고민하지 마세요!
              <br />
              <br />
              <img
                src={`/static/images/board/board_23_1_ko.jpg`}
                style={{
                  padding: '1rem',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>
                2) 결과물이 마음에 안 든다면? 다시 만들 수 있어요!{' '}
              </span>{' '}
              <br />
              완성된 광고를 원하는대로 더 수정하고 싶으신가요? 메인 배경을 새롭게 생성할 수 있고,
              상품과 배경의 필터 효과도 마음껏 조절할 수 있어요.
              <br />
              <br />
              <img
                src={`/static/images/board/board_23_2_ko.jpg`}
                style={{
                  padding: '1rem',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }}
              />{' '}
              <br />
              <br />
              <img
                src={`/static/images/board/board_23_3_ko.jpg`}
                style={{
                  padding: '1rem',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>3)더욱 섬세한 편집 기능으로 완성도 UP!</span>
              <br />
              디자인 스티커, 로고, 텍스트, 배경 수정, AI 폰트까지! 필요한 요소들을 추가해서 우리
              브랜드만의 차별화된 광고를 완성해보세요.
              <br />
              <br />
              <img
                src={`/static/images/board/board_23_4_ko.jpg`}
                style={{
                  padding: '1rem',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>📚 심플의 진화, 이제 4가지 테마로! </span>
              <br />
              <br />
              <img
                src={`/static/images/board/board_23_5_ko.jpg`}
                style={{
                  padding: '1rem',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>✨ 빛 보정이 한층 더 강력해졌어요! </span>
              <br />
              AI가 만든 배경과 원본 제품 이미지가 아직도 어색하게 느껴지셨나요? 이제 걱정 마세요!
              리라이팅 기능이 더욱 똑똑해져서 전문 포토그래퍼의 손길을 더한 것처럼, AI가 자동으로
              빛과 그림자를 더욱 자연스럽게 조절해 완성도 높은 결과물을 만들어드려요.
              <br />
              <br />
              <img
                src={`/static/images/board/board_22_6_ko.jpg`}
                style={{
                  padding: '1rem',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }}
              />{' '}
              <br />
              <br />
            </DetaislTypo>
          </Korean>

          <English>
            <DetaislTypo>
              Hello, customer!
              <br />
              <br />
              As we approach the warm year-end season, Draph Art is back with even more powerful AI
              features! 🎄 In this update, our Banner Ads features have become smarter and more
              convenient. Let us share the special December update news with you!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>🎉 Banner Ads v2 is now more powerful!</span> <br />
              Now you can create ads in just one click using templates and even recreate
              backgrounds! You can also make more detailed edits by adding logo images and various
              design elements. Create attractive ads more quickly and easily, as if they were
              touched by a professional designer!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                1) Easier ad creation! Added &apos;Select Concept&apos; option{' '}
              </span>{' '}
              <br />
              We&apos;ve prepared various templates for social media ads, posters, product detail
              pages, online banners, and Instagram ads. Just choose your favorite template, and an
              ad will be instantly created with all elements harmoniously combined - from product
              images to ad copy and background design. No more worrying about ad design!
              <br />
              <br />
              <img
                src={`/static/images/board/board_23_1_en.jpg`}
                style={{
                  padding: '1rem',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>
                2) Not satisfied with the result? You can regenerate it!{' '}
              </span>{' '}
              <br />
              Want to modify your completed ad further? You can generate new main backgrounds and
              freely adjust filter effects for both products and backgrounds.
              <br />
              <br />
              <img
                src={`/static/images/board/board_23_2_en.jpg`}
                style={{
                  padding: '1rem',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>3) Banner Ad Image Editing Feature </span> <br />
              If you&apos;re not satisfied with the generated ad image, use the editing feature to
              modify it! You can adjust the brightness, contrast, and saturation of products and
              backgrounds, and even change the font, size, color, and position of the ad copy!
              <br /> <br />
              <img
                src={`/static/images/board/board_22_3_en.jpg`}
                style={{
                  padding: '1rem',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>
                Enhanced editing features for better quality!
              </span>{' '}
              <br />
              From design stickers to logos, ad copy, and background modifications! Add the elements
              you need to create unique ads for your brand.
              <br />
              <br />
              <img
                src={`/static/images/board/board_23_4_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>
                📚 Simple&apos;s evolution - now with 4 themes!{' '}
              </span>{' '}
              <br />
              Do you like simple backgrounds? Now you can choose from 4 simple themes instead of
              just one. With Draph&apos;s upgraded image generation AI model, you can experience
              even higher quality simple backgrounds!
              <br />
              <br />
              <img
                src={`/static/images/board/board_23_5_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
            </DetaislTypo>
          </English>
        </>
      ),
    },
    {
      id: 22,
      title: t('board.board_22_title'),
      date: '24.11.29',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <Korean>
            <DetaislTypo>
              안녕하세요, 드랩입니다.
              <br />
              드랩아트를 이용해 주셔서 늘 감사드립니다!
              <br />
              <br />
              쌀쌀한 바람이 불어오는 11월, 드랩아트가 획기적인 변화를 가지고 찾아왔어요! 🍂 AI 광고
              제작 기능이 더욱 강력하고 섬세해졌답니다. 게다가 서비스 전체적으로 생성 속도는 더
              빨라졌어요! 지금부터 11월의 특별한 업데이트 소식을 전해드릴게요.
              <br /> <br />
              <span style={{ fontWeight: 600 }}>
                🆕 광고 v2 beta에 신규 기능이 추가됐어요!{' '}
              </span>{' '}
              <br />
              기존 광고 기능과는 확연히 다른 광고 v2 beta, 사용하고 계신가요? 아직 어떻게 사용할지
              모르는 분들을 위해 광고 v2 beta의 신규 기능을 낱낱이 파헤쳐 볼게요!
              <br /> <br />
              <span style={{ fontWeight: 600 }}>1) 광고 문구 자동 생성 </span> <br />
              업로드한 상품 이미지와 사용자가 입력한 브랜드 및 제품 정보를 바탕으로 드랩 AI가 최적의
              광고 문구를 자동 생성해요. 만약 광고 문구를 바꾸고 싶다면 [수정] 버튼을 눌러 [텍스트]
              메뉴에서 직접 입력하거나 AI가 추천하는 광고 문구를 새롭게 적용할 수도 있어요!
              <br /> <br />
              <img
                src={`/static/images/board/board_22_1_ko.png`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>2) 배경 정보 입력 기능 </span> <br />
              이미지 설명 단계에서 배경 정보 입력 박스에 원하는 배경 콘셉트를 입력해보세요. 구도는
              유지하면서 이미지 배경은 입력한 프롬프트에 맞춰 생성됩니다!
              <br /> <br />
              <img
                src={`/static/images/board/board_22_2_ko.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>3) 광고 이미지 수정 기능 </span> <br />
              생성된 광고 이미지가 마음에 들지 않는다면 수정 기능을 사용해 편집하세요! 상품과 배경의
              밝기, 대비, 채도 등을 조절할 수 있고, 광고 문구의 폰트, 크기, 색상, 위치도 바꿀 수
              있어요!
              <br /> <br />
              <img
                src={`/static/images/board/board_22_3_ko.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>⚡️생성 속도가 2배나 빨라졌어요!</span> <br />
              최근 이용자가 많이 늘어나고, 다양한 업그레이드 기능들도 꾸준히 반영되면서 이용자가
              몰리는 시간대에 생성 속도가 느려지는 현상이 있었어요. 이에 서버를 증설하고 모니터링
              시스템을 구축하는 등의 개선 작업을 통해 전체적으로 이미지 생성 속도를 이전 대비 2배
              이상 높였어요! 앞으로도 더욱 쾌적한 서비스를 제공하기 위해 계속 노력할게요!
              <br /> <br />
              <img
                src={`/static/images/board/board_22_4_ko.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>💎이제 4K 화질로 더욱 선명하게! </span> <br />
              생성된 이미지가 흐릿하게 느껴지셨나요? 이제 걱정 마세요! 새로 추가된 화질 개선
              기능으로 4K 수준의 고해상도 이미지를 만나보실 수 있어요. 최대 3072x3072 해상도로, 그
              어느 때보다 선명하고 디테일한 결과물을 제공합니다.
              <br /> <br />
              <img
                src={`/static/images/board/board_22_5_ko.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>👀마이페이지에서 모든 정보를 한눈에! </span> <br />
              내 구독 현황부터 보유 크레딧, 내가 아낀 비용, 크레딧 사용 내역까지! 이제
              마이페이지에서 모든 정보를 한 번에 확인하실 수 있어요. 여러분의 드랩아트 활용 현황을
              더욱 쉽게 관리해보세요. <br /> <br />
              <img
                src={`/static/images/board/board_22_6_ko.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
            </DetaislTypo>
          </Korean>

          <English>
            <DetaislTypo>
              Hello, customer!
              <br />
              <br />
              As November brings its cool breeze, Draph Art has returned with some groundbreaking
              changes! 🍂 Our AI banner ads featuraes have become more powerful and sophisticated.
              Plus, the overall service processing speed has gotten faster! Let me share with you
              these special November updates.
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                🆕 New features have been added to Banner Ads v2 beta!
              </span>{' '}
              <br />
              Have you tried the Banner Ads v2 beta, which is distinctly different from the existing
              function? Let&apos;s dive deep into the new features of Banner Ads v2 beta for those
              who aren&apos;t sure how to use it yet!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>1) Automatic Ad Copy Generation </span> <br />
              Based on your uploaded product images and user-input brand and product information,
              Draph AI automatically generates optimal advertising copy. If you want to change the
              ad copy, you can click the [Edit] button and either input text directly in the [Text]
              menu or apply newly AI-recommended advertising copy!
              <br /> <br />
              <img
                src={`/static/images/board/board_22_1_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>2) Background Setting Feature </span> <br />
              During the image description step, try typing your desired background concept in the
              background input box. While keeping the original composition, it creates a background
              that matches your prompt!
              <br /> <br />
              <img
                src={`/static/images/board/board_22_2_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>3) Banner Ad Image Editing Feature </span> <br />
              If you&apos;re not satisfied with the generated ad image, use the editing feature to
              modify it! You can adjust the brightness, contrast, and saturation of products and
              backgrounds, and even change the font, size, color, and position of the ad copy!
              <br /> <br />
              <img
                src={`/static/images/board/board_22_3_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>
                ⚡️Generation Speed is Now Twice as Fast!{' '}
              </span>{' '}
              <br />
              With the recent increase in users and steady implementation of various upgrade
              features, we experienced slower generation speeds during peak hours. Through
              improvements such as server expansion and monitoring system implementation, we&apos;ve
              increased the overall image generation speed by more than double compared to before!
              We&apos;ll continue working hard to provide an even better service!
              <br /> <br />
              <img
                src={`/static/images/board/board_22_4_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>💎Now Clearer with 4K Quality!</span> <br />
              Did your generated images seem blurry? No need to worry anymore! With our newly added
              upscale feature, you can now experience 4K-level high-resolution images. With a
              maximum resolution of 3072x3072, we provide clearer and more detailed results than
              ever before.
              <br /> <br />
              <img
                src={`/static/images/board/board_22_5_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>
                👀See All Information at a Glance in My Page!{' '}
              </span>{' '}
              <br />
              From your subscription status to available credits, cost savings, and credit usage
              history - you can now check all information at once on My Page. Manage your Draph Art
              usage status more easily than ever. <br /> <br />
              <img
                src={`/static/images/board/board_22_6_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
            </DetaislTypo>
          </English>
        </>
      ),
    },
    {
      id: 21,
      title: t('board.board_21_title'),
      date: '24.11.12',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <Korean>
            <DetaislTypo>
              안녕하세요, 드랩입니다.
              <br />
              드랩아트를 이용해 주셔서 늘 감사드립니다!
              <br />
              <br />
              &quot;재생성&quot;에 대한 운영 방식이 변경될 예정입니다. <br />
              <br />
              <span style={{ fontWeight: 600 }}>[변경 전]</span>
              <br />
              - 재생성 횟수 제한 없음
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>[변경 후]</span>
              <br />
              - 다운로드한 아트워크에 한해 재생성 불가
              <br />
              * 생성된 콘텐츠를 다운로드 하신 경우, 해당 아트워크에 한해 재생성 버튼이
              비활성화됩니다. <br />
              * 적용일자 : 2024-11-14 09:00 <br />
              <br />
              <br />
              원하는 결과물이 도출되었음에도 무한히 재생성이 진행되어, 서버문제와 전반적 속도부분에
              부담이 생겼었는데요, 여러 고객님들이 더욱 빠르고 쾌적하게 서비스를 이용하실 수 있도록
              시스템 자원을 조금 더 효율적으로 사용하기 위한 것이니, 너그러운 마음으로 이해해 주시면
              감사하겠습니다.
              <br />
              <br />
              앞으로도 드랩아트는 꼭 필요한 커머스 컨텐츠를 자유롭게 생성하실 수 있는 서비스로 더욱
              발전하겠습니다!
              <br />
              <br />
              감사합니다.
            </DetaislTypo>
          </Korean>

          <English>
            <DetaislTypo>
              Hello. This is Draph!
              <br />
              Thank you for always using Draph Art!
              <br />
              <br />
              The operation method for the &quot;Redo&quot; feature will be changing. <br />
              <br />
              <span style={{ fontWeight: 600 }}>[Before Change] </span>
              <br />
              - Unlimited regeneration attempts
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>[After Change] </span>
              <br />
              - Regeneration not available for downloaded artwork
              <br />
              * Once content has been downloaded, the redo button will be disabled for that specific
              artwork. <br />
              * Effective Date: November 14, 2024, 09:00 <br />
              <br />
              <br />
              We noticed that unlimited regenerations were being performed even after desired
              results were achieved, which created strain on our servers and overall speed. This
              change is intended to use system resources more efficiently so that all customers can
              enjoy faster and smoother service. We kindly ask for your understanding.
              <br />
              <br />
              Draph Art will continue to develop as a service where you can freely create essential
              commerce content!
              <br />
              <br />
              Thank you.
            </DetaislTypo>
          </English>
        </>
      ),
    },
    {
      id: 20,
      title: t('board.board_20_title'),
      date: '24.10.31',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <Korean>
            <DetaislTypo>
              안녕하세요, 고객님!
              <br />
              찬바람이 불어오기 시작하는 계절, 드랩아트가 특별한 소식을 가지고 찾아왔어요! AI로는
              구현이 어려웠던 이미지도, 이제는 전문가의 손길로 만나보실 수 있답니다. 더불어 AI
              기능도 한층 더 업그레이드되어 더욱 퀄리티 높은 결과물을 만나보실 수 있어요.
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>[NEW] 이제 전문가가 직접 만들어드립니다!</span>
              <br />
              AI로는 표현하기 어려운 이미지가 필요하신가요? 전문가와 상담을 통해 원하시는 이미지를
              구체적으로 말씀해주세요. 단순한 배경 합성부터, 제품 콘셉트 이미지, 모델과 상품의
              자연스러운 공간 배치, 제품을 들고 있는 모델샷까지!{' '}
              <span style={{ fontWeight: 600 }}>
                드랩아트의 전문가가 상담 내용을 바탕으로 퀄리티 높은 상품 이미지를 대신
                제작해드려요!
              </span>
              <br />
              <br />
              <Link
                to="/overview/bespoke"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: 'unset',
                  textDecoration: 'none',
                  fontSize: isMobile ? '1.3rem' : '1.6rem',
                  fontWeight: 600,
                }}
              >
                👉 제작 대행 상담 바로가기
              </Link>
              <br />
              <br />
              <img
                src={`/static/images/board/board_20_1_ko.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>[모델 기능] 더 편리하게, 더 다채롭게! </span>
              <br />
              외국인 모델이 새로 추가됐어요!{' '}
              <span style={{ fontWeight: 600 }}> 모델 생성, 모델 얼굴 변경</span> 에서 남녀 각각
              1명씩의 외국인 고정 모델을 만나보실 수 있답니다.{' '}
              <span style={{ fontWeight: 600 }}>
                또한, 영역 자동 선택 기능으로 작업 시간도 단축됐어요!
              </span>{' '}
              모델 변경에서는 옷 영역이, 모델 얼굴 변경에서는 얼굴 영역이 자동으로 선택되어 훨씬 더
              쉽게 사용하실 수 있어요.
              <br />
              <br />
              <img
                src={`/static/images/board/board_20_2_ko.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <img
                src={`/static/images/board/board_20_3_ko.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>[배경 생성] 더욱 선명하고 다채로졌어요!</span>
              <br />
              <span style={{ fontWeight: 600 }}>
                {' '}
                꾸준한 AI 학습으로 배경 생성 품질이 크게 향상되었어요.
              </span>{' '}
              같은 프롬프트를 입력해도 이전보다 훨씬 더 선명하고 디테일한 배경이 생성됩니다. 게다가
              <span style={{ fontWeight: 600 }}> 테마 선택 옵션도 더욱 다양해졌어요! </span> 평온한
              숲 속, 새하얀 겨울냄새, 깔끔한 주방, 심플이즈굿 등 제품 사진에 실제로 많이 사용되는
              테마들이 새롭게 추가되었답니다. 이제 더욱 선명하고 다양한 콘셉트로 여러분의 상품을
              돋보이게 만들어보세요!
              <br />
              <br />
              <img
                src={`/static/images/board/board_20_4_ko.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <br />
            </DetaislTypo>

            <Typography sx={{ fontWeight: 600 }}>💡 프로 팁 </Typography>
            <ul style={{ marginLeft: '3rem' }}>
              <li>
                AI로 구현하기 어려운 특별한 구도나 연출이 필요하다면, 제작 대행 서비스를
                이용해보세요.{' '}
              </li>
              <li>새로워진 배경 생성 기능과 다양한 테마를 활용해 상품 이미지를 만들어보세요.</li>
              <li>외국인 모델로 브랜드의 고급스러움을 한층 더해보세요. </li>
            </ul>
          </Korean>

          <English>
            <DetaislTypo>
              Hello, customer!
              <br />
              This October brings cool breezes and an upgraded Draph Art! From enhanced background
              generation to new models, we&apos;re introducing special features that will make your
              products stand out even more.
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>[BG Generation] Clearer and More Vibrant! </span>
              <br />
              <span style={{ fontWeight: 600 }}>
                {' '}
                The BG Generation quality has significantly improved through continuous AI learning.{' '}
              </span>{' '}
              The same prompts now create much clearer and more detailed backgrounds than before.
              Plus,
              <span style={{ fontWeight: 600 }}>
                we&apos;ve expanded our theme selection options!
              </span>{' '}
              We&apos;ve added new themes that are commonly used in product photography. Now make
              your products stand out with clearer and more diverse concepts!
              <br />
              <br />
              <img
                src={`/static/images/board/board_20_1_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                [Model Features] More Convenient and Diverse!{' '}
              </span>
              <br />
              <span style={{ fontWeight: 600 }}>
                We&apos;ve added stunning new international models!
              </span>{' '}
              You can now find one fixed international model for each gender in both Model Creation
              and Model Face Creation features. Save time with automatic area selection! The
              clothing area in Model Changes and facial area in Model Face Creations are
              <span style={{ fontWeight: 600 }}>
                {' '}
                now automatically selected, making the process much easier.{' '}
              </span>
              <br />
              <br />
              <img
                src={`/static/images/board/board_20_2_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <img
                src={`/static/images/board/board_20_3_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <br />
            </DetaislTypo>

            <Typography sx={{ fontWeight: 600 }}>💡 Pro Tips </Typography>
            <ul style={{ marginLeft: '3rem' }}>
              <li>
                Create series product images using the improved background generation and various
                themes.
              </li>
              <li>Add extra sophistication to your brand with international models. </li>
              <li>
                Speed up your model generation workflow using the automatic area selection feature!{' '}
              </li>
            </ul>
          </English>
        </>
      ),
    },
    {
      id: 19,
      title: t('board.board_19_title'),
      date: '24.09.27',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <Korean>
            <DetaislTypo>
              안녕하세요, 고객님!
              <br />
              가을의 문턱에서 드랩아트가 여러분의 목소리에 귀 기울여 또 한 번 진화했습니다!
              <br />
              <br />
              많은 분이 모델 기능을 이용하실 때 &quot;만들 때마다 항상 같은 얼굴의 모델이 나왔으면
              좋겠어요&quot;라고 말씀해 주셨죠. 맞아요. 이전에는 생성할 때마다 모델의 얼굴이 바뀌어
              일관된 모델 아이덴티티를 가져가기 어려웠어요.
              <br />
              <br />
              하지만 이제 걱정 마세요! 특정 모델 얼굴로만 일관성 있게 이미지를 만들 수 있는 옵션이
              추가되었어요! 물론 기존처럼 랜덤하게 생성하는 것도 가능해요.
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                [모델 생성]에서도 원하는 모델을 선택할 수 있게 됐어요!{' '}
              </span>
              <br />
              이전에는 모델을 선택할 수 없어서 결과물 4장 모두 모델의 성별과 얼굴이 랜덤으로
              나왔었죠. 이제는 원하는 대로 모델을 선택할 수 있게 되었어요. 성별은 물론, 성별마다 세
              가지 옵션의 모델 얼굴이 제공돼요.
              <br />
              AI(기본)와 특정 얼굴로 고정되는 두 명의 모델 중에서 선택할 수 있어요.
              <br />
              *AI(기본)의 경우, 결과물 4장에서 모델의 이목구비가 미세하게 다를 수 있음
              <br />
              <br />
              <img
                src={`/static/images/board/board_19_1_ko.png`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              심지어 모델의 얼굴 각도와 포즈도 훨씬 다양해졌어요! 이전보다 더 자연스럽고 다채로운
              포즈로 여러분의 상품을 더욱 돋보이게 만들 수 있어요. 어떤 포즈들이 추가되었는지 지금
              한 번 생성해 보세요!
              <br />
              <br />
              <img
                src={`/static/images/board/board_19_2_ko.png`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                [모델 얼굴 변경]에서 모델 선택 옵션이 변경됐어요!
              </span>
              <br />
              AI(기본) 옵션과 함께, 특정 얼굴로 고정되는 두 명의 모델 중 하나를 선택할 수 있게
              되면서 원하는 모델의 얼굴로 일관성 있는 이미지를 만들 수 있게 되었어요.
              <br />
              인종 선택 옵션이 없어졌지만 걱정하지 마세요. 퀄리티 개선을 위해 잠깐 자리를 비운
              것으로, 조만간 더 매력적인 모델들로 업그레이드되어 다시 추가될 예정이에요.
              <br />
              *AI(기본)의 경우, 원본 얼굴에 따라 모델의 이목구비가 미세하게 다를 수 있음
              <br />
              <br />
              <img
                src={`/static/images/board/board_19_3_ko.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>[배경 확장]의 자유도가 훨씬 높아졌어요!</span>
              <br />
              이전까지는 원본 이미지의 위치가 가운데에 고정된 채 입력한 사이즈에 맞춰 AI가 알아서
              배경을 확장했었어요. 이제는 직접 미리보기 화면을 보면서 상하좌우로 확장 영역을 조정할
              수 있어요. 또한, 원본 이미지의 위치도 옮길 수 있게 돼서 특정 방향으로만 배경을 길게
              확장할 수도 있어요! (ex. 원본 이미지를 오른쪽 정렬한 후, 왼쪽으로만 길게 배경 확장)
              <br />
              <br />
              <img
                src={`/static/images/board/board_19_4_ko.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <br />
              <img
                src={`/static/images/board/board_19_5_ko.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
            </DetaislTypo>
          </Korean>

          <English>
            <DetaislTypo>
              Hello, this is Draph!
              <br />
              At the doorstep of autumn, Draph Art has once again evolved by listening to your
              feedback!
              <br />
              <br />
              Many of you mentioned, &quot;I wish the model&apos;s face would stay the same each
              time I create an image.&quot; That’s right. Previously, the model&apos;s face changed
              with each generation, making it hard to maintain a consistent model identity.
              <br />
              <br />
              But worry no more! We&apos;ve added an option to consistently generate images with the
              same model&apos;s face! Of course, you can still generate random faces as before.😍
              Check out the details below!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                [Model Creation] Now allows you to choose the model you want!
              </span>
              <br />
              Previously, all four generated images came with random genders and faces. Now, you can
              select your desired model. You can choose not only the gender but also from three face
              options per gender. You can pick between AI (default) and two specific models with
              fixed faces.
              <br />
              *For AI (default), the facial features may vary slightly across the four generated
              images.
              <br />
              <br />
              <img
                src={`/static/images/board/board_19_1_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              Even the model&apos;s facial angles and poses have become much more diverse! You can
              now use models with more natural and varied poses to make your products stand out even
              more than before. Try generating now to see which new poses have been added!
              <br />
              <br />
              <img
                src={`/static/images/board/board_19_2_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                [Model Face Creation] Selection options have been updated!
              </span>
              <br />
              Along with the AI (default) option, you can now choose from two specific models with
              fixed faces, allowing you to create consistent images with your preferred model’s
              face.
              <br />
              Although the race selection option has been temporarily removed, don’t worry. It’s
              undergoing a quality upgrade and will be back soon with even more attractive model
              options.
              <br />
              *For AI (default), the model’s facial features may vary slightly depending on the
              original face.
              <br />
              <br />
              <img
                src={`/static/images/board/board_19_3_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>[BG Extender] Now offers more flexibility! </span>
              <br />
              Previously, the original image’s position was fixed in the center, and the AI
              automatically expanded the background to match the input size. Now, you can manually
              adjust the expansion area in all directions while previewing the result. You can also
              move the original image, allowing you to extend the background in just one direction!
              (e.g., aligning the original image to the right and extending the background only to
              the left).
              <br />
              <br />
              <img
                src={`/static/images/board/board_19_4_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <br />
              <img
                src={`/static/images/board/board_19_5_en.jpg`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
            </DetaislTypo>
          </English>
        </>
      ),
    },
    {
      id: 18,
      title: t('board.board_18_title'),
      date: '24.08.29',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <Korean>
            <DetaislTypo>
              안녕하세요, 고객님!
              <br />
              무더운 여름, 드랩아트가 시원한 변화로 찾아왔어요. 어떤 점이 달라졌는지 함께
              살펴볼까요? 8월 업데이트로 더욱 빨라지고, 더욱 놀라운 결과물을 만들어 내고 있는
              드랩아트를 확인해 보세요!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>생성 속도가 더 빨라졌어요! </span>
              <br />
              &quot;기다림은 지루해!&quot; 여러분의 목소리에 귀 기울였어요. 이제 드랩아트는 더욱
              빠르게 여러분의 상상을 현실로 만들어냅니다. 백엔드 시스템을 대폭 개선하여 동시에
              처리할 수 있는 작업량을 늘렸고, 대기 시간은 줄였어요. 쉽게 말해, 여러분이 커피 한 잔
              마시는 사이 더 많은 이미지를 만들어낼 수 있게 되었답니다!
              <br />
              <br />
              <img
                src={`/static/images/board/board_18_1_ko.png`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>생성 이미지 품질이 더 좋아졌어요! </span>
              <br />
              드랩아트의 생성 AI 모델은 계속 성장하고 있어요. 상품 주변에 하얀 테두리가 생기는
              문제를 개선하여 상품과 배경의 경계가 더욱 자연스러워졌어요. 게다가 [모델 변경] 메뉴의
              생성 이미지도 최대 1024px 사이즈로 생성 가능해져 선명도와 디테일이 크게
              향상되었습니다. 마네킹이 아닌 진짜 모델이 입은 것 같은 자연스러운 결과물을 만나보세요!
              <br />
              <br />
              <img
                src={`/static/images/board/board_18_2_ko.png`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>상품 이미지 콘셉트 고민을 없애 드릴게요! </span>
              <br />
              드랩아트 홈 화면이 새롭게 변신했어요! 이제 다른 고객들의 멋진 생성 결과물을 한눈에 볼
              수 있답니다. 콘셉트가 무엇인지 궁금한 이미지를 클릭해 보세요. &apos;비포&apos;와
              &apos;애프터&apos; 이미지는 물론, 그 결과를 만들어낸 콘셉트 프롬프트 입력 정보까지
              모두 확인할 수 있어요. 마음에 드는 스타일이 있다면 프롬프트를 참고해 여러분만의 특별한
              이미지를 만들어보세요! (생성물에 대한 마케팅 활용 동의를 한 고객의 이미지만 공개)
              <br />
              <br />
              <img
                src={`/static/images/board/board_18_3_ko.png`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              앞으로도 고객분들의 의견을 반영하여 더욱 편하게 드랩아트를 이용하실 수 있도록 계속해서
              서비스를 개선해나갈게요!
              <br /> 감사합니다!
            </DetaislTypo>
          </Korean>

          <English>
            <DetaislTypo>
              Hello, this is Draph!
              <br />
              As summer comes to a close and the weather cools down, Draph Art is here with
              refreshing changes. Let’s take a look at what’s new! Check out how Draph Art has
              become even faster and is producing more amazing results with the August update!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>Generation speed has increased! </span>
              <br />
              “Waiting is boring!” We’ve listened to your feedback. Draph Art now brings your
              imagination to life even quicker. We’ve significantly improved our backend system to
              increase the amount of work we can process simultaneously and reduced wait times. In
              simple terms, you can now create more images while you enjoy a cup of coffee!
              <br />
              <br />
              <img
                src={`/static/images/board/board_18_1_en.png`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>Image quality has improved! </span>
              <br />
              Draph Art&apos;s generative AI model continues to grow. We’ve addressed the issue of
              white borders appearing around products, making the boundaries between the product and
              background much more natural. Additionally, images generated in the [Model Change]
              menu can now be created at a maximum size of 1024px, greatly enhancing clarity and
              detail. Experience results that look like real models, not mannequins!
              <br />
              <br />
              <img
                src={`/static/images/board/board_18_2_en.png`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <span style={{ fontWeight: 600 }}>
                Say goodbye to product image concept worries!{' '}
              </span>
              <br />
              Draph Art’s home has had a makeover! Now, you can easily view the amazing results
              created by other customers. Curious about the concepts behind certain images? Click to
              see the &apos;Before&apos; and &apos;After&apos; images, as well as the concept prompt
              input information that created those results. If you find a style you like, use the
              prompt as inspiration to create your own special images! (Only images from customers
              who have consented to marketing use will be displayed)
              <br />
              <br />
              <img
                src={`/static/images/board/board_18_3_en.png`}
                style={{ padding: '1rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              Draph will continue to improve our services based on your feedback to ensure a more
              convenient user experience. Thank you!
            </DetaislTypo>
          </English>
        </>
      ),
    },
    {
      id: 17,
      title: t('board.board_17_title'),
      date: '24.07.29',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <Korean>
            <DetaislTypo>
              안녕하세요. 드랩입니다!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}> 드랩아트가 새 단장을 했어요!</span> 서비스 출시
              1주년을 맞이해 그동안 고객님들께 받은 피드백을 반영하여 웹사이트 디자인을 새롭게 꾸민
              것은 물론, 상품 이미지 생성 퀄리티까지 UP 했어요. 이번 업데이트 포인트를 지금부터
              차근차근 알려드릴게요.
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>UI/UX가 더 편하게 리뉴얼되었어요! </span>
              <br />
              사용법이 어렵다는 피드백을 반영해 상품 이미지 생성하는 과정을 더 쉽고, 간편하게
              바꿨어요. 생성 전에 여러 가지 설정을 할 필요 없이 원본 이미지를 등록하기만 하면 AI가
              알잘딱깔센하게 매력적인 상품 이미지를 만들어주고, 생성된 결과물과 원본 이미지를 한
              눈에 비교할 수 있는 화면도 추가했어요! <br />
              TIP. 리뉴얼 후에도 톱니바퀴 버튼을 누르면 이전처럼 다양한 생성 옵션을 설정할 수
              있어요.
              <br />
              <br />
              <img
                src={`/static/images/board/board_17_1_ko.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <img
                src={`/static/images/board/board_17_2_ko.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              <span style={{ fontWeight: 600 }}>
                다양한 포디움 배경이 업데이트됐어요!
              </span> <br /> 전문 스튜디오에서 촬영한 듯한 무드가 물씬 풍기는 다양한 종류의
              &#39;포디움(podium)&#39; 배경을 만나보세요. 어떤 매력적인 포디움 배경이 나올지는 오직
              AI만 알고 있다는 사실! 단, 화장품, 패션잡화 등 실제 상품 사이즈가 소·중형인 상품만
              포디움 배경이 랜덤으로 생성되는 점 기억해 주세요.
              <br />
              <br />
              <img
                src={`/static/images/board/board_17_3_ko.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                원하는 콘셉트의 이미지가 있으면 올려보세요!
              </span>{' '}
              <br /> 원하는 상품 이미지의 디자인을 텍스트로 일일이 쓰기 힘드셨죠? 어떤 느낌인지
              머릿속에는 그려지는데 그걸 설명할 마땅한 단어가 떠오르지 않았던 답답함...! 앞으로는
              원하는 콘셉트의 이미지가 있으면 올려주세요! 올려주신 참고 배경 이미지와 비슷한
              콘셉트로 상품 이미지를 만들어드려요.
              <br />
              <br />
              <img
                src={`/static/images/board/board_17_4_ko.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>하모나이징을 개선했어요!</span> <br />{' '}
              하모나이징(harmonizing)은 AI가 새롭게 생성한 배경과 원본 상품 이미지 간에 이질감을
              줄이고 조화롭게 하는 자동 보정 기능이에요. 이전과 비교하여 원본 사진의 상품 색감은
              최대한 유지하면서 AI가 새로 만든 배경과 서로 어색해 보이지 않도록 드랩아트만의 최적
              하모나이징 비율을 찾아 적용했어요!
              <br />
              <br />
              <img
                src={`/static/images/board/board_17_5_ko.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              앞으로도 고객분들의 의견을 반영하여 더욱 편하게 드랩아트를 이용하실 수 있도록 계속해서
              서비스를 개선해나갈게요!
              <br /> 감사합니다!
            </DetaislTypo>
          </Korean>

          <English>
            <DetaislTypo>
              Hello, this is Draph!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}> Draph Art has been renewed! </span> To celebrate
              the first anniversary of our service launch, we’ve revamped the website design based
              on the feedback we&apos;ve received from you. We’ve also improved the quality of
              product image generation. Let me take you through the update points step by step.
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>The UI/UX has been renewed for easier use! </span>
              <br />
              Based on the feedback that the process was difficult to use, we’ve made it easier and
              more convenient to generate AI product images. You only need to upload the original
              image without having to set multiple options beforehand, and the AI will create
              attractive product images for you effortlessly. We’ve also added a screen where you
              can compare the generated results with the original image at a glance!
              <br /> TIP: Even after the renewal, you can click the gear button to set various
              generation options like before.
              <br />
              <br />
              <img
                src={`/static/images/board/board_17_1_en.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />{' '}
              <br />
              <img
                src={`/static/images/board/board_17_2_en.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              <span style={{ fontWeight: 600 }}>
                A variety of podium backgrounds have been updated!
              </span>{' '}
              <br /> Check out the various types of podium backgrounds that give off the vibe of
              being shot in a professional studio. Only the AI knows what kind of attractive podium
              background will be generated!
              <br /> Just remember, podium backgrounds will be randomly created only for products
              that are small to medium-sized, such as cosmetics and fashion accessories.
              <br />
              <br />
              <img
                src={`/static/images/board/board_17_3_en.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                If you have an image of the desired concept, please upload it!
              </span>{' '}
              <br /> It must have been difficult to write out the design of the product image you
              wanted in text, right? You had a clear idea in your head but struggled to find the
              right words to describe it. From now on, if you have an image of the desired concept,
              please upload it! We’ll create product images based on a concept similar to the
              reference background image you provided.
              <br />
              <br />
              <img
                src={`/static/images/board/board_17_4_en.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>We’ve improved harmonizing! </span> <br />{' '}
              Harmonizing is an automatic correction feature that reduces the sense of mismatch
              between the newly generated background and the original product image, creating
              harmony. Compared to before, we’ve applied Draph Art’s optimal harmonizing ratio to
              maintain the original product colors as much as possible while ensuring that the
              AI-generated background doesn’t look awkward next to it!
              <br />
              <br />
              <img
                src={`/static/images/board/board_17_5_en.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              Draph will continue to improve our services based on your feedback to ensure a more
              convenient user experience. Thank you!
            </DetaislTypo>
          </English>
        </>
      ),
    },
    {
      id: 16,
      title: t('board.board_16_title'),
      date: '24.06.25',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <Korean>
            <DetaislTypo>
              안녕하세요. 드랩입니다!
              <br />
              <br />
              드랩아트 6월 업데이트의 메인 키워드는
              <span style={{ fontWeight: 600 }}> ‘실사’</span>
              에요! <br />
              생성 배경 화질을 대폭 상향하여 실제 카메라로 찍은 듯 자연스럽고, 디테일한 사진 배경을
              얻으실 수 있어요! <br />
              지금 바로 우리 브랜드만의 감도 높은 상품 이미지를 드랩아트에서 만들어보세요!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>배경이 실사에 더 가까워졌어요!</span>
              <br />
              아무리 잘 만들어도 AI 특유의 부자연스러움이 느껴진다면 쇼핑몰 대표 이미지나 상세
              페이지에 사용하기에 꺼려지죠. 그래서 이미지의 배경이 실제 카메라로 찍은 사진처럼
              더욱더 사실적으로 만들어지도록 생성 AI 모델의 생성 배경 화질을 대폭 상향했어요!
              <br />
              <br />
              <img
                src={`/static/images/board/board_16_1.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              <span style={{ fontWeight: 600 }}>심도 표현을 개선했어요! </span> <br /> 이전까지는
              사진에 중심이 되는 ‘상품’이 더 부각될 수 있게 전체적으로 얕은 심도로 배경을
              만들었어요. 즉, 배경 대부분을 아웃포커싱하여 흐릿하게 보이게 했는데요, 그렇다 보니
              원근감이 제대로 반영되지 않아 아쉽다는 의견이 많았어요.
              <br />
              그래서 이번 업데이트에서는 상품과 배경 간의 원근감을 최대한 반영하여 상품과 가까우면
              더 또렷하게, 멀면 더 흐릿하게 배경이 생성되도록 개선했어요!
              <br />
              <br />
              <img
                src={`/static/images/board/board_16_2.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              <br />
              앞으로도 고객분들의 의견을 반영하여 더욱 편하게 드랩아트를 이용하실 수 있도록 계속해서
              서비스를 개선해나갈게요!
              <br /> 감사합니다!
            </DetaislTypo>
          </Korean>

          <English>
            <DetaislTypo>
              Hello, this is Draph!
              <br />
              <br />
              We have significantly updated Draph Art’s generative AI model, so you can obtain
              natural backgrounds that look like they were taken with a real camera and are close to
              real life! Check out what else has been updated!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                The background has become closer to real life!{' '}
              </span>
              <br />
              No matter how well-made, if you feel the unnaturalness to AI-generated creations, you
              may hesitate to use them as the main or detailed images in your online store. That’s
              why we updated Draph Art’s generative AI model to create backgrounds that look more
              realistic, as if taken with an actual camera.
              <br />
              Just take a look at the image where every petal is intricately made!
              <br />
              <br />
              <img
                src={`/static/images/board/board_16_1.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              <span style={{ fontWeight: 600 }}>We’ve improved depth expression! </span> <br />{' '}
              Previously, we created backgrounds with a shallow depth of field overall to make the
              ’product’ at the center of the photo, stands out more. In other words, most of the
              background was out of focus and blurred. However, we received a lot of feedback that
              this approach did not properly reflect a sense of depth.
              <br />
              So, in this update, we have improved the AI model to reflect the depth between the
              product and background more accurately. Now, the background is generated more clearly
              if it is closer to the product and blurrier if it is farther away!
              <br />
              <br />
              <img
                src={`/static/images/board/board_16_2.png`}
                style={{ padding: '0.6rem', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              <br />
              Draph will continue to improve our services based on your feedback to ensure a more
              convenient user experience. Thank you!
            </DetaislTypo>
          </English>
        </>
      ),
    },
    {
      id: 15,
      title: t('board.board_15_title'),
      date: '24.05.23',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <Korean>
            <DetaislTypo>
              안녕하세요. 드랩입니다!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                배경 생성, 포트폴리오 메뉴의 배경 테마 선택 기능
              </span>
              이 개선되었습니다. <br />
              <br />
              <img
                src={`/static/images/board/board_15_1_ko.jpg`}
                style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              <span style={{ fontWeight: 600 }}>기존: </span>AI, 직접 입력, 기본 테마, 시즌 테마 중
              선택
              <br />
              <span style={{ fontWeight: 600 }}>변경: </span>심플, 콘셉트 2개 테마 중 1개 선택.
              콘셉트를 선택한 경우 AI, 직접 입력, 기본 테마, 시즌 테마와 같이 상세 콘셉트 선택 가능
              <br />
              <br />
              선택한 테마에 따라 생성되는 이미지의 배경 종류와 지원되는 고급 설정은 아래와 같습니다.
              <br /> <br /> <span style={{ fontWeight: 600 }}>&lt;심플&gt;</span>
              <br />
              <span style={{ fontWeight: 600 }}>생성되는 이미지의 배경 종류 </span>
              <br />
              흰색 배경, 단색 배경, 깔끔한 스타일의 심플 배경
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>고급 설정 </span>
            </DetaislTypo>
            <Typography
              component="ul"
              sx={{
                marginLeft: '0.7rem',
                listStyleType: 'none',
                '& li': {
                  '&::before': {
                    content: '"-"',
                    marginRight: '0.3rem',
                  },
                },
              }}
            >
              <li>하나의 사물만 감지</li>
              <li>그림자 생성</li>
              <li>모델 얼굴 변경</li>
              <li>2개 이상의 이미지 사이즈 생성</li>
            </Typography>
            <br />
            <img
              src={`/static/images/board/board_15_2_ko.jpg`}
              style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
            />
            <DetaislTypo>
              <br />
              <span style={{ fontWeight: 600 }}>&lt;콘셉트&gt;</span>
              <br />
              <span style={{ fontWeight: 600 }}>생성되는 이미지의 배경 종류</span>
              <br />
              상세 콘셉트를 반영한 배경
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>고급 설정 </span>
            </DetaislTypo>
            <Typography
              component="ul"
              sx={{
                marginLeft: '0.7rem',
                listStyleType: 'none',
                '& li': {
                  '&::before': {
                    content: '"-"',
                    marginRight: '0.3rem',
                  },
                },
              }}
            >
              <li>하나의 사물만 감지</li>
              <li>모델 얼굴 변경</li>
              <br />
            </Typography>{' '}
            <img
              src={`/static/images/board/board_15_3_ko.jpg`}
              style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
            />
            <br />
            <DetaislTypo>
              테마 선택 기능이 개선되면서 기존 ‘배경 종류’ 선택 옵션은 사라지게 되었습니다. 이에
              기존에 설정한 배경 종류는 아래와 같이 변경되어 적용됩니다. <br /> <br />
              <span style={{ fontWeight: 600 }}>기존에 생성 배경 또는 사진</span>
              배경이 1개라도 선택되어 있는 경우
              <br /> → 배경 테마가 <span style={{ fontWeight: 600 }}>‘콘셉트’</span>로 선택
              <br />
              <br /> <span style={{ fontWeight: 600 }}>그 외의 경우</span>
              <br /> → 배경 테마가 <span style={{ fontWeight: 600 }}>‘심플’</span>로 선택 <br />
              <br />
              앞으로도 드랩아트는 고객분들의 의견을 반영하여 더욱 편하게 서비스를 이용하실 수 있도록
              개선을 진행하겠습니다. 감사합니다!
            </DetaislTypo>
          </Korean>

          <English>
            <DetaislTypo>
              Hello, this is Draph!
              <br />
              <br />
              We&apos;ve improved the background theme selection feature in the BG Generation and
              Portfolio menus. <br />
              <br />
              <img
                src={`/static/images/board/board_15_1_en.jpg`}
                style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
              />
              <br />
              <span style={{ fontWeight: 600 }}>Previous: </span>Options were AI, Custom Input,
              Default Themes, and Seasonal Themes.
              <br />
              <span style={{ fontWeight: 600 }}>Updated: </span>You can now choose between two
              themes: Simple and Concept. If you select Concept, you can further choose from
              detailed options like AI, Custom Input, Default Themes, and Seasonal Themes.
              <br />
              <br />
              The types of backgrounds generated and the available advanced settings based on the
              chosen theme are as follows:
              <br /> <br /> <span style={{ fontWeight: 600 }}>&lt;Simple&gt;</span>
              <br />
              <span style={{ fontWeight: 600 }}>Types of backgrounds generated: </span>
              <br />
              White background, Solid color background, Clean-style simple background.
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>Advanced settings: </span>
            </DetaislTypo>
            <Typography
              component="ul"
              sx={{
                marginLeft: '0.7rem',
                listStyleType: 'none',
                '& li': {
                  '&::before': {
                    content: '"-"',
                    marginRight: '0.3rem',
                  },
                },
              }}
            >
              <li>Single object detection</li>
              <li>Generate shadows </li>
              <li>Model face swap</li>
              <li>Generate images in more than two sizes</li>
            </Typography>
            <br />
            <img
              src={`/static/images/board/board_15_2_en.jpg`}
              style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
            />
            <DetaislTypo>
              <br />
              <span style={{ fontWeight: 600 }}>&lt;Concept&gt;</span>
              <br />
              <span style={{ fontWeight: 600 }}>Types of backgrounds generated: </span>
              <br />
              Backgrounds reflecting the detailed concept.
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>Advanced settings: </span>
            </DetaislTypo>
            <Typography
              component="ul"
              sx={{
                marginLeft: '0.7rem',
                listStyleType: 'none',
                '& li': {
                  '&::before': {
                    content: '"-"',
                    marginRight: '0.3rem',
                  },
                },
              }}
            >
              <li>Single object detection</li>
              <li>Model face swap</li>
              <br />
            </Typography>{' '}
            <img
              src={`/static/images/board/board_15_3_en.jpg`}
              style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
            />
            <br />
            <DetaislTypo>
              With the improvement of the theme selection feature, the previous &apos;Background
              Types&apos; selection option has been removed. Accordingly, existing background types
              will be applied as follows: <br /> <br />
              <span style={{ fontWeight: 600 }}>
                If either Generated Background or Photo Background was previously selected:{' '}
              </span>
              <br /> → The background theme will be set to{' '}
              <span style={{ fontWeight: 600 }}>&apos;Concept&apos;</span>
              <br />
              <br /> <span style={{ fontWeight: 600 }}>In all other cases: </span>
              <br /> → The background theme will be set to{' '}
              <span style={{ fontWeight: 600 }}>&apos;Simple&apos;</span> <br />
              <br />
              Draph will continue to improve our services based on your feedback to ensure a more
              convenient user experience. Thank you!
            </DetaislTypo>
          </English>
        </>
      ),
    },
    {
      id: 14,
      title: t('board.board_14_title'),
      date: '24.04.26',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <DetaislTypo>
            <Korean>
              안녕하세요. 드랩입니다!
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>
                광고 문구와 배너 이미지를 자동으로 만들어주는 광고 배너 기능
              </span>
              이 업데이트되었습니다. 제품 이름과 컨셉만 입력하면 우리 제품에 딱 맞는 광고 문구가
              들어간 배너 이미지가 자동으로 만들어집니다. <br />
              <br />
              광고 배너 기능은 이용할 때마다 <span style={{ fontWeight: 600 }}>1크레딧이 차감</span>
              되며, <span style={{ fontWeight: 600 }}>총 4종류의 다양한 배너 이미지</span>가
              생성됩니다.
              {/* (단, 재생성은 무료로 이용 가능)  */}
              <br />
              <br />
              온라인 광고를 돌리고 싶은데 배너 이미지가 없어서 못 돌리셨다면, 지금 바로 드랩아트의
              광고 배너 기능을 사용해 보세요!
              <br /> <br />
            </Korean>

            <English>
              Hello, this is Draph! <br />
              <br />{' '}
              <span style={{ fontWeight: 600 }}>
                Our “Banner Ads” feature, which automatically generates ads copy and banner images,
                has been updated.
              </span>{' '}
              Simply put in the name and concept of your product to get a banner ad that is
              perfectly tailored for your product. <br />
              <br /> Each Banner Ad generation will{' '}
              <span style={{ fontWeight: 600 }}>deduct 1 credit</span> and you’ll be provided with{' '}
              <span style={{ fontWeight: 600 }}>a total of 4 different banner images.</span>
              {/* (However, regeneration is available for free)  */}
              <br />
              <br /> If you hesitated on running online ads because you didn’t have a banner image,
              try Draph Art’s new feature “Banner Ads” right now!
              <br /> <br />
            </English>

            <img
              src={`/static/images/board/board_banner_01_${getStaticResourceLanguage(
                i18n.language
              )}.jpg`}
              style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
            />

            <br />
            <br />

            <img
              src={`/static/images/board/board_banner_02_${getStaticResourceLanguage(
                i18n.language
              )}.jpg`}
              style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
            />
          </DetaislTypo>
        </>
      ),
    },
    {
      id: 13,
      title: t('board.board_13_title'),
      date: '24.04.22',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <DetaislTypo>
            {t('board.board_13_comment_1')}
            <br />
            <br />
            {t('board.board_13_comment_2')}
            <br />
            <br />
            <span style={{ fontWeight: 600 }}>{t('board.board_13_comment_3')}</span>
            <br />
            {t('board.board_13_comment_4')}
            <br />
            <br />
            <span style={{ fontWeight: 600 }}>{t('board.board_13_comment_5')}</span>
            <br />
            {t('board.board_13_comment_6')}
            <br />
            <br />
          </DetaislTypo>
          <ul>
            <li style={{ marginLeft: '2rem' }}>{t('board.board_13_comment_7')}</li>
            <ul style={{ marginLeft: '3rem' }}>
              <li>{t('board.board_13_comment_8_a')}</li>
              <li>{t('board.board_13_comment_8_b')}</li>
              <li>{t('board.board_13_comment_8_c')}</li>
              <li>{t('board.board_13_comment_8_d')}</li>
            </ul>
            <br />
            <li style={{ marginLeft: '2rem' }}>{t('board.board_13_comment_9')}</li>
            <ul style={{ marginLeft: '3rem' }}>
              <li>{t('board.board_13_comment_10_a')}</li>
              <li>{t('board.board_13_comment_10_b')}</li>
              <li>{t('board.board_13_comment_10_c')}</li>
            </ul>
          </ul>
        </>
      ),
    },
    {
      id: 12,
      title: t('board.board_12_title'),
      date: '23.12.21',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <DetaislTypo>
            {t('board.board_12_comment_1')}
            <br />
            <br />
            {t('board.board_12_comment_2')}
            <br />
            <br />
            {t('board.board_12_comment_3')}
            <br />
            <span style={{ fontWeight: 600 }}>{t('board.board_12_comment_4')}</span>
            <br />
            {t('board.board_12_comment_5')}
            <br />
            <br />
          </DetaislTypo>

          <img
            src="/static/images/board/board_231221_1.jpg"
            style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
          />

          <DetaislTypo>
            <br />
            <span style={{ fontWeight: 600 }}>{t('board.board_12_comment_6')}</span>
            <Korean>
              <br />
              <span style={{ fontWeight: 600 }}>{t('board.board_12_comment_7_a')}</span>
              {t('board.board_12_comment_7_b')}
            </Korean>
            <br />
            <span style={{ fontWeight: 600 }}>{t('board.board_12_comment_8_a')}</span>
            {t('board.board_12_comment_8_b')}
            <br />
            <br />
          </DetaislTypo>

          <img
            src="/static/images/board/board_231221_2.jpg"
            style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
          />

          <DetaislTypo>
            <br />
            <span style={{ fontWeight: 600 }}>{t('board.board_12_comment_9')}</span>
            <br />
            {t('board.board_12_comment_10')}
            <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_12_comment_11_a')}</span>
            {t('board.board_12_comment_11_b')}
            <br />
            {t('board.board_12_comment_12')}
            <br />
            <br />
          </DetaislTypo>

          <img
            src="/static/images/board/board_231221_3.jpg"
            style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
          />

          <DetaislTypo>
            <br />
            <span style={{ fontWeight: 600 }}>{t('board.board_12_comment_13')}</span>
            <br />
            <span style={{ fontWeight: 600 }}>{t('board.board_12_comment_14')}</span>
            <br />
            {t('board.board_12_comment_15')} <br />
            <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_12_comment_16_a')}</span>{' '}
            {t('board.board_12_comment_16_b')}
            <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_12_comment_17_a')}</span>{' '}
            {t('board.board_12_comment_17_b')}
            <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_12_comment_18_a')}</span>{' '}
            {t('board.board_12_comment_18_b')}
            <br />
            <br />
          </DetaislTypo>

          <img
            src="/static/images/board/board_231221_4.jpg"
            style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
          />
        </>
      ),
    },
    {
      id: 11,
      title: t('board.board_11_title'),
      date: '23.12.01',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <DetaislTypo>
            {t('board.board_11_comment_1')}
            <br />
            <br />
            {t('board.board_11_comment_2')}
            <br />
            {t('board.board_11_comment_3')}
            <br />
            <br />
          </DetaislTypo>
          <div style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
            <img src="/static/images/board/board_theme.jpeg" />
          </div>
          <DetaislTypo>
            <br />
            {t('board.board_11_comment_4')}
            <br />
            <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_11_comment_5_a')}</span>{' '}
            {t('board.board_11_comment_5_b')}
            <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_11_comment_5_c')}</span>{' '}
            {t('board.board_11_comment_5_d')}
            <br />
            <span style={{ textIndent: '1rem', listStyleType: 'none' }}>
              <span style={{ fontWeight: 600 }}> {t('board.board_11_comment_5_e')}</span>{' '}
              {t('board.board_11_comment_5_f')}
            </span>
            <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_11_comment_5_g')}</span>{' '}
            {t('board.board_11_comment_5_h')}
            <br />
            <br />
            {t('board.board_11_comment_6')}
            <br />
          </DetaislTypo>
        </>
      ),
    },
    {
      id: 10,
      title: t('board.board_10_title'),
      date: '23.11.23',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <DetaislTypo>
            {t('board.boadr_10_comment_1')} <br />
            <br />
            <span style={{ fontWeight: 600 }}>{t('board.boadr_10_comment_2_a')}</span>
            {t('board.boadr_10_comment_2_b')} <br />
            <br />
            {t('board.boadr_10_comment_3')}
            <span style={{ fontWeight: 600 }}>{t('board.boadr_10_comment_4_a')}</span>
            {t('board.boadr_10_comment_4_b')} <br />
            <br />
          </DetaislTypo>

          <div>
            <img
              src="/static/images/board/board_dragdrop_1.jpeg"
              style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
            />
          </div>
          <DetaislTypo>
            <br />
            {t('board.boadr_10_comment_5')} <br />
            <br />
          </DetaislTypo>
          <div style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
            <img src="/static/images/board/board_dragdrop_2.jpeg" />
          </div>
          <DetaislTypo>
            <br />
            {t('board.boadr_10_comment_6')} <br />
          </DetaislTypo>
        </>
      ),
    },
    {
      id: 9,
      title: t('board.board_9_title'),
      date: '23.11.06',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <DetaislTypo>
            {t('board.board_9_comment_1')} <br />
            <br />
            <span style={{ fontWeight: 600 }}>{t('board.board_9_comment_2_a')}</span>
            {t('board.board_9_comment_2_b')}
            <br />
            {t('board.board_9_comment_3')}
            <br />
            <br />
            {t('board.board_9_comment_4')}
            <span style={{ fontWeight: 600 }}> {t('board.board_9_comment_5_a')}</span>
            {t('board.board_9_comment_5_b')}
            <span style={{ fontWeight: 600 }}> {t('board.board_9_comment_5_c')}</span>
            {t('board.board_9_comment_5_d')}
            <span style={{ fontWeight: 600 }}> {t('board.board_9_comment_5_e')}</span>
            {t('board.board_9_comment_5_f')}
            <br />
            <br />
            {t('board.board_9_comment_6')}
            <br />
            <br />
          </DetaislTypo>

          <div style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
            <img src="/static/images/board/board_mannequin.jpeg" />
          </div>
          <br />
          <br />
          <Link
            to="https://blog.naver.com/draph_ai/223266272189"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'unset',
              textDecoration: 'none',
              fontSize: isMobile ? '1.3rem' : '1.6rem',
            }}
          >
            {t('board.board_9_comment_7')}
          </Link>
          <br />
          <Link
            to="/model/mannequin?pages=1"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'unset',
              textDecoration: 'none',
              fontSize: isMobile ? '1.3rem' : '1.6rem',
            }}
          >
            {t('board.board_9_comment_8')}
          </Link>
          <br />
        </>
      ),
    },
    {
      id: 8,
      title: t('board.board_8_title'),
      date: '23.10.30',
      allowedLangs: ['ko'],
      details: (
        <>
          <DetaislTypo>
            안녕하세요. 드랩입니다! <br />
            <br />
            드랩아트를 이용해 주시는 분들께 진심으로 감사드립니다.
            <br />
            <br />
            드랩은 지난 7월 드랩아트 서비스 출시 이후 지속적인 업데이트와 기능 강화로 고객분들의
            편의성 증대에 노력해 왔습니다. 더불어 합리적인 요금 정책으로 고객분들의 부담을 덜어
            드리고자 항상 고민해 왔습니다.
            <br />
            <br />
            이에 2023년 10월 30일부터 요금제 개편을 통해 새로운 요금제를 시행하게 되었습니다.
            업그레이드된 기능과 경제적인 요금을 통해 드랩아트에서 다채롭고 매력적인 상품 사진을
            생성해 보시길 바랍니다.
            <br />
            <br />- 기존 월정액 구독 중인 고객님은 결제한 가격 기준으로 개편 요금제가 적용됩니다.
            <br />
            예시) 기존에 19,900원으로 월정액 35 구독 중인 경우, 동일 가격의 개편 요금인 월정액 75로
            변경 → 월정액 75의 경우 75 크레딧이 매월 충전되기 때문에 개편 요금제에 맞춰 40 크레딧이
            계정에 추가 충전
          </DetaislTypo>

          <br />
          <br />
          <Link
            to="/user/mypage"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'unset',
              textDecoration: 'none',
              fontSize: isMobile ? '1.3rem' : '1.6rem',
            }}
          >
            ▶ 현재 구독 중인 요금제 확인하기
          </Link>
          <br />
          <Link
            to="/pricing"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'unset',
              textDecoration: 'none',
              fontSize: isMobile ? '1.3rem' : '1.6rem',
            }}
          >
            ▶ 개편된 요금제 확인하기
          </Link>
          <br />
        </>
      ),
    },
    {
      id: 7,
      title: t('board.board_7_title'),
      date: '23.10.18',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <DetaislTypo>
            {t('board.board_7_comment_1')}
            <br />
            <br />
            {t('board.board_7_comment_2_a')}
            <span style={{ fontWeight: 600 }}> {t('board.board_7_comment_2_b')}</span>{' '}
            {t('board.board_7_comment_2_c')}
            <br />
            {t('board.board_7_comment_3')}
            <br />
            <br />
            {t('board.board_7_comment_4')}
            <br />
            <br />
          </DetaislTypo>

          <div style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
            <img src="/static/images/board/board_GIF.jpg" />
          </div>
          <br />
          <Link
            to="https://blog.naver.com/draph_ai/223251478148"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'unset',
              textDecoration: 'none',
              fontSize: isMobile ? '1.3rem' : '1.6rem',
            }}
          >
            {t('board.board_7_comment_5')}
          </Link>
        </>
      ),
    },
    {
      id: 6,
      title: t('board.board_6_title'),
      date: '23.10.18',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <DetaislTypo>
            {t('board.board_6_comment_1')}
            <br />
            <br />
            {t('board.board_6_comment_2')}
            <br /> {t('board.board_6_comment_3')}
            <br />
            <br /> {t('board.board_6_comment_4')}
            <br />
            {t('board.board_6_comment_5')}
            <br />
            <br /> {t('board.board_6_comment_6')}
            <br /> {t('board.board_6_comment_7')}
            <br />
            <br /> {t('board.board_6_comment_8')}
            <br /> {t('board.board_6_comment_9')}
            <br /> <br /> {t('board.board_6_comment_10')}
            <br />
            <br />
          </DetaislTypo>
          <Link
            to="/generate/removebg?pages=1"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'unset',
              textDecoration: 'none',
              fontSize: isMobile ? '1.3rem' : '1.6rem',
            }}
          >
            {t('board.board_6_comment_11')}
          </Link>{' '}
        </>
      ),
    },
    {
      id: 5,
      title: t('board.board_5_title'),
      date: '23.09.27',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <DetaislTypo>
            {t('board.board_5_comment_1')}
            <br />
            <br />
            <span style={{ fontWeight: 600 }}>{t('board.board_5_comment_2_a')}</span>
            {t('board.board_5_comment_2_b')}

            <br />
            {t('board.board_5_comment_3')}

            <br />
            <br />
            {t('board.board_5_comment_4')}

            <br />
            <br />
          </DetaislTypo>

          <Link
            to="/portfolio/upload?pages=1"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'unset',
              textDecoration: 'none',
              fontSize: isMobile ? '1.3rem' : '1.6rem',
            }}
          >
            {t('board.board_5_comment_5')}
          </Link>
          <br />
          <Link
            to="/portfolio?pages=1"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'unset',
              textDecoration: 'none',
              fontSize: isMobile ? '1.3rem' : '1.6rem',
            }}
          >
            {t('board.board_5_comment_6')}
          </Link>
          <br />
        </>
      ),
    },
    {
      id: 4,
      title: `${titlePrefix} ${t('board.board_4_title')}`,
      date: '23.09.01',
      allowedLangs: ['ko'],
      details: (
        <>
          <DetaislTypo>
            안녕하세요. 드랩입니다! <br />
            <br />
            드랩아트 튜토리얼을 처음부터 끝까지 완료한 회원 중 200명을 추첨하여 스타벅스 모바일
            쿠폰을 드립니다! 자세한 내용은 아래를 확인해 주세요. <br />
            <br />■ 이벤트 기간 <br />~ 2023년 8월 31일까지
            <br /> <br />
            ■ 이벤트 대상
            <br />
            드랩아트에 처음 로그인 했을 때 뜨는 튜토리얼을 끝까지 완료하고, 회원정보에 휴대폰번호를
            입력한 회원
            <br />
            <br />
            ■ 이벤트 경품 / 당첨자수 <br />
            스타벅스 아메리카노(Tall) 모바일 쿠폰 / 200명
            <br />
            <br />
            ■ 당첨자 발표 <br />
            당첨자에 한해 2023년 9월 7일 회원 정보에 입력되어 있는 휴대폰번호로 모바일 쿠폰 발송
            <br />
            <br />
            ■ 유의사항
            <br />
          </DetaislTypo>

          <ul
            style={{
              marginLeft: isMobile ? '1.6rem' : '2.2rem',
              fontSize: isMobile ? '1.3rem' : '1.6rem',
            }}
          >
            <li>
              이벤트 기간 내 드랩아트에 회원가입하고 이벤트 대상에 충족되면 자동으로 이벤트에
              참여됩니다.
            </li>{' '}
            <li> 회원정보에 휴대폰 번호가 없는 경우 당첨 대상에서 제외됩니다.</li>
            <li>
              잘못된 회원정보로 인한 당첨자 발표 미수신 및 경품 오발송 등에 대해서 당사는 책임지지
              않습니다.{' '}
            </li>
            <li> ‘회원정보 수정’에 등록된 휴대폰 번호를 확인해 주시기 바랍니다. </li>
            <li>
              {' '}
              내부 사정에 따라 이벤트 기간, 경품 종류, 당첨자 발표, 경품 발송일 등이 변경될 수
              있습니다.
            </li>{' '}
            <br />
          </ul>
        </>
      ),
    },
    {
      id: 3,
      title: `${titlePrefix} ${t('board.board_3_title')}`,
      date: '23.07.24',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <DetaislTypo>
            {t('board.board_3_comment_1')}
            <br />
            <br />
            {t('board.board_3_comment_2')}
            <br />
            <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_3_comment_3')}</span>{' '}
            {t('board.board_3_comment_4')}
            <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_3_comment_5')}</span>{' '}
            {t('board.board_3_comment_6')}
            <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_3_comment_7')}</span>{' '}
            {t('board.board_3_comment_8')}
            <br />
            <br />
          </DetaislTypo>
          <Link
            to="/promotion/openevent"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'unset',
              textDecoration: 'none',
              fontSize: isMobile ? '1.3rem' : '1.6rem',
            }}
          >
            {t('board.board_3_comment_9')}
          </Link>{' '}
        </>
      ),
    },

    {
      id: 2,
      title: `${titlePrefix} ${t('board.board_2_title')} `,
      date: '23.07.24',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <DetaislTypo>
            {t('board.board_2_comment_1')}
            <br /> <br />
            {t('board.board_2_comment_2')}
            <br /> <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_2_comment_3')}</span>{' '}
            {t('board.board_2_comment_4')}
            <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_2_comment_5')}</span>{' '}
            {t('board.board_2_comment_6')}
            <br />
            <span style={{ fontWeight: 600 }}> {t('board.board_2_comment_7')}</span>{' '}
            {t('board.board_2_comment_8')}
            <br />
            <br />
          </DetaislTypo>
          <Link
            to="/portfolio"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'unset',
              textDecoration: 'none',
              fontSize: isMobile ? '1.3rem' : '1.6rem',
            }}
          >
            {t('board.board_2_comment_9')}
          </Link>{' '}
        </>
      ),
    },
    {
      id: 1,
      title: t('board.board_1_title'),
      date: '23.07.24',
      allowedLangs: ['ko', 'en'],
      details: (
        <>
          <DetaislTypo>
            {t('board.board_1_comment_1')}
            <br />
            <br /> {t('board.board_1_comment_2')}
            <br /> <br />
            {t('board.board_1_comment_3')}
            <br />
            <br /> {t('board.board_1_comment_4')}
            <br />
            <br /> {t('board.board_1_comment_5')}
          </DetaislTypo>
        </>
      ),
    },
  ]

  const makeId = posts => {
    return posts.map((p, i) => {
      return { ...p, id: posts.length - i }
    })
  }

  const makePost = (allPosts, i18n) => {
    // 예: i18n.language === 'ko' or 'en' or 'ja'
    const { language } = i18n

    // 현재 language가 allowedLangs에 들어있는 포스트만 남긴다
    const filteredPosts = allPosts.filter(post => post.allowedLangs.includes(language))

    // 역순 아이디 부여
    return makeId(filteredPosts)
  }

  const posts = makePost(allPosts, i18n)
  const [page, setPage] = useState(1)
  const postsPerPage = 10
  const startIndex = (page - 1) * postsPerPage
  const endIndex = startIndex + postsPerPage
  const visiblePosts = posts.slice(startIndex, endIndex)
  const [expanded, setExpanded] = useState(false)

  const handleChange = id => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false)
  }

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const Post = ({ id, title, date, details }) => {
    return (
      <>
        <CustomAccordion
        // expanded={expanded === id} onChange={handleChange(id)}
        >
          <CustomAccordionSummary>
            <CenterAlignBox sx={{ flexGrow: 1 }}>
              <FlexBasis
                flexBasis={['15%', '70%', '15%']}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <Typography sx={postStyle}>{id}</Typography>
                <Typography
                  sx={{
                    textAlign: 'start',
                    width: '100%',
                    ml: { lg: '3rem', xs: 0 },
                    ...postStyle,
                  }}
                >
                  {title}
                </Typography>
                <Typography sx={postStyle}>{date}</Typography>
              </FlexBasis>
              <Divider />
            </CenterAlignBox>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <Box sx={{ px: '5%', py: '3%' }}>{details}</Box>
          </CustomAccordionDetails>
        </CustomAccordion>
      </>
    )
  }

  const MobilePost = ({ id, title, date, details }) => {
    return (
      <>
        <CustomAccordion sx={{ width: '100%' }}>
          <CustomAccordionSummary
            expandIcon={<DropdownArrowIcon color="black" />}
            sx={{
              mr: '2rem',
              height: '6.4rem',
              '&.Mui-expanded': {
                borderTop: '1px solid rgb(128, 128, 128, 0.4)',
              },
            }}
          >
            <CenterAlignBox
              sx={{
                display: 'flex',
                mr: '2rem',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <FlexBasis flexBasis={['15%', '85%']}>
                <Typography sx={{ fontWeight: 500, fontSize: '1.3rem', textAlign: 'center' }}>
                  {id}
                </Typography>
                <Box>
                  <Typography sx={{ fontWeight: 500, fontSize: '1.3rem' }}>{title}</Typography>
                  <Typography sx={{ fontWeight: 400, fontSize: '1.2rem', color: '#989898' }}>
                    {date}
                  </Typography>
                </Box>
              </FlexBasis>
            </CenterAlignBox>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <Box sx={{ px: '5rem', py: '3.5rem' }}>{details}</Box>
          </CustomAccordionDetails>
        </CustomAccordion>
      </>
    )
  }

  return (
    <>
      <Desktop>
        <CenterAlignStack sx={{ width: INFO_PERCENTAGE_WIDHT, pl: MYPAGE_SIDE_MARGIN }}>
          <Box
            sx={{
              height: '100%',
              width: '88.4rem',
              minHeight: '57rem',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FlexBasis flexBasis={['15%', '64%', '19%']}>
              <Box>
                <CenterAlignBox sx={{ justifyContent: 'space-between' }}>
                  <Typography sx={{ fontWeight: 800, fontSize: '3rem', pb: '3.4rem' }}>
                    {t('info_side_menu.notice')}
                  </Typography>
                  {/* {id === 2 && <Button>asdf</Button>} */}
                </CenterAlignBox>
                <Divider sx={{ border: `3px solid ${theme.palette.common.black}` }} />
                <CenterAlignBox sx={{ height: '5.8rem' }}>
                  <FlexBasis
                    flexBasis={['15%', '70%', '15%']}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Typography sx={headerStyle}>{t('board.number')}</Typography>
                    <Typography sx={headerStyle}>{t('board.title')}</Typography>
                    <Typography sx={headerStyle}>{t('board.date')}</Typography>
                  </FlexBasis>
                </CenterAlignBox>
                <Divider sx={{ border: `1px solid ${theme.palette.common.black}` }} />
              </Box>
              <Box sx={{ minHeight: '7.7rem' }}>
                {visiblePosts.map((post, idx) => {
                  return (
                    <Post
                      key={idx}
                      id={post.id}
                      title={post.title}
                      date={post.date}
                      details={post.details}
                    />
                  )
                })}

                {/* <Grid container spacing={2}>
        {visiblePosts.map(post => (
          <Grid item xs={12} key={post.id}>
            <Post title={post.title} />
          </Grid>
        ))}
      </Grid> */}
              </Box>
              <CenterAlignStack
                sx={{
                  pt: '5.5rem',
                  mb: '3rem',
                }}
              >
                {posts.length > postsPerPage && (
                  <CustomPagination
                    count={Math.ceil(posts.length / postsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                  />
                )}
              </CenterAlignStack>
            </FlexBasis>
          </Box>
        </CenterAlignStack>
      </Desktop>

      <Mobile>
        <CenterAlignStack
          sx={{
            mb: '6.4rem',
            minHeight: `calc(100vh - ${MYPAGE_APPBAR_MOBILE} - ${APPBAR_MOBILE})`,
          }}
        >
          <Typography sx={{ pt: '3.4rem', mb: '2.4rem', fontSize: '2.4rem', fontWeight: 800 }}>
            {t('info_side_menu.notice')}
          </Typography>
          {posts.map((post, idx) => {
            return (
              <MobilePost
                key={idx}
                id={post.id}
                title={post.title}
                date={post.date}
                details={post.details}
              />
            )
          })}
        </CenterAlignStack>
      </Mobile>
    </>
  )
}
