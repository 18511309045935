import { useEffect, useState, useMemo } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import QuillToolbar, { formats, modules } from './Toolbar'
import './TestPage10.css'
import { apis } from 'apis'
import { set } from 'lodash'
import { useArtworkPolling, useArtworkQuery } from 'hooks/useReactQuery'
import { useRecoilValue } from 'recoil'
import { userAtom } from 'atoms'
import * as config from 'config'

// 폴링 주기 (밀리초 단위)
const POLLING_INTERVAL = 5000 // 5초마다 폴링
const P_ID = '1241' // 5초마다 폴링
const CDN_URL = 'https://d1p0kjf7jiqoy5.cloudfront.net/'
// const P_ID = '3295' // 5초마다 폴링

// 완료 상태와 진행 중 상태를 정의 (예시)
// const ARTWORK_DONE_STATUS = 'DONE'
// const ARTWORK_IN_PROGRESS_STATUS = ['IN_PROGRESS', 'PENDING']
// const ARTWORK_ERROR_STATUS = ['ERROR']

function TestPage10() {
  const [value, setValue] = useState('') // 에디터 내용
  const [inputText, setInputText] = useState('') // 왼쪽 텍스트 입력값
  const [inputImage, setinputImage] = useState(new Uint8Array([])) // 빈 바이트 배열 초기값
  const [imageIndex, setImageIndex] = useState(1) // 현재 삽입할 이미지 번호

  const [purpose, setPurpose] = useState('review') // 목적
  const [contentType, setContentType] = useState('blog') // 콘텐츠 종류
  const [tone, setTone] = useState('polite') // 어투

  const [pName, setPName] = useState('') // 제품명
  const [pCategory, setPCategory] = useState('') // 제품 카테고리
  const [pDesc, setPDesc] = useState('') // 제품 설명
  const [bName, setBName] = useState('') // 브랜드명
  const [bDesc, setBDesc] = useState('') // 브랜드 설명

  const [selectedPieceIdx, setSelectedPieceIdx] = useState(0)
  const [selectedPiece, setSelectedPiece] = useState(null)
  var textGenDone = false

  const user = useRecoilValue(userAtom)

  const [portfolioDetail, setPortfolioDetail] = useState(null)

  useEffect(() => {
    apis.portfolio.getDefaultPortfolio('banner2').then(r => {
      setPortfolioDetail(r.data)
    })
  }, [])

  const {
    data: artworks,
    refetch: refetchArtworks,
    isLoading,
  } = useArtworkQuery({
    portfolioId: portfolioDetail?.id,
    enabled: !!portfolioDetail, // portfolioDetail이 있을 때만 쿼리 활성화
  }).getArtworks

  const [artwork, setArtwork] = useState({
    status: 'done',
  })

  const shouldPoll = useMemo(() => {
    if (!portfolioDetail) return false // portfolioDetail이 없으면 폴링하지 않음
    console.log(artwork.status)
    return config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)
  }, [artwork.status, portfolioDetail])

  const { individualArtwork, error, modPieceError, refetch } = useArtworkPolling(
    portfolioDetail?.id, // optional chaining 사용
    artwork.id,
    shouldPoll && !!portfolioDetail // portfolioDetail이 있고 shouldPoll이 true일 때만 폴링
  )

  const artworkDone = config.ARTWORK_DONE_STATUS === artwork.status
  const artworkError = config.ARTWORK_ERROR_STATUS.includes(artwork.status)
  const artworkLoading =
    (!artworkDone && !artworkError) || config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)
  useEffect(() => {
    if (individualArtwork) {
      console.log('individualArtwork:', individualArtwork)
      setArtwork(individualArtwork)
      // LLM 추천 텍스트 가져오기
      const obj = JSON.parse(individualArtwork?.config)
      if (obj.llm_recomm_text) {
        setValue(obj.llm_recomm_text.replace(/\n/g, '<br />'))
      }
    }
  }, [individualArtwork])

  useEffect(() => {
    if (individualArtwork?.pieces?.length > 0) {
      setArtwork(individualArtwork)
      setValue(prevText => {
        let updatedText = prevText

        individualArtwork.pieces.forEach((piece, index) => {
          const placeholder = `(image${index})`
          const imgTag = `<img src="${CDN_URL}${piece.path}" alt="uploaded image" />`

          if (updatedText.includes(placeholder)) {
            updatedText = updatedText.replaceAll(placeholder, imgTag)
          }
        })

        console.log('Updated Text with Images:', updatedText)
        return updatedText
      })
    }
  }, [individualArtwork?.pieces])

  // 이미지 업로드 처리 함수
  const handleImageUpload = event => {
    const file = event.target.files[0]

    const reader = new FileReader()

    reader.onloadend = () => {
      const arrayBuffer = reader.result
      const byteArray = new Uint8Array(arrayBuffer) // 바이트 배열로 변환

      // 바이트 배열을 상태에 저장
      setinputImage(file)
      // 바이트 배열 로그 출력
      console.log('이미지 바이트 배열 저장 완료: ', byteArray)
    }

    if (file) {
      reader.readAsArrayBuffer(file) // ArrayBuffer로 파일 읽기
    }
  }

  // API 요청 더미 함수
  const handleSubmit = async () => {
    if (!portfolioDetail) {
      console.log('포트폴리오 정보가 아직 로드되지 않았습니다.')
      return
    }

    const dataObject = {
      p_name: pName,
      p_category: pCategory,
      p_desc: pDesc,
      b_name: bName,
      b_desc: bDesc,
      purpose: purpose,
      target: contentType,
      nuance: tone,
    }

    const dataString = JSON.stringify(dataObject)

    const formData = new FormData()

    const user = (await apis.user.getUser()).data

    // 텍스트 데이터 추가
    formData.append('user_id', user.id)
    formData.append('portfolio_id', portfolioDetail.id)
    formData.append('username', user.username)
    formData.append('gen_options', dataString)

    // 이미지 파일 추가 (여기서 imageQueue는 업로드한 이미지 배열)
    console.log('inputImage: ', inputImage)

    formData.append('images', inputImage)

    apis.appfront
      .contentGen(formData)
      .then(r => {
        console.log('Response:', r) // 리스판스 출력
        console.log('Response:', r.data.artwork_id) // 리스판스 출력
        refetchArtworks()
        // setSelectedPieceIdx(0)
        setValue('')
        apis.portfolio.getArtwork(portfolioDetail.id, r.data.artwork_id).then(r => {
          console.log(r.data)
          setArtwork(r.data)
        })
      })
      .finally(() => {
        console.log('req done') // 리스판스 출력
      })
  }

  // 에디터 내용을 HTML로 복사하는 함수
  const copyToClipboardAsHTML = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        alert('에디터의 내용이 HTML 형식으로 복사되었습니다.')
      })
      .catch(err => {
        console.error('복사에 실패했습니다.', err)
      })
  }

  return (
    <div className="container">
      <div className="left-side">
        {/* 제품명 입력 */}
        <label>제품명 (p_name)</label>
        <input
          type="text"
          value={pName}
          onChange={e => setPName(e.target.value)}
          placeholder="제품명을 입력하세요"
        />

        {/* 제품 카테고리 입력 */}
        <label>제품 카테고리 (p_category)</label>
        <input
          type="text"
          value={pCategory}
          onChange={e => setPCategory(e.target.value)}
          placeholder="제품 카테고리를 입력하세요"
        />

        {/* 제품 설명 입력 */}
        <label>제품 설명 (p_desc)</label>
        <input
          type="text"
          value={pDesc}
          onChange={e => setPDesc(e.target.value)}
          placeholder="제품 설명을 입력하세요"
        />

        {/* 브랜드명 입력 */}
        <label>브랜드명 (b_name)</label>
        <input
          type="text"
          value={bName}
          onChange={e => setBName(e.target.value)}
          placeholder="브랜드명을 입력하세요"
        />

        {/* 브랜드 설명 입력 */}
        <label>브랜드 설명 (b_desc)</label>
        <input
          type="text"
          value={bDesc}
          onChange={e => setBDesc(e.target.value)}
          placeholder="브랜드 설명을 입력하세요"
        />

        {/* 목적 선택 */}
        <label>목적</label>
        <select value={purpose} onChange={e => setPurpose(e.target.value)}>
          <option value="review">리뷰</option>
          <option value="info">정보</option>
          <option value="life">일상</option>
          <option value="detail">상세페이지</option>
        </select>

        {/* 콘텐츠 종류 선택 */}
        <label>콘텐츠 종류</label>
        <select value={contentType} onChange={e => setContentType(e.target.value)}>
          <option value="blog">블로그</option>
          <option value="detail">상세페이지</option>
          <option value="insta">인스타</option>
        </select>

        {/* 어투 선택 */}
        <label>어투</label>
        <select value={tone} onChange={e => setTone(e.target.value)}>
          <option value="polite">정중</option>
          <option value="semi-polite">해요체</option>
          <option value="casual">반말</option>
        </select>

        {/* 이미지 업로드 */}
        <input type="file" accept="image/*" onChange={handleImageUpload} />

        {/* API 요청 버튼 */}
        <button onClick={handleSubmit}>API 요청 보내기</button>
      </div>
      <div className="right-side">
        <QuillToolbar />
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          modules={modules}
          formats={formats}
        />

        {/* HTML로 복사 버튼 추가 */}
        <button onClick={copyToClipboardAsHTML} className="copy-html-button">
          모든 내용을 HTML 형식으로 복사
        </button>
      </div>
    </div>
  )
}

export default TestPage10
