import React, { useState, useEffect, useRef, forwardRef, useLayoutEffect } from 'react'
import { styled, keyframes } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`

const customTextFieldShouldForwardProp = prop =>
  ![
    'minWidth',
    'maxWidth',
    'isMultiline',
    'isAnimating',
    'fontSize',
    'textColor',
    'bgColor',
    'borderRadius',
    'customMinHeight',
    'isRequired',
  ].includes(prop)

const CustomTextField = styled(TextField, {
  shouldForwardProp: customTextFieldShouldForwardProp,
})(
  ({
    theme,
    isRequired,
    minWidth = '25rem',
    maxWidth = '40rem',
    isMultiline,
    isAnimating,
    fontSize,
    textColor,
    bgColor,
    borderRadius,
    customMinHeight,
  }) => ({
    '& .MuiInputBase-root': {
      minHeight: customMinHeight || '4rem',
      fontSize: fontSize || '2rem',
      fontWeight: 700,
      backgroundColor:
        bgColor || (isRequired ? theme.palette.draph.blue : theme.palette.common.white),
      color: textColor || (isRequired ? theme.palette.common.white : theme.palette.draph.blue),
      borderRadius: borderRadius || '10px',
      paddingLeft: '1.6rem',
      paddingRight: '1.6rem',
      width: 'auto',
      minWidth,
      maxWidth,
      animation: isAnimating ? `${pulse} 0.5s ease-in-out` : 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: isMultiline ? 'normal' : 'nowrap',
    },
    width: '100%',
  })
)

const CustomTextFieldComponent = forwardRef(
  (
    {
      value,
      isRequired,
      onChange,
      placeholder,
      minWidth = '25rem',
      maxWidth = '40rem',
      fontSize = '2rem',
      textColor,
      minHeight,
      bgColor,
      borderRadius,
      sx = {},
      ...props
    },
    ref
  ) => {
    const inputRef = useRef(null)
    const measureRef = useRef(null) // 숨겨진 span
    const [fieldWidth, setFieldWidth] = useState(minWidth)
    const [isMultiline, setIsMultiline] = useState(false)
    const [isAnimating, setIsAnimating] = useState(false)

    // 실제 글자 폭 측정을 위해, 숨겨진 span의 오프셋 폭을 이용
    const measureTextWidth = text => {
      // measureRef가 렌더된 뒤에만 가능
      if (!measureRef.current) return 0
      // 스타일이 반영된 span에 실제 텍스트를 대입
      measureRef.current.textContent = text || ''
      // span의 실제 offsetWidth가 우리가 원하는 텍스트 너비
      return measureRef.current.offsetWidth
    }

    useLayoutEffect(() => {
      if (!value) {
        // 값이 없으면 기본값
        setFieldWidth(minWidth)
        setIsMultiline(false)
        return
      }

      // 실제 텍스트 길이 측정
      const contentWidth = measureTextWidth(value)

      // rem 단위 => 숫자만 parseInt
      // (실제로는 1rem = 16px 등 환경에 맞춰 환산 로직이 필요하지만 예시에선 단순화)
      const minWidthNum = parseFloat(minWidth) * 10 // 1rem -> 10px 가정
      const maxWidthNum = parseFloat(maxWidth) * 10

      const newWidth = Math.min(Math.max(contentWidth + 5, minWidthNum), maxWidthNum)
      const needMultiline = contentWidth + 20 > maxWidthNum

      // 멀티라인 모드로 전환 시에는 width를 maxWidth로 고정
      if (needMultiline) {
        setIsMultiline(true)
        setFieldWidth(maxWidth)
      } else {
        setIsMultiline(false)
        // px -> rem 변환 (역으로 / 10)
        setFieldWidth(`${newWidth / 10}rem`)
      }
    }, [value, minWidth, maxWidth])

    // 엔터키 눌렀을 때 애니메이션 주는 로직
    const handleKeyDown = event => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        animateCompletion()
        if (inputRef.current) {
          inputRef.current.blur()
        }
      }
    }

    const animateCompletion = () => {
      setIsAnimating(true)
      setTimeout(() => setIsAnimating(false), 500)
    }

    return (
      <>
        {/* 실제 폭을 측정할 숨겨진 span */}
        <span
          ref={measureRef}
          style={{
            // 실제 TextField 폰트, 사이즈, 패딩 등과 동일하게 맞춰야 함
            position: 'absolute',
            visibility: 'hidden',
            whiteSpace: 'pre', // 공백 포함해서 정확히 측정해야 하면 pre
            fontWeight: 700,
            fontSize,
            fontFamily: 'Pretendard',
            paddingLeft: '1.6rem',
            paddingRight: '1.6rem',
            border: 'none',
            // line-height, letter-spacing 등 실제 인풋에서 쓰이는 CSS도 필요 시 추가
          }}
        />
        <CustomTextField
          inputRef={el => {
            inputRef.current = el
            if (typeof ref === 'function') {
              ref(el)
            } else if (ref) {
              ref.current = el
            }
          }}
          value={value}
          isRequired={isRequired}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          fullWidth
          multiline={true}
          minWidth={minWidth}
          maxWidth={maxWidth}
          fontSize={fontSize}
          textColor={textColor}
          bgColor={bgColor}
          customMinHeight={minHeight}
          isMultiline={isMultiline}
          isAnimating={isAnimating}
          borderRadius={borderRadius}
          InputProps={{
            sx: {
              width: fieldWidth,
              p: 0,
              '& input, & textarea': {
                width: '100%',
                boxSizing: 'border-box',
                p: 0,
                overflow: 'visible',
                textOverflow: 'clip',
                whiteSpace: isMultiline ? 'normal' : 'nowrap',
                wordWrap: isMultiline ? 'break-word' : 'normal',
              },
            },
          }}
          sx={{
            '& .MuiInputBase-root': {
              width: fieldWidth,
              height: isMultiline ? 'auto' : minHeight || '4rem',
              minHeight: minHeight || '4rem',
            },
            ...sx,
          }}
          {...props}
        />
      </>
    )
  }
)

CustomTextFieldComponent.displayName = 'CustomTextFieldComponent'

export default CustomTextFieldComponent

// const CustomTextField2 = styled(TextField)(({ theme, required, isFocused }) => ({
//   '& .MuiInputBase-root': {
//     height: '4rem',
//     fontSize: '2rem',
//     fontWeight: 700,
//     backgroundColor: required ? theme.palette.draph.blue : theme.palette.common.white,
//     color: required ? theme.palette.common.white : theme.palette.draph.blue,
//     borderRadius: '10px',
//     paddingLeft: '1.6rem',
//     paddingRight: '1.6rem',
//     animation: isFocused ? `${pulse} 0.5s ease-out forwards` : 'none',
//   },
//   '& .MuiOutlinedInput-notchedOutline': {
//     border: 'none',
//   },
//   width: '100%',
// }))

// export const CustomTextFieldComponent2 = ({
//   inputRef,
//   value,
//   isRequired,
//   onChange,
//   promptType,
//   ...props
// }) => {
//   const [isFocused, setIsFocused] = useState(false)
//   const internalRef = useRef(null)

//   const handleKeyDown = e => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault()
//       setIsFocused(true)
//       if (internalRef.current) {
//         internalRef.current.blur()
//       }
//     }
//   }

//   return (
//     <CustomTextField2
//       inputRef={el => {
//         internalRef.current = el
//         if (inputRef) {
//           inputRef.current = el
//         }
//       }}
//       value={value}
//       required={isRequired}
//       onChange={e => onChange(e, `${promptType}Custom`)}
//       onKeyDown={handleKeyDown}
//       variant="outlined"
//       size="small"
//       placeholder="직접 입력"
//       fullWidth
//       isFocused={isFocused}
//       InputProps={{
//         sx: {
//           width: 'auto',
//           p: 0,
//           '& input': {
//             width: '100%',
//             minWidth: '21.8rem',
//             boxSizing: 'border-box',
//             p: 0,
//           },
//         },
//       }}
//       {...props}
//     />
//   )
// }

const customTextFieldRegen2ShouldForwardProp = prop =>
  !['isFocused', 'isRequired', 'minWidth', 'maxWidth'].includes(prop)

const CustomTextFieldRegen2 = styled(TextField, {
  shouldForwardProp: customTextFieldRegen2ShouldForwardProp,
})(({ theme, isRequired, isFocused }) => ({
  '& .MuiInputBase-root': {
    height: '2rem',
    fontSize: '1.2rem',
    fontWeight: 700,
    backgroundColor: theme.palette.draph.blue,
    color: theme.palette.common.white,
    borderRadius: '4px',
    paddingLeft: '1.6rem',
    animation: isFocused ? `${pulse} 0.5s ease-out forwards` : 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  width: '100%',
}))

export const CustomTextFieldComponentRegen2 = forwardRef(
  ({ value, onChange, isRequired, promptType, ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false)
    const internalRef = useRef(null)

    const handleKeyDown = e => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        setIsFocused(true)
        if (internalRef.current) {
          internalRef.current.blur()
        }
      }
    }

    return (
      <CustomTextFieldRegen2
        inputRef={el => {
          internalRef.current = el
          if (ref) {
            // forwardRef로 전달받은 ref에 할당
            if (typeof ref === 'function') {
              ref(el)
            } else {
              ref.current = el
            }
          }
        }}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        variant="outlined"
        size="small"
        placeholder="직접 입력"
        fullWidth
        isFocused={isFocused}
        isRequired={isRequired}
        InputProps={{
          sx: {
            width: 'auto',
            p: 0,
            '& input': {
              width: '100%',
              minWidth: '21.8rem',
              boxSizing: 'border-box',
              p: 0,
            },
          },
        }}
        {...props}
      />
    )
  }
)

CustomTextFieldComponentRegen2.displayName = 'CustomTextFieldComponentRegen2'
