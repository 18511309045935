export const UploadAssetIcon = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="00 General / 01 Icons / 01 Actions / 12 Upload">
      <path
        id="color"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 14C19.5128 14 19.9355 14.386 19.9933 14.8834L20 15V19C20 19.5128 19.614 19.9355 19.1166 19.9933L19 20H5C4.48716 20 4.06449 19.614 4.00673 19.1166L4 19V15C4 14.4477 4.44772 14 5 14C5.51284 14 5.93551 14.386 5.99327 14.8834L6 15V18H18V15C18 14.4872 18.386 14.0645 18.8834 14.0067L19 14ZM12 16C12.5523 16 13 15.5523 13 15L13.001 6.416L16.2929 9.70711C16.6534 10.0676 17.2206 10.0953 17.6129 9.7903L17.7071 9.70711C18.0676 9.34662 18.0953 8.77939 17.7903 8.3871L17.7071 8.29289L12.7071 3.29289C12.3466 2.93241 11.7794 2.90468 11.3871 3.2097L11.2929 3.29289L6.29289 8.29289C5.90237 8.68342 5.90237 9.31658 6.29289 9.70711C6.65338 10.0676 7.22061 10.0953 7.6129 9.7903L7.70711 9.70711L11.001 6.414L11 15C11 15.5523 11.4477 16 12 16Z"
        fill="#161616"
      />
    </g>
  </svg>
)
