import { Box, Button, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { apis } from 'apis'
import { AssetGridItem } from 'components/assets/AssetGridItem'
import { AssetSearch } from 'components/assets/AssetSearch'
import { UploadAssetIcon } from 'components/assets/UploadAssetIcon'
import { useAssets } from 'hooks/useAssets'
import { useCheckImage } from 'hooks/useRefineUploadFiles'
import { useUploadAsset } from 'hooks/useUploadAsset'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { allowedTypes } from 'utils/common'

const debounce = (callback, delay) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      // eslint-disable-next-line n/no-callback-literal
      callback(...args)
    }, delay)
  }
}

export const UserAssets = () => {
  const { t } = useTranslation()
  const observerRef = useRef(null)

  const {
    myAssets,
    page,
    changePage,
    totalPages,
    selectAssetInScroll,
    selectedAssets,
    resetAllSelect,
    addAsset,
  } = useAssets('scroll')
  const [files, setFiles] = useState([])

  const fetchNextPage = () => {
    if (page < totalPages) {
      changePage(page + 1)
    }
  }

  const checkImage = useCheckImage()

  const { uploadAssets } = useUploadAsset(true)

  useEffect(() => {
    const observer = new IntersectionObserver(
      debounce(entries => {
        if (entries[0].isIntersecting) {
          fetchNextPage()
        }
      }, 500),
      {
        threshold: 1,
      }
    )
    if (page > totalPages) {
      observer.unobserve(observerRef.current)
    }

    if (observerRef.current) {
      observer.observe(observerRef.current)
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current)
      }
    }
  }, [page, totalPages])

  const [searchText, setSearchTetxt] = useState('')

  return (
    <>
      <Typography
        sx={{ fontSize: '2.8rem', fontWeight: 700, textAlign: 'center', mt: '3.6rem', mb: '2rem' }}
      >
        {t('asset.title')}
      </Typography>
      <Stack direction={'row'}>
        <AssetSearch searchText={searchText} on />
        {selectedAssets.length !== 0 ? (
          <>
            <Button
              sx={{
                width: 270,
                height: 40,
                bgcolor: 'draph.blue',
                color: 'white',
                py: '10px',
                fontSize: '1.6rem',
                lineHeight: '2rem',
                ml: '16px',
              }}
              variant="contained"
            >
              <TrashIcon />
              {selectedAssets.length}장 삭제
            </Button>
            <Button
              sx={{
                width: 112,
                height: 40,
                bgcolor: 'common.lightgray',
                py: '10px',
                ml: '12px',
                color: 'text.secondary',

                ':hover': {
                  bgcolor: '#EEE',
                  color: '#161616',
                },
              }}
              disableRipple
              onClick={resetAllSelect}
            >
              <XIcon />
              선택 해제
            </Button>
          </>
        ) : null}
      </Stack>
      <Grid container rowSpacing={'12px'} columnSpacing={'12px'} key={'page'} columns={5}>
        <Grid item key="upload" xs={1}>
          <input
            type="file"
            id="file"
            style={{ display: 'none' }}
            accept={allowedTypes.join(',')}
            multiple
            onChange={async e => {
              e.preventDefault()
              const uploadFiles = [...e.target.files]
              const checkedImage = checkImage({ uploadFiles, files, setFiles })
              if (checkedImage) {
                const assetIds = await uploadAssets(uploadFiles)
                Promise.all(assetIds.map(apis.assets.getAsset))
                  .then(res => {
                    return res.map(i => i.data)
                  })
                  .then(assets => {
                    addAsset(assets)
                  })
              }
            }}
          />
          <label htmlFor="file">
            {/* ref: AssetUploadButton */}
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                px: '20px',
                py: '32px',
                bgcolor: 'common.lightgray',
                border: '1px dashed',
                borderColor: 'asset.borderGray',
                borderRadius: '10px',
                height: '100%',
              }}
            >
              <Stack alignItems={'center'} justifyContent={'center'} gap={'6px'}>
                <UploadAssetIcon />
                <Typography
                  sx={{
                    fontSize: '1.6rem',
                    color: 'black',
                    fontWeight: 600,
                  }}
                >
                  상품 이미지 업로드하기
                </Typography>
              </Stack>
              <Typography
                sx={{
                  fontSize: '1.2rem',
                  textAlign: 'center',
                }}
              >
                파일을 드롭하거나 <br /> 클릭하여 추가해주세요.
              </Typography>
            </Stack>
          </label>
        </Grid>
        {Object.values(myAssets)
          .flat()
          .map(asset => (
            <AssetGridItem
              height={260}
              key={asset.id}
              onClick={() => {
                selectAssetInScroll(asset.id)
              }}
              asset={asset}
            />
          ))}
        <Grid ref={observerRef} style={{ height: '1px' }} />
      </Grid>
    </>
  )
}

const TrashIcon = () => (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      marginRight: '8px',
    }}
  >
    <g id="Group 5675">
      <path
        id="Vector"
        d="M1.64502 5.36035H17.9108"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M16.1033 5.36035V18.1696C16.1033 19.0846 15.1996 19.9995 14.296 19.9995H5.25946C4.3558 19.9995 3.45215 19.0846 3.45215 18.1696V5.36035"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M6.16357 5.35975V3.52985C6.16357 2.6149 7.06723 1.69995 7.97088 1.69995H11.5855C12.4891 1.69995 13.3928 2.6149 13.3928 3.52985V5.35975"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M7.9707 9.93506V15.4248"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M11.5854 9.93506V15.4248"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

const XIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="00 General / 01 Icons / 01 Actions / 08 Close">
      <path
        id="color"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.694 6.30602C18.102 6.71405 18.102 7.37559 17.694 7.78361L13.4783 11.9993L17.694 16.2164C18.102 16.6244 18.102 17.286 17.694 17.694C17.286 18.102 16.6244 18.102 16.2164 17.694L12.0007 13.4769L7.78361 17.694C7.37559 18.102 6.71405 18.102 6.30602 17.694C5.89799 17.286 5.89799 16.6244 6.30602 16.2164L10.5231 11.9993L6.30602 7.78361C5.89799 7.37559 5.89799 6.71405 6.30602 6.30602C6.71405 5.89799 7.37559 5.89799 7.78361 6.30602L12.0007 10.5217L16.2164 6.30602C16.6244 5.89799 17.286 5.89799 17.694 6.30602Z"
        fill="#161616"
      />
    </g>
  </svg>
)
