import { Typography } from '@mui/material'
import { CenterAlignStack } from 'components/CenterAlignContainer'
import { useTranslation } from 'react-i18next'

export function NukkiUploadComment() {
  const { t } = useTranslation()
  return (
    <CenterAlignStack
      sx={{
        width: '100%',
        mt: { lg: '-2rem' },
        pb: { lg: '2.5rem' },
        '& .text': {
          fontSize: '1.4rem',
          fontWeight: 700,
          lineHeight: { lg: 1.2, xs: 1.1 },
        },
      }}
    >
      <Typography className="text">{t('upload_dialog.nukki_warning_1')}</Typography>
      <Typography className="text" sx={{ color: '#FF2323' }}>
        {t('upload_dialog.nukki_warning_2')}
      </Typography>
    </CenterAlignStack>
  )
}
