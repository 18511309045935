import { Input } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const AssetSearch = () => {
  const { t } = useTranslation()

  return (
    <Input
      inputProps={{
        sx: {
          p: 0,
          ml: '4px',
        },
      }}
      placeholder={t('asset.search')}
      sx={{
        bgcolor: 'common.lightgray',
        color: 'common.gray',
        borderRadius: '40px',
        py: '8px',
        px: '16px',
        fontSize: '1.4rem',
        mb: '20px',
        height: '40px',
        flex: 1,

        '::after': {
          display: 'none',
        },
        '::before': {
          display: 'none',
        },
      }}
      startAdornment={<SearchIcon />}
    />
  )
}

const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.875 2.25C10.9816 2.25 13.5 4.7684 13.5 7.875C13.5 9.15761 13.0707 10.34 12.348 11.2862L14.7803 13.7197C15.0732 14.0126 15.0732 14.4874 14.7803 14.7803C14.51 15.0507 14.0845 15.0715 13.7903 14.8427L13.7197 14.7803L11.2862 12.348C10.34 13.0707 9.15761 13.5 7.875 13.5C4.7684 13.5 2.25 10.9816 2.25 7.875C2.25 4.7684 4.7684 2.25 7.875 2.25ZM7.875 3.75C5.59683 3.75 3.75 5.59683 3.75 7.875C3.75 10.1532 5.59683 12 7.875 12C8.88835 12 9.81636 11.6346 10.5345 11.0284C10.5683 10.9152 10.6304 10.809 10.7197 10.7197C10.809 10.6304 10.9152 10.5683 11.0281 10.5334C11.6346 9.81636 12 8.88835 12 7.875C12 5.59683 10.1532 3.75 7.875 3.75Z"
      fill="#505050"
    />
  </svg>
)
