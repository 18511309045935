import dayjs from 'dayjs'
import axios from 'axios'
import { configData } from 'config'
import { useRecoilValue } from 'recoil'
import { userAtom } from 'atoms'
import { Navigate, useLocation } from 'react-router-dom'
import { useMobileMediaQuery } from './useMediaQuery'

export const useReportError = () => {
  const user = useRecoilValue(userAtom)
  const location = useLocation()
  const isMobile = useMobileMediaQuery()

  const onError = async (err, info) => {
    const formatComponentStack = stack => {
      if (!stack) return ''
      // 스택 트레이스를 줄 단위로 분할
      const lines = stack.split('\n').filter(line => line.trim())
      // 처음 5줄만 선택하고 나머지는 '...'로 표시
      const limitedStack = lines.slice(0, 5).join('\n')
      return lines.length > 5 ? `${limitedStack}\n...` : limitedStack
    }

    const componentStack = formatComponentStack(info.componentStack)
    const errMessage = err instanceof Error ? err.message : JSON.stringify(err)

    const timestamp = dayjs().format('YYYY-MM-DD HH:mm:ss')

    const slackMessage = {
      blocks: [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: '🚨 에러 발생 알림',
            emoji: true,
          },
        },
        {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: `*발생 시간:* ${timestamp}`,
            },
            {
              type: 'mrkdwn',
              text: `*에러 타입:* ${err.name || '알 수 없음'}`,
            },
          ],
        },
        {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: `*사용자:*\nID: ${user?.id || 'N/A'}\n유저명: ${user?.username || '비로그인'}`,
            },
            {
              type: 'mrkdwn',
              text: `*페이지:*${location.pathname}${location.search}\n *모바일:* ${isMobile} `,
            },
          ],
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*기기 정보:*\n\`\`\`${navigator.userAgent}\`\`\``,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*에러 메시지:*\n\`\`\`${errMessage}\`\`\``,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*발생 위치:*\n\`\`\`${componentStack}\`\`\``,
          },
        },
      ],
    }

    try {
      await axios.post(configData.SLACK_WEBHOOK, JSON.stringify(slackMessage), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    } catch (err) {
      console.error('에러 리포팅 실패:', err)
    }
  }
  return { onError }
}
