import { GUIDE_VIDEOS } from 'config'
import { English, Japanese, Korean } from 'hooks/useLanguage'
import { Desktop, Mobile } from 'hooks/useMediaQuery'

// TODO TODO TODO 모든 이미지 교체 TODO TODO TODO

// id 가 url 경로 /overview/:featureId 의 featureId와 매치
// 필수 field: id, path, main_image, title, howToUse[]

export const banner = {
  id: 'banner',
  path: '/login',
  menuNameKey: 'ads',
  video: {
    ko: '/static/video/overview/banner_en.mp4',
    en: '/static/video/overview/banner_en.mp4',
    ja: '/static/video/overview/banner_en.mp4',
  },
  main_image: `/static/sample/dummy2.png`,
  title: (
    <>
      <Korean>
        <span className="bold big">
          아니, OOO 브랜드는
          <br />
          광고 소재가 30개가 넘는다는데?
        </span>
      </Korean>
      <Japanese>
        <span className="bold big">
          え、OOOブランドは
          <br />
          広告素材が30点以上あるって？
        </span>
      </Japanese>
      <English>
        <span className="bold big">
          Wait,
          <br />
          Your competitor has
          <Mobile>
            <br />
          </Mobile>
          over 30 ad creatives!
        </span>
      </English>
    </>
  ),
  howToUse: [
    {
      text: (
        <>
          <Korean>
            <span className="bold">AI가 추천하는</span> <br />
            카피라이트와 이미지 구성을 확인해보세요.
          </Korean>
          <Japanese>
            <span className="bold">AIが提案する</span> <br />
            キャッチコピーと画像構成を確認してみてください。
          </Japanese>
          <English>
            Check out{' '}
            <span className="bold">
              AI-recommended <br />
              copywriting and image layouts.
            </span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/banner/ko/1.webp',
        en: '/static/images/overview/banner/en/1.webp',
        ja: '/static/images/overview/banner/en/1.webp',
      },
      alt: {
        ko: 'AI가 추천하는 카피라이트와 이미지 구성을 확인해보세요.',
        en: 'Check out AI-recommended copywriting and image layouts.',
        ja: 'Check out AI-recommended copywriting and image layouts.',
      },
    },
    {
      text: (
        <>
          <Korean>
            각 광고 플랫폼에서 요구하는 <br />
            <span className="bold">최적의 광고소재 사이즈를 한번에 설정하세요.</span>
          </Korean>
          <Japanese>
            各広告プラットフォームが求める <br />
            <span className="bold">最適な広告素材サイズを一括設定しましょう。</span>
          </Japanese>
          <English>
            <span className="bold">Set the optimal ad sizes </span>
            <br />
            for each platform all at once.
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/banner/ko/2.webp',
        en: '/static/images/overview/banner/en/2.webp',
        ja: '/static/images/overview/banner/en/2.webp',
      },
      alt: {
        ko: '각 광고 플랫폼에서 요구하는 최적의 광고소재 사이즈를 한번에 설정하세요.',
        en: 'Set the optimal ad sizes for each platform all at once.',
        ja: 'Set the optimal ad sizes for each platform all at once.',
      },
    },
    {
      text: (
        <>
          <Korean>
            모델 사진, 상품 사진을 최대한 활용한 <br />
            <span className="bold">효율 좋은 광고 소재로 A/B테스트를 실행해보세요.</span>
          </Korean>
          <Japanese>
            モデル写真、商品写真を最大限活用した <br />
            <span className="bold">効率の良い広告素材でA/Bテストを実行しましょう。</span>
          </Japanese>

          <English>
            Run A/B tests with
            <br />
            <span className="bold">highly effective ad creatives.</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/banner/ko/3.webp',
        en: '/static/images/overview/banner/en/3.webp',
        ja: '/static/images/overview/banner/en/3.webp',
      },
      alt: {
        ko: '모델 사진, 상품 사진을 최대한 활용한 효율 좋은 광고 소재로 A/B테스트를 실행해보세요.',
        en: 'Run A/B tests with highly effective ad creatives.',
        ja: 'Run A/B tests with highly effective ad creatives.',
      },
    },
  ],
}

export const bgGeneration = {
  id: 'bg_generation',
  path: '/generate/upload',
  menuNameKey: 'upload',
  video: {
    ko: '/static/video/overview/bg_generation_en.mp4',
    en: '/static/video/overview/bg_generation_en.mp4',
    ja: '/static/video/overview/bg_generation_en.mp4',
  },
  main_image: '/static/sample/dummy2.png',
  title: (
    <>
      <Korean>
        <span className="bold big">이제 스튜디오에 가지 않아도,</span>
        <br />
        <span className="bold big">
          컨셉에 맞는 상품 배경을{' '}
          <Mobile>
            <br />
          </Mobile>
          생성할 수 있습니다.
        </span>
      </Korean>

      <Japanese>
        <span className="bold big">もうスタジオに行かなくても、</span>
        <br />
        <span className="bold big">
          コンセプトに合った商品背景を{' '}
          <Mobile>
            <br />
          </Mobile>
          生成できます。
        </span>
      </Japanese>
      <English>
        <span className="lg-bold lg-big">No need to visit a studio anymore -</span>
        <br />
        <span className="bold big">Create backgrounds that match your theme perfectly.</span>
      </English>
    </>
  ),
  howToUse: [
    {
      text: (
        <>
          <Korean>
            제품의 특성에 맞는 배경을 <br />
            <span className="bold">상상하고, 입력하면 바로 생성됩니다. </span>
          </Korean>
          <Japanese>
            商品の特性に合った背景を <br />
            <span className="bold">想像して入力すれば、すぐに生成されます。</span>
          </Japanese>
          <English>
            Simply imagine a background <br /> that suits your product, <br />
            <span className="bold">enter it, and it’s generated instantly.</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/bg_generation/en/1.webp',
        en: '/static/images/overview/bg_generation/en/1.webp',
        ja: '/static/images/overview/bg_generation/en/1.webp',
      },
      alt: {
        ko: '제품의 특성에 맞는 배경을 상상하고, 입력하면 바로 생성됩니다.',
        en: 'Simply imagine a background that suits your product, enter it, and it’s generated instantly.',
        ja: 'Simply imagine a background that suits your product, enter it, and it’s generated instantly.',
      },
    },
    {
      text: (
        <>
          <Korean>
            시선을 붙잡을 수 있는 컨셉을 기획하여, <br />
            <span className="bold">제품의 특성을 효과적으로 보여주세요. </span>
          </Korean>
          <Japanese>
            視線を引きつけるコンセプトを企画して、
            <br />
            <span className="bold">商品の特性を効果的に表現してください。</span>
          </Japanese>
          <English>
            Plan a captivating theme to
            <br />
            <span className="bold"> effectively highlight your product’s features.</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/bg_generation/en/2.webp',
        en: '/static/images/overview/bg_generation/en/2.webp',
        ja: '/static/images/overview/bg_generation/en/2.webp',
      },
      alt: {
        ko: '시선을 붙잡을 수 있는 컨셉을 기획하여, 제품의 특성을 효과적으로 보여주세요.',
        en: 'Plan a captivating theme to effectively highlight your product’s features.',
        ja: 'Plan a captivating theme to effectively highlight your product’s features.',
      },
    },
    {
      text: (
        <>
          <Korean>
            야외, 실내, 심지어 가상 공간으로 <br />
            <span className="bold">제품을 배치하여 컨셉 사진을 생성해보세요.</span>
          </Korean>
          <Japanese>
            屋外、室内、さらには仮想空間で、
            <br />
            <span className="bold">商品を配置してコンセプト写真を生成してみましょう。</span>
          </Japanese>
          <English>
            Create photos by placing your product in <br />
            <span className="bold">
              outdoor settings, indoor scenes, <br />
              or even virtual spaces.
            </span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/bg_generation/en/3.webp',
        en: '/static/images/overview/bg_generation/en/3.webp',
        ja: '/static/images/overview/bg_generation/en/3.webp',
      },
      alt: {
        ko: '야외, 실내, 심지어 가상 공간으로 제품을 배치하여 컨셉 사진을 생성해보세요.',
        en: 'Create photos by placing your product in outdoor settings, indoor scenes, or even virtual spaces.',
        ja: 'Create photos by placing your product in outdoor settings, indoor scenes, or even virtual spaces.',
      },
    },
  ],
}

export const faceGeneration = {
  id: 'face_generation',
  path: '/model/face',
  menuNameKey: 'face',
  video: {
    ko: '/static/video/overview/face_blur_en.mp4',
    en: '/static/video/overview/face_blur_en.mp4',
    ja: '/static/video/overview/face_blur_en.mp4',
  },
  main_image: '/static/sample/dummy2.png',
  title: (
    <>
      <Korean>
        팩트체크. <br />
        <span className="bold big">
          모자이크된 이미지보다
          <Mobile>
            <br />
          </Mobile>
          얼굴이 노출된 이미지가
          <Mobile>
            <br />
          </Mobile>
          2.4배이상 클릭률 좋음
        </span>
      </Korean>
      <Japanese>
        ファクトチェック。
        <br />
        <span className="bold big">
          モザイクされた画像よりも
          <Mobile>
            <br />
          </Mobile>
          顔が見える画像のほうが
          <Mobile>
            <br />
          </Mobile>
          クリック率が2.4倍以上高い
        </span>
      </Japanese>
      <English>
        Fact check: <br />
        <span className="bold big">
          Images with visible faces have over 2.4 times higher <br />
          click-through rates than pixelated images.
        </span>
      </English>
    </>
  ),
  howToUse: [
    {
      text: (
        <>
          <Korean>
            헤어모델 사진 필요할때,
            <br />
            <span className="bold">모자이크 이미지 대신 얼굴 이미지로 사용해요.</span>
          </Korean>
          <Japanese>
            ヘアモデルの写真が必要なとき、
            <br />
            <span className="bold">モザイク画像の代わりに顔画像を使用しましょう。</span>
          </Japanese>
          <English>
            When you need hair model photos, <br />
            <span className="bold">
              use images with faces <br />
              instead of pixelated ones.
            </span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/face_generation/ko/1.webp',
        en: '/static/images/overview/face_generation/ko/1.webp',
        ja: '/static/images/overview/face_generation/ko/1.webp',
      },
      alt: {
        ko: '헤어모델 사진 필요할때, 모자이크 이미지 대신 얼굴 이미지로 사용해요.',
        en: 'When you need hair model photos, use images with faces instead of pixelated ones.',
        ja: 'When you need hair model photos, use images with faces instead of pixelated ones.',
      },
    },
    {
      text: (
        <>
          <Korean>
            촬영 허락을 구할 때,
            <br />
            <span className="bold">AI가 완전히 다른 얼굴로 바꿔준다고 알려주세요. </span>
          </Korean>
          <Japanese>
            撮影許可を求めるとき、
            <br />
            <span className="bold">AIが全く異なる顔に変えることを伝えてください。</span>
          </Japanese>
          <English>
            When asking for permission to shoot, <br /> let people know that their face <br />
            <span className="bold">will be replaced with an AI-generated one.</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/face_generation/ko/2.webp',
        en: '/static/images/overview/face_generation/ko/2.webp',
        ja: '/static/images/overview/face_generation/ko/2.webp',
      },
      alt: {
        ko: '촬영 허락을 구할 때, AI가 완전히 다른 얼굴로 바꿔준다고 알려주세요.',
        en: 'When asking for permission to shoot, let people know that their face will be replaced with an AI-generated one.',
        ja: 'When asking for permission to shoot, let people know that their face will be replaced with an AI-generated one.',
      },
    },
  ],
}

export const modelGeneration = {
  id: 'model_generation',
  path: '/model/modelbg',
  menuNameKey: 'modelbg',
  video: {
    ko: '/static/video/overview/modelbg_en.mp4',
    en: '/static/video/overview/modelbg_en.mp4',
    ja: '/static/video/overview/modelbg_en.mp4',
  },
  main_image: '/static/sample/dummy2.png',
  title: (
    <>
      <Korean>
        <span className="lg-bold lg-big">더 이상 모델 섭외하는 데 공들이지 마세요.</span>
        <br />
        <span className="bold big">AI 모델은 24시간 준비 완료!</span>
      </Korean>
      <Japanese>
        <span className="lg-bold lg-big">
          もうモデルのキャスティングに労力をかけないでください。
        </span>
        <br />
        <span className="bold big">AIモデルは24時間準備完了！</span>
      </Japanese>
      <English>
        <span className="lg-bold lg-big">Stop spending time booking models.</span>
        <br />
        <span className="bold big">Our AI models are ready 24/7!</span>
      </English>
    </>
  ),
  howToUse: [
    {
      text: (
        <>
          <Korean>
            신상품이 출시될 때마다 <br />
            <span className="bold">새로운 모델로 상품사진을 구성해보세요.</span>
          </Korean>
          <Japanese>
            新商品が発売されるたびに
            <br />
            <span className="bold">新しいモデルで商品写真を構成してみましょう。</span>
          </Japanese>
          <English>
            Use different models for your product photos
            <br />
            <span className="bold">every time you launch a new product.</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/model_generation/ko/1.webp',
        en: '/static/images/overview/model_generation/ko/1.webp',
        ja: '/static/images/overview/model_generation/ko/1.webp',
      },
      alt: {
        ko: '신상품이 출시될 때마다 새로운 모델로 상품사진을 구성해보세요.',
        en: 'Use different models for your product photos every time you launch a new product.',
        ja: 'Use different models for your product photos every time you launch a new product.',
      },
    },
    {
      text: (
        <>
          <Korean>
            매 시즌마다
            <br />
            <span className="bold">새로운 배경으로 모델 컷을 촬영해보세요.</span>
          </Korean>
          <Japanese>
            毎シーズン、
            <br />
            <span className="bold">新しい背景でモデルカットを撮影してみましょう。</span>
          </Japanese>

          <English>
            Update your model photos
            <br />
            <span className="bold">with fresh backgrounds every season.</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/model_generation/ko/2.webp',
        en: '/static/images/overview/model_generation/ko/2.webp',
        ja: '/static/images/overview/model_generation/ko/2.webp',
      },
      alt: {
        ko: '매 시즌마다 새로운 배경으로 모델 컷을 촬영해보세요.',
        en: 'Update your model photos with fresh backgrounds every season.',
        ja: 'Update your model photos with fresh backgrounds every season.',
      },
    },
  ],
}

export const bgRemoval = {
  id: 'bg_removal',
  path: '/generate/removebg',
  menuNameKey: 'removebg',
  video: {
    ko: '/static/video/overview/removebg_en.mp4',
    en: '/static/video/overview/removebg_en.mp4',
    ja: '/static/video/overview/removebg_en.mp4',
  },
  main_image: '/static/sample/dummy2.png',
  title: (
    <>
      <Korean>
        <span className="bold big">
          AI가 들썩이는 이 시대에,
          <br />
          아직도 한장씩 누끼따고 계세요?
        </span>
      </Korean>{' '}
      <Japanese>
        <span className="bold big">
          AIが話題を呼ぶこの時代に、
          <br />
          まだ1枚ずつ切り抜きをしていませんよね？
        </span>
      </Japanese>
      <English>
        <span className="bold big">
          In this AI-driven era, <br />
          Are you still cutting out backgrounds one by one?
        </span>
      </English>
    </>
  ),
  howToUse: [
    {
      text: (
        <>
          <Korean>
            한번에 수십장 업로드해서,
            <br />
            <span className="bold">일괄적으로 누끼이미지를 생성해보세요.</span>
          </Korean>
          <Japanese>
            一度に数十枚アップロードして、
            <br />
            <span className="bold">一括で切り抜き画像を生成してみましょう。</span>
          </Japanese>
          <English>
            Upload dozens of images at once and
            <br />
            <span className="bold"> generate background-free images in bulk.</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/bg_removal/en/1.webp',
        en: '/static/images/overview/bg_removal/en/1.webp',
        ja: '/static/images/overview/bg_removal/en/1.webp',
      },
      alt: {
        ko: '한번에 수십장 업로드해서, 일괄적으로 누끼이미지를 생성해보세요.',
        en: 'Upload dozens of images at once and generate background-free images in bulk.',
        ja: 'Upload dozens of images at once and generate background-free images in bulk.',
      },
    },
    {
      text: (
        <>
          <Korean>
            배경이 제거된 누끼 이미지를 활용해서,
            <br />
            <span className="bold">다양한 디자인 제작물을 제작해보세요.</span>
          </Korean>
          <Japanese>
            背景が除去された切り抜き画像を活用して、
            <br />
            <span className="bold">さまざまなデザイン制作物を作成してみましょう。</span>
          </Japanese>
          <English>
            Use these cut-out images
            <br />
            <span className="bold">to create a variety of designs.</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/bg_removal/en/2.webp',
        en: '/static/images/overview/bg_removal/en/2.webp',
        ja: '/static/images/overview/bg_removal/en/2.webp',
      },
      alt: {
        ko: '배경이 제거된 누끼 이미지를 활용해서, 다양한 디자인 제작물을 제작해보세요.',
        en: 'Use these cut-out images to create a variety of designs.',
        ja: 'Use these cut-out images to create a variety of designs.',
      },
    },
    {
      text: (
        <>
          <Korean>
            자연스러운 배경의 제품 사진을 원하신다면,
            <br />
            <span className="bold">배경 생성 기능으로 제품 배경을 만들어보세요.</span>
          </Korean>
          <Japanese>
            自然な背景の商品の写真が必要な場合、
            <br />
            <span className="bold">背景生成機能を使って商品背景を作成してみましょう。</span>
          </Japanese>
          <English>
            If you need product photos with backgrounds,
            <br />
            <span className="bold">
              use the background generation feature
              <br /> of Draph Art.
            </span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/bg_removal/en/3.webp',
        en: '/static/images/overview/bg_removal/en/3.webp',
        ja: '/static/images/overview/bg_removal/en/3.webp',
      },
      alt: {
        ko: '자연스러운 배경의 제품 사진을 원하신다면, 배경 생성 기능으로 제품 배경을 만들어보세요.',
        en: 'If you need product photos with backgrounds, use the background generation feature of Draph Art.',
        ja: 'If you need product photos with backgrounds, use the background generation feature of Draph Art.',
      },
    },
  ],
}

export const faceChange = {
  id: 'face_change',
  path: '/model/face',
  menuNameKey: 'face',
  video: {
    ko: '/static/video/overview/face_en.mp4',
    en: '/static/video/overview/face_en.mp4',
    ja: '/static/video/overview/face_en.mp4',
  },
  main_image: '/static/sample/dummy2.png',
  title: (
    <>
      <Korean>
        <span className="bold big">
          다른건 다 괜찮은데, <br />
          얼굴만은 노출하고 싶지 않을 때...
        </span>
      </Korean>
      <Japanese>
        <span className="bold big">
          他は全部大丈夫だけど、
          <br />
          顔だけは公開したくないとき…
        </span>
      </Japanese>
      <English>
        <span className="lg-bold lg-big">
          Everything else is fine,{' '}
          <Mobile>
            <br />
          </Mobile>
          but there’s just one thing -
        </span>
        <br />
        <span className="bold big">When you don’t want to reveal the face...</span>
      </English>
    </>
  ),
  howToUse: [
    {
      text: (
        <>
          <Korean>
            전체 사진의 레이아웃은 유지하면서,
            <br />
            <span className="bold">이미지의 사람 얼굴을 AI 모델 얼굴로 변경해요. </span>
          </Korean>
          <Japanese>
            全体の写真レイアウトはそのままに、
            <br />
            <span className="bold">画像の人の顔をAIモデルの顔に変更します。</span>
          </Japanese>
          <English>
            Keep the overall layout of the photo
            <br />
            <span className="bold">while changing the face to a model’s face.</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/face_change/en/1.webp',
        en: '/static/images/overview/face_change/en/1.webp',
        ja: '/static/images/overview/face_change/en/1.webp',
      },
      alt: {
        ko: '전체 사진의 레이아웃은 유지하면서, 이미지의 사람 얼굴을 AI 모델 얼굴로 변경해요.',
        en: 'Keep the overall layout of the photo while changing the face to a model’s face.',
        ja: 'Keep the overall layout of the photo while changing the face to a model’s face.',
      },
    },
    {
      text: (
        <>
          <Korean>
            원하는 사진의 분위기가 있을 때,
            <br />
            <span className="bold"> 원본에서 얼굴만 딱 인식해서 바뀌는 기능이에요. </span>
          </Korean>
          <Japanese>
            特定の写真の雰囲気を保ちたい場合、
            <br />
            <span className="bold">元画像から顔だけを認識して変更する機能です。</span>
          </Japanese>
          <English>
            If you want a specific vibe in a photo, <br />
            this feature detects and
            <span className="bold">
              <br /> changes just the face <br /> while keeping the original image intact.
            </span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/face_change/en/2.webp',
        en: '/static/images/overview/face_change/en/2.webp',
        ja: '/static/images/overview/face_change/en/2.webp',
      },
      alt: {
        ko: '원하는 사진의 분위기가 있을 때, 원본에서 얼굴만 딱 인식해서 바뀌는 기능이에요.',
        en: 'If you want a specific vibe in a photo, this feature detects and changes just the face while keeping the original image intact.',
        ja: 'If you want a specific vibe in a photo, this feature detects and changes just the face while keeping the original image intact.',
      },
    },
  ],
}

export const bundle = {
  id: 'bundle',
  path: '/generate/bundle',
  menuNameKey: 'bundle',
  video: {
    ko: '/static/video/overview/bundle_en.mp4',
    en: '/static/video/overview/bundle_en.mp4',
    ja: '/static/video/overview/bundle_en.mp4',
  },
  main_image: '/static/sample/dummy2.png',
  title: (
    <>
      <Korean>
        <span className="bold big">
          쿠팡에 상품 등록할 때,
          <br />
          묶음 상품으로 올리면 좋아요.
        </span>
      </Korean>
      <Japanese>
        <span className="bold big">
          オンラインストアに商品を掲載する場合、
          <br />
          さまざまな商品画像をバンドルして使うのは素晴らしい。
        </span>
      </Japanese>
      <English>
        <span className="lg-bold lg-big">When listing products on an online store,</span>
        <br />
        <span className="bold big">It’s great to use a variety of bundled product images.</span>
      </English>
    </>
  ),
  howToUse: [
    {
      text: (
        <>
          <Korean>
            번들상품의 개수에 따라
            <br />
            <span className="bold">상품 썸네일을 간편하게 생성할 수 있어요. </span>
          </Korean>
          <Japanese>
            バンドル商品の個数に応じて、
            <br />
            <span className="bold">商品サムネイルを簡単に生成できます。</span>
          </Japanese>
          <English>
            <span className="bold">
              Easily generate product thumbnail images
              <br />
            </span>
            based on the number of items in the bundle.
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/bundle/ko/1.webp',
        en: '/static/images/overview/bundle/en/1.webp',
        ja: '/static/images/overview/bundle/en/1.webp',
      },
      alt: {
        ko: '번들상품의 개수에 따라 상품 썸네일을 간편하게 생성할 수 있어요.',
        en: 'Easily generate product thumbnail images based on the number of items in the bundle.',
        ja: 'Easily generate product thumbnail images based on the number of items in the bundle.',
      },
    },
    {
      text: (
        <>
          <Korean>
            1+1 이벤트, 2+1 이벤트 등
            <br />
            <span className="bold"> 사은품이 들어가는 형태의 이미지 생성 기능도 </span>
            <br />곧 개발될 예정이에요.
          </Korean>
          <Japanese>
            1+1イベント、2+1イベントなど、
            <br />
            <span className="bold">おまけ付きの画像生成機能も</span>
            <br />
            近日開発予定です。
          </Japanese>
          <English>
            Additional types of
            <br />
            bundled product image generation <br />
            <span className="bold">are also coming soon.</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/bundle/ko/2.webp',
        en: '/static/images/overview/bundle/en/2.webp',
        ja: '/static/images/overview/bundle/en/2.webp',
      },
      alt: {
        ko: '1+1 이벤트, 2+1 이벤트 등 사은품이 들어가는 형태의 이미지 생성 기능도 곧 개발될 예정이에요.',
        en: 'Additional types of bundled product image generation are also coming soon.',
        ja: 'Additional types of bundled product image generation are also coming soon.',
      },
    },
  ],
}

export const bgExpansion = {
  id: 'bg_expansion',
  path: '/generate/canvas',
  menuNameKey: 'bg_expansion',
  video: {
    ko: '/static/video/overview/bg_expansion_en.mp4',
    en: '/static/video/overview/bg_expansion_en.mp4',
    ja: '/static/video/overview/bg_expansion_en.mp4',
  },
  main_image: '/static/sample/dummy2.png',
  title: (
    <>
      <Korean>
        <span className="lg-bold lg-big">좁게 찍힌 이미지도 OK!</span>
        <br />
        <span className="bold big">
          상하좌우 원하는 대로{' '}
          <Mobile>
            <br />
          </Mobile>
          이미지 연장 가능!
        </span>
      </Korean>
      <Japanese>
        <span className="lg-bold lg-big">狭く撮影された画像でもOK！</span>
        <br />
        <span className="bold big">
          上下左右、自由に{''}
          <Mobile>
            <br />
          </Mobile>
          画像を拡張可能！
        </span>
      </Japanese>
      <English>
        <span className="lg-bold lg-big">
          Even if the image is too narrow,{' '}
          <Mobile>
            <br />
          </Mobile>{' '}
          no problem!
        </span>
        <br />
        <span className="bold big">
          Extend the image in any direction - top, bottom, left, or right
        </span>
      </English>
    </>
  ),
  howToUse: [
    {
      text: (
        <>
          <Korean>
            정방형, 세로형, 가로형 등
            <br />
            <span className="bold">원하는 비율대로 이미지를 변경할 수 있어요.</span>
          </Korean>

          <Japanese>
            正方形、縦長、横長など、
            <br />
            <span className="bold">希望の比率に合わせて画像を変更できます。</span>
          </Japanese>

          <Japanese>
            片側に画像背景を長く拡張して、
            <br />
            <span className="bold">その上にテキストを加えれば広告画像の完成！</span>
          </Japanese>
          <English>
            <span className="bold">Adjust the image to your desired aspect ratio,</span>
            <br /> whether it’s square, vertical, or horizontal.
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/bg_expansion/ko/1.webp',
        en: '/static/images/overview/bg_expansion/ko/1.webp',
        ja: '/static/images/overview/bg_expansion/ko/1.webp',
      },
      alt: {
        ko: '정방형, 세로형, 가로형 등 원하는 비율대로 이미지를 변경할 수 있어요.',
        en: 'Adjust the image to your desired aspect ratio, whether it’s square, vertical, or horizontal.',
        ja: 'Adjust the image to your desired aspect ratio, whether it’s square, vertical, or horizontal.',
      },
    },
    {
      text: (
        <>
          <Korean>
            한쪽으로 이미지 배경을 길게 연장하고,
            <br />
            <span className="bold">그 위에 문구를 쓰면 광고 이미지 완성!</span>
          </Korean>
          <Japanese>
            片側に画像背景を長く拡張して、
            <br />
            <span className="bold">その上にテキストを加えれば広告画像の完成！</span>
          </Japanese>
          <English>
            Extend the background to one side <br />
            <span className="bold">and add text to create a perfect ad!</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/bg_expansion/ko/2.webp',
        en: '/static/images/overview/bg_expansion/ko/2.webp',
        ja: '/static/images/overview/bg_expansion/ko/2.webp',
      },
      alt: {
        ko: '한쪽으로 이미지 배경을 길게 연장하고, 그 위에 문구를 쓰면 광고 이미지 완성!',
        en: 'Extend the background to one side and add text to create a perfect ad!',
        ja: 'Extend the background to one side and add text to create a perfect ad!',
      },
    },
  ],
}

export const mannequinToModel = {
  id: 'mannequin_to_model',
  path: '/model/mannequin',
  menuNameKey: 'mannequin',
  video: {
    ko: '/static/video/overview/mannequin_en.mp4',
    en: '/static/video/overview/mannequin_en.mp4',
    ja: '/static/video/overview/mannequin_en.mp4',
  },
  main_image: '/static/sample/dummy2.png',
  title: (
    <>
      <Korean>
        <span className="lg-bold lg-big">그거 아세요?</span>
        <br />
        <span className="bold big">
          마네킹 착장 이미지는{' '}
          <Mobile>
            <br />
          </Mobile>{' '}
          클릭률 떨어지는거.
        </span>
      </Korean>
      <Japanese>
        <span className="lg-bold lg-big">知っていますか？</span>
        <br />
        <span className="bold big">
          マネキン着用画像は
          <Mobile>
            <br />
          </Mobile>
          クリック率が下がります。
        </span>
      </Japanese>
      <English>
        <span className="lg-bold lg-big">Did you know?</span>
        <br />
        <span className="bold big">Mannequin images tend to have lower click-through rates.</span>
      </English>
    </>
  ),
  howToUse: [
    {
      text: (
        <>
          <Korean>
            마네킹이 입고 있는 옷은 유지하면서,
            <br />
            <span className="bold">마네킹을 사람 모델로 변경해요.</span>
          </Korean>
          <Japanese>
            マネキンが着ている服はそのままに、
            <br />
            <span className="bold">マネキンを人モデルに変更します。</span>
          </Japanese>
          <English>
            Keep the outfit on the mannequin,
            <br />
            <span className="bold">but swap the mannequin with a human.</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/mannequin_to_model/ko/1.webp',
        en: '/static/images/overview/mannequin_to_model/ko/1.webp',
        ja: '/static/images/overview/mannequin_to_model/ko/1.webp',
      },
      alt: {
        ko: '마네킹이 입고 있는 옷은 유지하면서, 마네킹을 사람 모델로 변경해요.',
        en: 'Keep the outfit on the mannequin, but swap the mannequin with a human.',
        ja: 'Keep the outfit on the mannequin, but swap the mannequin with a human.',
      },
    },
    {
      text: (
        <>
          <Korean>
            마네킹이 아니어도 괜찮아요.
            <br />
            유지하고 싶은 부분만 선택하면 <br />
            <span className="bold">나머지는 모두 다 새롭게 생성해요.</span>
          </Korean>
          <Japanese>
            マネキンでなくても大丈夫です。
            <br />
            保持したい部分だけを選択すれば、
            <br />
            <span className="bold">残りはすべて新しく生成します。</span>
          </Japanese>
          <English>
            It doesn’t have to be a mannequin. <br />
            Just select the parts you want to keep,
            <br />
            <span className="bold">and we’ll recreate the rest for you.</span>
          </English>
        </>
      ),
      image: {
        ko: '/static/images/overview/mannequin_to_model/ko/2.webp',
        en: '/static/images/overview/mannequin_to_model/ko/2.webp',
        ja: '/static/images/overview/mannequin_to_model/ko/2.webp',
      },
      alt: {
        ko: '마네킹이 아니어도 괜찮아요. 유지하고 싶은 부분만 선택하면 나머지는 모두 다 새롭게 생성해요.',
        en: 'It doesn’t have to be a mannequin. Just select the parts you want to keep, and we’ll recreate the rest for you.',
        ja: 'It doesn’t have to be a mannequin. Just select the parts you want to keep, and we’ll recreate the rest for you.',
      },
    },
  ],
}
