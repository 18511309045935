import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Slider from '@mui/material/Slider'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

import { atom, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  artworkRegenCountAtom,
  blockDragDropAtom,
  canvasBrushCursorStyleAtom,
  clicksAtom,
  defaultPortfolioAtom,
  endpointAtom,
  indexAtom,
  mannequinLoadingAtom,
  maskImageAtom,
  nukkiSAMModeAtom,
  nukkiSAMOriSizeAtom,
  nukkiSAMSizeAtom,
  portfolioConfigAtom,
  portfolioRegenOptionAtom,
  portfolioTypeAtom,
  removeMaskImgAtom,
  segmentLoadingAtom,
  segmentStepAtom,
  selectedMaskImgAtom,
  uploadFilesAtom,
  userAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  isSimpleAtom,
  uploadFilesAndUrlAtom,
  uploadImgHeightAtom,
  uploadImgWidthAtom,
  portfolioDetailAtom,
  regenerateUploadFilesAndUrlAtom,
  refImageAtom,
  regenCustomPromptAtom,
  portfolioCustomPromptAtom,
} from 'atoms'
import {
  CenterAlignBox,
  CenterAlignStack,
  FormTextField,
  ShadowButton,
  ThemeTemplateThumb,
  CustomOutlinedInput,
  SpanDraphBlue,
  GoodBadExample,
  SegmentArea,
  StyledToggleButtonGroup,
  StyledToggleButton,
  ResetDialog,
  MobileSwipeableDrawer,
  PortfolioConfigTabs,
  SetPortfolioConcept,
  CreateButton,
  SetPortfolioCategory,
  SetPortfolioOutput,
} from 'components'
import { cloneElement, useEffect, useRef, useState } from 'react'

import { PORTFOLIO_CATEGORIES, ARTWORK_REGEN_REQ_MAX, ARTWORK_REGEN_AUTO_COMPLETE } from 'config'
import { apis } from 'apis'
import useConfirm from 'hooks/useConfirm'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import {
  CloseIcon,
  ClothesIcon,
  FoodIcon,
  CosmeticsIcon,
  CarIcon,
  ModelIcon,
  ClipBoardIcon,
  DropdownArrowIcon,
  RefreshIcon,
  CloseCuteIcon,
} from 'theme/icon'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import {
  Collapse,
  Divider,
  GlobalStyles,
  IconButton,
  OutlinedInput,
  SvgIcon,
  SwipeableDrawer,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material'
import { Stage, Layer, Line, Rect, Group } from 'react-konva'
import Konva from 'konva'
import { PuffLoader } from 'react-spinners'
import { AiOutlineConsoleSql } from 'react-icons/ai'
import * as config from 'config'
import { StyledInput } from 'components/SearchInput'
import { usePortfolioId } from 'hooks/usePortfoliId'
import {
  AIIconMini,
  getS3ImageSrc,
  iOS,
  imageTransparent,
  imageUrlToFile,
  isKo,
  transferToBack,
  transferToFront,
  translatePortfolioPromptEnToKo,
} from 'utils/common'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { useRefineUploadFiles, useUploadHook } from 'hooks/useRefineUploadFiles'
import { currentMenuAtom } from '../../atoms'
import { NoChip, YesChip } from './ArtworkRowControl'
import { NukkiEditTab } from './RegenerateDialog'
import { useApiHandler } from 'hooks/useApiHandler'
import { Japanese, Korean } from 'hooks/useLanguage'

const AngleSlider = styled(Slider)(({ theme }) => ({
  width: '1rem',
  padding: '0 1.2rem',
  '& .MuiSlider-rail': {
    color: '#D9D9D9',
    opacity: 1,
    height: 'calc(100% + 0.9rem)',
    bottom: 0,
  },
  '& .MuiSlider-track': {
    opacity: 0,
  },

  '& .MuiSlider-mark': {
    width: '0.8rem',
    height: '0.8rem',
    border: '0.5px solid #909090',
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
  },

  '& .MuiSlider-thumb': {
    width: '1.25rem',
    height: '1.25rem',
    outline: '0.1rem solid #4D80FF99',
    outlineOffset: '.3rem',

    '&:hover': {
      boxShadow: 'none',
    },

    '&.Mui-active': {
      // transition: 'outline-offset 0.2s',
      boxShadow: '2px 2px 20px rgba(77, 128, 255, 0.6)',
    },
  },

  [theme.breakpoints.down('lg')]: {
    padding: '0',
    paddingLeft: '2rem',
    //   marginTop: '2rem',
    //   width: '100%',
    //   '& .MuiSlider-rail': {
    //     height: '1rem',
    //   },

    //   '& .MuiSlider-mark:nth-last-of-type(3)': {
    //     left: 'calc(50% - 0.5rem) !important',
    //   },

    //   '& .MuiSlider-mark:nth-last-of-type(2)': {
    //     left: 'calc(100% - 1rem) !important',
    //   },
  },
}))

const angleImages = [
  '/static/images/angle_example/angle_04_perpen.png',
  '/static/images/angle_example/angle_03_45.png',
  '/static/images/angle_example/angle_02_floor.png',
  '/static/images/angle_example/angle_01_front.png',
]

// const angleValues = ['perpen', '45', 'floor', 'front']
const angleValues = ['perpen', 'diag', 'floor'] // 0, 1, 2
const actionHeight = '10rem'

const smallTitleStyle = {
  fontWeight: 700,
  fontSize: { lg: '1.6rem', xs: '1.3rem' },
  lineHeight: 'normal',
}

const commentStyle = {
  fontSize: { lg: '1.2rem', xs: '1.1rem' },
  fontWeight: 400,
  color: '#595959',
}

export default function RegenerateDialogV2({ open, setOpen, artwork, regenerateArtwork }) {
  const { t, i18n } = useTranslation()
  const { editUploadFile } = useUploadHook(regenerateUploadFilesAndUrlAtom)

  const [refImage, setRefImage] = useState([])
  const [sodModified, setSodModified] = useState(false)

  const [portfolioRegenOption, setPortfolioRegenOption] = useRecoilState(portfolioRegenOptionAtom)
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [isSimple, setIsSimple] = useRecoilState(isSimpleAtom) // 기본 선택일 경우 옮겨지는 느낌이 생김
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)
  const [regenCount, setRegenCount] = useRecoilState(artworkRegenCountAtom)
  const [files, setFiles] = useRecoilState(regenerateUploadFilesAndUrlAtom)

  const segmentLoading = useRecoilValue(segmentLoadingAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const resetPortfolioRegen = useResetRecoilState(portfolioRegenOptionAtom)
  const resetSelectedMaskImg = useResetRecoilState(selectedMaskImgAtom)
  const resetMannequinLoading = useResetRecoilState(mannequinLoadingAtom)
  const resetSegmentLoading = useResetRecoilState(segmentLoadingAtom)
  const resetSegmentStep = useResetRecoilState(segmentStepAtom)
  const resetUploadFilesAndUrl = useResetRecoilState(regenerateUploadFilesAndUrlAtom)
  const resetNukkiSAMSize = useResetRecoilState(nukkiSAMSizeAtom)
  const resetNukkiSAMOriSize = useResetRecoilState(nukkiSAMOriSizeAtom)
  const resetNukkiSAMMode = useResetRecoilState(nukkiSAMModeAtom)
  const resetMaskImage = useResetRecoilState(maskImageAtom)
  const resetRemoveMaskImg = useResetRecoilState(removeMaskImgAtom)
  const resetIndex = useResetRecoilState(indexAtom)
  const resetClick = useResetRecoilState(clicksAtom)
  const resetBlockDragDrop = useResetRecoilState(blockDragDropAtom)
  const resetUploadImgHeight = useResetRecoilState(uploadImgHeightAtom)
  const resetUploadImgWidth = useResetRecoilState(uploadImgWidthAtom)

  const filesRef = useRef(files)
  const refImageRef = useRef(refImage)

  // Update the ref whenever files changes
  useEffect(() => {
    filesRef.current = files
    refImageRef.current = refImage
  }, [files, refImage])

  const reset = () => {
    // console.log('revoke_10')
    filesRef.current.forEach(image => URL.revokeObjectURL(image.url))
    refImageRef.current.forEach(image => URL.revokeObjectURL(image.url))
    resetUploadFilesAndUrl()
    resetSelectedMaskImg()
    resetMannequinLoading()
    resetSegmentStep()
    resetSegmentLoading()
    resetNukkiSAMSize()
    resetNukkiSAMMode()
    resetMaskImage()
    resetRemoveMaskImg()
    resetNukkiSAMOriSize()
    resetIndex()
    resetClick()
    resetPortfolioRegen()
    resetBlockDragDrop()
    resetUploadImgHeight()
    resetUploadImgWidth()
  }

  const { callApi, abortApi } = useApiHandler({ apiKey: 'getNpyMaps', apis: apis.appfront })

  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isBgExpansionPage = portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION

  const refreshRegenCount = () => {
    apis.portfolio.getArtworkRegenCount().then(response => {
      if (response.data) {
        setRegenCount(response.data)
      }
    })
  }

  useEffect(() => {
    if (refImage.length) {
      setPortfolioRegenOption({
        ...portfolioRegenOption,
        theme_template: 'custom',
        theme_custom: '',
        ...{ bgComplex: true, bgGenerate: true, bgSimple: false },
      })
    }
  }, [refImage])

  const noSetting = isRemoveBgPage

  useEffect(() => {
    if (isRemoveBgPage) {
      !files.length &&
        editUploadFile(artwork.uploaded, {
          mannequinMode: true,
          service: 'removebg',
          artworkId: artwork.id,
        })
    }

    return () => {
      reset()
      abortApi()
    }
  }, [])

  const handleClose = (e, r) => {
    if (r && (r === 'backdropClick' || r === 'escapeKeyDown')) return
    setOpen(false)
    reset()
    abortApi()
  }

  const [promptValues, setPromptValues] = useRecoilState(regenCustomPromptAtom)

  // portfolioRegenOption 여기 현재의 아트워크 설정 넣음 추후 portfolioRegenOption로 RegenOption 설정
  useEffect(() => {
    const d = JSON.parse(artwork?.config)
    const config = transferToFront(d)
    setPortfolioRegenOption(p => ({ ...p, ...config }))

    return () => {
      resetPortfolioRegen()
    }
  }, [])

  const handleRegen = () => {
    let newPrompt = ''
    let newPromptValues = { ...promptValues }

    if (config.B2B_CUSTOM.includes(promptValues.productCustom)) {
      newPrompt = promptValues.productCustom
      newPromptValues = { product: 'custom', productCustom: promptValues.productCustom }
      setPromptValues({ product: 'custom', productCustom: promptValues.productCustom })
    } else {
      newPrompt = translatePortfolioPromptEnToKo(promptValues)
    }

    const adjustPortfolioConfig = isRemoveBgPage
      ? portfolioRegenOption
      : {
          ...portfolioRegenOption,
          bgGenerate: true,
          genShadow: true,

          theme_custom: newPrompt,
          prompt_object: newPromptValues,
          // mono 면 단색으로 빠지도록 설정
          ...(portfolioRegenOption.theme_template === 'mono'
            ? {
                bgSimple: true,
                bgGenerate: false,
                bgComplex: false,
              }
            : {
                bgSimple: false,
                bgGenerate: true,
                bgComplex: true,
              }),
        }

    const regenConfig = transferToBack(adjustPortfolioConfig)

    regenerateArtwork(regenConfig, refImage, sodModified ? 'sod_mod' : 'regenerate')
    handleClose()
  }

  return (
    <>
      {' '}
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          // disableScrollLock={true}
          sx={{
            overflowX: 'hidden',

            '& .MuiDialog-paper': {
              minWidth: '80rem',
              borderRadius: '10px',
              p: '4.4rem 4rem',
            },
          }}
        >
          <IconButton
            disableFocusRipple
            disableTouchRipple
            disableRipple
            sx={{ position: 'absolute', top: '2.8rem', right: '2.8rem' }}
            onClick={handleClose}
          >
            <CloseCuteIcon sx={{ width: '1.2rem', height: '1.2rem' }} />
          </IconButton>

          <Box
            sx={{
              width: '100%',

              pt: '1.5rem',
              pb: '2.8rem',
            }}
          >
            <BespokeAdBanner />
          </Box>

          <CenterAlignStack sx={{ width: '100%', gap: '2.8rem' }}>
            {!isRemoveBgPage && <ConfigDisplay option={portfolioRegenOption} refImage={refImage} />}
            <CofigPannel
              artwork={artwork}
              refImage={refImage}
              setRefImage={setRefImage}
              sodModified={sodModified}
              setSodModified={setSodModified}
            />
          </CenterAlignStack>

          <CenterAlignStack>
            <CreateButton onClick={handleRegen} disabled={isSimple === 'ref' && !refImage.length}>
              <Box
                className="content"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  transition: 'transform 0.3s ease-in-out', // transition 추가
                }}
              >
                {' '}
                <RefreshIcon
                  size={'medium'}
                  color="white"
                  style={{ width: '1.6rem', height: '2rem' }}
                />
                &nbsp;
                {t('regenerate_dialog.button_regenerate')}
              </Box>
            </CreateButton>
          </CenterAlignStack>
        </Dialog>
      </Desktop>
      <Mobile>
        <MobileSwipeableDrawer></MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

export const ConfigDisplay = ({ option, refImage }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const detail = useRecoilValue(portfolioDetailAtom)

  const configSimple = !(option.bgComplex || option.bgGenerate)

  return (
    <Stack
      sx={{
        width: '100%',
        '& .center': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'inherit',
        },

        '& .spaceBtw': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: 'inherit',
        },
        '& .tab': {
          p: '0.8rem 3rem',
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          borderRadius: '10px 10px 0 0',
          fontSize: '1.3rem',
          fontWeight: 700,
          width: 'fit-content',
        },

        '& .config': {
          p: '2rem 2.8rem',
          backgroundColor: '#F8F8F8',

          borderRadius: '0 0 10px 10px ',
          gap: '4.8rem',
          width: 'inherit',
          '& .item-stack': {
            gap: '0.8rem',

            '&.side': {
              width: '20rem',
            },
            '& .item-box': {
              display: 'flex',
            },
          },

          '& p': {
            display: 'block',
            fontSize: '1.4rem',
            '& span': {
              fontWeight: 600,
            },
          },
        },
      }}
    >
      <Box className="center tab">{t('upload_config.current_config')}</Box>
      <Box className="center config">
        <Box className="spaceBtw">
          <Stack className="item-stack side">
            <Box className="item-box">
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                <span>{t('upload_config.bg_theme')} :&nbsp;</span>{' '}
                {refImage?.length ? (
                  t('upload_header.ref_image')
                ) : (
                  <>
                    {configSimple ? t('upload_config.bg_simple') : t('upload_config.bg_concept')}
                    {!configSimple && ' > '}

                    {!configSimple &&
                      (option?.theme_template?.length > 0
                        ? option?.theme_template === 'custom'
                          ? option.theme_custom
                          : t(`theme_template.${option?.theme_template}`)
                        : '-')}
                  </>
                )}
              </Typography>
            </Box>

            <Typography>
              <span>{t('upload_config.product_category')} :&nbsp;</span>
              {t(`artwork_category.${option.category}`)}
            </Typography>
          </Stack>
          <CenterAlignStack className="item-stack" sx={{ width: '20rem' }}>
            <Stack sx={{ gap: '0.8rem' }}>
              <Box className="item-box">
                <Typography>
                  <span>{t('upload_config.include_nukki')} :&nbsp;</span>
                </Typography>
                {option.bgWhite ? <YesChip /> : <NoChip />}
              </Box>

              <Typography>
                <span>{t('upload_config.image_size')} :&nbsp;</span>

                {
                  (option.outputSizeList.length === 1 &&
                    option.outputSizeList[0].w === null &&
                    option.outputSizeList[0].h === null) ||
                  option.outputSizeList.length === 0
                    ? '자동'
                    : `${option.outputSizeList[0].w}px * ${option.outputSizeList[0].h}px`
                  // option.outputSizeList.map((size, index) => {
                  //     return (
                  //       <span style={{ fontWeight: 400 }} key={index}>
                  //         {size.w}px * {size.h}px
                  //       </span>
                  //     )
                  //   }
                }

                {/* {t('upload_config.width') + option.outputSizeList} */}
              </Typography>
            </Stack>
          </CenterAlignStack>
          <Stack className="item-stack side" sx={{ alignItems: 'end' }}>
            <Stack sx={{ gap: '0.8rem', width: 'fit-content' }}>
              <Box className="item-box">
                <Typography>
                  <span>{t('upload_config.multi_blob')} :&nbsp;</span>
                </Typography>
                {option.flagMultiblobSod ? <NoChip /> : <YesChip />}
              </Box>

              <Box className="item-box">
                <Typography>
                  <span>{t('upload_config.face_swap')} :&nbsp;</span>
                </Typography>
                {option.genFace ? <YesChip /> : <NoChip />}
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Stack>
  )
}

const CofigPannel = ({ artwork, refImage, setRefImage, sodModified, setSodModified }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const [tab, setTab] = useState(0)

  const { editUploadFile } = useUploadHook(regenerateUploadFilesAndUrlAtom)

  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const [portfolioRegenOption, setPortfolioRegenOption] = useRecoilState(portfolioRegenOptionAtom)
  const [files, setFiles] = useRecoilState(regenerateUploadFilesAndUrlAtom)

  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG

  const handleChange = (event, newValue) => {
    setTab(newValue)

    if (event.target.textContent === t('regenerate_dialog.removebg_edit')) {
      !files.length &&
        editUploadFile(artwork.uploaded, {
          mannequinMode: true,
          service: 'removebg',
          artworkId: artwork.id,
        })
    }
  }

  const onClickMain = category => event => {
    setPortfolioRegenOption({
      ...portfolioRegenOption,
      category: category.id,
      subCategory: 'auto',
    })
  }

  const tabs = [
    { default: t('upload_config.bg_theme'), mobile: t('upload_config.bg_theme_s') },
    {
      default: t('upload_config.product_category'),
      mobile: t('upload_config.product_category_s'),
    },
    {
      default: t('upload_config.advanced_setting'),
      mobile: t('upload_config.advanced_setting'),
    },
    {
      default: t('regenerate_dialog.removebg_edit'),
      mobile: t('regenerate_dialog.removebg_edit'),
    },
  ]

  return (
    <CenterAlignStack sx={{ width: '100%', alignItems: 'flex-start' }}>
      {isRemoveBgPage ? (
        <CenterAlignStack
          sx={{
            width: '18rem',
            py: '0.8rem',
            fontSize: '1.3rem !important',
            fontWeight: 700,
            backgroundColor: theme.palette.draph.blue,
            color: theme.palette.common.white,
            borderRadius: '10px 10px 0px 0px',
          }}
        >
          {t('regenerate_dialog.removebg_edit')}
        </CenterAlignStack>
      ) : (
        <PortfolioConfigTabs
          tabs={tabs}
          value={tab}
          onChange={handleChange}
          tabSx={{ fontSize: '1.3rem !important', height: '3.2rem !important' }}
        />
      )}

      {tab === 0 &&
        (isRemoveBgPage ? (
          <NukkiEditTab
            artwork={artwork}
            sodModified={sodModified}
            setSodModified={setSodModified}
          />
        ) : (
          <SetPortfolioConcept
            config={portfolioRegenOption}
            setConfig={setPortfolioRegenOption}
            files={refImage}
            setFiles={setRefImage}
            isDialog={true}
          />
        ))}

      {tab === 1 && (
        <SetPortfolioCategory
          config={portfolioRegenOption}
          setConfig={setPortfolioRegenOption}
          onClickMain={onClickMain}
        />
      )}

      {tab === 2 && (
        <SetPortfolioOutput config={portfolioRegenOption} setConfig={setPortfolioRegenOption} />
      )}

      {tab === 3 && (
        <NukkiEditTab artwork={artwork} sodModified={sodModified} setSodModified={setSodModified} />
      )}
    </CenterAlignStack>
  )
}

function BespokeAdBanner() {
  const handleClick = () => {
    window.open('/overview/bespoke', '_blank')
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '10rem',
          // background: 'pink',
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <GlobalStyles
          styles={`
        @font-face {
          font-family: 'Paperlogy';
          src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-4Regular.woff2') format('woff2');
          font-weight: 400;
          font-style: normal;
        }
        @font-face {
          font-family: 'Paperlogy';
          src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-7Bold.woff2') format('woff2');
          font-weight: 700;
          font-style: normal;
        }
      `}
        />
        <Box
          sx={{
            width: '100%',
            height: '100%',
            // border: '1px solid #ccc',
            backgroundImage: `url('/static/images/overview/bespoke/bespoke_banner_image.png')`,
            backgroundSize: '820px auto',
            backgroundPosition: 'right -110px center',
            backgroundRepeat: 'no-repeat',
            overflow: 'hidden',
          }}
        />

        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 68.75%)',
          }}
        />
        <Typography
          sx={{
            fontSize: '2rem',
            lineHeight: 1.35,
            fontWeight: 400,
            position: 'absolute',
            left: '24px',
            top: '21px',
            fontFamily: 'Paperlogy', // 커스텀 폰트 적용
            color: '#ffffff', // 텍스트 색상 (이미지에 따라 조정 가능)
            '& span': { fontSize: '2.4rem', fontWeight: 700 },
          }}
        >
          <Korean>재생성으로도 원하는 이미지를 얻을 수 없다면?</Korean>
          <Japanese>再生成でも希望の画像を得られない場合は？</Japanese>
          <br />
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Korean>
              드랩 스튜디오 제작대행 서비스!&nbsp;&nbsp;
              <svg
                width="89"
                height="16"
                viewBox="0 0 89 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M88.7071 8.70711C89.0976 8.31658 89.0976 7.68342 88.7071 7.29289L82.3431 0.928932C81.9526 0.538408 81.3195 0.538408 80.9289 0.928932C80.5384 1.31946 80.5384 1.95262 80.9289 2.34315L86.5858 8L80.9289 13.6569C80.5384 14.0474 80.5384 14.6805 80.9289 15.0711C81.3195 15.4616 81.9526 15.4616 82.3431 15.0711L88.7071 8.70711ZM0 9H88V7H0V9Z"
                  fill="white"
                />
              </svg>
            </Korean>{' '}
            <Japanese>
              ドラップスタジオ制作代行サービス！&nbsp;&nbsp;
              <svg
                width="89"
                height="16"
                viewBox="0 0 89 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M88.7071 8.70711C89.0976 8.31658 89.0976 7.68342 88.7071 7.29289L82.3431 0.928932C81.9526 0.538408 81.3195 0.538408 80.9289 0.928932C80.5384 1.31946 80.5384 1.95262 80.9289 2.34315L86.5858 8L80.9289 13.6569C80.5384 14.0474 80.5384 14.6805 80.9289 15.0711C81.3195 15.4616 81.9526 15.4616 82.3431 15.0711L88.7071 8.70711ZM0 9H88V7H0V9Z"
                  fill="white"
                />
              </svg>
            </Japanese>
          </span>
        </Typography>
      </Box>
    </>
  )
}
