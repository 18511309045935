import React, { useEffect, useRef, useState } from 'react'

import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, { centerCrop } from 'react-image-crop'

// import required modules
import { Navigation, Swiper, Keyboard, Controller } from 'swiper'

import _, { slice } from 'lodash'
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Typography,
  Paper,
  Divider,
  SwipeableDrawer,
  makeStyles,
  Switch,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  GIFstandardDate,
  convertPriceSymbolToWord,
  getPieceType,
  getS3ImageSrc,
  iOS,
  isKo,
  padZeros,
  sortPieces,
} from 'utils/common'
import { apis } from 'apis'

import { useLocation, useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import useConfirm from 'hooks/useConfirm'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  backgroundAtom,
  backgroundDialogAtom,
  backgroundFetchInfoAtom,
  backgroundFilterAtom,
  backgroundLoadingAtom,
  defaultPortfolioAtom,
  tutorialAtom,
  userAtom,
  userLikedBgAtom,
  portfolioTypeAtom,
  currentMenuAtom,
  imageDialogDiaplayModeAtom,
  pleaseLoginDialogAtom,
  downloadWarningDialogAtom,
  portfolioArtworkAtom,
} from 'atoms'
import { AiFillPushpin, AiOutlinePushpin, AiOutlineScissor } from 'react-icons/ai'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { fetchBackground, refreshBackground } from 'pages/Background'

import { useSnackbar } from 'notistack'
import { AnimatePresence, motion } from 'framer-motion'
import {
  backgroundFilterSelector,
  creditPolicyDictSelector,
  isPortfolioOwnerSelector,
} from 'selector'
import { CreateBackground } from 'components/background/CreateBackground'
import { useTheme } from '@mui/system'
import {
  StarFilledIcon,
  StarOutlinedIcon,
  DownloadIcon,
  TrashCanIcon,
  TrashCanIcon2,
  CloseIcon,
  CropIcon,
  SizeControlIcon,
  BlueCircleCheckIcon,
  BadExampleIcon,
  GIFSideIcon,
  UpscaledIcon,
} from 'theme/icon'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useMediaQuery } from 'react-responsive'
import { Tutorial } from 'theme/Tutorial'
import * as config from 'config'

import {
  CustomTooltip,
  Loading,
  SpanDraphBlue,
  getGA4EventNameByPortfolioType,
  triggerGA4DownloadEvent,
  triggerGA4UploadEventManualConfig,
} from 'components'
import useBackground from 'hooks/useBackground'
import { usePortfolioId } from 'hooks/usePortfoliId'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getDownloadPieceFileName } from '../../utils/common'
import { ArtworkRowImageSwiper } from './ArtworkRowImageSwiper'
import {
  bgRemovedStyle,
  checkBgremovedPiece,
  checkedURLforNoCache,
  getPieceDownloadCondition,
  isRemovedBg,
  isUpscaledPiece,
} from 'utils/artwork'
import { Dashboard } from 'pages'
import { English, Japanese, Korean } from 'hooks/useLanguage'
import { hideDownloadWarningPopup } from './DownloadWarningDialog'

const IMAGE_VIEWER_WIDTH = 78
const IMAGE_VIEWER_HEIGHT = 44

const DialogButton = styled(Button)(({ theme }) => ({
  boxShadow: '4px 4px 20px 0px #00000040',
  background: '#FFFFFFD9',
  fontSize: '1.8rem',
  fontWeight: 600,
  color: theme.palette.common.black,
  paddingTop: '0.9rem',
  paddingBottom: '0.9rem',
  borderRadius: '3rem',
  opacity: 0.85,

  '&:hover': {
    background: '#FFFFFFD9',
    opacity: 1,
  },
}))

export function ImageSwiperDialog2({
  open,
  setOpen,
  items,
  pieceIdx,
  setPieceIdx,
  artwork,
  setArtwork,
  refreshArtworks = () => {},
}) {
  const [artworkList, setArtworkList] = useRecoilState(portfolioArtworkAtom) // 포트폴리오의 전체 아트워크 목록

  const [swiper, setSwiper] = useState()
  const [displayMode, setDisplayMode] = useRecoilState(imageDialogDiaplayModeAtom)

  // -- 원본 이미지 사이즈가 아닌 화면에 표시되는 사이즈 --
  const [beforeImageW, setBeforeImageW] = useState()
  const [beforeImageH, setBeforeImageH] = useState()
  const [afterImageW, setAfterImageW] = useState()
  const [afterImageH, setAfterImageH] = useState()
  // -- 원본 이미지 사이즈가 아닌 화면에 표시되는 사이즈 --

  const [dialogWidth, setDialogWidth] = useState()
  const [dialogHeight, setDialogHeight] = useState(0)

  const MAX_HEIGHT = 'calc(85vh - 240px) !important'
  const MAX_WIDTH = 'calc(70vw - 280px) !important'
  const MAX_HEIGHT_PX = document.documentElement.clientHeight * 0.85 - 240
  const MAX_WIDTH_PX = document.documentElement.clientWidth * 0.7 - 280

  const [maxHeightPx, setMaxHeightPx] = useState(MAX_HEIGHT_PX)
  const [maxWidthPx, setMaxWidthPx] = useState(MAX_WIDTH_PX)

  const [cropMode, setCropMode] = useState(false)
  const [crop, setCrop] = useState({})

  const [user, setUser] = useRecoilState(userAtom)
  const creditPolicy = useRecoilValue(creditPolicyDictSelector)

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId

  const isBannerPageV2 = portfolioType === config.PORTFOLIO_TYPE_BANNERV2
  const isBannerPage = portfolioType === config.PORTFOLIO_TYPE_BANNER

  const [imgCropSize, setImgCropSize] = useState({})

  const [skipDownloadWarning, setSkipDownloadWarning] = useState(false)

  const { showConfirm } = useConfirm()
  const { t } = useTranslation

  const currentPiece = artwork.pieces ? artwork.pieces[pieceIdx] : null

  const [pieceDownloaded, setPieceDownloaded] = useState(true) // 누끼 이외의 피스를 다운받은 이력이 있는지 여부
  const [downloadWarningDialog, setDownloadWarningDialog] =
    useRecoilState(downloadWarningDialogAtom)

  useEffect(() => {
    setPieceDownloaded(getPieceDownloadCondition(artwork))
  }, [JSON.stringify(artwork?.pieces)])

  useEffect(() => {
    if (open) {
      // 스와이퍼를 init 하려는데 setTimeout 없이는 정상 작동이 안돼서 불가피하게 ..
      setTimeout(() => {
        const sw = new Swiper(`#image-dialog-swiper-container`, {
          navigation: {
            nextEl: `#image-swiper-dialog .swiper-button-next`,
            prevEl: `#image-swiper-dialog .swiper-button-prev`,
          },
          modules: [Navigation, Controller, Keyboard],
          keyboard: {
            enabled: true,
            onlyInViewport: false,
          },
          // slidesPerView: 'auto',
          slidesPerView: 1,
          on: {
            slideChange: function (s) {
              setPieceIdx(s.realIndex)
            },
          },
        })
        if (_.isArray(sw)) {
          setSwiper(sw[0])
        } else {
          setSwiper(sw)
        }
      }, 200)
    }

    // return () => {
    //   if (swiper) swiper.destroy()
    // }
  }, [open])

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(pieceIdx)
    }
  }, [swiper])

  // -------- width, height 조절하는 부분
  useEffect(() => {
    // 1. 원본이미지 높이, 생성이미지 높이, MAX HEIGHT 중 최소값으로 dialogHeight (dh) 결정
    const p = artwork.pieces[pieceIdx]
    let dh = maxHeightPx
    let bh = Infinity
    let ah = 0
    if (p?.id && artwork.uploadedSize?.h && artwork?.pieceImageSize[p.id]?.h) {
      dh = Math.min(artwork.uploadedSize.h, artwork?.pieceImageSize[p.id]?.h, maxHeightPx)
    }

    if (!cropMode && displayMode === 'compare') {
      bh = dh
    }

    ah = dh

    // 2. dh 기준으로 beforeImage 너비, afterImage 너비 계산
    let bw = 0
    let aw = maxWidthPx
    if (
      !cropMode &&
      displayMode === 'compare' &&
      artwork.uploadedSize?.w &&
      artwork.uploadedSize?.h
    ) {
      const w = artwork.uploadedSize.w
      const ratio = dh / artwork.uploadedSize.h
      bw = w * ratio
    }

    if (p && artwork.pieceImageSize[p.id]) {
      const w = artwork.pieceImageSize[p.id].w
      const h = artwork.pieceImageSize[p.id].h
      const ratio = dh / h
      aw = w * ratio
    }

    // 3. dialogWidth(dw)는 (bw + aw) 인데 (bw + aw) 가 다이얼로그 최대 너비를 넘어가면 너비와 높이 모두 재조정
    let dw = bw + aw
    if (dw > maxWidthPx) {
      const ratio = maxWidthPx / (bw + aw)
      if (!isNaN(ratio)) {
        dh = dh * ratio

        bw = bw * ratio
        aw = aw * ratio

        bh = bh * ratio
        ah = ah * ratio
      }
      dw = maxWidthPx
    }

    setDialogWidth(dw)
    setDialogHeight(dh)

    setBeforeImageW(bw)
    setBeforeImageH(bh)

    setAfterImageW(aw)
    setAfterImageH(ah)

    // console.log('dialog', dw, dh)
    // console.log('before', bw, bh)
    // console.log('after', aw, ah)
    // console.log('~~~~~~~')
  }, [
    maxHeightPx,
    maxWidthPx,
    pieceIdx,
    artwork.uploadedSize,
    artwork.pieceImageSize,
    displayMode,
    cropMode,
  ])

  useEffect(() => {
    function handleResize() {
      setMaxHeightPx(MAX_HEIGHT_PX)
      setMaxWidthPx(MAX_WIDTH_PX)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (cropMode === true) {
      setCrop({ unit: 'px', x: 0, y: 0, width: afterImageW / 2, height: afterImageH / 2 })
      if (swiper) {
        swiper.disable()
      }
    } else {
      if (swiper) {
        swiper.enable()
      }
    }
  }, [cropMode, afterImageW, afterImageH])

  const handleClose = () => {
    setOpen(false)
  }

  const onLoadUploaded = e => {
    if (!artwork.uploadedSize) {
      if (e.target.naturalWidth > 1 && e.target.naturalHeight > 1) {
        setArtwork(a => {
          return { ...a, uploadedSize: { w: e.target.naturalWidth, h: e.target.naturalHeight } }
        })
      }
    }
  }

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    return credit
  }

  const openDownloadWarning = downloadFucntion => {
    const piece = artwork.pieces[pieceIdx]
    const bgRemovedOnly = checkBgremovedPiece(piece.path)
    const skipPage = config.NO_REGENERATE_TYPES.includes(portfolioType)

    const skipPopup =
      localStorage.getItem(hideDownloadWarningPopup) ||
      bgRemovedOnly ||
      skipDownloadWarning ||
      skipPage ||
      true

    if (skipPopup) {
      downloadFucntion()
    } else {
      setDownloadWarningDialog({
        open: true,
        download: () => {
          downloadFucntion()
          setSkipDownloadWarning(true) // 한번 팝업 후 다운로드헀으면 계속 띄우지 않도록..
        },
      })
    }
  }

  const download = async () => {
    const piece = artwork.pieces[pieceIdx]
    if (user.use_credit_on.includes('artwork_download')) {
      const credit = await checkUserCredit()

      if (credit < creditPolicy.artwork_download) {
        showConfirm({
          alertOnly: true,
          content: <Typography>{t('common.credit_warning')}</Typography>,
        })
        return
      }
    }

    const path = piece.path
    const artworkName = artwork.name.replace('.' + _.last(artwork.name.split('.')), '')
    const url = checkedURLforNoCache(getS3ImageSrc(path))

    const img = new Image()
    img.src = url

    const blob = await fetch(url + `?w=${Date.now().toString()}`).then(r => r.blob())

    const ext = _.last(_.first(_.last(url.split('/')).split('?')).split('.'))
    const idx = 1

    const fileName =
      piece.result_filename?.length > 0
        ? piece.result_filename
        : getDownloadPieceFileName({
            prefix: artworkName,
            idx: idx,
            width: img.width,
            height: img.height,
            ext: ext,
          })
    saveAs(blob, fileName)

    apis.portfolio
      .updateArtworkDownload(portfolioId, artwork.id, { paths: [path] })
      .then(response => {
        const d = response.data.total_download
        checkUserCredit()

        if (d) {
          if (d) setArtwork({ ...artwork, download: d })

          apis.portfolio.getArtwork(portfolioId, artwork.id).then(response => {
            if (response.data) {
              const a = response.data
              const sorted = sortPieces(a)
              a.pieces = sorted

              const updatedList = artworkList.map(aInList =>
                aInList.id === artwork.id ? { ...aInList, ...{ ...a, pieces: sorted } } : aInList
              )

              setArtworkList(updatedList) // 목록도 업데이트
            }
          })
        }
      })

    const artworkConfig = JSON.parse(artwork.config)

    // ----- GA4 event -----
    if (config.DEFAULT_PORTFOLIO_TYPES.includes(portfolioType)) {
      const ev = getGA4EventNameByPortfolioType(portfolioType, 'download')
      triggerGA4DownloadEvent({
        eventName: ev,
        params: { count: 1 },
        method: 'dialog',
      })
    } else if (artworkConfig.flag_bg_expansion) {
      triggerGA4DownloadEvent({
        eventName: 'ai_canvas_exp_download',
        params: { count: 1 },
        method: 'dialog',
      })
    } else if (!portfolioType) {
      triggerGA4DownloadEvent({
        eventName: 'portfolio_download',
        artworkConfig,
        user,
        artwork,
        count: 1,
        method: 'dialog',
      })
    } else if (isBannerPageV2 || isBannerPage) {
      const eventParams = {
        method: 'dialog',
        count: 1,
        menu: isBannerPageV2 ? 'v2' : 'v1',
      }

      triggerGA4UploadEventManualConfig(eventParams, 'banner_download')
    } else {
      triggerGA4DownloadEvent({
        artworkConfig,
        user,
        artwork,
        count: 1,
        method: 'dialog',
      })
    }

    // ---------------------
  }

  const onLoadCropImage = idx => e => {
    const naturalWidth = e.target.naturalWidth
    const naturalHeight = e.target.naturalHeight

    const elHeight = e.target.height
    const elWidth = e.target.width
    const originalRatio = IMAGE_VIEWER_HEIGHT / IMAGE_VIEWER_WIDTH //  높이 / 너비 너비 비율
    const resizeRatio = elHeight / elWidth
    const isWidthLong = originalRatio > resizeRatio

    const isImageSmall =
      naturalHeight <= IMAGE_VIEWER_HEIGHT * 10 && naturalWidth <= IMAGE_VIEWER_WIDTH * 10

    const data = {
      [idx]: {
        width: isImageSmall ? naturalWidth : elWidth,
        height: isImageSmall ? naturalHeight : elHeight,
        isWidthLong,
        isImageSmall,
      },
    }

    setImgCropSize(prev => ({ ...prev, ...data }))
  }

  const cropDownload = () => {
    const canvas = document.createElement('canvas')

    const context = canvas.getContext('2d')
    const { x, y, width, height } = crop

    const image = new Image()
    image.src = getS3ImageSrc(
      checkedURLforNoCache(artwork?.pieces[swiper?.realIndex].path) + `?w=${Date.now().toString()}`
    )
    image.crossOrigin = 'anonymous'

    image.onload = () => {
      const size = imgCropSize[swiper?.realIndex]

      const scaleX = image.naturalWidth / size.width
      const scaleY = image.naturalHeight / size.height

      canvas.width = width * scaleX
      canvas.height = height * scaleY

      context.drawImage(
        image,
        x * scaleX,
        y * scaleY,
        width * scaleX,
        height * scaleY,
        0,
        0,
        width * scaleX,
        height * scaleY
      )

      const name = artwork.name.split('.')[0]

      canvas.toBlob(blob => {
        const file = new File([blob], `crop.jpg`, { type: 'image/jpeg' })

        // file을 사용하여 다운로드 또는 업로드 등의 작업 수행
        // 예시: 파일 다운로드
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(file)
        downloadLink.download = `${name}_cropped.jpg`
        downloadLink.click()
      }, 'image/jpeg')
    }
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          id="image-swiper-dialog"
          fullWidth
          maxWidth={'xl'}
          sx={{
            '& .MuiModal-backdrop': {
              backgroundColor: '#30303099',
              backdropFilter: 'blur(20px)',
            },
            '& .MuiPaper-root': {
              m: 0,

              width: dialogWidth,
              height: dialogHeight,
              maxWidth: MAX_WIDTH,
              maxHeight: MAX_HEIGHT,
              position: 'unset',

              overflow: 'visible',

              background: 'transparent',
              // background: 'pink', // 영역 확인용
              boxShadow: 'none',
            },

            '& #image-dialog-swiper-box': {
              maxHeight: '100%',
            },

            '& .swiper-button': {
              display: cropMode ? 'none' : '',
              backgroundImage: 'none',
              position: 'absolute',
              '&.swiper-button-disabled': {
                pointerEvents: 'unset !important',
                '& .button-box': {
                  pointerEvents: 'none',
                },
              },

              '&.swiper-button-prev': {},
              '&.swiper-button-next': {},
            },
          }}
        >
          {/* <Box
            sx={{
              width: MAX_WIDTH,
              height: MAX_HEIGHT,
              position: 'absolute',
              background: 'gold',
              top: '50%',
              transform: 'translateY(-50%)',
              opacity: 0.5,
            }}
          >
            최대 영역 확인용 박스
          </Box> */}

          {/* 이전 / 다음 화살표 버튼 */}

          <>
            <Box className={`swiper-button swiper-button-prev`}>
              <SwiperPrevButton />
            </Box>
            <Box className={`swiper-button swiper-button-next`}>
              <SwiperNextButton />
            </Box>
          </>

          <Box
            id="content-wrapper"
            sx={{
              maxHeight: '100%',
              position: 'relative',
            }}
          >
            <CenterAlignStack
              id="image-dialog-swiper-box"
              direction="row"
              sx={{
                position: 'relative',

                maxHeight: '100%',

                '& .height100': {
                  maxHeight: '100%',
                  height: '100%',
                },

                '& .swiper-container': {
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',

                  '& .swiper-wrapper': {},

                  '& .swiper-slide': {
                    background: 'transparent',
                    width: `${dialogWidth}px !important`,
                    '& .before-after-wrapper': {
                      '& .image-box ': {
                        position: 'relative',
                        '& img': {
                          maxHeight: MAX_HEIGHT,
                        },
                      },
                    },
                  },
                },
              }}
            >
              <Box id={`image-dialog-swiper-container`} className="swiper-container height100">
                {/* 이미지 상단 버튼 영역 */}
                <Stack
                  direction="row"
                  id="button-area"
                  sx={{
                    justifyContent: 'center',
                    display: 'flex',
                    zIndex: 9,
                    position: 'absolute',
                    top: '-6.8rem',
                  }}
                >
                  {/* 원본과 비교 보기 / 결과물만 보기 토글 스위치 */}
                  {!cropMode && (
                    <DisplayModeSwitch displayMode={displayMode} setDisplayMode={setDisplayMode} />
                  )}
                </Stack>

                <div className="swiper-wrapper height100">
                  <>
                    {artwork.pieces?.map((piece, idx) => {
                      const path = piece.path
                      const img = getS3ImageSrc(path)

                      return (
                        <div key={idx} className={`swiper-slide height100 swiper-slide-${idx}`}>
                          <CenterAlignStack
                            direction="row"
                            className="before-after-wrapper height100"
                            sx={{
                              '& img': {
                                height: dialogHeight,
                              },

                              '& .uploaded-image-box img': {
                                width: beforeImageW ? `${beforeImageW}px !important` : 'auto',

                                backgroundColor: 'white',
                                backgroundImage: `linear-gradient(45deg, #ddd 25%, transparent 25%),
                                                  linear-gradient(-45deg, #ddd 25%, transparent 25%),
                                                  linear-gradient(45deg, transparent 75%, #ddd 75%),
                                                  linear-gradient(-45deg, transparent 75%, #ddd 75%)`,
                                backgroundSize: '16px 16px',
                                backgroundPosition: '0 0, 0 8px, 8px -8px, -8px 0px',

                                maxWidth: 'unset',
                              },
                              '& .result-image-box img': {
                                width: afterImageW ? `${afterImageW}px !important` : 'auto',
                                maxWidth: 'unset',
                                ...(isRemovedBg(piece.path) && bgRemovedStyle),
                              },
                            }}
                          >
                            {beforeImageW > 0 && displayMode === 'compare' && (
                              <Box className="image-box uploaded-image-box height100">
                                <BeforeBox sx={{ position: 'absolute', top: '1rem', left: '1rem' }}>
                                  Before
                                </BeforeBox>
                                <img
                                  src={
                                    artwork.uploaded.includes('https://upload-pipeline-data')
                                      ? artwork.uploaded
                                      : getS3ImageSrc(artwork.uploaded)
                                  }
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translateY(-50%)',

                                    right: '-24px', // 화살표 이미지(아이콘) 의 width / 2
                                    zIndex: 3,
                                  }}
                                >
                                  <BeforeAfterArrow />
                                </Box>
                              </Box>
                            )}

                            <Box className="image-box result-image-box height100">
                              {isUpscaledPiece(currentPiece) && (
                                // 업스케일된 피스인지
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '0.8rem',
                                    left: '0.8rem',
                                    zIndex: 2,
                                  }}
                                >
                                  <UpscaledIcon />
                                </Box>
                              )}
                              {!cropMode && displayMode === 'compare' && (
                                <AfterBox sx={{ position: 'absolute', top: '1rem', right: '1rem' }}>
                                  After
                                </AfterBox>
                              )}

                              {cropMode ? (
                                <>
                                  <ReactCrop
                                    ruleOfThirds
                                    crop={crop}
                                    onChange={e => {
                                      setCrop(e)
                                    }}
                                  >
                                    <img src={img} onLoad={onLoadCropImage(idx)} />
                                  </ReactCrop>
                                </>
                              ) : (
                                <img src={img} />
                              )}
                            </Box>
                          </CenterAlignStack>
                        </div>
                      )
                    })}
                  </>
                </div>

                {/* 이미지 하단 버튼 영역 */}
                <Box
                  gap="1.2rem"
                  id="button-area"
                  sx={{
                    justifyContent: 'center',
                    display: 'flex',
                    zIndex: 9,
                    position: 'absolute',
                    // width: 'fit-content',
                    // border: '1px dashed gold',
                    bottom: '-7.7rem',
                    // bottom: 0,
                  }}
                >
                  {!user?.id ? (
                    <GuestButton setOpen={setOpen} />
                  ) : cropMode ? (
                    <>
                      {/* 취소 (크롭 모드 종료) 버튼 */}
                      <CancelButton
                        onClick={() => {
                          setCropMode(false)
                        }}
                      />

                      <CropRatioButton1
                        onClick={e => {
                          const adjusted = Math.max(crop.width, crop.height)

                          setCrop(c => ({ ...c, width: adjusted, height: adjusted }))
                        }}
                      />

                      {/* 크롭 결과 다운로드 버튼 */}
                      <DownloadButton
                        onClick={() => {
                          openDownloadWarning(cropDownload)
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {/* 이미지 자르기(크롭) 모드 진입 버튼 */}
                      <CropButton
                        onClick={() => {
                          setCropMode(true)
                        }}
                      />

                      {/* 다운로드 버튼 */}
                      <DownloadButton
                        onClick={() => {
                          openDownloadWarning(download)
                        }}
                      />
                    </>
                  )}
                </Box>
              </Box>

              <img
                src={
                  artwork.uploaded.includes('https://upload-pipeline-data')
                    ? artwork.uploaded
                    : getS3ImageSrc(artwork.uploaded)
                }
                onLoad={onLoadUploaded}
                style={{ display: 'none' }}
              />
            </CenterAlignStack>
          </Box>
        </Dialog>
      </Desktop>
    </>
  )
}

function BeforeAfter() {
  return <></>
}

function CropImage() {
  return <></>
}

function ModifyProductSize() {
  return <></>
}

// ------------------------------
// ------------------------------
// ------------------------------
// ------------------------------
// ------------------------------

const SwiperNavButtonBox = styled(Box)(({ theme }) => ({
  // minWidth: '4rem',
  // width: '4rem',
  // height: '4rem',
  minWidth: '8rem',
  width: '8rem',
  height: '8rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0.4rem',
  background: 'rgba(255, 255, 255, 0.3)',

  // opacity: 0.3,

  '&:hover': {
    background: 'rgba(255, 255, 255, 0.5)',
  },
}))

const SwiperPrevButton = () => (
  <SwiperNavButtonBox className="button-box">
    {/* <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5125 0.488155C10.9117 -0.112651 9.9663 -0.158867 9.31249 0.349508L9.15547 0.488155L0.82214 8.82149C0.221333 9.4223 0.175117 10.3677 0.683492 11.0215L0.82214 11.1785L9.15547 19.5118C9.80635 20.1627 10.8616 20.1627 11.5125 19.5118C12.1133 18.911 12.1595 17.9657 11.6511 17.3118L11.5125 17.1548L4.35898 10L11.5125 2.84518C12.1133 2.24437 12.1595 1.29899 11.6511 0.645168L11.5125 0.488155Z"
        fill="white"
      />
    </svg> */}
    <svg
      width="3rem"
      height="4rem"
      viewBox="0 0 24 40"
      style={{ marginLeft: '-0.5rem' }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0236 0.976311C21.822 -0.225302 19.9313 -0.317734 18.6236 0.699015L18.3096 0.976311L1.64294 17.643C0.441323 18.8446 0.348892 20.7354 1.36564 22.043L1.64294 22.357L18.3096 39.0237C19.6114 40.3254 21.7219 40.3254 23.0236 39.0237C24.2253 37.8221 24.3177 35.9313 23.3009 34.6237L23.0236 34.3096L8.71663 20L23.0236 5.69036C24.2253 4.48874 24.3177 2.59797 23.3009 1.29034L23.0236 0.976311Z"
        fill="white"
      />
    </svg>
  </SwiperNavButtonBox>
)

const SwiperNextButton = () => (
  <SwiperNavButtonBox className="button-box">
    {/* <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.488155 0.488155C1.08896 -0.112651 2.03435 -0.158867 2.68817 0.349508L2.84518 0.488155L11.1785 8.82149C11.7793 9.4223 11.8255 10.3677 11.3172 11.0215L11.1785 11.1785L2.84518 19.5118C2.1943 20.1627 1.13903 20.1627 0.488155 19.5118C-0.112651 18.911 -0.158867 17.9657 0.349508 17.3118L0.488155 17.1548L7.64167 10L0.488155 2.84518C-0.112651 2.24437 -0.158867 1.29899 0.349508 0.645168L0.488155 0.488155Z"
        fill="white"
      />
    </svg> */}
    <svg
      width="3rem"
      height="4rem"
      viewBox="0 0 24 40"
      style={{ marginLeft: '0.5rem' }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.976311 0.976311C2.17792 -0.225302 4.06869 -0.317734 5.37633 0.699015L5.69036 0.976311L22.357 17.643C23.5586 18.8446 23.6511 20.7354 22.6343 22.043L22.357 22.357L5.69036 39.0237C4.38861 40.3254 2.27806 40.3254 0.976311 39.0237C-0.225302 37.8221 -0.317734 35.9313 0.699015 34.6237L0.976311 34.3096L15.2833 20L0.976311 5.69036C-0.225302 4.48874 -0.317734 2.59797 0.699015 1.29034L0.976311 0.976311Z"
        fill="white"
      />
    </svg>
  </SwiperNavButtonBox>
)

const BeforeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alighItems: 'center',
  width: '10rem',
  color: 'white',
  fontSize: '1.6rem',
  fontWeight: 600,
  paddingTop: '0.8rem',
  paddingBottom: '0.8rem',
  background: '#30303080',
  borderRadius: '1rem',
  zIndex: 10,

  [theme.breakpoints.down('lg')]: {},
}))

const AfterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alighItems: 'center',
  width: '10rem',
  color: 'white',
  fontSize: '1.6rem',
  fontWeight: 600,
  paddingTop: '0.8rem',
  paddingBottom: '0.8rem',
  background: 'rgb(44, 77, 255, 0.5)',
  borderRadius: '1rem',
  zIndex: 10,

  [theme.breakpoints.down('lg')]: {},
}))

function DownloadButton(props) {
  const { t } = useTranslation()
  return (
    <>
      <DialogButton
        variant="contained"
        startIcon={
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.5 11C16.0128 11 16.4355 11.386 16.4933 11.8834L16.5 12V16C16.5 16.5128 16.114 16.9355 15.6166 16.9933L15.5 17H1.5C0.987164 17 0.564493 16.614 0.506728 16.1166L0.5 16V12C0.5 11.4477 0.947715 11 1.5 11C2.01284 11 2.43551 11.386 2.49327 11.8834L2.5 12V15H14.5V12C14.5 11.4872 14.886 11.0645 15.3834 11.0067L15.5 11ZM8.5 0C9.05229 0 9.5 0.447715 9.5 1L9.501 9.584L12.7929 6.29289C13.1534 5.93241 13.7206 5.90468 14.1129 6.2097L14.2071 6.29289C14.5676 6.65338 14.5953 7.22061 14.2903 7.6129L14.2071 7.70711L9.20711 12.7071C8.84662 13.0676 8.27939 13.0953 7.8871 12.7903L7.79289 12.7071L2.79289 7.70711C2.40237 7.31658 2.40237 6.68342 2.79289 6.29289C3.15338 5.93241 3.72061 5.90468 4.1129 6.2097L4.20711 6.29289L7.501 9.586L7.5 1C7.5 0.447715 7.94772 0 8.5 0Z"
              fill="#303030"
            />
          </svg>
        }
        sx={{
          px: '7.8rem',
          flex: '0 0 auto',
          minWidth: { lg: '24rem' },
        }}
        {...props}
      >
        {t('button.download')}
      </DialogButton>
    </>
  )
}

function CropRatioButton1(props) {
  const { t } = useTranslation()
  return (
    <>
      <DialogButton
        variant="contained"
        sx={{
          flex: '0 0 auto',
          px: '2rem',
          width: { lg: '8rem' },
        }}
        {...props}
      >
        1:1
      </DialogButton>
    </>
  )
}

const CropButton = ({ sx, ...props }) => (
  <DialogButton
    variant="contained"
    sx={{
      px: '2.3rem',
      minWidth: { lg: '7.1rem' },
      ...sx,
    }}
    {...props}
  >
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.90039 6.60812H7.37162M7.37162 2.3999V15.5506C7.37162 16.1316 7.84264 16.6026 8.42368 16.6026H22.1004M18.1552 21.8629V16.8657M17.8922 13.4465V7.66018C17.8922 7.07914 17.4211 6.60812 16.8401 6.60812H11.3168"
        stroke="#303030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </DialogButton>
)
const CancelButton = ({ sx, ...props }) => {
  const { t } = useTranslation()
  return (
    <DialogButton
      variant="contained"
      startIcon={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.694 6.30602C18.102 6.71405 18.102 7.37559 17.694 7.78361L13.4783 11.9993L17.694 16.2164C18.102 16.6244 18.102 17.286 17.694 17.694C17.286 18.102 16.6244 18.102 16.2164 17.694L12.0007 13.4769L7.78361 17.694C7.37559 18.102 6.71405 18.102 6.30602 17.694C5.89799 17.286 5.89799 16.6244 6.30602 16.2164L10.5231 11.9993L6.30602 7.78361C5.89799 7.37559 5.89799 6.71405 6.30602 6.30602C6.71405 5.89799 7.37559 5.89799 7.78361 6.30602L12.0007 10.5217L16.2164 6.30602C16.6244 5.89799 17.286 5.89799 17.694 6.30602Z"
            fill="#FF2323"
          />
        </svg>
      }
      sx={{
        px: '2.3rem',
        flex: '0 0 auto',
        ...sx,
      }}
      {...props}
    >
      {t('button.cancel')}
    </DialogButton>
  )
}

const BeforeAfterArrow = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="url(#paint0_linear_10830_5089)" />
    <path d="M34 24L19 32.6603L19 15.3397L34 24Z" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_10830_5089"
        x1="0"
        y1="24"
        x2="48"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C4DFF" />
        <stop offset="1" stopColor="#8D00FC" />
      </linearGradient>
    </defs>
  </svg>
)

const CustomSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: '18.8rem',
  height: '4.8rem',
  padding: 0,

  '& .MuiSwitch-switchBase': {
    height: '100%',
    padding: '2px',
    '&:hover': {
      background: 'transparent',
    },
    '& .MuiSwitch-thumb': {
      background: theme.palette.common.black,
      height: '100%',
      lineHeight: '2rem',
      width: '9.2rem',
      borderRadius: '30px',
      '&::after': {
        display: 'inline-block',
        content: '"원본 비교"',
        color: 'white',
      },

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '&.Mui-checked': {
      transform: 'translateX(92px) !important',
    },
  },

  '& .MuiSwitch-track': {
    borderRadius: '30px',
    width: '100%',
    height: '100%',

    background: '#FFFFFFD9',
    opacity: 1,

    display: 'flex',
    alignItems: 'center',

    '&::after': {
      display: 'flex',
      justifyContent: 'center',
      width: '9.2rem',
      // background: 'gold',
      content: '"결과물"',
    },
  },

  '& .Mui-checked+.MuiSwitch-track': {
    background: '#FFFFFFD9 !important',
    opacity: '1 !important',
  },
}))

function DisplayModeSwitch({ displayMode, setDisplayMode }) {
  // checked ? '결과물만 보기' : '원본 비교 보기'
  const checked = displayMode === 'result'

  return (
    <CenterAlignStack
      direction="row"
      sx={{
        background: '#FFFFFFD9',
        width: 'fit-content',
        position: 'relative',
        cursor: 'pointer',
        borderRadius: '30px',

        boxShadow: '4px 4px 20px 0px #00000040',

        p: '2px',
        '& .item': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '4.4rem',
          width: '9.2rem',

          fontSize: '1.6rem',
          fontWeight: 600,
        },

        '& .thumb': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          height: '4.4rem',
          width: '9.2rem',
          background: theme => theme.palette.common.black,
          borderRadius: '30px',
          color: 'white',
          transform: checked ? 'translateX(100%)' : 'translateX(0)',
          transition: 'all 0.2s ease',
          fontSize: '1.6rem',
          fontWeight: 600,
        },
      }}
    >
      <Box
        className="item"
        onClick={() => {
          setDisplayMode('compare')
        }}
      >
        <Korean>원본 비교</Korean>
        <Japanese>元画像との比較</Japanese>
        <English>Compare</English>
      </Box>
      <Box
        className="item"
        onClick={() => {
          setDisplayMode('result')
        }}
      >
        <Korean>결과물</Korean>
        <Japanese>結果物</Japanese>
        <English>Result</English>
      </Box>
      <Box className="thumb">
        {checked ? (
          <>
            <Korean>결과물</Korean>
            <Japanese>結果物</Japanese>
            <English>Result</English>
          </>
        ) : (
          <>
            <Korean>원본 비교</Korean>
            <Japanese>元画像との比較</Japanese>
            <English>Compare</English>
          </>
        )}
      </Box>
    </CenterAlignStack>
  )
}

function GuestButton({ setOpen }) {
  const setPleaseLoginDialog = useSetRecoilState(pleaseLoginDialogAtom)

  const handleClickDownload = () => {
    setOpen(false)
    setPleaseLoginDialog({ open: true, type: 'download' })
    // ----- GA4 event -----
    window.gtag('event', 'non_member_removebg_download', {
      method: 'dialog',
    })
    // ---------------------
  }
  return (
    <>
      <DownloadButton onClick={handleClickDownload} />
    </>
  )
}
