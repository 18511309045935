import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useAssets } from 'hooks/useAssets'
import { useTranslation } from 'react-i18next'
import { AssetGridItem } from './AssetGridItem'
import { AssetSearch } from './AssetSearch'
import { imgResizeAndBinary, useHandleGetNpy } from 'components/portfolio/UploadMannequinDialog'
import { portfolioArtworkAtom } from 'atoms'
import { useRecoilValue } from 'recoil'

export const MyAssetDialog = ({ open, onClose, setFiles, isMannequin, multiple }) => {
  const { t } = useTranslation()
  const { page, changePage, totalPages, myAssets, selectAsset, currentPageAsset } =
    useAssets('page')

  const handleGetNpy = useHandleGetNpy()
  const portfolioArtwork = useRecoilValue(portfolioArtworkAtom)

  const handleConfirmSelection = async () => {
    const selectedAssets = Object.values(myAssets)
      .flat()
      .filter(asset => asset.selected)

    const files = await Promise.all(
      selectedAssets.map(async asset => {
        const response = await fetch(`${asset.file_url}?w=${Date.now()}`)
        const blob = await response.blob()

        const file = new File([blob], asset.name, {
          type: blob.type,
        })

        return file
      })
    )

    const newFiles = files.map(i => ({
      file: i,
      url: URL.createObjectURL(i),
      size: i.size,
    }))

    setFiles(prev => prev.concat(newFiles))
    onClose()

    if (isMannequin) {
      const image = await imgResizeAndBinary(newFiles)

      handleGetNpy({ image, service: 'mannequin', isRegen: true, artworkId: portfolioArtwork.id })
    }

    return () => {
      newFiles.forEach(file => {
        URL.revokeObjectURL(file.url)
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: 869,
          width: '100%',
          boxSizing: 'border-box',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography sx={{ fontSize: '22px', fontWeight: 700, color: 'draph.blue' }}>
          {t('asset.my_asset_dialog.title')}
        </Typography>
        <Typography sx={{ fontSize: '15px', color: '#595959', fontWeight: 400 }}>
          {t('asset.my_asset_dialog.subtitle')}
        </Typography>
      </DialogTitle>
      <div></div>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', pt: 0 }}>
        <AssetSearch />
        <Grid container rowSpacing={'20px'} columnSpacing={'8px'} pb={'36px'} columns={5}>
          {currentPageAsset.length === 0 ? (
            <Grid xs={1} item>
              <Typography sx={{ textAlign: 'center', fontSize: '2rem' }}>
                {t('asset.my_asset_dialog.no_image')}
              </Typography>
            </Grid>
          ) : (
            currentPageAsset.map(asset => (
              <AssetGridItem key={asset.id} asset={asset} onClick={() => selectAsset(asset.id)} />
            ))
          )}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            showFirstButton
            showLastButton
            siblingCount={2}
            boundaryCount={1}
            count={Math.ceil(totalPages)}
            page={page}
            onChange={(e, p) => changePage(p)}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pt: 0, pb: '40px' }}>
        <Button
          sx={{
            bgcolor: 'white',
            color: 'common.black',
            fontSize: '1.6rem',
            fontWeight: 400,
          }}
          onClick={onClose}
        >
          {t('asset.my_asset_dialog.cancel')}
        </Button>
        <Button
          sx={{
            width: 200,
            bgcolor: 'draph.blue',
            color: 'white',
            fontSize: '1.6rem',
          }}
          onClick={handleConfirmSelection}
        >
          {t('asset.my_asset_dialog.next')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
