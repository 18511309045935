import { Button, Dialog, DialogContent, Slide, Stack, Typography, useTheme } from '@mui/material'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { OpacityMotionDiv } from './CustomStyle'
import MobileSwipeableDrawer from './MobileSwipeableDrawer'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { isKo, trasnferDate } from 'utils/common'
import { useRecoilValue } from 'recoil'
import { userAtom } from 'atoms'
import moment from 'moment'
import { apis } from 'apis'
import { English, Japanese, Korean } from 'hooks/useLanguage'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

// const style = {
//   title: { fontSize: { lg: '2.4rem', xs: '1.6rem' }, fontWeight: 700 },
//   subtitle: {
//     textAlign: 'center',
//     fontSize: { lg: '1.4rem', xs: '1.2rem' },
//     '& span': { fontWeight: 700 },
//   },

//   content: { fontSize: { lg: '1.6rem', xs: '1.3rem' } },
// }

export default function UserWithdraw({ open, setOpen, info }) {
  const isMobile = useMobileMediaQuery()

  const { t, i18n } = useTranslation()
  const user = useRecoilValue(userAtom)
  const theme = useTheme()

  const departureDate = moment('2024-05-24')
  const today = moment(new Date())

  const style = {
    title: {
      fontSize: { lg: isKo(i18n) ? '2.4rem' : '2.3rem', xs: isKo(i18n) ? '1.3rem' : '1.6rem' },
      fontWeight: 700,
    },
    subtitle: {
      textAlign: 'center',
      fontSize: { lg: '1.4rem', xs: '1.2rem' },
      '& span': { fontWeight: 700 },
    },

    content: {
      fontSize: { lg: isKo(i18n) ? '1.6rem' : '1.3rem', xs: isKo(i18n) ? '1.3rem' : '1rem' },
    },
  }

  const handleClose = () => {
    // ----- GA4 event -----
    window.gtag('event', 'delete_id_login_try', { method: 'cancel' })
    // ---------------------
    setOpen(false)
  }

  // 철회 프로세스
  const handleAction = () => {
    // ----- GA4 event -----
    window.gtag('event', 'delete_id_login_try', { method: 'recover_id' })
    // ---------------------
    apis.user
      .cancelLeaveDraph({
        username: info.username,
        password: info.pw,
        ...info,
      })
      .then(response => {
        if (response.data.success) {
          alert(t('user_withdraw.warning'))
        } else {
          alert(t('common.error'))
        }

        setOpen(false)
      })
  }

  const logos = [
    { name: 'google', img: '/static/images/logo/outside/google_logo.png' },
    { name: 'kakao', img: '/static/images/logo/outside/kakao_logo.png' },
    { name: 'naver', img: '/static/images/logo/outside/naver_logo.png' },
    { name: 'draph', img: '/static/images/logo/logo_image.png' },
  ]

  const avatarImg = info.sns
    ? logos.find(logo => logo.name === info.sns)?.img ||
      logos.find(logo => logo.name === 'draph')?.img
    : undefined
  // const avatarImg = user.id
  //   ? logos.find(logo => logo.name === user.login_sns)?.img ||
  //     logos.find(logo => logo.name === 'draph')?.img
  //   : undefined

  const content = (
    <CenterAlignStack>
      <Typography sx={{ ...style.title, textAlign: 'center' }}>
        {t('user_withdraw.title')}
      </Typography>

      <CenterAlignStack sx={{ mt: { lg: '2rem', xs: '1.2rem' } }}>
        <Korean>
          <Typography sx={{ ...style.subtitle }}>
            {t('user_withdraw.subtitle_1')}
            <br />
            <span>{t('user_withdraw.subtitle_2')}</span>
            <br />
            <span>{t('user_withdraw.subtitle_3')}</span>
            <br />
            <span>{t('user_withdraw.subtitle_4')}</span>
          </Typography>
        </Korean>
        <Japanese>
          <Typography sx={{ ...style.subtitle }}>
            {t('user_withdraw.subtitle_1')}
            <br />
            <span>{t('user_withdraw.subtitle_2')}</span>
            <br />
            <span>{t('user_withdraw.subtitle_3')}</span>
            <br />
            <span>{t('user_withdraw.subtitle_4')}</span>
          </Typography>
        </Japanese>
        <English>
          <Typography sx={{ ...style.subtitle, textAlign: 'left' }}>
            Thank you for coming back.
            <br />
            <span>
              All information in that ID will be completely deleted after 90 days from the date of
              application for withdrawal, , and the existing information can be used as it is when
              the application is withdrawn.
            </span>
          </Typography>
        </English>
      </CenterAlignStack>

      <Stack
        sx={{
          backgroundColor: '#F8F8F8',
          width: '100%',
          p: '1.6rem 2rem',
          borderRadius: '10px',
          mt: { lg: '2rem', xs: '1.6rem' },
          mb: { lg: '2.4rem', xs: '2.4rem' },

          '& span': {
            fontWeight: 600,
          },
        }}
      >
        <Typography sx={{ ...style.content }}>
          {t('user_withdraw.notice_head_1')} : &nbsp;
          <span>
            {info?.leave_requested_at && trasnferDate(info.leave_requested_at, 'detail', i18n)}
          </span>
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            ...style.content,
          }}
        >
          {t('user_withdraw.notice_head_2')} : &nbsp;
          <span
            style={{
              maxWidth: isMobile ? '15rem' : '20rem',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {info.username}
          </span>{' '}
          &nbsp;
          <img src={avatarImg} style={{ height: '1.6rem', width: 'auto' }} />
        </Typography>
        <Typography sx={{ ...style.content }}>
          {t('user_withdraw.notice_head_3')} : &nbsp;
          <span style={{ color: theme.palette.draph.blue }}>
            {info.withdraw_cancel_by &&
              '~' + trasnferDate(info.withdraw_cancel_by, 'detail', i18n) + ' '}
            ({-today.diff(info.withdraw_cancel_by, 'days') + t('user_withdraw.left')})
          </span>
        </Typography>
      </Stack>
      <CenterAlignBox sx={{}}>
        <Button
          sx={{
            width: '10.8rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 400,
            borderRadius: '4px',
            color: theme.palette.common.black,
            '&.Mui-disabled': {
              color: theme.palette.grey[500],
            },
            '&:hover': { background: '#ffffff', color: theme.palette.common.red },
          }}
          onClick={handleClose}
        >
          {t('button.confirm')}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: '9.9rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 400,
            borderRadius: '4px',
          }}
          onClick={handleAction}
        >
          {t('user_withdraw.button')}
        </Button>
      </CenterAlignBox>
    </CenterAlignStack>
  )

  return (
    <>
      {' '}
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              width: { lg: '40rem', xs: '320px' },
              minWidth: { lg: '40rem', xs: '430px' },
              minHeight: { lg: '27.7rem', xs: '430px' },
              margin: { lg: '20px', xs: 0 },
              borderRadius: '10px',
            },
          }}
        >
          <DialogContent
            sx={{
              overflowY: 'hidden',
              padding: '2.8rem 2.4rem',
            }}
          >
            {open && <OpacityMotionDiv>{content}</OpacityMotionDiv>}
          </DialogContent>
        </Dialog>
      </Desktop>
      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={handleClose}
        >
          <CenterAlignStack sx={{ mt: '0.8rem', mb: '2.2rem', px: '2rem' }}>
            {content}
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}
