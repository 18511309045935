import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { confirmDialogAtom, currentMenuAtom, pleaseLoginDialogAtom } from 'atoms'
import { alpha } from '@mui/material/styles'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { useNavigate } from 'react-router-dom'
import { Mobile } from 'hooks/useMediaQuery'
import { English, Japanese, Korean } from 'hooks/useLanguage'
import * as cookie from 'utils/cookie'

const DownloadNoticeContent = () => (
  <>
    <Typography className="text">
      <Korean>
        드랩아트 회원은 <span className="highlight-blue">무제한 이미지 다운로드</span> 가 가능해요
        <br />
        회원가입 후 다운 받는 모든 이미지는 상업적 사용이 가능합니다.
      </Korean>
      <Japanese>
        ドラップアートの会員になると、
        <span className="highlight-blue">無制限で画像をダウンロード</span>できます。
        <br />
        会員登録後にダウンロードするすべての画像は商用利用が可能です。
      </Japanese>
      <English>
        Draph Art members can
        <span className="highlight-blue">download images unlimitedly</span>
      </English>
    </Typography>
  </>
)

export default function PleaseLoginDialog() {
  const [dialog, setDialog] = useRecoilState(pleaseLoginDialogAtom)
  const { open, type } = dialog

  const resetData = useResetRecoilState(pleaseLoginDialogAtom)
  const currentMenu = useRecoilValue(currentMenuAtom)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClose = (event, reason) => {
    setDialog({ open: false, type: type })

    setTimeout(() => {
      resetData()
    }, 200)

    // ----- GA4 event -----
    let method = null
    if (reason) {
      if (reason === 'backdropClick') {
        method = 'dimmed'
      } else {
        method = reason
      }
    }
    window.gtag('event', 'non_member_try_to_upload_popup', { menu: currentMenu.id, method })
    // ---------------------
  }

  const setRedirect = () => {
    if (currentMenu.id === 'removebg') {
      cookie.setCookie(cookie.guestRedirectPath, '/generate/removebg')
    } else {
      cookie.removeCookie(cookie.guestRedirectPath)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        zIndex: 9999,
        '& .MuiDialog-paper': { width: { lg: '50rem' }, height: { lg: '20rem', xs: '17rem' } },
      }}
      // hideBackdrop={isHide}
    >
      <DialogContent
        sx={{ p: { lg: '2rem', xs: '2rem' }, pt: '1.4rem', fontSize: '1.6rem', fontWeight: 400 }}
      >
        <CenterAlignStack
          sx={{
            height: '100%',
            justifyContent: 'center',
            '& .text': {
              fontWeight: 500,
              textAlign: 'center',
              fontSize: { lg: '1.4rem', xs: '1.2rem' },
              lineHeight: 1.8,
              '& .highlight-blue': {
                background: theme => theme.palette.draph.newblue,
                color: 'white',
                px: '0.65rem',
                py: '0.4rem',
                borderRadius: '4px',
                fontSize: { lg: '1.3rem', xs: '1.1rem' },
                fontWeight: 600,
              },
              '& .highlight-lightblue': {
                background: '#E3ECFF',
                color: theme => theme.palette.draph.newblue,
                px: '0.95rem',
                py: '0.4rem',
                borderRadius: '4px',
                fontSize: { lg: '1.2rem', xs: '1.1rem' },
                fontWeight: 500,
                mr: '0.4rem',
              },
            },
          }}
        >
          {type === 'download' ? (
            <DownloadNoticeContent />
          ) : (
            <>
              <Typography className="text">
                <Korean>
                  드랩아트에 로그인하시면 다양한{' '}
                  <span className="highlight-blue">상업용 이미지 생성</span> 이 가능해요.
                  <br />
                  회원가입 후 다운 받는 모든 이미지는 상업적 사용이 가능합니다.
                </Korean>
                <Japanese>
                  ドラップアートにログインすると、さまざまな{' '}
                  <span className="highlight-blue">商用画像生成</span>が可能です。
                  <br />
                  会員登録後にダウンロードするすべての画像は商用利用が可能です。
                </Japanese>
                <English>
                  Login for Draph Art and get a variety of{' '}
                  <span className="highlight-blue">commercial images</span>
                </English>
              </Typography>
            </>
          )}

          <br />
          <Typography className="text">
            <Korean>
              <span className="highlight-lightblue">진행 중 이벤트</span>{' '}
              {new Date().getMonth() + 1}월 회원가입 시, 5크레딧 무료 지급 중!
            </Korean>
            <Japanese>
              <span className="highlight-lightblue">進行中のイベント</span>{' '}
              {new Date().getMonth() + 1}月に会員登録すると、5クレジットを無料でプレゼント！
            </Japanese>
            <English>
              <span className="highlight-lightblue">
                Event on {new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date())}
              </span>{' '}
              Sign up and get 5 free credits !
            </English>
          </Typography>
        </CenterAlignStack>
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        {/* 더 둘러보기 버튼 */}
        {/* <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            width: '50%',
            height: '4.8rem',
            backgroundColor: '#F8F8F8',
            borderRadius: '0',
            color: theme => theme.palette.common.black,
            '&:hover': {
              backgroundColor: '#F8F8F8',
              color: theme => theme.palette.common.black,
            },
          }}
        >
          {t('please_login_dialog.button_cancel')}
        </Button> */}

        <Button
          variant="contained"
          onClick={() => {
            setRedirect()
            navigate('/login')
            handleClose(null, 'login')
          }}
          sx={{
            width: '50%',
            height: '4.8rem',
            backgroundColor: '#F8F8F8',
            borderRadius: '0',
            color: theme => theme.palette.common.black,
            '&:hover': {
              backgroundColor: '#F8F8F8',
              color: theme => theme.palette.common.black,
            },
          }}
        >
          <Korean>기존 회원 로그인</Korean>
          <Japanese>既存会員ログイン</Japanese>
          <English>Login</English>
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            setRedirect()
            navigate('/join')
            handleClose(null, 'sign_up')
          }}
          sx={{
            ml: '0 !important',
            height: '4.8rem',
            borderRadius: '0',
            width: '50%',
            color: theme => theme.palette.common.white,
            '&:hover': {
              color: theme => theme.palette.common.white,
            },
          }}
        >
          <Korean>신규 회원 가입</Korean>
          <Japanese>新規会員登録</Japanese>
          <English>Sign up</English>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
