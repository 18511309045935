import { v4 as uuidv4 } from 'uuid'

export const isRemovedBg = path => path?.includes('bgremoved')
export const bgRemovedStyle = {
  background:
    'linear-gradient(45deg, lightgray 25%, transparent 25%), linear-gradient(-45deg, lightgray 25%, transparent 25%), linear-gradient(45deg, transparent 75%, lightgray 75%), linear-gradient(-45deg, white 75%, lightgray 75%)',
  backgroundSize: '20px 20px' /* 크기 조정 */,
  backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0',
}

const gradientBackground = 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)'

export const GRADIENT_TEXT_STYLE = {
  background: gradientBackground,
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textFillColor: 'transparent',
}

export const getUploadUUID = () => {
  // 하이픈 포함 36자. 디비에서 40자가 넘어가면 안됨~
  return uuidv4()
}

export const ADDPIECE_SELECTOR = 'addpiecetemp'
export const checkedURLforNoCache = path => {
  if (path?.includes(ADDPIECE_SELECTOR)) {
    return `${path}?w=${new Date()}`
  }
  return path
}

const getPiecePath = fullPath => {
  // 피스의 전체 path에서 아트워크id 부분 제외한 피스 파일명 부분만 남겨서 리턴
  return fullPath?.split('/').pop()
}

const removeExtension = str => {
  // 확장자 제거
  const lastDotIndex = str.lastIndexOf('.')
  if (lastDotIndex === -1) return str // 확장자가 없는 경우 원본 반환
  return str.substring(0, lastDotIndex)
}

const UPSCALED = 'upscaled'
const RECOMM_TYPES = ['draph_recommend_prompt', 'draph_recommend_theme']
const RECOMM_PROMPT = 'draph_recommend_prompt'
const RECOMM_THEME = 'draph_recommend_theme'

export const canUpscale = piece => {
  if (!piece) return
  const p = getPiecePath(piece.path).split('/').pop()
  const can =
    (p.includes('imggen_result') ||
      p.includes('simplegen_result') ||
      p.includes('compogen_result') ||
      p.includes(RECOMM_PROMPT) ||
      p.includes(RECOMM_THEME)) &&
    !p.includes('animated') &&
    !p.includes(UPSCALED)

  return can
}

export const isUpscaledPiece = piece => {
  // 타겟 piece가 업스케일된 이미지인지 확인
  if (!piece) return
  const fileName = piece.path.split('/').at(-1)

  return fileName.includes(UPSCALED)
}

export const isRecommPiece = piece => {
  // 타겟 piece가 업스케일된 이미지인지 확인
  if (!piece?.path) return

  const fileName = piece.path.split('/').at(-1)
  return RECOMM_TYPES.some(type => fileName?.includes(type))
}

export const hasUpscaledImage = (piece, artwork) => {
  // 타겟 piece에 대하여, 이미 업스케일된 이미지가 아트워크 내에 존재하는지 확인
  if (!piece || !artwork?.pieces) return
  const p = removeExtension(getPiecePath(piece.path))

  const up = artwork.pieces?.filter(piece => {
    const x = removeExtension(getPiecePath(piece.path))

    return x.includes(p) && x.includes(UPSCALED)
  })

  return !p.includes(UPSCALED) && up.length > 0
}

export const checkBgremovedPiece = path => {
  return path.split('/').slice(-1)[0].includes('bgremoved')
}

export const getPieceDownloadCondition = artwork => {
  // 누끼 이외의 피스를 다운받은 이력이 있는지 확인 (재생성을 막기 위해)

  const p = artwork.pieces
  const d = p.filter(p => !checkBgremovedPiece(p.path) && p.download)

  return d.length && d.length > 0
}
