import { useCallback, useEffect, useState } from 'react'
import { Router } from './routes'
import { apis } from './apis'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import ThemeConfig from './theme'
import './assets/css/App.css'
import GlobalStyles from './theme/globalStyles'
import { Backdrop, Dialog } from '@mui/material'

import { Tutorial, TutorialExitButton } from './theme/Tutorial'
import {
  backgroundLoadingAtom,
  newPortfolioAtom,
  tutorialAtom,
  userAtom,
  serverStatusAtom,
  noticeBannersAtom,
  currentMenuAtom,
  creditPlansAtom,
  uploadFilesAndUrlAtom,
  appStatusAtom,
} from './atoms'
import {
  ConfirmDialog,
  ZeroCreditWarningDialog,
  DragDropFullSize,
  Loading,
  PleaseLoginDialog,
  ScrollToTopComponenet,
  AllowBrowserNotiDialog,
  DownloadWarningDialog,
  CenterAlignBox,
} from './components'

import { useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'
import WelcomeDialog from 'components/WelcomeDialog'
import { CheckForIndexHTMLChange, isDev } from 'utils/common'
import ChannelService from 'utils/channeltalk'

import useMetaEdit from 'hooks/useMetaEdit'
import { useMenu } from 'hooks/useMenu'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { setCookie } from './utils/cookie'
import { ErrorBoundary } from 'react-error-boundary'
import { configData } from 'config'
import { ErrorBoundaryPage } from 'pages/ErrorBoundaryPage'
import dayjs from 'dayjs'
import { useReportError } from 'hooks/useReportError'

// 드랩인 - 수정시 데브디비 말고 프로드디비 유저아이디 기준으로 잘 체크하기!!
export const RIGHT_CLICK_ALLOW_USER = [2, 3, 5, 9, 12, 20, 37, 1229, 1272, 700] // 어드민, 태훈, 민주, 가영, 하은, 진호, 대한, 지혜, 가영2, 형훈, 진호2, 민우

const INDEX_HTML_PATH = `${window.location.origin}/index.html`

localStorage.setItem('DRAPH_ART_REFRESH', Date.now()) // checkUpdate 사용하려면 꼭 넣어주기
async function checkUpdate() {
  const lastRefresh = localStorage.getItem('DRAPH_ART_REFRESH')
  let needRefresh = false
  if (lastRefresh) {
    const diffMin = Math.floor((Date.now() - lastRefresh) / 60000)
    if (diffMin < 120) return
    needRefresh = await CheckForIndexHTMLChange(INDEX_HTML_PATH)
  }
  localStorage.setItem('DRAPH_ART_REFRESH', Date.now())
  if (needRefresh) window.location.reload()
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      retry: 1, // 최대 3번 재시도
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000), // 지수 백오프
      staleTime: 60 * 3000,
      refetchOnWindowFocus: false, // 브라우저에 포커스가 돌아올 때 refetch
    },
  },
})

function App() {
  const tutorial = useRecoilValue(tutorialAtom)
  const setPlans = useSetRecoilState(creditPlansAtom)
  const backgroundLoading = useRecoilValue(backgroundLoadingAtom)
  const user = useRecoilValue(userAtom)
  const isMobile = useMobileMediaQuery()

  const location = useLocation()

  const [serverStatus, setServerStatus] = useRecoilState(serverStatusAtom)
  const [noticeBanners, setNoticeBanners] = useRecoilState(noticeBannersAtom)
  const [appStatus, setAppStatus] = useRecoilState(appStatusAtom)

  const { i18n } = useTranslation()

  const { getCurrentMenu } = useMenu()

  const setCurrentMenu = useSetRecoilState(currentMenuAtom)
  const { onError } = useReportError()

  const { pageTitle, metaDescription } = useMetaEdit()
  useEffect(() => {
    // 라우팅 바뀔 때 업데이트 체크하여 리프레시
    checkUpdate()
    setCurrentMenu(getCurrentMenu())

    apis.common.getAppStatus().then(response => {
      setAppStatus(response.data)
      const beStatus = response.data.filter(s => s.server === 'backend')[0]
      if (beStatus) {
        setServerStatus({
          on: beStatus.on,
          notice: beStatus.notice,
        })

        if (!beStatus.on && beStatus.notice.length > 0) {
          setNoticeBanners([
            {
              id: 1,
              type: beStatus.notice_type ?? 'update',
              content: { ko: beStatus.notice, en: null },
            },
          ])
        } else if (beStatus.on) {
          setNoticeBanners([])
        }
      } else {
        setNoticeBanners([])
      }
    })
  }, [location])

  useEffect(() => {
    if (tutorial.mode && tutorial.step === 0) {
      // ----- GA4 event -----
      window.gtag('event', 'tutorial_begin', {})
      // ---------------------
    }
  }, [tutorial.mode])

  useEffect(() => {
    if (window.location.host === 'draph.art' && !RIGHT_CLICK_ALLOW_USER.includes(user.id)) {
      document.oncontextmenu = () => {
        return false
      }
    } else {
      document.oncontextmenu = () => {
        return true
      }
    }
  }, [user])

  useEffect(() => {
    initChannelTalk()
  }, [])

  useEffect(() => {
    if (window.ChannelIO) {
      if (appStatus?.filter(s => s.server === 'channeltalk')[0]?.on && i18n.language === 'ko') {
        ChannelService.showChannelButton()
        // ChannelService.showMessenger()
      } else {
        ChannelService.hideChannelButton()
        ChannelService.hideMessenger()
      }
    }
  }, [appStatus])

  useEffect(() => {
    if (window.ChannelIO) {
      ChannelService.clearCallbacks()
      ChannelService.onChatCreated(() => {
        onChannelTalkChatCreated()
      })
    }
  }, [user?.id])

  useEffect(() => {
    apis.common.getVersion() // crud 작동 체크겸

    const handleKeyDown = e => {
      // cmd+s 또는 ctrl+s 윈도우에서 ctrl+s 막아지는지 확인할 것
      if ((e.metaKey || e.ctrlKey) && e.key === 's') {
        e.preventDefault()
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    apis.common.getPlans().then(response => {
      const d = response.data
      const subs = d.filter(p => p.plan_type === 'subscription')
      const payGo = d.filter(p => p.plan_type === 'paygo')
      const bespoke = d.filter(p => p.plan_type === 'bespoke')

      const plans = {
        subscription: subs,
        payGo,
        bespoke,
      }
      setPlans(plans)
    })

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const initChannelTalk = () => {
    try {
      if (!window.ChannelIO) {
        ChannelService.loadScript()
      }

      const d = {
        pluginKey: 'cb99b67d-5e53-4f70-b02b-1a6658f9eccc',
      }

      // XX 로그인만 해도 유저정보가 넘어가서 MU로 잡히는것을 일단 방지..
      // let d = {
      //   pluginKey: 'cb99b67d-5e53-4f70-b02b-1a6658f9eccc',
      // }
      // if (user?.id) {
      //   d = {
      //     memberId: user?.id,
      //     profile: {
      //       name: user?.username,
      //       email: user?.email,
      //     },
      //     ...d,
      //   }
      // }
      ChannelService.boot(d)
    } catch (error) {
      console.error(error)
    }
  }

  const onChannelTalkChatCreated = useCallback(() => {
    // if (user?.id) {
    //   const d = {
    //     memberId: user?.id, // 어차피 업데이트되지 않음..
    //     profile: {
    //       name: user?.username,
    //       email: user?.email,
    //       test: 'abcd',
    //       x: 'asdf'
    //     },
    //   }
    //   ChannelService.updateUser(d, (x,y) => {console.log(x,y)})
    // }
  }, [user])

  const shutdownChannelTalk = () => {
    try {
      if (window.ChannelIO) {
        ChannelService.shutdown()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <ErrorBoundary onError={onError} FallbackComponent={ErrorBoundaryPage}>
        <Helmet defaultTitle={pageTitle()}>{metaDescription()}</Helmet>
        <QueryClientProvider client={queryClient}>
          <ThemeConfig>
            <GlobalStyles />
            {backgroundLoading && <Loading />}
            <img src="/static/images/logo/logo_v.png" style={{ display: 'none' }} alt="" />
            <Setgtag>
              <Router />
            </Setgtag>
            {/* 구글 대표사진 점수 높이기 */}
            <img src="/static/images/logo/logo_v.png" style={{ display: 'none' }} />

            {tutorial.mode && isMobile && (
              <TutorialExitButton
                style={
                  tutorial.step === '14_1' || tutorial.step === '14_2' || tutorial.step === '14_3'
                    ? { marginTop: '2rem' }
                    : {}
                }
              />
            )}
            <ConfirmDialog />
            <PleaseLoginDialog />
            <ZeroCreditWarningDialog />
            <WelcomeDialog />

            <AllowBrowserNotiDialog />
            {/* <DownloadWarningDialog /> */}
          </ThemeConfig>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  )
  // ThemeConfig 로 감싸주어서 theme/overrides 에서 오버라이드한 MUI 컴포넌트들이 사용됨
}

function Setgtag({ children }) {
  const [isLoading, setLoading] = useState(true)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    setLoading(true)

    const gtagScriptAdd = (trackingId = process.env.REACT_APP_GA_TRACKING_ID) => {
      const gtagSrc = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
      const gtagScript = document.createElement('script')
      gtagScript.setAttribute('src', gtagSrc)
      document.getElementsByTagName('head')[0].appendChild(gtagScript)

      const gtagScript2 = document.createElement('script')
      const gtagTxt = document.createTextNode(
        `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${trackingId}', {'custom_map': {'dimension1': 'user_category'}});`
      )
      gtagScript2.appendChild(gtagTxt)

      if (process.env.NODE_ENV === 'production') {
        let CONVERSION_PURCHASE_ID = 'AW-11287551428/cVeqCKiXqoUZEMS7qYYq'
        let CONVERSION_REGISTER_ID = 'AW-11287551428/rPULCLmHqoUZEMS7qYYq'
        let CONVERSION_BESPOKE_CONSULTING_ID = 'AW-11287551428/McR1CIa-xOAZEMS7qYYq'
        let CONVERSION_BESPOKE_PURCHASE_ID = 'AW-11287551428/BZNmCIadvOAZEMS7qYYq'

        if (window.localStorage.getItem('i18nextLng') === 'ko') {
          CONVERSION_PURCHASE_ID = 'AW-11287551428/LzKECJSUhc0YEMS7qYYq'
          CONVERSION_REGISTER_ID = 'AW-11287551428/TdMNCJGUhc0YEMS7qYYq'
          CONVERSION_BESPOKE_CONSULTING_ID = 'AW-11287551428/McR1CIa-xOAZEMS7qYYq'
          CONVERSION_BESPOKE_PURCHASE_ID = 'AW-11287551428/BZNmCIadvOAZEMS7qYYq'
        }

        if (window.localStorage.getItem('i18nextLng') === 'ja') {
          CONVERSION_PURCHASE_ID = 'AW-11287551428/G-0tCIT8oIQaEMS7qYYq'
          CONVERSION_REGISTER_ID = 'AW-11287551428/8-HUCL79oIQaEMS7qYYq'
          CONVERSION_BESPOKE_CONSULTING_ID = 'AW-11287551428/McR1CIa-xOAZEMS7qYYq'
          CONVERSION_BESPOKE_PURCHASE_ID = 'AW-11287551428/BZNmCIadvOAZEMS7qYYq'
        }

        const conversionPurchase = document.createTextNode(
          `gtag('config', 'AW-11287551428');function gtag_report_conversion_purchase(value, transaction_id, currency) {
              var callback = function () {};
              gtag('event', 'conversion', {
                  'send_to': '${CONVERSION_PURCHASE_ID}',
                  'value': value ?? 1.0,
                  'currency': currency ?? 'KRW',
                  'transaction_id': transaction_id ?? '',
                  'event_callback': callback
              });
              return false;
            }`
        )
        gtagScript2.appendChild(conversionPurchase)

        const conversionRegister = document.createTextNode(`
          function gtag_report_conversion_register() {
            var callback = function () {};
            gtag('event', 'conversion', {
                'send_to': '${CONVERSION_REGISTER_ID}',
                'event_callback': callback
            });
            return false;
          }
        `)
        gtagScript2.appendChild(conversionRegister)

        const conversionBespokeConsulting = document.createTextNode(`
          function gtag_report_conversion_bespoke_consulting() {
            var callback = function () {};
            gtag('event', 'conversion', {
                'send_to': '${CONVERSION_BESPOKE_CONSULTING_ID}',
                'event_callback': callback
            });
            return false;
          }
        `)
        gtagScript2.appendChild(conversionBespokeConsulting)

        const conversionBespokePurchase = document.createTextNode(`
          function gtag_report_conversion_bespoke_purchase() {
            var callback = function () {};
            gtag('event', 'conversion', {
                'send_to': '${CONVERSION_BESPOKE_PURCHASE_ID}',
                'event_callback': callback
            });
            return false;
          }
        `)
        gtagScript2.appendChild(conversionBespokePurchase)
      } else {
        const conversionPurchase = document.createTextNode(
          `function gtag_report_conversion_purchase() {
            return false;
          }`
        )
        gtagScript2.appendChild(conversionPurchase)

        const conversionRegister = document.createTextNode(`
          function gtag_report_conversion_register() {
            return false;
          }
         `)
        gtagScript2.appendChild(conversionRegister)

        const conversionBespokeConsulting = document.createTextNode(`
          function gtag_report_conversion_bespoke_consulting() {
            return false;
          }
        `)
        gtagScript2.appendChild(conversionBespokeConsulting)
        const conversionBespokePurchase = document.createTextNode(`
          function gtag_report_conversion_bespoke_purchase() {
            return false;
          }
        `)
        gtagScript2.appendChild(conversionBespokePurchase)
      }

      document.getElementsByTagName('head')[0].appendChild(gtagScript2)
    }

    const addPixelScript = (trackingId = process.env.REACT_APP_PIXEL_ID) => {
      let pixelScriptTxt = ''
      let imgSrc = ''
      if (!trackingId) {
        pixelScriptTxt = document.createTextNode(`window.fbq = function(){}`)
        imgSrc = ''
      } else {
        pixelScriptTxt = document.createTextNode(
          ` !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${trackingId}'); 
          fbq('track', 'PageView');
          `
        )
        imgSrc = `https://www.facebook.com/tr?id=${trackingId}&ev=PageView&noscript=1`
      }

      const pixelScript = document.createElement('script')

      pixelScript.appendChild(pixelScriptTxt)
      const pixelNoScript = document.createElement('noscript')
      const pixelImg = document.createElement('img')

      pixelImg.setAttribute('height', 1)
      pixelImg.setAttribute('width', 1)
      pixelImg.setAttribute('src', imgSrc)
      pixelNoScript.appendChild(pixelImg)

      document.getElementsByTagName('head')[0].appendChild(pixelScript)
      document.getElementsByTagName('head')[0].appendChild(pixelNoScript)
    }

    const addLinkedInScript = (trackingId = process.env.REACT_APP_LINKEDIN_ID) => {
      let script1Txt = ''
      let script2Txt = ''
      let imgUrl = ''

      if (trackingId) {
        script1Txt = `_linkedin_partner_id = "${trackingId}"; 
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; 
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);`

        script2Txt = `
        (function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; 
        window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; 
        var b = document.createElement("script"); 
        b.type = "text/javascript";b.async = true; 
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; 
        s.parentNode.insertBefore(b, s);})(window.lintrk);
        `

        imgUrl = 'https://px.ads.linkedin.com/collect/?pid=5865556&fmt=gif'
      } else {
        script2Txt = `window.lintrk = function(){}`
      }

      const txtNode1 = document.createTextNode(script1Txt)
      const txtNode2 = document.createTextNode(script2Txt)

      const script1Elem = document.createElement('script')
      script1Elem.appendChild(txtNode1)
      const script2Elem = document.createElement('script')
      script2Elem.appendChild(txtNode2)

      const noscriptElem = document.createElement('noscript')
      const imgElem = document.createElement('img')
      imgElem.setAttribute('height', 1)
      imgElem.setAttribute('width', 1)
      imgElem.setAttribute('style', 'display: none;')
      imgElem.setAttribute('src', imgUrl)
      noscriptElem.appendChild(imgElem)

      document.getElementsByTagName('head')[0].appendChild(script1Elem)
      document.getElementsByTagName('head')[0].appendChild(script2Elem)
      document.getElementsByTagName('head')[0].appendChild(noscriptElem)
    }

    const fetchUserCountry = async () => {
      let trackingId = process.env.REACT_APP_GA_TRACKING_ID
      try {
        const response = await axios.get('https://ipapi.co/country/')
        const data = await response.data
        setLoading(false)
        window.localStorage.setItem('country_code', data)

        if (!window.localStorage.getItem('checked_country')) {
          if (data === 'KR') {
            i18n.changeLanguage('ko')
            window.localStorage.setItem('i18nextLng', 'ko')
            window.localStorage.setItem('checked_country', true)
            setCookie('language', 'ko')
          } else {
            i18n.changeLanguage('en')
            window.localStorage.setItem('i18nextLng', 'en')
            window.localStorage.setItem('checked_country', true)
            setCookie('language', 'en')
            trackingId = process.env.REACT_APP_GA_TRACKING_ID // 언어설정 상관 없이 국문 GA 트래킹 아이디 공용으로 사용하기로 함 (24.01.18)
          }
        }

        gtagScriptAdd(trackingId)
      } catch (error) {
        setLoading(false)
        gtagScriptAdd()
        console.error('Error fetching user country:', error)
      }
    }
    fetchUserCountry()

    addPixelScript()
    addLinkedInScript()

    return () => {}
  }, [])

  if (isLoading) {
    return <></>
  }

  return children
}

export default App
