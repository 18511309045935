// material
import { Box, Button, Typography } from '@mui/material'
import { Link as RouterLink, useNavigate, useLocation, Link } from 'react-router-dom'
import { CenterAlignStack } from 'components'

import { promotionAnchorAtom, userSubInfoAtom } from 'atoms'
import { useRecoilValue, useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil'

import { removeUserCookie, getAccessToken } from 'utils/cookie'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import {
  MypageIcon,
  PricingIcon,
  RightArrowIcon,
  RightArrowShortIcon,
  LogoutIcon,
  CouponIcon,
} from 'theme/icon'
import SwiperCore, { Navigation, Pagination, Autoplay, Manipulation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { iOS, isFullUrl, isKo, isMoblieDevice } from 'utils/common'
import { SUB_EVENT_ID } from 'pages/Pricing'

// ----------------------------------------------------------------------

export const APPBAR_MOBILE = '5.2rem'
export const APPBAR_NONMEMBER_MOBILE = '9.2rem'
export const APPBAR_DESKTOP = '8rem'
export const APPBAR_SUBMENU = '4.7rem'
export const LINEBANNER_HEIGHT = '4.6rem'
export const LINEBANNER_HEIGHT_MOBILE = '4rem'
export const APPBAR_NONMEMBER_DESKTOP = '12.6rem'

export const triggerGAEvent = menu => {
  const EVENT_NAME = 'navbar_click'
  // ----- GA4 event -----
  window.gtag('event', EVENT_NAME, {
    menu,
  })
  // ---------------------
}

export function LineBannerSwiper({ subEventValid }) {
  const isMo = isMoblieDevice()
  const uuid = Math.ceil(Math.random() * 1000)

  const [currentIndex, setCurrentIndex] = useState(0)
  const [swiper, setSwiper] = useState(null)
  const { t, i18n } = useTranslation()

  const [promotionAnchor, setPromotionAnchor] = useRecoilState(promotionAnchorAtom)
  const userSubInfo = useRecoilValue(userSubInfoAtom)

  const isPromotion = location.pathname.includes('promotion')
  const token = getAccessToken()
  const navigate = useNavigate()

  const swiperRef = useRef(null)

  const LINEBANNER_NONMEMBER = {
    ko: [
      // {
      //   id: 0,
      //   url: '/board',
      //   date: '[공지]',
      //   content: '재생성 기능 운영 방식 변경 안내',
      //   bgColor: '#FFFFFF',
      //   dateTextColor: '#4D80FF',
      //   textColor: '#303030',
      //   anchor: 1,
      //   name: 'logout_white_20241112',
      // },
      {
        id: 1,
        url: '/event/ko/normal/sns_review_event',
        date: '[이벤트]',
        content: '리뷰 작성 시 네이버페이 포인트 모두 증정!',
        bgColor: '#4D80FF',
        dateTextColor: '#FFFFFF',
        textColor: '#FFFFFF',
        anchor: 1,
        name: 'logout_blue_snsevent',
      },
      {
        id: 2,
        url: '/board',
        date: '[업데이트]',
        content: '원클릭 템플릿으로 광고 자동 완성! ',
        bgColor: '#FFFFFF',
        dateTextColor: '#4D80FF',
        textColor: '#303030',
        anchor: 1,
        name: 'logout_white_202412',
      },
      // {
      //   id: 3,
      //   url: '/pricing',
      //   date: t('home_banner.header_1'),
      //   content: t('home_banner.banner_1'),
      //   bgColor: '#FFFFFF',
      //   dateTextColor: '#4D80FF',
      //   textColor: '#303030',
      //   anchor: 1,
      //   name: 'logout_white_free',
      // },
      {
        id: 4,
        img: '/static/images/promotion/promotion_web_1.jpg',
        url: '/promotion/openevent',
        content: t('home_banner.banner_2'),
        bgColor: '#4D80FF',
        textColor: 'white',
        anchor: 1,
        name: 'logout_blue_signup_promo',
      },
      {
        id: 5,
        url: isMo
          ? 'https://mobile.domeggook.com/support/notice/detail?no=7687&utm_source=domeggook&utm_medium=affiliate&utm_campaign=draphart'
          : 'https://domeggook.com/main/information/info_list.php?mode=inNM&no=7687&utm_source=domeggook&utm_medium=affiliate&utm_campaign=draphart',
        content: '[제휴] 지금 도매꾹 신규가입시 최대 10,000P 지급!',
        bgColor: '#C9F9EF',
        textColor: '#303030',
        anchor: 1,
        name: 'logout_sky_domeggook',
      },
    ],
    en: [
      // {
      //   id: 0,
      //   url: '/board',
      //   date: '',
      //   content: 'Notice of Changes to Redo Feature Operation',
      //   bgColor: '#FFFFFF',
      //   dateTextColor: '#4D80FF',
      //   textColor: '#303030',
      //   anchor: 1,
      //   name: 'logout_white_20241112',
      // },
      {
        id: 1,
        url: '/promotion/openevent',
        date: '',
        content: 'Sign up and use Draph Art for free!',
        bgColor: '#4D80FF',
        // dateTextColor: '#4D80FF',
        textColor: '#FFFFFF',
        anchor: 1,
        name: 'logout_blue_signup_promo',
      },
      {
        id: 2,
        url: '/board',
        date: '[Update]',
        content: 'Create Perfect Ads in One Click!',
        bgColor: '#FFFFFF',
        dateTextColor: '#4D80FF',
        textColor: '#303030',
        anchor: 1,
        name: 'logout_white_202412',
      },
    ],
  }

  const LINEBANNER_MEMBER = {
    ko: [
      // {
      //   id: 0,
      //   url: '/board',
      //   date: '[공지]',
      //   content: '재생성 기능 운영 방식 변경 안내',
      //   bgColor: '#FFFFFF',
      //   dateTextColor: '#4D80FF',
      //   textColor: '#303030',
      //   anchor: 1,
      //   name: 'login_white_20241112',
      // },
      {
        id: 1,
        url: '/event/ko/normal/sns_review_event',
        date: '[이벤트]',
        content: '리뷰 작성 시 네이버페이 포인트 모두 증정!',
        bgColor: '#4D80FF',
        dateTextColor: '#FFFFFF',
        textColor: '#FFFFFF',
        anchor: 1,
        name: 'login_blue_snsevent',
      },
      {
        id: 2,
        url: '/board',
        date: '[업데이트]',
        content: '원클릭 템플릿으로 광고 자동 완성! ',
        bgColor: '#FFFFFF',
        dateTextColor: '#4D80FF',
        textColor: '#303030',
        anchor: 1,
        name: 'login_white_202412',
      },
      // {
      //   id: 3,
      //   url: '/pricing',
      //   date: t('home_banner.header_1'),
      //   content: t('home_banner.banner_1'),
      //   bgColor: '#FFFFFF',
      //   dateTextColor: '#4D80FF',
      //   textColor: '#303030',
      //   anchor: 1,
      //   name: 'login_white_free',
      // },
      {
        id: 4,
        url: isMo
          ? 'https://mobile.domeggook.com/support/notice/detail?no=7687&utm_source=domeggook&utm_medium=affiliate&utm_campaign=draphart'
          : 'https://domeggook.com/main/information/info_list.php?mode=inNM&no=7687&utm_source=domeggook&utm_medium=affiliate&utm_campaign=draphart',
        content: '[제휴] 지금 도매꾹 신규가입시 최대 10,000P 지급!',
        bgColor: '#C9F9EF',
        textColor: '#303030',
        anchor: 1,
        name: 'login_sky_domeggook',
      },
    ],
    en: [
      // {
      //   id: 0,
      //   url: '/board',
      //   date: '[공지]',
      //   content: '재생성 기능 운영 방식 변경 안내',
      //   bgColor: '#FFFFFF',
      //   dateTextColor: '#4D80FF',
      //   textColor: '#303030',
      //   anchor: 1,
      //   name: 'login_white_20241112',
      // },
      {
        id: 1,
        url: '/board',
        date: '[Update]',
        content: 'Create Perfect Ads in One Click!',
        bgColor: '#FFFFFF',
        dateTextColor: '#4D80FF',
        textColor: '#303030',
        anchor: 1,
        name: 'login_white_202412',
      },
    ],
  }

  // const promotionlineBanners = !token
  //   ? // 비로그인 유저용 배너
  //     isKo(i18n)
  //     ? LINEBANNER_NONMEMBER.ko
  //     : LINEBANNER_NONMEMBER.en
  //   : // 로그인 유저용 배너
  //   subEventValid && isKo(i18n)
  //   ? LINEBANNER_MEMBER.ko
  //   : LINEBANNER_MEMBER.en

  const [promotionlineBanners, setPromotionlineBanners] = useState([])

  const filterBannerBySubEvent = (banners, subEventValid) => {
    if (!subEventValid) {
      return banners.filter(item => item.name !== 'login_white_free')
    }
    return banners
  }

  useEffect(() => {
    const { language } = i18n

    let baseBanners = []
    if (token) {
      baseBanners = LINEBANNER_MEMBER[language] || []
      baseBanners = filterBannerBySubEvent(baseBanners, subEventValid)
    } else {
      // 비로그인 유저
      baseBanners = LINEBANNER_NONMEMBER[language] || []
    }

    setPromotionlineBanners(baseBanners)
  }, [token, subEventValid, i18n.language])

  useEffect(() => {
    // const sw = new Swiper('.banner-swiper-container', {
    //   pagination: {
    //     el: '.swiper-pagination',
    //   },
    //   modules: [Autoplay, Pagination, Navigation, Manipulation],
    //   slidesPerView: 1,
    //   loop: true,
    //   autoplay: {
    //     delay: 5000,
    //     // disableOnInteraction: false,
    //   },
    // })
    // 왜...배열로 들어오는지 확인이 어려움
    // if (_.isArray(sw)) {
    //   setSwiper(sw[0])
    // } else {
    //   setSwiper(sw)
    // }
  }, [])

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update()

      swiperRef.current.slideTo(0)
      if (promotionlineBanners.length < 2) {
        swiperRef.current.autoplay.stop()
      } else {
        swiperRef.current.autoplay.start()
      }
    }
  }, [promotionlineBanners])

  useEffect(() => {
    const currentBanner = promotionlineBanners[currentIndex]

    if (currentBanner?.id) {
      // ----- GA4 event -----
      window.gtag('event', 'top_banner_imp', { creative_name: currentBanner?.name })
      // ---------------------
    }
  }, [currentIndex])

  const isMobile = useMobileMediaQuery()

  return (
    <>
      <Swiper
        modules={[Autoplay, Pagination, Navigation]}
        slidesPerView={1}
        loop={promotionlineBanners.length > 1}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        navigation={false}
        onSwiper={swiper => {
          swiperRef.current = swiper
        }}
        onSlideChange={() => {}}
        key={`banner-swiper-${i18n.language}-${promotionlineBanners.length}`}
        className={'banner-swiper-container'}
        sx={{ width: '100%', height: '100%' }}
      >
        <div className="swiper-wrapper">
          {promotionlineBanners.map((banner, idx) => (
            <SwiperSlide
              key={idx}
              className="swiper-slide"
              style={{
                display: 'flex',
                width: '100%',
                position: 'relative',
                justifyContent: 'center',
                backgroundColor: banner.bgColor,
                height: isMobile ? LINEBANNER_HEIGHT_MOBILE : LINEBANNER_HEIGHT,
              }}
            >
              <CenterAlignStack
                sx={{
                  position: 'relative',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // ----- GA4 event -----
                  window.gtag('event', 'top_banner_click', { creative_name: banner.name })
                  // ---------------------
                  if (banner.url.includes('promotion')) {
                    setPromotionAnchor(banner.anchor)
                  }
                  if (isPromotion || isFullUrl(banner.url)) {
                    window.open(banner.url, '_blank')
                  } else {
                    navigate(banner.url)
                  }
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: banner.textColor ?? 'white',
                    fontSize: { lg: '2rem', xs: '1.4rem' },
                    fontWeight: 700,
                  }}
                >
                  {banner.date && (
                    <span style={{ color: banner.dateTextColor }}>{banner.date}</span>
                  )}
                  &nbsp;
                  <span>{banner.content}</span>
                  <Button
                    sx={{
                      minWidth: { xs: 'unset' },
                      '&:hover': {
                        background: 'unset',
                      },
                    }}
                  >
                    <Desktop>
                      <RightArrowIcon color={banner.textColor} />
                    </Desktop>
                    <Mobile>
                      <RightArrowShortIcon color={banner.textColor} />
                    </Mobile>
                  </Button>
                </Typography>
              </CenterAlignStack>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </>
  )
}
