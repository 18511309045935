import { Box, Button, Stack, Typography } from '@mui/material'
import { mobileUploadDrawerOpenAtom } from 'atom/mobileUploadAtom'
import { useRefineUploadFiles } from 'hooks/useRefineUploadFiles'
import { useSetRecoilState } from 'recoil'
import { allowedTypes } from 'utils/common'
import { MobileUploadDrawer } from './MobileUploadDrawer'

export const MobileUploadPage = () => {
  return (
    <Stack
      sx={{
        px: '20px',
        py: '24px',
      }}
    >
      <UploadInstructions />
      <UploadInfo />
      <MobileUploadDrawer />
    </Stack>
  )
}

const ExclamationMark = ({ style }) => (
  <svg
    width="3"
    height="10"
    viewBox="0 0 3 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M2.29102 0.515625L2.16211 6.50391H0.837891L0.697266 0.515625H2.29102ZM1.50586 9.09375C1.00195 9.09375 0.591797 8.68359 0.591797 8.17969C0.591797 7.6875 1.00195 7.27734 1.50586 7.27734C1.98633 7.27734 2.4082 7.6875 2.4082 8.17969C2.4082 8.68359 1.98633 9.09375 1.50586 9.09375Z"
      fill="white"
    />
  </svg>
)

const UploadInstructions = () => (
  <Stack
    sx={{
      bgcolor: 'draph.blue',
      width: '100%',
      borderRadius: '10px',
      justifyContent: 'space-between',
    }}
    direction={'row'}
  >
    <Stack
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'center',
        ml: '20px',
      }}
    >
      <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.6rem' }}>
        PC로 접속해서 사용해보세요.
      </Typography>
      <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.2rem' }}>
        모바일에서는 상품 이미지를 등록할 수 있어요.
      </Typography>
    </Stack>
    <img src="/static/images/mobile_upload.png" alt="upload" style={{ marginRight: '8px' }} />
  </Stack>
)

const UploadInfo = () => {
  const setMobileUploadDrawerOpen = useSetRecoilState(mobileUploadDrawerOpenAtom)
  const refineUploadFiles = useRefineUploadFiles(20)

  return (
    <Stack
      sx={{
        justifyContent: 'flex-start',
        mt: '20px',
        borderRadius: '10px',
        background: '#FFF',
        boxShadow: '2px 2px 12px 0px rgba(0, 0, 0, 0.15)',
        py: '24px',
        px: '16px',
        gap: '16px',
      }}
    >
      <Typography
        sx={{
          fontSize: '1.6rem',
          fontWeight: '800',
          color: '#040404',
        }}
      >
        모바일에서 등록한 이미지는 <br />
        PC에서 바로 사용하실 수 있어요.
      </Typography>
      <Stack
        sx={{
          bgcolor: 'draph.lighterblue',
          borderRadius: '10px',
          py: '12px',
          px: '16px',
        }}
        direction={'row'}
        gap={'8px'}
        alignItems={'center'}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            width: '16px',
            height: '16px',
            bgcolor: 'draph.blue',
            position: 'relative',
          }}
        >
          <ExclamationMark
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />
        </Box>
        <Typography
          sx={{
            fontSize: '1.2rem',
            color: '#161616',
          }}
        >
          마이페이지 &gt; 내 상품 이미지에 자동으로 저장되고, <br />
          언제든 불러오기 해서 사용할 수 있어요.
        </Typography>
      </Stack>
      <input
        type="file"
        id="upload"
        style={{ display: 'none' }}
        accept={allowedTypes.join(',')}
        onChange={async e => {
          e.preventDefault()

          const result = await refineUploadFiles(Array.from(e.target.files))

          if (result) {
            setMobileUploadDrawerOpen(true)
          }
        }}
        multiple
      />
      <label htmlFor="upload" style={{ display: 'block', cursor: 'pointer' }}>
        <Button
          sx={{
            bgcolor: 'draph.blue',
            py: '8px',
            fontSize: '1.6rem',
            width: '100%',
            pointerEvents: 'none',
          }}
          variant="contained"
        >
          이미지 등록하기
        </Button>
      </label>
    </Stack>
  )
}
