import { useEffect, useRef } from 'react'

const FontManager = ({ fonts }) => {
  const styleRef = useRef(null)

  useEffect(() => {
    if (!fonts || !Array.isArray(fonts)) return

    // 폰트페이스 문자열 생성
    const fontFaces = fonts
      .filter(font => font && font.id && font.cdn_path)
      .map(font => {
        const format = font.cdn_path.split('.').pop()
        return `
          @font-face {
            font-family: '${font.id}';
            src: url('${font.cdn_path}') format('${format}');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }
        `
      })
      .join('\n')

    // style 태그 업데이트
    if (!styleRef.current) {
      styleRef.current = document.createElement('style')
      styleRef.current.type = 'text/css'
      document.head.appendChild(styleRef.current)
    }
    styleRef.current.textContent = fontFaces

    // 클린업
    return () => {
      if (styleRef.current && styleRef.current.parentNode) {
        styleRef.current.parentNode.removeChild(styleRef.current)
        styleRef.current = null
      }
    }
  }, [fonts])

  return null
}

export default FontManager
