import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { checkUser } from 'utils/user'
import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { apis } from 'apis'
export function NaverCommerceRedirectV2() {
  const navigate = useNavigate()
  const linkRef = useRef()

  useEffect(() => {
    checkUser()

    // ----- GA4 event -----
    window.gtag('event', 'sign_up_begin', {})
    // ---------------------
  }, [])

  const checkUser = async () => {
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')
    if (token) {
      try {
        const res = await apis.naverCommerceV2.login({ jwt: token })
        console.log(token)
        console.log(res.data)

        if (res.data.success) {
          if (res.data.registered) {
            // 로그인 처리
            const d = res.data

            const accessToken = d.access_token
            const refreshToken = d.refresh_token
            setAccessToken(accessToken)
            setRefreshToken(refreshToken)

            window.gtag('event', 'login', {
              method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
            })

            navigate('/home', { replace: true })
          } else {
            linkRef.current.click()
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          const detail = e.response.data.detail
          console.error('Unexpected 401 error:', detail)
          if (detail === 'Expired token') {
            alert('유효기간이(60초) 지나 에러페이지로 이동합니다.')
            window.location.href = 'https://solution.smartstore.naver.com/ko/error'
          } else if (detail === 'Invalid token') {
            alert('유효하지 않은 토큰입니다. 다시 시도해주세요.')
            window.location.href = 'https://solution.smartstore.naver.com/ko/error'
          } else if (detail === 'Invalid solution ID') {
            alert('유효하지 않은 솔루션 ID입니다. 다시 시도해주세요.')
            window.location.href = 'https://solution.smartstore.naver.com/ko/error'
          } else {
            alert('유효하지 않은 토큰입니다. 다시 시도해주세요.')
            window.location.href = 'https://solution.smartstore.naver.com/ko/error'
          }
        } else {
          console.error('An unexpected error occurred:', e)
        }
      }
    }
  }
  return (
    <Link
      ref={linkRef}
      to="https://sell.smartstore.naver.com/#/member/auth?interlockType=solution_auth&returnUrl=https:%2F%2Fsolution.smartstore.naver.com%2Fko%2Foauth%2Fredirect%3FsolutionId%3DSOL_5xGrBPfL9jrwphl1xX8DtD"
    />
  )
}
