import { apis } from 'apis'
import { userAtom } from 'atoms'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

export const useUploadAsset = (reload = false) => {
  const user = useRecoilValue(userAtom)
  const [assetUploadQueue, setAssetUploadQueue] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (assetUploadQueue.length !== 0) {
      setIsLoading(true)

      apis.assets
        .postPresigned(
          user.id,
          assetUploadQueue.map(i => i.name)
        )
        .then(res => {
          return res.data.assets
        })
        .then(async assets => {
          await Promise.all(
            assets.map(async (asset, index) => {
              const putS3 = await axios.put(
                asset.presigned_url,
                new Blob([assetUploadQueue[index]], { type: assetUploadQueue[index].type }),
                {
                  headers: {
                    'Content-Type': assetUploadQueue[index].type,
                  },
                }
              )

              if (putS3.status === 200) {
                await apis.assets.postActivate(asset.asset_id)
              }
            })
          )
        })
        .finally(() => {
          setAssetUploadQueue([])
          if (reload) {
            window.location.reload()
          }
          setIsLoading(false)
        })
    }
  }, [assetUploadQueue])

  const addFilesToQueue = files => {
    setAssetUploadQueue(prev => prev.concat(files))
  }

  const uploadAssets = async files => {
    return await apis.assets
      .postPresigned(
        user.id,
        files.map(i => i.name)
      )
      .then(res => {
        return res.data.assets
      })
      .then(async assets => {
        return await Promise.all(
          assets.map(async (asset, index) => {
            const putS3 = await axios.put(
              asset.presigned_url,
              new Blob([files[index]], { type: files[index].type }),
              {
                headers: {
                  'Content-Type': files[index].type,
                },
              }
            )

            if (putS3.status === 200) {
              await apis.assets.postActivate(asset.asset_id)
            }
            return asset.asset_id
          })
        )
      })
  }

  return {
    addFilesToQueue,
    isLoading,
    uploadAssets,
  }
}
