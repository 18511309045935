import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import * as config from 'config'
import { useEffect, useState } from 'react'

import { apis } from 'apis'
import {
  CenterAlignStack,
  CenterAlignBox,
  DialogButton,
  CustomTooltip,
  MobileSwipeableDrawer,
} from 'components'

import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'

import { t } from 'i18next'
import {
  bannerV2WarningDetectAtom,
  bannerV2WarningDialogAtom,
  downloadWarningDialogAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  portfolioAtom,
} from 'atoms'
import { userPortfolioNamesSelector } from 'selector'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { SwipeableDrawer } from '@mui/material'
import useConfirm from 'hooks/useConfirm'
import { useTranslation } from 'react-i18next'
import { iOS } from 'utils/common'
import { English, Japanese, Korean } from 'hooks/useLanguage'
import { ArtworkWarningIcon, CircleWarningIcon } from 'theme/icon'

export const hideDownloadWarningPopup = 'hideDownloadWarningPopup'

export default function DownloadWarningDialog() {
  const [dialog, setDialog] = useRecoilState(downloadWarningDialogAtom)
  const resetDialog = useResetRecoilState(downloadWarningDialogAtom)

  const [againChecked, setAgainChecked] = useState(false)
  const { open, download } = dialog

  useEffect(() => {}, [])

  const handleClose = (e, reason) => {
    resetDialog()
    setAgainChecked(false)
  }

  const handleDownload = () => {
    download()

    if (againChecked) {
      localStorage.setItem(hideDownloadWarningPopup, true)
    }
    handleClose()
  }

  const content = (
    <>
      <CenterAlignStack>
        <Typography
          sx={{
            fontSize: { lg: '1.4rem', xs: '1.4rem' },
            fontWeight: 400,
            textAlign: 'center',
            '& .bold': { fontWeight: 600 },
          }}
        >
          <Korean>
            다운로드 시, 아트워크를 재생성 할 때{' '}
            <Mobile>
              <br />
            </Mobile>
            <span className="bold">크레딧이 차감됩니다.</span>
            <br />
            다운로드 받으시겠어요?
          </Korean>
          <Japanese>
            ダウンロード時、該当アートワークは{' '}
            <Mobile>
              <br />
            </Mobile>
            <span className="bold">再生成が不可能になります。</span>
            <br />
            ダウンロードしますか？
          </Japanese>
          <English>
            If you download this image,{' '}
            <Mobile>
              <br />
            </Mobile>
            <span className="bold">credits will be deducted when regenerating this artwork.</span>
            <br />
            Would you like to proceed with the download?
          </English>
        </Typography>

        <Typography
          className="dont-show-again"
          sx={{
            cursor: 'pointer',
            mt: { lg: '1.8rem', xs: '2.4rem' },
            fonstSize: { lg: '1.4rem', xs: '1.4rem' },
            fontWeight: 400,
            color: againChecked ? '#2C4DFF' : '#808080',
            display: 'flex',
            alignItems: 'center',

            '& span': {
              width: '2.5rem',

              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
          onClick={() => {
            setAgainChecked(c => !c)
          }}
        >
          <span>
            {againChecked ? (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16C12.4184 16 16 12.4184 16 8C16 3.5816 12.4184 0 8 0C3.5816 0 0 3.5816 0 8C0 12.4184 3.5816 16 8 16Z"
                  fill="#2C4DFF"
                />
                <path
                  d="M4.60718 8.06377L6.91118 10.3678L11.5192 5.75977"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 17C13.4184 17 17 13.4184 17 9C17 4.5816 13.4184 1 9 1C4.5816 1 1 4.5816 1 9C1 13.4184 4.5816 17 9 17Z"
                  stroke="#808080"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.60718 9.06377L7.91118 11.3678L12.5192 6.75977"
                  stroke="#808080"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </span>
          <Korean>다시 보지 않기</Korean>
          <Japanese>二度と見ない</Japanese>
          <English>{"Don't show again"}</English>
        </Typography>
      </CenterAlignStack>
    </>
  )

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={false}
          sx={{
            '& .MuiDialog-paper': {
              pt: '3.2rem',
              px: 0,

              borderRadius: '1rem',
            },
          }}
        >
          <DialogContent sx={{ p: 0, px: '3.6rem' }}>{content}</DialogContent>

          <DialogActions sx={{ p: 0, mt: '2.6rem' }}>
            <CenterAlignStack
              direction="row"
              sx={{
                width: '100%',

                '& .button': {
                  fontSize: '1.4rem',
                  fontWeight: 600,
                  width: '50%',
                  height: '4.4rem',
                  borderRadius: 0,
                  '&.cancel': { background: '#F8F8F8', color: '#303030' },
                  '&.download': {
                    color: 'white',
                    background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
                  },
                },
              }}
            >
              <Button className="button cancel" onClick={handleClose}>
                {t('button.cancel')}
              </Button>
              <Button className="button download" onClick={handleDownload}>
                {t('button.download')}
              </Button>
            </CenterAlignStack>
          </DialogActions>
        </Dialog>
      </Desktop>

      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onClose={handleClose}
          onOpen={() => {
            return open
          }}
          anchor="bottom"
          sx={{
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
            },
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <CenterAlignBox sx={{ mt: '2rem' }}>{content}</CenterAlignBox>

          <CenterAlignStack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              pb: '2.8rem',
              mt: '2.8rem',

              '& .button': {
                fontSize: '1.6rem',
                fontWeight: 600,

                height: '4rem',
                borderRadius: 0,
                '&.cancel': { width: '8rem', background: 'transparent', color: '#303030' },
                '&.download': {
                  borderRadius: '4px',
                  width: '20rem',
                  color: 'white',
                  background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
                },
              },
            }}
          >
            <Button className="button cancel" onClick={handleClose}>
              {t('button.cancel')}
            </Button>
            <Button className="button download" onClick={handleDownload}>
              {t('button.download')}
            </Button>
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

export function BannerV2WarningDialog() {
  const [dialog, setDialog] = useRecoilState(bannerV2WarningDialogAtom)

  const resetDialog = useResetRecoilState(bannerV2WarningDialogAtom)
  const resetDetect = useResetRecoilState(bannerV2WarningDetectAtom)

  const [againChecked, setAgainChecked] = useState(false)
  const { open, confirmCallback, cancelCallback } = dialog

  useEffect(() => {}, [])

  const handleMoveLayer = (e, reason) => {
    resetDialog()
    resetDetect()

    cancelCallback()
    setAgainChecked(false)
  }

  const handleDownload = () => {
    confirmCallback()

    if (againChecked) {
      localStorage.setItem('hideBannerWarningPopup', true)
    }
    handleMoveLayer()
  }

  const handleClose = () => {
    resetDialog()
    resetDetect()
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={false}
          sx={{
            '& .MuiDialog-paper': {
              p: '3.2rem 2.5rem 2.8rem 2.5rem',
              borderRadius: '1rem',
            },
          }}
        >
          <CenterAlignStack>
            <CenterAlignBox sx={{ alignItems: 'center' }}>
              {' '}
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
                  fill="url(#paint0_linear_16115_2731)"
                />
                <path
                  d="M10.9162 5.70001L10.7605 11.9251H9.22775L9.07208 5.70001H10.9162ZM9.00023 13.6686C8.98826 13.1038 9.44329 12.6434 10.0061 12.6495C10.539 12.6434 11 13.1038 11 13.6686C11 14.2396 10.539 14.7 10.0061 14.7C9.44329 14.7 8.98826 14.2396 9.00023 13.6686Z"
                  fill="url(#paint1_linear_16115_2731)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_16115_2731"
                    x1="0"
                    y1="10"
                    x2="20"
                    y2="10"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2C4DFF" />
                    <stop offset="1" stopColor="#8D00FC" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_16115_2731"
                    x1="0"
                    y1="10"
                    x2="20"
                    y2="10"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2C4DFF" />
                    <stop offset="1" stopColor="#8D00FC" />
                  </linearGradient>
                </defs>
              </svg>{' '}
              <Typography className="gradient-text" sx={{ fontSize: '2rem', fontWeight: 700 }}>
                &nbsp;잠깐 ! 수정 후 적용하셨나요?
              </Typography>{' '}
            </CenterAlignBox>

            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '1.4rem',
                lineHeight: '1.5',
                m: '2rem 0 2.4rem 0 ',
              }}
            >
              <span style={{ fontWeight: 700 }}>
                적용 없이 레이어를 이동할 경우, 작업한 내용이 저장되지 않습니다.
              </span>
              <br />
              다른 레이어로 이동할까요?
            </Typography>
          </CenterAlignStack>

          {/* <DialogContent sx={{ p: 0, px: '3.6rem' }}>{content}</DialogContent> */}
          <DialogActions sx={{ p: 0 }}>
            <CenterAlignStack
              direction="row"
              sx={{
                width: '100%',
                gap: '1rem',
                '& .button': {
                  fontSize: '1.6rem',
                  fontWeight: 600,
                  width: '50%',

                  height: '4rem',
                  borderRadius: '4px',
                  '&.cancel': { background: '#EEEEEE', color: '#303030' },
                  '&.confirm': {
                    color: '#fff',
                    background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
                  },
                },
              }}
            >
              <Button className="button cancel" onClick={handleMoveLayer}>
                레이어 이동
              </Button>
              <Button className="button confirm" onClick={handleClose}>
                계속 수정하기
              </Button>
            </CenterAlignStack>
          </DialogActions>
        </Dialog>
      </Desktop>
    </>
  )
}

export function NaverCommerceRedirectDialog({ open, setOpen }) {
  const URL = 'https://solution.smartstore.naver.com/ko/solution/SOL_5xGrBPfL9jrwphl1xX8DtD/change'
  const navigate = useNavigate()

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          maxWidth={false}
          sx={{
            '& .MuiDialog-paper': {
              minWidth: '53rem',
              p: '3.2rem 2.5rem 2.8rem 2.5rem',
              borderRadius: '1rem',
            },
          }}
        >
          <CenterAlignStack>
            <CenterAlignBox sx={{ alignItems: 'center', mr: '1rem' }}>
              {' '}
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
                  fill="url(#paint0_linear_16115_2731)"
                />
                <path
                  d="M10.9162 5.70001L10.7605 11.9251H9.22775L9.07208 5.70001H10.9162ZM9.00023 13.6686C8.98826 13.1038 9.44329 12.6434 10.0061 12.6495C10.539 12.6434 11 13.1038 11 13.6686C11 14.2396 10.539 14.7 10.0061 14.7C9.44329 14.7 8.98826 14.2396 9.00023 13.6686Z"
                  fill="url(#paint1_linear_16115_2731)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_16115_2731"
                    x1="0"
                    y1="10"
                    x2="20"
                    y2="10"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2C4DFF" />
                    <stop offset="1" stopColor="#8D00FC" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_16115_2731"
                    x1="0"
                    y1="10"
                    x2="20"
                    y2="10"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2C4DFF" />
                    <stop offset="1" stopColor="#8D00FC" />
                  </linearGradient>
                </defs>
              </svg>{' '}
              <Typography className="gradient-text" sx={{ fontSize: '2rem', fontWeight: 700 }}>
                &nbsp;결제 안내
              </Typography>{' '}
            </CenterAlignBox>

            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '1.4rem',
                lineHeight: '1.5',
                m: '2rem 0 2.4rem 0 ',
              }}
            >
              네이버 커머스 연동 이용자 분들은{' '}
              <span style={{ fontWeight: 700 }}>네이버 정책상 네이버에서만 </span>
              결제가 가능합니다.
            </Typography>
          </CenterAlignStack>

          {/* <DialogContent sx={{ p: 0, px: '3.6rem' }}>{content}</DialogContent> */}
          <DialogActions sx={{ p: 0 }}>
            <CenterAlignStack
              direction="row"
              sx={{
                width: '100%',
                gap: '1rem',
                '& .button': {
                  fontSize: '1.6rem',
                  fontWeight: 600,
                  width: '50%',

                  height: '4rem',
                  borderRadius: '4px',
                  '&.cancel': { background: '#EEEEEE', color: '#303030' },
                  '&.confirm': {
                    color: '#fff',
                    background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
                  },
                },
              }}
            >
              <Button
                className="button cancel"
                onClick={() => {
                  navigate(-1)
                }}
              >
                뒤로가기
              </Button>
              <Button
                className="button confirm"
                onClick={() => {
                  navigate(-1)
                  window.open(URL, '_blank', 'noopener,noreferrer')
                }}
              >
                이동하기
              </Button>
            </CenterAlignStack>
          </DialogActions>
        </Dialog>
      </Desktop>
    </>
  )
}
