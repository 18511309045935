import { Box, Stack, Typography } from '@mui/material'
import { uploadFilesAtom } from 'atoms'
import { ImageThumb } from 'components/portfolio/UploadImageDialog'
import { useRefineUploadFiles } from 'hooks/useRefineUploadFiles'
import { useRecoilState } from 'recoil'
import { PlusMobileIcon } from 'theme/icon'
import { allowedTypes } from 'utils/common'

export const AddImage = () => {
  const [files, setFiles] = useRecoilState(uploadFilesAtom)
  const refineUploadFiles = useRefineUploadFiles(20)

  return (
    <Stack alignItems={'center'}>
      <Typography fontSize={'1.2rem'}>이미지 등록하기</Typography>
      <Stack py={'20px'} direction={'row'} gap="4px">
        {[...files].map((f, i) => (
          <ImageThumb
            key={i}
            url={URL.createObjectURL(f)}
            idx={i}
            setFiles={setFiles}
            files={files}
            variant="asset"
          />
        ))}
        <input
          type="file"
          id="upload"
          style={{ display: 'none' }}
          accept={allowedTypes.join(',')}
          onChange={e => {
            e.preventDefault()

            refineUploadFiles(Array.from(e.target.files))
          }}
          multiple
        />
        <label htmlFor="upload" style={{ display: 'block', cursor: 'pointer' }}>
          <Stack
            sx={{
              width: '66px',
              height: '100%',
              borderRadius: '8px',
            }}
            alignItems={'center'}
            justifyContent={'center'}
            bgcolor={'#DCDCDC'}
          >
            <PlusMobileIcon />
          </Stack>
        </label>
      </Stack>
    </Stack>
  )
}
